import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {DisaInterface} from "../../interfaces/Disa"
import {CoachInterface} from "../../interfaces/Coach"
import {MeetingInterface} from "../../interfaces/Meeting"
import Loading from "../Loading"
import { formatDate, formatHourMinute} from "../../helpers/dateFunctions"
import {RecommendationInterface} from "../../interfaces/RecommendationInterface";
import Recommendations from "../recommendations/Recommendations";
import Breadcrumbs from "../menu/Breadcrumbs";
import DisaRomb from "../graphics/DisaRomb";
import SpeedoMeter from "../graphics/SpeedoMeter";
import MeetingButton from "../MeetingButton";
import { IonModal } from '@ionic/react';
import {CONFIG} from "../../constants";
import { TrVar } from "../../services/translate";

interface ComponentProps{
}

interface PrivateLicense {
  code: string,
  customer: string
  seats: number
}

interface ComponentState {
  firstName: string
  lastName: string
  picture: string
  areas: Array<string>
  meetings: Array<MeetingInterface>
  coach: CoachInterface
  loggedIn: boolean
  showLogin: boolean
  page: string
  loaded: boolean
  privateLicenses: Array<PrivateLicense>
  recommendations: RecommendationInterface
  nextMeetingDisa: Array<DisaInterface>
  nextMeetingCustomerName: string
  nextMeetingGap: number
  cancelMeetingId: number
  cancelText: string
  showCancel: boolean
}

export default class CoachOverview extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state = {
    payType: 0,
    firstName: '',
    lastName: '',
    picture: '',
    areas: [],
    questions: [],
    nextMeetingDisa: [],
    nextMeetingCustomerName: '',
    nextMeetingGap: 0,
    coach: {} as CoachInterface,
    meetings: [],
    loggedIn: this.api.loggedIn,
    showLogin: false,
    page: 'overview',
    loaded: false,
    privateLicenses: [],
    recommendations: {} as RecommendationInterface,
    showCancel: false,
    cancelText: '',
    cancelMeetingId: 0,
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    this.api.get('coach?recommendations=1&meetingSummary=1&nextMeeting=1&privateLicenses=1').then(response => {
      const coach = response.json.coach
      this.setState({
        firstName: coach.firstName,
        lastName: coach.lastName,
        areas: coach.areas,
        picture: coach.picture,
        meetings: response.json.meetings,
        loaded: true,
        coach: coach,
        nextMeetingDisa: response.json.nextMeetingDisa,
        nextMeetingGap: response.json.nextMeetingGap,
        privateLicenses: response.json.privateLicenses,
        recommendations: response.json._recommendations,
        nextMeetingCustomerName: response.json.nextMeetingCustomerName
      })
    })
  }

  cancelBooking = () => {
    const data = {
      cancel_text: this.state.cancelText
    }
    this.api.post('coach_meeting/cancel/' + this.state.cancelMeetingId, data).then(_response => {
      this.setState({ showCancel: false })
      setTimeout(() => this.getData(), 300)
    })
  }  

  showMeetingStatus = (meetingType: number) => {
    let status = ''
    if (meetingType === 1) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === 2) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      return (
        <span className="mr-4">{status}</span>
      )
    }
    return (<></>)
  }

  showMeetingSummary = (doneMeetings: number, otherCoachMeetings: number, meetingCount: number) => {
    const meetingNr = doneMeetings + otherCoachMeetings
    let meetingWithYou = ""
    if (otherCoachMeetings > 0) {
      meetingWithYou = "" + doneMeetings
      if (meetingCount > 0) {
        meetingWithYou += " " + this.api.trTxt(TrVar.Of).toLowerCase() + " " +
          (meetingCount - otherCoachMeetings)
      }
      meetingWithYou += " " + this.api.trTxt(TrVar.WithYou).toLowerCase()
    }
    return (
      <>
        {meetingNr}
        {
          meetingCount > 0 &&
          <>
              &nbsp;{this.api.trTxt(TrVar.Of).toLowerCase()}
              &nbsp;{meetingCount}
          </>
        }
        {
          meetingWithYou.length > 0 &&
          <>
              &nbsp;({meetingWithYou})
          </>
        }
      </>
    )

  }

  rescheduleBooking = (id:number) => {
    this.setState({ showCancel: false },
      () => setTimeout(() => {changeUrl('/app/coach/bookings/reschedule/' + id)}, 300))
  }  

  renderCancelBooking = () => {
    return (
      <div className="top_margin bottom_margin right_margin left_margin text_center white">
        <h3 className="mt-4">{this.api.trTxt(TrVar.ChangeMeeting)}</h3>
        <div className="button green inline_block top_margin" onClick={() => this.rescheduleBooking(this.state.cancelMeetingId)}>
          {this.api.trTxt(TrVar.ToCalendar)}
        </div>
        <h3 className="mt-12">
          {this.api.trTxt(TrVar.WantToCancelMeeting)}
        </h3>

        <textarea className="mt-4 page_text_area small w-full" rows={4}
                  placeholder={this.api.trTxt(TrVar.CancelMeetingExplanation)}
                  value={this.state.cancelText} onChange={this.handleCancelText}/>

        <div className="top_margin text_center">
          <p className="font_small">{this.api.trTxt(TrVar.CoachCancelMeetingWarning)}</p>
          <div className="mt-2 button red inline_block" onClick={() => this.cancelBooking()}>{this.api.trTxt(TrVar.CancelMeeting)}</div>
        </div>
      </div>
    )
  }
  

  handleCancelText = (e: any) => {
    this.setState({cancelText: e.target.value})
  }

  noNextMeeting = () => {
    return (
      <div className="page_section bottom_margin">
        <h3>{this.api.trTxt(TrVar.NextMeeting)}</h3>
        <div className="top_margin">
          <p>{this.api.trTxt(TrVar.NoBookedMeeting)}</p>
          <p>{this.api.trTxt(TrVar.AddMeetingTimes)}</p>

          <div className="mt-12 button green inline_block"
               onClick={() => changeUrl('/app/coach/bookings/meeting_times')}>
            {this.api.trTxt(TrVar.ToCalendar)}
          </div>
        </div>
      </div>
    )
  }

  nextMeeting = () => {

    const meeting: MeetingInterface = this.state.meetings[0]

    return (
      <div className="page_section">
        <IonModal isOpen={this.state.showCancel} onDidDismiss={() => this.setState({showCancel: false})}
                  className="modal_medium">
          {
            this.renderCancelBooking()
          }
        </IonModal>
        <h3>{this.api.trTxt(TrVar.NextMeeting)}</h3>
        <div key={meeting.id}>
          <div className="border_box flex no_padding flex-wrap justify-between top_margin">
            <div className="flex flex-col justify-between">
              <div className="flex items-center top_left_margin">
                <img alt="client" className="picture_small round" src={meeting.client.picture} />
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.Client)}</p>
                  <p className="bold link_text" onClick={() => changeUrl("/app/coach/clients/" + meeting.client.id)}>
                    {meeting.client.firstName} {meeting.client.lastName}
                    {meeting.guestName ? ' + ' + meeting.guestName : ''}
                  </p>
                </div>
              </div>
              <div className="flex items-center top_left_margin">
                <img alt="employer" src="/assets/icon/blue/company.svg" width={45} />
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.Employer)}</p>
                  <p className="bold">{this.state.nextMeetingCustomerName}</p>
                </div>
              </div>              
              <div className="flex items-center top_left_margin">
                <img className="icon_large svg_blue" alt="time" src="/assets/icon/calendar.svg"/>
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.Date)}</p>
                  <p className="bold">{formatDate(meeting.start, false, this.api.lang)}</p>
                </div>
              </div>
              <div className="flex items-center top_left_margin">
                <img className="icon_large" alt="time" src="/assets/icon/blue/clock.svg"/>
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.Time)}</p>
                  <p className="bold">{formatHourMinute(meeting.start)}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between r_top_margin">
              <div className="flex items-center top_left_margin">
                <img alt="client" className="icon_large svg_blue" src="/assets/icon/calendar_time.svg" />
                <div className="left_margin">
                  <p className="blue font_small capitalize">{this.api.trTxt(TrVar.Meetings)}</p>
                  <p className="bold">
                    {this.showMeetingSummary(meeting.meetingsSummary.meetingsDone, meeting.meetingsSummary.otherCoachMeetings,
                      meeting.meetingsSummary.meetingsTotal)}
                  </p>
                </div>
              </div>
              <div className="flex items-center top_left_margin">
                <img className="icon_large " alt="time" src="/assets/icon/blue/license.svg"/>
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.License)}</p>
                  <p className="bold">{meeting.meetingsSummary.packageName}</p>
                </div>
              </div>
              <div className="flex items-center top_left_margin">
                <img className="icon_large" alt="time" src="/assets/icon/blue/valid_to.svg"/>
                <div className="left_margin">
                  <p className="blue font_small">{this.api.trTxt(TrVar.LicenseIsValidUpTo)}</p>
                  <p className="bold">
                    {meeting.meetingsSummary.licenseEnd ?
                      meeting.meetingsSummary.licenseEnd
                      :
                      this.api.trTxt(TrVar.NoEndDate)
                    }
                    </p>
                </div>
              </div>
            </div>

            <div className="top_left_margin" style={{width: "300px", height: "225"}}>
              <DisaRomb disa={this.state.nextMeetingDisa} color="cerise" height={300}/>
            </div>

            <div className="flex flex-col justify-between top_left_margin">
              <div style={{ maxWidth: '188px'}}>
                {meeting.client.firstName} {
                  this.state.nextMeetingGap < 0 ?
                ' ' + this.api.trTxt(TrVar.WantsToDecreaseTheirPotential).toLowerCase() + ' '
                :
                ' ' + this.api.trTxt(TrVar.WantsToIncreaseTheirPotential).toLowerCase() + ' '
                }
                {this.state.nextMeetingGap.toFixed(0)}%
              </div>
              <div className="flex items-center">
                <div className="my-4" style={{width: "100px", height: "100px"}}>
                  <SpeedoMeter value={this.state.nextMeetingGap}/>
                </div>
              </div>
              <div className='flex'>
                <MeetingButton who="coach" meeting={meeting}
                  name={meeting.coach.firstName} />
                <div  onClick={() => this.setState({cancelMeetingId: meeting.id, showCancel: true})} style={{ textDecoration: 'underline', cursor: 'pointer', alignSelf: 'center', fontSize: '14px' }} className='left_margin'>{this.api.trTxt(TrVar.CancelMeeting)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  comingMeetings = () => {

    return (
      <div className="page_section top_margin">
        <h3>{this.api.trTxt(TrVar.ComingMeetings)}</h3>
        {
          this.state.meetings.filter((m, index) => index > 0).map((meeting: MeetingInterface, i) => {
            return (
              <div key={meeting.id}
                   className={"border_box top_margin col-1_2 justify-between " + (i % 2 === 1 ? "r_left_margin_early_break": "")}>
                <div className="r-flex justify-between items-center">
                  <div className="flex items-center">
                    <img alt="client" className="picture_small round" src={meeting.client.picture} />  
                    <div className="left_margin">
                      <p className="blue font_small">{this.api.trTxt(TrVar.Client)}</p>
                      <p className="bold link_text" onClick={() => changeUrl("/app/coach/clients/" + meeting.client.id)}>{meeting.client.firstName} {meeting.client.lastName}</p>
                    </div>
                  </div>
                  <div className="r_top_margin">
                      <p className="bold">{formatDate(meeting.start, false, this.api.lang)}</p>
                      <p className="bold">{formatHourMinute(meeting.start)}</p>
                  </div>

                  <div className="button white" onClick={() => changeUrl("/app/coach/clients/" + meeting.client.id)}>
                    {this.api.trTxt(TrVar.ClientInfo)}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.Overview)} />

        {
          !this.state.coach.approved &&
          <div className="page_section bottom_margin">
            <div style={{ maxWidth: '720px' }}>
              <h4 className="blue">{this.api.trTxt(TrVar.WelcomeAndThanksForYourApplication)}</h4>
              {
                this.state.coach.coachType === 2 ? (
                  <>
                    <p className="top_margin">
                      {
                        this.api.trTxt(TrVar.WeAreSoHappyThatYouWantToBecomeACoachWithUs)
                      }
                    </p>
                    <h4 className="top_margin">{this.api.trTxt(TrVar.WhatHappensNext)}</h4>
                    <p className="">
                      {
                        this.api.trTxt(TrVar.RightNowWeHaveManyApplicants)
                      }
                    </p>
                    <p className="top_margin">
                      {
                        this.api.trTxt(TrVar.StoreProfileForTwelveMonths)
                      }
                    </p>
                    <p className="top_margin">
                      {
                        this.api.trTxt(TrVar.BestRegardsTeamZebrain)
                      }
                    </p>
                    <p>
                      &mdash; {
                        this.api.trTxt(TrVar.WeDemocratiseMentalStrength)
                      }
                    </p>
                    <p className="top_margin">
                      {
                        this.api.trTxt(TrVar.FollowUsOnSocialMedia)
                      }
                    </p>
                    <div className="top_margin">
                      <div className="flex">
                        <p>{this.api.trTxt(TrVar.Website) + ':'} &nbsp;</p>
                        <a href={this.api.trTxt(TrVar.WwwZebrainSe)} className='link_text'>{this.api.trTxt(TrVar.WwwZebrainSe)}</a>
                      </div>
                      <div className="flex">
                        <p>{this.api.trTxt(TrVar.LinkedIn) + ':'} &nbsp;</p>
                        <a href={this.api.trTxt(TrVar.WwwLinkedinCom)} className='link_text'>{this.api.trTxt(TrVar.WwwLinkedinCom)}</a>
                      </div>
                      <div className="flex">
                        <p>{this.api.trTxt(TrVar.Facebook) + ':'} &nbsp;</p>
                        <a href={this.api.trTxt(TrVar.WwwFacebookCom)} className='link_text'>{this.api.trTxt(TrVar.WwwFacebookCom)}</a>
                      </div>
                      <div className="flex">
                        <p>{this.api.trTxt(TrVar.Instagram) + ':'} &nbsp;</p>
                        <a href={this.api.trTxt(TrVar.AtZebrain)} className='link_text'>{this.api.trTxt(TrVar.AtZebrain)}</a>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="top_margin">{this.api.trTxt(TrVar.ThanksWhenZebrainHasApproved)}</p>
                )
              }
            </div>
          </div>
        }

        {
          (this.state.coach.approved && this.state.recommendations && this.state.recommendations.type === 'banner') &&
          <Recommendations recommendations={this.state.recommendations} isCoach={true} />
        }

        {
          this.state.coach.approved &&
          <>
            {
              this.state.meetings.length > 0 ? (
                this.nextMeeting()
              ) : (
                this.noNextMeeting()
              )
            }
          </>
        }


        {
          this.state.meetings.length > 1 &&
          this.comingMeetings()
        }

        {
          this.state.privateLicenses.length > 0 &&
          <div className="margin_top page_section">
            <h4 className="blue">Bjud in klienter</h4>
            <p className="top_margin">
              Mejla länken nedan till klienter du vill bjuda in:
            </p>
            {
              this.state.privateLicenses.map((license: PrivateLicense) => {
                return (
                  <div key={"licence_" + license.code} className="mt-4">
                    <h4>{license.customer}, {license.seats} klienter:</h4>
                    <p className="mt-2">{CONFIG.APP_URL_START}app/client_onboarding?package={license.code}</p>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    )
  }
}
