import React from "react";

interface DisaProp {
  key: string;
  name: string;
  value: number;
}
interface ComponentProps {
  disa: Array<number>
}

interface ComponentState {
}

class Analysis extends React.Component<ComponentProps, ComponentState> {

  private getDisaProps = (disa: Array<number>) => {
    let disaProps: Array<DisaProp> = [
      {
        key: 'D',
        name: 'Drivande',
        value: disa[0]
      },
      {
        key: 'I',
        name: 'Inspirerande',
        value: disa[1]
      },
      {
        key: 'S',
        name: 'Stödjande',
        value: disa[2]
      },
      {
        key: 'A',
        name: 'Analytisk',
        value: disa[3]
      }
    ]

    return disaProps.sort(function(a, b) {
      if (b.value > a.value) return 1;
      else if (b.value < a.value) return -1;
      else return 0;
    });
  }

  render() {
    const disaProps = this.getDisaProps(this.props.disa);
    return (
      <div style={{padding: "0 16px", width: "44vw"}}>
        <h3 className="text_center mt-4">Analys</h3>
        {
          disaProps[0].value > 0 ? (
            <p className="mt-4">
              Du är mest {disaProps[0].name} och näst mest {disaProps[1].name}
            </p>
          ) : (
            <p className="mt-4">Inget att analysera än...</p>
          )
        }
      </div>
    )
  }
}

export default Analysis;
