import {
  IonModal,
} from '@ionic/react'
import React from 'react'
import Api from "../../services/Api"
import "./VisionBoard.css"
import Emoji from "../graphics/Emoji";
import { TrVar } from '../../services/translate';

interface MediaInterface {
  id: number
  name: string
  url: string
}

interface ComponentProps {
  meetingId: number
  save?: Function
  webSocket?: WebSocket
}

interface ComponentState {
  goal: string
  goalOutcome: number
  nextStep: string
  insights: string
  sounds: Array<MediaInterface>
  pictures: Array<MediaInterface>
  clips: Array<MediaInterface>
  showSound: boolean
  showPicture: boolean
  showClip: boolean
  collapse1: boolean
  collapse2: boolean
  collapse3: boolean
  collapseTexts: boolean
  showEmojisGoal: boolean
  showEmojisNext: boolean
  showEmojisInsights: boolean
}

const IMAGE_PATH = "https://public.zebrain.se/visionboard/pictures/"

export default class VisionBoard extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    goal: '',
    goalOutcome: 0,
    nextStep: '',
    insights: '',
    sounds: [] as Array<MediaInterface>,
    pictures: [] as Array<MediaInterface>,
    clips: [] as Array<MediaInterface>,
    showSound: false,
    showPicture: false,
    showClip: false,
    collapse1: false,
    collapse2: false,
    collapse3: false,
    collapseTexts: false,
    showEmojisGoal: false,
    showEmojisNext: false,
    showEmojisInsights: false,
  }

  lastGoal = ''
  lastNextStep = ''
  lastInsights = ''

  sounds: Array<MediaInterface> = [
    {
      id: 1,
      name: 'Eagle',
      url: 'https://public.zebrain.se/visionboard/sounds/eagle.mp3'
    },
    {
      id: 2,
      name: 'Soldiers',
      url: 'https://public.zebrain.se/visionboard/sounds/souldiers.mp3'
    },
  ]

  clips: Array<MediaInterface> = [
    {
      id: 1,
      name: 'Analysis',
      url: 'https://public.zebrain.se/visionboard/clips/analysis.mp4'
    },
    {
      id: 2,
      name: 'Jobbtips',
      url: 'https://public.zebrain.se/visionboard/clips/job_tips.mp4'
    }
  ]

  pictures: Array<MediaInterface> = []

  componentDidMount() {
    for (let i = 30; i < 59; i++) {
      this.pictures.push({
        id: i,
        name: 'Pic ' + i,
        url: i + '.jpeg'
      })
    }
    for (let i = 1; i < 30; i++) {
      this.pictures.push({
        id: i,
        name: 'Pic ' + i,
        url: i + '.jpeg'
      })
    }
    // If VisionBoard is opened from external video meeting, do not query vision board data
    if (this.props.meetingId > 0) {
      const url = 'vision_board/get/' + this.props.meetingId
      this.api.get(url).then(response => {
        if (response.json.data) {
          this.parseData(response.json.data)
        }
      })
      // MB: This needs to distinguish between clients and coaches (ie coaches should use coach_log_event)
      //const data: LogEventInterface = {
      //  eventType: LogEventType.PAGE_VIEW,
      //  eventData: {name: 'vision_board_history'}
      //}
      //this.api.post('client_log_event', data).then()
    }

    if (this.props.webSocket) {
      this.props.webSocket.addEventListener('message', this.messageListener)
    }
  }

  messageListener = (event: any) => {
    const message = JSON.parse(event.data)
    if (message.type === 'meeting' && message.command === 'visionBoard') {
      if (message.data.what === 'goal') {
        this.setState({goal: message.data.value})
      } else if (message.data.what === 'goalOutcome') {
        this.setState({goalOutcome: message.data.value})
      } else if (message.data.what === 'nextStep') {
        this.setState({nextStep: message.data.value})
      } else if (message.data.what === 'insights') {
        this.setState({insights: message.data.value})
      } else if (message.data.what === 'sounds') {
        this.setState({sounds: this.populateMedia(message.data.value, this.sounds)})
      } else if (message.data.what === 'pictures') {
        this.setState({pictures: this.populateMedia(message.data.value, this.pictures)})
      } else if (message.data.what === 'clips') {
        this.setState({clips: this.populateMedia(message.data.value, this.clips)})
      }
    }
  }

  componentWillUnmount() {
    if (this.props.webSocket) {
      this.props.webSocket.removeEventListener('message', this.messageListener)
    }
  }

  getMedia = (id: number, medias: Array<MediaInterface>) => {
    for (const media of medias) {
      if (media.id === id) {
        return media
      }
    }
    return null
  }

  populateMedia = (storeds: Array<MediaInterface>, medias: Array<MediaInterface>) => {
    const myMedias: Array<MediaInterface> = []
    if (!storeds || storeds.length === 0) {
      return myMedias
    }
    for (const stored of storeds) {
      const myMedia = this.getMedia(stored.id, medias)
      if (myMedia) {
        myMedias.push(myMedia)
      }
    }
    return myMedias
  }

  parseData = (data: any) => {
    this.setState({
      goal: data.goal ? data.goal : '',
      goalOutcome: data.goalOutcome,
      nextStep: data.nextStep ? data.nextStep : '',
      insights: data.insights ? data.insights : '',
      sounds: this.populateMedia(data.sounds, this.sounds),
      pictures: this.populateMedia(data.pictures, this.pictures),
      clips: this.populateMedia(data.clips, this.clips),
    }, () => this.updateLast())
  }

  updateLast = () => {
    this.lastGoal = this.state.goal
    this.lastInsights = this.state.insights
    this.lastNextStep = this.state.nextStep
  }

  saveData = (what: string) => {
    const url = 'vision_board/post/' + this.props.meetingId
    let value: any
    let foundUpdate: boolean = true
    if (what === 'goal') {
      foundUpdate = false
      value = this.state.goal
      if (value !== this.lastGoal) {
        foundUpdate = true
        this.lastGoal = value
      }
    } else if (what === 'goalOutcome') {
      value = this.state.goalOutcome
    } else if (what === 'nextStep') {
      value = this.state.nextStep
      foundUpdate = false
      if (value !== this.lastNextStep) {
        foundUpdate = true
        this.lastNextStep = value
      }
    } else if (what === 'insights') {
      value = this.state.insights
      foundUpdate = false
      if (value !== this.lastInsights) {
        foundUpdate = true
        this.lastInsights = value
      }
    } else if (what === 'sounds') {
      value = this.state.sounds
    } else if (what === 'pictures') {
      value = this.state.pictures
    } else if (what === 'clips') {
      value = this.state.clips
    } else {
      alert('Error, cannot update ' + what)
      return
    }
    if (!foundUpdate) {
      return
    }

    const data = {
      what: what,
      value: value
    }
    if (this.props.meetingId > 0) {
      this.api.post(url, data).then(response => {
        console.log('saved vision board data')
      })
    }

    if (this.props.save) {
      this.props.save(data)
    }
  }

  keyUpGoal = (e: any) => {
    if (e.keyCode === 13) {
      this.saveData('goal')
    }
  }

  keyUpInsights = (e: any) => {
    if (e.keyCode === 13) {
      this.saveData('insights')
    }
  }

  keyUpNextStep = (e: any) => {
    if (e.keyCode === 13) {
      this.saveData('nextStep')
    }
  }


  handleGoal = (e: any) => {
    this.setState({goal: e.target.value})
  }

  setGoalOutcome = (outcome: number) => {
    this.setState({goalOutcome: outcome}, () => this.saveData('goalOutcome'))
  }

  handleNextStep = (e: any) => {
    this.setState({nextStep: e.target.value})
  }

  handleInsights = (e: any) => {
    this.setState({insights: e.target.value});
  }

  addSound = (sound: MediaInterface) => {
    let sounds = this.state.sounds
    sounds.push(sound)
    this.setState({sounds: sounds, showSound: false}, () => this.saveData('sounds'))
  }

  removeSound = (sound: MediaInterface) => {
    let sounds = []
    for (const s of this.state.sounds) {
      if (s.id !== sound.id) {
        sounds.push(s)
      }
    }
    this.setState({sounds: sounds}, () => this.saveData('sounds'))
  }

  addPicture = (picture: MediaInterface) => {
    this.state.pictures.push(picture)
    this.setState({pictures: this.state.pictures, showPicture: false}, () => this.saveData('pictures'))
  }

  removePicture = (picture: MediaInterface) => {
    let pictures = []
    for (const p of this.state.pictures) {
      if (p.id !== picture.id) {
        pictures.push(p)
      }
    }
    this.setState({pictures: pictures}, () => this.saveData('pictures'))
  }

  selectSound = () => {
    return (
      <div className="m-4">
        <div className="flex items-center justify-center">
          <img alt="music" className="icon_large cursor-pointer" src="/assets/icon/black/musical-notes-outline.svg"/>
          <h4 className="ml-4">Select sound</h4>
        </div>
        {
          this.sounds.map((sound: MediaInterface) => {
            if (this.mediaChosen(sound, this.state.sounds)) {
              return (
                <div key={"select_sound_" + sound.id} className="mt-4 gray">
                  {sound.name}
                </div>
              )
            } else {
              return (
                <div key={"select_sound_" + sound.id} className="mt-4 cursor-pointer underline"
                     onClick={() => this.addSound(sound)}>
                  {sound.name}
                </div>
              )

            }
          })
        }
      </div>
    )
  }

  addClip = (clip: MediaInterface) => {
    let clips = this.state.clips
    clips.push(clip)
    this.setState({clips: clips, showClip: false}, () => this.saveData('clips'))
  }

  removeClip = (clip: MediaInterface) => {
    let clips = []
    for (const c of this.state.clips) {
      if (c.id !== clip.id) {
        clips.push(c)
      }
    }
    this.setState({clips: clips}, () => this.saveData('clips'))
  }

  selectClip = () => {
    return (
      <div className="m-4 color_white">
        <div className="flex items-center justify-center">
          <img alt="music" className="icon_large cursor-pointer" src="/assets/icon/camera-on.svg"/>
          <h4 className="ml-4">Select video clip</h4>
        </div>
        {
          this.clips.map((clip: MediaInterface) => {
            if (this.mediaChosen(clip, this.state.clips)) {
              return (
                <div key={"select_clicp_" + clip.id} className="mt-4 gray">
                  {clip.name}
                </div>
              )
            } else {
              return (
                <div key={"select_clicp_" + clip.id} className="mt-4 cursor-pointer underline"
                     onClick={() => this.addClip(clip)}>
                  {clip.name}
                </div>
              )
            }
          })
        }
      </div>
    )
  }

  mediaChosen = (media: MediaInterface, medias: Array<MediaInterface>) => {
    for (const m of medias) {
      if (m.id === media.id) {
        return true
      }
    }
    return false
  }

  selectPicture = () => {
    return (
      <div className="m-4 color_white scroll_y">
        <div className="flex items-center justify-between">
          <h4 className="ml-4">{this.api.trTxt(TrVar.Select)} {this.api.trTxt(TrVar.Picture).toLowerCase()}</h4>
          <img alt="close" className="icon_medium cursor-pointer" src="/assets/icon/white/close-circle.svg"
               onClick={() => this.setState({showPicture: false})}/>
        </div>
        {
          this.pictures.map((picture: MediaInterface) => {
            if (this.mediaChosen(picture, this.state.pictures)) {
              return (
                <img className="vb_picture_small chosen" alt={picture.name} src={IMAGE_PATH + "thumbs/" + picture.url}
                     key={"select_picture_" + picture.id}/>
              )
            } else {
              return (
                <img className="vb_picture_small cursor-pointer" alt={picture.name}
                     src={IMAGE_PATH + "thumbs/" + picture.url}
                     key={"select_picture_" + picture.id}
                     onClick={() => this.addPicture(picture)}/>
              )
            }
          })
        }
      </div>
    )
  }

  // setSoundState = (sound: MediaInterface, playing: boolean) => {
  //   for (let s of this.state.sounds) {
  //     if (s.id === sound.id) {
  //       s.playing = playing
  //     }
  //   }
  //   this.setState({sounds: this.state.sounds})
  // }
  //
  // playSound = (sound: MediaInterface, play: boolean) => {
  //   const el = document.getElementById("sound_" + sound.id)
  //   if (!el) {
  //     console.log("Could not find audio element")
  //     return
  //   }
  //   const soundEl = el as HTMLAudioElement
  //   if (play) {
  //     soundEl.play()
  //   } else {
  //     soundEl.pause()
  //   }
  //   this.setSoundState(sound, play)
  // }
  //
  // restartSound = (sound: MediaInterface) => {
  //   const el = document.getElementById("sound_" + sound.id)
  //   if (!el) {
  //     console.log("Could not find audio element")
  //     return
  //   }
  //   const soundEl = el as HTMLAudioElement
  //   soundEl.currentTime = 0.0
  // }

  collapseText = (nr: number) => {
    const wideWindow = window.innerWidth >= 1100
    let c1 = this.state.collapse1
    let c2 = this.state.collapse2
    let c3 = this.state.collapse3
    let changedState = false
    if (nr === 1) {
      c1 = !c1
      changedState = c1
    } else if (nr === 2) {
      c2 = !c2
      changedState = c2
    } else if (nr === 3) {
      c3 = !c3
      changedState = c3
    }
    // If window is wide, collapse all boxes with any click
    if (wideWindow) {
      c1 = c2 = c3 = changedState
    }
    const collapseAll = (c1 && c2 && c3)
    this.setState({collapse1: c1, collapse2: c2, collapse3: c3, collapseTexts: collapseAll})
  }

  addEmojiGoal = (emoji: string) => {
    if (emoji) {
      this.setState({goal: this.state.goal + emoji, showEmojisGoal: false},
        () => this.saveData('goal'));
    } else {
      this.setState({showEmojisGoal: false});
    }
  }

  addEmojiInsights = (emoji: string) => {
    if (emoji) {
      this.setState({insights: this.state.insights + emoji, showEmojisInsights: false},
        () => this.saveData('insights'));
    } else {
      this.setState({showEmojisInsights: false});
    }
  }

  addEmojiNext = (emoji: string) => {
    if (emoji) {
      this.setState({nextStep: this.state.nextStep + emoji, showEmojisNext: false},
        () => this.saveData('nextStep'));
    } else {
      this.setState({showEmojisNext: false});
    }
  }

  render() {
    return (
      <>
      <div className="">
        <IonModal isOpen={this.state.showSound} onDidDismiss={() => this.setState({showSound: false})}>
          {this.selectSound()}
        </IonModal>
        <IonModal isOpen={this.state.showClip} onDidDismiss={() => this.setState({showClip: false})}>
          {this.selectClip()}
        </IonModal>
        <IonModal isOpen={this.state.showPicture} className="modal_centered"
                  onDidDismiss={() => this.setState({showPicture: false})}>
          {this.selectPicture()}
        </IonModal>
        <IonModal isOpen={this.state.showEmojisGoal} className="modal_medium"
                  onDidDismiss={() => this.setState({showEmojisGoal: false})}>
          <Emoji close={this.addEmojiGoal}/>
        </IonModal>
        <IonModal isOpen={this.state.showEmojisNext} className="modal_medium"
                  onDidDismiss={() => this.setState({showEmojisNext: false})}>
          <Emoji close={this.addEmojiNext}/>
        </IonModal>
        <IonModal isOpen={this.state.showEmojisInsights} className="modal_medium"
                  onDidDismiss={() => this.setState({showEmojisInsights: false})}>
          <Emoji close={this.addEmojiInsights}/>
        </IonModal>
        <div className="vb_flex justify-between">
          <div className="vb_top_col">
            <div className="flex justify-between items-center">
              <p className="bold">{this.api.lang === 'sv' ? 'Mål med dagens möte' : 'Goal of today\'s meeting'}</p>
              <img alt="toggle" className="icon_small cursor-pointer"
                   src={this.state.collapse1 ? '/assets/icon/black/chevron-down.svg' : '/assets/icon/black/chevron-up.svg'}
                   onClick={() => this.collapseText(1)}/>
            </div>
            {
              !this.state.collapse1 &&
              <div className="vb_textarea">
                <textarea className="vb_input_text mt-2" value={this.state.goal} onChange={this.handleGoal}
                          onKeyUp={this.keyUpGoal}
                        placeholder={this.api.trTxt(TrVar.WriteHere)}
                        onBlur={() => this.saveData('goal')}/>

                <div className="flex justify-between mt-1">
                  <p>{this.api.lang === 'sv' ? 'Nådde vi målet?' : 'Did we reach the goal?'}</p>
                  <img alt="smiley" onClick={() => this.setState({showEmojisGoal: true})}
                       src="/assets/icon/smiley.svg" className="icon_medium mr-2 cursor-pointer"/>
                </div>

                <div className="flex flex-1 items-center font_small">
                  <div className="flex items-center" onClick={() => this.setGoalOutcome(1)}>
                    <div className={"check_box small flex-shrink-0 " + (this.state.goalOutcome === 1 ? "bg_blue" : "")}/>
                    <p className="ml-2">{this.api.lang === 'sv' ? 'Ja' : 'Yes'}</p>
                  </div>
                  <div className="left_margin flex items-center" onClick={() => this.setGoalOutcome(2)}>
                    <div className={"check_box small flex-shrink-0 " + (this.state.goalOutcome === 2 ? "bg_blue" : "")}/>
                    <p className="ml-2">{this.api.lang === 'sv' ? 'Nej' : 'No'}</p>
                  </div>
                  <div className="left_margin flex items-center" onClick={() => this.setGoalOutcome(3)}>
                    <div className={"check_box small flex-shrink-0 " + (this.state.goalOutcome === 3 ? "bg_blue" : "")}/>
                    <p className="ml-2">{this.api.lang === 'sv' ? 'Delvis' : 'Partly'}</p>
                  </div>
                </div>
              </div>
            }

          </div>

          <div className="vb_top_col vb_second">
            <div className="flex justify-between items-center">
              <p className="bold">
                {this.api.lang === 'sv' ? 'Vad gör du konkret' :
                  'Which actions do you take'}
              </p>
              <img alt="toggle" className="icon_small cursor-pointer"
                   src={this.state.collapse2 ? '/assets/icon/black/chevron-down.svg' : '/assets/icon/black/chevron-up.svg'}
                   onClick={() => this.collapseText(2)}/>
            </div>
            {
              !this.state.collapse2 &&
              <div className="vb_textarea">
                <textarea className="vb_input_text mt-2" value={this.state.nextStep}
                          placeholder={this.api.trTxt(TrVar.WriteHere)}
                          onKeyUp={this.keyUpNextStep}
                          onChange={this.handleNextStep}  onBlur={() => this.saveData('nextStep')}/>
                <div className="text_right">
                  <img alt="smiley" onClick={() => this.setState({showEmojisNext: true})}
                       src="/assets/icon/smiley.svg" className="icon_medium mt-2 mr-2 cursor-pointer"/>
                </div>
              </div>
            }
          </div>

          <div className="vb_top_col vb_second">
            <div className="flex justify-between items-center">
              <p className="bold">
                {this.api.lang === 'sv' ? 'Vad tar du med dig' :
                  'Which are your key takeaways'}
              </p>
              <img alt="toggle" className="icon_small cursor-pointer"
                   src={this.state.collapse3 ? '/assets/icon/black/chevron-down.svg' : '/assets/icon/black/chevron-up.svg'}
                   onClick={() => this.collapseText(3)}/>
            </div>
            {
              !this.state.collapse3 &&
              <div className="vb_textarea">
                <textarea className="vb_input_text mt-2" value={this.state.insights}
                          placeholder={this.api.trTxt(TrVar.WriteHere)}
                          onKeyUp={this.keyUpInsights}
                          onChange={this.handleInsights} onBlur={() => this.saveData('insights')}/>
                <div className="text_right">
                  <img alt="smiley" onClick={() => this.setState({showEmojisInsights: true})}
                       src="/assets/icon/smiley.svg" className="icon_medium mt-2 mr-2 cursor-pointer"/>
                </div>
              </div>
            }
          </div>
        </div>



        <div className={"vb_media scroll_y top_margin bottom_margin_extra_large " + (this.state.collapseTexts ? "expand" : "")}>
          <div className="vb_add_media">
            {/*<div className="mx-4 cursor-pointer"*/}
            {/*     onClick={() => this.setState({showSound: !this.state.showSound})}>*/}
            {/*  <img alt="music" className="icon_medium cursor-pointer" src="/assets/icon/musical-notes-outline.svg"/>*/}
            {/*</div>*/}
            {/*<div className="mx-4 cursor-pointer">*/}
            {/*  <img alt="music" className="icon_large cursor-pointer" src="/assets/icon/happy-outline.svg"/>*/}
            {/*</div>*/}
            <div className="mx-4 cursor-pointer"
                 onClick={() => this.setState({showPicture: !this.state.showPicture})}>
              <img alt="music" className="icon_medium cursor-pointer" src="/assets/icon/black/images-outline.svg"/>
            </div>
            {/*<div className="mx-4 cursor-pointer"*/}
            {/*     onClick={() => this.setState({showClip: !this.state.showClip})}>*/}
            {/*  <img alt="music" className="icon_medium cursor-pointer" src="/assets/icon/camera.svg"/>*/}
            {/*</div>*/}
          </div>
          {
            this.state.sounds.map(sound => {
              return (
                <div className="vb_sound" key={"vb_sound_" + sound.id}>
                  <h4 className="mx-4">{sound.name}</h4>
                  <img alt="delete" className="icon_medium vb_delete" src="/assets/icon/black/close-circle.svg"
                       onClick={() => this.removeSound(sound)}/>
                  <audio controls id={"sound_" + sound.id} className="mt-4">
                    <source src={sound.url} type="audio/mpeg" />
                  </audio>
                  {/*<img className="vb_play" alt="pause" src="/assets/icon/play-skip-back.svg"*/}
                  {/*     onClick={() => this.restartSound(sound)}/>*/}
                  {/*{*/}
                  {/*  sound.playing ? (*/}
                  {/*    <img className="vb_play" alt="pause" src="/assets/icon/pause.svg"*/}
                  {/*         onClick={() => this.playSound(sound, false)}/>*/}
                  {/*  ) : (*/}
                  {/*    <img className="vb_play" alt="play" src="/assets/icon/play.svg"*/}
                  {/*         onClick={() => this.playSound(sound, true)}/>*/}
                  {/*  )*/}
                  {/*}*/}
                </div>
              )
            })
          }

          {
            this.state.clips.map(clip => {
              return (
                <div className="vb_clip" key={"vb_clip_" + clip.id}>
                  <h4 className="mx-4">{clip.name}</h4>
                  <img alt="delete" className="icon_medium vb_delete" src="/assets/icon/black/close-circle.svg"
                       onClick={() => this.removeClip(clip)}/>
                  <video controls muted={false} id={"clip_" + clip.id} className="vb_clip_video">
                    <source src={clip.url} type="video/mp4" />
                  </video>
                </div>
              )
            })
          }

          {
            this.state.pictures.map(picture => {
              return (
                <div className="vb_picture" key={"vb_picture_" + picture.id}>
                  <img alt={picture.name} src={IMAGE_PATH + picture.url} className="vb_border_radius"/>
                  <img alt="delete" className="icon_medium vb_delete" src="/assets/icon/black/close-circle.svg"
                       onClick={() => this.removePicture(picture)}/>
                </div>
              )
            })
          }
        </div>
        </div>
      </>
    )
  }
}
