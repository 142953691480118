import React, {Component} from 'react';
import {
  IonContent, IonModal,
  IonPage,
} from '@ionic/react';

import {RouteComponentProps} from "react-router";
import {arrangeVideos} from "./placeVideos"
import {openSocket, waitForSocket, sendSocket, sendSocketError, connectMeeting, closeSocket} from "./socket"
import {formatSoon} from "../../helpers/dateFunctions"
import {
  createLocalInfoDiv,
  createVideoContainer,
  createVideoElement,
  replaceTrackToPeer,
  startShareScreen,
  stopShareScreen,
  unmountVideo,
} from "./peer";
import {
  ResourcesInterface,
  PartnerInterface,
  DeviceInterface,
  MeetingInfoInterface, ERRORS
} from "./interfaces";
import './Video.css';
import {connectCanvasStream, startBackground, stopBackground, bgImages} from "./backgroundImage";
import ChatWindow from "../../components/ChatWindow";
import {ChatInterface} from "../../interfaces/Chat";
import Api from "../../services/Api";
import {CONFIG} from "../../constants";
import VideoHelp from "../../components/info/VideoHelp";
import CalendarClient from "../../components/client/CalendarClient";
import VisionBoard from "../../components/visionboard/VisionBoard";
import CalendarCoach from "../../components/coach/CalendarCoach";
import CoachNotes from "../../components/coach/CoachNotes";
import TestSound from "./TestSound";
import AccessHelp from "../help/AccessHelp";
import { TrVar } from "../../services/translate";

interface ComponentProps extends RouteComponentProps<{
  meetingKey: string
  who: string
  name: string
  externalId?: string
}> {}

interface ComponentState {
  meetingInfo: MeetingInfoInterface
  videoCompatible: boolean
  screenShareCompatible: boolean
  showVisionBoard: boolean
  meetingId: number
  showMenu: boolean
  menuSection: string
  showHelp: boolean
  showInfo: boolean
  showNotes: boolean
  showInfoLink: string
  showAddBooking: boolean
  showCoachCalendar: boolean
  info: string
  videoDeviceId: string
  videoGroupId: string
  audioDeviceId: string
  audioGroupId: string
  audioOutDeviceId: string
  cameraOn: boolean
  micOn: boolean
  shareScreen: boolean
  chatOpen: boolean
  useCanvas: boolean
  chatMessages: Array<ChatInterface>
  showAccessHelp: boolean
  innerWidth: number
  testSpeakers: boolean
  fullscreen: boolean
}

export default class Video extends Component<ComponentProps, ComponentState> {

  private api = Api.getInstance()
  resources: ResourcesInterface
  resizeFinished: any
  infoText: string
  userId: string
  userName: string
  externalMeeting: boolean
  localOpened: boolean
  devicesChanging: boolean

  constructor(props: ComponentProps) {
    super(props);
    this.localOpened = false
    this.devicesChanging = false
    let userId: string
    if (this.props.match.params.who === 'external') {
      userId = 'external_' + this.props.match.params.externalId
      this.externalMeeting = true
    } else {
      userId = this.props.match.params.who + '_' + window.localStorage[CONFIG.PERSON_ID]
      this.externalMeeting = false
    }
    this.infoText = ''
    this.userId = userId
    this.userName = this.props.match.params.name
    this.state = {
      meetingInfo: {} as MeetingInfoInterface,
      videoCompatible: !!navigator.mediaDevices,
      screenShareCompatible: 'getDisplayMedia' in navigator.mediaDevices,
      meetingId: 0,
      showMenu: false,
      menuSection: window.innerWidth < 800 ? '' : 'mic',
      showHelp: false,
      showInfo: false,
      showNotes: false,
      showVisionBoard: false,
      showInfoLink: '',
      showAddBooking: false,
      showCoachCalendar: false,
      info: '',
      videoDeviceId: 'unset',
      videoGroupId: 'unset',
      audioDeviceId: 'unset',
      audioGroupId: 'unset',
      audioOutDeviceId: 'unset',
      cameraOn: false,
      micOn: false,
      shareScreen: false,
      useCanvas: false,
      chatOpen: false,
      chatMessages: [] as Array<ChatInterface>,
      showAccessHelp: false,
      innerWidth: window.innerWidth,
      testSpeakers: false,
      fullscreen: false,
    }
    this.resources = {
      videoDevices: [],
      audioDevices: [],
      audioOutDevices: [],
      partners: [] as Array<PartnerInterface>,
      videoContainerRef: React.createRef(),
      userId: this.userId,
      userName: this.userName,
      who: this.props.match.params.who,
      webSocket: undefined,
      webSocketHasOpened: false,
      meetingKey: this.props.match.params.meetingKey,
      stateFunc: this.stateFunc,
      stateGet: this.stateGet,
      errorFunc: this.errorFunc,
      small: false,
      background: '',
      changeBackground: false,
      webSocketPingInterval: null,
      rtcStatus: 'waiting',
      rtcHasConnected: false,
      skipTurn: document.location.search.indexOf('skipTurn') > -1,
      gotInputDevices: false
    }
  }

  async componentDidMount() {

    this.displayInfo(this.api.lang === 'sv' ? 'Startar nätverk...' : 'Starting network...', true)
    openSocket(this.resources, this.userId, this.userName)

    const socketOpened = await waitForSocket(this.resources)

    if (!socketOpened) {
      if (this.resources.webSocket) {
        if (this.resources.webSocket.readyState === WebSocket.CLOSED) {
          console.log('-- websocket is closed ' + this.resources.webSocket.readyState)
        } else if (this.resources.webSocket.readyState === WebSocket.CONNECTING) {
          console.log('-- websocket is connecting ' + this.resources.webSocket.readyState)
        } else {
          console.log('websocket is in state ' + this.resources.webSocket.readyState)
        }
      }
      return
    }

    this.displayInfo(this.api.lang === 'sv' ? 'Startar kamera...' : 'Starting camera...', true)

    this.resources.gotInputDevices = await this.getInputDevices()

    this.displayInfo(this.api.lang === 'sv' ? 'Startar video...' : 'Starting video...', true)

    await this.startLocalVideo()

    this.setupMediaChange()

    window.addEventListener('resize', this.onResize)
    window.addEventListener('fullscreenchange', this.fullscreenChange)
  }

  getChatData = () => {
    this.api.get('chat/get/' + this.props.match.params.meetingKey).then(response => {
      const meetingInfo: MeetingInfoInterface = {
        meetingStart: response.json.meeting.start,
        meetingLength: response.json.meeting.length,
        clientId: response.json.meeting.client.id,
        clientName: response.json.meeting.client.firstName,
        clientPicture: response.json.meeting.client.picture,
        coachId: response.json.meeting.coach.id,
        coachName: response.json.meeting.coach.firstName,
        coachPicture: response.json.meeting.coach.picture,
      }
      let info = this.api.trTxt(TrVar.MeetingStarts)
      info += ': ' + formatSoon(meetingInfo.meetingStart, this.api.lang) + ', '
        + this.api.trTxt(TrVar.Length).toLowerCase() + ': ' + meetingInfo.meetingLength + ' ' + this.api.trTxt(TrVar.Minutes).toLowerCase()

      info += '\n\n' + this.api.trTxt(TrVar.WaitingFor) + ' '
      if (this.props.match.params.who === 'coach') {
        info += meetingInfo.clientName
      } else {
        info += meetingInfo.coachName
      }
      info += '...'
      const meetingStart = new Date(meetingInfo.meetingStart)
      const now = new Date()
      const secondsToStart = (meetingStart.getTime() - now.getTime()) / 1000
      if (secondsToStart > 3600) {
        info += '\n\n\n\n'
        if (this.api.lang === 'sv') {
          info += 'Obs! Det är långt kvar tills mötet startar.'
        } else {
          info += 'NB! It is a long time until meeting starts.'
        }
      }
      this.displayInfo(info,true)
      this.setState({
        chatMessages: response.json.chats,
        meetingId: response.json.meeting.id,
        meetingInfo: meetingInfo
      })
    })
  }

  fullscreen = () => {
    if (this.state.fullscreen) {
      document.exitFullscreen()
      this.setState({fullscreen: false})
    } else {
      const elem = document.getElementById("whole_window")
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
          this.setState({fullscreen: true})

          // } else if (elem.webkitRequestFullscreen) { /* Safari */
          //   elem.webkitRequestFullscreen();
          // } else if (elem.msRequestFullscreen) { /* IE11 */
          //   elem.msRequestFullscreen();
        }
      }
    }
  }

  fullscreenChange = (event: any) => {
    setTimeout(() => {
      if (!document.fullscreenElement && this.state.fullscreen) {
        this.setState({fullscreen: false})
      }
    }, 100)
  }

  hangup = () => {
    if (window.opener) {
      window.opener.console.log('From opened window')
      console.log('----- Closing window')
      window.opener.postMessage('video_window_close')
      // Catcher in the rye - if window.opener has gone away
      setTimeout(() => {
        window.location.href = '/app/' + this.props.match.params.who + '/overview'
      }, 300)
    } else {
      if (this.props.match.params.externalId) {
        window.location.href = '/app/external_meeting_closed/' + this.props.match.params.who + '/' +
          this.props.match.params.name + '/' + this.props.match.params.meetingKey + '/' +
          this.props.match.params.externalId
      } else {
        if (this.props.history.length > 2) {
          this.props.history.goBack()
        } else {
          window.location.href = '/app/' + this.props.match.params.who + '/overview'
        }
      }
    }
  }

  setupMediaChange = () => {
    navigator.mediaDevices.ondevicechange = () => {
      console.log('-------- media device change')
      if (this.devicesChanging) {
        console.log('---- warning: devices are in changing state')
        return
      }
      this.devicesChanging = true
      // const audioDeviceId = this.state.audioDeviceId
      // const videoDeviceId = this.state.videoDeviceId
      const audioGroupId = this.state.audioGroupId
      const videoGroupId = this.state.videoGroupId

      this.getInputDevices().then(() => {
        if (this.localOpened) {
          if (audioGroupId !== this.state.audioGroupId || videoGroupId !== this.state.videoGroupId) {
            console.log('Different audio or video')
            if (audioGroupId !== this.state.audioGroupId) {
              console.log('Different audio: ' + audioGroupId + ' !== ' + this.state.audioGroupId)
              this.stopLocalStream('audio')
            }
            if (videoGroupId !== this.state.videoGroupId) {
              console.log('Different video: ' + videoGroupId + ' !== ' + this.state.videoGroupId)
              this.stopLocalStream('video')
            }

            this.changeLocalVideo()
            console.log('Changed input stream')
          }
        }
        this.devicesChanging = false
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('fullscreenchange', this.fullscreenChange)
    closeSocket(this.resources)
    unmountVideo(this.resources)
  }

  errorFunc = (error: ERRORS) => {
    let errorText = ''
    switch(error) {
      case ERRORS.WEBSOCKET_FAILED_OPEN: {
        errorText = 'Failed opening connection to Zebrain system.\nPlease check your internet connection.\n' +
          'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_OPEN: {
        errorText = 'Lost connection to Zebrain system (1).\nPlease check your internet connection.\n' +
          'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_CLOSED: {
        errorText = 'Websocket is closed'
        break
      }
      case ERRORS.WEBSOCKET_EVENT_CLOSED: {
        errorText = 'Lost connection to Zebrain system (2).\nPlease check your internet connection.\n' +
          'If it works, try reloading this page.'
        break
      }
      case ERRORS.WEBSOCKET_NOT_AVAILABLE: {
        errorText = 'Websocket has died :-(\nTry reloading the page, ' +
          'and if that doesn\'t help, contact support@zebrain.se'
        break
      }
      case ERRORS.RTC_CONNECTION_STATE_FAILED: {
        if (this.resources.rtcHasConnected) {
          errorText = 'Lost video connection. This is probably due to a network error between you and meeting partner.\n'
          errorText += 'Check your internet connection. If it works, try reloading this page.\n'
          errorText += 'If that doesn\'t work, the problem could be with your partner\'s network.\n\n'
          errorText += 'If nothing works, contact support@zebrain.se and we will try to help.'
        } else {
          errorText = 'Failed connecting video stream. This is probably due to a firewall issue.\n'
          errorText += '\nSend a mail to support@zebrain.se and we will help.'
        }

        break
      }
      case ERRORS.RTC_NEGOTIATION_SLOW: {
        errorText = 'If your cannot see your meeting partner within ten seconds, try these steps:\n\n' +
          '1. Exit meeting and join again\n\n' +
          '2. If that doesn\'t work, restart your browser and join meeting again\n\n' +
          '3. If that doesn\'t work, contact support'
        break
      }
      default: {
        errorText = 'Unknown error: ' + error
        break
      }
    }

    this.displayInfo(errorText, true)
  }

  stateFunc = (key: string, value: any) => {
    if (key === 'shareScreen') {
      this.setState({shareScreen: value})
    } else if (key === 'showInfo') {
      this.setState({showInfo: value})
    } else if (key === 'info') {
      this.setState({info: value})
    } else if (key === 'infoText') {
      this.infoText = value
    } else if (key === 'info') {
      this.setState({info: value})
    } else if (key === 'menuSection') {
      this.setState({menuSection: value})
    } else if (key === 'arrangeVideos') {
      arrangeVideos(this.resources)
    } else if (key === 'chat') {
      this.receiveChat(value)
    } else {
      console.log('!!! Unknown key to stateFunc: ' + key)
    }
  }

  stateGet = (key: string) => {
    if (key === 'chatOpen') {
      return this.state.chatOpen
    } else if (key === 'showNotes') {
      return this.state.showNotes
    } else if (key === 'showVisionBoard') {
      return this.state.showVisionBoard
    }
    return null
  }

  // Local video methods

  asyncSetDevices(audioId: string, videoId: string, audioGroupId: string, videoGroupId: string) {
    return new Promise((resolve) => {
      this.setState({
        videoDeviceId: videoId,
        audioDeviceId: audioId,
        audioGroupId: audioGroupId,
        videoGroupId: videoGroupId
      }, () => {
        resolve(true);
      })
    })
  }

  async getInputDevices() {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices: Array<DeviceInterface> = []
    const audioDevices: Array<DeviceInterface> = []
    const audioOutDevices: Array<DeviceInterface> = []
    for (let device of mediaDevices) {
      if (device.kind === 'audioinput') {
        audioDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      } else if (device.kind === 'videoinput') {
        videoDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId
        })
      } else if (device.kind === 'audiooutput') {
        audioOutDevices.push({
          id: device.deviceId,
          label: device.label,
          groupId: device.groupId,
        })
      }
    }

    this.resources.audioDevices = audioDevices
    this.resources.videoDevices = videoDevices
    this.resources.audioOutDevices = audioOutDevices

    if (audioDevices.length > 0 && audioDevices[0].id !== ''
      && videoDevices.length > 0 && videoDevices[0].id !== '') {
      await this.asyncSetDevices(
        this.resources.audioDevices[0].id,
        this.resources.videoDevices[0].id,
        this.resources.audioDevices[0].groupId,
        this.resources.videoDevices[0].groupId
      )
      return true
    }
    return false
  }

  getConstraints = () => {
    let video:any = this.state.videoDeviceId === 'unset' ? true : {deviceId: {exact: this.state.videoDeviceId}}
    // HD Debugging. Provoke a tall video stream, as from a mobile camera
    // if (this.resources.who === 'client') {
    //   video = { width: 480, height: 640}
    // }
    const audio = this.state.audioDeviceId === 'unset' ? true : {deviceId: {exact: this.state.audioDeviceId}}
    return {
      video: video,
      audio: audio
    }
  }

  startLocalVideo() {

    this.resources.videoContainer = createVideoContainer(this.resources.videoContainerRef.current)
    this.resources.videoElement = createVideoElement(this.resources.videoContainer)
    this.resources.videoElement.classList.add('video_mirrored')

    const constraints = this.getConstraints()

    if (!this.resources.gotInputDevices) {
      this.displayInfo('Waiting for camera access approval',true)
    }

    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
      if (this.api.loggedIn && !this.externalMeeting) {
        this.displayInfo('Looking up meeting information',true)
        this.getChatData()
      } else {
        this.setState({showInfo: false})
      }
      if (this.resources.videoContainer) {
        createLocalInfoDiv(this.resources, this.resources.videoContainer, this.displayInfo)
      }

      this.resources.localStream = localStream
      // In case we could not get input devices due to allow access dialogue, run getInputDevices again
      if (this.state.videoDeviceId === 'unset' || this.state.audioDeviceId === 'unset') {
        this.getInputDevices().then(result => {
          this.resources.gotInputDevices = result
        })
      }
      try {
        // If user changed mic or camera, respect if the turned off camera or mic

        if (this.resources.videoElement) {
          this.resources.videoElement.onloadedmetadata = () => {
            this.localOpened = true
            arrangeVideos(this.resources)
            this.toggleCamera()
            this.toggleMic()
            connectMeeting(this.resources)
          }

          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after this.resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          this.resources.videoElement.muted = true
          this.resources.videoElement.srcObject = localStream
        } else {
          alert('Failed getting videoElement')
          sendSocketError('No video element', this.resources)
        }


      } catch (error) {
        this.displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
        sendSocketError('Failed opening video', this.resources)
      }
    }).catch(err => {
      // this.displayInfo('Could not connect to your camera. Follow the link below to solve the problem',
      //   true, '/app/help/camera')
      this.setState({showAccessHelp: true})
      sendSocketError('Failed getUserMedia', this.resources)
    })
  }

  changeLocalVideo() {
    const constraints = this.getConstraints()
    try {
      navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
        this.resources.localStream = localStream
        // if (this.resources.videoElement) {
        //   this.resources.videoElement.srcObject = localStream
        // }
        if (this.resources.videoElement) {
          // If user changed mic or camera, respect if the turned off camera or mic
          if (!this.state.micOn || !this.state.cameraOn) {
            this.resources.localStream?.getTracks().forEach((track: MediaStreamTrack) => {
              if (track.kind === 'video') {
                track.enabled = this.state.cameraOn
              } else if (track.kind === 'audio') {
                track.enabled = this.state.micOn
              }
            })
          }
          this.resources.videoElement.onloadedmetadata = () => {
            if (this.state.useCanvas) {
              connectCanvasStream(this.resources)
            } else {
              replaceTrackToPeer(this.resources)
            }
          }
          // Move the allocation of localStream to after listening to loadedmetadata. Suspicion is that some browsers
          // fire the event after this.resources.videoElement.srcObject = localStream but before
          // loademetadata-listener is in place (coach Paula saw this with Chrome on Windows.
          this.resources.videoElement.muted = true
          this.resources.videoElement.srcObject = localStream
        }

      }).catch(err => {
        this.setState({showAccessHelp: true})
        sendSocketError('Failed getUserMedia', this.resources)
      })
    } catch (error) {
      this.displayInfo('Could not open video.\n\nReload the page, and if that does not help - contact support',
          true)
      sendSocketError('Failed opening video', this.resources)
    }
  }

  onResize = () => {
    clearTimeout(this.resizeFinished);
    this.resizeFinished = setTimeout(() => {
      this.setState({innerWidth: window.innerWidth})
      arrangeVideos(this.resources)
    }, 300);
  }

  stopLocalStream = (kind: string) => {
    if (this.resources.localStream) {
      this.resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === kind && track.readyState === 'live') {
          console.log('Change, stopping previous track ' + track.kind)
          track.stop()
        }
      })
    }
  }

  changeVideo = (deviceId: string) => {
    if (deviceId === this.state.videoDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    this.stopLocalStream('video')

    this.setState({videoDeviceId: deviceId, showMenu: false}, () => this.changeLocalVideo())
  }

  changeAudio = (deviceId: string) => {
    if (deviceId === this.state.audioDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }
    this.stopLocalStream('audio')

    this.setState({audioDeviceId: deviceId}, () => this.changeLocalVideo())
    console.log('Changed to ' + deviceId)
  }

  changeOutAudio = (deviceId: string) => {
    if (deviceId === this.state.audioOutDeviceId) {
      console.log('already selected ' + deviceId)
      return
    }

    if (this.resources.videoElement && 'setSinkId' in HTMLMediaElement.prototype) {
      this.setState({audioOutDeviceId: deviceId})
      // @ts-ignore
      this.resources.videoElement.setSinkId(deviceId)
      // @ts-ignore
      this.soundFile.setSinkId(deviceId)
      console.log('Changed to ' + deviceId)
    }

  }

  displayInfo = (info: string, reset: boolean, link?: string) => {
    if (reset) {
      this.infoText = info
    } else {
      this.infoText += '\n' + info
    }
    let showInfoLink = link ? link : ''
    this.setState({showInfo: true, info: this.infoText, showInfoLink: showInfoLink})
  }

  toggleCamera = () => {
    const newState = !this.state.cameraOn
    if (this.resources.localStream) {
      this.resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'video') {
          track.enabled = newState
          this.setState({cameraOn: newState})
        }
      })
    }
  }

  toggleMic = () => {
    const newState = !this.state.micOn
    if (this.resources.localStream) {
      this.resources.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (track.kind === 'audio') {
          track.enabled = newState
          this.setState({micOn: newState})
        }
      })
    }
  }

  toggleNotes = () => {
    this.setState({showNotes: !this.state.showNotes}, () => arrangeVideos(this.resources))
  }

  closeNotes = () => {
    this.setState({showNotes: false}, () => arrangeVideos(this.resources))

  }

  toggleChat = (chatOpen: boolean) => {
    this.setState({chatOpen: chatOpen}, () => {
      arrangeVideos(this.resources)
    })
  }

  toggleVisionBoard = () => {
    this.setState({chatOpen: false, showVisionBoard: !this.state.showVisionBoard}, () => {
      arrangeVideos(this.resources)
    })
  }

  toggleShareScreen = () => {
    const shareScreen = !this.state.shareScreen
    if (shareScreen) {
      startShareScreen(this.resources)
    } else {
      stopShareScreen(this.resources)
    }
    // this.setState({shareScreen: shareScreen})
  }

  toggleCanvas = (background: string) => {
    const useCanvas = background.length > 0
    const changeCanvas = this.state.useCanvas !== useCanvas
    this.resources.background = background
    this.resources.changeBackground = true
    this.setState({useCanvas: useCanvas, showMenu: false})
    if (changeCanvas) {
      if (useCanvas) {
        startBackground(this.resources)
      } else {
        stopBackground(this.resources)
        replaceTrackToPeer(this.resources)
        setTimeout(() => {
          // Allow for canvas to be removed
          arrangeVideos(this.resources)
        }, 200)

      }
    }
  }

  sendChat = (message: string) => {

    const data = {
      message: message,
    }

    if (this.api.loggedIn && !this.externalMeeting) {
      this.api.post('chat/save/' + this.props.match.params.meetingKey, data)
    }

    const chatMessages = this.state.chatMessages
    chatMessages.push({
      id: 0,
      sender: 'you',
      senderName: this.api.trTxt(TrVar.You),
      message: message,
      createdAt: this.api.trTxt(TrVar.Now)
    })
    this.setState({chatMessages: chatMessages})

    if (this.resources.partners.length > 0) {
      const sendMess = {
        type: 'meeting',
        command: 'chat',
        message: message,
        user_name_from: this.userName
      }
      sendSocket(sendMess, this.resources)

      // this.toggleChat(true)
    } else {
      this.setState({showInfo: true, info: this.api.trTxt(TrVar.NoneToChatWith)})
    }
  }

  receiveChat = (message: any) => {
    const chatMessages = this.state.chatMessages
    chatMessages.push({
      id: 0,
      sender: 'other',
      senderName: message.user_name_from,
      message: message.message,
      createdAt: 'Nu'
    })
    this.setState({chatMessages: chatMessages})
    this.toggleChat(true)
  }

  sendVisionBoard = (data: any) => {

    if (this.resources.partners.length > 0) {
      const sendMess = {
        type: 'meeting',
        command: 'visionBoard',
        data: data,
        user_name_from: this.userName
      }
      sendSocket(sendMess, this.resources)
    }
  }

  showMenuLeft = (small: boolean) => {
    return (
      <div className="video_menu_left">
        <div className="flex flex-row justify-between items-center top_left_margin">
          <div className="">
            <h3>{this.api.lang === 'sv' ? 'Inställningar' : 'Settings'}</h3>
          </div>
          {
            small &&
              <div className="flex justify-end cursor-pointer mr-4"
                   onClick={() => this.setState({showMenu: false})}>
                  <img className="icon_small" src="/assets/icon/white/cross.svg" alt="close"/>
              </div>
          }
        </div>

        <div className={"video_menu_box mt-4 " + (this.state.menuSection === 'mic' ? 'selected' : '')}>
          <div className="flex items-center"
               onClick={() => this.setState({menuSection: 'mic'})}>
            <img src="/assets/icon/video_icon/mic.svg" alt="sound" className="video_menu_icon"/>
            <p>{this.api.trTxt(TrVar.Microphone)}</p>
          </div>
        </div>

        <div className={"video_menu_box " + (this.state.menuSection === 'loudspeakers' ? 'selected' : '')}>
          <div className="flex items-center"
               onClick={() => this.setState({menuSection: 'loudspeakers'})}>
            <img src="/assets/icon/white/volume.svg" alt="sound" className="video_menu_icon"/>
            <p>{this.api.lang === 'sv' ? 'Högtalare' : 'Loudspeakers'}</p>
          </div>
        </div>

        {
          this.resources.videoDevices.length > 1 &&
            <div className={"video_menu_box " + (this.state.menuSection === 'video' ? 'selected' : '')}>
              <div className="flex items-center" onClick={() => this.setState({menuSection: 'video'})}>
                <img src="/assets/icon/white/camera.svg" alt="camera" className="video_menu_icon"/>
                <p>Video</p>
              </div>
            </div>
        }

        <div className={"video_menu_box " + (this.state.menuSection === 'background' ? 'selected' : '')}>
          <div className="flex items-center" onClick={() => this.setState({menuSection: 'background'})}>
            <img src="/assets/icon/white/image-outline.svg" alt="mic" className="video_menu_icon"/>
            <p>{this.api.trTxt(TrVar.Background)}</p>
          </div>
        </div>

        {
          this.props.match.params.who === 'client' &&
            <div className="video_menu_box flex items-center"
                 onClick={() => this.setState({showAddBooking: !this.state.showAddBooking, showMenu: false})}>
                <img src="/assets/icon/calendar.svg" alt="calendar" className="svg_white video_menu_icon"/>
                <p>{this.api.trTxt(TrVar.BookNewMeeting)}</p>
            </div>
        }
        {
          this.props.match.params.who === 'coach' &&
            <div className="video_menu_box flex items-center"
                 onClick={() => this.setState({showCoachCalendar: !this.state.showCoachCalendar, showMenu: false})}>
                <img src="/assets/icon/calendar.svg" alt="calendar" className="svg_white video_menu_icon"/>
                <p>{this.api.trTxt(TrVar.HandleMeetingTimes)}</p>
            </div>
        }
        <div className={"video_menu_box flex items-center" + (this.state.menuSection === 'background' ? 'selected' : '')}
             onClick={() => this.setState({menuSection: 'help'})}>
          <img src="/assets/icon/white/help-circle.svg" alt="help" className="video_menu_icon tiny_icon"/>
          <p>{this.api.trTxt(TrVar.Help)}</p>
        </div>
      </div>
    )
  }

  showMenuRight = (small: boolean) => {
    return (
      <div className="video_menu_right">
        {
          small ? (
            <div className="flex justify-between m-4">
              <h3 onClick={() => this.setState({menuSection: ''})}>
                &lt;&nbsp;{this.api.trTxt(TrVar.Back)}
              </h3>
              <div className="text_right w-full cursor-pointer" onClick={() => this.setState({showMenu: false})}>
                <img src="/assets/icon/white/cross.svg" alt="close" className="icon_small" />
              </div>
            </div>
          ) : (
            <div className="flex justify-end cursor-pointer" onClick={() => this.setState({showMenu: false})}>
              <img src="/assets/icon/white/cross.svg" alt="close" className="icon_small m-4" />
            </div>
          )
        }

        <div className="mx-4">
        {
          this.showMenuSection()
        }
        </div>
      </div>
    )
  }
  soundFile = new Audio('/assets/sound/creativeminds.mp3')
  testSpeakers = () => {
    this.setState({ testSpeakers: !this.state.testSpeakers })

    this.soundFile.paused ? this.soundFile.play() : this.soundFile.pause();
  }

  speakertext = () => {
    if (this.state.testSpeakers) {
      return (
        this.api.lang === 'sv' ?
        'Spelar upp musik...'
        : 
        `Playing music...`)
    } else {
      return (
      this.api.lang === 'sv' ?
        'Testa dina högtalare'
        : 
        `Test your speakers`)
    }
  }

  showMenuSection = () => {
    if (this.state.menuSection === 'mic') {
      return (
        <div>
          <h3>{this.api.trTxt(TrVar.Microphone)}</h3>
          {
            this.resources.audioDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={this.state.audioDeviceId}
                      onChange={(e) => this.changeAudio(e.target.value)}>
                {
                  this.resources.audioDevices.map((device, index) => {
                    return (
                      <option key={"audio_" + index} value={device.id}>
                          {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
          <TestSound resources={this.resources} micOn={this.state.micOn} audioGroupId={this.state.audioGroupId}/>
        </div>
      )
    } else if (this.state.menuSection === 'loudspeakers') {
      return (
        <div>
          <h3>{this.api.lang === 'sv' ? 'Högtalare' : 'Speakers'}</h3>
          {
            this.resources.audioOutDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={this.state.audioOutDeviceId}
                      onChange={(e) => this.changeOutAudio(e.target.value)}>
                {
                  this.resources.audioOutDevices.map((device, index) => {
                    return (
                      <option key={"audio_" + index} value={device.id}>
                        {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
          <div className="top_margin">
            <div onClick={() => this.testSpeakers()}  className="flex">
              <div className={'video_test_sound ' + (this.state.testSpeakers ? 'on' : 'off')} />
              <div style={{ marginLeft: '5px' }} className='align-center'>
                {this.speakertext()}
              </div>
            </div>
            <div style={{ fontStyle: 'italic' }} className="top_margin font_small">
              { this.api.lang === 'sv' ?
                'Om du hör musik spelas, så fungerar dina högtalare'
                :
                `If you hear music, your speakers are working.`
              }
            </div>

            <h4 className="top_margin">
              {this.api.lang === 'sv' ? 'Hör du inget ljud?' : `Can't hear any sound?`}
            </h4>
            <div className="top_margin font_small">
              {this.api.lang === 'sv' ? 'Kontrollera att dina högtalare är på' : `Check if your speakers are on.`}
            </div>
          </div>
        </div>
      )
    } else if (this.state.menuSection === 'video') {
      return (
        <div>
          <h3>Video</h3>
          {
            this.resources.videoDevices.length > 1 &&
              <select className="mt-4 page_select w100" value={this.state.videoDeviceId}
                      onChange={(e) => this.changeVideo(e.target.value)}>
                {
                  this.resources.videoDevices.map((device, index) => {
                    return (
                      <option key={"video_" + index} value={device.id}>
                        {device.label}
                      </option>
                    )
                  })
                }
              </select>
          }
        </div>
      )
    } else if (this.state.menuSection === 'background') {
      return (
        <div>
          <h3>{this.api.trTxt(TrVar.Background)}</h3>

          <select className="mt-4 page_select w100" value={this.resources.background}
                  onChange={(e) => this.toggleCanvas(e.target.value)}>
            {
              bgImages.map((bgImage, index) => {
                return (
                  <option key={"bg_" + index} value={bgImage.image}>
                    {this.api.lang === 'sv' ? bgImage.name_sv : bgImage.name_en}
                  </option>
                )
              })
            }
          </select>
        </div>
      )
    } else if (this.state.menuSection === 'help') {
      return (
        <div>
          <h3>{this.api.trTxt(TrVar.Help)}</h3>
          <VideoHelp who={this.resources.who} lang={this.api.lang} resources={this.resources}/>
        </div>
      )
    } else {
      return (<div>Missing content for {this.state.menuSection}</div>)
    }
  }

  showMenu = () => {
    const small = this.state.innerWidth < 800
    let showLeft = true
    let showRight = true

    if (small) {
      if (this.state.menuSection === '') {
        showRight = false
      } else {
        showLeft = false
      }
    }
    return (
      <div className="video_menu_page flex">
        {
          showLeft && this.showMenuLeft(small)
        }
        {
          showRight && this.showMenuRight(small)
        }
      </div>
    )
  }

  
  render() {

    if (!this.state.videoCompatible) {
      return (
        <IonPage>
          <IonContent>
            <div className="m-4">Your browser is not compatible with video conferences</div>
          </IonContent>
        </IonPage>
      )
    }

    if (this.state.showAccessHelp) {
      return (
        <IonPage>
          <IonContent>
            <AccessHelp/>
          </IonContent>
        </IonPage>
      )
    }

    return (
      <IonPage>
        <IonContent className="app_bg" id="whole_window">
          {
            this.state.chatOpen &&
            <ChatWindow onChat={this.sendChat} chatMessages={this.state.chatMessages}
              isSendEnabled={true} showSender={true} who={this.props.match.params.who} videoChat={true} 
              onToggleOpen={() => this.toggleChat(!this.state.chatOpen)}
            />
          }
          {
            this.state.showNotes &&
              <div className="video_notes">
                <CoachNotes clientId={this.state.meetingInfo.clientId} close={this.closeNotes}/>
              </div>
          }
          {
            (this.state.showVisionBoard) &&
              <div className="video_vision_board">
                  <VisionBoard meetingId={this.state.meetingId} save={this.sendVisionBoard}
                               webSocket={this.resources.webSocket}/>
              </div>
          }

          <IonModal isOpen={this.state.showAddBooking} onDidDismiss={() => this.setState({showAddBooking: false})}
            className="modal_full">
            <CalendarClient close={() => this.setState({showAddBooking: false})} fromVideo={true} />
          </IonModal>
          <IonModal isOpen={this.state.showCoachCalendar} onDidDismiss={() => this.setState({showCoachCalendar: false})}
                    className="modal_full">
            <CalendarCoach close={() => this.setState({showCoachCalendar: false})} fromVideo={true} />
          </IonModal>

          <div ref={this.resources.videoContainerRef}/>

          {
            this.state.showInfo &&
            <div className="video_info_container">
              <div className="video_info">
                <div className="video_close_div" onClick={() => this.setState({showInfo: false})}>
                    <img src="/assets/icon/white/cross.svg" alt="close" className="icon_small" />
                </div>
                <div className="whitespace-pre-line">
                  {this.state.info}
                </div>
                {
                  this.state.showInfoLink &&
                    <div className="mt-4">
                        <a href={this.state.showInfoLink} target="_new">More information</a>
                    </div>
                }
              </div>
            </div>
          }

          {
            this.state.showMenu &&
            this.showMenu()
          }

          <div className="video_footer flex items-center justify-center">
            <div className="flex justify-evently">
              <div className="cursor-pointer video_footer_icon" onClick={() => this.toggleChat(!this.state.chatOpen)}>
                <img alt="chat" className="video_icon"
                     title={this.state.chatOpen ? this.api.trTxt(TrVar.CloseChat) : this.api.trTxt(TrVar.OpenChat)}
                     src={"/assets/icon/video_icon/chat-" + (this.state.chatOpen ? 'on' : 'off') + ".svg"} />
              </div>
              <div className="cursor-pointer video_footer_icon"
                   onClick={() => this.toggleVisionBoard()}>
                  <img alt="chat" className="video_icon"
                       title={this.api.trTxt(TrVar.MeetingNotes)}
                       src={"/assets/icon/video_icon/visionboard-" + (this.state.showVisionBoard ? 'on' : 'off') + ".svg"} />
              </div>

              <div className="cursor-pointer video_footer_icon" onClick={() => this.toggleCamera()}>
                <img title={this.state.cameraOn ? this.api.trTxt(TrVar.HideVideo) : this.api.trTxt(TrVar.ShowVideo)}
                     alt="camera" className="video_icon"
                     src={"/assets/icon/video_icon/camera-" + (this.state.cameraOn ? 'on' : 'off') + ".svg"} />
              </div>
              <div className="cursor-pointer video_footer_icon" onClick={() => this.toggleMic()}>
                <img alt="microphone" className="video_icon"
                     title={this.state.cameraOn ? this.api.trTxt(TrVar.MuteMic) : this.api.trTxt(TrVar.UnmuteMic)}
                     src={"/assets/icon/video_icon/mic-" + (this.state.micOn ? 'on' : 'off') + ".svg"} />
              </div>
              {
                (this.props.match.params.who === 'coach' && this.state.meetingInfo.clientId) &&
                <div className="cursor-pointer video_footer_icon" onClick={() => this.toggleNotes()}>
                    <img alt="notes" className="video_icon"
                         title={this.state.showNotes ? this.api.trTxt(TrVar.HideNotes) : this.api.trTxt(TrVar.ShowNotes)}
                         src={"/assets/icon/video_icon/notes-" + (this.state.showNotes ? 'on' : 'off') + ".svg"} />
                </div>
              }

              {
                this.state.screenShareCompatible &&
                <div className="cursor-pointer video_footer_icon" onClick={() => this.toggleShareScreen()}>
                  <img alt="present"  className="video_icon"
                       title={this.state.shareScreen ? this.api.trTxt(TrVar.StopPresenting) : this.api.trTxt(TrVar.StartPresenting)}
                       src={"/assets/icon/video_icon/screenshare-" + (this.state.shareScreen ? 'on' : 'off') + ".svg"} />
                </div>
              }
              <div className="cursor-pointer video_footer_icon" onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                <img alt="menu" className="video_icon"
                     src={"/assets/icon/video_icon/menu-" + (this.state.showMenu ? 'on' : 'off') + ".svg"} />
              </div>
              <div className="cursor-pointer video_footer_icon" onClick={() => this.hangup()}>
                <img title={this.api.trTxt(TrVar.LeaveMeeting)} alt="hangup" className="video_icon"
                     src="/assets/icon/video_icon/phone-off.svg" />
              </div>
              {
                this.state.innerWidth > 800 &&
                  <div className="cursor-pointer video_footer_icon round border_white p-1" onClick={() => this.fullscreen()}>
                    <img title="fullscreen" alt="fullscreen" className="p-2"
                         src={"/assets/icon/video_icon/fullscreen_" + (this.state.fullscreen ? "close": "open") + ".svg"} />
                  </div>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
};
