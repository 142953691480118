import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { connect, ConnectedProps } from 'react-redux';
import { tokenStore, tokenRemove, tokenChecked } from './redux/actions';
import { CONFIG } from './constants';
import { storeUrl } from './services/Navigation';
import Client from './pages/Client';
import ClientOnboarding from './pages/ClientOnboarding';
import CoachOnboarding from './pages/CoachOnboarding';
import Coach from './pages/Coach';
import WebSocketClient from './services/WebSocketClient';
import DisaTest from './pages/testing/DisaTest';
import ResetPassword from './pages/ResetPassword';
import Login from './components/login/Login';
import Start from './pages/Start';
import NotFoundPage from './pages/404/NotFoundPage';
import ClientInvitation from "./pages/ClientInvitation";
import Video from "./pages/openvideo/Video";
import VideoClosed from "./pages/openvideo/VideoClosed";
import TestMenu from "./pages/testing/TestMenu";
import TestTurn from "./pages/testing/TestTurn";
import CameraAccess from "./pages/help/CameraAccess";
import SignedOut from './pages/SignedOut/SignedOut';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import './theme/tailwind.css';
import './theme/fonts.css';
import CameraTest from "./pages/help/CameraTest";
import TestCalendar from "./pages/testing/TestCalendar";
import OpenBehaviour from "./pages/open/behaviour/OpenBehaviour";



const mapState = (state: any) => ({
  session: state.session,
  navigation: state.navigation,
});

const mapDispatch = {
  tokenStore,
  tokenRemove,
  tokenChecked,
};

const connector = connect(mapState, mapDispatch);

interface ComponentState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

setupIonicReact({
  mode: 'md',
});

class App extends React.Component<PropsFromRedux, ComponentState> {
  private webSocketClient = WebSocketClient.getInstance();

  componentDidMount() {
    storeUrl(window.location.pathname);
    this.checkToken();
  }

  checkToken = () => {
    const token = window.localStorage[CONFIG.TOKEN_NAME];
    if (token) {
      this.props.tokenStore(token);
    } else {
      this.props.tokenChecked();
    }
  };

  requiresLogin = () => {
    const urlParts = window.location.pathname.split('/');
    if (urlParts.length < 3) {
      return true;
    }

    const page = urlParts[2];
    const loginExceptions = [
      'reset_password',
      'invite',
      'external_meeting',
      'external_meeting_closed',
      'help',
      'signed_out',
      'open'
    ];

    return loginExceptions.indexOf(page) <= -1;

  };

  render() {
    if (!this.props.session.checked) {
      return <div />;
    } else if (!this.props.session.valid) {
      if (this.requiresLogin()) {
        return <Login />;
      }
    }

    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route path="/app/start" component={Start} />
              <Route path="/app/invite/:inviteCode" component={ClientInvitation} />
              <Route path="/app/client" component={Client} />
              <Route
                path="/app/client_onboarding"
                component={ClientOnboarding}
              />
              <Route path="/app/coach" component={Coach} />
              <Route
                path="/app/external_meeting/:who/:name/:meetingKey/:externalId"
                component={Video}
                exact={true}
              />
              <Route
                path="/app/external_meeting_closed/:who/:name/:meetingKey/:externalId"
                component={VideoClosed}
                exact={true}
              />
              <Route
                path="/app/meeting/:who/:name/:meetingKey"
                component={Video}
                exact={true}
              />
              <Route
                path="/app/coach_onboarding"
                component={CoachOnboarding}
              />


              <Route
                path="/app/reset_password/:token"
                component={ResetPassword}
                exact={true}
              />
              <Route
                path="/app/signed_out"
                component={SignedOut}
                exact={true}
              />
              <Route path="/app/testing" component={TestMenu} exact={true} />
              <Route path="/app/testing/turn" component={TestTurn} exact={true} />
              <Route path="/app/testing/disa" component={DisaTest} exact={true} />
              <Route path="/app/testing/calendar" component={TestCalendar} exact={true} />
              <Route path="/app/help/camera_test" component={CameraTest} exact={true} />
              <Route path="/app/help/camera" component={CameraAccess} exact={true} />
              <Route path="/app/open/behaviour" component={OpenBehaviour} exact={true} />
              <Route
                exact
                path="/app"
                render={() => <Redirect to="/app/start" />}
              />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/app/start" />}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default connector(App);
