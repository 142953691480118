import React, { useEffect, useRef, useState } from "react";
import './ChatWindow.css';
import { ChatInterface } from "../interfaces/Chat";
import { getDateLong, getDayTime } from "../helpers/dateFunctions";
import Api from "../services/Api";
import { IonModal } from "@ionic/react";
import Emoji from "./graphics/Emoji";
import { TrVar } from "../services/translate";
import '../theme/custom.css';


/** React functional component vs class component article:
 * https://javascript.plainenglish.io/which-is-better-class-components-or-functional-component-in-react-a417b4ef6c1a
 */

interface ComponentProps {
  onChat: Function,
  ai?: boolean,
  standalone?: boolean,
  showSender?: boolean,
  chatWithZebrain?: boolean,
  chatMessages: Array<ChatInterface>,
  isSendEnabled: boolean,
  who?: string,
  videoChat?: boolean,
  waiting?: boolean
  onToggleOpen?: () => void
}

const ChatWindow = (props: ComponentProps) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [msg, setMsg] = useState('');
  const [inputHeight, setInputHeight] = useState('34px');

  const api = Api.getInstance();

  let messRef = useRef<any>(null);

  let videoStyles = "absolute w-full h-full py-12 z-[1002] bg-neutral-900"

  useEffect(() => {
    scrollDown();
  }, [])

  const scrollDown = () => {
    if (messRef.current) {
      setTimeout(() => {
        messRef.current.scrollTop = messRef.current.scrollHeight;
      }, 100);
    }
  }

  useEffect(() => {
    scrollDown();
  }, [props.chatMessages.length, msg, props.chatWithZebrain])

  const send = () => {
    if (msg.length > 0) {
      props.onChat(msg);
      setMsg('');
      setInputHeight('34px');
      scrollDown();
    }
  }

  const changeMsg = (e: any) => {
    setMsg(e.target.value);
    const h = e.target.scrollHeight > 270 ? 270 : e.target.scrollHeight
    // e.target.style.height = h + "px"
    setInputHeight(h + "px");
  }

  const addEmoji = (emoji: string) => {
    if (emoji) {
      setMsg(msg + emoji);
      setShowEmojis(false);
    } else {
      setShowEmojis(false);
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      send();
    }
  };

  const formatChats = () => {
    const chats: Array<ChatInterface> = []
    for (const chat of props.chatMessages) {
      if (chat.chatWithZebrain === undefined || chat.chatWithZebrain === props.chatWithZebrain) {
        chats.push(chat)
      }
    }
    if (chats.length === 0) {
      return (<></>)
    }
    let prevDay = ''
    return (
      <div key={"the_chat_box"}>
        {
          chats.map((chat, index) => {
            const who = chat.senderName
            const you = chat.sender === 'you'
            const text_align = you ? 'text_right' : 'text_left'
            const thisDay = getDateLong(chat.createdAt, api.lang)
            let showDay = thisDay !== prevDay ? thisDay : ''
            prevDay = thisDay
            return (
              <div key={"chat_" + index}>
                {
                  (props.standalone && showDay.length > 0) &&
                  <div className={"text_center color_purple relative " + (index > 0 ? "mt-16" : "")}
                    style={{ marginBottom: "-30px" }}>
                    <hr className="bg_blue_gray" />
                    <div className="inline_block relative font_smaller px-4 blue_gray bg_dark_blue" style={{ top: "-6px" }}>
                      {showDay}
                    </div>
                  </div>
                }
                <div className={text_align}>
                  <div className={'top_margin font_smaller ' + text_align}>
                    {who}: {!props.ai && props.standalone && getDayTime(chat.createdAt, api.lang)}
                    {
                      (!props.ai && chat.sender === 'you' && chat.chatRead) &&
                      <span>&nbsp;({api.trTxt(TrVar.ChatRead).toLowerCase()})</span>
                    }
                    {
                      (!props.ai && chat.sender === 'you' && !chat.chatRead) &&
                      <span>&nbsp;({api.trTxt(TrVar.Unread).toLowerCase()})</span>
                    }
                  </div>
                  <div className={"chat_msg mt-1 whitespace-pre-line " + chat.sender}>
                    {chat.message}
                  </div>
                </div>
              </div>
            )
          })
        }
        { props.waiting && (
          <div className="rounded text-left pr-5 pl-5 rounded chat_waiting top_margin" style={{ display: 'inline-block'}}>
            <div className="dot-container">
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={"flex flex-col flex-nowrap " + (!props.standalone ? `${videoStyles}` : 'chat_container')}>
      <IonModal isOpen={showEmojis} className="modal_medium"
        onDidDismiss={() => setShowEmojis(false)}>
        <Emoji close={addEmoji} />
      </IonModal>
      <div className={'px-4 chat_msg_display flex-grow scroll_y' + (props.standalone ? ' standalone' : '')} ref={messRef}>
        {props.videoChat &&
          <div>
            <div className="chat_with_title">
              {api.trTxt(TrVar.ChatWith)} 
              {api.trTxt(TrVar.Your).toLowerCase()} 
              {props.who === 'client' ? api.trTxt(TrVar.Coach).toLowerCase() : props.who === 'coach' ? api.trTxt(TrVar.Client).toLowerCase() : api.trTxt(TrVar.MeetingPartner).toLowerCase()}
              {props.onToggleOpen && <div className="video_close_div" onClick={props.onToggleOpen}>
                <img src="/assets/icon/white/cross.svg" alt="close" className="icon_small" />
              </div>}
            </div>
          </div>
        }
        {
          formatChats()
        }
      </div>
      <div className="px-4">
        {
          props.isSendEnabled &&
          <div className="text_center pt-5 " style={{height: '100%'}}>
            <div className="bg_border_blue" style={{ height: "1px", margin: "-20px -20px 20px -20px" }} />
            <div className="mx-auto flex flex-row justify-around items-center" style={{height: '100%'}}>
              <div className="cursor-pointer"
                onClick={() => setShowEmojis(!showEmojis)}>
                <img alt="smiley" src="/assets/icon/white/smiley.svg" style={{ height: '20px' }} />
              </div>

              <div className="flex-grow px-4">
                <textarea
                  className="chat_text chat_textbox_border"
                  style={{ height: inputHeight, maxHeight: '80%', minHeight: '34px' }}
                  value={msg}
                  onChange={(e) => changeMsg(e)}
                  onKeyDown={handleKeyDown}
                  placeholder={api.trTxt(TrVar.WriteHere)}
                />
              </div>
              <div 
                className={`chat_send_btn flex items-center ${props.waiting ? 'cursor-not-allowed bg_dark_gray' : 'cursor-pointer'} `} 
                onClick={() => !props.waiting && send()}
              >
                <img alt="send" src="/assets/icon/black/send.svg" style={{ height: '17px', opacity: props.waiting ? 0.2 : 1 }} />
              </div>
            </div>

          </div>
        }
      </div>
    </div>
  );
}

export default ChatWindow;