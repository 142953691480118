import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import Api from "../services/Api";
import { changeUrl } from "../services/Navigation";
import WebSocketClient from "../services/WebSocketClient";
import { RouteComponentProps } from "react-router";
import { TrVar } from "../services/translate";

interface ComponentProps extends RouteComponentProps<{
  token: string
}>{}

interface ComponentState {
  password: string
  passwordVerify: string
  errorMsg: string
  loggedIn: boolean
  renderNr: number
}

export default class ResetPassword extends React.Component<ComponentProps, ComponentState> {

  private api = Api.getInstance();
  private webSocketClient = WebSocketClient.getInstance();

  state = {
    password: '',
    passwordVerify: '',
    errorMsg: '',
    loggedIn: false,
    renderNr: 0,
  }

  private handlePassword = (e: any) => {
    this.setState({password: e.target.value});
  }

  private handlePasswordVerify = (e: any) => {
    this.setState({passwordVerify: e.target.value});
  }

  private passwordOk = () => {
    return this.state.password.length > 9;
  }

  private passwordVerify = () => {
    if (this.state.password.length > 9 && this.state.passwordVerify === this.state.password) {
      return true
    }
    return false
  }

  changeLanguage = (lang: string) => {
    this.api.setLanguage(lang)
    this.setState({ renderNr: this.state.renderNr + 1 });
  }

  private login = () => {
    this.setState({errorMsg: ''})

    if (!this.passwordOk()) {
      this.setState({errorMsg: this.api.trTxt(TrVar.PasswordMustIncludeAtLeastTenCharacters)})
      return
    }

    if (!this.passwordVerify()) {
      this.setState({errorMsg: this.api.trTxt(TrVar.PasswordDontMatch)})
      return
    }

    let data = {
      password: this.state.password,
      password_verify: this.state.passwordVerify,
      token: this.props.match.params.token
    }

    this.api.post('reset_password', data, ['password_reset_expired'])
    .then(_response => {
      this.setState({loggedIn: true})
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({errorMsg: this.api.trTxt(TrVar.ThisLinkHasExpired)})
      }
    });
}

  render() {
    if (this.state.loggedIn) {
      return (
        <IonPage>
          <IonContent>
            <div className="page_content text_center">
              <div style={{ marginTop: '120px' }} className='page_section'>
                <h2 className="color_white mr-4">{this.api.trTxt(TrVar.PasswordChanged)}</h2>
                <div className="mt-8 color_white">
                  {this.api.trTxt(TrVar.SignInWithNewPassword)}
                  <div className="mt-8">
                    <button className="button" onClick={() => changeUrl('/app/client/overview')}>{this.api.trTxt(TrVar.Client)}</button>
                  </div>
                  <div className="mt-8">
                    <button className="button" onClick={() => changeUrl('/app/coach/overview')}>{this.api.trTxt(TrVar.Coach)}</button>
                  </div>
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      );
    }
    return (
      <IonPage>
        <IonContent>
          <div className="page_content text_center relative">
            {
              this.api.lang === 'sv' &&
              <div style={{ position: 'absolute', top: '-65px', right: '0' }} className="cursor-pointer" onClick={() => this.changeLanguage('en')}>
                <img className="circle_small" alt="english" src="/assets/icon/language_en.png" />
              </div>
            }
            {
              this.api.lang === 'en' &&
              <div style={{ position: 'absolute', top: '-65px', right: '0' }} className="cursor-pointer" onClick={() => this.changeLanguage('sv')}>
                <img className="circle_small" alt="english" src="/assets/icon/language_sv.png" />
              </div>
            }
            <div style={{ marginTop: '120px' }} className='page_section'>
              <div className="flex items-center justify-center">
                <h2 className="color_white mr-4">{this.api.trTxt(TrVar.ResetPassword)}</h2>
              </div>

              <p className="mt-6 color_white">{this.api.trTxt(TrVar.Password) + ': ' + this.api.trTxt(TrVar.AtLeast10Characters).toLowerCase()}</p>
              <input className="page_input" type="password" name="password" placeholder={this.api.trTxt(TrVar.Password)}
                onChange={(e) => this.handlePassword(e)} />

              <div className="mt-2">
                <input className="page_input" type="password" name="password" placeholder={this.api.trTxt(TrVar.PasswordAgain)}
                  onChange={(e) => this.handlePasswordVerify(e)} />
              </div>


              {
                this.state.errorMsg.length > 0 &&
                <div className="mt-6 error_red p-4">{this.state.errorMsg}</div>
              }
              <div className="mt-6">
                <button className="button" onClick={() => this.login()}>{this.api.trTxt(TrVar.UpdatePassword)}</button>
              </div>

            </div>

          </div>
        </IonContent>
      </IonPage>
    );
  }
}
