import React from 'react'

interface ComponentProps {
  animationType: string
  position?: string 
  color?: string
}

const SvgAnimation = (props: ComponentProps) => {
  if (props.animationType === 'star') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width="400" height="400" viewBox="0 0 400 400" style={{ width: "100%", height: "100%" }}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "rgb(252,201,200)", stopOpacity: "1" }} />
            <stop offset="100%" style={{ stopColor: "rgba(188,128,240,1)", stopOpacity: "1" }} />
          </linearGradient>
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_12_G" fill="freeze" keyTimes="0;0.24;0.3333333;0.3733333;0.5733333;1" path="M66.96 268.89 C66.96,268.89 66.96,268.89 66.96,268.89 C66.96,268.89 66.96,268.89 66.96,268.89 C66.96,266.72 66.96,252.72 66.96,255.89 C66.96,259.05 66.96,282.55 66.96,287.89 C66.96,287.89 66.96,287.89 66.96,287.89 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_12_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3333333;0.4133333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_12_G" fill="freeze" attributeName="transform" from="-66.956 -278.885" to="-66.956 -278.885" type="translate" additive="sum" keyTimes="0;0.24;1" values="-66.956 -278.885;-66.956 -278.885;-66.956 -278.885" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_12_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_11_G" fill="freeze" keyTimes="0;0.24;0.3733333;0.4133333;0.6133333;1" path="M45.25 213.53 C45.25,213.53 45.25,213.53 45.25,213.53 C45.25,213.53 45.25,213.53 45.25,213.53 C45.25,211.2 45.25,196.36 45.25,199.53 C45.25,202.7 45.25,227.03 45.25,232.53 C45.25,232.53 45.25,232.53 45.25,232.53 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_11_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3733333;0.4533333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.8s" begin="0s" xlinkHref="#_R_G_L_1_G_L_11_G" fill="freeze" attributeName="transform" from="-76.246 -227.529" to="-76.246 -227.529" type="translate" additive="sum" keyTimes="0;0.24;1" values="-76.246 -227.529;-76.246 -227.529;-76.246 -227.529" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_11_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_10_G" fill="freeze" keyTimes="0;0.24;0.3866667;0.4266667;0.6266667;1" path="M82.05 143.53 C82.05,143.53 82.05,143.53 82.05,143.53 C82.05,143.53 82.05,143.53 82.05,143.53 C82.05,140.2 82.05,118.87 82.05,123.53 C82.05,128.2 82.05,163.53 82.05,171.53 C82.05,171.53 82.05,171.53 82.05,171.53 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_10_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3866667;0.4666667;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_10_G" fill="freeze" attributeName="transform" from="-45.048 -181.533" to="-45.048 -181.533" type="translate" additive="sum" keyTimes="0;0.24;1" values="-45.048 -181.533;-45.048 -181.533;-45.048 -181.533" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_10_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_9_G" fill="freeze" keyTimes="0;0.24;0.3466667;0.3866667;0.5866667;1" path="M127.05 98.7 C127.05,98.7 127.05,98.7 127.05,98.7 C127.05,98.7 127.05,98.7 127.05,98.7 C127.05,95.37 127.05,74.37 127.05,78.7 C127.05,83.03 127.05,117.03 127.05,124.7 C127.05,124.7 127.05,124.7 127.05,124.7 " keyPoints="0;0;0;0.32;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_9_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3466667;0.4266667;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_9_G" fill="freeze" attributeName="transform" from="-42.048 -115.699" to="-42.048 -115.699" type="translate" additive="sum" keyTimes="0;0.24;1" values="-42.048 -115.699;-42.048 -115.699;-42.048 -115.699" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_9_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_8_G" fill="freeze" keyTimes="0;0.24;0.4266667;0.4666667;0.6666667;1" path="M56.83 105.32 C56.83,105.32 56.83,105.32 56.83,105.32 C56.83,105.32 56.83,105.32 56.83,105.32 C56.83,102.45 56.83,85.66 56.83,88.07 C56.83,90.49 56.83,114.53 56.83,119.82 C56.83,119.82 56.83,119.82 56.83,119.82 " keyPoints="0;0;0;0.36;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_8_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.4266667;0.5066667;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_8_G" fill="freeze" attributeName="transform" from="-78.834 -57.822" to="-78.834 -57.822" type="translate" additive="sum" keyTimes="0;0.24;1" values="-78.834 -57.822;-78.834 -57.822;-78.834 -57.822" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_8_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_7_G" fill="freeze" keyTimes="0;0.24;0.3866667;0.4266667;0.6266667;1" path="M106.08 63.73 C106.08,63.73 106.08,63.73 106.08,63.73 C106.08,63.73 106.08,63.73 106.08,63.73 C106.08,62.11 106.08,52.32 106.08,53.98 C106.08,55.65 106.08,70.44 106.08,73.73 C106.08,73.73 106.08,73.73 106.08,73.73 " keyPoints="0;0;0;0.34;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_7_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3866667;0.4666667;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_7_G" fill="freeze" attributeName="transform" from="-132.082 -12.734" to="-132.082 -12.734" type="translate" additive="sum" keyTimes="0;0.24;1" values="-132.082 -12.734;-132.082 -12.734;-132.082 -12.734" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_7_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_6_G" fill="freeze" keyTimes="0;0.24;0.3733333;0.4133333;0.6133333;1" path="M170.4 61.41 C170.4,61.41 170.4,61.41 170.4,61.41 C170.4,61.41 170.4,61.41 170.4,61.41 C170.4,58.41 170.4,39.08 170.4,43.41 C170.4,47.74 170.4,80.08 170.4,87.41 C170.4,87.41 170.4,87.41 170.4,87.41 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_6_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3733333;0.4533333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_6_G" fill="freeze" attributeName="transform" from="-170.395 -64.41" to="-170.395 -64.41" type="translate" additive="sum" keyTimes="0;0.24;1" values="-170.395 -64.41;-170.395 -64.41;-170.395 -64.41" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_6_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2666667;0.266668;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_5_G" fill="freeze" keyTimes="0;0.24;0.4533333;0.4933333;0.6933333;1" path="M232.08 122.23 C232.08,122.23 232.08,122.23 232.08,122.23 C232.08,122.23 232.08,122.23 232.08,122.23 C232.08,120.27 232.08,109.02 232.08,110.48 C232.08,111.93 232.08,127.56 232.08,130.98 C232.08,130.98 232.08,130.98 232.08,130.98 " keyPoints="0;0;0;0.37;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_5_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.4533333;0.5333333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_5_G" fill="freeze" attributeName="transform" from="-217.083 -31.976" to="-217.083 -31.976" type="translate" additive="sum" keyTimes="0;0.24;1" values="-217.083 -31.976;-217.083 -31.976;-217.083 -31.976" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_5_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2666667;0.266668;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_4_G" fill="freeze" keyTimes="0;0.24;0.36;0.4;0.6;1" path="M268.5 129.5 C268.5,129.5 268.5,129.5 268.5,129.5 C268.5,129.5 268.5,129.5 268.5,129.5 C268.5,126.5 268.5,106.84 268.5,111.5 C268.5,116.17 268.5,149.84 268.5,157.5 C268.5,157.5 268.5,157.5 268.5,157.5 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_4_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.36;0.44;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_4_G" fill="freeze" attributeName="transform" from="-268.5 -70.503" to="-268.5 -70.503" type="translate" additive="sum" keyTimes="0;0.24;1" values="-268.5 -70.503;-268.5 -70.503;-268.5 -70.503" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_4_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.226668;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_3_G" fill="freeze" keyTimes="0;0.24;0.3866667;0.4266667;0.6266667;1" path="M269.56 54.41 C269.56,54.41 269.56,54.41 269.56,54.41 C269.56,54.41 269.56,54.41 269.56,54.41 C269.56,50.58 269.56,25.58 269.56,31.41 C269.56,37.24 269.56,79.74 269.56,89.41 C269.56,89.41 269.56,89.41 269.56,89.41 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_3_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3866667;0.4666667;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_3_G" fill="freeze" attributeName="transform" from="-312.562 -105.409" to="-312.562 -105.409" type="translate" additive="sum" keyTimes="0;0.24;1" values="-312.562 -105.409;-312.562 -105.409;-312.562 -105.409" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_3_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2666667;0.266668;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_2_G" fill="freeze" keyTimes="0;0.24;0.4533333;0.4933333;0.6933333;1" path="M316.29 154.43 C316.29,154.43 316.29,154.43 316.29,154.43 C316.29,154.43 316.29,154.43 316.29,154.43 C316.29,150.43 316.29,124.59 316.29,130.43 C316.29,136.26 316.29,179.59 316.29,189.43 C316.29,189.43 316.29,189.43 316.29,189.43 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_2_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.4533333;0.5333333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_2_G" fill="freeze" attributeName="transform" from="-316.294 -170.426" to="-316.294 -170.426" type="translate" additive="sum" keyTimes="0;0.24;1" values="-316.294 -170.426;-316.294 -170.426;-316.294 -170.426" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_2_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2933333;0.2933347;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_1_G" fill="freeze" keyTimes="0;0.24;0.3333333;0.3733333;0.5733333;1" path="M311.43 223.53 C311.43,223.53 311.43,223.53 311.43,223.53 C311.43,223.53 311.43,223.53 311.43,223.53 C311.43,219.86 311.43,196.7 311.43,201.53 C311.43,206.36 311.43,244.03 311.43,252.53 C311.43,252.53 311.43,252.53 311.43,252.53 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_1_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.3333333;0.4133333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_1_G" fill="freeze" attributeName="transform" from="-280.428 -227.529" to="-280.428 -227.529" type="translate" additive="sum" keyTimes="0;0.24;1" values="-280.428 -227.529;-280.428 -227.529;-280.428 -227.529" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.24;0.2400013;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_0_G" fill="freeze" keyTimes="0;0.24;0.4133333;0.4533333;0.6533333;1" path="M271.38 273.12 C271.38,273.12 271.38,273.12 271.38,273.12 C271.38,273.12 271.38,273.12 271.38,273.12 C271.38,269.45 271.38,247.79 271.38,251.12 C271.38,254.45 271.38,286.12 271.38,293.12 C271.38,293.12 271.38,293.12 271.38,293.12 " keyPoints="0;0;0;0.35;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_0_G" fill="freeze" attributeName="transform" from="0 0" to="0.78201 0.78201" type="scale" additive="sum" keyTimes="0;0.24;0.4133333;0.4933333;1" values="0 0;0 0;0 0;0.78201 0.78201;0.78201 0.78201" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_0_G" fill="freeze" attributeName="transform" from="-266.378 -291.121" to="-266.378 -291.121" type="translate" additive="sum" keyTimes="0;0.24;1" values="-266.378 -291.121;-266.378 -291.121;-266.378 -291.121" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_L_0_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2666667;0.266668;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.24;0.2400013;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G" fill="freeze" attributeName="transform" from="0 0" to="1 1" type="scale" additive="sum" keyTimes="0;0.0533333;0.1066667;0.2;0.2533333;0.36;0.44;0.48;1" values="0 0;1.2 1.2;1 1;1 1;1 1;0.51 0.51;1.1 1.1;1 1;1 1" keySplines="0.167 0 0.667 1;0.167 0 0.833 1;0.167 0 0.667 1;0.167 0 0.667 1;0.167 0 0.833 1;0.167 0 0.667 1;0.167 0 0.833 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G" fill="freeze" attributeName="transform" from="-175 -205.172" to="-175 -205.172" type="translate" additive="sum" keyTimes="0;1" values="-175 -205.172;-175 -205.172" keySplines="0 0 1 1" calcMode="spline" />
          <animate attributeType="XML" attributeName="opacity" dur="3s" from="0" to="1" xlinkHref="#time_group" />
        </defs>
        <g id="_R_G">
          <g id="_R_G_L_1_G_M">
            <g id="_R_G_L_1_G" transform=" translate(200, 162) translate(-175, -160)">
              <g id="_R_G_L_1_G_L_12_G_M">
                <g id="_R_G_L_1_G_L_12_G">
                  <path id="_R_G_L_1_G_L_12_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M48.39 278.89 C58.64,278.89 66.96,287.2 66.96,297.45 C66.96,287.2 75.27,278.89 85.52,278.89 C75.27,278.89 66.96,270.57 66.96,260.32 C66.96,270.57 58.64,278.89 48.39,278.89z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_11_G_M">
                <g id="_R_G_L_1_G_L_11_G">
                  <path id="_R_G_L_1_G_L_11_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M66.96 227.53 C72.09,227.53 76.25,231.69 76.25,236.82 C76.25,231.69 80.41,227.53 85.54,227.53 C80.41,227.53 76.25,223.37 76.25,218.24 C76.25,223.37 72.09,227.53 66.96,227.53z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_10_G_M">
                <g id="_R_G_L_1_G_L_10_G">
                  <path id="_R_G_L_1_G_L_10_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M35.76 181.53 C40.89,181.53 45.05,185.69 45.05,190.82 C45.05,185.69 49.21,181.53 54.34,181.53 C49.21,181.53 45.05,177.37 45.05,172.24 C45.05,177.37 40.89,181.53 35.76,181.53z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_9_G_M">
                <g id="_R_G_L_1_G_L_9_G">
                  <path id="_R_G_L_1_G_L_9_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M32.76 115.7 C37.89,115.7 42.05,119.86 42.05,124.99 C42.05,119.86 46.21,115.7 51.34,115.7 C46.21,115.7 42.05,111.54 42.05,106.41 C42.05,111.54 37.89,115.7 32.76,115.7z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_8_G_M">
                <g id="_R_G_L_1_G_L_8_G">
                  <path id="_R_G_L_1_G_L_8_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M60.27 57.82 C70.52,57.82 78.83,66.14 78.83,76.39 C78.83,66.14 87.15,57.82 97.4,57.82 C87.15,57.82 78.83,49.51 78.83,39.25 C78.83,49.51 70.52,57.82 60.27,57.82z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_7_G_M">
                <g id="_R_G_L_1_G_L_7_G">
                  <path id="_R_G_L_1_G_L_7_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M122.79 12.73 C127.92,12.73 132.08,17.6 132.08,23.61 C132.08,17.6 136.24,12.73 141.37,12.73 C136.24,12.73 132.08,7.86 132.08,1.86 C132.08,7.86 127.92,12.73 122.79,12.73z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_6_G_M">
                <g id="_R_G_L_1_G_L_6_G">
                  <path id="_R_G_L_1_G_L_6_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M161.11 64.41 C166.24,64.41 170.4,68.57 170.4,73.7 C170.4,68.57 174.55,64.41 179.68,64.41 C174.55,64.41 170.4,60.25 170.4,55.12 C170.4,60.25 166.24,64.41 161.11,64.41z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_5_G_M">
                <g id="_R_G_L_1_G_L_5_G">
                  <path id="_R_G_L_1_G_L_5_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M207.79 31.98 C212.93,31.98 217.08,36.14 217.08,41.27 C217.08,36.14 221.24,31.98 226.37,31.98 C221.24,31.98 217.08,27.82 217.08,22.69 C217.08,27.82 212.93,31.98 207.79,31.98z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_4_G_M">
                <g id="_R_G_L_1_G_L_4_G">
                  <path id="_R_G_L_1_G_L_4_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M249.93 70.5 C260.19,70.5 268.5,78.82 268.5,89.07 C268.5,78.82 276.81,70.5 287.07,70.5 C276.81,70.5 268.5,62.19 268.5,51.94 C268.5,62.19 260.19,70.5 249.93,70.5z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_3_G_M">
                <g id="_R_G_L_1_G_L_3_G">
                  <path id="_R_G_L_1_G_L_3_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M303.27 105.41 C308.4,105.41 312.56,109.57 312.56,114.7 C312.56,109.57 316.72,105.41 321.85,105.41 C316.72,105.41 312.56,101.25 312.56,96.12 C312.56,101.25 308.4,105.41 303.27,105.41z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_2_G_M">
                <g id="_R_G_L_1_G_L_2_G">
                  <path id="_R_G_L_1_G_L_2_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M307.01 170.43 C312.14,170.43 316.29,174.58 316.29,179.72 C316.29,174.58 320.45,170.43 325.58,170.43 C320.45,170.43 316.29,166.27 316.29,161.14 C316.29,166.27 312.14,170.43 307.01,170.43z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_1_G_M">
                <g id="_R_G_L_1_G_L_1_G">
                  <path id="_R_G_L_1_G_L_1_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M261.86 227.53 C272.12,227.53 280.43,235.84 280.43,246.1 C280.43,235.84 288.74,227.53 299,227.53 C288.74,227.53 280.43,219.22 280.43,208.96 C280.43,219.22 272.12,227.53 261.86,227.53z " />
                </g>
              </g>
              <g id="_R_G_L_1_G_L_0_G_M">
                <g id="_R_G_L_1_G_L_0_G">
                  <path id="_R_G_L_1_G_L_0_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M257.09 291.12 C262.22,291.12 266.38,295.28 266.38,300.41 C266.38,295.28 270.54,291.12 275.67,291.12 C270.54,291.12 266.38,286.96 266.38,281.83 C266.38,286.96 262.22,291.12 257.09,291.12z " />
                </g>
              </g>
            </g>
          </g>
          <g id="_R_G_L_0_G" transform=" translate(200, 234)">
            <path id="_R_G_L_0_G_D_0_P_0" fill="url(#grad1)" fillOpacity="1" fillRule="nonzero" d=" M246.77 315.63 C246.77,315.63 175,277.9 175,277.9 C175,277.9 103.23,315.63 103.23,315.63 C103.23,315.63 116.93,235.7 116.93,235.7 C116.93,235.7 58.86,179.1 58.86,179.1 C58.86,179.1 107.16,172.08 107.16,172.08 C107.16,172.08 109.02,184.89 109.02,184.89 C109.02,184.89 86.68,188.14 86.68,188.14 C86.68,188.14 130.84,231.19 130.84,231.19 C130.84,231.19 120.41,291.97 120.41,291.97 C120.41,291.97 175,263.27 175,263.27 C175,263.27 229.59,291.97 229.59,291.97 C229.59,291.97 219.16,231.19 219.16,231.19 C219.16,231.19 263.32,188.14 263.32,188.14 C263.32,188.14 202.29,179.27 202.29,179.27 C202.29,179.27 175,123.97 175,123.97 C175,123.97 157.11,160.22 157.11,160.22 C157.11,160.22 145.51,154.49 145.51,154.49 C145.51,154.49 175,94.71 175,94.71 C175,94.71 210.89,167.44 210.89,167.44 C210.89,167.44 291.14,179.1 291.14,179.1 C291.14,179.1 233.07,235.7 233.07,235.7 C233.07,235.7 246.77,315.63 246.77,315.63z " />
          </g>
        </g>
        <g id="time_group" />
      </svg>
    )
  } else if (props.animationType === 'checkmark') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width="1080" height="1080" viewBox="0 0 1080 1080" style={{ width: "100%", height: "100%" }}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "rgb(252,201,200)", stopOpacity: "1" }} />
            <stop offset="100%" style={{ stopColor: "rgb(232,177,214)", stopOpacity: "1" }} />
          </linearGradient>
          <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "rgb(232,177,214)", stopOpacity:"1" }} />
            <stop offset="100%" style={{ stopColor: "rgba(188,128,240,1)", stopOpacity: "1" }} />
          </linearGradient>
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_13_G" fill="freeze" keyTimes="0;0.5692308;0.6153846;0.8461538;1" path="M917.97 552.94 C917.97,552.94 917.97,552.94 917.97,552.94 C917.97,541.63 917.97,474.77 917.97,485.06 C917.97,495.34 917.97,593.06 917.97,614.66 C917.97,614.66 917.97,614.66 917.97,614.66 " keyPoints="0;0;0.35;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_13_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5692308;0.6615385;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_13_G" fill="freeze" attributeName="transform" from="-266.378 -291.121" to="-266.378 -291.121" type="translate" additive="sum" keyTimes="0;1" values="-266.378 -291.121;-266.378 -291.121" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_13_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.4;0.4000015;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_12_G" fill="freeze" keyTimes="0;0.4769231;0.5230769;0.7538462;1" path="M719.32 610.32 C719.32,610.32 719.32,610.32 719.32,610.32 C719.32,599 719.32,527.52 719.32,542.43 C719.32,557.35 719.32,673.57 719.32,699.8 C719.32,699.8 719.32,699.8 719.32,699.8 " keyPoints="0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_12_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.4769231;0.5692308;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_12_G" fill="freeze" attributeName="transform" from="-280.428 -227.529" to="-280.428 -227.529" type="translate" additive="sum" keyTimes="0;1" values="-280.428 -227.529;-280.428 -227.529" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_12_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_11_G" fill="freeze" keyTimes="0;0.5692308;0.6153846;0.8461538;1" path="M339.99 738.52 C339.99,738.52 339.99,738.52 339.99,738.52 C339.99,726.17 339.99,646.46 339.99,664.46 C339.99,682.46 339.99,816.17 339.99,846.52 C339.99,846.52 339.99,846.52 339.99,846.52 " keyPoints="0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_11_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5692308;0.6615385;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_11_G" fill="freeze" attributeName="transform" from="-316.294 -170.426" to="-316.294 -170.426" type="translate" additive="sum" keyTimes="0;1" values="-316.294 -170.426;-316.294 -170.426" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_11_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.4307692;0.4307708;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_10_G" fill="freeze" keyTimes="0;0.5384615;0.5846154;0.8153846;1" path="M964.48 275.89 C964.48,275.89 964.48,275.89 964.48,275.89 C964.48,264.06 964.48,186.92 964.48,204.92 C964.48,222.92 964.48,354.06 964.48,383.89 C964.48,383.89 964.48,383.89 964.48,383.89 " keyPoints="0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_10_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5384615;0.6307692;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_10_G" fill="freeze" attributeName="transform" from="-312.562 -105.409" to="-312.562 -105.409" type="translate" additive="sum" keyTimes="0;1" values="-312.562 -105.409;-312.562 -105.409" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_10_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.4;0.4000015;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_9_G" fill="freeze" keyTimes="0;0.5076923;0.5538462;0.7846154;1" path="M840.52 138.18 C840.52,138.18 840.52,138.18 840.52,138.18 C840.52,128.92 840.52,68.24 840.52,82.64 C840.52,97.04 840.52,200.92 840.52,224.58 C840.52,224.58 840.52,224.58 840.52,224.58 " keyPoints="0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_9_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5076923;0.6;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_9_G" fill="freeze" attributeName="transform" from="-268.5 -70.503" to="-268.5 -70.503" type="translate" additive="sum" keyTimes="0;1" values="-268.5 -70.503;-268.5 -70.503" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_9_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3538462;0.3538477;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_8_G" fill="freeze" keyTimes="0;0.6153846;0.6615385;0.8923077;1" path="M669.86 117.96 C669.86,117.96 669.86,117.96 669.86,117.96 C669.86,111.91 669.86,77.2 669.86,81.7 C669.86,86.2 669.86,134.41 669.86,144.96 C669.86,144.96 669.86,144.96 669.86,144.96 " keyPoints="0;0;0.37;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_8_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.6153846;0.7076923;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_8_G" fill="freeze" attributeName="transform" from="-217.083 -31.976" to="-217.083 -31.976" type="translate" additive="sum" keyTimes="0;1" values="-217.083 -31.976;-217.083 -31.976" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_8_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.4;0.4000015;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_7_G" fill="freeze" keyTimes="0;0.5230769;0.5692308;0.8;1" path="M525.79 149.38 C525.79,149.38 525.79,149.38 525.79,149.38 C525.79,140.12 525.79,80.46 525.79,93.84 C525.79,107.21 525.79,206.98 525.79,229.61 C525.79,229.61 525.79,229.61 525.79,229.61 " keyPoints="0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_7_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5230769;0.6153846;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_7_G" fill="freeze" attributeName="transform" from="-170.395 -64.41" to="-170.395 -64.41" type="translate" additive="sum" keyTimes="0;1" values="-170.395 -64.41;-170.395 -64.41" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_7_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.4;0.4000015;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_6_G" fill="freeze" keyTimes="0;0.5384615;0.5846154;0.8153846;1" path="M575.57 805.18 C575.57,805.18 575.57,805.18 575.57,805.18 C575.57,800.17 575.57,769.95 575.57,775.09 C575.57,780.24 575.57,825.88 575.57,836.04 C575.57,836.04 575.57,836.04 575.57,836.04 " keyPoints="0;0;0.34;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_6_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5384615;0.6307692;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_6_G" fill="freeze" attributeName="transform" from="-132.082 -12.734" to="-132.082 -12.734" type="translate" additive="sum" keyTimes="0;1" values="-132.082 -12.734;-132.082 -12.734" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_6_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_5_G" fill="freeze" keyTimes="0;0.5846154;0.6307692;0.8615385;1" path="M243.26 170.71 C243.26,170.71 243.26,170.71 243.26,170.71 C243.26,161.84 243.26,110.02 243.26,117.48 C243.26,124.94 243.26,199.12 243.26,215.45 C243.26,215.45 243.26,215.45 243.26,215.45 " keyPoints="0;0;0.36;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_5_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5846154;0.6769231;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_5_G" fill="freeze" attributeName="transform" from="-78.834 -57.822" to="-78.834 -57.822" type="translate" additive="sum" keyTimes="0;1" values="-78.834 -57.822;-78.834 -57.822" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_5_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_4_G" fill="freeze" keyTimes="0;0.4923077;0.5384615;0.7692308;1" path="M129.75 307.64 C129.75,307.64 129.75,307.64 129.75,307.64 C129.75,297.36 129.75,232.56 129.75,245.93 C129.75,259.3 129.75,364.21 129.75,387.87 C129.75,387.87 129.75,387.87 129.75,387.87 " keyPoints="0;0;0.32;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_4_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.4923077;0.5846154;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_4_G" fill="freeze" attributeName="transform" from="-42.048 -115.699" to="-42.048 -115.699" type="translate" additive="sum" keyTimes="0;1" values="-42.048 -115.699;-42.048 -115.699" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_4_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_3_G" fill="freeze" keyTimes="0;0.5384615;0.5846154;0.8153846;1" path="M139.01 510.79 C139.01,510.79 139.01,510.79 139.01,510.79 C139.01,500.5 139.01,434.67 139.01,449.07 C139.01,463.47 139.01,572.5 139.01,597.19 C139.01,597.19 139.01,597.19 139.01,597.19 " keyPoints="0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_3_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5384615;0.6307692;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_3_G" fill="freeze" attributeName="transform" from="-45.048 -181.533" to="-45.048 -181.533" type="translate" additive="sum" keyTimes="0;1" values="-45.048 -181.533;-45.048 -181.533" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_3_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_2_G" fill="freeze" keyTimes="0;0.5230769;0.5692308;0.8;1" path="M199.27 684.72 C199.27,684.72 199.27,684.72 199.27,684.72 C199.27,677.52 199.27,631.75 199.27,641.52 C199.27,651.29 199.27,726.38 199.27,743.35 C199.27,743.35 199.27,743.35 199.27,743.35 " keyPoints="0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_2_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.5230769;0.6153846;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_2_G" fill="freeze" attributeName="transform" from="-76.246 -227.529" to="-76.246 -227.529" type="translate" additive="sum" keyTimes="0;1" values="-76.246 -227.529;-76.246 -227.529" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_2_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_1_G" fill="freeze" keyTimes="0;0.4769231;0.5230769;0.7538462;1" path="M460.61 331.7 C460.61,331.7 460.61,331.7 460.61,331.7 C460.61,325.02 460.61,281.82 460.61,291.59 C460.61,301.36 460.61,373.87 460.61,390.33 C460.61,390.33 460.61,390.33 460.61,390.33 " keyPoints="0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_1_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.4769231;0.5692308;1" values="0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_1_G" fill="freeze" attributeName="transform" from="-66.956 -278.885" to="-66.956 -278.885" type="translate" additive="sum" keyTimes="0;1" values="-66.956 -278.885;-66.956 -278.885" keySplines="0 0 1 1" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G_D_0_P_0" fill="freeze" attributeName="fill-opacity" from="0" to="1" keyTimes="0;0.0153846;1" values="0;1;1" keySplines="0.167 0.167 0.833 0.833;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G_D_0_P_0" fill="freeze" attributeName="d" attributeType="XML" from="M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " to="M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " keyTimes="0;0.0769231;1" values="M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z ;M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z ;M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " keySplines="0.581 0 0.711 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G_D_1_P_0" fill="freeze" attributeName="fill-opacity" from="0" to="1" keyTimes="0;0.0615385;0.0769231;1" values="0;0;1;1" keySplines="0.167 0.167 0.833 0.833;0.167 0.167 0.833 0.833;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G_D_1_P_0" fill="freeze" attributeName="d" attributeType="XML" from="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z " to="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z " keyTimes="0;0.0769231;0.1538462;1" values="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z " keySplines="0.525 0 0.667 1;0.525 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G" fill="freeze" attributeName="transform" from="2.83674 2.83674" to="2.83674 2.83674" type="scale" additive="sum" keyTimes="0;0.2615385;0.3846154;0.4769231;0.5230769;1" values="2.83674 2.83674;2.83674 2.83674;1.4000000000000001 1.4000000000000001;3.10674 3.10674;2.83674 2.83674;2.83674 2.83674" keySplines="0.266 0 0.595 1;0.266 0 0.595 1;0.221 0 0.578 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="1.8s" begin="0s" xlinkHref="#_R_G_L_0_G" fill="freeze" attributeName="transform" from="-100.5 -78" to="-100.5 -78" type="translate" additive="sum" keyTimes="0;1" values="-100.5 -78;-100.5 -78" keySplines="0 0 1 1" calcMode="spline" />
          <animate attributeType="XML" attributeName="opacity" dur="3s" from="0" to="1" xlinkHref="#time_group" />
        </defs>
        <g id="_R_G">
          <g id="_R_G_L_13_G_M">
            <g id="_R_G_L_13_G">
              <path id="_R_G_L_13_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M257.09 291.12 C262.22,291.12 266.38,295.28 266.38,300.41 C266.38,295.28 270.54,291.12 275.67,291.12 C270.54,291.12 266.38,286.96 266.38,281.83 C266.38,286.96 262.22,291.12 257.09,291.12z " />
            </g>
          </g>
          <g id="_R_G_L_12_G_M">
            <g id="_R_G_L_12_G">
              <path id="_R_G_L_12_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M261.86 227.53 C272.12,227.53 280.43,235.84 280.43,246.1 C280.43,235.84 288.74,227.53 299,227.53 C288.74,227.53 280.43,219.22 280.43,208.96 C280.43,219.22 272.12,227.53 261.86,227.53z " />
            </g>
          </g>
          <g id="_R_G_L_11_G_M">
            <g id="_R_G_L_11_G">
              <path id="_R_G_L_11_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M307.01 170.43 C312.14,170.43 316.29,174.58 316.29,179.72 C316.29,174.58 320.45,170.43 325.58,170.43 C320.45,170.43 316.29,166.27 316.29,161.14 C316.29,166.27 312.14,170.43 307.01,170.43z " />
            </g>
          </g>
          <g id="_R_G_L_10_G_M">
            <g id="_R_G_L_10_G">
              <path id="_R_G_L_10_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M303.27 105.41 C308.4,105.41 312.56,109.57 312.56,114.7 C312.56,109.57 316.72,105.41 321.85,105.41 C316.72,105.41 312.56,101.25 312.56,96.12 C312.56,101.25 308.4,105.41 303.27,105.41z " />
            </g>
          </g>
          <g id="_R_G_L_9_G_M">
            <g id="_R_G_L_9_G">
              <path id="_R_G_L_9_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M249.93 70.5 C260.19,70.5 268.5,78.82 268.5,89.07 C268.5,78.82 276.81,70.5 287.07,70.5 C276.81,70.5 268.5,62.19 268.5,51.94 C268.5,62.19 260.19,70.5 249.93,70.5z " />
            </g>
          </g>
          <g id="_R_G_L_8_G_M">
            <g id="_R_G_L_8_G">
              <path id="_R_G_L_8_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M207.79 31.98 C212.93,31.98 217.08,36.14 217.08,41.27 C217.08,36.14 221.24,31.98 226.37,31.98 C221.24,31.98 217.08,27.82 217.08,22.69 C217.08,27.82 212.93,31.98 207.79,31.98z " />
            </g>
          </g>
          <g id="_R_G_L_7_G_M">
            <g id="_R_G_L_7_G">
              <path id="_R_G_L_7_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M161.11 64.41 C166.24,64.41 170.4,68.57 170.4,73.7 C170.4,68.57 174.55,64.41 179.68,64.41 C174.55,64.41 170.4,60.25 170.4,55.12 C170.4,60.25 166.24,64.41 161.11,64.41z " />
            </g>
          </g>
          <g id="_R_G_L_6_G_M">
            <g id="_R_G_L_6_G">
              <path id="_R_G_L_6_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M122.79 12.73 C127.92,12.73 132.08,17.6 132.08,23.61 C132.08,17.6 136.24,12.73 141.37,12.73 C136.24,12.73 132.08,7.86 132.08,1.86 C132.08,7.86 127.92,12.73 122.79,12.73z " />
            </g>
          </g>
          <g id="_R_G_L_5_G_M">
            <g id="_R_G_L_5_G">
              <path id="_R_G_L_5_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M60.27 57.82 C70.52,57.82 78.83,66.14 78.83,76.39 C78.83,66.14 87.15,57.82 97.4,57.82 C87.15,57.82 78.83,49.51 78.83,39.25 C78.83,49.51 70.52,57.82 60.27,57.82z " />
            </g>
          </g>
          <g id="_R_G_L_4_G_M">
            <g id="_R_G_L_4_G">
              <path id="_R_G_L_4_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M32.76 115.7 C37.89,115.7 42.05,119.86 42.05,124.99 C42.05,119.86 46.21,115.7 51.34,115.7 C46.21,115.7 42.05,111.54 42.05,106.41 C42.05,111.54 37.89,115.7 32.76,115.7z " />
            </g>
          </g>
          <g id="_R_G_L_3_G_M">
            <g id="_R_G_L_3_G">
              <path id="_R_G_L_3_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M35.76 181.53 C40.89,181.53 45.05,185.69 45.05,190.82 C45.05,185.69 49.21,181.53 54.34,181.53 C49.21,181.53 45.05,177.37 45.05,172.24 C45.05,177.37 40.89,181.53 35.76,181.53z " />
            </g>
          </g>
          <g id="_R_G_L_2_G_M">
            <g id="_R_G_L_2_G">
              <path id="_R_G_L_2_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M66.96 227.53 C72.09,227.53 76.25,231.69 76.25,236.82 C76.25,231.69 80.41,227.53 85.54,227.53 C80.41,227.53 76.25,223.37 76.25,218.24 C76.25,223.37 72.09,227.53 66.96,227.53z " />
            </g>
          </g>
          <g id="_R_G_L_1_G_M">
            <g id="_R_G_L_1_G">
              <path id="_R_G_L_1_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M48.39 278.89 C58.64,278.89 66.96,287.2 66.96,297.45 C66.96,287.2 75.27,278.89 85.52,278.89 C75.27,278.89 66.96,270.57 66.96,260.32 C66.96,270.57 58.64,278.89 48.39,278.89z " />
            </g>
          </g>
          <g id="_R_G_L_0_G" transform=" translate(540.71, 540.024)">
            <path id="_R_G_L_0_G_D_0_P_0" fill="url(#grad1)" fillOpacity="0" fillRule="nonzero" d=" M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " />
            <path id="_R_G_L_0_G_D_1_P_0" fill="url(#grad2)" fillOpacity="0" fillRule="nonzero" d=" M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z " />
          </g>
        </g>
        <g id="time_group" />
      </svg>
    )
  } else if (props.animationType === 'cup') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width="1080" height="1080" viewBox="0 0 1080 1080" style={{ width: "100%", height: "100%" }}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="100%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "rgb(252,201,200)", stopOpacity: "1" }} />
            <stop offset="100%" style={{ stopColor: "rgba(188,128,240,1)", stopOpacity: "1" }} />
          </linearGradient>
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G" fill="freeze" attributeName="transform" from="2.7 2.7" to="2.7 2.7" type="scale" additive="sum" keyTimes="0;0.0769231;0.2;0.2615385;0.3846154;0.4769231;0.5230769;1" values="2.7 2.7;0 2.7;2.7 2.7;2.7 2.7;1.377 1.377;2.97 2.97;2.7 2.7;2.7 2.7" keySplines="0.167 0 0.833 1;0.167 0 0.667 1;0.167 0 0.667 1;0.167 0 0.833 1;0.167 0 0.667 1;0.167 0 0.833 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_1_G" fill="freeze" attributeName="transform" from="-104 -110.5" to="-104 -110.5" type="translate" additive="sum" keyTimes="0;1" values="-104 -110.5;-104 -110.5" keySplines="0 0 1 1" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_12_G" fill="freeze" keyTimes="0;0.3692308;0.4769231;0.5230769;0.7538462;1" path="M206.61 829.7 C206.61,829.7 206.61,829.7 206.61,829.7 C206.61,829.7 206.61,829.7 206.61,829.7 C206.61,823.02 206.61,779.82 206.61,789.59 C206.61,799.36 206.61,871.87 206.61,888.33 C206.61,888.33 206.61,888.33 206.61,888.33 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_12_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.4769231;0.5692308;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_12_G" fill="freeze" attributeName="transform" from="-66.956 -278.885" to="-66.956 -278.885" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-66.956 -278.885;-66.956 -278.885;-66.956 -278.885" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_12_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_11_G" fill="freeze" keyTimes="0;0.3692308;0.5230769;0.5692308;0.8;1" path="M235.27 652.72 C235.27,652.72 235.27,652.72 235.27,652.72 C235.27,652.72 235.27,652.72 235.27,652.72 C235.27,645.52 235.27,599.75 235.27,609.52 C235.27,619.29 235.27,694.38 235.27,711.35 C235.27,711.35 235.27,711.35 235.27,711.35 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_11_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5230769;0.6153846;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_11_G" fill="freeze" attributeName="transform" from="-76.246 -227.529" to="-76.246 -227.529" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-76.246 -227.529;-76.246 -227.529;-76.246 -227.529" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_11_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_10_G" fill="freeze" keyTimes="0;0.3692308;0.5384615;0.5846154;0.8153846;1" path="M139.01 510.79 C139.01,510.79 139.01,510.79 139.01,510.79 C139.01,510.79 139.01,510.79 139.01,510.79 C139.01,500.5 139.01,434.67 139.01,449.07 C139.01,463.47 139.01,572.5 139.01,597.19 C139.01,597.19 139.01,597.19 139.01,597.19 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_10_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5384615;0.6307692;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_10_G" fill="freeze" attributeName="transform" from="-45.048 -181.533" to="-45.048 -181.533" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-45.048 -181.533;-45.048 -181.533;-45.048 -181.533" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_10_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_9_G" fill="freeze" keyTimes="0;0.3692308;0.4923077;0.5384615;0.7692308;1" path="M129.75 307.64 C129.75,307.64 129.75,307.64 129.75,307.64 C129.75,307.64 129.75,307.64 129.75,307.64 C129.75,297.36 129.75,232.56 129.75,245.93 C129.75,259.3 129.75,364.21 129.75,387.87 C129.75,387.87 129.75,387.87 129.75,387.87 " keyPoints="0;0;0;0.32;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_9_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.4923077;0.5846154;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_9_G" fill="freeze" attributeName="transform" from="-42.048 -115.699" to="-42.048 -115.699" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-42.048 -115.699;-42.048 -115.699;-42.048 -115.699" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_9_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_8_G" fill="freeze" keyTimes="0;0.3692308;0.5846154;0.6307692;0.8615385;1" path="M243.26 170.71 C243.26,170.71 243.26,170.71 243.26,170.71 C243.26,170.71 243.26,170.71 243.26,170.71 C243.26,161.84 243.26,110.02 243.26,117.48 C243.26,124.94 243.26,199.12 243.26,215.45 C243.26,215.45 243.26,215.45 243.26,215.45 " keyPoints="0;0;0;0.36;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_8_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5846154;0.6769231;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_8_G" fill="freeze" attributeName="transform" from="-78.834 -57.822" to="-78.834 -57.822" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-78.834 -57.822;-78.834 -57.822;-78.834 -57.822" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_8_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_7_G" fill="freeze" keyTimes="0;0.3692308;0.5384615;0.5846154;0.8153846;1" path="M407.57 107.18 C407.57,107.18 407.57,107.18 407.57,107.18 C407.57,107.18 407.57,107.18 407.57,107.18 C407.57,102.17 407.57,71.95 407.57,77.09 C407.57,82.24 407.57,127.88 407.57,138.04 C407.57,138.04 407.57,138.04 407.57,138.04 " keyPoints="0;0;0;0.34;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_7_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5384615;0.6307692;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_7_G" fill="freeze" attributeName="transform" from="-132.082 -12.734" to="-132.082 -12.734" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-132.082 -12.734;-132.082 -12.734;-132.082 -12.734" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_7_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_6_G" fill="freeze" keyTimes="0;0.3692308;0.5230769;0.5692308;0.8;1" path="M525.79 149.38 C525.79,149.38 525.79,149.38 525.79,149.38 C525.79,149.38 525.79,149.38 525.79,149.38 C525.79,140.12 525.79,80.46 525.79,93.84 C525.79,107.21 525.79,206.98 525.79,229.61 C525.79,229.61 525.79,229.61 525.79,229.61 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_6_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5230769;0.6153846;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_6_G" fill="freeze" attributeName="transform" from="-170.395 -64.41" to="-170.395 -64.41" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-170.395 -64.41;-170.395 -64.41;-170.395 -64.41" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_6_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.4;0.4000015;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_5_G" fill="freeze" keyTimes="0;0.3692308;0.6153846;0.6615385;0.8923077;1" path="M669.86 117.96 C669.86,117.96 669.86,117.96 669.86,117.96 C669.86,117.96 669.86,117.96 669.86,117.96 C669.86,111.91 669.86,77.2 669.86,81.7 C669.86,86.2 669.86,134.41 669.86,144.96 C669.86,144.96 669.86,144.96 669.86,144.96 " keyPoints="0;0;0;0.37;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_5_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.6153846;0.7076923;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_5_G" fill="freeze" attributeName="transform" from="-217.083 -31.976" to="-217.083 -31.976" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-217.083 -31.976;-217.083 -31.976;-217.083 -31.976" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_5_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.4;0.4000015;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_4_G" fill="freeze" keyTimes="0;0.3692308;0.5076923;0.5538462;0.7846154;1" path="M828.52 168.18 C828.52,168.18 828.52,168.18 828.52,168.18 C828.52,168.18 828.52,168.18 828.52,168.18 C828.52,158.92 828.52,98.24 828.52,112.64 C828.52,127.04 828.52,230.92 828.52,254.58 C828.52,254.58 828.52,254.58 828.52,254.58 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_4_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5076923;0.6;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_4_G" fill="freeze" attributeName="transform" from="-268.5 -70.503" to="-268.5 -70.503" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-268.5 -70.503;-268.5 -70.503;-268.5 -70.503" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_4_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3538477;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_3_G" fill="freeze" keyTimes="0;0.3692308;0.5384615;0.5846154;0.8153846;1" path="M964.48 275.89 C964.48,275.89 964.48,275.89 964.48,275.89 C964.48,275.89 964.48,275.89 964.48,275.89 C964.48,264.06 964.48,186.92 964.48,204.92 C964.48,222.92 964.48,354.06 964.48,383.89 C964.48,383.89 964.48,383.89 964.48,383.89 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_3_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5384615;0.6307692;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_3_G" fill="freeze" attributeName="transform" from="-312.562 -105.409" to="-312.562 -105.409" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-312.562 -105.409;-312.562 -105.409;-312.562 -105.409" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_3_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.4;0.4000015;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_2_G" fill="freeze" keyTimes="0;0.3692308;0.6153846;0.6615385;0.8923077;1" path="M975.99 476.52 C975.99,476.52 975.99,476.52 975.99,476.52 C975.99,476.52 975.99,476.52 975.99,476.52 C975.99,464.17 975.99,384.46 975.99,402.46 C975.99,420.46 975.99,554.17 975.99,584.52 C975.99,584.52 975.99,584.52 975.99,584.52 " keyPoints="0;0;0;0.3;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_2_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.6153846;0.7076923;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_2_G" fill="freeze" attributeName="transform" from="-316.294 -170.426" to="-316.294 -170.426" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-316.294 -170.426;-316.294 -170.426;-316.294 -170.426" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_2_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.4307692;0.4307708;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_1_G" fill="freeze" keyTimes="0;0.3692308;0.4769231;0.5230769;0.7538462;1" path="M865.32 674.32 C865.32,674.32 865.32,674.32 865.32,674.32 C865.32,674.32 865.32,674.32 865.32,674.32 C865.32,663 865.32,591.52 865.32,606.43 C865.32,621.35 865.32,737.57 865.32,763.8 C865.32,763.8 865.32,763.8 865.32,763.8 " keyPoints="0;0;0;0.31;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_1_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.4769231;0.5692308;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_1_G" fill="freeze" attributeName="transform" from="-280.428 -227.529" to="-280.428 -227.529" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-280.428 -227.529;-280.428 -227.529;-280.428 -227.529" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_1_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692308;0.3692323;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animateMotion repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_0_G" fill="freeze" keyTimes="0;0.3692308;0.5692308;0.6153846;0.8461538;1" path="M821.97 848.94 C821.97,848.94 821.97,848.94 821.97,848.94 C821.97,848.94 821.97,848.94 821.97,848.94 C821.97,837.63 821.97,770.77 821.97,781.06 C821.97,791.34 821.97,889.06 821.97,910.66 C821.97,910.66 821.97,910.66 821.97,910.66 " keyPoints="0;0;0;0.35;1;1" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_0_G" fill="freeze" attributeName="transform" from="0 0" to="2.41307 2.41307" type="scale" additive="sum" keyTimes="0;0.3692308;0.5692308;0.6615385;1" values="0 0;0 0;0 0;2.41307 2.41307;2.41307 2.41307" keySplines="0.333 0 0.667 1;0.333 0 0.667 1;0.333 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animateTransform repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_0_G" fill="freeze" attributeName="transform" from="-266.378 -291.121" to="-266.378 -291.121" type="translate" additive="sum" keyTimes="0;0.3692308;1" values="-266.378 -291.121;-266.378 -291.121;-266.378 -291.121" keySplines="0 0 1 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_L_0_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.4;0.4000015;1" values="0;0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="2.6s" begin="0s" xlinkHref="#_R_G_L_0_G_M" fill="freeze" attributeName="opacity" from="0" to="1" keyTimes="0;0.3692308;0.3692323;1" values="0;0;1;1" keySplines="0 0 0 0;0 0 0 0;0 0 0 0" calcMode="spline" />
          <animate attributeType="XML" attributeName="opacity" dur="3s" from="0" to="1" xlinkHref="#time_group" />
        </defs>
        <g id="_R_G">
          <g id="_R_G_L_1_G" transform=" translate(540, 602)">
            <path id="_R_G_L_1_G_D_0_P_0" fill="url(#grad1)" fillOpacity="1" fillRule="nonzero" d=" M202.19 27.24 C200.1,24.7 196.12,21.67 188.96,21.67 C188.96,21.67 185.13,21.67 185.13,21.67 C185.13,21.67 185.13,33.92 185.13,33.92 C185.13,33.92 188.96,33.92 188.96,33.92 C190.27,33.92 191.98,34.11 192.71,35 C193.24,35.65 194.33,37.8 193.16,43.94 C193.16,43.94 177.73,87.89 177.73,87.89 C177.73,87.89 160.76,96.16 160.76,96.16 C160.93,95.23 161.09,94.27 161.23,93.29 C161.23,93.29 178.39,0.25 178.39,0.25 C178.39,0.25 29.52,0.25 29.52,0.25 C29.52,0.25 33.46,21.67 33.46,21.67 C33.46,21.67 18.95,21.67 18.95,21.67 C11.79,21.67 7.8,24.7 5.72,27.24 C0.25,33.91 2.18,43.57 2.82,46.75 C2.82,46.75 2.9,47.17 2.9,47.17 C2.9,47.17 20.28,96.69 20.28,96.69 C20.28,96.69 35.11,103.92 35.11,103.92 C35.11,103.92 40.48,92.91 40.48,92.91 C40.48,92.91 30.17,87.89 30.17,87.89 C30.17,87.89 14.75,43.94 14.75,43.94 C13.58,37.8 14.66,35.65 15.19,35 C15.93,34.11 17.64,33.92 18.95,33.92 C18.95,33.92 35.72,33.92 35.72,33.92 C35.72,33.92 46.67,93.29 46.67,93.29 C51.34,124.55 67.83,137.29 75.64,141.66 C75.64,141.66 75.64,160.69 75.64,160.69 C75.64,160.69 87.89,160.69 87.89,160.69 C87.89,160.69 87.89,133.45 87.89,133.45 C87.89,133.45 83.76,132.03 83.76,132.03 C83.56,131.96 63.72,124.75 58.77,91.39 C58.77,91.39 44.23,12.5 44.23,12.5 C44.23,12.5 163.68,12.5 163.68,12.5 C163.68,12.5 149.17,91.18 149.17,91.18 C149.17,91.18 149.13,91.39 149.13,91.39 C144.26,124.28 124.91,131.74 124.15,132.03 C124.15,132.03 120.02,133.45 120.02,133.45 C120.02,133.45 120.02,160.69 120.02,160.69 C120.02,160.69 132.26,160.69 132.26,160.69 C132.26,160.69 132.26,141.66 132.26,141.66 C138.29,138.29 149.49,129.92 156.43,111.9 C156.43,111.9 187.62,96.69 187.62,96.69 C187.62,96.69 205.01,47.17 205.01,47.17 C205.01,47.17 205.09,46.75 205.09,46.75 C205.73,43.57 207.66,33.91 202.19,27.24z " />
            <path id="_R_G_L_1_G_D_1_P_0" fill="url(#grad1)" fillOpacity="1" fillRule="nonzero" d=" M42.88 188.77 C42.88,188.77 42.88,195.97 42.88,195.97 C42.88,195.97 55.13,195.97 55.13,195.97 C55.13,195.97 55.13,188.77 55.13,188.77 C55.13,186.89 56.66,185.36 58.54,185.36 C58.54,185.36 152.81,185.36 152.81,185.36 C152.81,185.36 152.81,204.97 152.81,204.97 C152.81,206.85 151.28,208.38 149.4,208.38 C149.4,208.38 42.9,208.38 42.9,208.38 C42.9,208.38 42.9,220.63 42.9,220.63 C42.9,220.63 149.4,220.63 149.4,220.63 C158.03,220.63 165.06,213.6 165.06,204.97 C165.06,204.97 165.06,173.11 165.06,173.11 C165.06,173.11 58.54,173.11 58.54,173.11 C49.91,173.11 42.88,180.14 42.88,188.77z " />
          </g>
          <g id="_R_G_L_0_G_M">
            <g id="_R_G_L_0_G" transform=" translate(540, 496) translate(-540, -493.5)"><g id="_R_G_L_0_G_L_12_G_M"><g id="_R_G_L_0_G_L_12_G">
              <path id="_R_G_L_0_G_L_12_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M48.39 278.89 C58.64,278.89 66.96,287.2 66.96,297.45 C66.96,287.2 75.27,278.89 85.52,278.89 C75.27,278.89 66.96,270.57 66.96,260.32 C66.96,270.57 58.64,278.89 48.39,278.89z " />
            </g>
            </g>
              <g id="_R_G_L_0_G_L_11_G_M">
                <g id="_R_G_L_0_G_L_11_G">
                  <path id="_R_G_L_0_G_L_11_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M66.96 227.53 C72.09,227.53 76.25,231.69 76.25,236.82 C76.25,231.69 80.41,227.53 85.54,227.53 C80.41,227.53 76.25,223.37 76.25,218.24 C76.25,223.37 72.09,227.53 66.96,227.53z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_10_G_M">
                <g id="_R_G_L_0_G_L_10_G">
                  <path id="_R_G_L_0_G_L_10_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M35.76 181.53 C40.89,181.53 45.05,185.69 45.05,190.82 C45.05,185.69 49.21,181.53 54.34,181.53 C49.21,181.53 45.05,177.37 45.05,172.24 C45.05,177.37 40.89,181.53 35.76,181.53z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_9_G_M">
                <g id="_R_G_L_0_G_L_9_G">
                  <path id="_R_G_L_0_G_L_9_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M32.76 115.7 C37.89,115.7 42.05,119.86 42.05,124.99 C42.05,119.86 46.21,115.7 51.34,115.7 C46.21,115.7 42.05,111.54 42.05,106.41 C42.05,111.54 37.89,115.7 32.76,115.7z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_8_G_M">
                <g id="_R_G_L_0_G_L_8_G">
                  <path id="_R_G_L_0_G_L_8_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M60.27 57.82 C70.52,57.82 78.83,66.14 78.83,76.39 C78.83,66.14 87.15,57.82 97.4,57.82 C87.15,57.82 78.83,49.51 78.83,39.25 C78.83,49.51 70.52,57.82 60.27,57.82z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_7_G_M">
                <g id="_R_G_L_0_G_L_7_G">
                  <path id="_R_G_L_0_G_L_7_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M122.79 12.73 C127.92,12.73 132.08,17.6 132.08,23.61 C132.08,17.6 136.24,12.73 141.37,12.73 C136.24,12.73 132.08,7.86 132.08,1.86 C132.08,7.86 127.92,12.73 122.79,12.73z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_6_G_M">
                <g id="_R_G_L_0_G_L_6_G">
                  <path id="_R_G_L_0_G_L_6_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M161.11 64.41 C166.24,64.41 170.4,68.57 170.4,73.7 C170.4,68.57 174.55,64.41 179.68,64.41 C174.55,64.41 170.4,60.25 170.4,55.12 C170.4,60.25 166.24,64.41 161.11,64.41z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_5_G_M">
                <g id="_R_G_L_0_G_L_5_G">
                  <path id="_R_G_L_0_G_L_5_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M207.79 31.98 C212.93,31.98 217.08,36.14 217.08,41.27 C217.08,36.14 221.24,31.98 226.37,31.98 C221.24,31.98 217.08,27.82 217.08,22.69 C217.08,27.82 212.93,31.98 207.79,31.98z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_4_G_M">
                <g id="_R_G_L_0_G_L_4_G">
                  <path id="_R_G_L_0_G_L_4_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M249.93 70.5 C260.19,70.5 268.5,78.82 268.5,89.07 C268.5,78.82 276.81,70.5 287.07,70.5 C276.81,70.5 268.5,62.19 268.5,51.94 C268.5,62.19 260.19,70.5 249.93,70.5z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_3_G_M">
                <g id="_R_G_L_0_G_L_3_G">
                  <path id="_R_G_L_0_G_L_3_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M303.27 105.41 C308.4,105.41 312.56,109.57 312.56,114.7 C312.56,109.57 316.72,105.41 321.85,105.41 C316.72,105.41 312.56,101.25 312.56,96.12 C312.56,101.25 308.4,105.41 303.27,105.41z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_2_G_M">
                <g id="_R_G_L_0_G_L_2_G">
                  <path id="_R_G_L_0_G_L_2_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M307.01 170.43 C312.14,170.43 316.29,174.58 316.29,179.72 C316.29,174.58 320.45,170.43 325.58,170.43 C320.45,170.43 316.29,166.27 316.29,161.14 C316.29,166.27 312.14,170.43 307.01,170.43z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_1_G_M">
                <g id="_R_G_L_0_G_L_1_G">
                  <path id="_R_G_L_0_G_L_1_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M261.86 227.53 C272.12,227.53 280.43,235.84 280.43,246.1 C280.43,235.84 288.74,227.53 299,227.53 C288.74,227.53 280.43,219.22 280.43,208.96 C280.43,219.22 272.12,227.53 261.86,227.53z " />
                </g>
              </g>
              <g id="_R_G_L_0_G_L_0_G_M">
                <g id="_R_G_L_0_G_L_0_G">
                  <path id="_R_G_L_0_G_L_0_G_D_0_P_0" fill="#ffffff" fillOpacity="1" fillRule="nonzero" d=" M257.09 291.12 C262.22,291.12 266.38,295.28 266.38,300.41 C266.38,295.28 270.54,291.12 275.67,291.12 C270.54,291.12 266.38,286.96 266.38,281.83 C266.38,286.96 262.22,291.12 257.09,291.12z " />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="time_group" />
      </svg>
    )
  } else if (props.animationType === 'simple_checkmark') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" width="400" height="400" viewBox="0 0 400 400" style={{ width: '100%', height: '100%' }}>
        <defs>
          <animate repeatCount="1" dur="0.8s" begin="0s" xlinkHref={"#_R_G_L_0_G_D_0_P_0" + props.position}fill="freeze" attributeName="fill-opacity" from="0" to="1" keyTimes="0;0.05;1" values="0;1;1" keySplines="0.167 0.167 0.833 0.833;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="0.8s" begin="0s" xlinkHref={"#_R_G_L_0_G_D_0_P_0" + props.position} fill="freeze" attributeName="d" attributeType="XML" from="M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " to="M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " keyTimes="0;0.25;1" values="M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z ;M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z ;M0.25 66.54 C0.25,66.54 47.99,122.41 47.99,122.41 C47.99,122.41 59.28,109.25 59.28,109.25 C59.28,109.25 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " keySplines="0.581 0 0.711 1;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="0.8s" begin="0s" xlinkHref={"#_R_G_L_0_G_D_1_P_0" + props.position} fill="freeze" attributeName="fill-opacity" from="0" to="1" keyTimes="0;0.2;0.25;1" values="0;0;1;1" keySplines="0.167 0.167 0.833 0.833;0.167 0.167 0.833 0.833;0 0 0 0" calcMode="spline" />
          <animate repeatCount="1" dur="0.8s" begin="0s" xlinkHref={"#_R_G_L_0_G_D_1_P_0" + props.position} fill="freeze" attributeName="d" attributeType="XML" from="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z " to="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z " keyTimes="0;0.25;0.5;1" values="M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z ;M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 200.25,11.37 200.25,11.37 C200.25,11.37 187.26,0.25 187.26,0.25 C187.26,0.25 65.11,142.51 65.11,142.51z " keySplines="0.525 0 0.667 1;0.525 0 0.667 1;0 0 0 0" calcMode="spline" />
          <animate attributeType="XML" attributeName="opacity" dur="1s" from="0" to="1" xlinkHref={"#time_group" + props.position} />
        </defs>
        <g id={"_R_G" + props.position}>
          <g id={"_R_G_L_0_G" + props.position} transform=" translate(200, 200) scale(1.98787, 1.98787) translate(-100.5, -78)">
            <path id={"_R_G_L_0_G_D_0_P_0" + props.position} fill={props.color} fillOpacity="0" fillRule="nonzero" d=" M0.25 66.54 C0.25,66.54 0.23,66.51 0.23,66.51 C0.23,66.51 13.28,55.39 13.28,55.39 C13.28,55.39 13.24,55.43 13.24,55.43 C13.24,55.43 0.25,66.54 0.25,66.54z " />
            <path id={"_R_G_L_0_G_D_1_P_0" + props.position} fill={props.color} fillOpacity="0" fillRule="nonzero" d=" M65.11 142.51 C65.11,142.51 76.39,155.73 76.39,155.73 C76.39,155.73 76.37,155.71 76.37,155.71 C76.37,155.71 65.11,142.52 65.11,142.52 C65.11,142.52 65.11,142.51 65.11,142.51z " />
          </g>
        </g>
        <g id={"time_group" + props.position} />
      </svg>
    )
  }
  return (
    <>
      Animation is broken.
    </>
  )
}

export default SvgAnimation;
