import React from 'react';
import {changeUrl} from "../../services/Navigation"
import Api from '../../services/Api';
import Loading from '../Loading';
import {IonModal} from '@ionic/react';
import {formatDate, formatHourMinute, formatSoon} from '../../helpers/dateFunctions';
import ClientMeasureAnalysis from '../client/ClientMeasureAnalysis';
import ClientProgram from "../client/ClientProgram";
import {CoachInterface} from '../../interfaces/Coach';
import ClientMeasureDevelopment from '../client/ClientMeasureDevelopment';
import {ClientInterface, SuggestClientInterface} from "../../interfaces/Client";
import CoachClientNotFound from './CoachClientNotFound';
import {MeetingInterface, MeetingSuggestionInterface, MeetingType} from "../../interfaces/Meeting";
import Breadcrumbs from "../menu/Breadcrumbs";
import CoachNotes from "./CoachNotes";
import DisaRomb from "../graphics/DisaRomb";
import {DisaInterface} from "../../interfaces/Disa";
import {MeetingSchemaInterface} from "../meetingSchema/interface";
import {goodAt, toFeelGood, youAre, youCreate, youNeed, youSeek, youWant} from "../texts/behaviour";
import ClientMeasureSummary from "../client/ClientMeasureSummary";
import ClientHistory from "./ClientHistory";
import Tasks from "../tasks/Tasks";
import MeetingButton from "../MeetingButton";
import CalendarCoach from "./CalendarCoach";
import ClientTasks2 from "./ClientTasks2";
import {FocusAreaSettingsInterface, FocusMeasureInterface} from "../tasks/interfaces";
import MeetingSchema from "../meetingSchema/MeetingSchema";
import {showMeetingSuggestionStatus} from "../meeting_suggestions/meetingSuggestions";
import {PackageType} from "../../interfaces/Package";
import {CustomerIntroductionInterface} from "../../interfaces/CustomerIntroduction";
import ClientProgress from "../client/ClientProgress";
import FocusAreaLink from "../focus_area/FocusAreaLink";
import FocusAreaSummary from "../focus_area/FocusAreaSummary";
import { TrVar } from "../../services/translate"

interface ComponentProps {
  navigation: any
}

interface PreviousCoachingInterface {
  start: string
  end: string
  meetings: number
  withYou: boolean
}

interface ComponentState {
  coach: CoachInterface;
  client: ClientInterface;
  suggestClient: SuggestClientInterface
  clientLicense: any;
  loaded: boolean;
  showMoreDisa: boolean;
  showSuggestedMeetings: boolean
  meetingSuggestions: Array<MeetingSuggestionInterface>
  showNotes: boolean;
  notFound: boolean;
  measures: Array<any>;
  plansDone: number
  lastPowerArea: any;
  doneMeetings: number;
  otherCoachMeetings: number;
  futureMeetings: Array<MeetingInterface>;
  nextMeeting: MeetingInterface;
  picture: string;
  coachLanguages: Array<string>;
  disa?: Array<DisaInterface>;
  coachClientTexts?: Array<string>;
  showMeetingSuggestion: boolean
  focusTasks: number
  focusAreas: Array<FocusMeasureInterface>
  focusAreaSettings: Array<FocusAreaSettingsInterface>
  powerTasks: number
  customerName: string
  meetingSchema: MeetingSchemaInterface
  customerIntroduction?: CustomerIntroductionInterface
  previousCoaching: Array<PreviousCoachingInterface>
  showPreviousCoaching: boolean
  customerPrograms: number
  frameworks: number
}

class CoachClientCard extends React.Component<ComponentProps, ComponentState> {
  state: Readonly<ComponentState> = {
    client: {} as ClientInterface,
    suggestClient: {} as SuggestClientInterface,
    coach: {} as CoachInterface,
    measures: [],
    plansDone: 0,
    clientLicense: {},
    loaded: false,
    showMoreDisa: false,
    showSuggestedMeetings: false,
    meetingSuggestions: [],
    showNotes: false,
    lastPowerArea: null,
    notFound: false,
    doneMeetings: 0,
    otherCoachMeetings: 0,
    futureMeetings: [],
    nextMeeting: {} as MeetingInterface,
    picture: '',
    coachLanguages: [],
    showMeetingSuggestion: false,
    focusTasks: 0,
    focusAreas: [],
    focusAreaSettings: [],
    powerTasks: 0,
    customerName: '',
    meetingSchema: {} as MeetingSchemaInterface,
    customerIntroduction: {} as CustomerIntroductionInterface,
    showPreviousCoaching: false,
    previousCoaching: [],
    customerPrograms: 0,
    frameworks: 0
  };

  private api = Api.getInstance();

  private getData = () => {
    this.api.get('coach_client/' + this.props.navigation.client, ['client_not_found'])
    .then((response) => {
      this.setState({
        coach: response.json.coach,
        client: response.json.client,
        suggestClient: response.json.suggestClient,
        clientLicense: response.json.clientLicense,
        doneMeetings: response.json.doneMeetings,
        otherCoachMeetings: response.json.otherCoachMeetings,
        futureMeetings: response.json.futureMeetings,
        nextMeeting: response.json.nextMeeting,
        disa: response.json.disa,
        coachClientTexts: response.json.coachClientTexts,
        measures: response.json.measures,
        lastPowerArea: response.json.lastPowerArea,
        coachLanguages: response.json.coachLanguages,
        picture: response.json.client.picture,
        powerTasks: response.json.powerTasks,
        focusTasks: response.json.focusTasks,
        focusAreaSettings: response.json.focusAreaSettings,
        focusAreas: response.json.focusAreas,
        customerName: response.json.customerName,
        meetingSchema: response.json.meetingSchema,
        meetingSuggestions: response.json.meetingSuggestions,
        customerIntroduction: response.json.customerIntroduction,
        plansDone: response.json.plansDone,
        previousCoaching: response.json.previousCoaching,
        customerPrograms: response.json.customerPrograms,
        frameworks: response.json.frameworks,
        loaded: true
      })
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({loaded: true, notFound: true})
      }
    });
  };

  componentDidMount() {
    this.getData();
  }

  goToMenu = () => {
    changeUrl('/app/coach/clients/' + this.props.navigation.client)
  }

  openPowerTasks = () => {
    changeUrl('/app/coach/client_tasks/' + this.props.navigation.client)
  };

  changePage = () => {
    console.log('Should not happen!');
  };

  showMeetingStatus = (meetingType: number) => {
    let status = ''
    if (meetingType === 1) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === 2) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      return (
        <div className="mt-4 error_red">{status}</div>
      )
    }
    return (<></>)
  }

  closeNotes = () => {
    this.setState({showNotes: false})
  }

  showLicensetext = (meetingPeriod?: number) => {
    if (meetingPeriod === 14) {
      return this.api.trTxt(TrVar.MeetingEveryOtherWeek)
    } else if (meetingPeriod === 28) {
      return this.api.trTxt(TrVar.MeetingEveryFourWeeks)
    }
    return ''
  }

  showMeetingSummary = (doneMeetings: number, otherCoachMeetings: number, meetingCount: number) => {
    const meetingNr = doneMeetings + otherCoachMeetings
    let meetingWithYou = ""
    if (otherCoachMeetings > 0) {
      meetingWithYou = "" + doneMeetings
      if (meetingCount > 0) {
        meetingWithYou += " " + this.api.trTxt(TrVar.Of).toLowerCase()  + " " +
          (meetingCount - otherCoachMeetings)
      }
      meetingWithYou += " " + this.api.trTxt(TrVar.WithYou).toLowerCase()
    }
    return (
      <>
        {meetingNr}
        {
          meetingCount > 0 &&
            <>
              &nbsp;{this.api.trTxt(TrVar.Of).toLowerCase()}
              &nbsp;{meetingCount}
            </>
        }
        {
          meetingWithYou.length > 0 &&
            <>
              &nbsp;({meetingWithYou})
            </>
        }
      </>
    )
  }

  subPage = (subUrl: string) => {
    changeUrl('/app/coach/clients/' + this.props.navigation.client + '/' + subUrl)
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }
    if (this.state.notFound) {
      return (
        <CoachClientNotFound />
      )
    }

    const clientAreas = this.state.client.areas;
    const coachAreas = this.state.coach.areas;

    const clientName = this.state.client.firstName + ' ' + this.state.client.lastName

    if (this.props.navigation.page === 'meeting_history') {
      return (<ClientHistory clientId={this.props.navigation.client}/>)
    } else if (this.props.navigation.page === 'meeting_suggestion') {
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs name={this.api.trTxt(TrVar.SuggestMeeting)}
                       breadcrumbs={[
                         {
                           name: this.api.trTxt(TrVar.MyClients),
                           link: '/app/coach/clients'
                         },
                         {
                           name: this.state.client.firstName,
                           link: '/app/coach/clients/' + this.state.client.id
                         }
                       ]}/>
            <CalendarCoach suggestClient={this.state.suggestClient} close={() => this.goToMenu()}/>
        </div>

      )
    } else if (this.props.navigation.page === 'dashboard') {
      return (
        <div className="page_content">
          <Breadcrumbs name={this.api.trTxt(TrVar.ClientDashboard)}
                       breadcrumbs={[
                         {name: this.api.trTxt(TrVar.MyClients), link:'/app/coach/clients'},
                         {name: clientName, link: '/app/coach/clients/' + this.props.navigation.client}
                       ]}/>
          <div className="flex client_dashboard_direction top_margin">
            <FocusAreaLink coach={true} measures={this.state.measures} focusMeasures={this.state.focusAreas}
                           focusAreaSettings={this.state.focusAreaSettings}/>
            <FocusAreaSummary measures={this.state.measures} focusMeasures={this.state.focusAreas}
                              plansDone={this.state.plansDone}/>
          </div>
          <div className="top_margin"/>
          <ClientMeasureSummary measures={this.state.measures} />
        </div>
      )
    } else if (this.props.navigation.page === 'development') {
      let areaNr = -1
      if (this.props.navigation.sub1.length === 1) {
        areaNr = parseInt(this.props.navigation.sub1)
      }
      return (
        <ClientMeasureDevelopment
          coachId={this.state.coach.id}
          clientId={this.props.navigation.client}
          summary=''
          close={this.goToMenu}
          areaNr={areaNr}
          navigation={this.props.navigation}
        />
      );
    } else if (this.props.navigation.page === 'progress') {
      return (
        <ClientProgress
          coachId={this.state.coach.id}
          clientId={this.props.navigation.client}
          close={this.goToMenu}
          navigation={this.props.navigation}
        />
      );
    } else if (this.props.navigation.page === 'programs') {
      return (
        <ClientProgram
          close={this.goToMenu}
          client={this.state.client}
        />
      );
    } else if (this.props.navigation.page === 'measure') {
      if (this.props.navigation.sub1 !== '') {
        const measureId = parseInt(this.props.navigation.sub1)
        return (
          <ClientMeasureAnalysis
            coachId={this.state.coach.id}
            changePage={this.changePage}
            measureId={measureId}
            close={this.goToMenu}
          />
        )
      }
    } else if (this.props.navigation.page === 'tasks') {
      return (<Tasks navigation={this.props.navigation}/>)
    } else if (this.props.navigation.page === 'tasks2') {
      return (<ClientTasks2 clientId={this.props.navigation.client} navigation={this.props.navigation}/>)
    }

    let primaryKey = ''
    let secondaryKey = ''
    if (this.state.disa) {
      const primaryProp: DisaInterface = this.state.disa[0]
      const secondaryProp: DisaInterface = this.state.disa[1]
      primaryKey = primaryProp.key
      secondaryKey = secondaryProp.key
    }

    return (
      <div className="page_content">
        <Breadcrumbs name={clientName}
                     breadcrumbs={[{name: this.api.trTxt(TrVar.MyClients), link:'/app/coach/clients'}]}/>
        <IonModal isOpen={this.state.showNotes} className="modal__small"
                  onDidDismiss={() => this.setState({showNotes: false})}>
          {
            this.state.showNotes &&
            <CoachNotes clientId={this.state.client.id} close={this.closeNotes}/>
          }

        </IonModal>

        <div className="page_section">
          <h1>{clientName}</h1>
          <div className="page_bar"/>
          <div className="flex flex-wrap justify-between section_direction">
            <div className="text_center my-auto">
              <img className="round" height={'auto'} width={'auto'} src={this.state.picture} alt="Client" />
            </div>
            <div className="flex flex-col r_left_margin justify-between">
              <div>
                <h4 className="blue">{this.api.trTxt(TrVar.Age)}</h4>
                <h4>{this.state.client.age}</h4>                
              </div>
              <div>
                <h4 className="top_margin blue">{this.api.trTxt(TrVar.WorkPlace)}</h4>
                <h4>{this.state.customerName}</h4>
              </div>
              <div>
                <h4 className="blue top_margin">{this.api.trTxt(TrVar.CoachingLanguage)}</h4>
                <h4>{this.state.coachLanguages.join(', ')}</h4>
              </div>

            </div>
            <div className="flex flex-col justify-between r_top_margin r_left_margin">

              <div>
                <h4 className="blue">{this.api.trTxt(TrVar.Meetings)}</h4>
                <h4>
                  {this.showMeetingSummary(this.state.doneMeetings, this.state.otherCoachMeetings,
                    this.state.clientLicense?.meetingCount)}
                </h4>
              </div>
              <div>
                <h4 className="blue  top_margin">{this.api.trTxt(TrVar.License)}</h4>
                <h4>{this.state.client.package?.name}</h4>
                <p>{this.showLicensetext(this.state.client.package?.meetingPeriod)}</p>
              </div>
              <div>
                <h4 className="blue top_margin">{this.api.trTxt(TrVar.LicenseIsValidUpTo)}</h4>
                <h4>
                  {this.state.clientLicense?.endDate ?
                      this.state.clientLicense.endDate
                      :
                      this.api.trTxt(TrVar.NoEndDate)
                  }</h4>
              </div>
            </div>
            <div className="flex flex-col justify-between r_top_margin r_left_margin">
              <div>
                <h4 className="blue">{this.api.trTxt(TrVar.NextMeeting)}</h4>
                <h4>
                  {
                    this.state.nextMeeting ? (
                      formatSoon(this.state.nextMeeting.start, this.api.lang)
                    ) : (
                      <>-</>
                    )
                  }
                </h4>
                {
                  this.state.nextMeeting &&
                    <div className="top_margin">
                        <MeetingButton who={"coach"} meeting={this.state.nextMeeting}
                                       name={this.state.coach.firstName}/>
                    </div>
                }
                {
                  (this.state.nextMeeting && this.state.nextMeeting.meetingType === MeetingType.BOOKED) &&
                    <div className="top_margin">
                        <p className="cursor-pointer underline"
                            onClick={() => changeUrl('/app/coach/bookings/reschedule/' + this.state.nextMeeting.id)}>
                          {this.api.trTxt(TrVar.ChangeBooking)}
                        </p>
                    </div>
                }
              </div>
              <div className="top_margin cursor-pointer underline"
                   onClick={() => changeUrl('/app/coach/chat/' + this.state.client.id)}>
                {this.api.trTxt(TrVar.GoToChat)}
              </div>
            </div>
          </div>

          {
            this.state.customerIntroduction?.coachText &&
              <>
                <div className="page_bar" style={{ margin:'20px 0' }} />
                <div>
                  <h4>
                    {this.api.trTxt(TrVar.MessageFromCustomer)}:
                  </h4>
                  <p className="mt-4 whitespace-pre-line">
                    {this.state.customerIntroduction.coachText}
                  </p>
                </div>
              </>
          }

          {
            this.state.frameworks > 0 &&
              <>
                  <div className="page_bar" style={{ margin:'20px 0' }} />
                  <div className="flex justify-between">
                      <div className='flex'>
                          <img src="/assets/icon/blue/customer_programs.svg" height={40} alt="programs" />
                          <div className='left_margin align-center'>
                            {this.api.trTxt(TrVar.CustomerFramework)}
                          </div>
                      </div>
                      <div className="button inline_block"
                           onClick={() => changeUrl('/app/coach/clients/' + this.state.client.id + '/programs')}>
                        {this.api.trTxt(TrVar.Show)}
                      </div>
                  </div>
              </>
          }

          <div className="page_bar" style={{ margin:'20px 0' }} />
          {
            this.state.client.package && this.state.client.package.type !== PackageType.ACCESS &&
            <>
              <MeetingSchema meetingSchema={this.state.meetingSchema} clientName={this.state.client.firstName}
                             includeHeader={true}/>
              <div className="page_bar" style={{ margin:'20px 0' }} />
            </>
          }

            <div className='flex justify-between items-center'>
                <div className='flex'>
                    <img src="/assets/icon/blue/calendar_checked.svg" height={40} alt="history" />
                    <div className='left_margin align-center'>
                      {this.api.trTxt(TrVar.PreviousCoaching)}
                    </div>
                </div>
              {
                this.state.previousCoaching.length === 0 ? (
                  <div>{this.api.trTxt(TrVar.No)}</div>
                ) : (
                  <div className="button inline_block"
                       onClick={() => this.setState({showPreviousCoaching: !this.state.showPreviousCoaching})}>
                    {this.api.trTxt(TrVar.Show)}
                  </div>
                )
              }
            </div>
            {
              this.state.showPreviousCoaching &&
                <div className="mt-4">
                  {
                    this.state.previousCoaching.map((previousCoaching: PreviousCoachingInterface, i) => {
                      return (
                        <div className="mt-2 flex" key={"prev_coaching_" + i}>
                          <p style={{minWidth: "210px"}}>{previousCoaching.start} - {previousCoaching.end}</p>
                          <p className="ml-8">
                            {previousCoaching.meetings}&nbsp;&nbsp;{this.api.trTxt(TrVar.Meetings)}&nbsp;
                            {previousCoaching.withYou ?
                              this.api.trTxt(TrVar.WithYou).toLowerCase()
                              :
                              this.api.trTxt(TrVar.WithOtherCoach).toLowerCase()
                            }
                          </p>
                        </div>
                      )
                    })
                  }
                </div>
            }
            <div className="page_bar" style={{ margin:'20px 0' }} />

          <div className='flex justify-between items-center'>
            <div className='flex'>
              <img src="/assets/icon/blue/clock.svg" height={40} alt="notes" />
              <div className='left_margin align-center'>
                {this.api.trTxt(TrVar.WhatTheClientHasDone)}
              </div>
            </div>
            <div className="button inline_block" onClick={() => this.subPage('progress')}>
              {this.api.trTxt(TrVar.Show)}
            </div>
          </div>
          <div className="page_bar" style={{ margin:'20px 0' }} />

          <div className="flex justify-between items-center">
            <div className='flex'>
              <img src="/assets/icon/blue/notes.svg" height={40} alt="notes" />
              <div className='left_margin align-center'>{this.api.trTxt(TrVar.CoachNotes)}</div>
            </div>
            <div className="button" onClick={() => this.setState({showNotes: true})}>
              {this.api.trTxt(TrVar.Show)}
            </div>
          </div>

          {
            this.state.client.package && this.state.client.package.type !== PackageType.ACCESS &&
              <>
                <div className="page_bar" style={{ margin:'20px 0' }} />
                <div className="flex justify-between items-center">
                    <div className="flex">
                        <img className='svg_blue' src="/assets/icon/calendar.svg" height={36} alt="calendar" />
                        <div className='left_margin align-center'>{this.api.trTxt(TrVar.SuggestMeeting)}</div>
                    </div>
                  {
                    this.state.meetingSuggestions.length > 0 ?
                      (
                        <div className="cursor-pointer underline"
                             onClick={() => this.setState({showSuggestedMeetings: !this.state.showSuggestedMeetings})}>
                          {this.api.trTxt(TrVar.SuggestedMeetings) + ':'}&nbsp;
                          {this.state.meetingSuggestions.length}
                        </div>
                      ) : (
                        <div>{this.api.trTxt(TrVar.NoSuggestedMeetings)}</div>
                      )
                  }
                  <div className="button" onClick={() => this.subPage('meeting_suggestion')}>
                    {this.api.trTxt(TrVar.Show)}
                  </div>
                </div>
                {
                  this.state.showSuggestedMeetings &&
                    <>
                      {
                        this.state.meetingSuggestions.map(suggestion => {
                          return (
                            <div key={"suggestion_" + suggestion.id} className="flex justify-center mt-2">
                              <div className="flex items-center mr-8">
                                <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                                {formatSoon(suggestion.start, this.api.lang)}, {suggestion.length} min
                              </div>
                              {showMeetingSuggestionStatus(this.api.lang, suggestion)}
                            </div>
                          )
                        })
                      }
                    </>
                }
                <div className="page_bar" style={{ margin:'20px 0' }} />

                <div className="flex justify-between items-center">
                    <div className="flex">
                        <img src="/assets/icon/blue/calendar_marked.svg" width={40} alt="marked calendar" />
                        <div className='left_margin align-center'>{this.api.trTxt(TrVar.MeetingHistory)}</div>
                    </div>
                    <div className="button" onClick={() => this.subPage('meeting_history')}>
                      {this.api.trTxt(TrVar.Show)}
                    </div>
                </div>
              </>
          }

          <div className="page_bar" style={{ margin:'20px 0' }} />
          <div className="flex justify-between items-center">
            <div className="flex">
              <img className='svg_blue' src="/assets/icon/overview.svg" height={40} alt="dashboard icon" />
              <div className='left_margin align-center'>{this.api.trTxt(TrVar.ClientDashboard)}</div>
            </div>
            <div className="button" onClick={() => this.subPage('dashboard')}>
              {this.api.trTxt(TrVar.Show)}
            </div>
          </div>

          {
            this.state.powerTasks > 0 &&
            <>
              <div className="page_bar" style={{ margin:'20px 0' }} />
              <div className="flex justify-between items-center">
                <div className="flex">
                  <img src="/assets/icon/blue/megaphone.svg" width={40} alt="megaphone" />
                  <div className='left_margin align-center'>
                    {this.api.trTxt(TrVar.ClientPotentialTasks)} ({this.state.powerTasks})
                  </div>
                </div>
                <div className="button" onClick={() => this.subPage('tasks')}>{this.api.trTxt(TrVar.Show)}</div>
              </div>
            </>
          }

          <div className="page_bar" style={{ margin:'20px 0' }} />
          <div className="flex justify-between items-center">
            <div className="flex">
              <img src="/assets/icon/blue/dart.svg" width={40} alt="bull's eye" />
              <div className='left_margin align-center'>
                {
                  this.api.trTxt(TrVar.AreasOfDevelopment)
                }
                &nbsp;: {this.state.focusTasks} {this.api.trTxt(TrVar.CoachQuestionsDone).toLowerCase()}
              </div>              
            </div>
            <div className="button" onClick={() => this.subPage('tasks2')}>{this.api.trTxt(TrVar.Show)}</div>
          </div>

          <div className="page_bar" style={{ margin:'20px 0' }} />
          <div className="flex justify-between items-center">
            <div className="flex">
              <img src="/assets/icon/blue/development.svg" width={40} alt="developmental chart" />
              <div className='left_margin align-center'>{this.api.trTxt(TrVar.ClientPotentialDevelopment)}</div>
            </div>
            <div className="button" onClick={() => this.subPage('development')}>
              {this.api.trTxt(TrVar.Show)}
            </div>
          </div>

          <div className="page_bar" style={{ margin:'20px 0' }}/>
          <div className="flex section_direction">
            <div className="page_half_content">
              <p>{this.state.client.firstName} {this.api.trTxt(TrVar.Is).toLowerCase()}:</p>
              {
                youAre[primaryKey][this.api.lang].map((prop: string, i: number) => {
                  return (
                    <div key={"your_are_" + i}
                         className="pa_oval_button uppercase pa_gradient_cerise inline_block top_margin right_margin">
                      {prop}
                    </div>
                  )
                })
              }

              <p className="top_margin">{this.state.client.firstName} {this.api.trTxt(TrVar.CanAlsoBe).toLowerCase()}:</p>
              {
                youAre[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                  return (
                    <div key={"your_are_also_" + i}
                         className="pa_oval_button uppercase pa_gradient_cerise inline_block top_margin right_margin">
                      {prop}
                    </div>
                  )
                })
              }
              <div className="button inline_block top_margin"
                   onClick={() => this.setState({showMoreDisa: !this.state.showMoreDisa})}>
                {this.api.trTxt(TrVar.SeeWholeProfile)}
              </div>
            </div>
            <div className="page_half_content r_top_margin r_left_margin">
              {
                this.state.disa &&
                <DisaRomb disa={this.state.disa} color="cerise"/>
              }
            </div>
          </div>

          {
            this.state.showMoreDisa &&
            <div className="top_margin">
                <div className="col-1_2">
                  {this.state.coachClientTexts?.map(
                    (txt: any, index: any) => {
                      return (
                        <p key={'txt_' + index} className="top_margin">
                          {txt}
                        </p>
                      );
                    }
                  )}
                </div>

                <div className="col-1_2 r_top_margin_early_break">
                    <div className="pa_circle_box r_left_margin">
                        <div className="pa_top pa_outer_circle pa_gradient_cerise_45">
                            <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                                <div>
                                    <h4>{this.api.trTxt(TrVar.YouSeek)}:</h4>
                                    <div dangerouslySetInnerHTML={{__html: youSeek[primaryKey][this.api.lang]}}/>
                                </div>
                            </div>
                        </div>

                        <div className="pa_left pa_outer_circle pa_gradient_cerise_45">
                            <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                                <div>
                                    <h4>{this.api.trTxt(TrVar.YouWant)}:</h4>
                                    <div dangerouslySetInnerHTML={{__html: youWant[primaryKey][this.api.lang]}}/>
                                </div>
                            </div>
                        </div>

                        <div className="pa_right pa_outer_circle pa_gradient_cerise_45">
                            <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                                <div>
                                    <h4>{this.api.trTxt(TrVar.YouNeed)}:</h4>
                                    <div dangerouslySetInnerHTML={{__html: youNeed[primaryKey][this.api.lang]}}/>
                                </div>
                            </div>
                        </div>

                        <div className="pa_bottom pa_outer_circle pa_gradient_cerise_45">
                            <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                                <div>
                                    <h4>{this.api.trTxt(TrVar.YouCreate)}:</h4>
                                    <div dangerouslySetInnerHTML={{__html: youCreate[primaryKey][this.api.lang]}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1_2 top_margin">
                    <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/brain.svg"/>
                    <h3 className="top_margin">{this.api.trTxt(TrVar.Strengths)}:</h3>
                    <h4 className="top_margin">{this.state.client.firstName} {this.api.trTxt(TrVar.IsEspeciallyGood).toLowerCase()}:</h4>
                    <ul>
                      {
                        goodAt[primaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <li key={"good_at_" + i}>
                              {prop}
                            </li>
                          )
                        })
                      }
                    </ul>

                    <h4 className="top_margin">{this.state.client.firstName} {this.api.trTxt(TrVar.IsAlsoGoodAt).toLowerCase()}:</h4>
                    <ul>
                      {
                        goodAt[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <li key={"also_good_at_" + i}>
                              {prop}
                            </li>
                          )
                        })
                      }
                    </ul>
                </div>

                <div className="col-1_2 top_margin r_left_margin">
                    <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/smiley.svg"/>
                    <h3 className="top_margin">{this.api.trTxt(TrVar.Wellbeing)}:</h3>
                    <h4 className="top_margin">
                    {this.api.trTxt(TrVar.ToFeelReallyGood)} {this.state.client.firstName}
                    </h4>
                    <ul>
                      {
                        toFeelGood[primaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <li key={"feel_good_" + i}>
                              {prop}
                            </li>
                          )
                        })
                      }
                    </ul>

                    <h4 className="top_margin">{this.state.client.firstName} {this.api.trTxt(TrVar.AlsoNeeds).toLowerCase()}:</h4>
                    <ul>
                      {
                        toFeelGood[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <li key={"also_feel_good_" + i}>
                              {prop}
                            </li>
                          )
                        })
                      }
                    </ul>
                </div>
            </div>
          }
          <div className="page_bar" style={{ margin: '20px 0' }} />
          <h3>{this.api.trTxt(TrVar.ClientsCoachingAreas)}</h3>
          <div className="top_margin flex section_direction">
            <div className="r-half-col">
              {
                clientAreas.map((area, index) => {
                  if (index < 6) {
                    if (coachAreas.includes(area)) {
                      return (
                        <div key={"area_" + index} style={{ marginTop: '10px' }} className='flex'>
                          <img src="/assets/icon/purple/check.svg" alt="" />
                          <div style={{ marginLeft: '10px', color: '#BC80F0' }}>{area}</div>
                        </div>
                      )
                    } else {
                      return (
                        <div key={"area_" + index} style={{ marginTop: '10px', marginLeft: '38px' }}>{area}</div>
                      )
                    }
                  } else {
                    return (null);
                  }
                })
              }
            </div>
            <div className="r-half-col">
              {
                clientAreas.map((area, index) => {
                  if (index >= 6) {
                    if (coachAreas.includes(area)) {
                      return (
                        <div key={index} style={{ marginTop: '10px' }} className='flex'>
                          <img src="/assets/icon/purple/check.svg" alt="" />
                          <div style={{ marginLeft: '10px', color: '#BC80F0' }}>{area}</div>
                        </div>                      )
                    } else {
                      return (
                        <div style={{ marginTop: '10px', marginLeft: '38px' }}>{area}</div>
                      )
                    }
                  } else {
                    return (null);
                  }
                })
              }
            </div> 
          </div>
          <div className="top_margin flex">
            <img src="/assets/icon/purple/check.svg" alt="" />
            <div style={{ marginLeft: '10px' }}>= {this.api.trTxt(TrVar.InCommon)}</div>
          </div>
        </div>

        <div className="page_section top_margin">
          <p>
            <b>{this.api.trTxt(TrVar.PotentialMeasurement)}</b>
          </p>
          <p className="top_margin">
            {this.state.client.firstName}{' '}
            {this.api.trTxt(TrVar.HasSharedPotential)}:
          </p>
          {this.state.measures?.map((measure: any) => {
            return (
              <div
                className="top_margin border_box r-flex justify-between items-center"
                key={'measure' + measure.id}
              >
                <div className="flex items-center">
                  <img
                    alt="calendar"
                    className="icon_small svg_white right_margin"
                    src="/assets/icon/calendar.svg"
                  />
                  {formatDate(measure.createdAt, false, this.api.lang)}
                  <img
                    alt="time"
                    className="icon_small left_margin right_margin"
                    src="/assets/icon/white/time-outline.svg"
                  />
                  {formatHourMinute(measure.createdAt)}
                </div>

                <div className="flex items-center r_left_margin r_top_margin">
                  <div className="button" onClick={() => this.subPage('measure/' + measure.id)}>
                    {this.api.trTxt(TrVar.Result)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CoachClientCard;
