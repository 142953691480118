import React, { useEffect } from 'react';
import Api from '../../services/Api';
import Breadcrumbs from "../menu/Breadcrumbs";
import { LogEventInterface, LogEventType } from "../../interfaces/LogEvent";
import { TrVar } from "../../services/translate"
import Text from "../Text/Text"

const ClientAboutPotential = () => {
  const api = Api.getInstance();

  useEffect(() => {
    const api = Api.getInstance();
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: { name: 'our_method' }
    };
    api.post('client_log_event', data).then();
  }, [])

  return (
    <>
      <Breadcrumbs name={api.trTxt(TrVar.AboutPotential)} />
      <div className="page_content">
        <div className="page_section">
          <h1>{api.trTxt(TrVar.AboutPotential)}</h1>
          <div className="page_bar" />
          <div style={{ maxWidth: '712px'}} className='r-half-col'>
            <h3 className='top_margin'>{api.trTxt(TrVar.WhatIsPotential)}</h3>
            <Text color="gray">
              {api.trTxt(TrVar.WhatIsPotentialSub1)}
            </Text>
            <Text color="gray" className='top_margin'>
              {api.trTxt(TrVar.WhatIsPotentialSub1)}
            </Text>

            <h3 className="top_margin">{api.trTxt(TrVar.AboutOurPotentialMeasurement)}</h3>
            <Text color="gray">
              {api.trTxt(TrVar.AboutOurPotentialMeasurementSub1)}
            </Text>
            <Text color="gray" className='top_margin'>
              {api.trTxt(TrVar.AboutOurPotentialMeasurementSub2)}
            </Text>

            <h3 className='top_margin'>{api.trTxt(TrVar.AboutPotential)}</h3>
            <Text color="gray">
              {api.trTxt(TrVar.AboutTotalPotentialSub1)}
            </Text>

            <h3 className='top_margin'>{api.trTxt(TrVar.ThisIsHowYouAccessYourUntappedPotential)}</h3>
            <Text color="gray">
              {api.trTxt(TrVar.ThisIsHowYouAccessYourUntappedPotentialSub1)}
            </Text>
            <Text color="gray" className='top_margin'>
              {api.trTxt(TrVar.ThisIsHowYouAccessYourUntappedPotentialSub2)}
            </Text>
          </div>
        </div>
      
        <div className="page_section top_margin">
          <div style={{ maxWidth: '712px' }}>
            <h2>{api.trTxt(TrVar.MoreAboutOurFourAreas)}</h2>
            <Text color="gray">
              {api.trTxt(TrVar.MoreAboutOurFourAreasSub1)}
            </Text>
          </div>
          <div className="flex section_direction reverse">
            <div style={{ maxWidth: '712px' }} className='r-half-col r_right_margin top_margin'>
              <h3>{api.trTxt(TrVar.SelfInsight)}</h3>
              <Text color="gray">{api.trTxt(TrVar.WhoAmI)}?</Text>
              <Text color="gray">{api.trTxt(TrVar.BeingAwareOfYourNeeds)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.BasicEnergy)}</h3>
              <Text color="gray">{api.trTxt(TrVar.HowDoIFeel)}?</Text>
              <Text color="gray">{api.trTxt(TrVar.FeelingBalancedEnergizedAndTheAbsence)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.Motivation)}</h3>
              <Text color="gray">{api.trTxt(TrVar.HowMotivatedAmI)}?</Text>
              <Text color="gray">{api.trTxt(TrVar.WhatWeNeedInOrderToFeelMotivated)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.SelfLeadership)}</h3>
              <Text color="gray">{api.trTxt(TrVar.HowDoIGetThere)}</Text>
              <Text color="gray">{api.trTxt(TrVar.TheAbilityToUnderstandAndActAccording)}</Text>

              <h3 className='top_margin'>{api.trTxt(TrVar.SelfInsight)}</h3>
              <Text color="gray">{api.trTxt(TrVar.SelfInsightSub1)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.SelfInsightSub2) + ' '} <span className='underline'>1-3.</span></Text>

              <h3 className="top_margin">{api.trTxt(TrVar.BasicEnergy)}</h3>
              <Text color="gray">{api.trTxt(TrVar.BasicEnergySub1)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.BasicEnergySub2)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.BasicEnergySub3)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.BasicEnergySub4)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.Motivation)}</h3>
              <Text color="gray">{api.trTxt(TrVar.MotivationSub1)}</Text>
              <h4 className="top_margin">{api.trTxt(TrVar.Ignition)}</h4>
              <Text color="gray">{api.trTxt(TrVar.IgnitionFactorsAreInternalFactors)}</Text>
              <Text color="gray" className="top_margin">{api.trTxt(TrVar.Example)}:</Text>
              <Text color="gray">&mdash; {api.trTxt(TrVar.SenseOfMeaningfulness)}</Text>
              <Text color="gray">&mdash; {api.trTxt(TrVar.EngagingInActivitiesYouAreInterestedIn)}</Text>
              <Text color="gray">&mdash; {api.trTxt(TrVar.BeingInControl)}</Text>
              <Text color="gray">&mdash; {api.trTxt(TrVar.ExperienceProgress)}</Text>
              <Text color="gray">&mdash; {api.trTxt(TrVar.ReceiveRegognitionOfSuccess)}</Text>
              <Text color="gray" className="top_margin">{api.trTxt(TrVar.WhenWeAreAwareOfOurIgniterAndExtinguish)}</Text>
              <Text color="gray" className="top_margin">{api.trTxt(TrVar.OurMeasurementOfMotivationIsBasedOnSelfDeterminationTheory)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.SelfLeadership)}</h3>
              <Text color="gray">{api.trTxt(TrVar.SelfLeadershipSub1)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.SelfLeadershipSub2)}</Text>
              <Text color="gray" className='top_margin'>{api.trTxt(TrVar.SelfLeadershipSub3)}</Text>

              <h3 className="top_margin">{api.trTxt(TrVar.MoreAboutSelfLeadership)}</h3>
              <Text color="gray">
                {api.trTxt(TrVar.MoreAboutSelfLeadershipSub1Part1) + ' '}
                <span className='underline'>4,5</span>
                {' ' + api.trTxt(TrVar.MoreAboutSelfLeadershipSub1Part2).toLowerCase() + ' '}
                <span className='underline'>6</span>
              </Text>
              <Text color="gray" className="top_margin">{api.trTxt(TrVar.MoreAboutSelfLeadershipSub2) + ' '}<span className='underline'>7</span></Text>

              <Text color="gray" className="top_margin">{api.trTxt(TrVar.CrucialFactors)}:</Text>
              <ul>
                <li>{api.trTxt(TrVar.SettingYourOwnClearGoals) + ' '} <span className='underline'>2,3,5,8</span></li>
                <li>{api.trTxt(TrVar.RecognisingWhenWeSucceedAndRewardingOurselves) + ' '} <span className='underline'>3,5</span></li>
                <li>{api.trTxt(TrVar.EvaluatingOurActionsAndAdjustingThem) + ' '} <span className='underline'>1,3,5</span></li>
                <li>{api.trTxt(TrVar.ToContinuallyEvaluateOurActionsOverALongPeriod) + ' '} <span className='underline'>2,3</span></li>
              </ul>
            </div>
            <div style={{ maxWidth: '100%' }} className='top_margin'>
              <img src="/assets/illustration/heatmap_diagram.png" alt="heatmap diagram" />
            </div>
          </div>
        </div>

        <div className="page_section top_margin">
          <h2>{api.trTxt(TrVar.References)}</h2>
          <ol>
            <li>{api.trTxt(TrVar.AboutPotentialReference1)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference2)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference3)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference4)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference5)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference6)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference7)}</li>
            <li>{api.trTxt(TrVar.AboutPotentialReference8)}</li>
          </ol>
        </div>
      </div>
    </>
  )
}

export default ClientAboutPotential;
