import React from 'react'
import Api from "../services/Api"
import { MeetingInterface, MeetingType } from "../interfaces/Meeting";
import { TrVar } from "../services/translate"
import {TrArray} from "../services/translate_arrays";

interface ComponentProps {
  who: string
  meeting: MeetingInterface
  name: string
}

interface ComponentState {
  openedWindow: any
}


export default class MeetingButton extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state = {
    openedWindow: null,
  }

  componentDidMount() {
    window.addEventListener('message', this.messageListener)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.messageListener)
  }

  messageListener = (event: any) => {
    if (event.data === 'video_window_close') {
      this.closeVideoWindow()
    }
  }

  openVideoWindow = () => {
    const openedWindow = window.open("/app/meeting/" + this.props.who + "/" + this.props.name + "/"
      + this.props.meeting.meetingKey)
    this.setState({openedWindow: openedWindow})
  }

  public closeVideoWindow = () => {
    if (this.state.openedWindow !== null) {
      // @ts-ignore
      this.state.openedWindow.close()
    }
    this.setState({openedWindow: null})
  }

  public goToVideoWindow = () => {
    if (this.state.openedWindow !== null) {
      // @ts-ignore
      if (this.state.openedWindow.closed) {
        this.setState({openedWindow: null})
        alert(this.api.trTxt(TrVar.MeetingIsClosedReopen))
      } else {
        // @ts-ignore
        this.state.openedWindow.focus()
      }
    } else {
      console.log('openedWindow === null')
    }
  }

  render() {
    if (![MeetingType.BOOKED, MeetingType.CONFIRMED_OK].includes(this.props.meeting.meetingType)) {
      return (
        <div className="button red inline_block">
          {this.api.trArr(TrArray.MeetingType)[this.props.meeting.meetingType]}
        </div>
      )
    }
    return (
      <>
        {
          this.state.openedWindow ? (
            <div className="button green inline_block"
                 onClick={() => this.goToVideoWindow()}>
              {this.api.trTxt(TrVar.GoToVideoMeeting)}
            </div>
          ) : (
            <div className={"button green inline_block"}
                 onClick={() => this.openVideoWindow()}>
              {this.api.trTxt(TrVar.EnterVideoMeeting)}
            </div>
          )
        }
      </>
    )
  }
}
