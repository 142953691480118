import React, { useState, useEffect } from 'react';
import Api from "../../services/Api";
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from '../../services/translate';

const ClientContactRequest = () => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [storedPhone, setStoredPhone] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  const api = Api.getInstance();

  useEffect(() => {
    const api = Api.getInstance();
    
    const getData = () => {
      api.get('client_request_contact').then(response => {
        setPhone(response.json.phone);
        setEmail(response.json.email);
        setStoredPhone(response.json.phone);
        setHasRequested(response.json.hasRequested);
        setLoaded(true);
      })
    }

    getData();
  }, []);

  const sendRequest = () => {
    const data = {
      phone: phone
    };

    api.post('client_request_contact', data).then(response => {
      setHasRequested(response.json.hasRequested);
    });
  };

  const renderRequested = () => {
    return (
      <div className="top_margin">
        <p>
          {
            api.trTxt(TrVar.WeWillBeInTouch)
          }
        </p>
      </div>
    );
  }

  const handlePhone = (e: any) => {
    setPhone(e.target.value);
  }

  const renderRequest = () => {
    return (
      <div className="top_margin">
        <div className="top_margin">
          <p>
            {
              api.trTxt(TrVar.WeAreWorkingToMakeItPossibleToUpgradeToCoach)
            }
          </p>
          <p>
            {
              storedPhone.length > 0 ?
                api.trTxt(TrVar.UntilThenClickTheButtonBelow)
                :
                api.trTxt(TrVar.UntilThenFillOutTheContactForm)
            }
          </p>
        </div>
        {
          storedPhone.length === 0 ? (
            <div className="top_margin">
              <p>{api.trTxt(TrVar.Email)}</p>
              <div className="mt-2 page_input flex items-center">
                <p>{email}</p>
                <img className="ml-8 icon_medium" alt="check" src="/assets/icon/white/check.svg" />
              </div>

              <p className="top_margin">
                {api.trTxt(TrVar.PhoneNumber)}
              </p>
              <input className="page_input mt-2" type="text" value={phone} placeholder="+467x-xx xx xx"
                onChange={(e) => handlePhone(e)} />
            </div>
          ) : (
            <div className="top_margin">
            </div>
          )
        }
        <div className="top_margin">
          <div className="button green inline_block" onClick={() => sendRequest()}>Skicka</div>
        </div>
      </div>
    );
  }

  if (!loaded) {
    return (
      <Loading />
    );
  }
  
  let name = api.trTxt(TrVar.UpgradeToCoaching);
  let breadcrumbs = [{ name: api.trTxt(TrVar.Overview), link: '/app/client/overview' }];

  return (
    <div className="page_content">
      <Breadcrumbs breadcrumbs={breadcrumbs} name={name} />
      <div className="page_section flex">
        <div>
          <h1>{api.trTxt(TrVar.UpgradeToCoaching)}</h1>
          {
            hasRequested ? renderRequested() : renderRequest()
          }
        </div>
        <div className="hide_medium">
          <div className="flex justify-center mt-12" style={{ maxWidth: "300px", maxHeight: "300px", marginLeft: "64px" }}>
            <img alt="matching" src="/assets/illustration/matching.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientContactRequest;
