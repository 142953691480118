import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";


interface ComponentProps extends RouteComponentProps<{
  meetingKey: string
  who: string
  name: string
  externalId: string
}> {}

interface ComponentState {}

export default class VideoClosed extends React.Component<ComponentProps, ComponentState> {

  constructor(props: ComponentProps) {
    super(props);
    const defaultDisa = [0, 0, 0, 0];
    this.state = {
      clientDisa: defaultDisa,
      coachDisa: defaultDisa,
    }
  }

  render() {
    const videoUrl = '/app/external_meeting/' + this.props.match.params.who + '/' + this.props.match.params.name
      + '/' + this.props.match.params.meetingKey + '/' + this.props.match.params.externalId
    return (
      <IonPage>
        <IonContent>
          <div className="mt-8 text_center">
            <h1>Tack för idag {this.props.match.params.name}!</h1>
            <p className="mt-8">
              Har du råkat lägga på av misstag kan du hoppa in igen:
            </p>
            <Link to={videoUrl}>
              <button className="mt-8 button inline_block">Gå in i Videomöte</button>
            </Link>
          </div>
        </IonContent>
      </IonPage>
    );
  }

}
