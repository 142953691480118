import React from 'react';
import './NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="notfound_container">
      <div className="notfound_header">
        {' '}
        {
          <a href="https://app.zebrain.se/app/start">
            <img
              className="notfound_logo"
              alt="previous"
              src="/assets/images/logo_white.svg"
            />
          </a>
        }
      </div>
      <h1 className="notfound_h1">404</h1>
      <h2 className="notfound_h2">Error - Page Not Found</h2>
      <div className="notfoung_goto">
        <h2 className="notfound_h2">Sorry! We lost our stripes...</h2>{' '}
        <a className="notfound_goto_link" href="/app/start">
          Zebrain platform startpage
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
