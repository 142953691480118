import React from 'react'
import Api from "../../services/Api"
import './ClientLicense.css';
import {ClientInterface} from "../../interfaces/Client";
import {CoachInterface} from "../../interfaces/Coach"
import {changeUrl} from "../../services/Navigation";
import CoachAgreement from "../info/CoachAgreement";
import EditAreas from "../edit/EditAreas";
import EditCoach from "../edit/EditCoach"
import Breadcrumbs from "../menu/Breadcrumbs";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import { TrVar } from '../../services/translate';
import SvgAnimation from '../SvgAnimation';

interface ComponentProps {
  client: ClientInterface
  coach?: CoachInterface
  selectedCoach: Function
  navigation: any

}
interface ComponentState {
  error: string
  step: number
  coach?: CoachInterface
}

export default class ChatCoach extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    error: '',
    step: 1,
    coach: this.props.coach
  }

  componentDidMount() {
    if (["1", "2", "3", "4", "5", "6"].indexOf(this.props.navigation.sub1) < 0) {
      changeUrl('/app/client/chat_coach/1')
    }
  }

  selectContent = () => {
    this.logStep()
    if (this.props.navigation.sub1 === "2") {
      return this.showStep2()
    } else if (this.props.navigation.sub1 === "3") {
      return this.showStep3()
    } else if (this.props.navigation.sub1 === "4") {
      return this.showStep4()
    } else if (this.props.navigation.sub1 === "5") {
      return this.showStep5()
    } else if (this.props.navigation.sub1 === "6") {
      return this.showStep6()
    }
    return this.showStep1()
  }

  changeStep = (step: number) => {
    changeUrl('/app/client/' + this.props.navigation.page + '/' + step)
  }

  logStep = () => {
    let step = this.props.navigation.sub1
    if (step.length === 0) {
      return
    }
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'chat_coach_step_' + step}
    }
    this.api.post('client_log_event', data).then()
  }

  showStep1 = () => {
    return (
      <div className="page_content">
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.HowWorksChatCoaching)}</h1>
          <div className="page_bar" />
          <div className="top_margin r-flex justify-between">
            <div style={{maxWidth: "500px"}}>
              <h4>{this.api.trTxt(TrVar.AboutChatCoaching)}:</h4>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.GetAPersonalCoach)}</p>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.WriteAsOftenAsYouWant)}</p>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.YourCoachRepliesThrice)}</p>

              <h4 className="top_margin">{this.api.trTxt(TrVar.WhatYouCanTalkToYourCoachAbout)}:</h4>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.HowToIdentifyYourStrengthsAndIncreaseYourMotivation)}</p>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.HowToIncreaseYourStressResilience)}</p>
              <p className="mt-2 ml-2">-&nbsp;&nbsp;{this.api.trTxt(TrVar.HowToStrengthenYourSelfLeadership)}</p>

              <p className="top_margin">
                {this.api.trTxt(TrVar.TakeALookAtYourPotentialAssessmentOrRecommendedDevelopmentAreasForMoreIdeas)}
              </p>

              <div className="mt-16">
                <div className="button green inline_block" onClick={() => this.changeStep(2)}>{this.api.trTxt(TrVar.IWantToChat)}!</div>
              </div>
            </div>
            <div className="hide_medium" style={{width: "50%"}}>
              <img style={{width: "380px"}} alt="matching" src="/assets/illustration/matching.svg"/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showStep2 = () => {
    return (
      <div className="page_content">
        <div className="page_section text_center">
          <div style={{ width: '100%' }} className="modal_animation mx-auto">
            <SvgAnimation animationType='star' />
          </div>
          <h1 className="top_margin">{this.api.trTxt(TrVar.Great)}!</h1>

          <p className="top_margin">
            {this.api.trTxt(TrVar.InTheNextStepWeWillMatchYouWithOneOfOurCoaches)}
          </p>

          <div className="top_margin">
            <div className="button green inline_block" onClick={() => this.changeStep(3)}>{this.api.trTxt(TrVar.Next)}</div>
          </div>
        </div>
      </div>
    )
  }

  showStep3 = () => {
    return (
      <div className="page_content">
        <CoachAgreement hasCoach={true} chatCoach={true} save={() => {console.log('saved!'); this.changeStep(4)}}/>
      </div>
    )
  }

  showStep4 = () => {
    return (
      <div className="page_content">
        <div className="page_section">
          <EditAreas save={() => this.changeStep(5)} who="client" hideCoachLanguage={true}
                     hideAreas={false}
                     saveText={this.api.trTxt(TrVar.Continue)} onboarding={false} cancelButton={false}/>
        </div>
      </div>
    )
  }

  showStep5 = () => {
    return (
      <EditCoach save={this.props.selectedCoach} chatCoach={true}
                 selectCoach={(coach: CoachInterface) => {this.setState({coach: coach});this.changeStep(6) }}/>
    )
  }

  showStep6 = () => {
    return (
      <div className="page_content">
        <div className="page_section text_center">
          {
            this.state.coach &&
            <>
              <img alt="coach" className="coach_picture" src={this.state.coach.picture}/>
              <h1 className="top_margin">{this.api.trTxt(TrVar.GreatChoice)}!</h1>

              <h1 className="top_margin">
                {this.state.coach.firstName} {this.api.trTxt(TrVar.IsAnExcellentMatch).toLowerCase()}
              </h1>

              <div className="top_margin">
                  <div className="button green inline_block" onClick={() => changeUrl('/app/client/chat')}>
                      {this.api.trTxt(TrVar.ToChat)}
                  </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  progressBar = () => {

    const percent = 100 * parseInt(this.props.navigation.sub1) / 6
    return (
      <div className="header_progress_container">
        <div className="header_progress_bar bg_blue_gray w-full">
          <div style={{width: (percent + "%")}} className="header_progress_bar bg_purple"/>
        </div>
      </div>
    )
  }

  render() {
    let linkName:string
    let link: string
    if (this.props.navigation.sub1 === '1') {
      linkName = 'Översikt'
      link = '/app/client/overview'
    } else {
      const page = parseInt(this.props.navigation.sub1)
      linkName = 'Tillbaka'
      link = '/app/client/chat_coach/' + (page - 1)
    }
    return (
      <>
        <Breadcrumbs breadcrumbs={[{name: linkName, link: link}]} backLink={true}
                     name=''/>
        {this.progressBar()}
        {this.selectContent()}
      </>
    )
  }
}
