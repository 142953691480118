import {
  IonContent,
  IonPage,
} from '@ionic/react'
import React from 'react'
import Api from "../services/Api"
import {BehaviouralQuestion} from "../interfaces/BehaviouralQuestion"
import {DisaInterface} from "../interfaces/Disa"
import {CoachInterface} from "../interfaces/Coach"
import {RouteComponentProps} from "react-router"
import Loading from "../components/Loading";
import {CONFIG} from "../constants";
import {AreaInterface} from "../interfaces/Area";
import EditAbout from "../components/edit/EditAbout";
import CoachOnboardingStart from "../components/coach/CoachOnboardingStart";
import BehaviourEdit from "../components/behaviour/BehaviourEdit";
import BehaviourView from "../components/behaviour/BehaviourView";
import CoachProfile from '../components/coach/CoachProfile'
import CoachExperience from '../components/coach/CoachExperience'
import { TrVar } from '../services/translate'

enum Pages {
  ChooseLogin,
  Welcome,
  CoachProfile,
  CoachExperience,
  DisaEdit,
  DisaResult,
  EditAbout,
  Done
}

interface ComponentProps extends RouteComponentProps<{}>{}

interface ComponentState {
  areas: Array<AreaInterface>
  questions: Array<BehaviouralQuestion>
  disa: Array<DisaInterface>
  coach: CoachInterface
  meetings: number
  page: number
  loaded: boolean
  pages: Array<number>
  pageNr: number
}

export default class CoachOnboarding extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    areas: [] as Array<AreaInterface>,
    questions: [],
    disa: [],
    coach: {} as CoachInterface,
    page: 0,
    meetings: 0,
    loaded: false,
    pages: [],
    pageNr: 0,
  }

  componentDidMount() {
    window.addEventListener('popstate', this.backListener)
    this.getData()
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.backListener)
  }

  backListener = (event: PopStateEvent)  => {
    if (event.currentTarget) {
      // @ts-ignore
      const urlParts = event.currentTarget.location.pathname.split('/')
      if (urlParts.length === 4 && urlParts[2] === 'coach_onboarding') {
        const pageNrFromUrl = parseInt(urlParts[3])
        let page = 0
        if (pageNrFromUrl && pageNrFromUrl < this.state.pages.length) {
          page = this.state.pages[pageNrFromUrl]
          if (page > 0) {
            this.setState({page: page, pageNr: pageNrFromUrl})
          }
        }
      }
    }
  }

  private getPage = (data: any) => {

    if (!data.coach.coachType) {
      return Pages.Welcome
    }
    if (data.missingFields.length > 0) {
      return Pages.EditAbout
    }

    if (!data.coach.companyName) {
      return Pages.CoachProfile
    }

    let selectedArea = false
    for (let area of data.areas) {
      if (area.selected) {
        selectedArea = true
      }
    }
    if (!selectedArea) {
      return Pages.CoachExperience
    }

    if (!data.disa) {
      return Pages.DisaEdit
    }
    return Pages.Done
  }

  private getData = () => {
    this.api.get('coach_onboarding').then(response => {
      const page = this.getPage(response.json)

      if (page === Pages.Done) {
        this.setOnboarded()
      }

      const pages = this.getPages()
      const pageNr = this.getPageNr(page, pages)

      this.props.history.replace("/app/coach_onboarding/" + pageNr)

      this.setState({
        areas: response.json.areas,
        questions: response.json.questions,
        disa: response.json.disa,
        coach: response.json.coach,
        page: page,
        pages: pages,
        pageNr: pageNr,
        loaded: true
      })
    })
  }

  getPages = () => {
    return [
      Pages.Welcome,
      Pages.EditAbout,
      Pages.CoachProfile,
      Pages.CoachExperience,
      Pages.DisaEdit,
      Pages.DisaResult,
      Pages.Done
    ]
  }

  private previous = () => {
    const pageNr = this.state.pageNr - 1
    const page = this.state.pages[pageNr]
    this.setState({page: page, pageNr: pageNr})
    this.props.history.push("/app/coach_onboarding/" + pageNr)
  }

  private next = () => {
    let pageNr = this.state.pageNr + 1

    if (pageNr >= (this.state.pages.length - 1)) {
      this.setOnboarded()
    } else {
      const page = this.state.pages[pageNr]
      this.setState({page: page, pageNr: pageNr})
      this.props.history.push("/app/coach_onboarding/" + pageNr)
    }
  }

  setOnboarded = () => {
    this.api.post('coach_onboarded', {}).then(response => {
      window.location.href = "/app/coach/overview"
    })
  }

  private goHome = () => {
    let homePage = CONFIG.HOME_PAGE
    if (this.api.lang === 'en') {
      homePage += 'en/'
    }
    window.location.href = homePage
  }

  changeLanguage = (lang: string) => {
    this.api.setLanguage(lang)
    window.location.reload()
  }

  showProgressBar = () => {
    const barWidth = (100 * (this.state.pageNr + 1) / this.state.pages.length) + "%"
    return (
      <div style={{width: "100%", height: "10px", borderRadius: "5px", backgroundColor: "#fff"}}>
        <div style={{width: barWidth, height: "10px", borderRadius: "5px", backgroundColor: "#bc80f0"}}/>
      </div>
    )
  }

  getPageNr = (page: number, pages: Array<number>) => {
    let pageNr = 0
    for (const p of pages) {
      if (p === page) {
        return pageNr
      }
      pageNr++
    }
    return 0
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    const backClass = this.state.pageNr > 1 ? "visibility_visible" : "visibility_hidden"
    const forwardClass = this.state.pageNr === Pages.DisaResult ? 'white ' : "display_none"

    return (
      <IonPage>
        <IonContent>
          <div className='page_content onboarding'>
            <div className="onboarding_header">
              <div className="onboarding_logo cursor-pointer flex-shrink-0" onClick={() => this.goHome()}/>
              <div className="flex-grow mx-8 hide_small">
                {this.showProgressBar()}
              </div>

              <div className="flex items-center left_margin">
                <div className={"button white " + backClass} onClick={() => this.previous()}>
                  {this.api.trTxt(TrVar.Back)}
                </div>
                <div className={"left_margin button " + forwardClass} onClick={() => this.next()}>
                  {this.api.trTxt(TrVar.GoForward)}
                </div>
              </div>
            </div>

            <div className="w-full py-4 show_small">
              {this.showProgressBar()}
            </div>
            {
              this.state.page === Pages.Welcome &&
              <div className="page_section">
                <CoachOnboardingStart next={this.next}/>
              </div>
            }
            {
              this.state.page === Pages.EditAbout &&
              <div className="page_section">
                <EditAbout save={this.getData} who="coach" saveText={this.api.trTxt(TrVar.Continue)}/>
              </div>
            }
            {
              this.state.page === Pages.CoachExperience &&
              <CoachExperience onboarding={true} done={this.next} saveText={this.api.trTxt(TrVar.Next)}/>
            }
            {
              this.state.page === Pages.CoachProfile &&
                <CoachProfile done={this.next} saveText={this.api.trTxt(TrVar.Next)} onboarding={true}/>
            }
            {
              this.state.page === Pages.DisaEdit &&
                <BehaviourEdit isOnboarding={true} save={this.next} who="coach" saveText={this.api.trTxt(TrVar.Next)} cancelButton={false}/>
            }
            {
              this.state.page === Pages.DisaResult &&
              <>
                <BehaviourView who="coach" onboarding={true} redo={this.previous} next={this.next} />
              </>
            }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
