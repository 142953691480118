import React from 'react';
import './NotFoundPage.css';

const NotFoundComponent = () => {
  return (
    <div className="page_content">
      <div className="page_section">
        <h1 className="error_red">404</h1>
        <h2 className="top_margin">Page Not Found</h2>
        <h2>Sorry! We lost our stripes...</h2>
      </div>
    </div>
  );
};

export default NotFoundComponent;
