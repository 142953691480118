export enum TrArray {
  MeetingType,
}

type TrArrData = {
  [key: string]: Array<string>
}

type TrArrInterface = {
  [key in TrArray]: TrArrData
}

export const TrArr: TrArrInterface = {
  [TrArray.MeetingType]: {
    en: [
      'Ok',
      'Client cancelled late',
      'Coach missed meeting',
      'Coach offers new meeting',
      'Zebrain offers new meeting',
      'Meeting confirmed',
      'Client missed meeting'],
    sv: [
      'Ok',
      'Klient avbokade sent',
      'Coach missade mötet',
      'Coach bjuder på mötet',
      'Zebrain bjuder på mötet',
      'Mötet bekräftat',
      'Klient missade mötet'
    ]
  },
}
