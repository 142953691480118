import React from 'react'
import {LicenseInterface} from "../../interfaces/License";
import {ClientLicenseInterface} from "../../interfaces/ClientLicense";
import Api from "../../services/Api";
import Loading from '../Loading';
import { TrVar } from "../../services/translate"

interface ComponentProps{
  save: Function
  gotLicense?: Function
  saveText: string
  license?: LicenseInterface
  licenseCode: string
}

interface ComponentState {
  campaignCode: string
  message: string
  gotPackage: boolean
  loaded: boolean
}

export default class EditLicense extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    campaignCode: this.props.license ? this.props.license.code : this.props.licenseCode ? this.props.licenseCode : '',
    message: '',
    gotPackage: false,
    loaded: false
  }

  componentDidMount() {
    if (this.props.licenseCode) {
      this.save()
    } else {
      this.setState({loaded: true})
    }
  }

  private save = () => {
    if (this.state.campaignCode.length > 0) {
      const data = {
        code: this.state.campaignCode
      }
      this.api.post('acquire_license', data, ['license_code_not_found', 'no_seats_available', 'license_expired'])
      .then(response => {
        const clientLicense: ClientLicenseInterface = response.json.clientLicense
        let message = this.api.trTxt(TrVar.CongratulationsYouGotLicense) + ' ' + response.json.license.package.name
        if (clientLicense.endDate !== null) {
          message += '<br>' + this.api.trTxt(TrVar.LicenseIsValidTo) + clientLicense.endDate
        }
        if (this.props.gotLicense) {
          this.props.gotLicense(response.json.license, clientLicense)
        }
        this.setState({message: message, gotPackage: true, loaded: true})
        this.props.save();
      })
      .catch(response => {
        if (response.isExpectedError) {
          if (response.isExpectedError === 'license_code_not_found') {
            this.setState({message: this.api.trTxt(TrVar.LicenseCodeNotFound) + '. ' + this.api.trTxt(TrVar.NeedLicenseCode), loaded: true})
          } else if (response.isExpectedError === 'no_seats_available') {
            this.setState({message: this.api.trTxt(TrVar.NoSeatsAvailable) + '. ' + this.api.trTxt(TrVar.NeedLicenseCode), loaded: true})
          } else {
            this.setState({message: this.api.trTxt(TrVar.LicenseExpired) + '. ' + this.api.trTxt(TrVar.NeedLicenseCode), loaded: true})
          }
        }
      })
    } else {
      this.setState({message: this.api.trTxt(TrVar.YouNeedToEnterALicenseCode), loaded: true})
    }
  }

  private handleCode = (e: any) => {
    this.setState({campaignCode: e.target.value})
  }

  render() {
    if (!this.state.loaded) {
      console.log('---- Loading.....')
      return (
        <Loading />
      )
    }
    return (
      <div className="page_section">
        <h1>{this.api.trTxt(TrVar.WelcomeAboard)}!</h1>
        {this.api.trTxt(TrVar.ClientOnboardingIntro)}
        <div className="mt-8 flex items-center">
          <p>{this.api.trTxt(TrVar.EnterYourLicenseCode)}</p>
        </div>

        {
          this.state.message.length > 0 &&
          <div className="my-4 red" dangerouslySetInnerHTML={{__html: this.state.message}}/>
        }


        <div className="top_margin">
          <input className="page_input mr-4" type="text" value={this.state.campaignCode}
                 placeholder={this.api.trTxt(TrVar.LicenseCode)} style={{width: "140px"}}
                 onChange={(e) => this.handleCode(e)}/>
          {
            !this.state.gotPackage &&
              <div className="button inline_block r_top_margin green" onClick={() => this.save()}>
                {this.api.trTxt(TrVar.GetLicensePackage)}
              </div>
          }
          {
            this.state.gotPackage &&
              <div className="button inline_block r_top_margin green" onClick={() => this.props.save()}>
                {this.props.saveText}
              </div>
          }
        </div>
      </div>
    )
  }
}
