import { CONFIG } from "../constants"

const DEBUG = false

export default class WebSocketClient {

  static myInstance: WebSocketClient
  private token: string
  private userId: string
  public webSocket?: WebSocket
  public eventEl: any
  public unReadMessage: boolean
  private pingInterval: any

  constructor() {
    this.unReadMessage = false
    this.token = window.localStorage[CONFIG.TOKEN_NAME]
    this.userId = window.localStorage[CONFIG.PERSON_ID]
    this.checkOpen()
    this.eventEl = document.createTextNode('')
  }

  public checkOpen() {

    if (this.webSocket) {
      if (this.webSocket.readyState === this.webSocket.CONNECTING) {
        if (DEBUG) console.log('Socket: already connecting, exiting')
        return
      } else if (this.webSocket.readyState === this.webSocket.OPEN) {
        if (DEBUG) console.log('Socket: already open, exiting')
        return
      } else if (this.webSocket.readyState === this.webSocket.CLOSING) {
        if (DEBUG) console.log('Socket: is closing ----- todo: reopen when closed')
        return
      } else if (this.webSocket.readyState === this.webSocket.CLOSED) {
        if (DEBUG) console.log('Socket: is closed ----- todo: reopen when closed')
      }
    }

    this.token = window.localStorage[CONFIG.TOKEN_NAME]
    this.userId = window.localStorage[CONFIG.PERSON_ID]
    if (!this.token) {
      if (DEBUG) console.log('Socket: No token, exiting')
      return
    }

    this.openSocket()

  }

  private openSocket(extraMessage?: any) {
    console.log("WEB_SOCKET_ENDPOINT", CONFIG.WEB_SOCKET_ENDPOINT)
    this.webSocket = new WebSocket(CONFIG.WEB_SOCKET_ENDPOINT)
    if (DEBUG) console.log('socket new WebSocket: ' + this.webSocket.readyState)

    // console.log('Tried opening websocket')
    this.webSocket.onerror = (err) => {
      console.log(err)
      if (this.webSocket) {
        console.log('socket error: ' + this.webSocket.readyState)
      }
    }


    this.webSocket.onopen = (event) => {
      // console.log('Sending', JSON.stringify(mess))

      if (this.webSocket) {
        const mess = {
          type: 'session_init',
          token: this.token,
          user_id: this.userId,
        }
        if (DEBUG) console.log('socket onopen: ' + this.webSocket.readyState)
        this.webSocket.send(JSON.stringify(mess))
        if (extraMessage) {
          // We just sent the init call - wait for a short while to let first message arrive at websocket server
          // TODO - it would be better to wait for session_init confirmation before sending next message
          setTimeout(() => {
            if (this.webSocket) {
              this.webSocket.send(JSON.stringify(extraMessage))
              if (DEBUG) console.log('Sent message after open')
            }
          }, 200)
        }
      }
    }

    // Use addEventListener in places where message matters, e.g VideoMeeting
    // This is just for debugging
    this.webSocket.onmessage = (message) => {
      const jsonMess = JSON.parse(message.data)
    }

    this.webSocket.onclose = (message) => {
      if (DEBUG) {
        console.log('socket closed')
      }
      clearInterval(this.pingInterval)
    }

    this.pingInterval = setInterval(() => {
      if (this.webSocket) {
        if (this.webSocket?.readyState === this.webSocket?.OPEN) {
          const mess = {
            type: 'ping'
          }
          console.log(JSON.stringify(mess))
          this.webSocket.send(JSON.stringify(mess))
        } else {
          console.log('webSocket not open')
        }
      } else {
        console.log('No webSocket!')
      }
    }, 30000)
  }

  public send = (message: any) => {
    if (this.webSocket) {
      if (this.webSocket.readyState === 1) { // OPEN
        this.webSocket.send(JSON.stringify(message))
      } else if (this.webSocket.readyState === 0) {  // CONNECTING
        setTimeout(() => {
          if (this.webSocket && this.webSocket.readyState === 1) {
            console.log('socket open after delay')
            this.webSocket.send(JSON.stringify(message))
          } else {
            alert('Error: webSocket is broken. Report to support')
          }
        }, 1000)
      } else if (this.webSocket.readyState === 2) {  // CLOSING
        alert('Error: webSocket is closing. Report to support')
      } else if (this.webSocket.readyState === 3) {  // CLOSED
        console.log('Error: webSocket is closed. Try reopen')
        this.openSocket(message)
      }
    } else {
      alert('Error: webSocket is broken. Report to support')
    }

  }

  public close() {
    if (this.webSocket) {
      console.log('socket close - state ' + this.webSocket.readyState)
      if (this.webSocket.readyState === 1) {
        this.webSocket.close()
      } else {
        console.log('socket is not open')
      }
      console.log('socket close - closed ' + this.webSocket.readyState)
    } else {
      console.log('socket close - no webSocket')
    }

  }

  public sendEvent = (name: string, message: any) => {
    const ev = new CustomEvent(name, {detail: {message: message}})
    this.eventEl.dispatchEvent(ev)
  }


  /**
   * @returns {WebSocketClient}
   */
  static getInstance(): WebSocketClient {
    if (WebSocketClient.myInstance == null) {
      if (DEBUG) console.log('HD: creating new instance')
      WebSocketClient.myInstance = new WebSocketClient()
    } else {
      if (DEBUG) console.log('HD: checking existing instance')
      WebSocketClient.myInstance.checkOpen()
    }
    return this.myInstance
  }
}
