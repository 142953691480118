import {
  IonPage,
} from '@ionic/react'
import React from 'react'
import Api from "../services/Api"
import {RouteComponentProps} from "react-router"
import {LicenseInterface} from "../interfaces/License"
import Loading from "../components/Loading";
import {CONFIG} from "../constants";
import Login from "../components/login/Login";
import {changeUrl} from "../services/Navigation";
import { TrVar } from '../services/translate'

interface CandidateInterface {
  email: string
  firstName: string
  lastName: string
}
interface ComponentProps extends RouteComponentProps<{
  inviteCode: string
}>{}

interface ComponentState {
  clientCandidate: CandidateInterface
  license: LicenseInterface
  showLogin: boolean
  isClient: boolean
  error: string
  errorType: string
  loaded: boolean
}

export default class ClientInvitation extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    clientCandidate: {} as CandidateInterface,
    license: {} as LicenseInterface,
    showLogin: false,
    isClient: false,
    error: '',
    errorType: '',
    loaded: false,
  }

  componentDidMount() {
    this.parseUrl()
    if (this.api.loggedIn) {
      this.getState()
    } else {
      this.getData()
    }
  }

  parseUrl = () => {
    const urlParts = window.location.href.split('?')
    if (urlParts.length > 1) {
      const args = urlParts[1].split('&')
      for (const nameValue of args) {
        const pair = nameValue.split('=')
        if (pair.length === 2) {
          if (pair[0] === 'language') {
            this.api.setLanguage(pair[1])
          }
        }
      }
      window.history.pushState({}, "Zebrain", urlParts[0])
    }
  }

  changeLanguage = (lang: string) => {
    this.api.setLanguage(lang)
    window.location.reload()
  }

  private getState = () => {
    this.api.get('person_state', ['unauthorized'])
    .then(response => {
      const clientState = response.json.clientState
      if (clientState === 2) {
        window.location.href = '/app/client/overview'   // Client is already onboard
      } else if (clientState === 1) {
        window.location.href = '/app/client_onboarding'   // Client is onboarding
      } else {
        this.getData()
      }
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.getData()
      }
    })
  }

  private getData = () => {
    const url = 'client_invite/' + this.props.match.params.inviteCode
    this.api.get(url, ['invitation_not_found', 'invitation_expired', 'license_expired', 'no_seats_available'])
    .then(response => {
      this.setState({
        license: response.json.license,
        clientCandidate: response.json.clientCandidate,
        loaded: true,
      })
    })
    .catch(response => {
      if (response.isExpectedError === 'invitation_not_found') {
        this.setState({
          error: this.api.trTxt(TrVar.InvitationNotFound),
          errorType: response.errorName,
          loaded: true
        })
      } else if (response.isExpectedError === 'no_seats_available') {
        this.setState({
          error: this.api.trTxt(TrVar.NoSeatsAvailable),
          errorType: response.errorName,
          loaded: true
        })
      } else if (response.isExpectedError === 'invitation_expired') {
        this.setState({
          error: this.api.trTxt(TrVar.InvitationExpired),
          errorType: response.errorName,
          loaded: true
        })
      } else {
        this.setState({
          error: this.api.trTxt(TrVar.LicenseExpired),
          errorType: response.errorName,
          loaded: true
        })
      }
    });
  }

  private goHome = () => {
    let homePage = CONFIG.HOME_PAGE
    if (this.api.lang === 'en') {
      homePage += 'en/'
    }
    window.location.href = homePage
  }

  exitLogin = (loggedIn: boolean) => {
    this.setState({showLogin: false})
    if (loggedIn) {
      this.claimLicense()
    }
  }

  claimLicense = () => {
    const url = 'client_invite/' + this.props.match.params.inviteCode + '/claim'
    this.api.post(url, {}, ['person_have_client'])
    .then(_response => {
      window.location.href = '/app/client_onboarding'
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({error: response.errorMessage, isClient: true})
      }
    });
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    if (this.state.showLogin) {
      return (
        <Login exitLogin={this.exitLogin}/>
      )
    }
    return (
      <IonPage >
        <div style={{ color: '#fff', backgroundColor: '#000026', height: '100vh' }} >
          <div className="absolute" style={{ top: "20px", left: "25px" }}
            onClick={() => this.goHome()}>
            <img alt="logo" src="/assets/icon/white/logo_text.svg" />
          </div>
          <div className="absolute" style={{ top: "20px", right: "20px", zIndex: 2002 }}>
            {
              this.api.lang === 'sv' &&
              <div className="mx-4 cursor-pointer" onClick={() => this.changeLanguage('en')}>
                <img className="circle_small" alt="english" src="/assets/icon/language_en.png" />
              </div>
            }
            {
              this.api.lang === 'en' &&
              <div className="mx-4 cursor-pointer" onClick={() => this.changeLanguage('sv')}>
                <img className="circle_small" alt="english" src="/assets/icon/language_sv.png" />
              </div>
            }
          </div>
          <div style={{ backgroundColor: '#000026' }} className="page_content">
            {
              this.state.error.length > 0 ? (
                <div className='flex section_direction'>
                  <div className='page_section half'>
                    <h1>{this.api.trTxt(TrVar.Whoops)}!</h1>
                    <h2 className="top_margin">
                      {this.state.error.length > 1 ?
                        this.state.error + '.'
                        :
                        <>
                          {this.api.trTxt(TrVar.ThisLinkNoLongerWorks)
                          }
                        </>
                      }
                    </h2>
                    {/*<p className="mt-4">*/}
                    {/*  Error type: {this.state.errorType}*/}
                    {/*</p>*/}
                    <div className="mt-8">
                      <h3>{this.api.trTxt(TrVar.DoYouWantToCreateAndAccount)}</h3>
                      <div style={{ margin: '10px 0' }}>
                        {
                          this.api.trTxt(TrVar.IfYouHaventCreatedAnAccountToGetStartedContactSupport)
                        }
                      </div>
                      <a style={{ color: '#000026' }} href={"mailto:support@zebrain.se?subject=Invitation " +
                        this.props.match.params.inviteCode + " not working"}>
                        <div className="button inline-flex">
                          {this.api.trTxt(TrVar.ContactSupport)}
                        </div>
                      </a>
                    </div>
                    <div className="mt-8">
                      <h3>{this.api.trTxt(TrVar.DoYouWantToSignIn)}</h3>
                      <div style={{ margin: '10px 0' }}>
                        {
                          this.api.trTxt(TrVar.IfYouAlreadyHaveAnAccountSignInAndContinue)
                        }
                      </div>
                      <div className="button inline-flex" onClick={() => changeUrl('/app/client/overview')}>
                        {this.api.trTxt(TrVar.Logging)}
                      </div>
                    </div>
                  </div>
                    
                  <div className='page_section half flex '>
                    <img className='hide_small' src="/assets/illustration/signout.svg" alt="" />
                  </div>
                </div>

              ) : (
                <div className="flex section_direction">
                  <div className="page_section half">
                    <h1 style={{ marginBottom: '60px' }}>{this.api.trTxt(TrVar.Welcome)} {this.state.clientCandidate.firstName}!</h1>

                    <div>
                      {this.api.trTxt(TrVar.HaveInvitedYou)}
                    </div>

                    <div>
                      {this.api.trTxt(TrVar.YouHaveLicense)} <b>{this.state.license.package.name}</b>.
                    </div>

                    <div style={{ marginBottom: '60px' }} className="top_margin">
                      {this.api.trTxt(TrVar.CreateAccountBelow)}
                    </div>

                    <div>
                      <button className='button' onClick={() => this.setState({ showLogin: true })}>{this.api.trTxt(TrVar.CreateAccount)}</button>
                    </div>
                  </div>

                  <div className="page_section half r_top_margin flex">
                    <img src="/assets/illustration/certified.svg" width={432} alt="" />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </IonPage>
    )
  }
}
