import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {MeasureInterface} from "../../interfaces/MeasureInterface";
import {FocusAreaSettingsInterface, FocusMeasureInterface} from "../tasks/interfaces";
import { TrVar } from '../../services/translate';

interface ComponentState {}

interface ComponentProps {
  measures: Array<MeasureInterface>
  focusMeasures?: Array<FocusMeasureInterface>
  focusAreaSettings?: Array<FocusAreaSettingsInterface>
  followupArea?: string
  coach?: boolean
}

export default class FocusAreaLink extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  getActiveFocusArea = () => {
    if (this.props.followupArea) {
      return this.props.followupArea
    }
    if (this.props.focusAreaSettings && this.props.focusAreaSettings.length > 0) {
      return this.props.focusAreaSettings[this.props.focusAreaSettings.length - 1].areaName
    }
    if (this.props.focusMeasures) {
      return this.props.focusMeasures[0].focusAreas[0].focusArea
    }

    // Catcher in the rye
    return 'future'
  }

  getFocusAreaName = (focusAreaShort: string) => {
    if (this.props.focusMeasures) {
      for (const focusArea of this.props.focusMeasures[0].focusAreas) {
        if (focusArea.focusArea === focusAreaShort) {
          return focusArea.name
        }
      }
    }
    // Catcher in the rye
    return 'Missing name'
  }

  render() {
    if (this.props.measures.length === 0) {
      return (
        <div className="page_section">
          <h3>Not available</h3>
          <p className="top_margin">First measure is not completed yet</p>
        </div>
      )
    }

    const hasStarted = (!!(this.props.focusAreaSettings && this.props.focusAreaSettings.length > 0))
    const focusArea = this.getActiveFocusArea()
    const focusAreaName = this.getFocusAreaName(focusArea)
    let taskUrl = hasStarted ? '/app/client/tasks2/' + focusArea + '/' : '/app/client/tasks2'
    if (this.props.followupArea) {
      taskUrl += '?filter=followup'
    }
    const headerText = this.props.followupArea ?
      this.api.trTxt(TrVar.AnswerYourFollowUp)
      :
      hasStarted ?
        this.api.trTxt(TrVar.KeepWorkingWithYourAreaOfDevelopment)
        : this.api.trTxt(TrVar.StartWorkingWithYourPotential)

    const mainText = this.props.followupArea ?
      this.api.trTxt(TrVar.RememberToFollowUpYourPlan)
      :
      hasStarted ? focusAreaName :
        this.api.trTxt(TrVar.GetStartedWithYourAreasOfDevelopment)
    const buttonText = this.props.followupArea ?
      this.api.trTxt(TrVar.FollowingUp)
      :
      hasStarted ?
        this.api.trTxt(TrVar.Continue)
        : this.api.trTxt(TrVar.GetStarted)

    return (
      <div className="page_section min_width half r-half-col">
          <div className="r-flex">
              <div className="r_top_margin r-flex" >
                  <div className="r_top_margin align-center">
                      <img style={{ width: '171px', marginRight: '80px' }} alt={focusAreaName}
                           src={"/assets/focusArea/area_icon_" + focusArea + ".svg"} />
                  </div>
              </div>
              <div style={{ maxWidth: '300px' }} className='flex r_top_margin left_margin flex-col justify-between'>
                  <div>
                      <p className="blue"><b>{headerText}</b></p>
                      <h2 style={{marginBottom: '40px'}}>{mainText}</h2>
                  </div>
                  <div>
                    {
                      !this.props.coach &&
                        <div className="button green top_margin inline_block"
                             onClick={() => changeUrl(taskUrl)}>{buttonText}</div>
                    }
                  </div>
              </div>
          </div>
      </div>
    )
  }
}
