import { IonContent, IonPage, } from '@ionic/react'
import React from 'react'
import Api from "../services/Api"
import { DisaInterface } from "../interfaces/Disa"
import { CoachInterface } from "../interfaces/Coach"
import { MeetingInterface } from "../interfaces/Meeting"
import WebSocketClient from "../services/WebSocketClient"
import ClientOverview from "../components/client/ClientOverview";
import ClientBookings from "../components/client/ClientBookings";
import ClientChat from "../components/client/ClientChat";
import EditCoach from "../components/edit/EditCoach";
import ClientMeasure from "../components/client/ClientMeasure";
import { urlChange } from "../redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { Redirect } from "react-router-dom";
import { ClientInterface } from "../interfaces/Client";
import Loading from "../components/Loading";
import { PackageInterface, PackageType } from "../interfaces/Package";
import CameraTest from "./help/CameraTest";
import CameraAccess from "./help/CameraAccess";
import Menu from "../components/menu/Menu";
import Account from '../components/account/Account';
import ClientProgram from "../components/client/ClientProgram";
import ClientProfile from '../components/client/ClientProfile';
import ClientDevelopmentAreas from '../components/client/ClientDevelopmentAreas';
import ClientLicense from '../components/client/ClientLicense';
import ClientCoach from '../components/client/ClientCoach';
import DetailedBehaviorAnalysis from '../components/detailed_behavior_analysis/DetailedBehaviorAnalysis'
import ClientMeasureDevelopment from "../components/client/ClientMeasureDevelopment";
import ClientMeasureAnalysis from "../components/client/ClientMeasureAnalysis";
import BehaviourEdit from "../components/behaviour/BehaviourEdit";
import BehaviourView from "../components/behaviour/BehaviourView";
import Breadcrumbs from "../components/menu/Breadcrumbs";
import Tasks from "../components/tasks/Tasks";
import Tasks2 from "../components/tasks/Tasks2";
import NotFoundComponent from "./404/NotFoundComponent";
import KlarnaPurchase from '../components/client/KlarnaPurchase'
import KlarnaConfirm from '../components/client/KlarnaConfirm'
import ClientAboutPotential from '../components/client/ClientAboutPotential'
import ClientPurchase from '../components/client/ClientPurchase'
import ClientContactRequest from "../components/client/ClientContactRequest";
import ClientTip from '../components/client/ClientTip'
import Login from '../components/login/Login'
import ClientMeetingHistory from "../components/client/ClientMeetingHistory";
import ChatCoach from "../components/client/ChatCoach";
import { changeUrl } from "../services/Navigation"
import { ClientUISettingsInterface } from '../interfaces/ClientUISettings'
import { TrVar } from "../services/translate";
import ClientDashboardTest from '../components/client/ClientTest'
import ClientSupport from '../components/client/ClientSupport'
import ClientFaq from '../components/client/ClientFaq'
import ClientChatAi from "../components/client/ClientChatAi";
import ClientChatOne from "../components/client/ClientChatOne";
import { LicenseInterface } from "../interfaces/License";
import * as Sentry from "@sentry/react";

const mapState = (state: any) => ({
  navigation: state.navigation
})

const mapDispatch = {
  urlChange
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface ComponentState {
  payType: number
  client: ClientInterface
  personId: number
  license: LicenseInterface
  isActive: boolean
  disa: Array<DisaInterface>
  coach?: CoachInterface
  package?: PackageInterface
  meetings: Array<MeetingInterface>
  loggedIn: boolean
  width: number
  menuOpen: boolean
  isCoach: boolean
  badges: any
  page: string
  missingClient: boolean
  loaded: boolean
  oldPowerTasks: boolean
  free: boolean
  clientUISettings: ClientUISettingsInterface
  customerPrograms: number
  frameworks: number
}

class Client extends React.Component<PropsFromRedux, ComponentState>  {

  private api = Api.getInstance()
  private webSocketClient = WebSocketClient.getInstance()
  private scrollRef: any

  constructor(props: PropsFromRedux) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      payType: 0,
      client: {} as ClientInterface,
      personId: -1,
      license: {} as LicenseInterface,
      isActive: true,
      disa: [],
      coach: undefined,
      package: undefined,
      meetings: [],
      loggedIn: this.api.loggedIn,
      width: 375,
      menuOpen: false,
      isCoach: false,
      badges: {},
      page: 'overview',
      missingClient: false,
      loaded: false,
      oldPowerTasks: false,
      free: false,
      clientUISettings: {} as ClientUISettingsInterface,
      customerPrograms: 0,
      frameworks: 0
    }
  }

  componentDidMount() {
    document.title = "Zebrain: Client"
    this.setDimensions()
    window.addEventListener("resize", this.setDimensions)
    this.webSocketClient.webSocket?.addEventListener("message", this.messageListener)
    this.getData()
    this.getClientSettings()
    window.addEventListener('popstate', this.backListener)
  }

  componentDidUpdate(prevProps: PropsFromRedux, prevState: ComponentState, snapshot: any) {
    if (prevProps.navigation.url !== this.props.navigation.url) {
      this.scrollToTop()
      if (this.props.navigation.page === 'chat') {
        this.setState({ badges: 0 })
      }
    }
  }

  backListener = (event: PopStateEvent) => {
    if (event.currentTarget) {
      // @ts-ignore
      this.props.urlChange(event.currentTarget.location.pathname)
    }
  }

  messageListener = (event: any) => {
    const mess = JSON.parse(event.data)
    if (mess.type === 'client') {
      if (mess.badges) {
        this.setState({ badges: mess.badges })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDimensions)
    window.removeEventListener("popstate", this.backListener)
    this.webSocketClient.webSocket?.removeEventListener("message", this.messageListener)
  }

  setDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  private scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.getScrollElement().then((el: HTMLElement) => {
        this.scrollRef.current.scrollToTop();
      });
    }
  };

  parseData = (resultJson: any) => {
    const client = resultJson.client

    if(resultJson && resultJson.personId) { 
      Sentry.setUser({ id: resultJson.personId.toString() });
    }

    this.setState({
      client: client,
      personId: resultJson.personId,
      license: resultJson.license,
      package: client.package,
      isActive: client.isActive,
      coach: resultJson.coach !== null ? resultJson.coach : undefined,
      meetings: resultJson.meetings,
      isCoach: resultJson.isCoach,
      badges: resultJson.badges,
      loaded: true,
      oldPowerTasks: resultJson.oldPowerTasks,
      customerPrograms: resultJson.customerPrograms,
      frameworks: resultJson.framework
    })
  }

  private getClientSettings = () => {
    this.api.get('ui_settings')
      .then(response => {
        this.setState({
          clientUISettings: response.json
        })
      }).catch(error => {
        console.log(error)
      })
  }

  updateHaveSeenDevelopmentAreasIntro = () => {
    this.setState({
      clientUISettings: {
        ...this.state.clientUISettings,
        haveSeenDevelopmentAreasIntro: true,
      }
    })
  }

  updateHaveSeenOneTaskIntro = (area: string) => {
    if (area === 'selfInsight') {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          self_insight_hide_intro: true,
        }
      })
    } else if (area === 'motivation') {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          motivation_hide_intro: true,
        }
      })
    } else if (area === 'relation') {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          relation_hide_intro: true,
        }
      })
    } else if (area === 'prestation') {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          prestation_hide_intro: true,
        }
      })
    } else if (area === 'stress') {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          stress_hide_intro: true,
        }
      })
    } else {
      this.setState({
        clientUISettings: {
          ...this.state.clientUISettings,
          future_hide_intro: true,
        }
      })
    }
  }

  convertKGtoPounds = (kg: number) => {
    return Math.round(kg * 2.20462)
  }

  private getData = () => {
    this.api.get('client?badges=1&oldPowerTasks=1', ['client_required'])
      .then(response => {
        this.parseData(response.json)
        if (response.json.license?.package.type === PackageType.FREE) {
          this.setState({ free: true });
        }
      }).catch(response => {
        if (response.isExpectedError) {
          this.setState({ missingClient: true, loaded: true })
        } else {
          console.log(response)
        }
      })
  }

  showMenu = (open: boolean) => {
    this.setState({ menuOpen: open })
  }

  changeCoach = () => {
    this.getData()
    changeUrl('/app/client/overview')
  }

  analysisDone = () => {
    this.setState({ page: 'overview' })
  }

  goTo = (url: string) => {
    console.log('------ Obsolete: would go to ' + url)
  }

  selectBehaviour = () => {
    if (this.props.navigation.sub1 === 'edit') {
      return (
        <>
          <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.YourBehaviourProfile), link: '/app/client/behaviour' }]}
            name={this.api.trTxt(TrVar.Edit)} />
          <div className="page_content">
            <div className="page_section">
              <BehaviourEdit cancelButton={true} who="client" saveText={this.api.trTxt(TrVar.Save)} />
            </div>
          </div>
        </>
      )
    } else {
      return (<BehaviourView who="client" />)
    }
  }

  createdAccount = (loggedIn: boolean) => {
    if (loggedIn) {
      const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
      if (searchParams.from) {
        window.location.href = '/app/client/' + searchParams.from
      } else {
        window.location.href = '/app/client/overview'
      }
    } else {
      alert('Fatal error. Contact support')
    }
  }


  localRoute = () => {
    if (this.props.navigation.page === 'overview' || this.props.navigation.page === '') {
      return (<ClientOverview goTo={this.goTo} navigation={this.props.navigation} badges={this.state.badges} clientUISettings={this.state.clientUISettings} hasAiCoach={!this.state.license ? false : this.state.license.hasAiCoach} />)
    } else if (this.props.navigation.page === 'measure') {
      if (this.props.navigation.sub1 === 'analysis') {
        let measureId = 0
        if (this.props.navigation.sub2 !== 'new') {
          measureId = parseInt(this.props.navigation.sub2)
        }
        return (<ClientMeasure measureId={measureId} measureDone={() => console.log('change page')}
          scrollToTop={this.scrollToTop} />)
      } else if (this.props.navigation.sub1 === 'new') {
        return (
          <ClientMeasure measureId={0} scrollToTop={this.scrollToTop} measureDone={() => console.log('change page')} />)
      } else if (this.props.navigation.sub1 === 'result') {
        let measureId = parseInt(this.props.navigation.sub2)
        return (<ClientMeasureAnalysis measureId={measureId} changePage={() => console.log('change page')}
          close={() => console.log('change page')} />)
      }
      let areaNr = -1
      let summary = ''
      if (this.props.navigation.sub1 === 'summary') {
        summary = this.props.navigation.sub2
      }
      if (this.props.navigation.sub1.length === 1) {
        areaNr = parseInt(this.props.navigation.sub1)
      }
      return (
        <ClientMeasureDevelopment areaNr={areaNr} summary={summary} close={() => console.log('Only for coaches')} />
      )
    } else if (this.props.navigation.page === 'test') {
      return (
        <ClientDashboardTest navigation={this.props.navigation} />
      )
    } else if (this.props.navigation.page === 'tasks') {
      return (<Tasks navigation={this.props.navigation} />)
    } else if (this.props.navigation.page === 'tasks2') {
      return (<Tasks2 navigation={this.props.navigation}
        scrollRef={this.scrollRef}
        isFree={this.state.free}
        clientUISettings={this.state.clientUISettings}
        updateSeenIntro={this.updateHaveSeenDevelopmentAreasIntro}
        updateOneTaskIntro={this.updateHaveSeenOneTaskIntro} />)
    } else if (this.props.navigation.page === 'purchase') {
      if (this.props.navigation.sub1 === 'confirm') {
        return (<KlarnaConfirm orderId={this.props.navigation.sub2} />)
      }
      return (<ClientPurchase isFree={this.state.free} isIncognito={this.state.client.isIncognito} />)
    } else if (this.props.navigation.page === 'support') {
      const hasCoach = this.state.coach !== undefined
      return (<ClientSupport clientHasCoach={hasCoach} free={this.state.free} />)
    } else if (this.props.navigation.page === 'faq') {
      const hasCoach = this.state.coach !== undefined
      return (<ClientFaq clientHasCoach={hasCoach} />)
    } else if (this.props.navigation.page === 'my_license') {
      return (<ClientLicense />)
    } else if (this.props.navigation.page === 'chat_coach') {
      return (<ChatCoach client={this.state.client} coach={this.state.coach} navigation={this.props.navigation} selectedCoach={this.getData} />)
    } else if (this.props.navigation.page === 'my_account') {
      return (<Account who='client' package={this.state.package} />)
    } else if (this.props.navigation.page === 'my_profile') {
      return (<ClientProfile reload={this.getData} />)
    } else if (this.props.navigation.page === 'my_coach') {
      return (<ClientCoach />)
    } else if (this.props.navigation.page === 'about_potential') {
      return (<ClientAboutPotential />)
    } else if (this.props.navigation.page === 'development_areas') {
      return (<ClientDevelopmentAreas navigation={this.props.navigation} />)
    } else if (this.props.navigation.page === 'bookings') {
      const hasCoach = this.state.coach !== undefined
      return (<ClientBookings navigation={this.props.navigation} reload={this.getData}
        isClientActive={this.state.isActive} hasCoach={hasCoach} package={this.state.client.package} />)
    } else if (this.props.navigation.page === 'program') {
      return (<ClientProgram />)
    } else if (this.props.navigation.page === 'meeting_history') {
      return (<ClientMeetingHistory navigation={this.props.navigation} />)
    } else if (this.props.navigation.page === 'behaviour') {
      if (this.props.navigation.sub1 === 'edit') {
        return (
          <>
            <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.YourBehaviourProfile), link: '/app/client/behaviour/view' }]}
              name={this.api.trTxt(TrVar.Edit)} />
            <div className="page_content">
              <div>
                <BehaviourEdit cancelButton={true} who="client" saveText={this.api.trTxt(TrVar.Save)} />
              </div>
            </div>
          </>
        )
      } else if (this.props.navigation.sub1 === 'new') {
        return (
          <>
            <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.YourBehaviourProfile), link: '/app/client/behaviour/view' }]}
              name={this.api.trTxt(TrVar.NewMeasurement)} />
            <div className="page_content">
              <div>
                <BehaviourEdit cancelButton={false} who="client" saveText={this.api.trTxt(TrVar.Save)} />
              </div>
            </div>
          </>
        )
      } else {
        return (<BehaviourView who="client" />)
      }
    } else if (this.props.navigation.page === 'camera_test') {
      return (<CameraTest inPlatform={true} />)
    } else if (this.props.navigation.page === 'camera') {
      return (<CameraAccess inPlatform={true} />)
    } else if (this.props.navigation.page === 'chat') {
      const hasCoach = this.state.coach !== undefined
      if (this.props.navigation.sub1) {
        if (this.props.navigation.sub1 === 'ai') {
          return (<ClientChatAi scrollRef={this.scrollRef} />)
        } else {
          const coachId = parseInt(this.props.navigation.sub1)
          return (<ClientChatOne isClientActive={this.state.isActive} clientHasCoach={hasCoach} coachId={coachId} />)
        }
      } else {
        return (<ClientChat client={this.state.client} hasAiCoach={!this.state.license ? false : this.state.license.hasAiCoach} isClientActive={this.state.isActive} clientHasCoach={hasCoach} />)
      }
    } else if (this.props.navigation.page === 'editCoach') {
      return (<EditCoach save={this.changeCoach} />)
    } else if (this.props.navigation.page === 'behaviour_styles') {
      return (<DetailedBehaviorAnalysis who='client' />)
    } else if (this.props.navigation.page === 'purchase-klarna') {
      return (<KlarnaPurchase isFree={this.state.free} />)
    } else if (this.props.navigation.page === 'request_contact') {
      return (<ClientContactRequest />)
    } else if (this.props.navigation.page === 'request_tip') {
      return (<ClientTip />)
    } else if (this.props.navigation.page === 'create_account' && this.state.client.isIncognito) {
      return (<Login exitLogin={this.createdAccount} />)
    } else {
      return (<NotFoundComponent />)
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading />
      )
    }

    if (this.state.missingClient) {
      return (
        <IonPage>
          <IonContent>
            <div className="mt-8 text_center">
              <h2>{this.api.trTxt(TrVar.LinkError)}</h2>
              <p className="mt-8">
                {this.api.trTxt(TrVar.TheLinkThatTookYouHereIsNotMeantForFirstTimeUsers)}
              </p>
              <p className="mt-8">
                {this.api.trTxt(TrVar.IfYouHaveCreatedAZebrainAccountBeforePleaseSendAMailToSupport)}
              </p>
              <p className="mt-8">
                {this.api.trTxt(TrVar.IfThisIsYourFirstTimeAsAZebrainUserYouCanProceedToSetUpYourAccount)}
              </p>
              <div className="mt-8 button white inline_block"
                onClick={() => window.location.href = '/app/client_onboarding'}>
                {this.api.trTxt(TrVar.SetUpAccount)}
              </div>
            </div>
          </IonContent>
        </IonPage>
      )
    }

    if (!this.state.client.isOnboarded) {
      return (
        <Redirect to="/app/client_onboarding" />
      )
    }

    const showChat = this.state.client.package?.isVideoCoachIncluded ? true : this.state.client.package?.type === PackageType.ACCESS

    return (

      <IonPage>
        <Menu client={this.state.client} unreadChats={this.state.badges} isCoach={this.state.isCoach}
          package={this.state.package} oldPowerTasks={this.state.oldPowerTasks}
          showChat={showChat} customerPrograms={this.state.customerPrograms}
          frameworks={this.state.frameworks}
        />
        <IonContent scrollEvents={true} ref={this.scrollRef} className="app_bg">


          <div>
            {
              this.localRoute()
            }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Client)
