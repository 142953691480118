import {
  IonRange,
  IonModal,
} from '@ionic/react'
import React from 'react'
import {BehaviouralQuestion} from "../../interfaces/BehaviouralQuestion";
import Api from "../../services/Api";
import Loading from "../Loading";
import {changeUrl} from "../../services/Navigation";
import BarChart from '../graphics/BarChart';
import { TrVar } from '../../services/translate';

interface ComponentProps{
  save?: Function
  saveText: string
  who: string
  cancelButton?: boolean
  packageFree?: boolean
  coachFree?: boolean
  isOnboarding?: boolean
  progress?: Function
}

interface ComponentState {
  questions: Array<BehaviouralQuestion>
  disabled: boolean
  loaded: boolean
  runCalculating: boolean
  warningSave: boolean
  warningCancel: boolean
}

export default class BehaviourEdit extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  initialValue: number = 0;
  finalValue: number = 0;


  constructor(props: ComponentProps) {
    super(props);
    this.state = {
    questions: [],
    disabled: true,
    loaded: false,
    runCalculating: false,
    warningSave: false,
    warningCancel: false,
    }
  }

  componentDidMount() {
    const url = 'disa/' + this.props.who

    this.api.get(url).then(response => {
      this.setState({ questions: response.json.questions, loaded: true })
        response.json.questions.map((question: any, index: number) => {
          this.initialValue = this.initialValue + question.value;
          return this.initialValue;
        })
    })
  }

  private changeQuestion = (value: number, index: number) => {
    const questions = this.state.questions as Array<BehaviouralQuestion>;
    questions[index].value = value;
    this.setState({questions: questions, disabled: false});
  }

  checkBeforeSave = () => {
    this.state.questions.map((question: any, index: number) => {
      this.finalValue = this.finalValue + question.value;
      return this.finalValue;
    })

    if (this.initialValue === this.finalValue) {
      this.setState({ warningSave: true });
    } else {
      this.save();
    }

    this.finalValue = 0;
  }

  private save = () => {
    this.setState({ warningSave: false });
    setTimeout(() =>  this.setState({ runCalculating: true }), 250)
    const url = 'disa/' + this.props.who
    const data = {
      questions: this.state.questions
    }
    
    this.api.post(url, data).then(_response => {
      setTimeout(this.postSave, 3000)
    })
  }

  private postSave = () => {
    if (this.props.progress && this.props.who === 'client') {
      this.props.progress(3)
    } else if (this.props.isOnboarding) {
      if (this.props.save) {
        this.props.save()
      }
    } else if (this.props.who === 'client') {
      changeUrl('/app/client/behaviour/view')
    } else if (this.props.who === 'coach') {
      changeUrl('/app/coach/behaviour/view')
    }
  }

  goBack = () => {
    this.setState({ warningCancel: false })
    if (this.props.who === 'client') {
      setTimeout(() => changeUrl('/app/client/behaviour/view'), 250)
    } else {
      setTimeout(() => changeUrl('/app/coach/behaviour/view'), 250)
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    return (
      <div className='relative'>
        <IonModal className="modal_small" onDidDismiss={() => this.setState({ warningSave: false })} isOpen={this.state.warningSave} >
          <div style={{ height: '100%' }} className="behavior_modal">
            <div style={{ height: '100%' }} className="flex flex-col justify-between">
              <div>
                <h2>{this.api.trTxt(TrVar.WantToSave)}</h2>
                <div className="top_margin">
                  {this.api.trTxt(TrVar.DoYouWantToSave)}
                </div>
              </div>
              <div>
                <div onClick={() => this.save()} className="button inline_block">{this.api.trTxt(TrVar.Save)}</div>
                <div onClick={() => this.setState({ warningSave: false })} className="button left_margin white inline_block">{this.api.trTxt(TrVar.Cancel)}</div>
              </div>
            </div>
          </div>
        </IonModal>
        <IonModal className="modal_small" onDidDismiss={() => this.setState({ warningCancel: false })} isOpen={this.state.warningCancel} >
          <div style={{ height: '100%' }} className="behavior_modal">
            <div style={{ height: '100%' }} className="flex flex-col justify-between">
              <div>
                <h2>{this.api.trTxt(TrVar.WantToCancel)}</h2>
                <div className="top_margin">
                  {this.api.trTxt(TrVar.DoYouWantToCancel)}
                </div>
              </div>
              <div>
                <div onClick={() => this.setState({ warningCancel: false })} className="button inline_block">{this.api.trTxt(TrVar.Continue)}</div>
                <div onClick={() => this.goBack()} className="button left_margin white inline_block">{this.api.trTxt(TrVar.Cancel)}</div>
              </div>
            </div>
          </div>
        </IonModal>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.BehaviourAnalysis)}</h1>
        </div>

        {
          this.state.questions.map((question: BehaviouralQuestion, index: number) => {
            const k = 'question_' + index;
            return (
              <div key={k} className='page_section top_margin'>
                <h3>{index + 1}. {question.name}</h3>
                <div className="flex top_margin">
                  <div className="font_small my-auto right_margin hide_small">{question.left}</div>
                  <IonRange min={0} max={100} pin={true} style={{ padding: "0px" }}
                    className="behavior_analysis_slider" value={question.value}
                    onIonChange={e => this.changeQuestion(e.detail.value as number, index)} />
                  <div className="font_small my-auto left_margin hide_small">{question.right}</div>
                </div>
                <div className="show_small">
                  <div className="flex justify-between">
                    <div className="my-auto font_small">{question.left}</div>
                    <div className="my-auto font_small">{question.right}</div>
                  </div>
                </div>
              </div>
            )
          })
        }

        <div className="top_margin bottom_margin page_section no_bg text_center">
          <div className="button green inline_block" onClick={() => this.checkBeforeSave()}>
            {this.props.saveText}
          </div>
          {
            this.props.cancelButton &&
            <div className="left_margin button red inline_block" onClick={() => this.setState({ warningCancel: true })}>
              {this.api.trTxt(TrVar.Cancel)}
            </div>
          }
        </div>
        {this.state.runCalculating &&
          <div className='onboarding_modal'>
            <div className="onboarding_modal_content">
              <div className='text_center' style={{ maxWidth: '550px', margin: '0 auto' }}>
                <div style={{ marginBottom: '40px' }}>
                  {this.api.trTxt(TrVar.AnalyzingBehaviour)}
                </div>
                <BarChart value={100} size="transition_linear_width_2s medium" oneColor={true} showValue={false} />
              </div>
            </div>
          </div>
        }
      </div>
    )
  }     
}
