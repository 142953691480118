import {MeetingSuggestionInterface} from "../../interfaces/Meeting";
import React from "react";

export function showMeetingSuggestionStatus(lang: string, suggestion: MeetingSuggestionInterface) {
  if (suggestion.initiator === 'client') {
    if (suggestion.status === 0) {
      return (
        <div>
          <div className="button green inline_block"
               onClick={() => alert('Todo')}>
            Accept
          </div>
          <div className="ml-2 button red inline_block"
               onClick={() => alert('Todo')}>
            Reject
          </div>
        </div>
      )
    } else {
      return (
        <div>{suggestion.status === 1 ? 'Accepted by you' : ' Rejected by you'}</div>
      )
    }
  } else {
    if (suggestion.status === 0) {
      return (
        <div>{lang === 'sv' ? 'Väntar på svar från klient' : 'Waiting for client reply'}</div>
      )
    } else if (suggestion.status === 1) {
      return (
        <div>{lang === 'sv' ? 'Accepterat av klient' : 'Accepted by client'}</div>
      )
    } else if (suggestion.status === 2) {
      return (
        <div>{lang === 'sv' ? 'Avböjt av klient' : 'Rejected by client'}</div>
      )
    }
  }
}