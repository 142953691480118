import React from 'react'
import {IonContent} from "@ionic/react";
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  close: Function
}

interface ComponentState {}

export default class MeetingSuggestion extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  render() {
    return (
      <IonContent>
        <div style={{ minHeight: "100%" }}>
          <div className="p-8">
            <div className="flex items-center justify-between">
              <h3>{this.api.trTxt(TrVar.SuggestMeetingToClient)}</h3>
              <img alt="close" onClick={() => this.props.close()} className="cursor-pointer icon_medium"
                src="/assets/icon/white/close-circle.svg" />
            </div>
            <p className="top_margin">
              {this.api.trTxt(TrVar.ClickOnATimeInTheCalendarWhereYouWantToAddAMeetingSuggestion)}
            </p>
            <p className="top_margin">
              {this.api.trTxt(TrVar.WhenYouSaveTheMeetingSuggestion)}
            </p>
          </div>
        </div>
      </IonContent>
    )
  }
}
