import React from 'react'
import Api from "../../services/Api"
import {InputInterface, InputsInterface} from "../form/interfaces";
import Input from "../form/Input";
import {checkInputErrors, countInputErrors, getInputValue} from "../form/CheckInput";
import { TrVar } from '../../services/translate';

interface DictInterface {
  [name: string]: any
}

interface ComponentProps{
  save: Function
  who: string
  licenseCode?: string
  saveText: string
  progress?: Function
  setName?: Function
}

interface ComponentState {
  error: string
  missingFields: Array<string>
  inputs: InputsInterface
  showFormError: boolean
  first_name: string
}

export default class EditAbout extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  inputs: InputsInterface = {}

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
    error: '',
    missingFields: [] as Array<string>,
    inputs: this.inputs,
    showFormError: false,
    first_name: ''
    }
  }


  componentDidMount() {
    const url = this.props.who === 'coach' ? 'coach' : 'client'
    this.api.get(url).then(response => {
      const jsonObj = this.props.who === 'coach' ? response.json.coach : response.json.client

      this.inputs.firstName = {
        type: 'text',
        name: 'firstName',
        placeholder: this.api.trTxt(TrVar.FirstName),
        label: this.api.trTxt(TrVar.FirstName) + '*',
        value: jsonObj.firstName,
        error: '',
        maxLength: 64,
        minLength: 1
      }

      this.inputs.lastName = {
        type: 'text',
        name: 'lastName',
        placeholder: this.api.trTxt(TrVar.LastName),
        value: jsonObj.lastName,
        label: this.api.trTxt(TrVar.LastName) + '*',
        error: '',
        maxLength: 64,
        minLength: 1
      }

      this.inputs.phone = {
        type: 'text',
        placeholder: this.api.trTxt(TrVar.PhoneNumberPlaceholder),
        name: 'phone',
        value: '',
        label: this.api.trTxt(TrVar.Phone) + (this.props.who === 'coach' ? '*' : ''),
        error: '',
        maxLength: 18,
        minLength: (this.props.who === 'coach' ? 1 : 0)
      }

      if (jsonObj.missingFields.indexOf('gender') > -1) {
        this.inputs.gender = {
          type: 'radioselect',
          name: 'gender',
          placeholder: '',
          value: '',
          error: '',
          minOptions: 1,
          options: [
            {
              name: this.api.trTxt(TrVar.Man),
              value: '1',
              selected: false,
            },
            {
              name: this.api.trTxt(TrVar.Woman),
              value: '2',
              selected: false,
            },
            {
              name: this.api.trTxt(TrVar.NonBinary),
              value: '3',
              selected: false,
            },
            {
              name: this.api.trTxt(TrVar.DoNotWantToSpecify),
              value: '4',
              selected: false,
            }
            
          ]
        }
      }

      if (jsonObj.missingFields.indexOf('date_of_birth') > -1) {
        this.inputs.dateOfBirth = {
          type: 'date',
          name: 'dateOfBirth',
          placeholder: this.api.trTxt(TrVar.DateOfBirthPlaceholder),
          value: '',
          error: '',
        }
      }

      if (jsonObj.missingFields.indexOf('email') > -1) {
        this.inputs.email = {
          type: 'text',
          name: 'email',
          placeholder: this.api.trTxt(TrVar.Email),
          value: '',
          error: '',
          label:  this.api.trTxt(TrVar.Email) +'*',
          maxLength: 64,
          minLength: 4
        }
      }
      if (jsonObj.missingFields.indexOf('city') > -1) {
        this.inputs.city = {
          type: 'text',
          name: 'city',
          placeholder: this.api.trTxt(TrVar.City),
          label: this.api.trTxt(TrVar.City) + '*',
          value: '',
          error: '',
          maxLength: 64,
          minLength: 1
        }
      }      

      this.setState({
        inputs: this.inputs,
        missingFields: jsonObj.missingFields,
        first_name: jsonObj.firstName,
      })
    })
  }

  private save = () => {

    if (this.checkAllErrors() > 0) {
      return
    }

    if (this.inputs.phone.value.length > 0) {
      if (!this.inputs.phone.value.match(/^\+\d{7,17}$/)) {
        this.inputs.phone.error = this.api.trTxt(TrVar.FormatPhoneNumber)
        return
      }
    }

    const profile: DictInterface = {}

    profile.firstName = this.inputs.firstName.value
    profile.lastName = this.inputs.lastName.value

    if (this.inputs.email) {
      profile.email = this.inputs.email.value
    }
      
    profile.phone = this.inputs.phone.value

    if (this.inputs.city) {
      profile.city = this.inputs.city.value
    }

    if (this.inputs.company) {
      profile.company = this.inputs.company.value
    }

    if (this.inputs.dateOfBirth) {
      profile.dateOfBirth = this.inputs.dateOfBirth.value
    }

    if (this.inputs.gender) {
      profile.gender = parseInt(getInputValue(this.inputs.gender))
    }

    const data = {
      profile: profile,
      i18n: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        systemLanguage: this.api.lang
      }
    }

    const url = this.props.who === 'coach' ? 'coach_save2' : 'client_save2'

    this.api.post(url, data, ['email_in_use', 'invalid_email'])
      .then(_response => {
        if (this.props.who === 'coach') {
          this.props.save()
        } else {
          if (this.props.progress) {
            this.props.progress(1)     
          }
          if (this.props.setName) {
            this.props.setName(this.inputs.firstName.value)
          }          
        }
      })
      .catch(response => {
        if (response.isExpectedError === 'email_in_use') {
          this.setState({ showFormError: true, error: this.api.trTxt(TrVar.EmailInUse)})
        } else if (response.isExpectedError === 'invalid_email') {
          this.setState({ showFormError: true, error: this.api.trTxt(TrVar.InvalidEmail) })
        } else {
          this.setState({ showFormError: true, error: 'Unknown error' })
        }
      })
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  private logout = () => {
    this.api.logOut('/app/client')
  }

  render() {
    return (
      <div className="mx-auto" style={{ maxWidth: "560px" }}>
        <div className="blue text_center">
          {this.api.trTxt(TrVar.Part2Of2)}
        </div>
        <h1 className='text_center'>{this.api.trTxt(TrVar.ProvideUsYourDetails)}</h1>
        {
          this.inputs.firstName &&
          <div className="top_margin">
            <Input data="first-name-input" input={this.inputs.firstName} update={this.inputChange}
              classes={"page_input "} language={this.api.lang} />
          </div>
        }
        {
          this.inputs.lastName &&
          <div className="top_margin">
            <Input data="first-name-input" input={this.inputs.lastName} update={this.inputChange}
              classes={"page_input "} language={this.api.lang} />
          </div>
        }
        {
          this.inputs.phone &&
          <div className="top_margin">
            <Input data="phone-input" input={this.inputs.phone} update={this.inputChange}
              classes={"page_input "} language={this.api.lang} />
          </div>
        }
        {
          this.inputs.dateOfBirth &&
          <div className="top_margin">
            <div style={{ marginBottom: '5px' }}>{this.api.trTxt(TrVar.Birthday)}*</div>
            <Input data="date-of-birth-input" input={this.inputs.dateOfBirth} update={this.inputChange}
              classes={"date_input "} language={this.api.lang} />
          </div>
        }
        {
          this.inputs.gender &&
          <div className="top_margin">
            <div style={{ marginBottom: '5px' }}>{this.api.trTxt(TrVar.IIdentifyAs)}*</div>
            <div className='flex section_direction'>
              <Input data="gender-input" input={this.inputs.gender} update={this.inputChange} classes="r_top_margin" language={this.api.lang} />
            </div>
          </div>
        }
        {
          this.inputs.email &&
          <div className="top_margin">
            <Input data="email-input" input={this.inputs.email} update={this.inputChange} classes={"page_input "}
              language={this.api.lang} />
          </div>
        }

        {
          this.inputs.city &&
          <div className="top_margin">
            <Input data="city-input" input={this.inputs.city} update={this.inputChange} classes={"page_input "}
              language={this.api.lang} />
          </div>
        }

        {
          this.inputs.company &&
          <div className="top_margin">
            <Input data="company-input" input={this.inputs.company} update={this.inputChange} classes={"page_input "}
              language={this.api.lang} />
          </div>
        }

        {
          this.state.showFormError && <p className="top_margin error_box">
            {this.state.error.length > 0 ? this.state.error.length : this.api.trTxt(TrVar.FormErrors)}
          </p>
        }

        <div className="text_center mt-8">
          <div className="button green mobile_size_btn inline_block" onClick={() => this.save()}>
            {this.api.trTxt(TrVar.Next)}
          </div>
        </div>

        <div className="my-8">
          <h4 className="pt-4">{this.api.trTxt(TrVar.AlreadyCreatedAccount)}</h4>
          <p className="mt-2 font_small">
            {this.api.trTxt(TrVar.IfYouAlreadyCreatedAnAccountWithAnotherLoginMethod)}
          </p>
          <div className="text_center mt-4">
            <div className="button red mobile_size_btn inline_block" onClick={() => this.logout()}>
              {this.api.trTxt(TrVar.SignOut)}
            </div>
          </div>

        </div>
      </div>
    )
  }
}
