import React, { useState, useEffect } from 'react';
import {
  IonModal,
} from '@ionic/react'
import MeasureInfo from "../info/MeasureInfo";
import Api from "../../services/Api";
import {changeUrl} from "../../services/Navigation";
import Breadcrumbs from "../menu/Breadcrumbs";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import { TrVar } from "../../services/translate";

const CoachMeasureMenu = () => {
  const api = Api.getInstance();
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const api = Api.getInstance();
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'our_method'}
    }
    api.post('coach_log_event', data).then()
  }, [])
  
  return (
    <>
      <IonModal isOpen={showInfo} className="modal_tall" onDidDismiss={() => setShowInfo(false)}>
        <MeasureInfo close={() => setShowInfo(false)}/>
      </IonModal>    

        <div className="page_content">
        <Breadcrumbs name={api.trTxt(TrVar.OurMethod)}/>
        <div className="page_section">
          <h1>{api.trTxt(TrVar.OurMethod)}</h1>
          <div className="page_bar"/>
          <h2>{api.trTxt(TrVar.MeasureEffectsCoaching)}</h2>
          <p className="top_margin">
            {api.trTxt(TrVar.ZebrainsClientsCanMeasureTheirEffectOfCoaching) + ' '}
            <b>{api.trTxt(TrVar.MyClients)}</b>
          </p>
          <p className="top_margin">
            {api.trTxt(TrVar.ZebrainsUniqueMeasurementOfPotentialContainsFourFactors)}:  
          </p>
          <p className="top_margin">
            <b>{api.trTxt(TrVar.SelfInsight)}</b> = {api.trTxt(TrVar.WhoAmI)}?
          </p>
          <p className="top_margin">
            <b>{api.trTxt(TrVar.BasicEnergy)}</b> = {api.trTxt(TrVar.HowDoIFeel)}?
          </p>
          <p className="top_margin">
            <b>{api.trTxt(TrVar.Motivation)}</b> = {api.trTxt(TrVar.HowMotivatedAmI)}?
          </p>
          <p className="top_margin">
            <b>{api.trTxt(TrVar.SelfLeadership)}</b> = {api.trTxt(TrVar.HowDoIGetThere)}
          </p>
          <p className="top_margin">
            {api.trTxt(TrVar.SumOfAndTheRelationshipBetweenTheseFourFactors)}
            <b>{api.trTxt(TrVar.TotalPotential)}</b>.
          </p>
          <p className="top_margin">
            {api.trTxt(TrVar.MeasureContinuously)}
          </p>
          <p className="top_margin underline cursor-pointer" onClick={() => changeUrl('/app/coach/clients')}>
            {api.trTxt(TrVar.CoachMeasureLink)}
          </p>
        </div>
        <div className="page_section top_margin">
          <h2>
            {api.trTxt(TrVar.CoachTasksHeader)}
          </h2>
          <p className="top_margin">
            {api.trTxt(TrVar.CoachTasksIntro)}
          </p>
          <p className="mt-4 underline cursor-pointer" onClick={() => setShowInfo(true)}>
            {api.trTxt(TrVar.ReadMoreAboutYour)} <b>{api.trTxt(TrVar.TotalPotential)}</b>.
          </p>
          <h4 className="top_margin">
            {api.trTxt(TrVar.CoachTasksSubheader1)}
          </h4>
          <p className="top_margin">
            1. {api.trTxt(TrVar.CoachTasksStep1)}
          </p>
          <p className="top_margin">
            2. {api.trTxt(TrVar.CoachTasksStep2)}
          </p>
          <p className="top_margin">
            3. {api.trTxt(TrVar.CoachTasksStep3)}
          </p>
          <p className="top_margin left_margin">
            a. {api.trTxt(TrVar.CoachTasksStep3Sub1)}
          </p>
          <p className="top_margin left_margin">
            b. {api.trTxt(TrVar.CoachTasksStep3Sub2)}
          </p>
          <p className="top_margin">
            4. {api.trTxt(TrVar.CoachTasksStep4)}
          </p>
          <p className="top_margin underline cursor-pointer" onClick={() => changeUrl('/app/coach/clients')}>
            {api.trTxt(TrVar.CoachTasksLink)}
          </p>
        </div>
        </div>      
    </>
  );
}

export default CoachMeasureMenu;