import React from 'react'
import Api from "../services/Api"
import { checkInputErrors, countInputErrors } from './form/CheckInput';
import { InputsInterface, InputInterface } from './form/interfaces';
import Input from './form/Input';
import { TrVar } from '../services/translate';

interface ComponentProps {
}

interface ComponentState {
  showFormError: boolean
  inputs: InputsInterface
  messageSent: boolean
}


export default class Tip extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()
  
  inputs: InputsInterface = {
    employer: {
      type: 'text',
      name: 'employer',
      placeholder: this.api.trTxt(TrVar.Company) + '/' + this.api.trTxt(TrVar.Organisation),
      value: '',
      label: this.api.trTxt(TrVar.YourEmployer) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    contact: {
      type: 'text',
      name: 'contact',
      placeholder: this.api.trTxt(TrVar.NameOfContact),
      value: '',
      label: this.api.trTxt(TrVar.ContactPerson) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    mail: {
      type: 'text',
      name: 'mail',
      placeholder: this.api.trTxt(TrVar.EmailContactPerson),
      value: '',
      label: this.api.trTxt(TrVar.Email) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    phone: {
      type: 'text',
      name: 'phone',
      placeholder: this.api.trTxt(TrVar.PhoneContactPerson),
      value: '',
      label: this.api.trTxt(TrVar.Phone) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    reference: {
      type: 'text',
      name: 'reference',
      placeholder: this.api.trTxt(TrVar.Optional),
      value: '',
      label: this.api.trTxt(TrVar.YourName),
      error: '',
      maxLength: 64
    }
  }  
  
  state: Readonly<ComponentState> = {
    showFormError: false,
    inputs: this.inputs,
    messageSent: false
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  shareLink = () => {
    let subject = this.api.trTxt(TrVar.ShareLinkTitle)
    let body = this.api.trTxt(TrVar.ShareLinkBody);
    window.open(`mailto:?subject=${subject}&body=${body}`);
  }

  sendBossTip = () => {
    if (this.checkAllErrors() > 0) {
      return
    }
    const data = {
      work_place: this.inputs.employer.value,
      contact_name: this.inputs.contact.value,
      contact_email: this.inputs.mail.value,
      phone: this.inputs.phone.value,
      name: this.inputs.reference.value,
    }
    this.api.post('client_request_contact_manager', data).then(response => {
      if (response.json.delivered) {
        this.setState({ messageSent: true });
      }
    })
  }

  render() {    
    return (  
      <div className="page_section">
        <h1>{this.api.trTxt(TrVar.TipYourBoss)}</h1>
        <div className="flex top_margin section_direction">
          <div className="r-half-col">
            <div style={{ maxWidth: '392px' }}>
            {this.api.trTxt(TrVar.LeaveContacts)}
            </div>
            <div style={{ maxWidth: '392px'}} className="top_margin">
              {this.api.trTxt(TrVar.YouCanBeAnonymous)}
            </div>
            <div style={{ maxWidth: '392px'}} className="top_margin bottom_margin">
              {this.api.trTxt(TrVar.Thanks)}
            </div>
            <h4 style={{ maxWidth: '392px', marginTop: '60px'}}>
              {this.api.trTxt(TrVar.WantToShareLink)}
            </h4>
            <div style={{ maxWidth: '392px' }} onClick={() => this.shareLink()} className="top_margin button inline_block">
              <div className="flex">
                {this.api.trTxt(TrVar.WhyZebrain)}  
                <img src="/assets/icon/share.svg" className='left_margin' height={20} alt="share button" />                
              </div>
            </div>
          </div>
          <div className="r-half-col r_left_margin r_top_margin flex flex-col">
            <Input data="employer-input" input={this.inputs.employer} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
            <Input data="contact-input" input={this.inputs.contact} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
            <Input data="mail-input" input={this.inputs.mail} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
            <Input data="phone-input" input={this.inputs.phone} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
            <Input data="reference-input" input={this.inputs.reference} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
            {
              this.state.showFormError && <p className="top_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>        
            }
            <div>
              {
                this.state.messageSent ? (
                  <div className="top_margin">
                    {this.api.trTxt(TrVar.TipThanks)}
                  </div>
                ) : (
                  <div onClick={() => this.sendBossTip()} className="top_margin button inline_block green">
                    {this.api.trTxt(TrVar.Send)}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )  
  }
}
