import {ResourcesInterface} from "./interfaces";

const WIDTH_WIDE = 745
const FOOTER_HEIGHT = 90
const VIDEO_PADDING = 12

interface VideoElementInterface {
  container: HTMLElement
  element: HTMLElement
  width: number
  height: number
  ratio: number
  screen: boolean
  mine: boolean
}

function makeElement(element: HTMLVideoElement, container: HTMLElement, screen: boolean, mine: boolean) {
  return {
    container: container,
    element: element,
    width: element.videoWidth,
    height: element.videoHeight,
    ratio: element.videoWidth / element.videoHeight,
    screen: screen,
    mine: mine
  }
}

function makeCanvasElement(element: HTMLCanvasElement, container: HTMLElement, width: number, height: number) {
  return {
    container: container,
    element: element,
    width: width,
    height: height,
    ratio: width / height,
    screen: false,
    mine: true
  }
}

export function arrangeVideos(resources: ResourcesInterface) {
  const videoElements: Array<VideoElementInterface> = []
  const screenElements: Array<VideoElementInterface> = []
  const smallElements: Array<VideoElementInterface> = []
  const showNotes = resources.stateGet('showNotes')
  const showVisionBoard = resources.stateGet('showVisionBoard')
  let partnerScreens = 0
  resources.partners.forEach((partner => {
    if (partner.videoElement && partner.videoContainer) {
      videoElements.push(makeElement(partner.videoElement, partner.videoContainer, false, false))
    }
    if (partner.screenElement && partner.screenContainer) {
      screenElements.push(makeElement(partner.screenElement, partner.screenContainer, true, false))
      partnerScreens++
    }
  }))
  if (resources.canvas && resources.videoContainer) {
    const width = resources.canvas.width
    const height = resources.canvas.height
    const canvasEl = makeCanvasElement(resources.canvas.canvasOut, resources.videoContainer, width, height)
    if (resources.small && partnerScreens === 0) {
      smallElements.push(canvasEl)
    } else {
      videoElements.push(canvasEl)
    }
  } else if (resources.videoElement && resources.videoContainer) {
    if (!showVisionBoard && resources.small && partnerScreens === 0) {
      smallElements.push(makeElement(resources.videoElement, resources.videoContainer, false, true))
    } else {
      videoElements.push(makeElement(resources.videoElement, resources.videoContainer, false, true))
    }
  }

  if (resources.screenElement && resources.screenContainer) {
    videoElements.push(makeElement(resources.screenElement, resources.screenContainer,true, true))
  }

  if (window.innerWidth > WIDTH_WIDE) {
    placeVideosWide(videoElements, showNotes, showVisionBoard, partnerScreens)
    placeVideosSmall(smallElements, partnerScreens)
  } else {
    placeVideosNarrow(videoElements, partnerScreens)
    placeVideosSmall(smallElements, partnerScreens)
  }
  if (partnerScreens > 0) {
    placeScreens(screenElements, partnerScreens)
  }

}

function calculateCols(videoElements: Array<VideoElementInterface>, width: number, height: number) {
  let maxVideoRatio = 0
  for (const videoElement of videoElements) {
    if (videoElement.ratio > maxVideoRatio) {
      maxVideoRatio = videoElement.ratio
    }
  }
  let cols = 1
  const ratio = width / height
  if (videoElements.length > 2) {
    if (ratio < 0.5) {
      cols = 1
    } else {
      cols = 2
    }
  } else if (videoElements.length > 1) {
    if (ratio > maxVideoRatio) {    // Screen is wider than video
      cols = videoElements.length
    }
  }
  return cols
}

function placeVideosWide(videoElements: Array<VideoElementInterface>, showNotes: boolean,
                         showVisionBoard: boolean, partnerScreens: number)  {

  let windowRight = 0
  let windowLeft = 0
  if (showNotes) {
    windowRight = 364
  }
  let width = window.innerWidth - windowRight

  if (partnerScreens > 0 || showVisionBoard) {
    if (width < 500) {
      width = 200
    } else {
      width = 364
    }
    windowLeft = window.innerWidth - width
  }
  const height = window.innerHeight - FOOTER_HEIGHT

  const cols = calculateCols(videoElements, width, height)
  const rows = Math.round(videoElements.length / cols)

  const maxWidth = width / cols
  const maxHeight = height / rows

  let left = 0
  let top = 0
  let row = 0
  let col = 0
  for (const videoElement of videoElements) {
    let containerWidth = maxWidth
    let containerHeight = containerWidth / videoElement.ratio
    if (containerHeight > maxHeight) {
      containerHeight = maxHeight
      containerWidth = containerHeight * videoElement.ratio
    }

    let zIndex = 90

    if (videoElement.screen && !videoElement.mine) {
      zIndex = 91
      left = 0
      top = 0
      containerWidth = width
      containerHeight = height
    } else {
      left = col * maxWidth
      top = row * maxHeight + VIDEO_PADDING
    }
    const videoWidth = containerWidth - 12
    const videoHeight = containerHeight - 12
    const containerLeft = left + windowLeft + (maxWidth - videoWidth) / 2
    const containerStyle = 'position: fixed;z-index:' + zIndex + ';top:' + top + 'px;left:' + containerLeft + 'px;'
      + 'width:' + containerWidth + 'px; height:' + containerHeight + 'px;'
    const videoStyle = 'position: absolute;top:0;left:0;'
      + 'width:' + videoWidth + 'px; height:' + videoHeight + 'px;'

    videoElement.container.setAttribute('style', containerStyle)
    videoElement.element.setAttribute('style', videoStyle)

    col++
    if (col % cols === 0) {
      col = 0
      row++
    }
  }
}

function placeVideosSmall(videoElements: Array<VideoElementInterface>, partnerScreens: number)  {

  const maxWidth = 200
  const maxHeight = 200

  for (const videoElement of videoElements) {
    let containerWidth = maxWidth
    let containerHeight = containerWidth / videoElement.ratio
    if (containerHeight > maxHeight) {
      containerHeight = maxHeight
      containerWidth = containerHeight * videoElement.ratio
    }

    let zIndex = 95

    const videoWidth = containerWidth - 2
    const videoHeight = containerHeight - 2
    const containerStyle = 'position: fixed;z-index:' + zIndex + ';top:12px;right:12px;'
      + 'width:' + containerWidth + 'px; height:' + containerHeight + 'px;'
    const videoStyle = 'position: absolute;top:0;left:0;'
      + 'width:' + videoWidth + 'px; height:' + videoHeight + 'px;'

    videoElement.container.setAttribute('style', containerStyle)
    videoElement.element.setAttribute('style', videoStyle)
  }
}

function placeVideosNarrow(videoElements: Array<VideoElementInterface>, partnerScreens: number)  {

  const width = window.innerWidth
  const height = window.innerHeight - FOOTER_HEIGHT

  const cols = calculateCols(videoElements, width, height)
  const rows = Math.round(videoElements.length / cols)

  const maxWidth = width / cols
  const maxHeight = height / rows

  let left = 0
  let top = 0
  let row = 0
  let col = 0
  for (const videoElement of videoElements) {
    let containerWidth = maxWidth
    let containerHeight = containerWidth / videoElement.ratio
    if (containerHeight > maxHeight) {
      containerHeight = maxHeight
      containerWidth = containerHeight * videoElement.ratio
    }

    let zIndex = 90

    left = col * maxWidth
    top = row * maxHeight

    const videoWidth = containerWidth - 2
    const videoHeight = containerHeight - 2
    const containerLeft = left
    const containerStyle = 'position: absolute;z-index:' + zIndex + ';top:' + top + 'px;left:' + containerLeft + 'px;'
      + 'width:' + containerWidth + 'px; height:' + containerHeight + 'px;'
    const videoStyle = 'position: absolute; top: 1px; left: 1px;'
      + 'width:' + videoWidth + 'px; height:' + videoHeight + 'px;'

    videoElement.container.setAttribute('style', containerStyle)
    videoElement.element.setAttribute('style', videoStyle)
    col++
    if (col % cols === 0) {
      col = 0
      row++
    }
  }
}

function placeScreens(videoElements: Array<VideoElementInterface>, partnerScreens: number)  {

  let windowLeft = 0
  // if (chatOpen) {
  //   windowLeft = 375
  // }
  let width = window.innerWidth
  if (window.innerWidth > WIDTH_WIDE) {
    width = window.innerWidth - windowLeft - 300
  }
  const height = window.innerHeight - FOOTER_HEIGHT

  const rows = videoElements.length

  const maxWidth = width
  const maxHeight = height / rows

  let left = 0
  let top = 0
  let row = 0
  for (const videoElement of videoElements) {
    let containerWidth = maxWidth
    let containerHeight = containerWidth / videoElement.ratio
    if (containerHeight > maxHeight) {
      containerHeight = maxHeight
      containerWidth = containerHeight * videoElement.ratio
    }

    let zIndex = 93
    left = 0
    top = row * height

    const videoWidth = containerWidth - 2
    const videoHeight = containerHeight - 2
    const containerLeft = left + windowLeft
    const containerStyle = 'position: absolute;z-index:' + zIndex + ';top:' + top + 'px;left:' + containerLeft + 'px;'
      + 'width:' + containerWidth + 'px; height:' + containerHeight + 'px;'
    const videoStyle = 'position: absolute; top: 1px; left: 1px;'
      + 'width:' + videoWidth + 'px; height:' + videoHeight + 'px;'

    videoElement.container.setAttribute('style', containerStyle)
    videoElement.element.setAttribute('style', videoStyle)

    row++

  }
}
