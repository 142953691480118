import React, { useState } from 'react';

import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import Text from "../Text/Text";


interface ItemProps {
    title: string;
    content: React.JSX.Element | string;
}

interface AccordionProps {
    items: ItemProps[]
}

const Accordion = (props: AccordionProps) => {
    const { items } = props;
    const [openItemId, setOpenItemId] = useState<string>("");

    const toggleItem = (itemId: string) => {
        setOpenItemId(openItemId === itemId ? "" : itemId);
    };

    return (
        <>
            <hr className='mb-6 bg-neutral-600' style={{ height: '1px' }}/>
            {items.map((item, index) => {
                const isOpen = openItemId === `item${index}`;
                return (
                    <React.Fragment key={index}>
                        <div
                            className=" flex my-auto cursor-pointer justify-between flex-nowrap"
                            onClick={() => toggleItem(`item${index}`)}
                        >
                            <p className="font-medium text-base text-white">{item.title}</p>
                            {isOpen ? 
                                <FaAngleUp className="self-center ml-2 support_icon" /> : 
                                <FaAngleDown className="self-center ml-2 support_icon" />
                            }
                        </div>
                        <div
                            id={`q${index}Answer`}
                            className={`mt-6 mr-8 ${isOpen ? 'block' : 'hidden'}`}
                        >
                            {typeof item.content === 'string' ? 
                                <p className="text-base text-neutral-400">{item.content}</p> :
                                <>{item.content}</>
                            }
                        </div>
                        <hr className='my-6 bg-neutral-600' style={{ height: '1px' }}/>
                    </React.Fragment>
                );
            })}
        </>
    );
}

export default Accordion;
