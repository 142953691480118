import React from 'react';
import Api from '../../services/Api';
import './SignedOut.css';
import {CONFIG} from '../../constants';
import { TrVar } from '../../services/translate';


interface ComponentState {
}

let buttonClasses = `
  grid max-md:grid-rows-2 md:grid-cols-2 content-center gap-4
  signout_button top_margin shrink md:max-w-[300px]`

export default class SignedOut extends React.Component<ComponentState>  {

  api = Api.getInstance()

  state = {
  }

  componentDidMount () {

  }

  signIn = () => {
    window.location.href = '/app/start'
  }

  goHome = () => {
    let url = this.api.lang === 'sv' ? '/sv' : '/en'
    window.location.href = CONFIG.HOME_PAGE+url
  }


  render() {
    return (
        <>
        <div className="signout_section h-full">
            <img className="h-6 object-contain" height="24px" src="/assets/icon/green/logo_text.svg" alt="Zebrain Logo" />
            <div className="flex h-full align-middle justify-center">
              <div
                className="flex flex-row justify-center align-middle my-auto w-[50%]"
              >
                <div className='page_section half signout_text p-1 pt-8 grow flex justify-center align-middle'>
                    <h1 className="shrink">{this.api.trTxt(TrVar.SignedOut)}</h1>
                    <h4 className="shrink">{this.api.trTxt(TrVar.ThanksCya)}</h4> 
                    <div className={buttonClasses}>
                        <button 
                          className='button blue r_top_margin md:max-w-[140px] shrink' 
                          onClick={() => this.signIn()} 
                          style={{minWidth: '140px'}}
                        >
                            {this.api.trTxt(TrVar.LogBack)}
                          </button>
                        <button 
                          className='button white r_top_margin md:max-w-[140px] shrink' 
                          onClick={() => this.goHome()}
                          style={{minWidth: '140px'}}
                        >
                            {this.api.trTxt(TrVar.GoWebsite)}
                        </button>                    
                    </div>
                </div>
              </div>
                <div className='flex page_section my-auto'>
                    <img src="/assets/illustration/signout.svg" alt="" />
                </div>                
            </div>
        </div>
        </>
    )
  }
}
