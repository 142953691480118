import React from 'react';
import Api from '../../services/Api';
import SvgAnimation from '../SvgAnimation';

interface ComponentProps {
  progress: Function
}

const CoachAgreementOutro = (props: ComponentProps) => {
  const api = Api.getInstance();

  return (
    <div className='page_section flex flex-col justify-center'>
      <div style={{ width: '100%' }} className="modal_animation mx-auto">
        <SvgAnimation animationType='star' />
      </div>
      <div style={{ paddingTop: '40px', maxWidth: '492px', margin: '0 auto' }} className="text_center">
        {api.lang === 'sv' ?
          <>
            <h1>Matchad och klar!</h1>
            <div className='top_margin'>
              Dags att boka möte med din coach så du kan komma igång.
            </div>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress()} className="button mobile_size_btn green inline_block">
                Nästa
              </div>
            </div>
          </>
          :
          <>
            <h1>Matched and ready!</h1>
            <div className='top_margin'>
              It's time to get started and book a meeting with your coach.
            </div>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress()} className="button mobile_size_btn green inline_block">
                Next
              </div>
            </div>
          </>}
      </div>
    </div>
  )
}

export default CoachAgreementOutro;
