import React from 'react';
import './Calendar.css';
import { getWeekdayShort, getWeekdayLong, getWeekFromDate, isSameDate, getMonthLong, getDayOfWeek } from "../../helpers/dateFunctions";
import Api from "../../services/Api";
import { CalendarEventInterface } from "../../interfaces/CalendarEvent";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { TrVar } from '../../services/translate';

interface DayInterface {
  weekDay: number;
  d: Date;
  today: boolean;
  isBookable: boolean;
}

interface ComponentProps {
  events: Array<CalendarEventInterface>;
  clickDate: Function;
  clickEvent: Function;
  onlyDate?: boolean;
  lockPeriod?: string;
  firstDate?: Date;
  lastDate?: Date;
  boxHeight?: number;
}

interface ComponentState {
  date: string;
  days: Array<DayInterface>;
  rows: Array<string>;
  headers: Array<string>;
  width: number;
  height: number;
  offsetLeft: number;
  boxWidth: number;
  boxHeight: number;
  showMonth: boolean;
  dummy: boolean;
}

export default class Calendar extends React.Component<ComponentProps, ComponentState> {

  resizeFinished: any;
  containerRef: any;
  scrollRef: any;
  day = new Date();
  today = new Date();
  api = Api.getInstance();

  constructor(props: ComponentProps) {
    super(props);
    this.containerRef = React.createRef();
    this.scrollRef = React.createRef();
    this.state = {
      date: "2022-08-29",
      days: [],
      rows: [
        "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",
        "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
      headers: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      height: 720,
      width: 0,
      boxHeight: 44,
      boxWidth: 0,
      offsetLeft: 40,
      showMonth: this.props.lockPeriod === 'month',
      dummy: false,

    }
  }

  scrollHeight = () => {
    if (this.scrollRef.current) {
      const scrollTop = this.state.showMonth ? 0 : 7 * this.state.boxHeight - 15;
      this.scrollRef.current.scrollTop = scrollTop;
    } else {
      console.log('No scrollRef yet');
    }
  }

  async componentDidMount() {
    window.addEventListener('resize', this.onResize);
    await this.setDays();
    this.setSizes(true);
  
    // Force re-render if width is still 0
    if (this.state.width === 0) {
      setTimeout(() => {
        if (this.containerRef.current) {
          const width = this.containerRef.current.clientWidth;
          if (width > 0) {
            this.setState({ width: width }, () => {
              this.setSizes(false);
            });
          }
        }
      }, 100);
    }
  
    setTimeout(() => {
      this.scrollHeight();
    }, 5);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    clearTimeout(this.resizeFinished);
    this.resizeFinished = setTimeout(() => {
      this.setSizes(false);
    }, 300);
  }

  setSizes = (first: boolean) => {
    if (this.containerRef.current) {
      const width = this.containerRef.current.clientWidth;
      if (width !== 0 || first) {
        let rowNr = 24;
        let boxHeight = 44;
        if (this.state.showMonth) {
          rowNr = Math.ceil(this.state.days.length / 7);
          boxHeight = this.props.boxHeight ? this.props.boxHeight : 120;
        }
        const boxWidth = (width - this.state.offsetLeft) / 7;
        const height = boxHeight * rowNr;
        this.setState({ width: width, height: height, boxWidth: boxWidth, boxHeight: boxHeight }, () => {
          // Dummy state update to force re-render
          this.setState({ dummy: !this.state.dummy });
        });
      }
    }
  }
  

  isDateBookable = (d: Date) => {
    if (this.props.firstDate && d < this.props.firstDate) {
      return false;
    } else if (this.props.lastDate && d > this.props.lastDate) {
      return false;
    }
    return true;
  }

  setDays = () => {
    const days = [] as Array<DayInterface>;

    if (this.state.showMonth) {
      let dayFirst = new Date(this.day);
      dayFirst.setDate(1);
      dayFirst.setHours(0, 0, 0, 0);
      let dayOfWeek = getDayOfWeek(dayFirst);
      const monthNr = dayFirst.getMonth();
      let addedDays = 0;
      for (let i = -dayOfWeek; i < 42; i++) {
        let dayDate = new Date(dayFirst);
        dayDate.setDate(dayFirst.getDate() + i);
        if (i > 27 && dayDate.getMonth() !== monthNr && addedDays % 7 === 0) {
          break;
        }
        days.push({
          weekDay: getDayOfWeek(dayDate),
          d: dayDate,
          today: isSameDate(dayDate, this.today),
          isBookable: this.isDateBookable(dayDate),
        });
        addedDays++;
      }
    } else {
      let dayOfWeek = getDayOfWeek(this.day);
      let monday = new Date(this.day);
      monday.setDate(this.day.getDate() - dayOfWeek);

      for (let i = 0; i < 7; i++) {
        let dayDate = new Date(monday);
        dayDate.setDate(monday.getDate() + i);
        dayDate.setHours(0, 0, 0, 0);
        const today = isSameDate(dayDate, this.today);
        days.push({
          weekDay: i,
          d: dayDate,
          today: today,
          isBookable: today ? true : this.isDateBookable(dayDate),
        });
      }
    }

    return new Promise((resolve) => {
      this.setState({ days: days }, () => {
        resolve(true);
      });
    });
  }

  goToToday = () => {
    this.day = new Date();
    this.setDays();
  }

  showWeekThisDay = (d: Date) => {
    this.day = d;
    this.togglePeriod('w');
  }

  addDays = (days: number) => {
    if (this.state.showMonth) {
      let addMonths = days > 0 ? 1 : -1;
      this.day.setDate(15);
      this.day.setMonth(this.day.getMonth() + addMonths);
    } else {
      this.day.setDate(this.day.getDate() + days);
    }

    this.setDays().then(() => {
      if (this.state.showMonth) {
        this.setSizes(false);
      }
    });
  }

  clickDate = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const t = y / this.state.boxHeight;
    const row = Math.floor(t);
    const col = Math.floor(x / this.state.boxWidth);
    let date: Date;
    if (this.state.showMonth) {
      date = new Date(this.state.days[7 * row + col].d.getTime());
      const hour = Math.floor(24 * (y % this.state.boxHeight) / this.state.boxHeight);
      date.setHours(hour, 0, 0, 0);
    } else {
      date = new Date(this.state.days[col].d.getTime());
      const minutes = Math.floor((t - row) * 60);
      date.setHours(row, minutes, 0, 0);
    }

    this.props.clickDate(date);
  }

  showEventsMonth = () => {
    let lastDayPos = this.state.days.length - 1;
    let dayEvents: Array<number> = [];
    for (let i = 0; i < this.state.days.length; i++) {
      dayEvents[i] = 0;
    }

    return (
      <>
        {
          this.props.events.filter(event => (event.d > this.state.days[0].d && event.d < this.state.days[lastDayPos].d))
            .sort((a, b) => a.d.getTime() - b.d.getTime())
            .map((event, i) => {
              const dayPos = Math.floor((event.d.getTime() - this.state.days[0].d.getTime()) / (24 * 3600 * 1000));
              let dayEventNr = dayEvents[dayPos];

              const left = (dayPos % 7) * this.state.boxWidth + this.state.offsetLeft;
              const top = Math.floor(dayPos / 7) * this.state.boxHeight + 24 + dayEventNr * 25;
              const width = this.state.boxWidth - 1;
              dayEventNr++;
              dayEvents[dayPos] = dayEventNr;
              if (dayEventNr < 4) {
                return (
                  <div className={"calendar_event " + (this.props.onlyDate ? 'button_disabled ' : 'cursor-pointer ')
                    + (event.class ? event.class : '')} key={"event_" + i}
                    onClick={() => this.props.clickEvent(event)}
                    style={{
                      width: width + "px",
                      top: top + "px",
                      left: (left + 1) + "px",
                    }}>
                    {event.name}
                  </div>
                )
              } else if (dayEventNr === 4) {
                return (
                  <div className="calendar_event cursor-pointer bg_light_blue color_black" key={"event_" + i}
                    onClick={() => this.showWeekThisDay(event.d)}
                    style={{
                      width: width + "px",
                      top: top + "px",
                      left: (left + 1) + "px",
                    }}>
                    {this.api.trTxt(TrVar.More)}
                  </div>
                )
              } else {
                return (<></>)
              }

            })
        }
      </>
    )
  }

  showEventsWeek = () => {
    const firstDate = this.state.days[0].d;
    const lastDate = this.state.days[6].d;
    lastDate.setHours(23, 59, 59, 0);
    return (
      <>
        {
          this.props.events.filter(event => (event.d > firstDate && event.d < lastDate))
            .map((event, i) => {
              const h = this.state.boxHeight * event.length / 60 - 2;
              const dayOfWeek = getDayOfWeek(event.d);
              const left = this.state.offsetLeft + dayOfWeek * this.state.boxWidth;
              const top = this.state.boxHeight * (event.d.getHours() + event.d.getMinutes() / 60) + 1;
              return (
                <div className={"calendar_event  " + (this.props.onlyDate ? 'button_disabled ' : 'cursor-pointer ')
                  + (event.class ? event.class : '')} key={"event_" + i}
                  onClick={() => this.props.clickEvent(event)}
                  style={{
                    width: (this.state.boxWidth - 2) + "px",
                    height: h + "px",
                    top: top + "px",
                    left: (left + 1) + "px",
                    zIndex: 10001
                  }}>
                  {event.name}
                </div>
              )
            })
        }
      </>
    )
  }

  showDateHeader = () => {
    let month = '';
    let year = 0;
    let week = '';
    if (this.state.days.length > 3) {
      let monthDay = 3;
      if (this.state.days.length > 7) {
        monthDay = 10;
      }
      const day = this.state.days[monthDay].d;
      week = this.api.trTxt(TrVar.Week) + ' ' + getWeekFromDate(this.day);
      year = day.getFullYear();
      month = getMonthLong(day.getMonth(), this.api.lang);
    }
    if (this.state.boxWidth > 70) {
      return (
        <>
          <div className="font_small blue">
            {this.state.showMonth ? '' : week}
          </div>
          <h2 className="calendar_date_header white"> {month} {year}</h2>

        </>
      )
    } else {
      return (
        <>
          {
            !this.state.showMonth &&
            <div className='font_small blue'>{week}</div>
          }
          <h2 className='calendar_date_banner white'>{month} {year}</h2>

        </>)
    }
  }

  showDayHeader = (day: DayInterface) => {
    if (this.state.boxWidth > 100) {
      return (
        <div className={day.isBookable ? '' : 'calendar_unbookable'}>
          {getWeekdayLong(day.weekDay, this.api.lang)}&nbsp;&nbsp;{day.d.getDate()}
        </div>
      )
    } else if (this.state.boxWidth > 70) {
      return (
        <div className={day.isBookable ? '' : 'calendar_unbookable'}>
          {getWeekdayShort(day.weekDay, this.api.lang)}&nbsp;&nbsp;{day.d.getDate()}
        </div>
      )
    }
    const weekDay = getWeekdayShort(day.weekDay, this.api.lang);
    return (
      <div className={day.isBookable ? '' : 'calendar_unbookable'}>
        <p>{weekDay}</p>
        <p>{day.d.getDate()}</p>
      </div>
    )
  }

  togglePeriod(period: string | undefined | null) {
    let showMonth = false;
    if (period && period === 'm') {
      showMonth = true;
    }
    console.log('period is ' + showMonth);
    this.setState({ showMonth: showMonth }, () => {
      this.setDays().then(() => {
        this.setSizes(true);
        this.scrollHeight();
      });
    });
  }

  showMonthHeader = () => {
    const weekDays = [0, 1, 2, 3, 4, 5, 6];
    return (
      <>
        {
          weekDays.map(day => {
            return (
              <div className="calendar_header_col"
                key={"header_" + day} style={{ width: this.state.boxWidth + "px" }}>
                {
                  this.state.boxWidth > 70 ? getWeekdayLong(day, this.api.lang) : getWeekdayShort(day, this.api.lang)
                }
              </div>
            )
          })
        }
      </>
    )
  }

  showWeekHeader = () => {
    return (
      <>
        {
          this.state.days.map((day, i) => {
            return (
              <div className={"w-full calendar_header_col " + (day.today ? 'today' : '')}
                key={"header_" + i} style={{ width: this.state.boxWidth + "px" }}>
                {this.showDayHeader(day)}
              </div>
            )
          })
        }
      </>
    )
  }

  monthDates = () => {
    const colorUnbookable = '#60608b';
    const colorNotInMonth = this.props.firstDate ? '#fff' : '#777';
    return (
      <>
        {
          this.state.days.map((day, i) => {

            const row = Math.floor(i / 7);
            const col = i % 7;
            const top = (row * this.state.boxHeight + 4) + 'px';
            const left = (col * this.state.boxWidth + 4 + this.state.offsetLeft) + 'px';
            const dayNr = day.d.getDate();
            const fontColor = day.today ? '#000' :
              day.isBookable ?
                ((row === 0 && dayNr > 10) || (row > 2 && dayNr < 10)) ? colorNotInMonth : '#fff'
                :
                colorUnbookable;
            const style = {
              top: top,
              left: left,
              color: fontColor,
              backgroundColor: day.today ? '#fff' : '',
            }
            return (
              <div key={"date_text_" + i} className="calendar_month_nr" style={style}>{dayNr}</div>
            )
          })
        }
      </>
    )
  }

  monthLeftCol = () => {
    const rows = [];
    let dayPos = 0;
    while (dayPos < this.state.days.length) {
      const day = this.state.days[dayPos];
      let weekName = this.api.trTxt(TrVar.W);
      weekName += getWeekFromDate(day.d);
      rows.push(weekName);
      dayPos += 7;
    }
    return (
      <>
        {
          rows.map((row, i) => {
            const top = (i + 0.5) * this.state.boxHeight - 9;
            return (
              <div key={"row_" + i} style={{
                top: top + "px",
                position: "absolute",
                width: this.state.offsetLeft + "px",
                textAlign: "center",
              }}>{row}</div>
            )
          })
        }
      </>
    )
  }

  weekLeftCol = () => {
    return (
      <>
        {
          this.state.rows.map((row, i) => {
            const top = (i + 1) * this.state.boxHeight - 9;
            return (
              <div key={"hour_" + row} style={{
                top: top + "px",
                position: "absolute",
                width: this.state.offsetLeft + "px",
                textAlign: "center",
              }}>{row}</div>
            )
          })
        }
      </>
    )
  }

  render() {
    return (
      <div className="calendar_container" ref={this.containerRef}>
        <div className="flex calendar_top_banner">
          <div className='cal_min_width'>
            {this.showDateHeader()}
          </div>

          <div className="flex cal_date_header justify-between min-w-[370px]">
            {
              !this.props.lockPeriod &&
              <div>
                <IonSegment
                  value={this.state.showMonth ? 'm' : 'w'} mode="ios"
                  onIonChange={(e) => this.togglePeriod(String(e.target.value))}
                >
                  <IonSegmentButton className='cal_segment_button' value="w">
                    <IonLabel>{this.api.trTxt(TrVar.Week)}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton className='cal_segment_button' value="m">
                    <IonLabel>{this.api.trTxt(TrVar.Month)}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
            }

            <div>
              <div className="flex items-center justify-center">
                <div onClick={() => this.addDays(-7)} className="cursor-pointer mx-4">
                  <img style={{ transform: 'rotate(90deg)' }} alt="back" className="icon_medium" src="/assets/icon/white/arrow_down.svg" />
                </div>
                <div className="white">
                  <div className={"button white inline_block"} onClick={() => this.goToToday()}>
                    {
                      this.api.trTxt(TrVar.Today)
                    }
                  </div>
                </div>
                <div onClick={() => this.addDays(7)} className="cursor-pointer mx-4">
                  <img style={{ transform: 'rotate(-90deg)' }} alt="back" className="icon_medium" src="/assets/icon/white/arrow_down.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="calendar_header mt-6">
          {
            this.state.showMonth ? this.showMonthHeader() : this.showWeekHeader()
          }
        </div>

        <div className="calendar_date_container scroll_y" ref={this.scrollRef}>

          {
            this.state.width === 0 ? (
              <></>
            ) : (
              <div className="calendar_content relative"
                style={{ height: (this.state.height + "px") }}
              >
                {
                  this.state.showMonth ? this.monthLeftCol() : this.weekLeftCol()
                }

                {
                  this.state.showMonth && this.monthDates()
                }

                <div className="calendar_grid cursor-pointer" style={{
                  paddingLeft: this.state.offsetLeft + "px",
                  left: this.state.offsetLeft + "px",
                  backgroundSize: (this.state.boxWidth + "px " + this.state.boxHeight + "px")
                }}
                  onClick={(e) => this.clickDate(e)} />

                {
                  this.state.showMonth ? this.showEventsMonth() : this.showEventsWeek()
                }
              </div>
            )
          }
        </div>

      </div>
    )
  }

};
