import React from "react";

const Loading = () => {
  return (
    <div className="mt-24 text_center mx-auto gray">
      <h2>Laddar...</h2>
    </div>
  );
}

export default Loading;
