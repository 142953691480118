import React from 'react';

type TextElement = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'a' | 'div' | 'li';

interface TextProps {
    element?: TextElement;
    size?: 'xxs' | 'xs' | 'sm' | 'md' ;
    weight?: 'regular' | 'bold';
    color?: 'white' | 'gray' | 'black' | 'blue' | 'error' | 'cerise';
    className?: string;
    href?: string;
    children: React.ReactNode;
    onClick?: () => void;
}

const TextComponent: React.FC<TextProps> = ({
    element = 'p',
    size = 'md',
    color = 'white',
    weight= "regular",
    className = '',
    href,
    children,
    onClick = () => {},
}) => {
  // Define sizes
  const sizeClasses = {
    xxs: 'font_smallest',
    xs: 'font_smaller',
    sm: 'font_small',
    md: '',
  };

  const weightClasses = {
    regular: '',
    bold: 'bold',
  };

  const colorClasses = {
    white: 'text-white',
    gray: 'color_neutral_400',
    black: 'text-black',
    blue: 'blue',
    error: 'error_red',
    cerise: 'color_cerise'
  };


  // Combined Tailwind classes
  const combinedClasses = `${sizeClasses[size]} ${weightClasses[weight]} ${colorClasses[color]} ${className}`;

  // Element mapping
  const Element = element;

  if (element === 'a' && href) {
    return (
      <Element className={combinedClasses} href={href}>
        {children}
      </Element>
    );
  }

  return <Element className={combinedClasses}>{children}</Element>;
};

export default TextComponent;
