import React from 'react';
import Api from "../../services/Api"
import { changeUrl } from "../../services/Navigation"
import { TrVar } from "../../services/translate";

interface ComponentProps {
  hasBeenChatCoached: boolean
  hasBeenVideoCoached: boolean
}

const ChatCoachMessage = (props: ComponentProps) => {
  const api = Api.getInstance();
  const today = new Date().getDate();

  const renderContent = () => {
    if (props.hasBeenVideoCoached) {
      if (today % 3 === 0) {
        return (
          <h1>{api.trTxt(TrVar.WouldYouLikeToContinueReceivingCoaching)}</h1>
        )         
      } else if (today % 3 === 1) {
        return (
          <h1>{api.trTxt(TrVar.ReceiveContinuedGuidanceThroughYourDevelopment)}</h1>
        )         
      } else {
        return (
          <h1>{api.trTxt(TrVar.KeepBoostingYourselfWithCoaching)}</h1>
        )         
      }
  
    } else {
      if (today % 6 === 0) {
        return (
          <h1>{api.trTxt(TrVar.BoostYourSelfEsteemWithCoaching)}</h1>
        )
      } else if (today % 6 === 1) {
        return (
          <h1>{api.trTxt(TrVar.ReceiveGuidanceInIdentifyingYourStrengthsAndMotivations)}</h1>
        )
      } else if (today % 6 === 2) {
        return (
          <h1>{api.trTxt(TrVar.TooMuchGoingOnInYourHead)}</h1>
        )
      } else if (today % 6 === 3) {
        return (
          <h1>{api.trTxt(TrVar.AchieveBalanceInYourLifeThroughCoaching)}</h1>
        )
      } else if (today % 6 === 4) {
        return (
          <h1>{api.trTxt(TrVar.BoostYourCareerThroughCoaching)}</h1>
        )
      } else {
        return (
          <h1>{api.trTxt(TrVar.DoYouWantToImproveYourRelationshipWithYourColleagues)}</h1>
        )
      }
    }
  }

  return (
    <div className="page_section half r_left_margin_early_break r_top_margin_early_break gradient_pink_purple color_black">
      <div className="mt-12 flex items-center">
        <img alt="beta" className="icon_medium_small" src="/assets/icon/megaphone.svg" />
        <h4 className="left_margin">{api.trTxt(TrVar.New)}!</h4>
      </div>
      {renderContent()}
      <h4 className="top_margin">{api.trTxt(TrVar.DiscoverTheBenefitsOfChatCoaching)}.</h4>
      <div className="btn_top_margin button green inline_block" onClick={() => changeUrl('/app/client/chat_coach')}>
        {api.trTxt(TrVar.GetStarted)}
      </div>
    </div>
  )
}

export default ChatCoachMessage;
