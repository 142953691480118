export const youAre: any = {
  D: {
    sv: ['rak', 'tydlig', 'bestämd'],
    en: ['straightforward', 'clear', 'determined']
  },
  I: {
    sv: ['entusiastisk', 'spontan', 'idérik och ser möjligheter där andra ser hinder'],
    en: ['enthusiastic', 'spontaneous', 'creative and see opportunities where others see obstacles']
  },
  S: {
    sv: ['ödmjuk', 'tålmodig', 'förstående och får andra att känna sig delaktiga'],
    en: ['humble', 'patient', 'understanding and make others feel seen']
  },
  A: {
    sv: ['saklig', 'noggrann', 'systematisk och ser till att det blir rätt där andra kan slarva med detaljer'],
    en: ['objective', 'thorough', 'systematic and you like to ensure everything is right where others may be careless with details']
  }
}

export const goodAt: any = {
  D: {
    sv: ['Ta initiativ och se till att komma i gång med det som är viktigast.',
      'Fokusera på att saker blir gjorda så snabbt som möjligt.',
      'Få energi av utmaningar och svårigheter.',
      'Vilja prestera bättre än andra.',
      'Inte fastna i detaljer.'],
    en: ['Take initiative and make sure you get started with what is most important.',
      'Focus on getting things done as quickly as possible.',
      'Gain energy from challenges and difficulties.',
      'Willing to perform better than others.',
      'Do not get caught up in details.']
  },
  I: {
    sv: ['Inspirera och skapa entusiasm.',
      'Hitta nya lösningar på problem.',
      'Sprida positiv stämning.',
      'Måla upp lockande visioner.',
      'Se till att du har kul tillsammans med andra.'],
    en: ['Inspiring and creating enthusiasm.',
      'Finding new solutions to problems.',
      'Spreading a positive mood.',
      'Painting enticing visions.',
      'Making sure you have fun with others.'],
  },
  S: {
    sv: ['Hjälpa till när det behövs.',
      'Vara uthållig även när förutsättningarna är sämre.',
      'Se andras behov.',
      'Skapa trygg och varm stämning.',
      'Arbeta lugnt och systematiskt.'],
    en: ['Assisting when needed.',
      'Perseverance, even when the conditions are tough.',
      'Seeing the needs of others.',
      'Creating a safe and warm atmosphere.',
      'Working calmly and systematically.'],
  },
  A: {
    sv: ['Göra objektiva bedömningar.',
      'Ställa höga kvalitativa krav.',
      'Ställa rätt faktafrågor.',
      'Hålla koll på viktiga detaljer.',
      'Se till att slutresultatet blir rätt.'],
    en: ['Making objective assessments.',
      'Setting high quality goals.',
      'Asking the right factual questions.',
      'Keeping track of important details.',
      'Making sure that the end result is correct.'],
  }
}

export const hintPrimary: any = {
  D: {
    sv: 'Ha koll så att du inte driver på dig själv för snabbt i din personliga utveckling och i stället ' +
      'accepterar att det ibland kan behöva ta tid. Du kommer ändå uppnå det som är riktigt viktigt för dig.',
    en: 'Make sure that you do not push yourself too hard in your personal development. Instead, try to accept that ' +
      'change sometimes may need to take time. You will still achieve things that are important to you.'
  },
  I: {
    sv: 'Ha lite extra koll på så du inte missar viktiga detaljer i det som händer i din personliga utveckling och ' +
      'som kan ge dig ännu fler möjligheter till ett ännu rikare och mer spännande liv.',
    en: 'Make sure to pay a little extra attention as not miss important details in what happens in your ' +
      'personal development. Seeing these details can give you more opportunities for a richer and more exciting life.'
  },
  S: {
    sv: 'Saker behöver ta tid – men inte för lång tid. Fokusera lite extra på konkreta och tydliga mål i din ' +
      'personliga utveckling så ger det resultat.',
    en: 'You could benefit from having a little extra control from time to time, as well as focusing on concrete and clear ' +
      'goals in your personal development. This could be beneficial if you want to see quicker improvements in your development.'
  },
  A: {
    sv: 'Det kan vara klokt att emellanåt kontrollera att ditt noggranna och genomtänkta sätt inte gör att du ' +
      'fastnar för mycket i detaljer. Det kan förhindra att du uppnår de resultat och den kvalitet som är viktigt ' +
      'för dig.',
    en: 'It may be wise to reflext from time to time, so that your careful and thoughtful manner does not cause you to ' +
      'get stuck in too many details which can prevent you from achieving the results and the quality that are so ' +
      'important to you.'
  }
}

export const hintSecondary: any = {
  D: {
    sv: 'Ha även koll så att du inte driver på dig själv för snabbt i din personliga utveckling och i stället ' +
      'accepterar att det ibland kan behöva ta tid. Du kommer ändå uppnå det som är riktigt viktigt för dig.',
    en: 'Make sure you do not push yourself too hard in your personal development. Instead, try to accept that ' +
      'change sometimes may need to take time. You will still achieve the things that are important to you.'
  },
  I: {
    sv: 'Du kan också ha lite extra koll på så du inte missar viktiga detaljer i det som händer i din personliga ' +
      'utveckling. Det kan ge dig ännu fler möjligheter till ett ännu rikare liv.',
    en: 'Make sure to pay a little extra attention as not miss important details in what happens in your ' +
      'personal development. Seeing these details can give you more opportunities for a richer and more exciting life.'
  },
  S: {
    sv: 'Saker behöver ta tid – men inte för lång tid. Fokusera lite extra på konkreta och tydliga mål i din ' +
      'personliga utveckling så ger det resultat.',
    en: 'You could benefit from having a little extra control from time to time, as well as focusing on concrete and clear ' +
      'goals in your personal development. This could be beneficial if you want to see quicker improvements in ' +
      'your development.'
  },
  A: {
    sv: 'Det kan också vara klokt att emellanåt kontrollera att ditt noggranna och genomtänkta sätt inte gör att du ' +
      'fastnar för mycket i detaljer. Det kan förhindra att du uppnår de resultat och den kvalitet som är viktigt ' +
      'för dig.',
    en: 'It may also be wise to reflect from time to time, so that your careful and thoughtful manner does not cause you ' +
      'get stuck in too many details, which can prevent you from achieving the results and the quality that are so ' +
      'important to you.'
  }
}

export const youSeek: any = {
  D: {
    sv: 'Inflytande och kontroll',
    en: 'Power and control'
  },
  I: {
    sv: 'Starka upplevelser',
    en: 'Exciting experiences'
  },
  S: {
    sv: 'Gemenskap',
    en: 'Community'
  },
  A: {
    sv: 'Förståelse för det du ska göra innan du sätter igång',
    en: 'An understanding of what to do before you start'
  },
}

export const youWant: any = {
  D: {
    sv: 'Tydliga resultat',
    en: 'Clear results'
  },
  I: {
    sv: 'Positiva bekräftelser',
    en: 'Positive affirmations'
  },
  S: {
    sv: 'Kontinuitet i det du gör',
    en: 'Continuity in what you do'
  },
  A: {
    sv: 'Noggrannhet och koll på detaljer',
    en: 'Accuracy and attention to detail'
  },
}

export const youNeed: any = {
  D: {
    sv: 'Konkreta mål',
    en: 'Concrete goals'
  },
  I: {
    sv: 'Möjligheter och frihet',
    en: 'Opportunities and freedom'
  },
  S: {
    sv: 'Veta vad som gäller',
    en: 'Know what is expected'
  },
  A: {
    sv: 'System för det du ska göra',
    en: 'Having clear processes in place'
  },
}

export const youCreate: any = {
  D: {
    sv: 'Initiativ och rakhet',
    en: 'Initiative and straight&shy;forwardness'
  },
  I: {
    sv: 'Entusiasm och möjlighetstänk',
    en: 'Enthusiasm and opportunistic thinking'
  },
  S: {
    sv: 'Omtänksam&shy;het, lugn och ro',
    en: 'A caring environment'
  },
  A: {
    sv: 'Kvalitet',
    en: 'Quality'
  },
}

export const toFeelGood: any = {
  D: {
    sv: ['Kunna ta egna och påverka andras beslut.',
      'Få översiktlig information utan för mycket detaljer.',
      'Veta att det du gör leder till konkreta resultat.'
    ],
    en: ['To be able to make your own decisions and influence other.',
      'Receive clear information with actionable steps.',
      'Know that what you do leads to concrete results.'
    ],
  },
  I: {
    sv: ['Få mycket stimulans för alla dina sinnesintryck.',
      'Få positiv feedback ofta, för den du är och det du gör.',
      'Få möjligheter att vara med ”där det händer saker”.'
    ],
    en: ['A lot of stimulation for all your sensory impressions.',
      'To regularly receive positive feedback for who you are and the work you do.',
      'Opportunities to be involved "where things happen"'
    ],
  },
  S: {
    sv: ['Kunna planera saker tillsammans med andra.',
      'Prata om vad som skall hända framöver.',
      'Göra saker tillsammans med andra.'
    ],
    en: ['To be able to plan things together with others.',
      'Talk about what will happen in the future.',
      'Do things with others.'
    ],
  },
  A: {
    sv: ['Få möjligheter att i detalj förstå det som är viktigt.',
      'Få tid och resurser att analysera de detaljer som krävs för att göra rätt.',
      'Få tid och resurser att se till att det blir rätt i slutändan.'
    ],
    en: ['To understand what is important.',
      'Have the time and resources to analyze what is required of you.',
      'Have the time and resources to ensure the outcome is accurate.'
    ],
  },
}