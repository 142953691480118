import React from 'react'

interface DashInterface {
  name: string
  value: number
}

interface ComponentProps{
  valueArr: Array<Array<number>>
  names: Array<string>
  dashes?: Array<DashInterface>
  maxWidth?: boolean
  colors: Array<string>
  maxHeight?: number
  width?: number
  isArea: boolean
}

const LineChart= (props: ComponentProps) => {
  let maxValue = 0
  let width = 0
  let max = 0;
  let min = 100;

  const setMinAndMax = () => {
    if (props.isArea) {
      max = 0;
      min = 100;    
      let v1;
      let v2;

      for (const values of props.valueArr) {
        for (let value of values) {
          if (value > max) {
            max = value;
          }

          if (value < min) {
            min = value;
          }
        }
      }

      if (props.dashes?.length === 2) {
        v1 = props.dashes[0].value
        v2 = props.dashes[1].value
      }

      if (v1) {
        if (max >= 75 || v1 >= 75) {
          max = 100;
        } else {
          max = 75;
        }
      } else {
        if (max >= 75) {
          max = 100;
        } else {
          max = 75;
        }      
      }

      if (v2) {
        if (min <= 25 || v2 <= 25) {
          min = 0;
        } else if (min < 50) {
          min = 25
        } else {
          min = 50
        }
      } else {
        if (min <= 25) {
          min = 0;
        } else if(min < 50) {
          min = 25
        } else {
          min = 50
        }      
      }      
    } else {
      max = 100;
      min = 0;
    }
  };

  const translateY = (y: number) => {
    let result = 100 * (y - min) / (max - min);
    return result;
  }

  const getCoords = () => {
    setMinAndMax();
    width = props.width ? props.width : 100 + 50 * (props.valueArr[0].length - 2)
    const xStep = props.valueArr[0].length > 1 ? width / (props.valueArr[0].length - 1) : width
    const coordArr = []
    for (const values of props.valueArr) {
      const coords = []
      let x = 0
      for (let value of values) {
        let translatedValue = translateY(value);
        coords.push([x, (100 - translatedValue)])
        x += xStep
        if (translatedValue > maxValue) {
          maxValue = translatedValue
        }
      }
      // If we only have one value, show that on two positions
      if (values.length === 1) {
        coords.push([xStep, (100 - translateY(values[0]))])
      }
      coordArr.push(coords)
    }
    return coordArr
  }

  const renderCoord = (coords: Array<Array<number>>, path: any, index: number) => {
    return (
      <g key={"path_" + index}>
        {
          path.bgPath &&
          <path d={path.bgPath} style={{ fill: path.gradientUrl, opacity: "0.3" }}>
            <animate attributeName="d" from={path.bgPathFrom} to={path.bgPath} dur={path.dur} />
          </path>
        }

        {
          coords.filter((c, i) => (i > 0 && i < (coords.length - 1))).map((coord, i) => {
            const x = (i + 1) * width / (coords.length - 1)
            const d = "M " + x + " 0 L " + x + " 100"
            return (
              <path d={d} key={"hor_line_" + index + "_" + i}
                style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />
            )
          })
        }

        {
          coords.map((coord, i) => {
            return (
              <circle key={"dot_" + index + "_" + i} cx={coord[0]} cy={coord[1]} r={3} style={{ fill: path.color }}>
                <animate attributeName="cy" from="100" to={coord[1]} dur={path.dur} />
              </circle>
            )
          })
        }
        <path d={path.linePath} style={{ stroke: path.color, strokeWidth: "1.5", fill: "none" }}>
          <animate attributeName="d" from={path.linePathFrom} to={path.linePath} dur={path.dur} />
        </path>
      </g>
    )
  }

  const dashBox = () => {
    if (props.dashes?.length === 2) {
      const v1 = translateY(props.dashes[0].value);
      const v2 = translateY(props.dashes[1].value)
      const height = Math.abs(v1 - v2)
      const y = 100 - Math.max(v1, v2)
      return (
        <rect x={0} y={y} width={width} height={height} fill="#20203D"/>
      )
    }
    return <></>
  }

  const drawYAxis = () => {
    const marginLeft = 24
    const diffMaxMin = max - min;

    if (props.isArea) {
      return (
        <g transform={"translate(" + (marginLeft - 9) + ", 9)"}>
          <text x={0} y={1} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{max === 100 ? 100 : 75}</text>

          {(diffMaxMin === 100 || diffMaxMin === 75) &&
            <text x={0} y={(diffMaxMin === 100 ? 25 : 33)} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{max === 100 ? 75 : 50}</text>
          }

          {(diffMaxMin === 50 || diffMaxMin === 25 || diffMaxMin === 100) &&
            <text x={0} y={50} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{diffMaxMin === 100 ? 50 : (diffMaxMin === 50 && max === 100) ? 75 : (diffMaxMin === 50 && max === 75) ? 50 : '' }</text>
          }

          {(diffMaxMin === 100 || diffMaxMin === 75) &&
            <text x={0} y={(diffMaxMin === 100 ? 75 : 67)} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{min === 0 ? 25 : 50}</text>
          }
        
          <text x={0} y={100} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{min === 25 ? 25 : min === 50 ? 50 : 0}</text>
        </g>
      );     
    }
    
    return (
      <g transform={"translate(" + (marginLeft - 9) + ", 9)"}>
        <text x={0} y={1} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{100}</text>
        <text x={0} y={25} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{75}</text>
        <text x={0} y={50} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{50}</text>
        <text x={0} y={75} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{25}</text>
        <text x={0} y={100} fontSize="0.4rem" fill="#ffffff" textAnchor="end">{0}</text>
      </g>
    );
  }

  const calculateChart = () => {
    const boxPath = "M 2 0 L " + (width - 2) + " 0 A 2 2 0 0 1 " + width + " 2 L " + width + " 98 A 2 2 0 0 1 "
      + (width - 2) + " 100 L 2 100 A 2 2 0 0 1 0 98 L 0 2 A 2 2 0 0 1 2 0"
    
    const diffMaxMin = max - min;

    if (props.isArea) {
      return (
        <>
          <path d={boxPath}
            style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="none" />
          
          {(diffMaxMin === 100 || diffMaxMin === 75) &&
            <path d={"M 0 " + (diffMaxMin === 100 ? '25' : '33') + " L " + width + (diffMaxMin === 100 ? ' 25' : ' 33')}
              style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />
          }

          {(diffMaxMin === 50 || diffMaxMin === 25) &&
            <path d={"M 0 50 L " + width + " 50"}
              style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />
          }

          {(diffMaxMin === 100 || diffMaxMin === 75) &&
            <path d={"M 0 " + (diffMaxMin === 100 ? '75' : '67') + " L " + width + (diffMaxMin === 100 ? ' 75' : ' 67')}
              style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />
          }
        </>
      );      
    }

    return (
      <>
        <path d={boxPath}
          style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="none" />
          
        <path d={"M 0 25 L " + width + " 25"}
          style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />

        <path d={"M 0 50 L " + width + " 50"}
          style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />

        <path d={"M 0 75 L " + width + " 75"}
          style={{ stroke: "#666666", strokeWidth: "0.5" }} fill="#000026" />
      </>
    );
  }
  
    let color = props.colors[0]
    let stopColor = "#ffccc8"

    const coordArr = getCoords()
    const paths: any = []
    for (let coordNr = 0; coordNr < coordArr.length; coordNr++) {
      const coords = coordArr[coordNr]
      const color = props.colors[coordNr]
      let bgPath = "M 0 100"
      let bgPathFrom = "M 0 100"
      let linePath = "M 0 " + coords[0][1]
      let linePathFrom = "M 0 100"
      let i = 0
      for (let coord of coords) {
        bgPath += " L " + coord[0] + " " + coord[1]
        bgPathFrom += " L " + coord[0] + " 100"
        if (i > 0) {
          linePath += " L " + coord[0] + " " + coord[1]
          linePathFrom += " L "+ coord[0] + " 100"
        }
        i++
      }
      bgPath += " L " + width + " 100 z"
      bgPathFrom += " L " + width + " 100 z"
      const gradientId = 'grad_' + color
      paths.push({
        linePath: linePath,
        linePathFrom: linePathFrom,
        bgPath: bgPath,
        bgPathFrom: bgPathFrom,
        color: color,
        dur: "0.5s",
        gradientUrl: 'url(#' + gradientId + ')'
      })
    }



    const gradientTop = 100 - maxValue
    const gradientId = 'grad_' + color
    const marginLeft = 24
    const marginRight = (props.dashes || props.maxWidth) ? 46 : 0
    const viewBox = "0 0 " + (width + marginLeft + marginRight) + " 124"
    let maxHeight = "unset"
    if (props.maxHeight) {
      if (window.innerWidth > 500) {
        maxHeight = props.maxHeight + "px"
      } else {
        const svgWidth = width + marginLeft
        const svgHeight = 120
        maxHeight = (300 * svgHeight / svgWidth) + "px"
      }
    }

  return (
      
    <svg viewBox={viewBox} style={{ maxHeight: maxHeight }}
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        {
          props.colors.length === 1 &&
          <linearGradient id={gradientId} x1="0%" y1={gradientTop + "%"} x2="0%" y2="100%">
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor={stopColor} />
          </linearGradient>
        }
      </defs>

      {drawYAxis()}

      {
        props.dashes?.map((dash, i) => {
          return (
            <text key={"dash_text_" + i} x={width + marginLeft + 1} y={100 - translateY(dash.value) + 8}
              fontSize="0.4rem" fill="#94abff">
              {dash.name}
            </text>
          )
        })
      }

      <g transform={"translate(" + (marginLeft - 6) + ", 6)"}>

        {dashBox()}

        {calculateChart()}

        {
          coordArr.map((coords, index) => {
            return renderCoord(coords, paths[index], index)
          })
        }

        {
          props.names.map((name, i) => {
            let x = props.names.length > 1 ? i * width / (props.names.length - 1) : 0
            let textAnchor = "middle"
            if (i === 0) {
              textAnchor = "start"
            } else if (i === (props.names.length - 1)) {
              textAnchor = "end"
            }
            return (
              <text key={"name_" + i} x={x} y={111} fontSize="0.4rem" fill="#ffffff" textAnchor={textAnchor}>
                {name}
              </text>
            )
          })
        }
        {
          props.dashes?.map((dash, i) => {
            return (
              <line key={"dash_line_" + i} x1={0} y1={100 - translateY(dash.value)} x2={width} y2={100 - translateY(dash.value)}
                style={{ stroke: "#94abff", strokeWidth: 1, strokeDasharray: "4 1" }} />
            )
          })
        }
      </g>
    </svg>
  );
}

export default LineChart;
