import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import './ClientLicense.css';
import { changeUrl } from "../../services/Navigation";
import { TrVar } from '../../services/translate';

interface ComponentState {
  completePurchase: boolean
}

interface ComponentProps {
  orderId: string
}

export default class KlarnaConfirm extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    completePurchase: false
  }

  componentDidMount() {
    this.confirmOrder()
  }

  confirmOrder = () => {
    const data = {
      order_id: this.props.orderId
    }
    this.api.post('klarna/confirm_order', data).then(response => {
      this.renderOrderSnippet(response.json.html_snippet)
      this.setState({ completePurchase: true });
    })
  }

  renderOrderSnippet = (htmlSnippet: string) => {
    var checkoutContainer = document.getElementById('checkout-container')
    if (checkoutContainer) {
      checkoutContainer.innerHTML = htmlSnippet
      var scriptsTags = checkoutContainer.getElementsByTagName('script')
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode
        if (parentNode) {
            var newScriptTag = document.createElement('script')
            newScriptTag.type = 'text/javascript'
            newScriptTag.text = scriptsTags[i].text
            parentNode.removeChild(scriptsTags[i])
            parentNode.appendChild(newScriptTag)
        }
      }
    }
  }

  render() {
    return (
      <>
        <Breadcrumbs breadcrumbs={[]} name={this.api.trTxt(TrVar.PurchaseConfirmation)} />
        <div className="page_content">
          <div className="page_section client_purchase_bg">
            <div style={{ backgroundColor: '#20203D', margin: '0', padding: '12px' }} className="flex section_direction">
              <div id="checkout-container" className="r-half-col half">
                {this.api.trTxt(TrVar.YourPurchaseIsBeingProcessed)}
              </div>
              <div className="r-half-col half">
                {this.state.completePurchase &&
                  <>
                      <div style={{ margin: '0' }} className='client_purchase_upgrade'>
                    <h2 style={{ marginBottom: '20px', fontWeight: '600' }}>{this.api.trTxt(TrVar.Awesome)}!</h2>
                      <div className="top_margin">
                      {this.api.trTxt(TrVar.YouveGotAccessToEverything)}
                    </div>
                        <div style={{ marginTop: '40px' }} onClick={() => changeUrl('/app/client/tasks2')} className="button green top_margin inline_block">
                          {this.api.trTxt(TrVar.Continue)}
                        </div>
                      </div>
                  </>
                }
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}
