import React from "react";
import {IonCol, IonGrid, IonModal, IonRow} from "@ionic/react";
import Api from "../../services/Api";
import {MeetingInterface, MeetingType, MeetingSuggestionInterface} from "../../interfaces/Meeting";
import {formatSoon, todayStr} from "../../helpers/dateFunctions";
import {changeUrl} from "../../services/Navigation";
import "./ClientBookings.css"
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import MeetingButton from "../MeetingButton";
import {PackageInterface, PackageType} from "../../interfaces/Package";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import CalendarClient from "./CalendarClient";
import { TrVar } from "../../services/translate";

interface ComponentProps {
  reload: Function
  isClientActive: boolean
  hasCoach: boolean
  navigation: any
  package: PackageInterface
}

interface ComponentState {
  nextMeetings: Array<MeetingInterface>
  lastBookableDate?: LastBookableDate
  showAddBooking: boolean
  rescheduleMeetingId: number
  showCancel: boolean
  packageId: number
  meeting: MeetingInterface
  showAllHistory: boolean
  cancelText: string
  error: string
  loaded: boolean
  meetingSuggestions: Array<MeetingSuggestionInterface>
}

interface LastBookableDate {
  date: string,
  reason: string
}

export default class ClientBookings extends React.Component<ComponentProps, ComponentState> {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    nextMeetings: [],
    showAddBooking: false,
    rescheduleMeetingId: 0,
    showCancel: false,
    packageId: 0,
    meeting: {} as MeetingInterface,
    showAllHistory: false,
    cancelText: '',
    error: '',
    loaded: false,
    meetingSuggestions: [],
  }

  componentDidMount() {
    if (this.props.package.isVideoCoachIncluded) {
      this.getMeetings()
    } else {
      this.setState({loaded: true})
    }

    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'bookings'}
    }
    this.api.post('client_log_event', data).then()
  }

  getMeetings = () => {
    this.api.get('client_meetings').then(response => {
      this.setState({
        nextMeetings: response.json.nextMeetings,
        lastBookableDate: response.json.lastBookableDate,
        packageId: response.json.packageId,
        meetingSuggestions: response.json.meetingSuggestions,
        loaded: true
      })
    })
  }

  handleCancelText = (e: any) => {
    this.setState({cancelText: e.target.value})
  }

  showCancelBooking = (meeting: MeetingInterface) => {
    this.setState({meeting: meeting, showCancel: true})
  }

  goToChat = () => {
    this.setState({showCancel: false},
        () => setTimeout(() => changeUrl('/app/client/chat'), 300))
  }

  renderCancelBooking = () => {
    if (!this.state.meeting) {
      return (<></>)
    }
    const dateStart = new Date(this.state.meeting.start)
    const now = new Date()
    const tooSoon = (dateStart.getTime() - now.getTime()) < 24 * 3600 * 1000
    return (
      <div className="top_margin bottom_margin right_margin left_margin color_white text_center">
        {
          tooSoon ? (
            <>
              <h3 className="mt-4">{this.api.trTxt(TrVar.CancelMeeting)}</h3>
              <div className="top_margin text_left">
                <p>
                  {this.api.trTxt(TrVar.CannotReschedule) + ' ' + this.api.trTxt(TrVar.ChatToReschedule)}
                </p>
                <div className="text_center top_margin">
                  <div className="button green inline_block" onClick={() => this.goToChat()}>
                    {this.api.trTxt(TrVar.ToChat)}
                  </div>
                </div>

              </div>
            </>
            ) : (
              <>
                <h3 className="mt-4">
                  {this.api.trTxt(TrVar.ChangeMeeting)}
                </h3>
                <p className="top_margin">{this.api.trTxt(TrVar.CalendarChangeTime)}</p>
                <div className="button green inline_block top_margin" onClick={() => this.rescheduleBooking(this.state.meeting.id)}>
                  {this.api.trTxt(TrVar.ToCalendar)}
                </div>

              </>
          )
        }
        <h3 className="mt-12">
          {this.api.trTxt(TrVar.DoYouWantToCancelMeeting)}
        </h3>
        <textarea className="top_margin page_text_area small w-full" rows={4}
                  placeholder={this.api.trTxt(TrVar.CancelMeetingExplanation)}
                  value={this.state.cancelText} onChange={this.handleCancelText}/>
        <div className="top_margin">
          <div className="button red inline_block" onClick={() => this.cancelBooking()}>
            {this.api.trTxt(TrVar.CancelMeeting)}
          </div>
        </div>
      </div>
    )
  }

  cancelBooking = () => {
    const meetingId = this.state.meeting.id
    const url = 'client_meeting_block/cancel/'
    this.setState({showCancel: false})
    const data = {
      cancel_text: this.state.cancelText
    }
    this.api.post(url + meetingId, data).then(_response => {
      this.getMeetings()
      this.props.reload()
    })
  }

  saveBooking = (booked: boolean) => {
    if (booked) {
      this.props.reload()
      this.setState({ rescheduleMeetingId: 0 });
    }
    this.getMeetings()
    this.goToMenu()
  }

  showMeetingStatus = (meetingType: number) => {
    let status = ''
    if (meetingType === 1) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === 2) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      return (
        <div className="top_margin error_red">{status}</div>
      )
    }
    return (<></>)
  }

  hideAddBooking = () => {
    this.setState({showAddBooking: false, rescheduleMeetingId: 0})
  }

  rescheduleBooking = (id:any) => {
    this.setState({ rescheduleMeetingId: id, showCancel: false },
      () => setTimeout(() => {changeUrl('/app/client/bookings/reschedule')}, 300))
  }

  goToMenu = () => {
    changeUrl('/app/client/bookings')
    this.setState({ rescheduleMeetingId: 0 });
  }

  meetingSuggestionReply = (suggestion: MeetingSuggestionInterface, status: number) => {
    const data = {
      suggestionId: suggestion.id,
      status: status
    }
    this.api.post('client_meeting_suggestion/reply', data).then(_response => {
      this.getMeetings()
    })
  }

  showMeetingSuggestionStatus = (suggestion: MeetingSuggestionInterface) => {
    let recently = new Date()
    recently.setMinutes(recently.getMinutes() - 30)

    const isPassed = new Date(suggestion.start) < recently
    const isBookable = !this.state.lastBookableDate || this.state.lastBookableDate.date >= suggestion.start.substring(0, 10)

    if (suggestion.initiator === 'coach') {
      if (suggestion.status === 0) {
        return (
          <div>
            {
              !isPassed && isBookable ? (
                <div className="button green inline_block"
                    onClick={() => this.meetingSuggestionReply(suggestion, 1)}>
                  {this.api.trTxt(TrVar.Accept)}
                </div>
              ) : (
                <>{this.api.trTxt(TrVar.CannotBeBooked)}</>
              )
            }
            <div className="ml-2 button red inline_block"
                 onClick={() => this.meetingSuggestionReply(suggestion, 2)}>
              {this.api.trTxt(TrVar.Reject)}
            </div>
          </div>
        )
      } else {
        return (
          <div>{suggestion.status === 1 ?
            this.api.trTxt(TrVar.AcceptedByYou) :
            this.api.trTxt(TrVar.RejectedByYou)}</div>
        )
      }
    } else {
      if (suggestion.status === 0) {
        return (
          <div>Waiting for coach reply</div>
        )
      } else if (suggestion.status === 1) {
        return (
          <div>Accepted by coach</div>
        )
      } else if (suggestion.status === 2) {
        return (
          <div>Rejected by coach</div>
        )
      }
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    if (!this.props.package.isVideoCoachIncluded) {
      return (
        <div className="page_content">
          <Breadcrumbs name={this.api.trTxt(TrVar.YourBookings)}/>
          <div className="page_section">
            {
              this.api.trTxt(TrVar.DoesNotAllowMeetingsWithCoach)
            }
          </div>
        </div>
      )
    }
    if (this.props.navigation.sub1 === 'meeting_times') {
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.YourBookings), link: '/app/client/bookings', linkFunc: this.saveBooking}]
          } name={this.api.trTxt(TrVar.BookNewMeeting)}/>
          <CalendarClient close={this.saveBooking} lastBookableDate={this.state.lastBookableDate?.date} />
        </div>
      )
    } else if (this.props.navigation.sub1 === 'reschedule') {
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.YourBookings), link: '/app/client/bookings', linkFunc: this.saveBooking}]
          } name={this.api.trTxt(TrVar.Reschedule)}/>
            <CalendarClient close={this.saveBooking} lastBookableDate={this.state.lastBookableDate?.date}
                            rescheduleMeetingId={this.state.rescheduleMeetingId}/>

        </div>
      )
    }
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.YourBookings)}/>
        <IonModal isOpen={this.state.showCancel} onDidDismiss={() => this.setState({showCancel: false})}
          className="modal_medium">
              <img alt="close" className="icon_small close_div" src="/assets/icon/white/cross.svg"
                   onClick={() => this.setState({showCancel: false})} />                
          {
            this.renderCancelBooking()
          }
        </IonModal>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.ComingMeetings)}</h1>
          <div className="page_bar" />

        {
          this.props.isClientActive && this.props.hasCoach ? (
            <>
              {
                this.state.nextMeetings.length === 0 &&
                  <div className="border_box top_margin">
                    {this.api.trTxt(TrVar.YouHaveNoUpcomingMeetingsBooked)}
                  </div>
              }

              {
                this.state.nextMeetings.map((meeting: MeetingInterface) => {
                  return (
                    <div key={"next_meeting_" + meeting.id} className="border_box top_margin flex items-center justify-between">
                      <img alt="coach" src={meeting.coach.picture} className="picture_small round"/>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12" sizeMd="4" className="flex items-center">
                            <h4>
                              {meeting.coach.firstName} {meeting.coach.lastName}
                              {
                                (meeting.guestName && meeting.guestName.length > 0) ? " + " + meeting.guestName : ""
                              }
                            </h4>

                          </IonCol>
                          <IonCol size="12" sizeMd="4" className="flex items-center">
                              <div className="flex items-center">
                                <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                                {formatSoon(meeting.start, this.api.lang)}, {meeting.length} min
                              </div>
                          </IonCol>

                          <IonCol size="12" sizeMd="4" className="flex items-center">
                            {
                              meeting.meetingType === MeetingType.BOOKED &&
                                <div className="flex items-center">
                                  <div className="link_text"
                                       onClick={() => this.showCancelBooking(meeting)}>
                                    {this.api.trTxt(TrVar.ChangeBooking)}
                                  </div>
                                  <div className="left_margin">
                                      <MeetingButton who="client" meeting={meeting}
                                                     name={meeting.client.firstName}/>
                                  </div>
                                </div>
                            }
                            {
                              meeting.meetingType === 1 &&
                                <div>{this.api.trTxt(TrVar.YouHaveCancelled)}</div>
                            }
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  )
                })
              }

              <div className="top_margin">
                {
                  (!this.state.lastBookableDate || this.state.lastBookableDate.date >= todayStr()) &&
                    <div className="button inline_block" onClick={() => changeUrl('/app/client/bookings/meeting_times')}>
                      {this.api.trTxt(TrVar.BookNewMeeting)}
                    </div>
                }
                {
                  this.state.lastBookableDate &&
                  <div className="top_margin">
                    {
                      (this.state.lastBookableDate.reason === 'CLIENT_LICENSE_EXPIRES' ||
                       this.state.lastBookableDate.reason === 'CUSTOMER_LICENSE_EXPIRES') &&
                      <>
                        {this.api.trTxt(TrVar.CannotBookMeetingsAfter)} {this.state.lastBookableDate.date} {this.api.trTxt(TrVar.WhenYourLicenseExpires).toLowerCase()}
                      </>
                    }
                    {
                      this.state.lastBookableDate.reason === 'ALL_MEETINGS_BOOKED' &&
                      <>
                        {this.api.trTxt(TrVar.AllMeetingsBooked)}
                      </>
                    }
                  </div>
                }
              </div>
            </>
          ) : (
            <div className="top_margin">
            {
              !this.props.isClientActive ? (
                <p>{this.api.trTxt(TrVar.AccountInactive)} {this.api.trTxt(TrVar.CannotBookMeetings)}</p>
              ) : (
                <>
                {
                  this.state.packageId === PackageType.ACCESS ? (
                    <p className="bottom_margin">
                      {this.api.trTxt(TrVar.NoAccessToCoachYet)}
                    </p>
                  ) : (this.state.packageId === PackageType.FREE ||
                    this.state.packageId === PackageType.ACCESS_PRIVATE) ? (
                    <div className="flex section_direction">
                      <div className="r-half-col">
                        <div style={{ maxWidth: '496px' }}>
                          <h2>{this.api.trTxt(TrVar.YourLicenseDoesNotIncludeCoachMeetings) }</h2>
                          <div className="top_margin blue">
                            {this.api.trTxt(TrVar.IfYouHaveHadCoach)}
                          </div>
                          {
                            this.state.packageId === PackageType.FREE ? (
                              <>
                                <div className="top_margin blue">
                                  {this.api.trTxt(TrVar.WithLicenseAccess)}
                                </div>
                                <div onClick={() => changeUrl('/app/client/purchase')} className="top_margin button green inline_block">
                                  {this.api.trTxt(TrVar.Upgrade)}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="top_margin blue">
                                  {this.api.trTxt(TrVar.DoYouWantToUpgrade)}
                                </div>
                                <div onClick={() => changeUrl('/app/client/request_contact')} className="top_margin button green inline_block">
                                  {this.api.trTxt(TrVar.GetStarted)}
                                </div></>
                            )
                          }
                        </div>
                      </div>
                      <div className="r-half-col r_top_margin text_center">
                        <img src="/assets/illustration/matching.svg" width={286} alt="" />
                      </div>
                    </div>  
                  )
                  :
                  (
                    <>
                      <p className="bottom_margin">
                        {this.api.trTxt(TrVar.NotSelectedCoach)}. {this.api.trTxt(TrVar.CannotBookMeetings)}
                      </p>
                      <div className="button inline_block" onClick={() => changeUrl('/app/client/editCoach')}>
                        {this.api.trTxt(TrVar.SelectCoach)}
                      </div>
                    </>
                  )
                }
                </>
              )
            }
            </div>
          )
        }
        </div>

        {
          (this.props.isClientActive && this.props.hasCoach && this.state.meetingSuggestions.length > 0) &&
          <div className="top_margin page_section">
              <h2>{this.api.trTxt(TrVar.MeetingSuggestion)}</h2>
            {
              this.state.meetingSuggestions.map((suggestion: MeetingSuggestionInterface) => {
                return (
                  <div className="border_box top_margin r-flex justify-between items-center"
                       key={"suggestion_" + suggestion.id}>
                    <div className="flex items-center">
                      <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                      {formatSoon(suggestion.start, this.api.lang)}, {suggestion.length} min
                    </div>
                    <p>{suggestion.initiator === 'coach' ?
                        this.api.trTxt(TrVar.CoachSuggestion)
                        :
                        this.api.trTxt(TrVar.YourSuggestion)}</p>
                    {this.showMeetingSuggestionStatus(suggestion)}
                  </div>
                )
              })
            }
          </div>
        }

        {(this.state.packageId !== PackageType.FREE && this.state.packageId !== PackageType.ACCESS_PRIVATE) &&

          <div className="top_margin page_section">
            <h2>{this.api.trTxt(TrVar.PreviousMeetings)}</h2>

            <div className="top_margin cursor-pointer underline"
              onClick={() => changeUrl('/app/client/meeting_history')}>
              {this.api.trTxt(TrVar.MovedToPreviousMeetings)}
            </div>
          </div>
        }
      </div>
    )
  }
}
