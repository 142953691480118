import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {MeasureInterface} from "../../interfaces/MeasureInterface";
import LineChart from "../graphics/LineChart";
import './ClientMeasureSummary.css'
import { TrVar } from "../../services/translate"

interface ComponentState {}

interface ComponentProps {
  measures: Array<MeasureInterface>
  packageType?: number
}

export default class ClientMeasureSummary extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  graphNames = [
    this.api.trTxt(TrVar.StartState),
    this.api.trTxt(TrVar.CurrentState),
    this.api.trTxt(TrVar.DesiredState)
  ]
  areaNames = [
    this.api.trTxt(TrVar.SelfInsight),
    this.api.trTxt(TrVar.BasicEnergy),
    this.api.trTxt(TrVar.Motivation),
    this.api.trTxt(TrVar.SelfLeadership)
  ]
  graphColors = [
    "#60b9a5",
    "#5b6ef4",
    "#eaf47f",
    "#ea74ef",
  ]
  firstMeasureDict: any = {}

  getLargestGapQuestion = () => {
    const lastMeasure = this.props.measures[0] as MeasureInterface
    let largestGapQuestion: any = null
    let largestGap = -100
    for (let areaNr = 0; areaNr < 4; areaNr++) {
      let powerQuestionNr = -1
      const area = lastMeasure.areas[areaNr]
      for (let question of area.questions) {
        const gap = question.valueDesired - question.valueCurrent
        let thisPowerQuestionNr = -1
        if (gap > 10) {
          powerQuestionNr++
          thisPowerQuestionNr = powerQuestionNr
        }
        if (gap > largestGap) {
          largestGap = gap
          question.areaNr = areaNr
          question.powerQuestionNr = thisPowerQuestionNr
          largestGapQuestion = question
        }
      }
    }
    return largestGapQuestion
  }

  contactEmployer = () => {
    if (this.props.packageType === 9) {
      return (
        <div style={{ background: 'linear-gradient(159deg, rgba(188,128,240,1) 0%, rgba(255,204,200,1) 100%)' }} className="top_margin page_section">
          <div className="flex section_direction">
            <div className="r-half-col flex flex-col justify-between">
              <div>
                <h2>{this.api.trTxt(TrVar.CouldWeContactYourBoss)}</h2>
                <div style={{ fontSize: '20px' }} className="top_margin">
                  {this.api.trTxt(TrVar.WeWantToTipThemAboutCoaching)}
                </div>                
              </div>
              <div>
                <div onClick={() => changeUrl('/app/client/request_tip')} className="button green inline_block r_top_margin">{this.api.trTxt(TrVar.ReadMore)}</div>
              </div>
            </div>
            <div className="r-half-col r_top_margin">
              <img height={228} src="/assets/illustration/signout.svg" alt="man in a door" />
            </div>            
          </div>
        </div>        
      )
    }
    
    if (this.props.packageType === 8) {
      return (
        <div className="top_margin">
          <div className="flex section_direction flex-wrap">
            <div style={{ backgroundColor: '#94ABFF' }} className="r-half-col page_section min_width half">
              <div className="flex section_direction">
                <div className='flex flex-col justify-between'>
                  <div>
                    <h4>{this.api.trTxt(TrVar.TakeTheShortcutToFullPotential)}</h4>
                    <h2>
                      {this.api.trTxt(TrVar.ReachYourPotentialFasterWithACoach)}
                    </h2>                      
                  </div>
                  <div>
                    <div onClick={() => changeUrl('/app/client/request_contact')} className="top_margin button green inline_block">{this.api.trTxt(TrVar.ReadMore)}</div>
                  </div>
                </div>
                <img className='r_left_margin r_top_margin' src="/assets/illustration/matching.svg" width={230} alt="matching between two people" />
              </div>
            </div>
            <div style={{ backgroundColor: '#BC80F0' }} className="page_section min_width half r-half-col client_dashboard_second_box">
              <div className="flex section_direction">
                <div className='flex flex-col justify-between'>
                  <div>
                    <h4>{this.api.trTxt(TrVar.LiftCoachingWithYourBoss)}</h4>
                    <h2>
                      {this.api.trTxt(TrVar.RaiseYourselfToNewHeights)}
                    </h2>   
                  </div>
                  <div>
                    <div onClick={() => changeUrl('/app/client/request_tip')} className="top_margin button green inline_block">{this.api.trTxt(TrVar.ReadMore)}</div>
                  </div>
                </div>
                <img className='r_left_margin r_top_margin' src="/assets/illustration/rotation.svg" width={230} alt="matching between two people" />
              </div>
            </div>            
          </div>
        </div>        
      )
    }
  }

  topChange = (positiveChange: boolean) => {
    const measureNow = this.props.measures[0]

    const questions = []
    let changes = 0
    for (let areaNr = 0; areaNr < 4; areaNr++) {
      let devQuestion = -1
      const areaNow = measureNow.areas[areaNr]
      for (let questionNr = 0; questionNr < areaNow.questions.length; questionNr++) {
        const questionNow = areaNow.questions[questionNr]
        const questionStart = this.firstMeasureDict[questionNow.id]
        let thisDevQuestion = -1
        if (questionStart) {
          const change = questionNow.valueCurrent - questionStart.valueCurrent
          if ((questionNow.valueDesired - questionNow.valueCurrent) > 10) {
            devQuestion++;
            thisDevQuestion = devQuestion
          }
          if (change !== 0) {
            questions.push({
              id: questionNow.id,
              areaNr: areaNr,
              question: questionNow.question,
              valueStart: questionStart.valueCurrent,
              valueNow: questionNow.valueCurrent,
              desiredNow: questionNow.valueDesired,
              devQuestion: thisDevQuestion,
              change: change
            })
            changes++
          }
        }
      }
    }

    if (changes < 1) {
      return (
        <p className="top_margin">N/A</p>
      )
    }

    if (positiveChange) {
      questions.sort(function(a, b) {return b.change - a.change})
    } else {
      questions.sort(function(a, b) {return a.change - b.change})
    }

    let maxQuestions = 3
    if (maxQuestions > changes) {
      maxQuestions = changes
    }

    const showQuestions = []
    for (let i = 0; i < maxQuestions; i++) {
      if (positiveChange) {
        if (questions[i].change < 0) {
          continue
        }
      } else {
        if (questions[i].change > 0) {
          continue
        }
      }
      showQuestions.push(questions[i])
    }

    return (
      <div>
        {
          showQuestions.map((question, i) => {
            const values = [question.valueStart, question.valueNow, question.desiredNow]
            const color = this.graphColors[question.areaNr]
            const areaName = this.areaNames[question.areaNr]
            const border = "1px solid " + color

            return (
              <div key={"improvement_" + question.id} className={"r_top_margin col-1_3 min-w-360 " + (i > 0 ? "r_left_margin" : "")}>
                <div className="top_margin" style={{minHeight: "42px"}}>
                  {question.question}
                </div>
                <div className="top_margin inline_block uppercase font_smaller px-4 py-2"
                     style={{border: border, color: color, borderRadius: "12px"}}>{areaName}</div>
                <div className="top_margin"/>
                <LineChart isArea={false} valueArr={[values]} names={this.graphNames} colors={[color]}  width={200}/>
              </div>
            )
          })
        }
      </div>
    )
  }

  topPotential = (positive: boolean) => {
    const measureNow = this.props.measures[0]

    const questions = []
    for (let areaNr = 0; areaNr < 4; areaNr++) {
      const areaNow = measureNow.areas[areaNr]
      let devQuestion = -1
      for (let questionNr = 0; questionNr < areaNow.questions.length; questionNr++) {
        const questionNow = areaNow.questions[questionNr]
        const questionStart = this.firstMeasureDict[questionNow.id]
        let thisDevQuestion = -1
        if ((questionNow.valueDesired - questionNow.valueCurrent) > 10) {
          devQuestion++;
          thisDevQuestion = devQuestion
        }
        if (questionNow) {
          questions.push({
            id: questionNow.id,
            areaNr: areaNr,
            question: questionNow.question,
            valueStart: questionStart ? questionStart.valueCurrent : null,
            valueNow: questionNow.valueCurrent,
            desiredNow: questionNow.valueDesired,
            devQuestion: thisDevQuestion
          })
        }
      }
    }

    if (positive) {
      questions.sort(function(a, b) {return b.valueNow - a.valueNow})
    } else {
      questions.sort(function(a, b) {return a.valueNow - b.valueNow})
    }

    let maxQuestions = 3

    const showQuestions = []
    for (let i = 0; i < maxQuestions; i++) {
      showQuestions.push(questions[i])
    }

    return (
      <div>
        {
          showQuestions.map((question, i) => {
            let values: Array<number>
            let graphNames: Array<string>
            if (question.valueStart) {
              values = [question.valueStart, question.valueNow, question.desiredNow]
              graphNames = this.graphNames
            } else {
              values = [question.valueNow, question.desiredNow]
              graphNames = [this.graphNames[1], this.graphNames[2]]
            }
            const color = this.graphColors[question.areaNr]
            const areaName = this.areaNames[question.areaNr]
            const border = "1px solid " + color
            return (
              <div key={"improvement_" + question.id} className={"r_top_margin col-1_3 min-w-360 " + (i > 0 ? "r_left_margin" : "")}>
                <div style={{minHeight: "42px"}}>
                  {question.question}
                </div>
                <div className="top_margin inline_block uppercase font_smaller px-4 py-2"
                     style={{border: border, color: color, borderRadius: "12px"}}>{areaName}</div>
                <div className="top_margin"/>
                <LineChart isArea={false} valueArr={[values]} names={graphNames} colors={[color]} width={200}/>
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    if (this.props.measures.length === 0) {
      return (
        <div className="page_section">
          <h3>Not available</h3>
          <p className="top_margin">First measure is not completed yet</p>
        </div>
      )
    }
    if (this.props.measures.length > 1) {
      const measureStart = this.props.measures[this.props.measures.length - 1]
      for (let areaNr = 0; areaNr < 4; areaNr++) {
        const areaStart = measureStart.areas[areaNr]
        for (let question of areaStart.questions) {
          this.firstMeasureDict[question.id] = question
        }
      }
    }

    return (
      <>
        {this.contactEmployer()}
        {
          this.props.measures.length > 1 &&
            <div className="top_margin page_section">
              <h2>
                {this.api.trTxt(TrVar.LargestImprovement)}
              </h2>
              {this.topChange(true)}
            </div>
        }

        <div className="top_margin page_section">
          <h2>
            {this.api.trTxt(TrVar.LargestUnusedPotential)}
          </h2>
          {this.topPotential(false)}
        </div>

        <div className="top_margin page_section">
          <h2>
            {this.api.trTxt(TrVar.LargestReachedPotential)}
          </h2>
          {this.topPotential(true)}
        </div>

      </>
    )
  }
}
