import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import BehaviouralQuestions from '../../components/testing/BehaviouralQuestions';
import Disa from "../../components/testing/Disa";
import Analysis from "../../components/testing/Analysis";


interface ComponentProps {}

interface ComponentState {
  clientDisa: Array<number>,
  coachDisa: Array<number>,
}

class DisaTest extends React.Component<ComponentProps, ComponentState> {

  constructor(props: ComponentProps) {
    super(props);
    const defaultDisa = [0, 0, 0, 0];
    this.state = {
      clientDisa: defaultDisa,
      coachDisa: defaultDisa,
    }
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <div style={{display: "flex", width: "100vw"}}>
              <BehaviouralQuestions name="Klient" update={(disa: Array<number>) => this.setState({clientDisa: disa})}/>
              <div className="ml-4">
                <Disa disa={this.state.clientDisa}/>
                <Analysis disa={this.state.clientDisa}/>
              </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

}

export default DisaTest;
