import React from 'react'
import {CoachInterface} from "../../interfaces/Coach";
import Api from "../../services/Api";
import ShowSelectCoach from "./ShowSelectCoach";
import { changeUrl } from "../../services/Navigation";
import { TrVar } from "../../services/translate"
import Text from '../Text/Text';

interface ComponentProps{
  save: Function
  onboarding?: boolean
  progress?: Function
  selectCoach?: Function
  chatCoach?: boolean
  chooseCoach?: Function
}

interface ComponentState {
  coaches: Array<CoachInterface>
  previousCoaches: Array<CoachInterface>
  trainer: boolean
  missingSettings: boolean
  selectedCoach: CoachInterface
}

export default class EditCoach extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    coaches: [] as Array<CoachInterface>,
    previousCoaches: [] as Array<CoachInterface>,
    trainer: false,
    missingSettings: true,
    selectedCoach: {} as CoachInterface,
  }

  componentDidMount() {
    let url = 'coaches_matching'
    if (this.props.chatCoach) {
      url += '?only_chat=1'
    }
    this.api.get(url).then(response => {
      this.setState({
        coaches: response.json.coaches,
        previousCoaches: response.json.previousCoaches,
        trainer: response.json.trainer,
        missingSettings: response.json.missingSettings
      })
    })
  }

  selectCoach = (coach: CoachInterface) => {
    this.api.post('coach_select/' + coach.id, {}).then(_response => {
      if (this.props.onboarding && this.props.chooseCoach) { 
        this.props.save(3)
        this.setState({ selectedCoach: coach })
        this.props.chooseCoach(coach)
      } else {
        this.props.save()
      }
      if (this.props.selectCoach) {
        this.props.selectCoach(coach)
      }
    })
  }

  selectMessage = () => {
    if (this.state.missingSettings) {
      return (
        <>
          <p>{this.api.trTxt(TrVar.DevelopmentAreasBeforeCoach)}</p>
          <div className="button top_margin inline_block"
               onClick={() => changeUrl('/app/client/development_areas?next=/app/client/editCoach')}>
            {this.api.trTxt(TrVar.Select)} {this.api.trTxt(TrVar.DevelopmentAreas).toLowerCase()}
          </div>
        </>
      )
    } else if (this.state.trainer) {
      return (
        <>
          <p>{this.api.trTxt(TrVar.YourLicenseIsConnected)}</p>
        </>
      )
    } else {
      return (
        <p>
          {this.api.trTxt(TrVar.MatchingSortedCoaches)}
        </p>
      )
    }
  }

  render() {
    return (
      <div className={this.props.onboarding ? "" : "page_content"}>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.SelectCoach)}</h1>
          <div className="top_margin">
            {
              this.selectMessage()
            }
          </div>
        </div>

        <div>
          {
            this.state.coaches.map((coach: CoachInterface, index) => {
              return (
                <div className={"page_section top_margin"} key={'select_coach_' + index}>
                  <ShowSelectCoach coach={coach} selectCoach={this.selectCoach} description={coach.languageDescription} />
                </div>
              )
            })
          }
        </div>

        {
          this.state.previousCoaches.length > 0 &&
            <>
              <div className="page_section top_margin">
                  <h2>{this.api.trTxt(TrVar.YourPreviousCoaches)}</h2>
              </div>
              {
                this.state.previousCoaches.map((coach: CoachInterface, index) => {
                  return (
                    <div className={"page_section top_margin"} key={'select_prev_coach_' + index}>
                      <ShowSelectCoach coach={coach} selectCoach={this.selectCoach} description={coach.languageDescription} />
                    </div>
                  )
                })
              }
            </>
        }
      </div>
    )
  }
}
