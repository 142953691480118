import {
  IonContent, IonPage,
} from '@ionic/react';
import React from 'react';
import {Link} from "react-router-dom";
import {changeUrl} from "../../services/Navigation";

interface ComponentProps {
  inPlatform?: boolean
}

interface ComponentState {
  accessStatus: number
  showChrome: boolean
  showFirefox: boolean
  showSafari: boolean
}

export default class CameraAccess extends React.Component<ComponentProps, ComponentState> {

  state = {
    accessStatus: 0,
    showChrome: false,
    showFirefox: false,
    showSafari: false,
  }

  componentDidMount() {
    this.testAccess()
  }

  testAccess = () => {
    this.setState({accessStatus: 1})
    const constraints = {
      video: true,
      audio: true
    }
    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
      this.setState({accessStatus: 2})
    }).catch(err => {
      // alert('Fatal error: Could not connect to your camera')
      this.setState({accessStatus: 3})
      console.log(err)
    })
  }

  showAccessStatus = () => {
    let txt = 'Status: waiting for you to start test'
    if (this.state.accessStatus === 1) {
      txt = 'Status: waiting for test to complete...'
    } else if (this.state.accessStatus === 2) {
      txt = 'It works! You have access to the camera.'
    } else if (this.state.accessStatus === 3) {
      txt = 'Access denied. Follow instructions for your browser below.'
    }
    return (
      <div className="bg_dark_blue py-2 px-4 r_left_margin r_top_margin">
        {txt}
      </div>
    )
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="max_w_desktop flex-1 px-8 mt-8 color_white">
            <h2>Camera access test</h2>
            <div className="mt-4 r-flex items-center">
              <div className="button inline_block"onClick={() => this.testAccess()}>Test camera access</div>
              {this.showAccessStatus()}
            </div>

            {
              this.state.accessStatus === 2 &&
              <div className="mt-8">
                <div className="mt-4">
                  {
                    this.props.inPlatform ? (
                      <p className="mt-4 underline cursor-pointer" onClick={() => changeUrl('/app/help/camera_test')}>
                        Test your microphone, volume and camera
                      </p>
                    ) : (
                      <Link to='/app/help/camera_test' className="underline">
                        Test your microphone, volume and camera
                      </Link>
                    )
                  }
                </div>
              </div>
            }


            {
              this.state.accessStatus === 3 &&
                <>
                  <h2 className="mt-8">Access denied</h2>
                  <p className="mt-8">
                      There are three common reasons your camera access denied. If none of the attempts below solve the
                      problem, please contact us at <a className="underline"
                                                       href="mailto:support@zebrain.se">support@zebrain.se</a>.
                  </p>

                  <h4 className="mt-8">1. Browser settings deny camera access</h4>
                  <div className="mt-4">
                      First time you open a Zebrain video meeting, the browser will ask permission to use your
                      camera, for example:
                      <div className="r-flex">
                          <div className="text_center">
                              <img alt="Ask access" className="mt-2" src="/assets/images/help/access_camera_chrome.png" />
                          </div>
                      </div>
                  </div>
                  <p className="mt-4">
                      If you accidentally clicked "Block" in dialogue above, your video meeting will not work.
                  </p>

                  <p className="mt-4">
                      To make sure that your browser is allowed access to the camera, follow the instructions for your browser
                      below:
                  </p>

                  <div className="bg_dark_blue mt-4 py-2 px-4">
                    <div className="underline cursor-pointer"
                         onClick={() => this.setState({showChrome: !this.state.showChrome})}>
                      <b>Chrome</b>
                    </div>
                    {
                      this.state.showChrome &&
                      <div className="mt-4">
                        <p>1. Click the pad lock to the left of the url-window:</p>
                        <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_chrome.png" />
                        <p className="mt-4">2. Choose <b>Allow</b> for Camera and Microphone</p>
                        <p className="mt-4">3. Click Test camera access again to verify that it works.</p>
                        <p className="mt-4">4. Go back to video meeting and try again.</p>
                      </div>
                    }
                  </div>

                  <div className="bg_dark_blue mt-2 py-2 px-4">
                    <div className="underline cursor-pointer"
                         onClick={() => this.setState({showSafari: !this.state.showSafari})}>
                        <b>Safari</b>
                    </div>
                  {
                    this.state.showSafari &&
                    <div className="mt-4">
                        <p>1. Open Safari Preferences:</p>
                        <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_safari.png" />
                        <p className="mt-4">2. Navigate to Websites</p>
                        <p className="mt-4">3. Go to Camera and Microphone tabs</p>
                        <p className="mt-4">4. Change access to Allow</p>
                        <p className="mt-4">5. Reload page and allow access to camera and microphone</p>
                        <p className="mt-4">6. Click Test camera access again to verify that it works.</p>
                        <p className="mt-4">7. Go back to video meeting and try again.</p>
                    </div>
                  }
                  </div>

                  <div className="bg_dark_blue mt-2 py-2 px-4">
                    <div className="underline cursor-pointer"
                         onClick={() => this.setState({showFirefox: !this.state.showFirefox})}>
                        <b>Firefox</b>
                    </div>
                    {
                      this.state.showFirefox &&
                      <div className="mt-4">
                          <p>1. Click the camera icon to the left of the url-window:</p>
                          <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_firefox.png" />
                          <p className="mt-4">2. Click to remove the Blocked resources</p>
                          <p className="mt-4">3. Reload page and allow access to camera and microphone</p>
                          <p className="mt-4">4. Click Test camera access again to verify that it works.</p>
                          <p className="mt-4">5. Go back to video meeting and try again.</p>
                      </div>
                    }
                  </div>
                  <h4 className="mt-8">2. Camera is used by other application</h4>
                  <p className="mt-4">
                    Check that there are no other applications using the camera. If another application is using the camera,
                    this application cannot access the camera.
                  </p>

                  <h4 className="mt-8">3. Camera is not plugged in</h4>
                  <p className="mt-4">
                    If you have a lap top, this should never happen. But if you have a desktop computer, make sure that the
                    camera is plugged in to your computer.
                  </p>
                </>
            }
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
