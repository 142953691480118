import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import ExternalLogging from "../../services/ExternalLogging";


interface ComponentProps {}

interface ComponentState {
  status: string
  turnServer: string
  useUdp: boolean
  useTcp: boolean
  iceCandidates: number
}

export default class TestTurn extends React.Component<ComponentProps, ComponentState> {

  private externalLogging = ExternalLogging.getInstance()

  state = {
    status: 'Waiting to start',
    turnServer: 'turn.zebrain.se',
    useUdp: true,
    useTcp: true,
    iceCandidates: 0
  }

  checkTurn = () => {

    const turnConfig:any = []

    if (this.state.useUdp) {
      let url = 'turn:' + this.state.turnServer + ':443'
      turnConfig.push({
        url: url,
        username: 'hakan',
        credential: 'darkness'
      })
    }

    if (this.state.useTcp) {
      let url = 'turn:' + this.state.turnServer + ':443?transport=tcp'
      turnConfig.push({
        url: url,
        username: 'hakan',
        credential: 'darkness'
      })
    }

    this.setState({iceCandidates: 0})

    let pc = new RTCPeerConnection({iceServers:turnConfig})

    pc.createDataChannel("");    //create a bogus data channel
    pc.createOffer().then((sdp: any) => {
      console.log(sdp.sdp)
      const typI = sdp.sdp.indexOf('typ relay')
      if(typI > -1){ // sometimes sdp contains the ice candidates...
        console.log('index of typ relay is ' + typI)
      }
      pc.setLocalDescription(sdp);
      this.setState({status: "Created offer"})
    })
    // create offer and set local description
    pc.addEventListener('icecandidate', (event) => {
      console.log(event)
      if (event.candidate) {
        this.setState({iceCandidates: this.state.iceCandidates + 1})
      } else {
        this.setState({status: "Received empty candidate, done negotiating"})
      }

    })

    pc.addEventListener('connectionstatechange', (event: any) => {
      console.log('-------- connectionStateChange: ' + event.target.connectionState)
      if (event.target.connectionState === 'failed') {
        this.setState({status: "Connection state change: Failed"})
      }
    })

    pc.addEventListener('error', function(event: any) {
      console.log('--------- error below')
      console.log(event)
    })

    pc.addEventListener('icecandidateerror', function(event: any) {
      console.log('--------- icecandidateerror below')
      console.log(event)
    })
  }

  handleServer = (e: any) => {
    this.setState({turnServer: e.target.value})
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Test turn</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className="page_content">
            <div className="page_section">
              <h2 className="mb-4">Turn testing status: {this.state.status}</h2>
              <div className="mt-4">
                <p className="font_small">Turn server hostname:</p>
                <input className="page_input" type="text" name="server" value={this.state.turnServer}
                       onChange={(e) => this.handleServer(e)}/>
              </div>
              <div className="mt-4 flex" onClick={() => this.setState({useUdp: !this.state.useUdp})}>
                <div className={"radio_input " + (this.state.useUdp ? "chosen" : "")}/>
                <div className="ml-4">Use udp for Turn</div>
              </div>
              <div className="mt-4 flex" onClick={() => this.setState({useTcp: !this.state.useTcp})}>
                <div className={"radio_input " + (this.state.useTcp ? "chosen" : "")}/>
                <div className="ml-4">Use tcp for Turn</div>
              </div>
              <div className="mt-8">
                <div className="button inline_block" onClick={() => this.checkTurn()}>Start test</div>
              </div>
              <p className="mt-4">{this.state.iceCandidates} ice candidates received (check console log)</p>

              <p className="mt-4" onClick={() => this.externalLogging.enable()}>Enable external logging</p>
              <p onClick={() => this.externalLogging.checkStatus()}>Check logging status</p>
              <p onClick={() => this.externalLogging.disable()}>Disable logging</p>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

};
