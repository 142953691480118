import React from 'react';
import Api from "../../services/Api"
import { TrVar } from '../../services/translate';

interface ComponentProps  {
  progress: Function
}

const EditAreasIntro = (props: ComponentProps) => {
  const api = Api.getInstance()

  return (
    <div className='page_section'>
      <h1 className='text_center'>{api.lang === 'sv' ? 'Matcha med coach' : `Match with a coach`}</h1>
      <div style={{ maxWidth: '391px', margin: '0 auto', paddingTop: '64px' }}>
        <div className="flex justify-between">
          <div className='flex'>
            <div style={{ marginRight: '20px' }}>1</div>
            <div>{api.lang === 'sv' ? 'Beteendeanalys' : 'Behavioural analysis'}</div>
          </div>
          <div>
            <img height={22} src="/assets/icon/blue/check.svg" alt="blue checkmark" />
          </div>
        </div>

        <div className='onboarding_intro_bar' />

        <div className="flex justify-between ">
          <div className='flex'>
            <div style={{ marginRight: '16px' }}>2</div>
            <div>{api.lang === 'sv' ? 'Potentialmätning' : 'Measurement of potential'}</div>
          </div>
          <div>
            <img height={22} src="/assets/icon/blue/check.svg" alt="blue checkmark" />
          </div>
        </div>

        <div className='onboarding_intro_bar ' />

        <div className="flex justify-between ">
          <div className='flex'>
            <div style={{ marginRight: '16px' }}>3</div>
            <div>{api.lang === 'sv' ? 'Matcha med coach' : 'Match with coach'}</div>
          </div>
          <div>
            2 min
          </div>
        </div>

        <div className='onboarding_intro_bar ' />

        <div className="flex justify-between not_reached">
          <div className='flex'>
            <div style={{ marginRight: '18px' }}>4</div>
            <div>{api.lang === 'sv' ? 'Boka möte med coach' : 'Make appointmend with coach'}</div>
          </div>
          <div>
            2 min
          </div>
        </div>
            
        <div className='onboarding_intro_bar not_reached' />

      </div>

      <div style={{ marginTop: '40px' }} className="flex justify-center">
        <div onClick={() => props.progress(1)} className='button green mobile_size_btn inline_block'>
          {api.trTxt(TrVar.Next)}
        </div>
      </div>

    </div>
  )
}

export default EditAreasIntro;
