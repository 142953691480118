import React from 'react';

interface ComponentProps {
  inPlatform?: boolean
}

interface ComponentState {
  showChrome: boolean
  showFirefox: boolean
  showSafari: boolean
}

export default class AccessHelp extends React.Component<ComponentProps, ComponentState> {

  state = {
    showChrome: false,
    showFirefox: false,
    showSafari: false,
  }

  render() {
    return (
      <div className="p-4 color_white page_wider_content mx-auto">
        <h2 className="mt-4">Oops! We could not find your camera</h2>
        <h3 className="mt-8">Don't worry, it should be easy to fix.</h3>
        <p className="mt-4">
          There are three common reasons for this, listed below.
        </p>
        <p className="mt-4">
         If these instructions don't solve the problem, please contact us
          at <a className="underline" href="mailto:support@zebrain.se">support@zebrain.se</a>.
        </p>

        <h3 className="mt-8">1. Browser settings deny camera access</h3>
        <div className="mt-4">
            First time you open a Zebrain video meeting, the browser will ask permission to use your
            camera. It looks something like this:
            <div className="r-flex">
                <div className="text_center">
                    <img alt="Ask access" className="mt-2" src="/assets/images/help/access_camera_chrome.png" />
                </div>
            </div>
        </div>
        <p className="mt-4">
            If you accidentally clicked "Block" in dialogue above, your video meeting will not work.
        </p>

        <p className="mt-4">
            To make sure that your browser is allowed access to the camera, follow the instructions for your browser
            below:
        </p>

        <div className="bg_dark_blue mt-4 py-2 px-4">
          <div className="underline cursor-pointer"
               onClick={() => this.setState({showChrome: !this.state.showChrome})}>
            <b>Chrome</b>
          </div>
          {
            this.state.showChrome &&
            <div className="mt-4">
              <p>1. Click the pad lock to the left of the url-window:</p>
              <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_chrome.png" />
              <p className="mt-4">2. Choose <b>Allow</b> for Camera and Microphone</p>
              <p className="mt-4">3. Reload page.</p>
            </div>
          }
        </div>

        <div className="bg_dark_blue mt-2 py-2 px-4">
          <div className="underline cursor-pointer"
               onClick={() => this.setState({showSafari: !this.state.showSafari})}>
              <b>Safari</b>
          </div>
        {
          this.state.showSafari &&
          <div className="mt-4">
              <p>1. Open Safari Preferences:</p>
              <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_safari.png" />
              <p className="mt-4">2. Navigate to Websites</p>
              <p className="mt-4">3. Go to Camera and Microphone tabs</p>
              <p className="mt-4">4. Change access to Allow</p>
              <p className="mt-4">5. Reload page and allow access to camera and microphone</p>
          </div>
        }
        </div>

        <div className="bg_dark_blue mt-2 py-2 px-4">
          <div className="underline cursor-pointer"
               onClick={() => this.setState({showFirefox: !this.state.showFirefox})}>
              <b>Firefox</b>
          </div>
          {
            this.state.showFirefox &&
            <div className="mt-4">
                <p>1. Click the camera icon to the left of the url-window:</p>
                <img alt="Ask access" className="mt-2" src="/assets/images/help/allow_firefox.png" />
                <p className="mt-4">2. Click to remove the Blocked resources</p>
                <p className="mt-4">3. Reload page and allow access to camera and microphone</p>
            </div>
          }
        </div>
        <h3 className="mt-8">2. Camera is used by other application</h3>
        <p className="mt-4">
          Check that there are no other applications using the camera. If another application is using the camera,
          this application cannot access the camera.
        </p>

        <h3 className="mt-8">3. Camera is not plugged in</h3>
        <p className="mt-4">
          If you have a lap top, this should never happen. But if you have a desktop computer, make sure that the
          camera is plugged in to your computer.
        </p>
      </div>
    );
  }
}
