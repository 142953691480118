import React, { useState, useEffect } from 'react'
import EditAbout from '../edit/EditAbout'
import EditAboutDone from '../OnboardingSteps/EditAboutDone'
import Api from "../../services/Api"
import { TrVar } from '../../services/translate'

interface ComponentProps {
  save: Function
  scrollTop: Function
  licenseCode: string
  goTo: number
  progress: Function
  who: string
  missingInfo: Function
}


const AboutOnboarding = (props: ComponentProps) =>  {
  const api = Api.getInstance()
  const [firstName, setFirstName] = useState('')
  
  const changeName = (name: string) => {
    setFirstName(name)
  }

  useEffect(() => {
    const api = Api.getInstance()
    api.get('client').then(response => {
      if (response.json.client.missingFields.length === 0) {
        props.missingInfo(false)
      }
    })
  // eslint-disable-next-line
  }, [])

  if (props.goTo === 1) {
    return (
      <div className="page_section ">
        <EditAboutDone firstName={firstName} save={props.save} />
      </div>
    )
  }

  return (
    <div className="page_section">
      <EditAbout setName={changeName} save={props.save} who="client"
        licenseCode={props.licenseCode} progress={props.progress}
        saveText={api.trTxt(TrVar.Save)} />
    </div>
  )   
}

export default AboutOnboarding;
