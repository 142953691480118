import React from "react"
import {changeUrl} from "../../services/Navigation";
import "./Breadcrumbs.css"

interface BreadcrumbInterface {
  name: string
  link: string
  linkFunc?: Function
}

interface ComponentProps {
  breadcrumbs?: Array<BreadcrumbInterface>
  name: string
  backLink?: boolean
}

interface ComponentState {}

export default class Breadcrumbs extends React.Component<ComponentProps, ComponentState> {

  showBack = () => {
    if (this.props.breadcrumbs && this.props.breadcrumbs.length > 0) {
      const breadcrumb = this.props.breadcrumbs[this.props.breadcrumbs.length - 1]
      return (
        <div className="breadcrumbsBack" onClick={() => this.followLink(breadcrumb)}>
          <img alt="back" src="/assets/icon/white/chevron-back-outline.svg"/>
        </div>
      )
    }
    return (
      <></>
    )
  }

  followLink = (breadcrumb: BreadcrumbInterface) => {
    if (breadcrumb.linkFunc) {
      breadcrumb.linkFunc()
    } else {
      changeUrl(breadcrumb.link)
    }
  }

  render() {

    if (this.props.backLink && this.props.breadcrumbs) {
      const link = this.props.breadcrumbs[0].link
      const name = this.props.breadcrumbs[0].name
      return (
        <div className="breadcrumbsMain">
          <div className="flex ml-4 cursor-pointer items-center" onClick={() => changeUrl(link)}>
            <img alt="back" className="icon_medium" src="/assets/icon/white/chevron-back-outline.svg"/>
            <p>{name}</p>
          </div>
        </div>
      )
    }

    return (
      <div className="breadcrumbsMain">
        <div className="breadcrumbsSmall">
          {this.showBack()}
          <div className="breadcrumbsName">
            {this.props.name}
          </div>
        </div>
        <div className="breadcrumbsLarge">
          {
            this.props.breadcrumbs?.map((breadcrumb, index) => {
              return (
                <div className="breadcrumbsLink" key={'br_l_' + index} onClick={() => this.followLink(breadcrumb)}>
                  {breadcrumb.name} &gt;&nbsp;
                </div>
              )
            })
          }
          <div className="breadcrumbsName">
            {this.props.name}
          </div>
        </div>
      </div>
    )
  }
}
