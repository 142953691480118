import React from 'react'

import Calendar from "../calendar/Calendar";
import Api from "../../services/Api";
import {dateFormatDate} from "../../helpers/dateFunctions";

interface ComponentProps {
  save: Function
  close: Function
}

interface ComponentState {}

export default class SelectFollowUpDate extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  firstDate: Date = new Date()
  lastDate: Date = new Date()

  constructor(props: ComponentProps) {
    super(props);
    this.firstDate.setHours(0)
    this.lastDate.setDate(this.firstDate.getDate() + 14)
    this.lastDate.setHours(23)
    this.lastDate.setMinutes(59)
  }

  clickDate = (d: Date) => {
    if (d < this.firstDate) {
      const msg = this.api.lang === 'sv' ?
        'Du kan inte välja en dag som passerat'
        :
        'You cannot choose a day that has passed'
      alert(msg)
      return
    } else if (d > this.lastDate) {
      const msg = this.api.lang === 'sv' ?
        'Välj en uppföljning senast ' + dateFormatDate(this.lastDate, false, this.api.lang)
        :
        'Please select follow up latest ' + dateFormatDate(this.lastDate, false, this.api.lang)
      alert(msg)
      return
    }
    this.props.save(d)
  }

  clickEvent = (e: any) => {
    console.log(e)
  }

  render() {

    let paddingBottom = 216
    let subtractHeight = window.innerWidth < 900 ? 148 : 90
    const pageHeight = "calc(100vh - " + subtractHeight + "px)"

    return (
      <div className="page_section" style={{ height: pageHeight, paddingBottom: paddingBottom + "px" }}>
        <h3 className="white text_center ml-4">
          Välj dag för uppföljning, senast {dateFormatDate(this.lastDate, false, this.api.lang)}
        </h3>
        <Calendar events={[]} clickDate={this.clickDate} clickEvent={this.clickEvent} boxHeight={54}
                  firstDate={this.firstDate} lastDate={this.lastDate} lockPeriod='month'/>
      </div>
    )
  }
}
