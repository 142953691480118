import React, { useState, useEffect } from 'react';
import Api from "../../services/Api";
import Breadcrumbs from "../menu/Breadcrumbs";
import './DetailedBehaviorAnalysis.css';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  who: string
}

const DetailedBehaviorAnalysis = (props: ComponentProps) => {
  const [isCoach, setIsCoach] = useState(true);

  const api = Api.getInstance()

  useEffect(() => {
    if (props.who === 'coach') {
      setIsCoach(true)
    } else {
      setIsCoach(false)    
    }
  }, [props.who]);
 
  return (
    <>
      {isCoach ?
        (<Breadcrumbs breadcrumbs={[{ name: api.trTxt(TrVar.MyAccount), link: '/app/coach/my_account' },
        { name: api.trTxt(TrVar.YourBehaviourProfile), link: '/app/coach/behaviour/view' }]}
          name={api.trTxt(TrVar.DetailedBehaviour)} />)
        : 
          (<Breadcrumbs breadcrumbs={[{ name: api.trTxt(TrVar.YourBehaviourProfile), link: '/app/client/behaviour/view' }]}
            name={api.trTxt(TrVar.DetailedBehaviour)} />)
          }
      <div className='page_content'>
        <div className="page_section">
          <h1>{api.trTxt(TrVar.AboutBehaviourAlgorithm)}</h1>
          <div className="page_bar" />
          <div className="flex section_direction">
            <div className="page_half_content">
              <h3>{api.trTxt(TrVar.AboutBehaviourAlgorithm)}</h3>
              <p className="top_margin">
                {api.trTxt(TrVar.ThereAreNoConnectionsBetweenOurPersonalitiesOrOurDifferentBehaviouralPatterns)}
              </p>
              <p className="top_margin">
                {api.trTxt(TrVar.OurMatchingAlgorithmIsBasedOnBigFive)}
              </p>
              <p className="top_margin">
                {api.trTxt(TrVar.OurMatchingAlgorithmIsBasedOnBigFive2)}
              </p>
            </div>
            <div className="page_half_content r_left_margin">
              <img className='hide_medium' src="/assets/illustration/behavior_analysis.svg" alt="" />
            </div>
          </div>
        </div>

        <div className="page_section flex section_direction top_margin">
          <div className="page_half_content">
            <h3>{api.trTxt(TrVar.ResearchBehind)}</h3>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.BigFive)}.</h4>
            <p>{api.trTxt(TrVar.WorldsMostWidelyUsedResearchBasedPersonalityModels)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.RevisedNEOPersonality)}</h4>
            <p>{api.trTxt(TrVar.FurtherDevelopmentOfBigFive)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.HexacoModel)}</h4>
            <p>{api.trTxt(TrVar.BigFiveWithASixthFactor)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.SocialStyles)}</h4>
            <p>{api.trTxt(TrVar.ShowsAmongOtherThingsTheImportanceOfAdapting)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.IDIInterpersonalDynamicInventory)}</h4>
            <p>{api.trTxt(TrVar.FurtherDevelopmentOfSocialStyles)}</p>
          </div>
          <div className="page_half_content bottom_margin r_top_margin r_left_margin">
            <h4 className="color_cerise">{api.trTxt(TrVar.STCSystemsCenteredTherapy)}</h4>
            <p>{api.trTxt(TrVar.YvonneAgazarinsModel)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.SCMStereotypeContentModel)}</h4>
            <p>{api.trTxt(TrVar.HowWeJudgeOthers)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.HelenFisher)}</h4>
            <p>{api.trTxt(TrVar.HelenFishersResearchTogetherWithBrainResearcherLucyLBrown)}</p>
            <h4 className="top_margin color_cerise">{api.trTxt(TrVar.GiacomoRizzolottis)}</h4>
            <p>{api.trTxt(TrVar.GiacomoRizzolottisResearchAboutMirrorNeurons)}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailedBehaviorAnalysis;
