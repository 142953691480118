import React from 'react'
import CalendarClient from '../client/CalendarClient'
import { ClientLicenseInterface } from '../../interfaces/ClientLicense'
import { CoachInterface } from '../../interfaces/Coach'
import { LicenseInterface } from '../../interfaces/License'
import CalendarClientIntro from '../OnboardingSteps/CalendarClientIntro'
import CalendarClientOverview from '../OnboardingSteps/CalendarClientOverview'
import OnboardingOutro from '../OnboardingSteps/Outro'

interface ComponentProps {
  progress: Function
  scrollToTop?: Function
  coach?: CoachInterface
  clientLicense?: ClientLicenseInterface
  license?: LicenseInterface
  finishSection: Function
  close: Function
  goTo: number
  onBoarding: boolean
  goToDev: Function
  goToChat: Function
}

const CalendarOnboarding = (props: ComponentProps) => {

  if (props.goTo === 0) {
    return (
      <CalendarClientOverview progress={props.progress} coach={props.coach} />
    )
  } else if (props.goTo === 1) {
    return (
      <CalendarClientIntro progress={props.progress} license={props.license} clientLicense={props.clientLicense} />
    )
  } else if (props.goTo === 2) {
    return (
      <CalendarClient goToChat={props.goToChat} finishSection={props.finishSection} progress={props.progress} close={props.close} onBoarding={props.onBoarding} />
    )    
  } else {
    return (
      <OnboardingOutro save={props.close} goToDev={props.goToDev} />
    )
  }
}

export default CalendarOnboarding;
