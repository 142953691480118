import React from 'react';
import Api from '../../services/Api';
import Text from '../Text/Text'

interface ComponentProps {
  progress: Function
}

const BehaviourIntro = (props: ComponentProps) => {
  const api = Api.getInstance();

  return (
    <div className='page_section'>
      <h1>{api.lang === 'sv' ? 'Beteendeanalys' : 'Behavioural analysis'}</h1>
      <div className='page_bar' />
      <div className="flex max-sm:flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
        <div className="w-1/2">
          <div style={{ maxWidth: '478px' }}>
            {api.lang === 'sv' ?
              <>
                <h3>Analysen tar fram din beteendeprofil och innehåller:</h3>
                <ul className="color_neutral_400 list-disc pt-2 translate-x-6" style={{ marginLeft: '-10px' }}>
                  <li>Dina beteendestilar</li>
                  <li>Dina egenskaper och karaktärsdrag</li>
                  <li>Dina styrkor</li>
                </ul>
                <Text element="div" color="gray" className="top_margin text-neutral-900">
                  Svara utifrån hur du brukar vara. Ditt spontana svar
                  är vanligen mest korrekt.
                </Text>
                <div className="top_margin hide_small">
                  <div onClick={() => props.progress(2)} className="top_margin button inline_block green">
                    Gör beteendeanalys
                  </div>
                </div> 
              </>
              :
              <>
                <h3>The analysis will calculate your behavioural analysis and contains:</h3>
                <ul className="pl-12 list-disc pt-2" style={{ marginLeft: '-10px' }}>
                  <li>Your behavioural styles</li>
                  <li>Your features and characteristics</li>
                  <li>Your strengths</li>
                </ul>
                <Text element="div" color="gray" className="top_margin text-neutral-900">
                  Answer based on instinct, the less you think about the answer usually gives
                  the most correct result.
                </Text>
                <div className="top_margin hide_small" >
                  <div onClick={() => props.progress(2)} className="top_margin button inline_block green">
                    Start behavioural analysis
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className="w-1/2 r_top_margin">
          <img className='onboarding_img_mobile' src="/assets/illustration/behavior_analysis.svg" alt="girl with magnifying glass" />
        </div>
      </div>
      <div className="top_margin show_small">
        <div onClick={() => props.progress(2)} className="top_margin mobile_size_btn button inline_block green">
          Gör beteendeanalys
        </div>
      </div>
    </div>
  )
}

export default BehaviourIntro;
