export interface ActionInterface {
    id: number
    measureTaskId: number
    notes: string
    status: number
    changed: boolean
}

export interface TaskInterface {
    id: number,
    nr: number,
    questionId: number,
    questionText: string,
    subQuestions: Array<string>
    action: string
    info: string
    userAction: ActionInterface
    viewOpen: boolean
}

export interface QuestionInterface {
    id: number
    question: string
    valueCurrent: number
    valueStart: number
    valueDesired: number
    largeGap: boolean
    tasks: Array<TaskInterface>
}

export interface CoachQuestionInterface {
    id: number
    name: string
    question: string
    answer: string
    done: boolean
    createdAt?: string
    updatedAt?: string
}

export interface PotentialQuestionInterface {
    importance: number
    coachQuestions: Array<CoachQuestionInterface>
    question: QuestionInterface
    loop: boolean
}

export interface FocusAreaInterface {
    name: string
    focusArea: string
    score: number
    potentialQuestions: Array<PotentialQuestionInterface>
}

export interface FocusAreaSettingsInterface {
    areaName: string
    lengthInWeeks: number
    createdAt: string
}

export interface FocusMeasureInterface {
    measureId: number
    questions: QuestionInterface,
    date: string,
    focusAreas: Array<FocusAreaInterface>
}

export enum PlanStatusType {
    NONE,
    YES,
    SORT_OF,
    NO,
}

export enum PlanNotDoneType {
    NONE,
    FORGOT,
    TOO_HARD,
    NOT_RELEVANT = 3,
    NO_TIME = 4,
    OTHER = 5
}

export interface FocusPlanInterface {
    id: number
    coachQuestionId: number
    plan: string
    when: string
    followUp: string
    reminderAt: string
    planCompleted: boolean
    done: PlanStatusType
    notDoneReason: PlanNotDoneType
    feedback: string
    feedbackOther: string
    createdAt: string
    updatedAt: string
    followupCompleted: boolean
}
