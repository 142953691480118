import React from 'react'
import Api from "../../services/Api"
import {DisaInterface} from "../../interfaces/Disa"

import Breadcrumbs from "../menu/Breadcrumbs";
import DisaRomb from "../graphics/DisaRomb";
import Loading from "../Loading";
import './Behaviour.css'
import {youAre, goodAt, youSeek, youWant, youNeed, youCreate, toFeelGood, hintPrimary, hintSecondary}
  from "../texts/behaviour"
import { changeUrl } from '../../services/Navigation';
import { TrVar } from '../../services/translate';
import Text from '../Text/Text';

interface ComponentProps{
  who: string
  onboarding?: boolean
  redo?: Function
  next?: Function
  progress?: Function
  finishSection?: Function
}

interface ComponentState {
  disa: Array<DisaInterface>
  disaTexts: Array<string>
  loaded: boolean
  showInfo: boolean
}

export default class BehaviourView extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private who: string

  constructor(props: ComponentProps) {
    super(props);

    this.who = this.props.who === 'client' ? 'client' : 'coach'

    this.state = {
      disa: [],
      disaTexts: [],
      showInfo: !this.props.onboarding,
      loaded: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    this.api.get('analysis/' + this.props.who, ['conflict']).then(response => {
      this.setState({
        disa: response.json.disa,
        disaTexts: response.json.disaTexts,
        loaded: true
      })
    }).catch(response => {
        if (response.isExpectedError) {
          this.setState({ loaded: true });
        }
      })
  }

  redo = () => {
    if (this.props.redo) {
      this.props.redo()
    } else if (this.props.who === 'client') {
      changeUrl('/app/client/behaviour/edit')
    } else if (this.props.who === 'coach') {
      changeUrl('/app/coach/behaviour/edit')
    }
  }

  next = () => {
    if (this.props.next) {
      this.props.next()
    }
  }

  goTo = (url: string) => {
    if (this.who === 'client') {
        changeUrl('/app/client/' + url)
    } else {
        changeUrl('/app/coach/' + url)
    }
  }

  showBreadCrumbs = () => {
    if (this.who === 'coach') {
      return (
        <Breadcrumbs name={this.api.trTxt(TrVar.YourBehaviourProfile)}/>
      )
    } else {
      return (
        <Breadcrumbs name={this.api.trTxt(TrVar.YourBehaviourProfile)} />
      )
    }
  }

  newMeasurement = () => {
    if (this.props.who === 'client') {
      changeUrl('/app/client/behaviour/new')
    } else {
      changeUrl('/app/coach/behaviour/new')
    }
  }

  onboardProgress = (sub: number) => {
    if (this.props.progress && this.props.finishSection) {
      this.props.progress(4)  
      this.props.finishSection()      
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    if (this.state.disa.length > 0) {
      const primaryProp: DisaInterface = this.state.disa[0]
      const secondaryProp: DisaInterface = this.state.disa[1]
      const primaryKey: string = primaryProp.key
      const secondaryKey = secondaryProp.key

      const checkDisaName = (key: string) => {
        if (key === 'Supporting behavior') {
          return this.api.trTxt(TrVar.Supporting)
        } else if (key === 'Boosting behavior') {
          return this.api.trTxt(TrVar.Boosting)
        } else if (key === 'Driving behavior') {
          return this.api.trTxt(TrVar.Driving)
        } else {
          return this.api.trTxt(TrVar.Detailed)
        }
      }

      const checkDisaDesc = (key: string) => {
        if (key === 'Supporting behavior') {
          return this.api.trTxt(TrVar.SupportingDesc)
        } else if (key === 'Boosting behavior') {
          return this.api.trTxt(TrVar.BoostingDesc)
        } else if (key === 'Driving behavior') {
          return this.api.trTxt(TrVar.DrivingDesc)
        } else {
          return this.api.trTxt(TrVar.DetailedDesc)
        }
      }

      return (
        <>
          {!this.props.onboarding && this.showBreadCrumbs()}
          <div className={this.props.onboarding ? "" : "page_content"}>

            <div className="page_section">
              <h1>{this.api.trTxt(TrVar.YourAnalysisResult)}</h1>
              <div className="page_bar" />

              <div className="col-1_2">
                <div style={{ maxWidth: "600px" }}>
                  <DisaRomb disa={this.state.disa} color="cerise" />
                </div>
              </div>

              <div className="col-1_2">
                {
                  this.state.disa.map((d: DisaInterface, i) => {
                    return (
                      <div key={"disa_" + i} className="top_margin r_left_margin">
                        <h3>{checkDisaName(d.name)} <span className='color_cerise'>{d.value.toFixed(0)}%</span></h3> 
                        <Text color='gray'>{checkDisaDesc(d.name)}</Text>
                      </div>
                    )
                  })
                }
              </div>

              <div className="r-flex top_margin items-center">
                {
                  (this.props.onboarding && !this.state.showInfo) &&
                  <div className="button inline_block right_margin green" onClick={() => (this.props.who === 'client' ? this.onboardProgress(4) : this.next())}>
                    {this.api.trTxt(TrVar.Continue)}
                  </div>
                }
                {
                  (this.props.onboarding && !this.state.showInfo) &&
                  <div className="button inline_block white right_margin"
                    onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
                    {this.api.trTxt(TrVar.ReadMoreAboutYou)}
                  </div>
                }
                {
                  !this.props.onboarding &&
                  <div className="button inline_block right_margin green" onClick={() => this.redo()}>
                    {this.api.trTxt(TrVar.RedoTheAnalysis)}
                  </div>
                }
                {
                  !this.props.onboarding &&
                  <div className="button inline_block white r_left_margin r_top_margin" onClick={() => this.goTo('behaviour_styles')}>
                    {this.api.trTxt(TrVar.ReadMore)}
                  </div>
                }
              </div>
            </div>


            {
              this.state.showInfo &&
              <>
                <div className="page_section top_margin">
                  <div className="col-1_2">
                    <h2 className="bold">{this.api.trTxt(TrVar.MoreAboutYou)}</h2>
                    <p className="top_margin">{this.api.trTxt(TrVar.YouAre)}:</p>
                    {
                      youAre[primaryKey][this.api.lang].map((prop: string, i: number) => {
                        return (
                          <div key={"your_are_" + i}
                            className="pa_oval_button capitlize pa_background inline_block top_margin right_margin">
                              {prop}
                          </div>
                        )
                      })
                    }

                    <p className="top_margin">{this.api.trTxt(TrVar.YouAreAlso)}:</p>
                    {
                      youAre[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                        return (
                          <div key={"your_are_" + i}
                            className="pa_oval_button capitlize pa_background inline_block top_margin right_margin">
                            {prop}
                          </div>
                        )
                      })
                    }
                  </div>

                  <div className="col-1_2 r_top_margin_early_break">
                    <div className="pa_circle_box r_left_margin">
                      <div className="pa_top pa_outer_circle pa_gradient_cerise">
                        <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                            <h4>{this.api.trTxt(TrVar.YouSeek)}:</h4>
                            <Text color="gray">
                              <span dangerouslySetInnerHTML={{ __html: youSeek[primaryKey][this.api.lang] }} />
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="pa_left pa_outer_circle pa_gradient_cerise">
                        <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                            <h4>{this.api.trTxt(TrVar.YouWant)}:</h4>
                            <Text color="gray">
                              <span dangerouslySetInnerHTML={{ __html: youWant[primaryKey][this.api.lang] }} />
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="pa_right pa_outer_circle pa_gradient_cerise">
                        <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                            <h4>{this.api.trTxt(TrVar.YouNeed)}:</h4>
                            <Text color="gray">
                              <span dangerouslySetInnerHTML={{ __html: youNeed[primaryKey][this.api.lang] }} />
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="pa_bottom pa_outer_circle pa_gradient_cerise">
                        <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                            <h4>{this.api.trTxt(TrVar.YouCreate)}:</h4>
                            <Text color="gray">
                              <span dangerouslySetInnerHTML={{ __html: youCreate[primaryKey][this.api.lang] }} />
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-1_2 top_margin">
                    <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/brain.svg" />
                    <h3 className="top_margin">{this.api.trTxt(TrVar.YouAreEspeciallyGoodAt)}:</h3>
                    <ul>
                      {
                        goodAt[primaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <Text color="gray" element="li" key={"good_at_" + i}>
                              {prop}
                            </Text>
                          )
                        })
                      }
                    </ul>

                    <h3 className="top_margin">{this.api.trTxt(TrVar.YouAreAlsoGoodAt)}:</h3>
                    <ul>
                      {
                        goodAt[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <Text color="gray" element="li" key={"also_good_at_" + i}>
                              {prop}
                            </Text>
                          )
                        })
                      }
                    </ul>
                  </div>

                  <div className="col-1_2 top_margin">
                    <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/smiley.svg" />
                    <h3 className="top_margin">{this.api.trTxt(TrVar.YouNeedToFeelGood)}:</h3>
                    <ul>
                      {
                        toFeelGood[primaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <Text color="gray" element="li" key={"feel_good_" + i}>
                              {prop}
                            </Text>
                          )
                        })
                      }
                    </ul>

                    <h3 className="mt-8">{this.api.trTxt(TrVar.YouAlsoNeed)}:</h3>
                    <ul>
                      {
                        toFeelGood[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                          return (
                            <Text color="gray" element="li" key={"also_feel_good_" + i}>
                              {prop}
                            </Text>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>

                <div className="page_section top_margin">
                  <div className=" col-1_2">
                    <h2 className="bold">{this.api.trTxt(TrVar.MoreAboutYou)}</h2>
                    <Text color="gray" className="top_margin">{this.state.disaTexts[0]}</Text>
                    <Text color="gray" className="top_margin">{this.state.disaTexts[1]}</Text>
                  </div>
                  <div className="col-1_2 r_left_margin r_top_margin">
                    <h3>&nbsp;</h3>
                    <Text color="gray" className="top_margin">{this.state.disaTexts[2]}</Text>
                    <Text color="gray" className="top_margin">{this.state.disaTexts[3]}</Text>
                  </div>
                </div>

                <div className="page_section top_margin">
                  <div className=" col-1_2">
                    <h2 className="bold">{this.api.trTxt(TrVar.ThinkAbout)}</h2>
                    <Text color="gray" className="top_margin">{hintPrimary[primaryKey][this.api.lang]}</Text>
                  </div>
                  <div className="col-1_2 r_left_margin r_top_margin">
                    <h3>&nbsp;</h3>
                    <p className="top_margin">{hintSecondary[secondaryKey][this.api.lang]}</p>
                  </div>
                </div>                

                {
                  this.props.onboarding &&
                  <div className="top_margin page_section no_bg text_center">
                    <div className="button inline_block right_margin green" onClick={() => (this.props.who === 'client' ? this.onboardProgress(4) : this.next())}>
                      {this.api.trTxt(TrVar.Continue)}
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </>
      )
    }


    if (this.state.disa.length === 0) {
      return (
        <>
          {!this.props.onboarding && this.showBreadCrumbs()}
          <div className={this.props.onboarding ? "" : "page_content"}>
            <div className="page_section">
              <h1>{this.api.trTxt(TrVar.BehaviourProfile)}</h1>
              <div className="top_margin">
                {this.api.trTxt(TrVar.NoBehaviourMeasure)}
              </div>
              <div onClick={() => this.newMeasurement()} className="button inline_block top_margin">{this.api.trTxt(TrVar.NewMeasurement)}</div>
            </div>
          </div>
        </>
      )
    }
  }
}
