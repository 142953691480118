import React, {useState, useEffect, useRef} from 'react';
import Api from '../../services/Api';
import CoachAgreement from '../info/CoachAgreement';
import { LicenseInterface } from '../../interfaces/License';
import SvgAnimation from '../SvgAnimation';
import OnboardingOutro from './Outro';

interface ComponentProps {
  packageFree: boolean
  coachFree: boolean
  close: Function
  license?: LicenseInterface
  goToDev: Function
  save: Function
}

const ClientMeasureOutro = (props: ComponentProps) => {
  const api = Api.getInstance();
  const [coachFreeResult, toggleCoachFreeResult] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Measure the width of the content container
    if (contentRef.current && contentRef.current.offsetWidth) {
      setContentWidth(contentRef.current.offsetWidth || 0 );
    }
  }, [props]); // Re-run when props change

  const coachFreeSave = () => {
    toggleCoachFreeResult(true);
  }  

  if ((!props.packageFree && !props.coachFree)) {
    return (
      <div className='page_section flex flex-col justify-center'>
      <div style={{ width: contentWidth }} className="modal_animation mx-auto">
        <SvgAnimation animationType='star' />
      </div>
        <div style={{ paddingTop: '40px', maxWidth: '331px', margin: '0 auto' }} className="text_center" ref={contentRef}>
          {api.lang === 'sv' ?
            <>
              <h1>Bra jobbat!</h1>
              <div className='top_margin'>
                Du är klar med potentialmätningen och det är dags att matchas med coacher.
              </div>
              <div style={{ marginTop: '40px' }}>
                <div onClick={() => props.close()} className="button mobile_size_btn green inline_block">
                  Nästa
                </div>
              </div>
            </>
            :
            <>
              <h1>Well Done!</h1>
              <div className='top_margin'>
                You are finished with your measurement of potential, time to match with coaches.
              </div>
              <div style={{ marginTop: '40px' }}>
                <div onClick={() => props.close()} className="button mobile_size_btn green inline_block">
                  Next
                </div>
              </div>
            </>}
        </div>
      </div>
    )
  } else if (coachFreeResult) {
    return <OnboardingOutro save={props.save} goToDev={props.goToDev} />
  } else {
    return (
      <CoachAgreement next={coachFreeSave} hasCoach={false} inOnboarding={true}/>
    )
  }
}

export default ClientMeasureOutro;
