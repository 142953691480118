import React, { useState } from 'react';
import Api from "../../services/Api";
import EditAreasIntro from "../OnboardingSteps/EditAreasIntro"; 
import EditCoachOutro from "../OnboardingSteps/EditCoachOutro"; 
import EditAreas from "../edit/EditAreas";
import EditCoach from "../edit/EditCoach";
import CoachAgreement from "../info/CoachAgreement";
import { LicenseInterface } from '../../interfaces/License';
import { CoachInterface } from '../../interfaces/Coach';
import CoachAgreementOutro from '../OnboardingSteps/CoachAgreementOutro';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  save: Function
  coachFree: boolean
  progress: Function
  goTo: number
  finishSection: Function
  selectCoach: Function
  license?: LicenseInterface
  scrollTop?: Function
}

const CoachSectionOnboarding = (props: ComponentProps) => {
  const api = Api.getInstance()
  const [selectedCoach, setSelectedCoach] = useState({} as CoachInterface)

  const save = () => {
    props.progress(2)
  }

  const saveAgreement = () => {
    setTimeout(() => props.progress(5), 5)
  }

  const chooseCoach = (coach: CoachInterface) => {
    setSelectedCoach(coach);
  }

  if (props.goTo === 0) {
    return (
      <EditAreasIntro progress={props.progress} />
    )
  } else if (props.goTo === 1) {
    return (
      <div className="page_section">
        <EditAreas save={() => save()} who="client" hideCoachLanguage={props.coachFree}
          hideAreas={props.coachFree}
          saveText={api.trTxt(TrVar.Continue)} onboarding={true} cancelButton={false} />
      </div>
    )
  } else if (props.goTo === 2) {
    return (
      <EditCoach save={props.progress} chooseCoach={chooseCoach} onboarding={true} selectCoach={props.selectCoach} />
    )
  } else if (props.goTo === 3) {
    return (
      <EditCoachOutro progress={props.progress} selectedCoach={selectedCoach} />
    )
  } else if (props.goTo === 4) {
    return (
      <CoachAgreement inOnboarding={true} progress={props.progress} finishSection={props.finishSection} save={saveAgreement} hasCoach={!props.coachFree} />
    )
  } else {
    return (
      <CoachAgreementOutro progress={props.save} />
    )
  }
}

export default CoachSectionOnboarding;
