import {InputInterface, InputsInterface} from "./interfaces";

export function checkInput(input: InputInterface, language: string) {
  if (input.minLength) {
    if (input.value.length < input.minLength && input.minLength === 1) {
      input.error = language === 'sv' ? 'Får inte vara tomt' : 'Cannot be empty'
      return input.error
    } else if (input.value.length < input.minLength && input.minLength > 1) { 
      input.error = language === 'sv' ? `Minst ` + input.minLength + ` bokstäver` :
        `Minimum of ` + input.minLength + ` characters`;
      return input.error
    }
  }
  if (input.type === 'date') {
    if (input.value.length < 1 || input.value.length > 10) {
      input.error = language === 'sv' ? `Ogiltigt datum` :
        `Invalid date`;
      return input.error
    } if (input.minDate) {
      if (input.value < input.minDate) {
        input.error = language === 'sv' ? `Oops, dubbelkolla datumet! ` :
          `Oops, double-check the date!`;
        return input.error
      }
    } if (input.maxDate) {
      if (input.value > input.maxDate) {
        input.error = language === 'sv' ? `Oops, du verkar vara i framtiden!` :
          `Oops, you seem to be in the future!`;
        return input.error
      }      
    }
  }
  if (input.type === 'email') {
    if (input.minLength === 0 && input.value === '') {
      input.error = ''
      return input.error
    }
    let regex = /\S+@\S+\.\S+/;
    if (!regex.test(input.value)) {
      input.error = language === 'sv' ? `Ogiltig mejladdress` :
        `Invalid email adress`;
      return input.error
    }
  }
  if (input.maxLength) {
    if (input.value.length > input.maxLength) {
      input.error = language === 'sv' ? 'Max ' + input.maxLength + ' bokstäver' :
        'Maximum ' + input.maxLength + ' characters'
      return input.error
    }
  }
  if (input.maxValue) {
    const val = parseInt(input.value)
    if (val > input.maxValue) {
      input.error = language === 'sv' ? 'Värdet får inte vara större än ' + input.maxLength :
        'Value can not be larger than ' + input.maxLength
      return input.error
    }
  }
  if (input.minOptions) {
    let selectedOptions = 0
    if (input.options) {
      for (const option of input.options) {
        if (option.selected) {
          selectedOptions++
        }
      }
    }
    if (selectedOptions < input.minOptions) {
      input.error = language === 'sv' ? 'Du måste välja minst ' + (input.minOptions === 1 ? 'ett' : input.minOptions) :
        'You must choose at least ' + (input.minOptions === 1 ? 'one' : input.minOptions)
      return input.error
    }
  }
  input.error = ''
  return input.error
}

export function countInputErrors(inputs: InputsInterface) {
  let errors = 0
  for (const input of Object.values(inputs)) {
    if (input.error.length > 0) {
      errors++
    }
  }
  return errors
}

export function checkInputErrors(inputs: InputsInterface, language: string) {
  let errors = 0
  for (const input of Object.values(inputs)) {
    checkInput(input, language)
    if (input.error.length > 0) {
      errors++
    }
  }
  return errors
}

export function getInputValue(input: InputInterface): string {
  if (input.type === 'select' || input.type === 'radioselect') {
    if (input.options) {
      for (let option of input.options) {
        if (option.selected) {
          return option.value
        }
      }
    }
    return ''
  } else {
    return input.value
  }
}

export function getInputValues(input: InputInterface, valueType: string): Array<any> {
  const values = []
  if (input.type === 'selectmultiple') {
    if (input.options) {
      for (let option of input.options) {
        if (option.selected) {
          if (valueType === "int") {
            values.push(parseInt(option.value))
          } else {
            values.push(option.value)
          }
        }
      }
    }
  }
  return values
}
