import {IonContent, IonModal, IonPage} from '@ionic/react';
import React from 'react';
import Calendar from "../../components/calendar/Calendar";
import {CalendarEventInterface} from "../../interfaces/CalendarEvent";
import {dateFormatSoon} from "../../helpers/dateFunctions";
import Api from "../../services/Api";


interface ComponentProps {}

interface ComponentState {
  events: Array<CalendarEventInterface>
  showPop: boolean
  showEvent: CalendarEventInterface
  clickDate: Date
}

export default class TestCalendar extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()

  state = {
    events: [],
    showPop: false,
    showEvent: {} as CalendarEventInterface,
    clickDate: new Date()
  }

  componentDidMount() {
    const events = [] as Array<CalendarEventInterface>
    const d = new Date()

    const ev: CalendarEventInterface = {
      id: 12,
      name: 'Håkan',
      lastName: 'Djärv',
      d: d,
      date: "2022-08-22",
      length: 90,
      url: '',
      type: 'meeting'
    }
    events.push(ev)

    const d2 = new Date()
    d2.setDate(d2.getDate() + 3)
    d2.setHours(11, 30, 0, 0)

    events.push({
      id: 13,
      name: 'Rolf',
      lastName: 'Djärv',
      d: d2,
      date: "2022-08-22",
      length: 90,
      url: '',
      type: 'meeting'
    })

    const d3 = new Date()
    d3.setDate(d3.getDate() + 3)
    d3.setHours(13, 0, 0, 0)

    events.push({
      id: 14,
      name: 'Berit',
      lastName: 'Djärv',
      d: d3,
      date: "2022-08-22",
      length: 30,
      url: '',
      type: 'meeting'
    })
    this.setState({events: events})
  }


  clickDate = (d: Date) => {
    this.setState({showEvent: {} as CalendarEventInterface, clickDate: d, showPop: true})
  }

  clickEvent = (showEvent: CalendarEventInterface) => {
    console.log('Clicked event')
    this.setState({showEvent: showEvent, clickDate: showEvent.d, showPop: true})
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <IonModal isOpen={this.state.showPop} className="modal_small"
                    onDidDismiss={() => this.setState({showPop: false})}>
            <div className="p-4 color_white">
              <div className="flex justify-between">
                <h2>Handle</h2>
                <img alt="close" className="cal_icon cursor-pointer" src="/assets/icon/white/close-circle.svg"
                     onClick={() => this.setState({showPop: false})}/>
              </div>
              {
                this.state.showEvent.id ?
                  (
                    <div className="mt-4">
                      <p>Event: {this.state.showEvent.id} {this.state.showEvent.name}</p>
                      <p>Date: {this.state.showEvent.d.toISOString()}</p>
                    </div>
                  ) : (
                    <div className="mt-4">Time: {dateFormatSoon(this.state.clickDate, this.api.lang)}</div>
                  )
              }
            </div>
          </IonModal>
          <div className="page_content">
            <div className="page_section">
              <div style={{height: "70vh", paddingBottom: "102px"}}>
                <Calendar events={this.state.events} clickDate={this.clickDate} clickEvent={this.clickEvent}/>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

};
