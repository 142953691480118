import React from 'react';
import Api from '../../services/Api';
import { TrVar } from '../../services/translate';

const CoachClientNotFound = () => {
    const api = Api.getInstance()

    return (
        <div className="notfound_client">
            <h2 className="notfound_h2">{api.trTxt(TrVar.PageNotFound)}</h2>
            <h2 className="notfound_h2">{api.trTxt(TrVar.PleaseChooseAnotherClient)}</h2>
        </div>
    )
}

export default CoachClientNotFound;