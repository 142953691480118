import {ResourcesInterface, PartnerInterface} from "./interfaces";

export async function debugStream(stream: MediaStream, peerConnection: RTCPeerConnection)  {

  console.log('--- debugStream')
  let info = ''

  for (const track of stream.getTracks()) {

    const reports = await peerConnection.getStats(track)
    info += debugReport(reports)
  }
  return info
}

export function debugOffer(offer: any) {
  for (const line of offer.sdp.split('\r\n')) {
    if (line.substr(0, 2) === 'm=') console.log(line)
    // console.log(line)
  }
}

function debugReport(reports: RTCStatsReport) {
  let info = ''
  // let bytesReceived = 0
  reports.forEach((now: any) => {
    if (now.type === 'track') {
      // console.log(now)
      if (now.kind === 'video') {
        info += '\nvideo size: ' + now.frameWidth + ' x ' + now.frameHeight
      } else if (now.kind === 'audio') {
        if (now.audiolevel !== undefined) {
          info += '\naudio level: ' + now.audioLevel
        }
        console.log('total audio energy: ' + now.totalAudioEnergy)
      } else {
        console.log(now)
      }
    } else if (now.type === 'remote-outbound-rtp') {
      info += '\n' + now.kind + ' bytes sent ' + now.bytesSent
    } else if (now.type === 'media-source') {
      if (now.kind === 'audio') {
        info += '\nAudio level: ' + now.audioLevel
      } else if (now.kind === 'video') {
        info += '\nFrames per second: ' + now.framesPerSecond
      }
    // } else if (now.type === 'transport') {
    //   if (bytesReceived === 0) {
    //     bytesReceived = now.bytesReceived
    //     info += '\nBytes received: ' + bytesReceived
    //   }
    } else {
      console.log('debugReport type: ' + now.type)
      // console.log(now)
    }
  })
  return info
}


export async function showLocalInfo(resources: ResourcesInterface, showInfo: Function) {

  let info = ''
  if (resources.partners.length === 0) {
    info += 'Not connected yet'
  } else {
    const partner = resources.partners[0]
    if (resources.localStream) {
      info += await debugStream(resources.localStream, partner.peerConnection)
    }
    if (resources.screenStream) {
      info += await debugStream(resources.screenStream, partner.peerConnection)
    }
  }

  showInfo(info, true)
}

export async function showPartnerInfo(partner: PartnerInterface) {
  let info = partner.userName + ': ' + partner.peerConnection.iceConnectionState +
    ' (gathering state: ' + partner.peerConnection.iceGatheringState + ')'
  if (partner.videoStream) {
    info += await debugStream(partner.videoStream, partner.peerConnection)
  }
  return info
}
