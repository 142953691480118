import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import { ClientInterface } from '../../interfaces/Client';
import { InputInterface, InputsInterface } from '../form/interfaces';
import {checkInputErrors, countInputErrors} from '../form/CheckInput'
import Input from "../../components/form/Input";
import { changeUrl } from "../../services/Navigation";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  navigation: any
}

interface ComponentState {
  client: ClientInterface
  loaded: boolean
  inputs: InputsInterface
  showFormError: boolean
}

export default class ClientDevelopmentAreas extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  inputs: InputsInterface = {  
    areas: {
      type: 'selectmultiple',
      name: 'areas',
      placeholder: '',
      value: '',
      error: '',
      options: [],
      minOptions: 1,
    },
    languages: {
      type: 'selectmultiple',
      name: 'languages',
      placeholder: '',
      value: '',
      error: '',
      options: [],
      minOptions: 1
    }
  }
  
  state: Readonly<ComponentState> = {
    client: {} as ClientInterface,
    loaded: false,
    showFormError: false,
    inputs: this.inputs
  }

  componentDidMount() {
    this.getData();
  }

  private getData = () => {
    this.api.get('client?areas=1&i18n=1').then(response => {
      const client = response.json.client as ClientInterface
      for (const area of response.json.areas) {
        this.inputs.areas.options?.push({
          name: area.name,
          value: area.id,
          moreInfo: area.moreInfo,
          selected: area.selected,
          readonly: area.readonly
        })
      }
      for (let lang of response.json.i18n.languages) {
        this.inputs.languages.options?.push({
          name: lang.name,
          value: lang.key,
          selected: lang.selected,
        })
      }
      this.setState({ client: client, loaded: true });
    })
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  private saveForm = () => {
    if (this.checkAllErrors() > 0) {
      return
    }

    const areas = []
    if (this.inputs.areas.options) {
      for (const area of this.inputs.areas.options) {
        if (area.selected) {
          areas.push(parseInt(area.value))
        }
      }
    }

    const languages = []
    if (this.inputs.languages.options) {
      for (const language of this.inputs.languages.options) {
        if (language.selected) {
          languages.push(language.value)
        }
      }
    }
  
    const areaData = {
      developmentAreas: {
        areas: areas,
      },
      i18n: {
        languages: languages
      }
    }

    let nextUrl: string
    if (this.props.navigation.args.startsWith("next=")) {
      nextUrl = this.props.navigation.args.substring(5)
    } else {
      nextUrl = '/app/client/my_account'
    }

    this.api.post('client_save2', areaData).then(_response => {
      changeUrl(nextUrl)
    })
  }
  
  render() {    
    return (  
      <>  
        <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.MyAccount), link: '/app/client/my_account' }]}
          name={this.api.trTxt(TrVar.CoachAreas)}/>    
        <div className="page_content">      
          <div className="page_section">        
            <div className="flex">          
              <img className='svg_blue' height={50} src="/assets/icon/diagram.svg" alt="blue diagram icon" />          
              <h1 className='left_margin my-auto'>{this.api.trTxt(TrVar.CoachAreas)}</h1>        
            </div>        
            <div className="page_bar" />        
            <div className="flex justify-between">          
              <div style={{ width: "600px" }}>            
                <h2>{this.api.trTxt(TrVar.YourCoachAreas)}</h2>
                <h4 className="blue top_margin bottom_margin">{this.api.trTxt(TrVar.ChooseInterest)}</h4>
                <Input data="development-areas" input={this.inputs.areas} update={this.inputChange} classes=""
                       language={this.api.lang} />

                <h2 className='top_margin bottom_margin'>{this.api.trTxt(TrVar.LangToBeCoached)}</h2>
                <Input data="languages-input" input={this.inputs.languages} update={this.inputChange} classes=""
                       language={this.api.lang} />
                <button className="button top_margin " color="blue" onClick={() => this.saveForm()}>{this.api.trTxt(TrVar.Save)}</button>          
              </div>          
              <div className="hide_medium">                
                <img src="/assets/illustration/development.svg"                   
                  style={{ width: "360px", height: "360px" }}                   
                  alt="development areas" />          
              </div>        
            </div>      
          </div>    
        </div>  
      </>    
    )  
  }
}
