import {
  IonCol,
  IonGrid, IonRow,
} from '@ionic/react'
import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {MeasureArea} from "../../interfaces/MeasureArea";
import {MeasureQuestion} from "../../interfaces/MeasureQuestion";
import BarChart from "../graphics/BarChart";
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from "../../services/translate";

interface ComponentProps{
  measureId: number
  coachId?: number
  first?: boolean
  changePage: Function
  close: Function
}

interface ComponentState {
  areas: Array<MeasureArea>
  areaNr: number
  totalCurrent: number
  totalDesired: number
  firstName: string
  measures: number
}

export default class ClientMeasureAnalysis extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    areas: [],
    areaNr: -1,
    totalCurrent: 0,
    totalDesired: 0,
    firstName: '',
    measures: 0
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    let url = 'measure/analysis/' + this.props.measureId
    if (this.props.coachId) {
      url = 'measure_coach/analysis/' + this.props.measureId
    }
    this.api.get(url).then(response => {
      this.setState({
        areas: response.json.areas,
        totalCurrent: response.json.totalCurrent,
        totalDesired: response.json.totalDesired,
        firstName: response.json.firstName,
        measures: response.json.measures
      })
    })
  }

  private goBack = () => {
    if (this.props.coachId) {
      this.props.close()
    } else {
      changeUrl('/app/client/measure')
    }
  }

  public closeArea = () => {
    this.setState({areaNr: -1})
  }

  render() {
    if (this.state.areaNr >= 0) {
      const area =  this.state.areas[this.state.areaNr]
      const areaWantIncTxt = area.desired > area.current ? this.api.trTxt(TrVar.Increase).toLowerCase() : this.api.trTxt(TrVar.Decrease).toLowerCase()
      let improveText = this.api.trTxt(TrVar.Improve).toLowerCase()
      let increasedP = area.desired - area.current
      if (increasedP < 0) {
        improveText = this.api.trTxt(TrVar.Decrease).toLowerCase()
      }
      const breadcrumbs = []
      if (this.props.coachId) {
        breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
        breadcrumbs.push({name: this.state.firstName, link: '', linkFunc: this.props.close})
        breadcrumbs.push({name: this.api.trTxt(TrVar.MeasurePotential), link: '', linkFunc: this.closeArea})
      } else {
        breadcrumbs.push({name: this.api.trTxt(TrVar.MeasurePotential), link: '/app/client/measure'})
        breadcrumbs.push({name: this.api.trTxt(TrVar.Result), link: '', linkFunc: this.closeArea})
      }
      return (
        <div className="page_content">
          <Breadcrumbs breadcrumbs={breadcrumbs} name={area.name} />
          <div className="page_section">
            <IonGrid>
              <IonRow>
                <IonCol size="12" sizeMd="6">
                  <div className="text_center">
                    <h1>{this.state.firstName}</h1>
                    <h4 className="top_margin">
                      {this.api.trTxt(TrVar.YouWantTo)} {improveText} {area.your} {area.name} {this.api.trTxt(TrVar.With).toLowerCase()}
                      &nbsp;{Math.abs(increasedP).toFixed(0)}%
                    </h4>
                  </div>
                </IonCol>
                <IonCol size="12" sizeMd="6" className="text_center">
                  <p className="font_smaller bottom_margin uppercase">{this.api.trTxt(TrVar.CurrentState)}</p>
                  <BarChart value={area.current} size=""/>
                  <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.DesiredState)}</p>
                  <BarChart value={area.desired} size="" oneColor={true}/>
                </IonCol>
              </IonRow>
            </IonGrid>

            <h4 className="top_margin">
              {this.api.trTxt(TrVar.YouWantTo)} {areaWantIncTxt} {area.your} {area.name} {this.api.trTxt(TrVar.From).toLowerCase()}
              &nbsp;{area.current.toFixed(0)}%
              &nbsp;{this.api.trTxt(TrVar.To).toLowerCase()} {area.desired.toFixed(0)}%
            </h4>
            {
              area.questions.filter((question: MeasureQuestion) => {
                return question.valueDesired > question.valueCurrent
              }).map((question: any, qidx: number) => {
                return (
                  <p key={"question_imp_" + qidx} className="mt-4 mx-8">
                    {question.improve}
                  </p>
                )
              })
            }
            <h3 className="top_margin">{this.api.trTxt(TrVar.ResultPerQuestion)}</h3>
            {
              area.questions.map((question: MeasureQuestion, questionIndex) => {
                return (
                  <div key={"question_" + questionIndex}>
                    <h4 className="top_margin">{question.question}</h4>
                    <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.CurrentState)}</p>
                    <BarChart value={question.valueCurrent} size="small"/>
                    <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.DesiredState)}</p>
                    <BarChart value={question.valueDesired} size="small" oneColor={true}/>
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }
    let improveText = this.api.trTxt(TrVar.Improve).toLowerCase()
    let increasedP = this.state.totalDesired - this.state.totalCurrent
    if (increasedP < 0) {
      improveText = this.api.trTxt(TrVar.Decrease).toLowerCase()
    }
    let breadcrumbsName: string
    const breadcrumbs = []
    if (this.props.coachId) {
      breadcrumbsName = this.api.trTxt(TrVar.MeasurePotential)
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.state.firstName, link: '', linkFunc: this.props.close})
    } else {
      breadcrumbsName = this.api.trTxt(TrVar.Result)
      breadcrumbs.push({name: this.api.trTxt(TrVar.MeasurePotential), link: '/app/client/measure'})
    }
    return (
      <div className="page_content">
        {
          this.props.first ? (
            <h2>
              {this.api.trTxt(TrVar.ResultFromYourMeasurement)}
            </h2>
          ) : (
            <Breadcrumbs breadcrumbs={breadcrumbs} name={breadcrumbsName} />
          )
        }

        <div className="page_section">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <div className="text_center right_margin">
                  <h1>{this.state.firstName}</h1>
                  <h4 className="top_margin">
                    {this.api.trTxt(TrVar.YouWantTo)} {improveText}
                    &nbsp;{this.api.trTxt(TrVar.YourTotalPotentialWith).toLowerCase()} {Math.abs(increasedP).toFixed(0)}%
                  </h4>
                </div>
              </IonCol>
              <IonCol size="12" sizeMd="6" className="text_center">
                <p className="font_smaller bottom_margin uppercase">{this.api.trTxt(TrVar.CurrentState)}</p>
                <BarChart value={this.state.totalCurrent} size=""/>
                <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.DesiredState)}</p>
                <BarChart value={this.state.totalDesired} size=""/>
              </IonCol>
            </IonRow>
          </IonGrid>


          <h2 className="top_margin">{this.api.trTxt(TrVar.ResultsPerArea)}</h2>
          {
            this.state.areas.map((area: MeasureArea, areaIndex) => {

              return (
                <div key={"area_" + areaIndex} className="top_margin col-1_2">
                  <h4>{area.name}</h4>
                  <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.CurrentState)}</p>
                  <BarChart value={area.current} size="small"/>
                  <p className="font_smaller top_margin bottom_margin uppercase">{this.api.trTxt(TrVar.DesiredState)}</p>
                  <BarChart value={area.desired} size="small" oneColor={true}/>
                  <p className="top_margin underline cursor-pointer" onClick={() => this.setState({areaNr: areaIndex})}>
                    {this.api.trTxt(TrVar.ReadMore)}
                  </p>
                </div>
              )
            })
          }
          {
            this.props.first &&
              <div className="page_section text_center">
                  <div className="button inline_block" onClick={() => this.props.close(false)}>
                    {this.api.trTxt(TrVar.Continue)}
                  </div>
              </div>
          }
        </div>
        {
          (this.state.measures > 1 && !this.props.coachId) &&
          <div className="page_section text_center">
            <div className="button inline_block" onClick={() =>  changeUrl('/app/client/measure')}>
              {this.api.trTxt(TrVar.ViewYourDevelopment)}
            </div>
          </div>
        }
      </div>
    )
  }
}
