import {IonTextarea} from '@ionic/react'
import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import {TrVar} from "../../services/translate"
import "./Tasks.css"

interface ActionInterface {
  id: number
  measureTaskId: number
  notes: string
  status: number
  changed: boolean
}

interface TaskInterface {
  id: number,
  nr: number,
  questionId: number,
  questionText: string,
  subQuestions: Array<string>
  action: string
  info: string
  userAction: ActionInterface
  viewOpen: boolean
}

interface QuestionInterface {
  id: number
  question: string
  valueCurrent: number
  valueStart: number
  valueDesired: number
  largeGap: boolean
  tasks: Array<TaskInterface>
}

interface AreaInterface {
  name: string
  nr: number
  your: string
  yourCap: string
  valueCurrent: number
  valueDesired: number
  valueStart: number
  largeGap: number
  powerQuestions: number
  questions: Array<QuestionInterface>
  gapQuestions: Array<QuestionInterface>
}

interface ComponentProps {
  navigation: any
}

interface ComponentState {
  areas: Array<AreaInterface>
  clientName: string
  measureId: number
  loaded: boolean
  position: number
  changes: number
  showMeasureInfo: boolean
  error: string
}

export default class Tasks extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private baseUrl = this.props.navigation.client ? '/app/coach/clients/' + this.props.navigation.client + '/tasks/'
    : '/app/client/tasks/'

  state: Readonly<ComponentState> = {
    areas: [],
    loaded: false,
    clientName: '',
    position: 0,
    changes: 0,
    measureId: 0,
    showMeasureInfo: false,
    error: ''
  }

  componentDidMount() {
    this.api.getProfile()
    this.getData()
  }

  private getData = () => {
    let url = 'potential_tasks'
    if (this.props.navigation.client) {
      url = 'potential_tasks_coach/' + this.props.navigation.client
    }
    this.api.get(url, ['no_measure']).then(response => {
      this.setState({
        areas: response.json.areas,
        measureId: response.json.measureId,
        clientName: response.json.name,
        loaded: true
      })
    }).catch(response => {
      if (response.isExpectedError) {
        this.setState({error: 'no_measure', loaded: true})
      }
    })
  }

  private goTo = (area: number, question?: number, task?: number) => {
    let url = this.baseUrl + area + "/"
    if (question) {
      url += question + "/"
      if (task) {
        url += task + "/"
      }
    }
    changeUrl(url)
  }

  private saveAction = (action: ActionInterface) => {
    if (action.changed) {
      this.api.post('potential_task/save', action).then(_response => {
        action.changed = false
        this.setState({changes: 0})
      })
    }
  }

  navigate = (areaNr: number, questionNr: number) => {
    let url = this.baseUrl
    if (areaNr > -1) {
      url += areaNr
      if (questionNr > -1) {
        url += '/' + questionNr
      }
    }
    changeUrl(url)
  }

  getArea = (areaNr: number) => {
    if (areaNr >= 0 && areaNr < 4) {
      return this.state.areas[areaNr]
    }
    return {} as AreaInterface
  }

  getQuestion = (questionId: number, area: AreaInterface) => {
    for (const question of area.gapQuestions) {
      if (question.id === questionId) {
        return question
      }
    }
    return {} as QuestionInterface
  }

  getTask = (taskId: number, question: QuestionInterface) => {
    // If we have no taskId, return first task not worked with. Or last, if all have been worked with
    if (taskId === 0) {
      for (const task of question.tasks) {
        if (task.userAction.status === 0) {
          return task
        }
      }
      return question.tasks[question.tasks.length - 1]
    }
    for (const task of question.tasks) {
      if (task.id === taskId) {
        return task
      }
    }
    return {} as TaskInterface
  }

  handleNote = (e: any, task: TaskInterface) => {
    task.userAction.notes = e.target.value
    task.userAction.changed = true
    this.setState({changes: this.state.changes + 1})
  }

  changeNote = (e: any, task: TaskInterface) => {
    this.saveAction(task.userAction)
  }

  handleStatus = (status: number, task: TaskInterface) => {
    if (this.props.navigation.client === 0 && task.userAction.status !== status) {
      task.userAction.status = status
      task.userAction.changed = true
      // If user changed status directly from changing notes, we might make two calls simultaneously. Add delay to
      // avoid collision in backend.
      setTimeout(() => {
        this.saveAction(task.userAction)
      },200)

      // this.setState({changes: this.state.changes + 1})
    }
  }

  areaGapQuestionsDone = (area: AreaInterface) => {
    let questions = 0
    for (const question of area.gapQuestions) {
      let done = 0
      for (const task of question.tasks) {
        if (task.userAction && task.userAction.status === 4) {
          done++
        }
      }
      if (done === question.tasks.length) {
        questions++
      }
    }
    return questions
  }

  areaTasksDone = (area: AreaInterface) => {
    let tasks = 0
    for (const question of area.gapQuestions) {
      for (const task of question.tasks) {
        if (task.userAction && task.userAction.status === 4) {
          tasks++
        }
      }
    }
    return tasks
  }

  areaTasks = (area: AreaInterface) => {
    let tasks = 0
    for (const question of area.gapQuestions) {
      tasks += question.tasks.length
    }
    return tasks
  }

  questionTasksDone = (question: QuestionInterface) => {
    let tasks = 0
    for (const task of question.tasks) {
      if (task.userAction && task.userAction.status === 4) {
        tasks++
      }
    }
    return tasks
  }

  getBreadcrumbs = () => {
    let breadcrumbs = []
    if (this.props.navigation.client) {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.state.clientName, link: '/app/coach/clients/' + this.props.navigation.client})
    }
    return breadcrumbs
  }

  showMenu = () => {
    let breadcrumbs = this.getBreadcrumbs()

    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.GrowPotential)} breadcrumbs={breadcrumbs}/>

        { !this.props.navigation.client &&
          <div style={{background: 'linear-gradient(90deg, rgba(255,204,200,1) 0%, rgba(188,128,240,1) 100%)', height: '100%', width: '100%'}} className="page_section dark_blue">
            {this.api.lang === 'sv'
              ?
              <div style={{ maxWidth: '563px' }}>
                <div className="flex">
                  <div className='right_margin'>
                    <h3>Vill du testa våra nya coachfrågor?</h3>
                    <div className='font_smaller'>Du hittar de även i huvudmenyn under Utvecklingsområden.</div>
                  </div>
                  <div style={{ maxHeight: '45px', margin: 'auto auto' }} onClick={() => changeUrl('/app/client/tasks2')} className="button inline_block green">Kör</div>
                </div>
              </div>
              :
              <div style={{ maxWidth: '563px' }}>
                <div className="flex">
                  <div className='right_margin'>
                    <h3>Do you want to try our new coaching questions?</h3>
                    <div className='font_smaller'>You can also find them in the main menu under Areas of development.</div>
                  </div>
                  <div style={{ maxHeight: '45px', margin: 'auto auto' }} onClick={() => changeUrl('/app/client/tasks2')} className="button inline_block green">Go</div>
                </div>
              </div>
              }
          </div>
        }

        <div className="page_section top_margin">
          <h1>{this.api.trTxt(TrVar.GrowPotential)}</h1>
          <div className="page_bar"/>
          <p className="top_margin">
            {this.api.trTxt(TrVar.PowerQuestionsIntro)}
          </p>
        </div>

        {
          this.state.areas.map((area: AreaInterface, index: number) => {
              const gapQuestionsDone = this.areaGapQuestionsDone(area)
              const gapQuestionsRemaining = area.gapQuestions.length - gapQuestionsDone
              const tasks = this.areaTasks(area)
              const tasksDone = this.areaTasksDone(area)
              let powerQuestionsIntroVar: TrVar
              switch(area.nr) {
                case 1: {
                  powerQuestionsIntroVar = TrVar.PowerQuestionsIntro1
                  break
                }
                case 2: {
                  powerQuestionsIntroVar = TrVar.PowerQuestionsIntro2
                  break
                }
                case 3: {
                  powerQuestionsIntroVar = TrVar.PowerQuestionsIntro3
                  break
                }
                default: {
                  powerQuestionsIntroVar = TrVar.PowerQuestionsIntro0
                  break
                }
              }

              let powerAreaGoodVar: TrVar
              switch(area.nr) {
                case 1: {
                  powerAreaGoodVar = TrVar.PowerAreaGood1
                  break
                }
                case 2: {
                  powerAreaGoodVar = TrVar.PowerAreaGood2
                  break
                }
                case 3: {
                  powerAreaGoodVar = TrVar.PowerAreaGood3
                  break
                }
                default: {
                  powerAreaGoodVar = TrVar.PowerAreaGood0
                  break
                }
              }

              let powerAreaDoneVar: TrVar
              switch(area.nr) {
                case 1: {
                  powerAreaDoneVar = TrVar.PowerAreaDone1
                  break
                }
                case 2: {
                  powerAreaDoneVar = TrVar.PowerAreaDone2
                  break
                }
                case 3: {
                  powerAreaDoneVar = TrVar.PowerAreaDone3
                  break
                }
                default: {
                  powerAreaDoneVar = TrVar.PowerAreaDone0
                  break
                }
              }            
            
              return (
                <div className="top_margin page_section" key={"area_" + index}>
                  <h2 className={"task_color_" + area.nr}>
                    {area.name}
                  </h2>
                  <div className="r-flex">
                    <div className="mt-4 r-half-col">
                      {this.api.trTxt(powerQuestionsIntroVar)}
                    </div>
                    <div className="r-half-col r-ml-8">
                      {
                        (tasksDone === tasks && area.powerQuestions > 0) ? (
                          <>
                            <div className="flex mt-4">
                              <div className="circle_medium bg_green flex flex-shrink-0 justify-center items-center">
                                <img className="px-1" alt="check" src="/assets/icon/black/checkmark.svg" />
                              </div>
                              <div className="ml-4">
                                {this.api.trTxt(powerAreaGoodVar)}!&nbsp; 
                                {this.api.trTxt(TrVar.PowerAreaDone)} {area.name}. {this.api.trTxt(powerAreaDoneVar)}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex mt-4 items-center">
                              <div className={"task_circle task_gradient_" + area.nr + " task_number color_black flex flex-shrink-0 justify-center items-center"}>
                                {gapQuestionsRemaining}
                              </div>
                              <div className="ml-4">
                                {this.api.trTxt(TrVar.YouHave)} {gapQuestionsRemaining} {this.api.trTxt(TrVar.Statements).toLowerCase()}&nbsp;
                                {this.api.trTxt(TrVar.YouCanWorkOn).toLowerCase()}.
                              </div>
                            </div>
                            {
                              tasksDone > 0 &&
                              <div className="flex mt-4 items-center">
                                <img alt="check" className="icon_huge" src={"/assets/icon/tasks_check_" + area.nr + ".svg"}/>
                                <div className="ml-4">
                                  {this.api.trTxt(TrVar.YouHaveCompleted)} {tasksDone} {this.api.trTxt(TrVar.PowerfulQuestions).toLowerCase()}.&nbsp;
                                  {this.api.trTxt(TrVar.WellDone)}!&nbsp;
                                </div>
                              </div>
                            }
                          </>
                        )
                      }

                      {
                        area.powerQuestions > 0 &&
                        <div className="mt-8">
                          {
                            (tasks === tasksDone) ? (
                              <p className="button white inline_block" onClick={() => this.navigate(index, -1)}>
                                {this.api.trTxt(TrVar.SeeQuestions)}
                              </p>
                            ) : (
                              <div className={"button inline_block " + ((tasksDone > 0 || index === 0) ? "green" : "white")}
                                   onClick={() => this.navigate(index, -1)}>
                                {
                                  tasksDone === 0 ? this.api.trTxt(TrVar.GetStarted) : this.api.trTxt(TrVar.Continue)
                                }
                              </div>
                            )
                          }

                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            }
          )
        }
        <p className="mt-8">&nbsp;</p>
      </div>
    )
  }

  showArea = () => {

    let breadcrumbs = this.getBreadcrumbs()
    breadcrumbs.push({name: this.api.trTxt(TrVar.GrowPotential), link: this.baseUrl})

    const area: AreaInterface = this.getArea(parseInt(this.props.navigation.sub1))

    const colorClass = "task_color_" + area.nr

    return (
      <div>
        <div className="page_content">
          <Breadcrumbs breadcrumbs={breadcrumbs}
                       name={area.name}/>
          <div className="page_section">
            <h1 className={colorClass}>{area.name}</h1>
            <div className="page_bar"/>
            <p>
              {this.api.trTxt(TrVar.ChooseStatementAndPowerQuestions)}
            </p>
          </div>

          {
            area.gapQuestions.map(question => {
              const tasksDone = this.questionTasksDone(question)
              let buttonText: string
              let buttonClass: string
              if (tasksDone === question.tasks.length) {
                buttonText = this.api.trTxt(TrVar.SeeQuestions)
                buttonClass = 'white'
              } else if (tasksDone === 0) {
                buttonText = this.api.trTxt(TrVar.GetStarted)
                buttonClass = 'green'
              } else {
                buttonText = this.api.trTxt(TrVar.Continue)
                buttonClass = 'green'
              }
              return (
                <div key={"question_" + question.id} className="page_section top_margin">
                  <div className="r-flex justify-between">
                    <div className="col-1_2">
                      <h3>{question.question}</h3>
                      <div className="flex items-center mt-4">
                        <div className={"task_circle task_gradient_" + area.nr + " task_number color_black flex flex-shrink-0 justify-center items-center"}>
                          {question.tasks.length}
                        </div>
                        <p className="ml-4">{this.api.trTxt(TrVar.YouHave)} {question.tasks.length}&nbsp;
                          {this.api.trTxt(TrVar.QuestionsToWorkWith).toLowerCase()}
                        </p>
                      </div>
                      {
                        tasksDone > 0 &&
                        <div className="flex mt-4 items-center">
                          <img alt="check" className="icon_huge" src={"/assets/icon/tasks_check_" + area.nr + ".svg"}/>
                          <div className="ml-4">
                            {this.api.trTxt(TrVar.YouHaveCompleted)} {tasksDone} {this.api.trTxt(TrVar.PowerfulQuestions).toLowerCase()}.&nbsp;
                            {this.api.trTxt(TrVar.WellDone)}!&nbsp;
                          </div>
                        </div>
                      }
                    </div>

                    <div className="r_top_margin r_left_margin">
                      <div>
                       {this.api.trTxt(TrVar.YourCurrentState)}:&nbsp;
                        <div className={colorClass + " inline_block"}>{question.valueCurrent}%</div>
                      </div>
                      <div className="mt-4">
                        {this.api.trTxt(TrVar.DesiredState)}:&nbsp;
                        <div className={colorClass + " inline_block"}>{question.valueDesired}%</div>
                      </div>
                    </div>

                    <div className="r_top_margin  r-ml-4">
                      <div className={"button " + buttonClass}
                           onClick={() => this.goTo(area.nr, question.id)}>
                        {buttonText}
                      </div>
                    </div>
                  </div>

                </div>
              )
            })
          }

        </div>
      </div>
    )
  }

  showQuestion = () => {
    const area: AreaInterface = this.getArea(parseInt(this.props.navigation.sub1))
    const question: QuestionInterface = this.getQuestion(parseInt(this.props.navigation.sub2), area)

    let taskId = 0
    if (this.props.navigation.sub3.length > 0) {
      taskId = parseInt(this.props.navigation.sub3)
    }
    const task: TaskInterface = this.getTask(taskId, question)

    const colorClass = "task_color_" + area.nr
    const bgColorClass = "task_bgcolor_" + area.nr
    let prevTaskId = 0
    let nextTaskId = 0
    if (question.tasks.length > 1) {
      let position = 0
      for (const t of question.tasks) {
        if (t.id === task.id) {
          break
        }
        position++
      }
      if (position > 0) {
        prevTaskId = question.tasks[position - 1].id
      }
      if (position < (question.tasks.length - 1)) {
        nextTaskId = question.tasks[position + 1].id
      }
    }

    let breadcrumbs = this.getBreadcrumbs()
    breadcrumbs.push({name: this.api.trTxt(TrVar.GrowPotential), link: this.baseUrl})
    breadcrumbs.push({name: area.name, link: this.baseUrl + area.nr})

    return (
      <div className="page_content">
        <Breadcrumbs breadcrumbs={breadcrumbs} name={this.api.trTxt(TrVar.PowerfulQuestion)} />

        <div className="page_section text_center blue">
          {question.question}
        </div>

        <div className="page_section top_margin text_center">
          {
            question.tasks.map((t: TaskInterface) => {
              let progressClass = "task_progress inline_block mx-2"
              if (t.id === task.id) {
                progressClass += " active"
              }
              let innerStyle: any = {width: "0%", height: "100%"}
              if (t.userAction.status === 4) {
                innerStyle = {width: "100%", height: "100%", borderRadius: "5px"}
              } else if (t.userAction.status > 0) {
                innerStyle = {width: "50%", height: "100%", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px"}
              }

              return (
                <div key={"task_" + t.id} className={progressClass}
                     onClick={() => this.goTo(area.nr, question.id, t.id)}>
                  <div style={innerStyle} className={bgColorClass}/>
                </div>
              )
            })
          }

          <h2 className={colorClass + " top_margin"}>{task.questionText}</h2>
          {
            task.info.length > 0 &&
            <p className="mt-2 font_small"><i>{task.info}</i></p>
          }

          {
            task.subQuestions.map((subQuestion, index) => {
              return (
                <p key={"subquestion_" + task.id + "_" + index} className="mt-2">
                  {subQuestion}
                </p>
              )
            })
          }

          <IonTextarea disabled={this.props.navigation.client > 0}
                       className="top_margin mx-auto page_text_area text_left" cols={40} rows={11}
                       placeholder={this.api.trTxt(TrVar.WriteHere)}
                       value={task.userAction.notes} onInput={(e) => this.handleNote(e, task)}
                       onBlur={(e) => this.changeNote(e, task)}/>


          <div className="r-flex justify-between mx-auto" style={{maxWidth: "500px"}}>
            <div className="mt-4 flex items-center cursor-pointer" onClick={() => this.handleStatus(2, task)}>
              <div className={"check_box flex-shrink-0" + (task.userAction.status === 2 ? " bg_blue" : "")}/>
              <p className="ml-4">{this.api.trTxt(TrVar.PowerQuestionsFeedback1)}</p>
            </div>
            <div className="mt-4 r-ml-4 flex items-center  cursor-pointer" onClick={() => this.handleStatus(3, task)}>
              <div className={"check_box flex-shrink-0" + (task.userAction.status === 3 ? " bg_blue" : "")}/>
              <p className="ml-4">{this.api.trTxt(TrVar.PowerQuestionsFeedback2)}</p>
            </div>
            <div className="mt-4 r-ml-4 flex items-center  cursor-pointer" onClick={() => this.handleStatus(4, task)}>
              <div className={"check_box flex-shrink-0" + (task.userAction.status === 4 ? " bg_blue" : "")}/>
              <p className="ml-4">{this.api.trTxt(TrVar.PowerQuestionsFeedback3)}</p>
            </div>
          </div>

          <div className="flex justify-between top_margin mx-auto" style={{maxWidth: "500px"}}>
            <div>
              {
                (prevTaskId > 0) &&
                  <div className="button white"
                       onClick={() => this.goTo(area.nr, question.id, prevTaskId)}>
                    {this.api.trTxt(TrVar.Previous)}
                  </div>
              }
            </div>

            <div>
              {
                (nextTaskId > 0) &&
                <div className={"button " + (task.userAction.status > 0 ? "blue" : "white")}
                     onClick={() => this.goTo(area.nr, question.id, nextTaskId)}>
                  {this.api.trTxt(TrVar.Next)} {this.api.trTxt(TrVar.Question).toLowerCase()}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    if (this.state.error.length > 0) {
      return (
        <div className="page_content">
          <div className="page_section">
            <h2>Not available</h2>
            <p className="top_margin">First measure is not done yet</p>
          </div>
        </div>
      )
    }

    if (this.props.navigation.sub2.length > 0) {
      return this.showQuestion()
    } else if (this.props.navigation.sub1.length > 0) {
      return this.showArea()
    } else {
      return this.showMenu()
    }
  }
}
