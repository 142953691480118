import React from 'react'
import {CardInterface} from "./interfaces"
import "./carousel.css"
import {
  IonRow,
  IonCol,
  IonModal
} from "@ionic/react";
import BarChart from '../graphics/BarChart';
import { changeUrl } from "../../services/Navigation";
import Api from "../../services/Api";
import {
  FocusPlanInterface,
} from "../tasks/interfaces";
import {
  formatDate,
} from '../../helpers/dateFunctions';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  cards: Array<CardInterface>
  position: number
  storePosition: Function
  click?: Function
  chooseNext: boolean
  setChooseNext: Function
  isFree: boolean
  plans: Array<FocusPlanInterface>
  navigation: any
  focusArea: string
  filter: number
  toggleFollowup: Function
}

interface ComponentState {
  position: number
  swipeWidth: number
  cardWidth: number
  degrees: number
  showAllQuestionAreas: boolean
  showEarlyPlan: boolean
  choosenCard: number
}

const DEGS_TO_RADS = 2 * Math.PI / 360

export default class Carousel extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  interval: any
  swipeRef: any
  containerRef: any
  swipe: HTMLElement | undefined
  prevMoveX: number = 0
  prevMoveY: number = 0
  cx: number = 0
  mouseDown = false
  animating = false
  moves: number = 0
  maxPos = this.props.cards.length - 1
  direction = 'n'

  constructor(props: ComponentProps) {
    super(props);
    this.swipeRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      position: this.props.position,
      swipeWidth: 0,
      cardWidth: window.innerWidth < 792 ? 310 : 392,
      degrees: 0,
      showAllQuestionAreas: window.innerWidth < 792,
      showEarlyPlan: false,
      choosenCard: 0,
    }
  }

  componentDidMount() {
    this.props.toggleFollowup(false)
    this.setSwipeWidth()
    window.addEventListener("resize", this.setSwipeWidth)
    this.swipe = this.swipeRef.current;
    this.updateSwipeRef()
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    if (this.props.cards.length !== prevProps.cards.length) {
        this.setState({position: 0})
        this.maxPos = this.props.cards.length - 1;
        this.setSwipeWidth()
        window.addEventListener("resize", this.setSwipeWidth)
        this.swipe = this.swipeRef.current
        this.updateSwipeRef()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSwipeWidth)
  }

  setSwipeWidth = () => {
    if (this.containerRef.current && this.swipeRef.current) {
      const swipeWidth = this.containerRef.current.offsetWidth
      this.swipeRef.current.style = 'width: ' +swipeWidth + 'px; height: 310px'
      this.setState({swipeWidth: swipeWidth})
    }
  }

  updateSwipeRef = () => {
    if (this.swipe) {

      this.swipe.addEventListener('touchmove', (event: any) => {
        this.touchMove(event);
      });

      this.swipe.addEventListener('touchend', (event: any) => {
        this.touchEnd(event, false);
      });

      this.swipe.addEventListener('mousedown', (event: any) => {
        this.mouseDown = true
        this.moves = 0
      });

      this.swipe.addEventListener('mousemove', (event: any) => {
        this.mouseMove(event)
      });

      this.swipe.addEventListener('mouseout', (event: any) => {
        if (this.mouseDown) {
          this.mouseDown = false
          this.touchEnd(event, true)
        }
      });

      this.swipe.addEventListener('mouseup', (event: any) => {
        this.mouseDown = false
        this.touchEnd(event, false);
      });

    }
    if (this.props.chooseNext) {
      this.props.setChooseNext(false)
      setTimeout(() => {
        this.animatePos(-45)
      }, 1800)
    }
  }

  touchMove = (event: any) => {
    if (event.changedTouches.length <= 0) {
      return;
    }

    let moveX = event.changedTouches[0].clientX
    let moveY = event.changedTouches[0].clientY

    if (this.prevMoveX === 0) {
      this.prevMoveX = moveX;
    }
    if (this.prevMoveY === 0) {
      this.prevMoveY = moveY;
    }

    let changeX = moveX - this.prevMoveX;
    let changeY = moveY - this.prevMoveY;

    if (this.direction === 'n') {
      if (changeX !== 0 || changeY !== 0) {
        if (Math.abs(changeY) > Math.abs(changeX)) {
          this.direction = 'y'
        } else {
          this.direction = 'x'
        }
      }
    }

    this.prevMoveX = moveX;
    this.prevMoveY = moveY;

    if (this.direction !== 'x') {
      return
    }

    if (event.cancelable)  event.preventDefault();

    if (changeX != null && changeX !== 0 && changeX) {
      this.moves++
      this.calculateDegreesPosition(changeX * 0.5)
    }
  }

  mouseMove = (event: any) =>  {
    if (this.mouseDown) {
      event.preventDefault();
      this.handleMove(event.offsetX)
    }
  }

  handleMove = (moveX: number) => {
    if (this.prevMoveX === 0) {
      this.prevMoveX = moveX;
    }

    let changeX = moveX - this.prevMoveX;

    this.prevMoveX = moveX;

    if (changeX != null && changeX !== 0 && changeX) {
      this.moves++
      this.calculateDegreesPosition(changeX * 0.5)
    }
  }

  touchEnd = (event: any, leftSwipeArea: boolean) => {
    const moves = this.moves
    this.moves = 0
    this.prevMoveX = 0
    this.prevMoveY = 0
    this.cx = 0
    if (this.direction === 'y') {
      this.direction = 'n'
      return
    }
    this.direction = 'n'
    let animateDegrees: number
    if (this.state.degrees > 22.5) {
      animateDegrees = 45.0 - this.state.degrees
    } else if (this.state.degrees > -22.5) {
      animateDegrees = -this.state.degrees
    } else {
      animateDegrees = -45 - this.state.degrees
    }
    if (Math.abs(animateDegrees) > 2.0) {
      this.animatePos(animateDegrees)
    } else if (!leftSwipeArea) {
      setTimeout(() => {
        this.setState({degrees: 0})
        if (moves < 1) {
          this.handleClick(event.offsetX)
        }
      }, 50)
    }
  }

  handleClick = (offsetX: number) => {
    const cardLeft = (this.state.swipeWidth - this.state.cardWidth) / 2
    if (offsetX < cardLeft) {
      // Swipe left
      this.animatePos(45)
      return
    }
    const cardRight = cardLeft + this.state.cardWidth
    if (offsetX < cardRight) {
      // Click card
      if (this.props.click) {
        if (this.props.isFree && this.state.position > 2) {
          changeUrl('/app/client/purchase')
        } else {
          this.props.click(this.props.cards[this.state.position].id, this.state.position)
        }

      }
      return
    }
    // Swipe right
    this.animatePos(-45)

  }

  calculateStyle = (index: number) => {
    let relPos = index - this.state.position
    if (relPos === this.maxPos) {
      relPos = -1
    } else if (relPos === -this.maxPos) {
      relPos = 1
    }
    const distance = Math.abs(relPos)
    let degrees = 0
    let display = "block"
    if (distance === 0) {
      degrees = this.state.degrees
    } else if (distance === 1) {
      degrees = relPos * 45 + this.state.degrees
     } else {
      display = "none"
    }
    const xMove = Math.sin(degrees * DEGS_TO_RADS) * 220
    const scale = Math.cos(Math.abs(degrees) * DEGS_TO_RADS)
    const zIndex = 1000 + Math.round(1000.0 * scale)
    const opacity = distance === 0 ? 1.0 : 0.5

    return {
      zIndex: zIndex,
      display: display,
      opacity: opacity,
      transform: "translate3d(" + xMove + "px,0,0) scale(" + scale + ")"
    }
  }

  animatePos = (degrees: number) => {
    if (this.animating) {
      return
    }
    let steps = Math.abs(degrees) > 22.3 ? 8 : 4
    let inc = degrees / steps
    let iterator = 0
    this.animating = true
    this.interval = setInterval(() => {
      if (iterator >= steps) {
        this.setState({degrees: 0})
        this.props.storePosition(this.state.position)
        clearInterval(this.interval)
        this.animating = false
      } else {
        iterator++
        this.calculateDegreesPosition(inc)
      }
    }, 25)
  }

  calculateDegreesPosition = (degreeChange: number) => {
    let position = this.state.position
    let degrees = this.state.degrees + degreeChange
    if (degrees > 22.5) {
      degrees -= 45
      position--
      if (position < 0) {
        position = this.maxPos
      }
    } else if (degrees < -22.5) {
      degrees += 45
      position++
      if (position > this.maxPos) {
        position = 0
      }
    }
    this.setState({degrees: degrees, position: position})
  }

  showCardContent = (card: any, index: number, greenBtn: boolean) => {
    const compareDates = () => {
      let today = new Date().toISOString().split('T')[0];
      let newToday = new Date(today);
      let followup = new Date(card.followupDate)
      if (followup <= newToday) {
        return true;
      } else {
        return false;
      }
    }

    const buttonText = () => {
      if ((card.followupDone) || (card.done && !card.plan)) {
        return this.api.trTxt(TrVar.Summary)
      } else if (card.planDone) {
        if (compareDates() === true) {
          return this.api.trTxt(TrVar.DoFollowUp)
        } else {
          return this.api.trTxt(TrVar.Review)
        }
      } else if ((card.done && !card.planDone) || (card.tasks[0] === true)) {
        return this.api.trTxt(TrVar.Continue)
      } else {
        return this.api.trTxt(TrVar.GetStarted)
      }
    }

    const buttonClass = (showGreen: boolean) => {
      if ((compareDates() === true) || card.done) {
        return ''
      } else if (showGreen) {
        return 'green'
      } else {
        return 'white'
      }
    }

    const buttonClick = () => {
      if (card.followupDone) {
        changeUrl('/app/client/tasks2/' + this.props.navigation.sub1 + '/' + this.props.navigation.sub2 + card.id + '/summary/')
      } else if (card.planDone) {
        if (compareDates() === true) {
          changeUrl('/app/client/tasks2/' + this.props.navigation.sub1 + '/' + this.props.navigation.sub2 + card.id + '/followup/')
        } else {
          this.props.click && this.props.click(card.id, index)        
        }
      } else {
        this.props.click && this.props.click(card.id, index)        
      }
    }

    const goToFollowup = () => {
      this.setState({ showEarlyPlan: false })
      setTimeout(() => changeUrl('/app/client/tasks2/' + this.props.navigation.sub1 + '/' + this.props.navigation.sub2 + this.state.choosenCard + '/followup/'), 300)
    }

    const checkDate = () => {
      if (card.planDone && !card.followupDone) {
        if (compareDates() === true) {
          return (
            <div className='text_center flex flex-col items-center'>
              <img width={60} src="/assets/icon/white/arrow_answer.svg" alt="white answer arrow" />
              <div className='top_margin'>{this.api.trTxt(TrVar.TimeForFollowup)}.</div>
            </div>
          )
        } else {
          return (
            <div className="flex flex-col">
              <div className="flex justify-center">
                <img width={60} src="/assets/icon/white/clock.svg" alt="white clock" />
              </div>
              <div className='top_margin'>{this.api.trTxt(TrVar.OngoingPlan)}</div>
              <div>{this.api.trTxt(TrVar.FollowUp)} {formatDate(card.followupDate, false, this.api.lang)}</div>
            </div>
          )
        }
      }
    }
    
    return (
      <>
        <IonModal isOpen={this.state.showEarlyPlan}
          onDidDismiss={() => this.setState({ showEarlyPlan: false })}
          className="modal_tall">
          <div style={{ backgroundColor: '#FFCCC8' }} className="h-full ">
            <img onClick={() => this.setState({ showEarlyPlan: false })} className='absolute modal_close'
              src="/assets/focusArea/cross_black.svg" height={'20px'} alt="" />
            <div style={{ height: '100%' }} className="text_center flex flex-col justify-center">
              <h1>
                {this.api.trTxt(TrVar.TimeForFollowup)}?
              </h1>
              <div className="top_margin">
                {this.api.trTxt(TrVar.HaveYouDoneYourPlan)}
              </div>
              <div className="flex justify-center">
                <div onClick={() => goToFollowup()} className="button inline_block btn_top_margin">{this.api.trTxt(TrVar.YesFollowUp)}!</div>
                <div onClick={() => this.setState({ showEarlyPlan: false })} className="button left_margin black inline_block btn_top_margin">{this.api.trTxt(TrVar.No)}</div>
              </div>
            </div>
          </div>
        </IonModal>
        <div className="relative">
          {(this.props.isFree && index > 2) &&
            <img onClick={() => changeUrl('/app/client/purchase')} className='absolute carousel_lock'
              src="/assets/focusArea/lock.svg" alt="" />
          }
          <div className='font_small blue'>
            {this.api.trTxt(TrVar.Exercise)} {index + 1}/{this.props.cards.length}</div>
          {card.followupDone ?
            <img className='absolute question_done_checkmark' src={"/assets/focusArea/circle_check_green.svg"}
              height={'50px'} alt="" />
            : card.done && !card.plan ?
              <img className='absolute question_done_checkmark' src={"/assets/focusArea/circle_check_green.svg"}
                height={'50px'} alt="" />
              :
              <></>
          }
          <h3 className='top_margin'>{card.name}</h3>
        </div>
        <div style={{ opacity: ((card.planDone && !card.followupDone) ? '0' : '1') }} className='top_margin w-full'>
          <p style={{ 'color': '#fff', 'marginBottom': '9px' }}>
            {this.api.trTxt(TrVar.YourCurrentState)} {this.api.trTxt(TrVar.Is).toLowerCase()} {card.current}%
          </p>
          <BarChart desiredValue={card.desired} value={card.current} size="small"></BarChart>
          <p style={{ 'color': '#fff', 'marginTop': '9px' }}>
            {
              card.desired > card.current ?
                this.api.trTxt(TrVar.YouWantToIncrease)
                :
                this.api.trTxt(TrVar.WantedState)
            }
            &nbsp;{card.desired}%
          </p>
        </div>
        <div style={{ width: '100%' }} className='relative'>
          {card.planDone &&
            <div className="absolute" style={{ top: '-88px', width: '100%' }}>
              {checkDate()}
            </div>
          }
          <div style={{ opacity: ((card.planDone && !card.followupDone) ? '0' : '') }}>
            <div>
              {this.api.trTxt(TrVar.Contains)} {card.tasks.length}
              &nbsp;{this.api.trTxt(TrVar.Questions).toLowerCase()}
              {card.plan &&
                <>
                  ,
                  <div>{this.api.trTxt(TrVar.PlansAndFollowUp).toLowerCase()}</div>
                </>
              }
            </div>
            <div className="flex flex-row justify-center">
              {
                card.tasks.map((task: boolean) => {
                  const star = task ? '/assets/focusArea/medal_green.svg' : '/assets/focusArea/medal_gray.svg'
                  return (
                    <img className="object-contain h-10" key={'star_' + card.id + (Math.random() * this.props.cards.length)} alt="star" style={{ 'marginTop': '5px', marginRight: '8px' }} height={'40px'} src={star} />
                  )
                })
              }
              {card.plan &&
                <>
                  <img className="object-contain h-10" key={'star_' + card.id + (Math.random() * this.props.cards.length)} alt="star" style={{ 'marginTop': '5px', marginRight: '8px' }} height={'40px'} src={card.planDone ? '/assets/focusArea/medal_green.svg' : '/assets/focusArea/medal_gray.svg'} />
                  <img className="object-contain h-10" key={'star_' + card.id + (Math.random() * this.props.cards.length)} alt="star" style={{ 'marginTop': '5px' }} height={'40px'} src={card.followupDone ? '/assets/focusArea/medal_green.svg' : '/assets/focusArea/medal_gray.svg'} />
                </>
              }
            </div>
          </div>
        </div>
        {(this.props.isFree && index > 2) ?
          <div onClick={() => changeUrl('/app/client/purchase')}>
            <div className={"button green inline_block"}>
              {this.api.trTxt(TrVar.UnlockExercise)}
            </div>
          </div>
          :
          <>
            {(card.planDone && !card.followupDone && (compareDates() === false)) &&
              <div className="pr-4" style={{ paddingBottom: '5px' }}>
                <div onClick={() => this.setState({ showEarlyPlan: true, choosenCard: card.id })} className="button white inline_block">
                  {this.api.trTxt(TrVar.MakeFollowUp)}
                </div>
              </div>
            }
            <div onClick={() => buttonClick()} className="flex flex-col">
              <div>
                <div className={"button inline_block " + buttonClass(greenBtn)}>
                  {buttonText()}
                </div>
              </div>
            </div>
          </>
        }
      </>
    )
  }

  showCarousel = () => { 
    return this.state.showAllQuestionAreas && this.props.cards.length > 3 
  }

  noCards = () => {
    return (
      <div>
        {this.props.filter === 2 ?
          this.api.trTxt(TrVar.SeeOngoingExercises)
          : this.props.filter === 3 ?
            this.api.trTxt(TrVar.SeeFollowUpExercises)
            : this.api.trTxt(TrVar.SeeFinishedExercises)
        }
      </div>
    )
  }

  render() {

    console.log('Showing carousel: ' + this.showCarousel())
    let greenBtnShown = 0;

    if (this.showCarousel()) {
      return (
        <div className={this.showCarousel() ? "display_block" : "display_none"}>
          <div className="carousel_swipe_container top_margin" ref={this.containerRef}>
            <div className="carousel_swipe_area" ref={this.swipeRef} />
            {
              this.props.cards.map((card: CardInterface, index: number) => {
                let greenBtn = !card.done && greenBtnShown < 3 ? true : false;
                if (greenBtn) {
                  greenBtnShown++;
                }
                const style = this.calculateStyle(index)
                return (
                  <div key={"card_" + card.id} className="carousel_card text_center" style={style}>
                    <div className='flex flex-col justify-between' style={{ 'height': '100%' }}>
                      {this.showCardContent(card, index, greenBtn)}
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className="flex flex-row justify-center w-full top_margin" >
            <img src="/assets/focusArea/card_shadow.svg" width={'700px'} alt="" />
          </div>
        </div>
      )
    } else if ((this.state.swipeWidth !== 0) && !this.showCarousel()){
      return (
        <IonRow className="top_margin task_cards_full">
          {this.props.cards.length === 0 ?
            this.noCards()
            :
            this.props.cards.map((card: CardInterface, index: number) => {
              let greenBtn = !card.done && greenBtnShown < 3 ? true : false;
              if (greenBtn) {
                greenBtnShown++;
              }
                
              return (
                <IonCol key={"card_" + card.id} size="12" sizeLg='6' sizeXl='4' >
                  <div className={'flex flex-col justify-between single_area_question_card text_center'}>
                    {this.showCardContent(card, index, greenBtn)}
                  </div>
                </IonCol>
              )
            })
          }
        </IonRow>
      )
    }

    return (
      <>
        <IonRow className="top_margin task_cards_full">
          {this.props.cards.length === 0 ?
            this.noCards()
            :
            this.props.cards.map((card: CardInterface, index: number) => {
              let greenBtn = !card.done && greenBtnShown < 3 ? true : false;
              if (greenBtn) {
                greenBtnShown++;
              }
              return (
                <IonCol key={"card_" + card.id} size="12" sizeLg='6' sizeXl='4' >
                  <div className={'flex flex-row justify-center single_area_question_card text_center'}>
                    {this.showCardContent(card, index, greenBtn)}
                  </div>
                </IonCol>
              )
            })
          }
        </IonRow>
      </>
    )
  }
}
