import React from 'react'
import {IonContent} from "@ionic/react";
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  close: Function
}

interface ComponentState {}

export default class MeetingBlock extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  render() {
    return (
      <IonContent>
        <div style={{ minHeight: "100%", padding: '35px 0' }}>
          <div className="p-8">
            <div className="flex items-center justify-between">
              <h3>{this.api.trTxt(TrVar.AddMeetings)}</h3>
              <img alt="close" onClick={() => this.props.close()} className="cursor-pointer icon_medium"
                src="/assets/icon/white/close-circle.svg" />
            </div>
            <p className="top_margin">
              {this.api.trTxt(TrVar.ClickOnATimeInTheCalendarWhereYouWantToAddATimeInterval)}
            </p>
            <p className="top_margin">
              {this.api.trTxt(TrVar.WhenYourClientWantsToBookAMeetingZebrainWillCalculate)}
            </p>
            <h4 className="mt-8">{this.api.trTxt(TrVar.ChangeMeetingBlock)}</h4>
            <p className="top_margin">
              {this.api.trTxt(TrVar.IfYouClickInAGreenMeetingBlockYouCanChange)}
            </p>
            <h4 className="mt-8">{this.api.trTxt(TrVar.Example)}</h4>
            <p className="top_margin">
              {this.api.trTxt(TrVar.YouAddAMeetingBlockForMonday)}
            </p>

            <ul>
              <li>{this.api.trTxt(TrVar.TimeNineOClock)}</li>
              <li>{this.api.trTxt(TrVar.TimeNineFortyFive)}</li>
              <li>{this.api.trTxt(TrVar.TimeTenThirty)}</li>
              <li>{this.api.trTxt(TrVar.TimeElevenFifteen)}</li>
            </ul>

            <p className="top_margin">
              {this.api.trTxt(TrVar.IfSomeoneHasBookedAMeetingInYourMeetingBlock)}
            </p>

            <ul>
              <li>{this.api.trTxt(TrVar.TimeNineOClock)}</li>
              <li>{this.api.trTxt(TrVar.TimeNineFortyFive)}</li>
              <li>{this.api.trTxt(TrVar.TimeElevenFifteen)}</li>
            </ul>
            <h4 className="mt-8">{this.api.trTxt(TrVar.IfSomeoneBooksALongerMeeting)}</h4>
            <p className="top_margin">
              {this.api.trTxt(TrVar.AClientThatIsAboutToBookAFortyFiveMinuteMeeting)}
            </p>


            <ul>
              <li>{this.api.trTxt(TrVar.TimeNineOClock)}</li>
              <li>{this.api.trTxt(TrVar.TimeTenOClock)}</li>
              <li>{this.api.trTxt(TrVar.TimeElevenOClock)}</li>
            </ul>

            <p>
              {this.api.trTxt(TrVar.IfYouGetBookedMeetingsWithDifferentLengthsInAMeetingBlock)}
            </p>


          </div>
        </div>
      </IonContent>
    )
  }
}
