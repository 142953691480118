import React from 'react'
import Api from "../../services/Api"
import { changeUrl } from '../../services/Navigation';
import './ClientLicense.css';
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  isFree: boolean
}


interface ComponentState {
  buttonActive: boolean
}

export default class KlarnaPurchase extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  state = {
    buttonActive: false
  }

  componentDidMount() {
    this.purchase();
  }

  purchase = () => {
    const data = {
      product_code: 'ACCESS_PRIVATE'
    }
    this.api.post('klarna/create_order', data).then(response => {
      this.renderOrderSnippet(response.json.html_snippet)
      this.setState({ buttonActive: true });
    })
  }

  renderOrderSnippet = (htmlSnippet: string) => {
    var checkoutContainer = document.getElementById('checkout-container')
    if (checkoutContainer) {
      checkoutContainer.innerHTML = htmlSnippet
      var scriptsTags = checkoutContainer.getElementsByTagName('script')
      for (var i = 0; i < scriptsTags.length; i++) {
        var parentNode = scriptsTags[i].parentNode
        if (parentNode) {
          var newScriptTag = document.createElement('script')
            newScriptTag.type = 'text/javascript'
            newScriptTag.text = scriptsTags[i].text
            parentNode.removeChild(scriptsTags[i])
            parentNode.appendChild(newScriptTag)
        }
      }
    }
  }

  render() {
    return (
      <>
        <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.Upgrade), link: '/app/client/purchase' }]}
          name={this.api.trTxt(TrVar.Checkout)} />
        <div className="page_content">
          <div className="page_section client_purchase_bg">
            {this.props.isFree ?
              <div className='flex section_direction client_purchase_checkout'>
                <div className='r-half-col half' id="checkout-container" />
                <div className='r-half-col half r_left_margin r_top_margin'>
                  <div style={{ margin: '0' }} className='client_purchase_upgrade'>
                    <div style={{ fontWeight: '500' }}>{this.api.trTxt(TrVar.UpgradeTo)} <b>{this.api.trTxt(TrVar.AccessPrivate)}</b></div>
                    <h2 style={{ marginBottom: '20px', fontWeight: '600' }}>{this.api.trTxt(TrVar.ReachYourPotential)}</h2>
                    <div className='client_purchase_bar' />
                    <h4 style={{ fontWeight: '600', marginBottom: '8px' }} className="top_margin">
                      {this.api.trTxt(TrVar.ThisIsIncluded)}:
                    </h4>
                    <div style={{ marginBottom: '5px' }} className='flex'>
                      <div className='client_purchase_dot' />
                      <div>
                        {this.api.trTxt(TrVar.AllDevelopmentAreas)}
                      </div>
                    </div>
                    <div style={{ marginBottom: '5px' }} className='flex'>
                      <div className='client_purchase_dot' />
                      <div>
                        {this.api.trTxt(TrVar.BehaviourAnalysis)}
                      </div>
                    </div>
                    <div style={{ marginBottom: '5px' }} className='flex'>
                      <div className='client_purchase_dot' />
                      <div>
                        {this.api.trTxt(TrVar.ContinualMeasurementOfDevelopment)}
                      </div>
                    </div>
                    <div style={{ marginBottom: '20px' }} className='flex'>
                      <div className='client_purchase_dot' />
                      <div>
                        {this.api.trTxt(TrVar.IndividualAccountOverview)}
                      </div>
                    </div>
                    <div className='client_purchase_bar top_margin' />
                    <h3 className='top_margin'>{this.api.trTxt(TrVar.Only)} <span style={{ color: '#BC80F0' }}>49 {this.api.trTxt(TrVar.Sek).toLowerCase()}</span> {this.api.trTxt(TrVar.PerMonth)}</h3>
                  </div>
                </div>
              </div>
              :
              <div>
                <div>
                  <p>{this.api.trTxt(TrVar.YouHaveAlreadyUpgradedToAccess)}</p>
                  <div className='button inline_block top_margin' onClick={() => changeUrl('/app/client/overview')}>{this.api.trTxt(TrVar.ToOverview)}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}
