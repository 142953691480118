import React, {useEffect, useState, ReactElement, ReactNode} from 'react';

import Markdown from 'react-markdown'
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks'

import { Modal, CustomFlowbiteTheme } from 'flowbite-react';
import { FaSpinner } from 'react-icons/fa';

import Api from "../../services/Api"
import {TrVar} from "../../services/translate"
import Breadcrumbs from "../menu/Breadcrumbs";
import Text from '../Text'
import TabBar, {TabBarRef} from '../Tabs';
import { IoIosArrowForward, IoIosArrowBack, IoMdClose } from 'react-icons/io';

import "./ClientProgram.css"

const api = Api.getInstance()

const customTheme: CustomFlowbiteTheme['tab'] = {
  "base": "flex flex-col",
  "tablist": {
    "base": "flex text-center tab_page_section rounded-t-none mb-8 p-0",
    "styles": {
      "default": "flex-wrap border-b border-gray-200",
      "underline": "flex-wrap -mb-px border-b border-gray-200",
      "pills": "flex-wrap font-medium text-sm text-gray-500 space-x-2 ",
      "fullWidth": "w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col rounded-none"
    },
    "tabitem": {
      "base": "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400",
      "styles": {
        "default": {
          "base": "rounded-t-lg",
          "active": {
            "on": "bg-gray-100 text-cyan-600",
            "off": "text-gray-500 hover:bg-gray-50 hover:text-gray-600"
          }
        },
        "underline": {
          "base": "rounded-t-lg",
          "active": {
            "on": "text-cyan-600 rounded-t-lg border-b-2 border-cyan-600 active",
            "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600"
          }
        },
        "pills": {
          "base": "",
          "active": {
            "on": "rounded-lg bg_neutral_700 text-white",
            "off": "rounded-lg hover:text-gray-900 bg_neutral_600 hover:bg-gray-100 text-white"
          }
        },
        "fullWidth": {
          "base": "ml-0 first:ml-0 w-full rounded-none flex",
          "active": {
            "on": "p-4 text-gray-900 bg-gray-100 active rounded-none",
            "off": "bg-white hover:text-gray-700 hover:bg-gray-50 rounded-none"
          }
        }
      },
      "icon": "mr-2 h-5 w-5"
    }
  },
  "tabitemcontainer": {
    "base": "",
    "styles": {
      "default": "",
      "underline": "",
      "pills": "",
      "fullWidth": ""
    }
  },
  "tabpanel": "pt-0"

};

const modalCustomTheme: CustomFlowbiteTheme['modal'] = {
  "root": {
    "base": "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-x-hidden md:inset-0 md:h-full",
    "show": {
      "on": "flex bg-gray-900 bg-opacity-90",
      "off": "hidden"
    },
    "sizes": {
      "sm": "max-w-sm",
      "md": "max-w-md",
      "lg": "max-w-lg",
      "xl": "max-w-xl",
      "2xl": "max-w-2xl",
      "3xl": "max-w-3xl",
      "4xl": "max-w-4xl",
      "5xl": "max-w-5xl",
      "6xl": "max-w-6xl",
      "7xl": "max-w-7xl",
      "full": "w-full"
    },
    "positions": {
      "top-left": "items-start justify-start",
      "top-center": "items-start justify-center",
      "top-right": "items-start justify-end",
      "center-left": "items-center justify-start",
      "center": "items-center justify-center",
      "center-right": "items-center justify-end",
      "bottom-right": "items-end justify-end",
      "bottom-center": "items-end justify-center",
      "bottom-left": "items-end justify-start"
    }
  },
  "content": {
    "base": "relative h-full w-full p-4 md:h-auto",
    "inner": "mt-12 relative rounded-lg bg-tranparent flex flex-col flex-nowrap justiy-end max-h-[90vh] w-full close_button_container"
  },
  "body": {
    "base": "p-6 flex-1 overflow-auto",
    "popup": "pt-0"
  },
  "header": {
    "base": "",
    "popup": "",
    "title": "text-xl font-medium  ",
    "close": {
      "base": "",
      "icon": "h-5 w-5 text-white"
    }
  },
  "footer": {
    "base": "flex items-center space-x-2 rounded-b border-gray-200 p-6",
    "popup": "border-t"
  }
};

// Interfaces
interface ProgramContentInterface {
  header: string;
  text: string;
}

interface ProgramFile {
  id: number;
  filename: string;
}

interface FrameworkInterface {
  id: number;
  name: string;
  logo: string;
  welcome_image: string;
  welcome_text: string;
  welcome_title: string;
  files: Array<ProgramFile>;
  content: Array<ProgramContentInterface>;
}

interface FrameworkSectionInterface {
  name: string;
  text: string;
  description: string;
  image: string;
  image_description: string;
  section_logo: string;
}



interface TabContentProps {
  framework?: FrameworkInterface;
  section: FrameworkSectionInterface;
  toggleOpenModal: (image: string) => void;
  handleButtonClick: (index: number) => void;
  index: number;
  numSections: number;

}

interface ItemInterface {
  title: string;
  content: JSX.Element;
}

interface ComponentProps {
  client?: any;
  close?: Function;
}

interface LandingPageProps {
  framework?: FrameworkInterface;
  sections: FrameworkSectionInterface[];
  handleButtonClick: Function;
  tabbarRef: React.RefObject<TabBarRef>;
  toggleOpenModal: (image: string) => void;
}

interface MarkdownProps {
  markdownText: string;
}

const SmallScreenLayout = ( framework: FrameworkInterface ) => (
  <div className="flex flex-col mb-4">
    <Text element='h1' className="text-xl font-medium">{framework.name}</Text>
    <hr className="full-divider mb-8 " />
    {framework.logo && <img src={framework.logo} alt="placeholder" className="object-contain object-left mb-4  max-h-24 max-w-80" />}
  </div>
);

const LargeScreenLayout = (framework: FrameworkInterface) => (
  <div>
    <div className="flex flex-row flex-nowrap justify-between">
      <div className="flex-1 min-w-0 mr-2">
        <Text element='h1' className="text-3xl font-medium break-words">{framework.name}</Text>
      </div>
      <div className="flex justify-end">
        {framework.logo && <img src={framework.logo} alt="placeholder" className="object-contain max-h-24 max-w-80" />}
      </div>
    </div>
    <hr className="full-divider mt-8 mb-8" />
  </div>
  );

const SmallScreenLandingPage = ({ framework, sections, handleButtonClick, tabbarRef, toggleOpenModal}: LandingPageProps) => {

  if(!framework){
    return null;
  }

  return (
    <>
      {/* Second Section */}
      <div className="page_section mb-8">
        <div className="flex flex-col w-full justify-between">
          <div className="flex flex-col w-full align-center gap-8">
            <div>
              {framework.welcome_image && <img 
                src={framework.welcome_image} 
                alt="placeholder" 
                className="w-full h-full object-cover rounded-sm cursor-pointer" 
                onClick={() => toggleOpenModal(framework.welcome_image)}
              />}
            </div>
            <div className="space-y-4">
              <Text element="h2" className="text-lg font-medium">{framework.welcome_title}</Text>
              <Text className="text-xs font-medium">{framework.welcome_text}</Text>
              <button 
                className="button inline_block bg-blue-300 text-sm font-medium"
                onClick={() => tabbarRef.current?.setActiveTab( 1)}
              >{api.trTxt(TrVar.ReadMore)}</button>
            </div>
          </div>
        </div>
      </div>
      {/* Third Section */}
      {(sections && sections.length > 0) && <div className="page_section mb-8">
        <Text className="text-lg font-medium">{api.trTxt(TrVar.Content)}</Text>
        <div className="flex flex-rows gap-10 mt-8 mb-2">
          {sections.map((el, index) => (
            <div key={index} className="flex flex-col border-left border-neutral-600  pl-4 space-y-5 justify-between" >
              <div>
              <img src="../../../assets/icon/Group 3575.png" alt="placeholder" className="h-12 w-12 "/>
              </div>
              <div>
                <Text element="h3" className="text-base font-medium">{el.name}</Text>
              </div>
              <Text color="gray" className="text-xs font-normal">{el.description}</Text>
              <div>
                <button 
                  className="button white k pl-0 pr-0 flex-shrink text-sm font-medium"
                  onClick={() => handleButtonClick(index)}
                >
                  {api.trTxt(TrVar.ReadMore)}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>}
    </>
  );
}

const LargeScreenLandingPage = ({ framework, sections, handleButtonClick, tabbarRef, toggleOpenModal }: LandingPageProps) => {

  if(!framework){
    return null;
  }
  
  return (
    <>
      {/* Second Section */}
      <div className="page_section mb-8">
        <div className="grid grid-cols-2 gap-2 w-full justify-between  h-full">
          <div className="flex-grow flex flex-col justify-center space-y-4 align-center h-full">
            <Text element="h2" className="text-2xl font-medium">{framework.welcome_title}</Text>
            <Text className=" text-base font-medium">{framework.welcome_text}</Text>
            <div className="order-2 " >
              {(sections && sections.length > 0) && <button 
                className="button inline_block bg-blue-300  font-medium text-sm"
                onClick={() => tabbarRef.current?.setActiveTab( 1)}
              >{api.trTxt(TrVar.ReadMore)}</button>}
            </div>
          </div>
          <div className="">
            <div className="order-1">
              {framework.welcome_image && <img 

                  onClick={() => toggleOpenModal(framework.welcome_image)}
                  src={framework.welcome_image} 
                  alt="placeholder" 
                  className="w-full h-full object-cover rounded-sm cursor-pointer" 
              />}
            </div>
          </div>
        </div>
      </div>
      {/* Third Section */}
      {(sections && sections.length > 0) && <div className="page_section mb-8">
        <Text element="h2" className="text-xl font-medium">{api.trTxt(TrVar.Content)}</Text>
        <div className="grid grid-cols-3 gap-4 mt-8">
          {sections.map((el, index) => (
            <div key={index} className="flex flex-col border-left border-neutral-600  pl-4 space-y-4 justify-between" >
              <div>
                <img 
                  src="../../../assets/icon/Group 3575.png" 
                  alt="placeholder" 
                  className="h-12 w-12 "
                />
              </div>
              <div>
                <Text element="h4" className="text-lg font-medium">{el.name}</Text>
              </div>
              <Text color="gray" className="text-sm font-medium">{el.description}</Text>
              <div>
                <button 
                  className="button white k pl-0 pr-0 flex-shrink text-sm font-medium"
                  onClick={() => handleButtonClick(index)}
                >
                  {api.trTxt(TrVar.ReadMore)}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>}
    </>
  );
}

const SmallScreenTabContent = (tabProps: TabContentProps) => {
  if(!tabProps.section.text){
    return null;
  }
  tabProps.section.text = tabProps.section.text.replace(/\n(?=\S)/g, "");
  tabProps.section.text = tabProps.section.text.replace(/\n/gi, "&nbsp;  \n")
  return (
    <div key={`program-${tabProps.section.name}`}>
      <div className="page_section mb-8">
        <Text element="h3" key={`content-header-${tabProps.section.name}-${tabProps.section.name}`}>{`${tabProps.section.name}`}</Text>
        <hr className="full-divider mt-8 mb-12" />
        
        <div className="flex flex-row w-full">
        {tabProps.section.text && <div className="md-container w-full">
          <Markdown 
              remarkPlugins={[remarkBreaks]} 
              rehypePlugins={[rehypeRaw]}
              key={`content-text-${tabProps.section.name}-${tabProps.section.name}`} 
              components={{
                h1: ({ node, children, ...props }) => {
                  return (
                    <h1 
                      className={`text-3xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h1>
                  );
                },
                h2: ({ node, children, ...props }) => {
                  return (
                    <h2 
                      className={`text-2xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h2>
                  );
                },
                h3: ({ node, children, ...props }) => {
                  return (
                    <h3 
                      className={`text-xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h3>
                  );
                },
                h4: ({ node, children, ...props }) => {
                  return (
                    <h4 
                      className={`text-lg font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h4>
                  );
                },
                h5: ({ node, children, ...props }) => {
                  return (
                    <h5 
                      className={`text-base font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h5>
                  );
                },
                p: ({ node, children, ...props }) => {
                  return (
                    <p 
                      className={`text-base font-normal text-neutral-400 [&>*]:break-all`} {...props}
                    >
                      {children}
                    </p>
                  );
                },
                ul: ({ node, ...props }) => {
                  return <ul className="list-disc list-inside text-base font-normal text-neutral-400" {...props} />;
                },
                ol: ({ node, ...props }) => {
                  return <ol className="list-decimal list-inside text-base font-normal text-neutral-400" {...props} />;
                },
                blockquote: ({ node, children, ...props }) => {
                  let childStyle = "child:text-lg child:italic child:font-medium child:leading-relaxed child:text-white childOfChild:hidden"
                  return (
                    <blockquote 
                      className={`p-4 my-4 border-l-4  bg-gray-50 border-gray-500 bg-transparent ${childStyle}`} {...props}
                    >
                      {
                        children
                      }
                    </blockquote>
                  );
                },
                img: ({ node, ...props }) => {
                  return <img 
                    onClick={()=>tabProps.toggleOpenModal(props.src || '')} 
                    className="h-auto rounded-xl py-4 max-h-40 object-contain cursor-pointer" {...props} 
                  />;
                }
              }}
            >
              {tabProps.section.text}
          </Markdown>
          </div>}
        </div>
      </div>
    </div>
  )
}

const LargeScreenTabContent = (tabProps: TabContentProps) => {
  if(!tabProps.section.text){
    return null;
  }
  tabProps.section.text = tabProps.section.text.replace(/&nbsp;/g, '');
  tabProps.section.text = tabProps.section.text.replace(/\n(?=\S)/g, "");
  tabProps.section.text = tabProps.section.text.replace(/\n/gi, "<span className='md-newline mb-4'>&nbsp;  \n</span>")
  return (
    <div key={`program-${tabProps.section.name}`}>
      <div className="page_section mb-8">
        <Text element="h3" key={`content-header-${tabProps.section.name}-${tabProps.section.name}`}>{`${tabProps.section.name}`}</Text>
        <hr className="full-divider mt-4 mb-10" />
        <div className="flex flex-row w-full">
        {tabProps.section.text && <div className={tabProps.section.image ? "md-container lg:w-[66%] xl:w-[66%] 2xl:w-[66%]" : "md-container lg:w-[66%] xl:w-[66%] 2xl:w-[66%]" }>
          <Markdown 
              remarkPlugins={[remarkBreaks]} 
              rehypePlugins={[rehypeRaw]}
              key={`content-text-${tabProps.section.name}-${tabProps.section.name}`} 
              components={{
                h1: ({ node, children, ...props }) => {
                  return (
                    <h1 
                      className={`text-3xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h1>
                  );
                },
                h2: ({ node, children, ...props }) => {
                  return (
                    <h2 
                      className={`text-2xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h2>
                  );
                },
                h3: ({ node, children, ...props }) => {
                  return (
                    <h3 
                      className={`text-xl font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h3>
                  );
                },
                h4: ({ node, children, ...props }) => {
                  return (
                    <h4 
                      className={`text-lg font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h4>
                  );
                },
                h5: ({ node, children, ...props }) => {
                  return (
                    <h5 
                      className={`text-base font-medium text-white mb-4`} {...props}
                    >
                      {children}
                    </h5>
                  );
                },
                p: ({ node, children, ...props }) => {
                  return (
                    <p 
                      className={`text-base font-normal text-neutral-400 [&>*]:break-all`} {...props}
                    >
                      {children}
                    </p>
                  );
                },
                ul: ({ node, ...props }) => {
                  return <ul className="list-disc list-inside text-base font-normal text-neutral-400" {...props} />;
                },
                ol: ({ node, ...props }) => {
                  return <ol className="list-decimal list-inside text-base font-normal text-neutral-400" {...props} />;
                },
                blockquote: ({ node, children, ...props }) => {
                  let childStyle = "child:text-lg child:italic child:font-medium child:leading-relaxed child:text-white childOfChild:hidden"
                  return (
                    <blockquote 
                      className={`p-4 my-4 border-l-4  bg-gray-50 border-gray-500 bg-transparent ${childStyle}`} {...props}
                    >
                      {
                        children
                      }
                    </blockquote>
                  );
                },
                img: ({ node, ...props }) => {
                  return <img 
                    className="w-full h-auto rounded-xl py-4 max-h-80 object-contain cursor-pointer" 
                    {...props} 
                    onClick={()=>tabProps.toggleOpenModal(props.src || '')} 
                  />;
                }
                
              }}
            >
              {tabProps.section.text}
          </Markdown>
          </div>}
          {tabProps.section.image && <div className="flex-shrink h-full justify-center pl-8 lg:w-[34%] xl:w-[34%] 2xl:w-[34%] hidden lg:block xl:block 2xl:block">
            <img 
              src={tabProps.section.image} 
              alt="placeholder" 
              className="w-full h-auto rounded-sm max-h-60 object-contain cursor-pointer"
              onClick={() => tabProps.toggleOpenModal(tabProps.section.image)}
            />
            <div className="border-left border-neutral-600 pl-4 mt-4">
              <Text color="gray" size="xxs">{tabProps.section.image_description}</Text>
            </div>
          </div>}
        </div>
        <div className="w-full flex flex-row justify-between">
          {(tabProps.index > 0) && <div className="flex justify-end items-center py-4">
            <button 
              className="flex items-center text-lg font-semibold"
              onClick={() => tabProps.handleButtonClick(tabProps.index-2)}
            >
               <IoIosArrowBack className="mr-2" /> Back
            </button>
          </div>}
          {(tabProps.index < (tabProps.numSections - 1)) && <div className="flex justify-end items-center py-4">
            <button 
              className="flex items-center text-lg font-semibold"
              onClick={() => tabProps.handleButtonClick(tabProps.index)}
            >
              Next <IoIosArrowForward className="ml-2" /> 
            </button>
          </div>}
        </div>
      </div>
    </div>
  )
}

const ClientProgram: React.FC<ComponentProps> = (props) => {
  
  // Component logic and hooks here (if necessary)
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [framework, setFramework] = useState<FrameworkInterface>();
  const [sections, setSections] = useState<FrameworkSectionInterface[]>([]);
  const [items, setItems] = useState<ItemInterface[]>([]);
  const breadcrumbs: Array<any> = []
  const tabbarRef = React.useRef<TabBarRef>(null);
  
  let name = "";

  useEffect(() => {
    getProgram();
    if (props.client) {
      breadcrumbs.push({name: api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: props.client.firstName, link: '', linkFunc: props?.close})
      name = props.client ? api.trTxt(TrVar.CustomerFramework) : api.trTxt(TrVar.YourProgram)
    }
  }, []);

  useEffect(() => {
    const landingPage = {
      title: 'Översikt',
      content: renderLandingPage()
    }
    const newItems = [landingPage];
    if (framework) {
      sections.forEach((section, index) => {
          newItems.push({
            title: section.name,
            content: renderTabContent(section, index+1, sections.length+1),
          });
      });
    }
    setItems(newItems);
  }, [sections]); 
  
  const spinStyle = {
    animation: 'spin 2s linear infinite'
  };

  // Function to handle button click
  const handleButtonClick = (index: number) => {
    tabbarRef.current?.setActiveTab(index + 1);
  };

  const renderLandingPage = (): JSX.Element => {
    return (
      <>
        <div 
          className="lg:hidden xl:hidden 2xl:hidden"
        >
          <SmallScreenLandingPage 
            toggleOpenModal={(image) => {
              setOpenModal(!openModal);
              setModalImage(image);
            }}
            framework={framework} 
            sections={sections} 
            handleButtonClick={handleButtonClick} 
            tabbarRef={tabbarRef}

          />
        </div>
        <div className="hidden lg:block xl:block 2xl:block">
          <LargeScreenLandingPage 
            toggleOpenModal={(image) => {
              setOpenModal(!openModal);
              setModalImage(image);
            }}
            framework={framework} 
            sections={sections} 
            handleButtonClick={handleButtonClick} 
            tabbarRef={tabbarRef}
          />
        </div>
      </>
    );
  };

  const renderTabContent = (section: FrameworkSectionInterface, index: number, numSections: number): JSX.Element => {
    return (
      <>
        <div className="lg:hidden xl:hidden 2xl:hidden">
          <SmallScreenTabContent 
            toggleOpenModal={(image) => {
              setOpenModal(!openModal);
              setModalImage(image);
            }}
            handleButtonClick={handleButtonClick}
            section={section} 
            index={index}
            numSections={numSections}
          />
        </div>
        <div className="hidden lg:block xl:block 2xl:block">
          <LargeScreenTabContent 
            index={index}
            numSections={numSections}
            section={section} 
            handleButtonClick={handleButtonClick}
            toggleOpenModal={(image) => {
              setOpenModal(!openModal);
              setModalImage(image);
            }}
          />
        </div>
      </>
    );
  };

  const getProgram = () => {
    const url = props.client ? 'coach_client_framework/' + props.client.id : 'client_framework'
    api.get(url).then(response => {
      console.log("response.json", response.json)
      setFramework(response.json.framework);

      setSections(response.json.framework_sections);
    })
  }

  if(!framework) {
    return<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><FaSpinner style={spinStyle} size={50} /></div>
  }

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>
      <div className="flex flex-col justify-center h-full max-md:mx-4 md:ml-40 md:mr-28 lg:ml-40 mt-20 pb-16">
        <div className="page_section mb-0" style={{borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', paddingBottom: '0px'}}>
          <div className="flex flex-col">
            <div className="lg:hidden xl:hidden 2xl:hidden">
              <SmallScreenLayout {...framework} />
            </div>
            <div className="hidden lg:block xl:block 2xl:block">
              <LargeScreenLayout {...framework}/>
            </div>
          </div>
        </div>
        <div className="rounded-t-none mt-0" style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px', paddingTop: '0px'}}>
          <TabBar ref={tabbarRef} items={items} theme={customTheme}/>
        </div>
      </div>
      <Modal 
        onClose={() => {
          console.log("Close modal")
          setOpenModal(false)
        }}
        show={openModal} 
        size="full" 
        position={"center"} 
        theme={modalCustomTheme} 
        popup 
        dismissible
        >
          <div className="flex flex-row h-auto flex-grow" onClick={() => setOpenModal(false)}>
            <div className="w-full flex justify-end">
              <IoMdClose  className="text-white xl:mr-12 text-2xl hover:text-[1.7rem] hover:font-bold cursor-pointer" />
            </div>
            <img 
                src={modalImage} 
                alt="placeholder" 
                className="w-full rounded-sm h-full object-contain max-h-[90vh]"
            />
          </div>
      </Modal>

    </>
  );
};

export default ClientProgram;

