import React from 'react'
import "./Tasks.css"
import Api from "../../services/Api";

interface ComponentProps {
  close: Function
}

interface ComponentState {}

export default class CardHelpModal extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  render() {

    return (
      <div className="help_modal relative">
        <div className="flex flex-col justify-center " style={{ 'height': '100%' }}>
          <img onClick={() => this.props.close()} className='absolute modal_close'
               src="/assets/icon/white/cross.svg" height={'20px'} alt="" />
          <div style={{ maxWidth: '598px', margin: '0 auto' }}>
            <h2>{this.api.lang === 'sv' ? 'Så gör du övningen:' : 'This is how you do the exercise:'}</h2>
            <div className='top_margin'>
              {this.api.lang === 'sv' ?
                'Varje övning innehåller 4-5 frågor. Ta dig tid att reflektera kring varje fråga och skriv sedan ner din tankar. Du kan fortsätta vid ett senare tillfälle om du inte hinner färdigt.'
                :
                `Each exercise contains 4-5 questions. Take time to reflect on each question and then write down your thoughts. If you don't have time to finish, feel free to come back later.`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                'Så är frågorna utformade:'
                :
                `How the questions are designed:`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Frågorna i varje övning följer coachmetodikens struktur:'
                :
                `The questions in each exercise follow the structure of the coaching methodology:`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                '1. Reflektion'
                :
                `1. Reflection`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Börja reflektera över din nuvarande situation.'
                :
                `Start reflecting on your current situation.`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                '2. Önskeläge'
                :
                `2. Desired state`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Hur skulle du önska att saker och ting vore om du fick tänka fritt?'
                :
                `How would you like things to be if you could think freely?`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                '3. Beteende'
                :
                `3. Behaviour`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Vad kan du göra för att skapa förändring?'
                :
                `What can you do to initiate change?`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                '4. Insikt'
                :
                `4. Insights`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Vad tar du med dig och vad kan du lära dig om detta?'
                :
                `What do you take with you and what can you learn from this?`
              }
            </div>            
          </div>    
        </div>
      </div>
    )
  }
}
