import React from 'react';
import Api from '../../services/Api';
import Loading from '../Loading';
import Breadcrumbs from "../menu/Breadcrumbs";
import {
  CoachQuestionInterface,
  FocusAreaInterface,
  FocusAreaSettingsInterface,
  FocusMeasureInterface, PotentialQuestionInterface,
} from "../tasks/interfaces";
import {formatSoon} from "../../helpers/dateFunctions";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  clientId: number
  navigation: any
}

interface ComponentState {
  measures: Array<FocusMeasureInterface>
  measure: FocusMeasureInterface
  focusAreaSettings: Array<FocusAreaSettingsInterface>
  clientName: string
  measureId: number
  loaded: boolean
}

export default class ClientTasks2 extends React.Component<ComponentProps, ComponentState> {
  state: Readonly<ComponentState> = {
    measures: [],
    measure: {} as FocusMeasureInterface,
    focusAreaSettings: [],
    loaded: false,
    clientName: '',
    measureId: 0
  };

  private api = Api.getInstance();

  private getData = () => {
    this.api.get('coach_client_tasks2/' + this.props.clientId, ['client_not_found'])
    .then((response) => {
      this.setState({
        measures: response.json.measures,
        measure: response.json.measures[0],
        measureId: response.json.measureId,
        focusAreaSettings: response.json.focusAreaSettings,
        clientName: response.json.name,
        loaded: true
      });
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({loaded: true})
      }
    });
  };

  componentDidMount() {
    this.getData();
  }

  getFocusAreaSettings = (name: string) => {
    for (const fas of this.state.focusAreaSettings) {
      if (fas.areaName === name) {
        return fas
      }
    }
    return undefined
  }

  getAnsweredQuestions = (potentialQuestions: Array<PotentialQuestionInterface>) => {
    const answeredQuestions = []
    for (const pq of potentialQuestions) {
      if (pq.coachQuestions[0].answer.length > 0) {
        answeredQuestions.push(pq)
      }
    }
    return answeredQuestions
  }

  showPotentialQuestions = (potentialQuestions: Array<PotentialQuestionInterface>) => {
    const answeredQuestions = this.getAnsweredQuestions(potentialQuestions)
    return (
      <>
        {
          answeredQuestions.map((potentialQuestion: PotentialQuestionInterface, i) => {
            return (
              <div key={"pq_" + i} className={"top_margin border_box"}>
                <h3>{potentialQuestion.question.question}</h3>
                {
                  potentialQuestion.coachQuestions.map((coachQuestion: CoachQuestionInterface, i) => {
                    return (
                      <div key={"coach_question_" + coachQuestion.id + '_' + i}>
                        <p className="mt-4 font_small">{coachQuestion.question}</p>
                        <p className="font_small gray whitespace-pre-line">{coachQuestion.answer}</p>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </>
    )
  }

  showFocusArea = (focusArea: FocusAreaInterface) => {
    const focusAreaSettings = this.getFocusAreaSettings(focusArea.focusArea)
    if (!focusAreaSettings) {
      return (
        <p className="mt-4">{this.api.trTxt(TrVar.NotStarted)}</p>
      )
    }
    return (
      <>
        <div className="mt-4">
            <p>
              {this.api.trTxt(TrVar.Started)}
              &nbsp;{formatSoon(focusAreaSettings.createdAt, this.api.lang)}
            </p>
            <p>
              {focusAreaSettings.lengthInWeeks}
              &nbsp;{this.api.trTxt(TrVar.WeeksPlanned).toLowerCase()}
            </p>
        </div>
        {this.showPotentialQuestions(focusArea.potentialQuestions)}
      </>
    )
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }

    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.DevelopmentAreas)}
                     breadcrumbs={[
                       {name: this.api.trTxt(TrVar.MyClients), link:'/app/coach/clients'},
                       {name: this.state.clientName, link:'/app/coach/clients/' + this.props.clientId}
                     ]}/>

        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.DevelopmentAreas)}</h1>
          <p className="top_margin">
            {
              this.api.trTxt(TrVar.HereYouSeeTheCoachQuestions)
            }
            &nbsp;{this.state.clientName}&nbsp;
            {
              this.api.trTxt(TrVar.HasWorkedWith).toLowerCase()
            }

          </p>
        </div>

        {
          this.state.measure.focusAreas.map((focusArea: FocusAreaInterface) => {
            return (
              <div key={"focus_" + focusArea.focusArea} className="page_section top_margin">
                <div className="flex items-center">
                  <img src={"/assets/focusArea/area_icon_" + focusArea.focusArea + ".svg"}
                       style={{height: "50px"}} alt="area"/>
                  <h2 className="ml-4">{focusArea.name}</h2>
                </div>

                {this.showFocusArea(focusArea)}
              </div>
            )
          })
        }
      </div>
    )
  }
}
