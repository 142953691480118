import React from 'react';
import Api from "../../services/Api";
import Breadcrumbs from "../menu/Breadcrumbs";
import { changeUrl } from "../../services/Navigation";
import './ClientPurchase.css';
import { TrVar } from '../../services/translate';

interface ComponentProps{
  isFree: boolean
  isIncognito: boolean
}

const ClientPurchase = (props: ComponentProps) => {
  const api = Api.getInstance();
  
  return (
    <>
      <Breadcrumbs name={api.trTxt(TrVar.Upgrade)} />
      {props.isFree ?
        <div className="page_content">
          <div style={{ paddingTop: '80px', paddingBottom: '80px' }} className="client_purchase_bg page_section">
            <div className='client_purchase_upgrade'>
              <div style={{ fontWeight: '500' }}>{api.trTxt(TrVar.UpgradeTo)} <b>{api.trTxt(TrVar.AccessPrivate)}</b></div>
              <h2 style={{ marginBottom: '20px', fontWeight: '600' }}>{api.trTxt(TrVar.ReachYourPotential)}</h2>
              <div className='client_purchase_bar' />
              <h4 style={{ fontWeight: '600', marginBottom: '8px' }} className="top_margin">
                {api.trTxt(TrVar.ThisIsIncluded)}
              </h4>
              <div style={{ marginBottom: '5px' }} className='flex'>
                <div className='client_purchase_dot' />
                <div>
                  {api.trTxt(TrVar.AllDevelopmentAreas)}
                </div>
              </div>
              <div style={{ marginBottom: '5px' }} className='flex'>
                <div className='client_purchase_dot' />
                <div>
                  {api.trTxt(TrVar.BehaviourAnalysis)}
                </div>
              </div>
              <div style={{ marginBottom: '5px' }} className='flex'>
                <div className='client_purchase_dot' />
                <div>
                  {api.trTxt(TrVar.ContinualMeasurementOfDevelopment)}
                </div>
              </div>
              <div style={{ marginBottom: '20px' }} className='flex'>
                <div className='client_purchase_dot' />
                <div>
                  {api.trTxt(TrVar.IndividualAccountOverview)}
                </div>
              </div>
              <div className='client_purchase_bar top_margin' />
              <h3 className='top_margin'>{api.trTxt(TrVar.Only)} <span style={{ color: '#BC80F0' }}>49 {api.trTxt(TrVar.Sek).toLowerCase()}</span> {api.trTxt(TrVar.PerMonth)}</h3>
              {
                props.isIncognito ? (
                  <>
                    <div className="mt-10">
                      <p>
                        {api.trTxt(TrVar.YouHaveNotCreatedAnAccountYet)}
                      </p>
                      <div onClick={() => changeUrl('/app/client/create_account?from=purchase')}
                        className="top_margin button green inline_block">{api.trTxt(TrVar.CreateAccount)}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-10">
                      <div onClick={() => changeUrl('/app/client/purchase-klarna')}
                          className="button green inline_block">{api.trTxt(TrVar.IWantToUpgrade)}</div>
                    </div>
                    <div className="mt-10 flex justify-center">
                      <img src="/assets/icon/klarna_button.svg" alt="Klarna" />
                      <div className="ml-2 font_smaller">
                        {api.trTxt(TrVar.PayWithKlarna)}
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
        :
        <div className="page_content">
          <div className="page_section">
            <h1>{api.trTxt(TrVar.YouHaveAlreadyUpgraded)}</h1>
          </div>
        </div>
      }
    </>
  )
}

export default ClientPurchase;
