import {
  IonContent,
  IonPage,
} from '@ionic/react'
import React from 'react'
import Api from "../services/Api"
import {DisaInterface} from "../interfaces/Disa"
import {MeetingInterface} from "../interfaces/Meeting"
import WebSocketClient from "../services/WebSocketClient"
import CoachOverview from "../components/coach/CoachOverview";
import CoachClients from "../components/coach/CoachClients";
import CoachBookings from "../components/coach/CoachBookings";
import CoachMeasureMenu from "../components/coach/CoachMeasureMenu";
import CoachChats from "../components/coach/CoachChats";
import {urlChange} from "../redux/actions";
import {connect, ConnectedProps} from "react-redux";
import {CoachInterface} from "../interfaces/Coach";
import {Redirect} from "react-router-dom";
import Loading from "../components/Loading";
import CoachClientCard from "../components/coach/CoachClientCard";
import Menu from "../components/menu/Menu";
import Support from '../components/support/Support'
import Account from '../components/account/Account'
import CoachProfile from '../components/coach/CoachProfile'
import CoachExperience from '../components/coach/CoachExperience'
import DetailedBehaviorAnalysis from '../components/detailed_behavior_analysis/DetailedBehaviorAnalysis'
import BehaviourEdit from "../components/behaviour/BehaviourEdit";
import BehaviourView from "../components/behaviour/BehaviourView";
import Breadcrumbs from "../components/menu/Breadcrumbs";
import NotFoundComponent from "./404/NotFoundComponent";
import CoachAgreement from "../components/info/CoachAgreement";
import CoachMeetingHistory from "../components/coach/CoachMeetingHistory";
import { TrVar } from "../services/translate";
import * as Sentry from "@sentry/react";

const mapState = (state: any) => ({
  navigation: state.navigation
})

const mapDispatch = {
  urlChange
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface ComponentState {
  payType: number
  personId: number
  coach: CoachInterface
  disa: Array<DisaInterface>
  meetings: Array<MeetingInterface>
  loggedIn: boolean
  showLogin: boolean
  width: number
  menuOpen: boolean
  isClient: boolean
  badges: any
  sessionOk: boolean
}

class Coach extends React.Component<PropsFromRedux, ComponentState>  {

  private api = Api.getInstance()
  private webSocketClient = WebSocketClient.getInstance()
  private scrollRef: any

  constructor(props: PropsFromRedux) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      payType: 0,
      personId: -1,
      coach: {} as CoachInterface,
      disa: [],
      meetings: [],
      loggedIn: this.api.loggedIn,
      showLogin: false,
      width: 375,
      menuOpen: false,
      isClient: false,
      badges: {},
      sessionOk: false,
    }
  }

  componentDidMount() {
    document.title = "Zebrain: Coach"
    this.setDimensions()
    window.addEventListener("resize", this.setDimensions)
    this.webSocketClient.webSocket?.addEventListener("message", this.messageListener)
    window.addEventListener('popstate', this.backListener)
    if (this.api.loggedIn) {
      this.getData()
    } else {
      this.setState({showLogin: true})
    }
  }

  componentDidUpdate(prevProps: PropsFromRedux, prevState: ComponentState, snapshot: any) {
    if (prevProps.navigation.url !== this.props.navigation.url) {
      this.scrollToTop()
      if (this.props.navigation.page === 'chat') {
        this.setState({badges: 0})
      }
    }
  }

  private scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.getScrollElement().then((el: HTMLElement) => {
        this.scrollRef.current.scrollToTop();
      });
    }
  };

  backListener = (event: PopStateEvent)  => {
    if (event.currentTarget) {
      // @ts-ignore
      this.props.urlChange(event.currentTarget.location.pathname)
    }
  }

  messageListener = (event: any) => {
    const mess = JSON.parse(event.data)
    if (mess.type === 'coach') {
      if (mess.badges) {
        this.setState({badges: mess.badges})
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDimensions)
    this.webSocketClient.webSocket?.removeEventListener("message", this.messageListener)
  }

  setDimensions = () => {
    this.setState({width: window.innerWidth})
  }

  private getData = () => {
    this.api.get('coach?badges=1', ['coach_required']).then(response => {
      if(response && response.json && response.json.personId) { 
        Sentry.setUser({ id: response.json.personId.toString() });
      }
      this.setState({
        personId: response.json.personId,
        coach: response.json.coach,
        meetings: response.json.meetings,
        isClient: response.json.isClient,
        badges: response.json.badges,
        sessionOk: true,
      })
    }).catch(response => {
      if (response.isExpectedError) {
        window.location.href = '/app/coach_onboarding'
      }
    })
  }

  public exitLogin = (loggedIn: boolean) => {
    this.setState({showLogin: false, loggedIn: loggedIn})
    if (loggedIn) {
      this.getData()
    }
  }

  showMenu = (open: boolean) => {
    this.setState({menuOpen: open})
  }

  localRoute = () => {
    if (this.props.navigation.client > 0) {
      return (<CoachClientCard navigation={this.props.navigation}/>)
    } else if (this.props.navigation.page === 'overview' || this.props.navigation.page === '') {
      return (<CoachOverview />)
    } else if (this.props.navigation.page === 'measure') {
      return (<CoachMeasureMenu/>)
    } else if (this.props.navigation.page === 'clients') {
      return (<CoachClients/>)
    } else if (this.props.navigation.page === 'bookings') {
      return (<CoachBookings reload={this.getData} navigation={this.props.navigation}/>)
    } else if (this.props.navigation.page === 'meeting_history') {
      return (<CoachMeetingHistory reload={this.getData} navigation={this.props.navigation}/>)
    } else if (this.props.navigation.page === 'behaviour') {
      if (this.props.navigation.sub1 === 'edit') {
        return (
          <>
            <Breadcrumbs breadcrumbs={[
              {name: this.api.trTxt(TrVar.YourBehaviourProfile), link: '/app/coach/behaviour/view'},
            ]}
                         name={this.api.trTxt(TrVar.Edit)}/>
            <div className="page_content">
              <div className="page_section">
                <BehaviourEdit cancelButton={true} who="coach" saveText={this.api.trTxt(TrVar.Save)}/>
              </div>
            </div>
          </>
        )
      } else if (this.props.navigation.sub1 === 'new') {
        return (
          <>
            <Breadcrumbs breadcrumbs={[{name: this.api.trTxt(TrVar.YourBehaviourProfile), link: '/app/coach/behaviour/view'}]}
                         name={this.api.trTxt(TrVar.NewMeasurement)} />
            <div className="page_content">
              <div>
                <BehaviourEdit cancelButton={false} who="coach" saveText={this.api.trTxt(TrVar.Save)}/>
              </div>
            </div>
          </>
        )
      } else {
        return (<BehaviourView who="coach"/>)
      }
    } else if (this.props.navigation.page === 'behaviour_styles') {
      return (<DetailedBehaviorAnalysis who="coach"/>)
    } else if (this.props.navigation.page === 'chat') {
      return (<CoachChats navigation={this.props.navigation}/>)
    } else if (this.props.navigation.page === 'support') {
      return (<Support 
        isCoach={true} 
        otherFaqList={[
          {
            "title": this.api.trTxt(TrVar.FaqTechnicalIssuesWithPlatform),
            "content": this.api.trTxt(TrVar.FaqTechnicalSupport)
          }
        ]}
        yourClientsFaqList={[
          {
            "title": this.api.trTxt(TrVar.FaqClientBase),
            "content": this.api.trTxt(TrVar.FaqClientBaseExplanation)
          },
          {
            "title": this.api.trTxt(TrVar.FaqMeetingClients),
            "content": this.api.trTxt(TrVar.FaqDigitalCoaching)
          },
          {
            "title": this.api.trTxt(TrVar.FaqClientMeetingDuration),
            "content": this.api.trTxt(TrVar.FaqMeetingDurationDetails)
          },
          {
            "title": this.api.trTxt(TrVar.FaqContactWithClients),
            "content": this.api.trTxt(TrVar.FaqClientCommunication)
          },
          {
            "title": this.api.trTxt(TrVar.FaqExtendingClientLicense),
            "content": this.api.trTxt(TrVar.FaqLicenseExtensionProcess)
          },

        ]}
        coachFaqList={[
          {
            "title": this.api.trTxt(TrVar.FaqICFAccreditedCoach),
            "content": this.api.trTxt(TrVar.FaqICFCoachLevelRequirement)
          },
          {
            "title": this.api.trTxt(TrVar.FaqICFCertificationValidity),
            "content": this.api.trTxt(TrVar.FaqValidICFCertificationProcess)
          },
          {
            "title": this.api.trTxt(TrVar.FaqRelationshipWithZebrain),
            "content": this.api.trTxt(TrVar.FaqZebrainPartnershipDetails)
          },
          {
            "title": this.api.trTxt(TrVar.FaqCostToJoinZebrain),
            "content": this.api.trTxt(TrVar.FaqJoiningZebrainIsFree)
          },
          {
            "title": this.api.trTxt(TrVar.FaqContractCommitmentDuration),
            "content": this.api.trTxt(TrVar.FaqStandardContractTerms)
          },
          {
            "title": this.api.trTxt(TrVar.FaqEmploymentFormAtZebrain),
            "content": this.api.trTxt(TrVar.FaqEmploymentRequirements)
          },
          {
            "title": this.api.trTxt(TrVar.FaqZebrainInside),
            "content": this.api.trTxt(TrVar.FaqZebrainInsideExplanation)
          },
          {
            "title": this.api.trTxt(TrVar.FaqInvoiceSubmission),
            "content": this.api.trTxt(TrVar.FaqInvoiceProcess)
          },
        ]}
      
      />)
    } else if (this.props.navigation.page === 'my_account') {

      if (this.props.navigation.sub1 === 'agreement') {
        return (
          <>
            <Breadcrumbs name={this.api.trTxt(TrVar.CoachAgreement)} breadcrumbs={[
              {name: this.api.trTxt(TrVar.MyAccount), link: '/app/coach/my_account'},
            ]}/>
            <div className="page_content">
              <div className="page_section">
                <CoachAgreement hasCoach={true}/>
              </div>
            </div>
          </>
        )

      } else {
        return (<Account who='coach' />)
      }

    } else if (this.props.navigation.page === 'my_profile') {
      return (<CoachProfile onboarding={false} />)
    } else if (this.props.navigation.page === 'about_me') {
      return (<CoachExperience onboarding={false} />)
    } else {
      return (<NotFoundComponent/>)
    }
  }

  render() {
    if (!this.state.sessionOk) {
      return (
        <Loading/>
      )
    }

    if (!this.state.coach.isOnboarded) {
      return (
        <Redirect to="/app/coach_onboarding"/>
      )
    }

    return (
      <IonPage>
        <Menu coach={this.state.coach} unreadChats={this.state.badges} isClient={this.state.isClient}/>
        <IonContent scrollEvents={true} ref={this.scrollRef} className="app_bg">
          <div className="flex">
            {
              this.localRoute()
            }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Coach)
