import React from "react";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import Api from "../../services/Api";
import {MeetingInterface} from "../../interfaces/Meeting";
import {formatSoon} from "../../helpers/dateFunctions";
import MeetingHistory from "../MeetingHistory";
import {changeUrl} from "../../services/Navigation";
import "./ClientBookings.css"
import VisionBoard from "../visionboard/VisionBoard";
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import { TrVar } from "../../services/translate";
import { TrArray } from "../../services/translate_arrays";

interface ComponentProps {
  navigation: any
}

interface ComponentState {
  previousMeetings: Array<MeetingInterface>
  visionBoardId: number
  meeting: MeetingInterface
  completedChats: any
  error: string
  loaded: boolean
}

export default class ClientMeetingHistory extends React.Component<ComponentProps, ComponentState> {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    previousMeetings: [],
    visionBoardId: 0,
    meeting: {} as MeetingInterface,
    completedChats: {},
    error: '',
    loaded: false,
  }

  componentDidMount() {
    this.getMeetings()
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'client_meeting_history'}
    }
    this.api.post('client_log_event', data).then()
  }

  getMeetings = () => {
    this.api.get('client_meeting_history').then(response => {
      this.setState({
        previousMeetings: response.json.previousMeetings,
        completedChats: response.json.completedChats,
        loaded: true
      })
    })
  }

  showMeetingStatus = (meetingType: number) => {
    if (meetingType < 1 || meetingType > 6) {
      return (<></>)
    }
    return (
      <div className="top_margin error_red">{this.api.trArr(TrArray.MeetingType)[meetingType]}</div>
    )
  }

  goToMenu = () => {
    changeUrl('/app/client/meeting_history')
  }

  goToMenuAndReload = () => {
    changeUrl('/app/client/meeting_history')
    this.getMeetings()
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    if (this.props.navigation.sub1 === 'meeting') {
      return (
          <MeetingHistory meetingKey={this.props.navigation.sub2} close={this.goToMenu}/>
      )
    } else if (this.props.navigation.sub1 === 'visionboard') {
      const visionBoardId = parseInt(this.props.navigation.sub2)
      return (
        <div className="page_content pt-4 p-h-full">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.PreviousMeetings), link: '', linkFunc: this.goToMenuAndReload}]
          } name="VisionBoard"/>
          <VisionBoard meetingId={visionBoardId}/>
        </div>
      )
    }
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.PreviousMeetings)}/>

        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.PreviousMeetings)}</h1>

          {
            this.state.previousMeetings.length === 0 &&
              <div className="border_box top_margin">
                {this.api.trTxt(TrVar.YouHaveNotHadAnyMeetingsYet)}
              </div>
          }

          {
            this.state.previousMeetings.map((meeting: MeetingInterface) => {
              const visionBoardText = meeting.visionBoardData ?
                this.api.trTxt(TrVar.SeeVisionboard)
                :
                ""
              return (
                <div key={"next_meeting_" + meeting.id} className="border_box top_margin">
                  <div className="flex items-center justify-between">
                    <img alt="coach" src={meeting.coach.picture} className="picture_small round" />
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12" sizeMd="4" className="flex items-center">
                          <h4>
                            {meeting.coach.firstName} {meeting.coach.lastName}
                            {meeting.guestName ? ' + ' + meeting.guestName : ''}
                          </h4>
                        </IonCol>
                        <IonCol size="12" sizeMd="4">
                          <div>
                            <div className="flex items-center top_margin">
                              <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg" />
                              {formatSoon(meeting.start, this.api.lang)}, {meeting.length} min
                            </div>
                          </div>
                          {this.showMeetingStatus(meeting.meetingType)}
                        </IonCol>
                        <IonCol size="12" sizeMd="4">
                          {
                            this.state.completedChats[meeting.id] ? (
                              <div className="cursor-pointer" onClick={() => changeUrl('/app/client/meeting_history/meeting/' + meeting.meetingKey)}>
                                <p className="underline show_small">{this.api.trTxt(TrVar.SeeChat)}</p>
                                <p className="underline hide_small text_right">{this.api.trTxt(TrVar.SeeChat)}</p>
                              </div>
                            ) : (
                              <div>
                                
                              </div>
                            )
                          }
                          <div className="cursor-pointer"
                               onClick={() => changeUrl('/app/client/meeting_history/visionboard/' + meeting.id)}>
                            <p className="underline show_small">{visionBoardText}</p>
                            <p className="underline hide_small text_right">{visionBoardText}</p>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
