import React from 'react';
import Api from '../../services/Api';
import Text from '../Text/Text'

interface ComponentProps {
  progress: Function
}

const ClientMeasureIntro = (props: ComponentProps) => {
  const api = Api.getInstance();

  return (
    <div className='page_section'>
      <h1>{api.lang === 'sv' ? 'Potentialmätning' : 'Measurement of potential'}</h1>
      <div className='page_bar' />
      <div className="flex max-sm:flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
        <div className="w-1/2">
          <div style={{ maxWidth: '478px' }}>
            {api.lang === 'sv' ?
              <>
                <h3>Du får svar på:</h3>
                <ul className="color_neutral_400 list-disc pt-2 translate-x-6" style={{ marginLeft: '-10px' }}>
                  <li>Din potential inom olika områden</li>
                  <li>Hur mycket av din potential du använder</li>
                  <li>Din oanvända potential</li>
                </ul>
                <Text element="div" color="gray" className="top_margin text-neutral-900">
                  Baserat på dina svar kommer du att få rekommenderade
                  utvecklingsområden som guidar dig vidare i din utveckling.
                </Text>
                <div className="top_margin hide_small">
                  <div onClick={() => props.progress(2)} className="top_margin button inline_block green">
                    Gör potentialmätning
                  </div>
                </div>
              </>
              :
              <>
                <h3>These are the answers you'll receive:</h3>
                <ul className="pl-12 list-disc pt-2" style={{ marginLeft: '-10px' }}>
                  <li>Your potential within different areas</li>
                  <li>How much of your potential you're using</li>
                  <li>Your unused potential</li>
                </ul>
                <Text element="div" color="gray" className="top_margin text-neutral-900">
                  Based on your answers you will get recommended areas of development guiding you in your development.
                </Text>
                <div className="top_margin hide_small">
                  <div onClick={() => props.progress(2)} className="top_margin button inline_block green">
                    Start measurement of potential
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className="w-1/2 r_top_margin">
          <img className='onboarding_img_mobile' src="/assets/illustration/development.svg" alt="development illustration" />
        </div>
      </div>
      <div className="top_margin show_small">
        <div onClick={() => props.progress(2)} className="top_margin button mobile_size_btn inline_block green">
          {api.lang === 'sv' ? 'Gör potentialmätning' : 'Start measurement of potential'}
        </div>
      </div>
    </div>
  );
}

export default ClientMeasureIntro
