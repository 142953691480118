import {URL_CHANGE} from '../actionTypes'
const initialState = {
  url: '',
  who: '',
  page: '',
  sub1: '',
  sub2: '',
  sub3: '',
  sub4: '',
  client: 0,
  args: ''
}

export default function parseUrl(state = initialState, action: any) {
  switch (action.type) {
    case URL_CHANGE: {
      const uri_args = action.payload.split('?')
      const urlParts = uri_args[0].split('/')
      let args = uri_args.length > 1 ? uri_args[1] : ''
      let client = 0
      let who = ''
      let page = ''
      let sub1 = ''
      let sub2 = ''
      let sub3 = ''
      let sub4 = ''
      // If coach is looking at client, cut out .../clients/clientId from urlParts to make navigation the same for
      // coach and client (except for clientId)
      if (urlParts.length > 4 && urlParts[2] === 'coach' && urlParts[3] === 'clients') {
        client = parseInt(urlParts[4])
        urlParts.splice(3, 2)
      }
      if (urlParts.length > 2) {
        who = urlParts[2]
        if (urlParts.length > 3) {
          page = urlParts[3]
          if (urlParts.length > 4) {
            sub1 = urlParts[4]
            if (urlParts.length > 5) {
              sub2 = urlParts[5]
              if (urlParts.length > 6) {
                sub3 = urlParts[6]
                if (urlParts.length > 7) {
                  sub4 = urlParts[7]
                }
              }
            }
          }
        }
      }
      return {
        url: action.payload,
        who: who,
        page: page,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
        sub4: sub4,
        client: client,
        args: args
      }
    }
    default:
      return state
  }
}
