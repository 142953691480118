import React from 'react';
import Api from '../../services/Api';
import Loading from '../Loading';
import { CoachInterface } from '../../interfaces/Coach';
import { ClientInterface } from '../../interfaces/Client';
import { changeUrl } from '../../services/Navigation';
import Breadcrumbs from "../menu/Breadcrumbs";
import { 
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import {MeetingInterface, MeetingType} from "../../interfaces/Meeting";
import {formatSoon} from "../../helpers/dateFunctions";
import {MeetingSchemaInterface} from "../meetingSchema/interface";
import MeetingSchema from "../meetingSchema/MeetingSchema";
import { TrVar } from '../../services/translate';

interface CoachClientInterface {
  client: ClientInterface,
  nextMeeting: MeetingInterface,
  meetingSchema: MeetingSchemaInterface,
  employer: string
  onboarded: string
}

interface ComponentProps {}

interface ComponentState {
  coach: CoachInterface;
  videoClients: Array<CoachClientInterface>;
  chatClients: Array<CoachClientInterface>;
  previousClients: Array<CoachClientInterface>;
  sortKey: string
  sortHigh: boolean
  loaded: boolean;
}

export default class CoachClients extends React.Component<
  ComponentProps,
  ComponentState
> {
  private api = Api.getInstance();

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    videoClients: [] as Array<CoachClientInterface>,
    chatClients: [] as Array<CoachClientInterface>,
    previousClients: [] as Array<CoachClientInterface>,
    sortKey: 'name',
    sortHigh: false,
    loaded: false,
  };

  componentDidMount() {
    this.getData();
  }

  private getData = () => {
    this.api.get('coach_clients').then((response) => {
      this.setState({
        coach: response.json.coach,
        videoClients: response.json.videoClients,
        chatClients: response.json.chatClients,
        previousClients: response.json.previousClients,
        loaded: true,
      });
    });
  };

  sortClients = (c1: CoachClientInterface, c2: CoachClientInterface):number => {
    let higher = false
    if (this.state.sortKey === 'name') {
      higher = c1.client.firstName.toLowerCase() > c2.client.firstName.toLowerCase()
    } else if (this.state.sortKey === 'onboarded') {
      higher = c1.onboarded > c2.onboarded
    } else if (this.state.sortKey === 'employer') {
      higher = c1.employer > c2.employer
    } else if (this.state.sortKey === 'nextMeeting') {
      if (c1.nextMeeting && c2.nextMeeting) {
        higher = c1.nextMeeting.start > c2.nextMeeting.start
      } else if (c1.nextMeeting) {
        higher = true
      }
    } else if (this.state.sortKey === 'license') {
      higher = c1.client.package.name > c2.client.package.name
    } else if (this.state.sortKey === 'meetingStatus') {
      if (c1.meetingSchema && c2.meetingSchema) {
        higher = c1.meetingSchema.currentPeriodOutcome > c2.meetingSchema.currentPeriodOutcome
      } else if (c1.meetingSchema) {
        higher = true
      }
    }

    if (this.state.sortHigh) {
      return higher ? -1 : 1
    } else {
      return higher ? 1 : -1
    }
  }

  changeSort = (key: string) => {
    if (key === this.state.sortKey) {
      this.setState({sortHigh: !this.state.sortHigh})
    } else {
      this.setState({sortKey: key})
    }
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.MyClients)}/>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.MyClients)}</h1>
          <div className="page_bar"/>
          <IonGrid>
            <div className="hide_small">
              <IonRow>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="3"
                        onClick={() => this.changeSort('name')}>
                  {this.api.trTxt(TrVar.Name)}
                </IonCol>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="2"
                        onClick={() => this.changeSort('onboarded')}>
                  {this.api.trTxt(TrVar.Onboarded)}
                </IonCol>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="2"
                        onClick={() => this.changeSort('employer')}>
                  {this.api.trTxt(TrVar.Employer)}
                </IonCol>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="2"
                        onClick={() => this.changeSort('nextMeeting')}>
                  {this.api.trTxt(TrVar.NextMeeting)}
                </IonCol>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="1"
                        onClick={() => this.changeSort('license')}>
                  {this.api.trTxt(TrVar.License)}
                </IonCol>
                <IonCol className="blue underline cursor-pointer" size="12" sizeMd="2"
                        onClick={() => this.changeSort('meetingStatus')}>
                  {this.api.trTxt(TrVar.MeetingStatus)}
                </IonCol>
              </IonRow>
              <div className="page_bar" style={{ margin:'20px 0' }}/>
            </div>

          {this.state.videoClients.sort(this.sortClients).map((client, index) => {
            return (
              <IonRow className="clientListMaxWidth r_top_margin" key={'client_' + client.client.id}>
                <IonCol className="flex my-auto cursor-pointer" size="12" sizeMd="3"
                        onClick={() => changeUrl('/app/coach/clients/' + client.client.id)}>
                  <img
                    alt="client"
                    src={client.client.picture}
                    className="picture_small round"
                  />
                  <div className="left_margin my-auto link_text">
                    {client.client.firstName} {client.client.lastName}
                  </div>
                </IonCol>
                <IonCol className="my-auto" size="6" sizeMd="2">
                  <div className="blue show_small">
                    {this.api.trTxt(TrVar.Onboarded)}
                  </div>
                  {client.onboarded.substring(0, 10)}
                </IonCol>
                <IonCol className="my-auto" size="6" sizeMd="2">
                  <div className="blue show_small">
                    {this.api.trTxt(TrVar.Employer)}
                  </div>
                  {client.employer}
                </IonCol>
                <IonCol className="my-auto" size="6" sizeMd="2">
                  <div className="blue show_small">
                    {this.api.trTxt(TrVar.NextMeeting)}
                  </div>
                  {
                    client.nextMeeting ? (
                      <>
                        {formatSoon(client.nextMeeting.start, this.api.lang)}
                        {
                          client.nextMeeting.meetingType !== MeetingType.BOOKED &&
                          <p className="red">{this.api.trTxt(TrVar.MeetingCancelled)}</p>
                        }
                      </>

                    ) : (
                      <>-</>
                    )
                  }
                </IonCol>
                <IonCol className="my-auto" size="6" sizeMd="1">
                  <div className="blue show_small">
                    {this.api.trTxt(TrVar.License)}
                  </div>
                  {client.client.package.name}
                </IonCol>
                <IonCol className="my-auto" size="6" sizeMd="2">
                  <div className="blue show_small">
                    {this.api.trTxt(TrVar.MeetingStatus)}
                  </div>
                  <MeetingSchema meetingSchema={client.meetingSchema} clientName={client.client.firstName}/>
                </IonCol>
              </IonRow>
            );
          })}
          <div className="page_bar" style={{ margin:'20px 0' }} />        
          </IonGrid>
        </div>

        {
          this.state.chatClients.length > 0 &&
            <div className="page_section top_margin">
                <h2>
                  {
                    this.api.trTxt(TrVar.ClientsWhoCanOnlyChat)
                  }
                </h2>
                <div className="page_bar"/>
                <IonGrid>
                    <div className="hide_small">
                        <IonRow>
                            <IonCol className="blue" size="12" sizeMd="6">
                              {this.api.trTxt(TrVar.Name)}
                            </IonCol>
                            <IonCol className="blue" size="12" sizeMd="6">
                              {this.api.trTxt(TrVar.License)}
                            </IonCol>
                        </IonRow>
                        <div className="page_bar" style={{ margin:'20px 0' }}/>
                    </div>

                  {this.state.chatClients.map((client, index) => {
                    return (
                      <IonRow className="clientListMaxWidth r_top_margin" key={'client_' + client.client.id}>
                        <IonCol className="flex my-auto cursor-pointer" size="12" sizeMd="6"
                                onClick={() => changeUrl('/app/coach/clients/' + client.client.id)}>
                          <img
                            alt="client"
                            src={client.client.picture}
                            className="picture_small round"
                          />
                          <div className="left_margin my-auto link_text">
                            {client.client.firstName} {client.client.lastName}
                          </div>
                        </IonCol>
                        <IonCol className="my-auto" size="12" sizeMd="6">
                          <div className="blue show_small">
                            {this.api.trTxt(TrVar.License)}
                          </div>
                          {client.client.package.name}
                        </IonCol>
                      </IonRow>
                    );
                  })}
                    <div className="page_bar" style={{ margin:'20px 0' }} />
                </IonGrid>
            </div>
        }

        {
          this.state.previousClients.length > 0 &&
            <div className="page_section top_margin">
              <h2>
                {
                  this.api.trTxt(TrVar.ClientsYouHaveCoachedBefore)
                }
              </h2>
              <div className="page_bar"/>
              <IonGrid>
                  <div className="hide_small">
                      <IonRow>
                          <IonCol className="blue" size="12" sizeMd="6">
                            {this.api.trTxt(TrVar.Name)}
                          </IonCol>
                          <IonCol className="blue" size="12" sizeMd="6">
                            {this.api.trTxt(TrVar.License)}
                          </IonCol>
                      </IonRow>
                      <div className="page_bar" style={{ margin:'20px 0' }}/>
                  </div>

                {this.state.previousClients.map((client, index) => {
                  return (
                    <IonRow className="clientListMaxWidth r_top_margin" key={'client_' + client.client.id}>
                      <IonCol className="flex my-auto cursor-pointer" size="12" sizeMd="6"
                              onClick={() => changeUrl('/app/coach/clients/' + client.client.id)}>
                        <img
                          alt="client"
                          src={client.client.picture}
                          className="picture_small round"
                        />
                        <div className="left_margin my-auto link_text">
                          {client.client.firstName} {client.client.lastName}
                        </div>
                      </IonCol>
                      <IonCol className="my-auto" size="12" sizeMd="6">
                        <div className="blue show_small">
                          {this.api.trTxt(TrVar.License)}
                        </div>
                        {client.client.package?.name}
                      </IonCol>
                    </IonRow>
                  );
                })}
                  <div className="page_bar" style={{ margin:'20px 0' }} />
              </IonGrid>
            </div>
        }
      </div>
    )
  }
}
