import {
  TOKEN_STORE,
  TOKEN_REMOVE,
  TOKEN_CHECKED,
  URL_CHANGE
} from './actionTypes'

export const tokenStore = (token: string) => ({
  type: TOKEN_STORE,
  payload: token
})

export const tokenRemove = () => ({
  type: TOKEN_REMOVE
})

export const tokenChecked = () => ({
  type: TOKEN_CHECKED
})

export const urlChange = (url: string) => ({
  type: URL_CHANGE,
  payload: url
})
