import React from 'react';
import Api from '../../services/Api';
import Loading from '../Loading';
import { formatSoon } from '../../helpers/dateFunctions';
import { CoachInterface } from '../../interfaces/Coach';
import {ClientInterface} from "../../interfaces/Client";
import {MeetingInterface} from "../../interfaces/Meeting";
import VisionBoard from "../visionboard/VisionBoard";
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  clientId: number
}

interface ComponentState {
  coach: CoachInterface;
  client: ClientInterface;
  clientLicense: any;
  loaded: boolean;
  showClientDetails: boolean;
  notFound: boolean;
  showAllHistory: boolean;
  previousMeetings: Array<MeetingInterface>;
  visionBoardId: number;
  meetingKey: string;
  picture: string;
}

export default class ClientHistory extends React.Component<ComponentProps, ComponentState> {
  state: Readonly<ComponentState> = {
    client: {} as ClientInterface,
    coach: {} as CoachInterface,
    clientLicense: {},
    loaded: false,
    showClientDetails: true,
    notFound: false,
    previousMeetings: [],
    showAllHistory: false,
    visionBoardId: 0,
    meetingKey: '',
    picture: '',
  };

  private api = Api.getInstance();

  private getData = () => {
    this.api.get('coach_client_history/' + this.props.clientId, ['client_not_found'])
    .then((response) => {
      this.setState({
        coach: response.json.coach,
        client: response.json.client,
        clientLicense: response.json.clientLicense,
        previousMeetings: response.json.previousMeetings,
        picture: response.json.client.picture,
        loaded: true,
      });
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.setState({loaded: true, notFound: true})
      }
    });
  };

  componentDidMount() {
    this.getData();
  }

  showMeetingStatus = (meetingType: number) => {
    let status = ''
    if (meetingType === 1) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === 2) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      return (
        <div className="left_margin error_red">{status}</div>
      )
    }
    return (<></>)
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }

    if (this.state.visionBoardId > 0) {
      return (
        <div className="page_content px-8">
          <p className="underline top_margin bottom_margin cursor-pointer" onClick={() => this.setState({visionBoardId: 0})}>
            {this.api.trTxt(TrVar.Back)}
          </p>
          <VisionBoard meetingId={this.state.visionBoardId}/>
        </div>
      )
    }

    const clientName = this.state.client.firstName + ' ' + this.state.client.lastName
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.MeetingHistory)}
                     breadcrumbs={[
                       {name: this.api.trTxt(TrVar.MyClients), link:'/app/coach/clients'},
                       {name: clientName, link:'/app/coach/clients/' + this.props.clientId}
                     ]}/>

        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.MeetingHistory)}</h1>
        </div>

        {
          this.state.previousMeetings.map((meeting: MeetingInterface) => {
            return (
              <div key={"next_meeting_" + meeting.id}
                   className="top_margin page_section">
                <div className="color_white">
                  <div className="flex items-center">
                    <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                    {formatSoon(meeting.start, this.api.lang)}, {meeting.length} min
                    {this.showMeetingStatus(meeting.meetingType)}
                  </div>
                </div>
                {
                  meeting.visionBoardData &&
                    <div className="top_margin">
                      <VisionBoard meetingId={meeting.id}/>
                    </div>
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}
