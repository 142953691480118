
import React, {useState, useRef} from 'react';

import {IonModal, IonList, IonItem, IonIcon, IonLabel} from "@ionic/react";

import Api from "../../services/Api"
import { TrVar } from '../../services/translate';

interface ComponentProps {
    isOpen: boolean
    leftButtonText?: string
    rightButtonText?: string
    onLeftClick?: () => void
    onRightClick?: () => void
    onDismiss?: () => void
}

const Modal = (props: ComponentProps) => {

    const api = Api.getInstance();
    const modal = useRef<HTMLIonModalElement>(null);

    return (
        <IonModal 
            isOpen={props.isOpen}
            style={{ width: 'auto', height: 'auto' }}
            id="custom-modal"
            ref={modal}
            onDidDismiss={() => props.onDismiss && props.onDismiss()}
        >
            <div className="modal-container mt-10 text_center color_white p-12">
                <img alt="!" src="/assets/icon/white/exclamation_circle.svg" className="icon_medium"/>
                <p className="mt-10">{api.trTxt(TrVar.ConfirmRemoveChat)}</p>
                <div className="mt-10 flex justify-center">
                {props.leftButtonText && <div 
                      className="button inline_block white"
                      onClick={props.onLeftClick && props.onLeftClick}
                  >
                      {props.leftButtonText}
                  </div>}
                  {props.rightButtonText &&  <div 
                      className="button inline_block ml-2"
                      onClick={props.onRightClick && props.onRightClick}
                  >
                      {props.rightButtonText}
                  </div>}
                </div>
            </div>
        </IonModal>
    )
}

export default Modal;