import {IonContent, IonHeader, IonPage, IonToolbar} from '@ionic/react';
import React from 'react';
import {Link, Redirect} from "react-router-dom";
import Api from "../services/Api";
import {CONFIG} from "../constants";
import Loading from "../components/Loading";
import { TrVar } from '../services/translate';

enum PERSON_STATE {
  NOT_STARTED,
  ONBOARDING,
  ONBOARDED
}

interface ComponentProps {}

interface ComponentState {
  clientState: number
  coachState: number
  error: string
  who: string
  waitForData: boolean
}

export default class Start extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  urlSearchParams: any;

  state: Readonly<ComponentState> = {
    clientState: 0,
    coachState: 0,
    error: '',
    who: '',
    waitForData: true,
  }

  componentDidMount() {
    this.setURLSearch();
    this.parseUrl()
    if (this.api.loggedIn) {
      this.getData()
    } else {
      this.setState({waitForData: false})
    }
  }

  setURLSearch = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
    this.urlSearchParams = searchParams;
    return searchParams;
  }


  parseUrl = () => {
    if (window.location.search) {
      if (this.urlSearchParams.language) {
        this.api.setLanguage(this.urlSearchParams.language)
      }
      if (this.urlSearchParams.who) {
        this.setState({who: this.urlSearchParams.who})
      }
    }
  }

  private getData = () => {
    this.api.get('person_state', ['unauthorized'])
    .then(response => {
      this.setState({
        clientState: response.json.clientState,
        coachState: response.json.coachState,
        waitForData: false,
      })
    })
    .catch(response => {
      if (response.isExpectedError) {
        this.api.loggedIn = false
        delete window.localStorage[CONFIG.TOKEN_NAME]
        window.location.reload()
        // this.setState({waitForData: false})
      }
    });
  }

  logOut = () => {
    this.api.logOut(CONFIG.HOME_PAGE)
  }

  render() {
    if (this.state.waitForData) {
      return (
        <Loading/>
      )
    }

    let redirectUrl = 'none'
    if (this.urlSearchParams.package === 'free') {
      if (this.state.clientState === PERSON_STATE.ONBOARDED) {
        redirectUrl = '/app/client/overview?package=free'
       } else {
        redirectUrl = '/app/client_onboarding?package=free'
      }
    } 
    if (this.urlSearchParams.mode === 'create' && this.urlSearchParams.package === 'free') {
      if (this.state.clientState === PERSON_STATE.ONBOARDED) {
        redirectUrl = '/app/client/overview?package=free&mode=create'
       } else {
        redirectUrl = '/app/client_onboarding?package=free&mode=create'
      }
    } 
    if (this.urlSearchParams.mode === 'create') {
      if (this.state.clientState === PERSON_STATE.ONBOARDED) {
        redirectUrl = '/app/client/overview?mode=create'
       } else {
        redirectUrl = '/app/client_onboarding?mode=create' 
      }
    } 
    if (this.state.who === 'coach') {
      if (this.state.coachState === PERSON_STATE.ONBOARDED) {
        redirectUrl = '/app/coach/overview'
      } else {
        redirectUrl = '/app/coach_onboarding'
      }
    } else if (this.state.who === 'client') {
      if (this.state.clientState === PERSON_STATE.ONBOARDED) {
        redirectUrl = '/app/client/overview'
      } else {
        redirectUrl = '/app/client_onboarding'
      }
    } else if (this.state.coachState === PERSON_STATE.ONBOARDED) {
      redirectUrl = '/app/coach/overview'
    } else if (this.state.clientState === PERSON_STATE.ONBOARDED) {
      redirectUrl = '/app/client/overview'
    } else if (this.state.coachState === PERSON_STATE.ONBOARDING) {
      redirectUrl = '/app/coach_onboarding'
    } else if (this.state.clientState === PERSON_STATE.ONBOARDING) {
      redirectUrl = '/app/client_onboarding'
    }
    // Send person to proper page if person has started any onboarding
    if (redirectUrl !== 'none') {
      return (
        <Redirect to={redirectUrl}/>
      )
    }

    if (this.state.error.length > 0) {
      return (
        <div className="mt-8 text_center">
          <h1>Error</h1>
          <p className="mt-8">{this.state.error}</p>
        </div>
      )
    }

    // If we are still here, it means that person is logged in, but has not started any onboarding

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <div className="header_navigation">
              <h2 className="hide_small"><a href={CONFIG.HOME_PAGE}>Zebrain</a></h2>
              <div className="flex items-center text_right">
                <button className="mr-8 button green cursor-pointer" onClick={() => this.logOut()}>
                  {this.api.trTxt(TrVar.SignOut)}
                </button>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="mt-8 text_center">
            <h2>{this.api.trTxt(TrVar.Welcome)}</h2>
            <p className="mt-12">
              {this.api.trTxt(TrVar.GetStartedAsClient)}:
            </p>
            <Link to="/app/client_onboarding">
              <button className="button green bg_dark_blue cursor-pointer mt-4 min-w-200">
                <p>{this.api.trTxt(TrVar.Client)}: {this.api.trTxt(TrVar.GetStarted)}</p>
              </button>
            </Link>

            <p className="mt-12">
              {this.api.trTxt(TrVar.GetStartedAsCoach)}:
            </p>
            <div className="mt-4">
              <Link to="/app/coach_onboarding">
                <button className="button green bg_dark_blue cursor-pointer min-w-200">
                  {this.api.tr('Coach')}: {this.api.trTxt(TrVar.GetStarted)}
                </button>
              </Link>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

};
