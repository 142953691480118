export enum PackageType {
  UNKNOWN = 0,
  TRAINER = 1,
  DISCOVER = 2,
  KICKSTART = 3,
  OPTIMIZE = 4,
  MAXIMIZE = 5,
  INSIDE = 6,
  ACCESS = 7,
  FREE = 8,
  ACCESS_PRIVATE = 9,
  ACCESS_BASIC = 10,
}

export interface PackageInterface {
  type: PackageType,
  name: string,
  meetingPeriod: number,
  meetingsPerMonth: number,
  isVideoCoachIncluded: boolean,
  isChatCoachIncluded: boolean,
  meetingLengthFirst: number,
  meetingLengthRest: number,
  customMeetingLengths: Array<number>,
  possibleMeetingLengths: Array<number>,
}
