export function introTexts (focusArea: string, lang: string) {
  if (focusArea === 'stress') {
    if (lang === 'sv') {
      return [
        'Stress i sig är inte skadligt i kortare perioder - våra kroppar är skapade ' +
        'för tillfälliga adrenalinpåslag. Men långvarig stress och brist på ' +
        'återhämtning, gör att kroppen och vår mentala kapacitet tar skada.',

        'För att hantera stress i vår vardag, är det viktigt att identifiera vad som ' +
        'orsakar stress. Det är viktigt att poängtera att vi alla är olika ' +
        'stresskänsliga. Vår känslighet kan också variera genom livet.',

        'Genom att öka vår självkännedom kan vi förstå vad som triggar stress för ' +
        'just oss. På så vis kan vi förebygga negativa stressnivåer och öka vårt ' +
        'välmående.'
      ]
    } else {
      return [
        'Stress itself is not harmful in short periods - our bodies are well-equipped ' +
        'to deal with it temporarily. However, long term stress and lack of recovery, ' +
        'will damage our physical and mental health.',

        `To deal with stress in our every day life, it's important to identify what ` +
        `causes stress. It is crucial to note that everyone's tolerance to stress ` +
        `differs. Our tolerance level fluctuates throughout our life.`,

        'By increasing your knowledge about yourself, what affects ' +
        'your stress levels, and the ways you are affected, you can learn to fend off and prevent excess ' +
        'stress, thereby improving your well-being.'
      ]
    }
  } else if (focusArea === 'motivation') {
    if (lang === 'sv') {
      return [
        'Motivation är de drivkrafter som får oss att vilja utföra en viss handling. På ' +
        'Zebrain skiljer vi på två typer av motivation: Tändarfaktorer ' +
        'släckarfaktorer (tändare och släckare):',

        'Tändarfaktorer är inre drivkrafter som tänder vår motivation. Det kan ' +
        'vara att känna meningsfullhet, självbestämmande eller att känna sig ' +
        'kompetent. Tändarfaktorer ökar vår motivation ju mer vi upplever dem.',

        'Släckare fungerar i motsatt riktning. De är yttre faktorer som behöver ' +
        'vara uppfyllda för att inte släcka vår motivation. Exempelvis kan otydlighet ' +
        'eller utebliven belöning få oss att känna oss mindre motiverade.',
        
        'Genom att identifiera vilka våra tändar- och släckarfaktorer är, kan hjälpa ' +
        'oss själva att bli mer motiverade.'
      ]
    } else {
      return [
        'Motivation is what we need both to feel motivated and to perform. At ' +
        'Zebrain we differentiate between two types of motivation factors: ' +
        'Extinguishing factors and Ignition factors (extinguishers and ignitions).',
        
        'Ignition factors are driving forces that increase our motivation. Here we find such factors as ' +
        'meaningfulness, interest, self-determination, and affirmation.' +
        'Ignition factors increase our motivation the more we have of them.',

        'Extinguishing factors work in the opposite direction. These are external factors that must ' +
        'be fulfilled sufficiently to avoid dragging down our motivation. For example not knowing what the ' +
        'rules are, or lack of rewards can make us feel less motivated.',

        'By identifying our extinguishing and ingnition factors, we can ' +
        'help ourselves become more motivated.'
      ]
    }
  } else if (focusArea === 'relation') {
    if (lang === 'sv') {
      return [
        'Att ha goda sociala relationer är positivt för vårt välmående och vår ' +
        'förmåga att känna motivation. Goda relationer får oss också att känna ' +
        'gemenskap och trygghet, vilket gör att vi kan slappna av och vara oss ' +
        'själva.',

        'Våra relationers karaktär förändras ofta genom livet. Det kan även vårt ' +
        'behov av kontakt med andra göra. Det är viktigt att tänka på att vi alla är ' +
        'olika; Vissa föredrar att ha många relationer, medan andra trivs med färre.',

        'Genom att både förstå vilken typ av relationer vi mår bra av och vilken roll ' +
        'vi själva har i dem, desto bättre kan vi bli på att skapa nya och ta hand om ' +
        'de relationer vi har.'
      ]
    } else {
      return [
        'Having good, well-functioning social relationships is positive for well-being and for our ' +
        'motivation in general. Good relationships affects our sense of community and security, and ' +
        'thus the feeling of being able to fully be ourselves.',
        
        'Our relationships change throughout life, and the ' +
        'number of relationships needed varies. ' +
        `It's important to note that everyone is different; ` +
        'Some need many relationships, while others need only a few.',

        'By better understanding yourself and what is important to you. As well as, what role ' +
        'we ourselves want have in them. You will gain more insight ' +
        'into how to manage and improve your relationships.'
      ]
    }
  } else if (focusArea === 'future') {
    if (lang === 'sv') {
      return [
        'För att vi ska må bra, är det viktigt att vi känner kontroll över vår egen ' +
        'framtid. Med kontroll menas att vi själva kan påverka vilka val vi gör inom ' +
        'såväl arbetsliv som privatliv. För att må bra, behöver vi också ha drömmar ' +
        'och mål om vår framtid.',

        'Genom att konkretisera och följa upp våra tankar om framtiden, kommer ' +
        'vi att känna oss mer motiverade att ta itu med faktorer i vår vardag som ' +
        'inte tar oss dit vi vill. Det kommer också skapa tydligare känsla av mening ' +
        'eftersom allting vi gör får ett tydligt syfte.'
      ]
    } else {
      return [
        `For your well-being, it's important to feel in control of one's future. ` +
        'Feeling in control of one’s own future and having the ability to influence both work and private ' +
        'life is important for mental health.' +
        `Your well-being is also dependent on having dreams and goals of one's future.`,

        'Therefore, it is important to actively work on solidifying and following up on your plans, desires, ' +
        'and goals for the future. The results are often more energy, increased motivation, and more ' +
        'meaningfulness in what you direct your energy towards. All of this contributes to important and ' +
        'valuable insights about yourself and about how you can influence your own future.'
      ]
    }
  } else if (focusArea === 'prestation') {
    if (lang === 'sv') {
      return [
        'Att prestera innebär att åstadkomma något önskvärt, trots att vi möter ' +
        'svårigheter. Vår förmåga att prestera påverkas av olika faktorer, men i ' +
        'stora drag styrs den av hur bra vi är på att använda och anpassa våra ' +
        'resurser.',

        'Genom att öka vår självkännedom, kan vi lära oss att använda våra ' +
        'resurser mer effektivt. Om vi dessutom tränar på att våga misslyckas, ' +
        'kommer vi att öka våra möjligheter att prestera på önskad nivå. På så vis ' +
        'kan vi också prestera mer hållbart och samtidigt få en mer realistisk och ' +
        'sund förväntning på oss själva.'
      ]
    } else {
      return [
        'To perform means to achieve something desirable, even though we face ' +
        'difficulties. Our ability to perform is affected by different factors, but ' +
        `overall it is determined based on how well we use and adapt our resources.`,

        'By increasing our knowledge or ourself, we can use our resources more ' +
        'effectively. You will train your courage, let go of the idea of being perfect, and dare to make mistakes. ' +
        'You will gain insights into what you want and increase your feeling of success.' +
        'Your insights into your ability to use your resources in ' +
        'the best way, will make you perform sustainable over the long term, and at the peak of your ability.',
      ]
    }
  } else if (focusArea === 'self_insight') {
    if (lang === 'sv') {
      return [
        'Att ha självkännedom eller självinsikt, innebär att vara medveten om de ' +
        'känslor, tankar eller impulser som ständigt pågår i vår inre värld. Har vi ' +
        'god självkännedom, kan vi troligtvis också förutse och parera våra ' +
        'reaktioner på olika typer av stimulering.',

        'Genom att lära känna oss själva - våra styrkor, begränsningar och vad vi ' +
        'behöver för att må bra, desto bättre förutsättning har vi att lyckas med ' +
        'det vi vill inom såväl arbetsliv som privatliv. God självkännedom gör också ' +
        'att vi blir bättre på att göra rätt prioriteringar och ta beslut som är bra och ' +
        'hållbara.'
      ]
    } else {
      return [
        'To have self-knowledge means that you are aware of your ' +
        'feelings, thoughts and impulses. ' +
        `If you know yourself well, most likely you have the ability to ` +
        'anticipate your reactions on different types of stimuli.',

        'The more you have insight into what is important in your life, where you are going, what you want to ' +
        'achieve, how you influence others, your strengths and limitations, the more opportunities you create ' +
        'to succeed in both your personal and working life. ' +
        'High levels of self-knowledge helps you prioritize and make correct and sustainable ' +
        'decisions.'
      ]
    }
  } else {
    return []
  }
}