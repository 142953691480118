import React from 'react'
import { ResourcesInterface } from "../../pages/openvideo/interfaces";
import Api from '../../services/Api';
import { TrVar } from '../../services/translate';

interface ComponentProps{
  who: string
  lang: string
  resources: ResourcesInterface
}

interface ComponentState {
  section: string
}

export default class VideoHelp extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  state = {
    section: '',
  }

  render() {
    let meetingPartner: string
    if (this.props.who === 'client') {
      meetingPartner = 'coach'
    } else if (this.props.who === 'coach') {
      meetingPartner = this.api.trTxt(TrVar.Client).toLowerCase()
    } else {
      meetingPartner = this.api.trTxt(TrVar.MeetingPartner).toLowerCase()
    }
    return (
      <div>
        <h4 className="top_margin underline cursor-pointer" onClick={() => this.setState({section: 'noPartner'})}>
          {
            this.api.trTxt(TrVar.NobodyShowsUpInTheMeeting)
          }
        </h4>
        {
          this.state.section === 'noPartner' &&
          <>
            <p className="top_margin">
              {
                this.api.trTxt(TrVar.VideoHelpNoPartnerPart1) + ' '
                   + meetingPartner + ' ' +  this.api.trTxt(TrVar.VideoHelpNoPartnerPart2).toLowerCase()
              }

            </p>
            <p className="top_margin pb-8">
              {
                this.api.trTxt(TrVar.IfNobodyShowsUpPleaseContact)
              }
              &nbsp;
              <a className="underline cursor-pointer color_white"
                href="mailto:support@zebrain.se">{this.api.trTxt(TrVar.SupportAtZebrainSe).toLowerCase()}</a>
              &nbsp;
              {
                this.props.lang === 'sv' ?
                  'så ser vi till att du inte går miste om ditt möte.' :
                  'and we will make sure you don\'t loose your meeting.'
              }

            </p>
          </>
        }

        <h4 className="top_margin underline cursor-pointer" onClick={() => this.setState({section: 'cannotHear'})}>
          {
            this.props.lang === 'sv' ?
              'Jag hör inte min ' + meetingPartner :
              'I can\'t hear my ' + meetingPartner
          }
        </h4>
        {
          this.state.section === 'cannotHear' &&
          <>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Kontrollera att volymen för högtalare eller hörlurar är uppskruvad.' :
                  'Make sure that the volume for your loud speakers or ear phones is turned up.'
              }
            </p>
            <p className="top_margin pb-8">
              {
                this.props.lang === 'sv' ?
                  'Om ljudet fungerar i övrigt (t ex i Spotify) kan det vara din ' + meetingPartner +
                  ' som har problem med ' +
                  'sin mikrofon. Chatta med din ' + meetingPartner + ' och föreslå nästa avsnitt i denna hjälpsektion.'
                  :
                  'If your sound works in other applications (e.g. Spotify) it might be your ' + meetingPartner + ' having ' +
                  'problems with their microphone. Chat with your ' + meetingPartner +' and suggest  the next ' +
                  'section in this help section.'
              }
            </p>
          </>
        }

        <h4 className="top_margin underline cursor-pointer" onClick={() => this.setState({section: 'notHeard'})}>
          {
            this.props.lang === 'sv' ?
              'Min ' + meetingPartner + ' hör inte mig' :
              'My ' + meetingPartner + ' can\'t hear me'
          }
        </h4>
        {
          this.state.section === 'notHeard' &&
          <>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Kontrollera att volymen för din mikrofon uppskruvad.' :
                  'Make sure that the volume for your microphone is turned up.'
              }
            </p>
            <p className="top_margin pb-8">
              {
                this.props.lang === 'sv' ?
                  'Om du fortfarande inte hörs kan du prova att ' :
                  'If you still cannot be heard, try '
              }
              <span className="link_text" onClick={() => this.props.resources.stateFunc('menuSection', 'mic')}>
              {
                this.props.lang === 'sv' ? 'byta mikrofon' : 'changing microphone'
              }
              </span>.
            </p>
          </>
        }

        <h4 className="top_margin underline cursor-pointer" onClick={() => this.setState({section: 'videoFreeze'})}>
          {
            this.props.lang === 'sv' ?
              'Bilden "fryser"' :
              'Image "freezes"'
          }
        </h4>
        {
          this.state.section === 'videoFreeze' &&
          <>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Detta beror nästan alltid på nätverksproblem mellan dig och din ' + meetingPartner + '.' :
                  'This is probably due to network problems between you and your ' + meetingPartner + '.'
              }
            </p>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Om du ändå hör din ' + meetingPartner + ' är bandbredden låg. Då prioriteras ljud framför bild.' :
                  'If you still hear your ' + meetingPartner + ' the bandwith is low. The sound is then prioritized before image.'
              }
            </p>
            <p className="top_margin pb-8">
              {
                this.props.lang === 'sv' ?
                  'Ibland är problemet tillfälligt, och bilden kommer igång igen. Om inte kan du prova att byta ' +
                  'nätverk och koppla upp igen.' :
                  'Sometimes the network problems are temporary, and the image starts working again. If not, try ' +
                  'changing network and reconnect to the meeting.'
              }
            </p>
          </>
        }

        <h4 className="top_margin underline cursor-pointer" onClick={() => this.setState({section: 'other'})}>
          {
            this.props.lang === 'sv' ?
              'Övriga problem' :
              'Other problems'
          }
        </h4>
        {
          this.state.section === 'other' &&
          <>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Har du fortfarande problem?' :
                  'Do you still have problems?'
              }
            </p>
            <p className="top_margin">
              {
                this.props.lang === 'sv' ?
                  'Skriv till ' :
                  'Please contact '
              }
              &nbsp;
              <a className="underline cursor-pointer color_white"
                 href="mailto:support@zebrain.se">support@zebrain.se</a>
              &nbsp;
              {
                this.props.lang === 'sv' ?
                  'så ska vi försöka hjälpa dig.' :
                  'and will try to help you.'
              }
            </p>
          </>
        }
      </div>
    )
  }
}
