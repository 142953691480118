import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {DisaInterface} from "../../interfaces/Disa"
import {CustomerIntroductionInterface} from "../../interfaces/CustomerIntroduction"
import {ClientInterface} from "../../interfaces/Client"
import {CoachInterface} from "../../interfaces/Coach"
import {MeetingInterface} from "../../interfaces/Meeting"
import { ClientUISettingsInterface } from '../../interfaces/ClientUISettings';
import Loading from "../Loading"
import {formatCountDown, formatSoon, todayStr} from "../../helpers/dateFunctions"
import WebSocketClient from "../../services/WebSocketClient"
import {RecommendationInterface} from "../../interfaces/RecommendationInterface"
import Recommendations from "../recommendations/Recommendations";
import {MeetingsSummaryInterface} from "../../interfaces/MeetingsSummaryInterface";
import {PackageInterface, PackageType} from "../../interfaces/Package";
import ClientMeasureSummary from "./ClientMeasureSummary";
import {MeasureInterface} from "../../interfaces/MeasureInterface";
import {MeasureArea} from "../../interfaces/MeasureArea";
import Breadcrumbs from "../menu/Breadcrumbs";
import CalendarClientOld from "./CalendarClient";
import DisaRomb from "../graphics/DisaRomb";
import MeetingButton from "../MeetingButton";
import {FocusAreaSettingsInterface, FocusMeasureInterface} from "../tasks/interfaces";
import {ClientLicenseOfferInterface} from "../../interfaces/ClientLicenseOffer";
import FocusAreaLink from "../focus_area/FocusAreaLink";
import FocusAreaSummary from "../focus_area/FocusAreaSummary";
import { TrVar } from "../../services/translate";
import ChatCoachMessage from './ChatCoachMessage'

interface LastBookableDate {
  date: string,
  reason: string
}

interface ComponentProps{
  goTo: Function
  navigation: any
  badges: any
  clientUISettings: ClientUISettingsInterface
  hasAiCoach?: boolean
}

interface ComponentState {
  payType: number
  client: ClientInterface
  areas: Array<string>
  disa: Array<DisaInterface>
  coach: CoachInterface
  package?: PackageInterface
  clientLicenseOffer?: ClientLicenseOfferInterface
  license?: any
  meetings: Array<MeetingInterface>
  loggedIn: boolean
  showLogin: boolean
  canChangeCoach: boolean
  page: string
  loaded: boolean
  recommendations: RecommendationInterface
  meetingsSummary: MeetingsSummaryInterface
  measures: Array<MeasureInterface>
  lastPowerArea: MeasureArea
  followupArea: string|undefined
  coachRequest: boolean
  lastBookableDate?: LastBookableDate
  nextMeetingStarts: string
  focusMeasures: Array<FocusMeasureInterface>
  focusAreaSettings: Array<FocusAreaSettingsInterface>
  customerIntroduction?: CustomerIntroductionInterface,
  plansDone: number,
  hasBeenChatCoached: boolean,
  hasBeenVideoCoached: boolean,
  hasAiCoach?: boolean,
}


export default class ClientOverview extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private webSocket = WebSocketClient.getInstance()
  private countDownInterval: any
  private nextMeeting: any

  state = {
    payType: 0,
    client: {} as ClientInterface,
    areas: [],
    questions: [],
    disa: [],
    coach: {} as CoachInterface,
    package: {} as PackageInterface,
    clientLicenseOffer: {} as ClientLicenseOfferInterface,
    meetings: [],
    loggedIn: this.api.loggedIn,
    showLogin: false,
    canChangeCoach: false,
    page: 'overview',
    loaded: false,
    recommendations: {} as RecommendationInterface,
    meetingsSummary: {} as MeetingsSummaryInterface,
    measures: [],
    lastPowerArea: {} as MeasureArea,
    followupArea: undefined,
    coachRequest: false,
    lastBookableDate: {} as LastBookableDate,
    nextMeetingStarts: '...',
    focusMeasures: [],
    focusAreaSettings: [],
    customerIntroduction: {} as CustomerIntroductionInterface,
    plansDone: 0,
    hasBeenChatCoached: false,
    hasBeenVideoCoached: false,
  }

  componentDidMount() {
    this.getData()
  }

  componentWillUnmount() {
    clearInterval(this.countDownInterval)
  }

  parseData = (resultJson: any) => {
    const client = resultJson.client
    this.setState({
      client: client,
      areas: client.areas,
      coach: resultJson.coach,
      meetings: resultJson.meetings,
      package: client.package,
      canChangeCoach: resultJson.canChangeCoach,
      clientLicenseOffer: resultJson.clientLicenseOffer,
      loaded: true,
      recommendations: resultJson._recommendations,
      meetingsSummary: resultJson.meetingsSummary,
      disa: resultJson.disa,
      measures: resultJson.measureList,
      lastPowerArea: resultJson.lastPowerArea,
      followupArea: resultJson.followupArea,
      coachRequest: resultJson.coachRequest,
      lastBookableDate: resultJson.lastBookableDate,
      focusMeasures: resultJson.focusMeasures,
      focusAreaSettings: resultJson.focusAreaSettings,
      customerIntroduction: resultJson.customerIntroduction,
      plansDone: resultJson.plansDone,
      hasBeenChatCoached: resultJson.hasBeenChatCoached,
      hasBeenVideoCoached: resultJson.hasBeenVideoCoached,
    }, () => {
      this.nextMeeting = this.getNextMeeting()
      if (this.nextMeeting) {
        const nextMeetingStarts = formatCountDown(this.nextMeeting.start, this.api.lang)
        if (nextMeetingStarts === "-") {
          this.setState({nextMeetingStarts: formatSoon(this.nextMeeting.start, this.api.lang)})
        } else {
          this.setState({nextMeetingStarts: nextMeetingStarts})
        }
        this.countDownInterval = setInterval(() => {
          const nextMeetingStarts = formatCountDown(this.nextMeeting.start, this.api.lang)
          if (nextMeetingStarts === "-") {
            clearInterval(this.countDownInterval)
            this.setState({nextMeetingStarts: formatSoon(this.nextMeeting.start, this.api.lang)})
          } else {
            this.setState({nextMeetingStarts: nextMeetingStarts})
          }
        }, 1000)
      }
    })
  }

  private getData = () => {
    this.api.get('client?recommendations=1&meetingSummary=1&behaviour=1&measures=1&customerIntroduction=1')
      .then(response => {
      this.parseData(response.json)
    })
  }

  private coachRequest = () => {
    const coach_type = this.state.client.package.isChatCoachIncluded ? 'video' : 'chat'
    this.api.post('request_coach/' + coach_type, {}).then(_response => {
      this.setState({coachRequest: true})
    })
  }

  acceptOffer = () => {
    this.api.post('accept_client_license_offer', {}, ['no_seats_available', 'license_expired'])
      .then(_response => {
        window.location.reload()
      })
      .catch(response => {
        if (response.isExpectedError === 'no_seats_available') {
          alert(this.api.trTxt(TrVar.NoSeatsAvailable))
        } else {
          alert(this.api.trTxt(TrVar.LicenseExpired))
        }
      })
  }

  checkSocket = () => {
    console.log('Socket readyState is ' + this.webSocket.webSocket?.readyState)
  }

  showMeetingStatus = (meetingType: number) => {
    let status = ''
    if (meetingType === 1) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === 2) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      return (
        <span className="mr-4">{status}</span>
      )
    }
    return (<></>)
  }

  getLargestGapQuestion = () => {
    const lastMeasure = this.state.measures[0] as MeasureInterface
    let largestGapQuestion: any = null
    let largestGap = -100
    for (let areaNr = 0; areaNr < 4; areaNr++) {
      let powerQuestionNr = -1
      const area = lastMeasure.areas[areaNr]
      for (let question of area.questions) {
        const gap = question.valueDesired - question.valueCurrent
        let thisPowerQuestionNr = -1
        if (gap > 10) {
          powerQuestionNr++
          thisPowerQuestionNr = powerQuestionNr
        }
        if (gap > largestGap) {
          largestGap = gap
          question.areaNr = areaNr
          question.powerQuestionNr = thisPowerQuestionNr
          largestGapQuestion = question
        }

      }
    }
    return largestGapQuestion
  }

  accessCoachRequest = () => {
    return (
      <div className="page_section half">
        {this.state.package?.type === PackageType.FREE
          ?
          <div className='flex justify-between'>
            <div style={{ width: '289px' }}>
              <div style={{ backgroundColor: '#BC80F0', color: '#fff', padding: '30px', borderRadius: '5px' }}>
                <div>{this.api.trTxt(TrVar.YouHaveLicensePlan)}:</div>
                <h1 style={{ marginTop: '5px' }}>Free</h1>
              </div>
              {
                this.state.client.isIncognito ? (
                  <>
                    <div style={{ marginTop: '30px' }}>
                      <div className="blue">{this.api.trTxt(TrVar.CreateFree)}</div>
                      <h2 >{this.api.trTxt(TrVar.SaveYourResults)}</h2>
                    </div>
                    <div onClick={() => changeUrl('/app/client/create_account')}
                      className="top_margin green button inline_block">
                      {this.api.trTxt(TrVar.CreateAccount)}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginTop: '30px' }}>
                      <div className="blue">{this.api.trTxt(TrVar.Upgrade)}</div>
                      <h2>{this.api.trTxt(TrVar.DevelopYourFullPotential)}!</h2>
                    </div>
                    <div onClick={() => changeUrl('/app/client/purchase')}
                      className="top_margin green button inline_block">
                      {this.api.trTxt(TrVar.Upgrade)}
                    </div>
                  </>
                )
              }
            </div>
            <img className='left_margin hide_small' src="/assets/illustration/certified.svg" width={266} alt="" />
          </div>
          : this.state.package?.type === PackageType.ACCESS_PRIVATE
            ?
            <div className='flex justify-between'>
              <div style={{ width: '289px' }}>
                <div style={{ backgroundColor: '#BC80F0', color: '#fff', padding: '30px', borderRadius: '5px' }}>
                  <div>{this.api.trTxt(TrVar.YouHaveLicensePlan)}</div>
                  <h1 style={{ marginTop: '5px' }}>Access</h1>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <div className="blue">{this.api.trTxt(TrVar.UniqueMatching)}</div>
                  <h2>{this.api.trTxt(TrVar.DoYouWantToUpgrade)}</h2>
                </div>
                <div onClick={() => changeUrl('/app/client/request_contact')}
                  className="top_margin button inline_block">
                  {this.api.trTxt(TrVar.GetStarted)}
                </div>
              </div>
              <img className='left_margin hide_small' src="/assets/illustration/matching.svg" width={266} alt="" />
            </div>
            :
            <div className='flex justify-between'>
              <div style={{ width: '289px' }}>
                {
                  this.state.coachRequest ? (
                    <h3>
                      {
                        this.state.client.package.isChatCoachIncluded ?
                          this.api.trTxt(TrVar.WeWillNotifyYourEmployerThatYouWantVideoCoaching)
                          :
                          this.api.trTxt(TrVar.WeWillNotifyYourEmployerThatYouWantChatCoaching)
                      }
                    </h3>
                  ) : (
                    <div>
                      <div className="blue">
                        {
                          this.api.trTxt(TrVar.ReportInterest)
                        }
                      </div>
                      <h2>
                        {
                          this.state.client.package.isChatCoachIncluded ?
                            this.api.trTxt(TrVar.DevelopFasterWithCoach)
                            :
                            this.api.trTxt(TrVar.DevelopFasterWithChatCoach)
                        }
                      </h2>
                      <div className="button inline_block mt-12" onClick={() => this.coachRequest()}>
                        {this.api.trTxt(TrVar.NotifyMyEmployer)}
                      </div>
                    </div>
                  )
                }
              </div>
              <img className='left_margin hide_small' src="/assets/illustration/certified.svg" width={169} alt="" />
            </div>
        }
      </div>
    )
  }

  getNextMeeting = () => {
    for (const meeting of this.state.meetings as Array<MeetingInterface>) {
      if (meeting.meetingType === 0) {
        return meeting
      }
    }
    return null
  }

  coachMeeting = () => {
    let nextMeeting:any = this.getNextMeeting()
    if (this.state.coach) {
      return (
        <div className="page_section quarter">
          <div className="flex justify-between">
            <div className="flex-grow">
              <h2>{this.api.trTxt(TrVar.NextMeeting)}</h2>
              <img className="icon_huge hide_medium svg_blue top_margin" alt="time" src="/assets/icon/calendar_time.svg"/>
              {
                nextMeeting ? (
                  <>
                    <div className="top_margin">
                      {this.api.trTxt(TrVar.Meeting)}&nbsp;
                      {
                        this.state.meetingsSummary ? this.state.meetingsSummary?.meetingsDone + 1 : ''
                      }
                      {
                        this.state.meetingsSummary?.meetingsTotal > 0 &&
                        <span>
                          &nbsp;{this.api.trTxt(TrVar.Of).toLowerCase()} {this.state.meetingsSummary.meetingsTotal}
                        </span>
                      }
                      , {nextMeeting?.length} {this.api.trTxt(TrVar.Min).toLowerCase()}
                    </div>
                    <h4 className="top_margin">{this.state.nextMeetingStarts}</h4>
                    {
                      nextMeeting.guestName?.length > 0 &&
                      <p className="top_margin">{nextMeeting.guestName} is invited</p>
                    }
                    <h4 className="top_margin">{this.api.trTxt(TrVar.Tip)}!</h4>
                    <p className='font_small'>{this.api.trTxt(TrVar.TestMeetingBeforeCoachSession)}</p>
                    <div className="btn_top_margin">
                      <MeetingButton who="client" meeting={nextMeeting}
                                     name={nextMeeting.client.firstName}/>
                    </div>
                  </>
                ) : (
                  <h4 className="top_margin">
                    {this.api.trTxt(TrVar.NoBookedMeeting)}
                  </h4>
                )
              }
              {/*{*/}
              {/*  this.state.meetingsSummary?.licenseEnd &&*/}
              {/*    <div className="top_margin">*/}
              {/*      {this.api.lang === 'sv' ? 'Licensen upphör ' : 'Licence expires '}*/}
              {/*      {this.state.meetingsSummary.licenseEnd}*/}
              {/*    </div>*/}
              {/*}*/}
            </div>
          </div>
        </div>
      )
    }
  }

  yourCoach = () => {
    if (this.state.coach) {
      return (
        <div className="page_section quarter r_left_margin_early_break r_top_margin_early_break">
          <h2>{this.api.trTxt(TrVar.YourCoach)}</h2>
          <img alt="coach" src={this.state.coach.picture} className="top_margin coach_picture small"/>
          <h4 className="top_margin blue">{this.api.trTxt(TrVar.Name)}</h4>
          <h4>{this.state.coach.firstName} {this.state.coach.lastName}</h4>

          {
            this.state.client.isActive &&
            <div className="top_margin">
              <div className="button white inline_block"
                   onClick={() => changeUrl('/app/client/overview/meeting_times')}>
                {this.api.trTxt(TrVar.BookNewMeeting)}
              </div>
            </div>
          }
        </div>
      )
    } else if (this.state.client.isActive) {
      return (
        <div className="page_section half">
          <p className="bold blue">{this.api.trTxt(TrVar.NotSelectedCoach)}</p>
          {
            this.state.areas.length === 0 ? (
              <div className="top_margin">
                <p>{this.api.trTxt(TrVar.DevelopmentAreasBeforeCoach)}</p>
                <div className="button top_margin inline_block"
                     onClick={() => changeUrl('/app/client/development_areas?next=/app/client/editCoach')}>
                  {this.api.trTxt(TrVar.Select)} {this.api.trTxt(TrVar.DevelopmentAreas).toLowerCase()}
                </div>
              </div>
            ) : (
              <div className="top_margin">
                <div className="button inline_block" onClick={() => changeUrl('/app/client/editCoach')}>
                  {this.api.trTxt(TrVar.SelectCoach)}
                </div>
              </div>
            )
          }
        </div>
      )
    } else {
      return (
        <div className="page_section">
          <p className="bold blue">{this.api.trTxt(TrVar.AccountInactive)}</p>
          <p className="top_margin">
            {this.api.trTxt(TrVar.InfoInactiveClient)}
          </p>
        </div>
      )
    }
  }

  renderChatCoach = () => {
    return (
      <div className="page_section half r_left_margin_early_break r_top_margin_early_break">
        <div className="flex justify-between">
          <img alt="coach" className="top_margin coach_picture mr-8" src={this.state.coach.picture}/>
          <div>
            <h2>{this.api.trTxt(TrVar.YourChatCoach)}</h2>
            <h4 className="blue top_margin">{this.api.trTxt(TrVar.Name)}</h4>
            <h4>{this.state.coach.firstName} {this.state.coach.lastName}</h4>

            {
              this.props.badges.unread ? (
                <div className="flex mt-4 items-center">
                  <div className="bg_red circle_smaller color_white flex flex-shrink-0 justify-center items-center">
                    !
                  </div>
                  <p className="ml-2">{this.api.trTxt(TrVar.YouHaveUnreadMessages)}n</p>
                </div>
              ) : (
                <p className="top_margin">{this.api.trTxt(TrVar.YouHaveNoUnreadMessages)}</p>
              )
            }

            {
              this.props.badges.unread ? (
                <div className="mt-8 button green inline_block" onClick={() => changeUrl('/app/client/chat')}>
                  {this.api.trTxt(TrVar.ReadMessage)}
                </div>
                ) : (
                <div className="mt-8 button white inline_block" onClick={() => changeUrl('/app/client/chat')}>
                  {this.api.trTxt(TrVar.ToChat)}
                </div>
                )
            }
          </div>
        </div>
      </div>
    )
  }

  renderAIChat = () => {
    return (
      <div className="page_section half r_left_margin_early_break r_top_margin_early_break gradient_pink_purple ">
          <div className="r-flex" style={{height: '100%'}}>
              <div  className='flex r_top_margin left_margin flex-col justify-between dark_blue' >
                  <div>
                        <div className='badge' style={{marginBottom: '20px'}}>
                          <p>Beta</p>
                        </div>
                      <h1 style={{marginBottom: '20px'}}>{this.api.trTxt(TrVar.TestChatCoach)}</h1>
                      <p>{this.api.trTxt(TrVar.MeetAICoach)}</p>
                  </div>
                  <div>
                    {
                      true && 
                        <div className="button green top_margin inline_block"
                            onClick={() => {
                              console.log('clicked')
                              changeUrl('/app/client/chat/ai')
                              this.api.get(`log_click_event?element=try_ai_chat`)
                            }}>
                          {this.api.trTxt(TrVar.GetStarted)}
                        </div>
                    }
                  </div>
              </div>
          </div>
      </div>
    )

  }

  renderChooseChatCoach = () => {
    return (
      <ChatCoachMessage hasBeenChatCoached={this.state.hasBeenChatCoached} hasBeenVideoCoached={this.state.hasBeenVideoCoached} />
    )
  }

  clientLicenseOffer = () => {
    return (
      <div className="page_section half">
        <div className='flex justify-between'>
            <div>
                <h3>{this.api.trTxt(TrVar.YouHaveBeenOffered)} {this.state.clientLicenseOffer.license.package.name}</h3>
              {
                this.state.clientLicenseOffer.lastValidDate >= todayStr() ? (
                  <>
                    <h4
                      className="top_margin">{this.api.trTxt(TrVar.OfferIsValid)} {this.state.clientLicenseOffer.lastValidDate}</h4>
                    <button className="button top_margin"
                            onClick={() => this.acceptOffer()}>{this.api.trTxt(TrVar.Accept)}</button>
                  </>
                ) : (
                  <p
                    className="top_margin">{this.api.trTxt(TrVar.OfferExpired)} {this.state.clientLicenseOffer.lastValidDate}</p>
                )
              }
            </div>
            <img className='left_margin hide_small' src="/assets/illustration/matching.svg" width={266} alt="" />
        </div>
      </div>
    )
  }

  renderRecommendationBox = () => {
    return (
      <>
        <div className="page_section half">
          <div className="flex">
            <div className='flex flex-col'>
              <div className="font_small">
                {this.state.recommendations.subtitle}
              </div>
              <h2>{this.state.recommendations.title}</h2>
              <div className="top_margin">{this.state.recommendations.text}</div>
              <div>
                <div className="button green btn_top_margin inline_block" onClick={() => changeUrl('/app/client/editCoach')}>
                  {this.state.recommendations.cta?.text}
                </div>
              </div>
            </div>
            <img className='r-ml-12' style={{width: "220px"}} src="/assets/illustration/matching.svg" alt="matching" />
          </div>
        </div>
      </>
    )
  }

  renderUpperLeft = () => {
    if (this.state.recommendations?.type === 'box') {
      return this.renderRecommendationBox()
    } else if (this.state.package?.isVideoCoachIncluded) {
      return (
        <>
          {this.coachMeeting()}
          {this.yourCoach()}
        </>
      )
    } else if (this.state.clientLicenseOffer) {
      return this.clientLicenseOffer()
    } else {
      return (
        <FocusAreaLink measures={this.state.measures} focusMeasures={this.state.focusMeasures}
                     focusAreaSettings={this.state.focusAreaSettings} followupArea={this.state.followupArea}/>
      )
    }
  }

  renderMiddleLeft = () => {
    if (this.state.package?.isVideoCoachIncluded || this.state.clientLicenseOffer) {
      return (
        <FocusAreaLink measures={this.state.measures} focusMeasures={this.state.focusMeasures}
                       focusAreaSettings={this.state.focusAreaSettings} followupArea={this.state.followupArea}/>
      )
    } else if (this.state.package) {
      return this.accessCoachRequest()
    } else {
      return (<></>)
    }
  }

  renderUpperRight = () => {

    if(
      (this.state.client.package?.type === PackageType.ACCESS 
      || this.state.client.package?.type === PackageType.MAXIMIZE
      || this.state.client.package?.type === PackageType.OPTIMIZE) && 
      !this.props.clientUISettings?.approved_ai_terms &&
      this.props.hasAiCoach
    ) {
      return this.renderAIChat();
    } else {
      return this.renderBehavioural();
    }

  }

  renderBehavioural = () => {
    return (
      <div className="page_section half r_left_margin_early_break r_top_margin_early_break">
        <h2>{this.api.trTxt(TrVar.YourBehaviourProfile)}</h2>
        {this.state.disa !== null ?
          <div className="flex w-full justify-between">
            <div className="flex flex-col justify-end">
              <div className="button white inline_block" onClick={() => changeUrl('/app/client/behaviour')}>
                {this.api.trTxt(TrVar.ReadMore)}
              </div>
            </div>
            <div style={{width: "min(400px, 80vw)"}}>
              <DisaRomb disa={this.state.disa} color="cerise" height={300}/>
            </div>
          </div>
          :
          <>
            <div className='top_margin'>{this.api.trTxt(TrVar.YouHaveNotCompletedAnyMeasurement)}</div>
            <div className="button white top_margin inline_block" onClick={() => changeUrl('/app/client/behaviour/new')}>
              {this.api.trTxt(TrVar.ToMeasurement)}
            </div>
          </>
        }
      </div>
    )
  }

  saveBooking = () => {
    changeUrl('/app/client/overview')
    this.getData()
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    } else if (this.props.navigation.sub1 === 'meeting_times') {
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.Overview), link: '/app/client/overview'}]
          } name={this.api.trTxt(TrVar.YourBookings)}/>
          <CalendarClientOld close={this.saveBooking} lastBookableDate={this.state.lastBookableDate?.date} />
        </div>
      )
    }

    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.Overview)}/>
        {this.state.recommendations.type === 'banner' &&
          <Recommendations recommendations={this.state.recommendations} submitInterest={this.coachRequest} isCoach={false}/>
        }

        {
          this.state.customerIntroduction?.clientText &&
          <div className="bottom_margin page_section">
            <h4>{this.api.trTxt(TrVar.AboutYourCoaching)}:</h4>
            <p className="mt-4 whitespace-pre-line ">
              {this.state.customerIntroduction.clientText}
            </p>
          </div>
        }

        <div className="r-large-flex">
          {
            this.renderUpperLeft()
          }
          {
            this.renderUpperRight()
          }
        </div>
        

        <div className="flex client_dashboard_direction top_margin">
          {
            this.renderMiddleLeft()
          }
          {
            this.state.package &&
              <FocusAreaSummary measures={this.state.measures} focusMeasures={this.state.focusMeasures}
                                plansDone={this.state.plansDone}/>
          }

        </div>
        <div className="top_margin"/>
        <ClientMeasureSummary measures={this.state.measures} packageType={this.state.package?.type} />
      </div>
    )
  }
}
