import React from 'react'
import Api from "../../services/Api"
import {MeasureArea} from "../../interfaces/MeasureArea";
import Loading from "../Loading";
import {formatDateYear, formatHourMinute, formatSoon} from "../../helpers/dateFunctions";
import Breadcrumbs from "../menu/Breadcrumbs";
import {ClientInterface} from "../../interfaces/Client";
import {MeetingInterface} from "../../interfaces/Meeting";
import {FocusPlanInterface, PlanStatusType} from "../tasks/interfaces";
import MeasureTable from "../measure/MeasureTable";
import {TrVar} from "../../services/translate";
import { TrArray } from '../../services/translate_arrays';
import {IonLabel, IonSegment, IonSegmentButton} from "@ionic/react";

interface MeasureInterface {
  id: number
  version: number
  createdAt: string
  areas: Array<MeasureArea>
  totalCurrent: number
  totalDesired: number
}

interface FocusTaskInterface {
  coachQuestion: string
  measureQuestion: string
  answer: string
  updatedAt: string
}

interface VisionBoardInterface {
  goal: string
  goalOutcome: number
  nextStep: string
  insights: string
  pictures: Array<any>
}

enum ACTION {
  MEETING,
  MEASURE,
  FOCUS_TASK,
  FOCUS_PLAN
}

interface ActionInterface {
  d: string
  nr: number
  actionType: ACTION
  data: any
  hasChat?: boolean
}

interface ChatInterface {
  fromCoach: boolean
  createdAt: string
  message: string
}

interface MeetingChatsInterface {
  [key: string]: Array<ChatInterface>
}

interface ComponentProps{
  close: Function
  coachId?: number
  clientId?: number
  navigation?: any
}

interface ComponentState {
  measures: Array<MeasureInterface>
  meetings: Array<MeetingInterface>
  meetingChats: MeetingChatsInterface,
  plans: Array<FocusPlanInterface>
  client: ClientInterface
  showAction: Array<boolean>
  filterAction: string
  loaded: boolean
}


export default class ClientProgress extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private actions: Array<ActionInterface> = []
  private firstMeasureId = 0

  state: Readonly<ComponentState> = {
    measures: [],
    meetings: [],
    meetingChats: {},
    plans: [],
    showAction: [],
    client: {} as ClientInterface,
    filterAction: 'all',
    loaded: false,
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    let url = 'progress'
    if (this.props.clientId && this.props.coachId) {
      url = 'progress_coach/' + this.props.clientId
    }
    this.api.get(url).then(response => {
      this.populateArrays(response.json)
      const showAction = []
      for (let i = 0; i < this.actions.length; i++) {
        showAction.push(false)
      }
      this.setState({
        measures: response.json.measures,
        meetings: response.json.meetings,
        meetingChats: response.json.meetingChats,
        plans: response.json.plans,
        client: response.json.client,
        showAction: showAction,
        loaded: true
      })
    })
  }

  populateArrays = (data: any) => {
    let actionNumber = 0

    const focusTaskPerDay: {[day: string]: Array<FocusTaskInterface>} = {}

    for (const measure of data.measures) {
      this.firstMeasureId = measure.id
      this.actions.push({
        d: measure.createdAt,
        nr: actionNumber++,
        actionType: ACTION.MEASURE,
        data: measure
      })
    }

    for (const meeting of data.meetings) {
      this.actions.push({
        d: meeting.start,
        nr: actionNumber++,
        actionType: ACTION.MEETING,
        data: meeting,
        hasChat: !!data.meetingChats[meeting.id]
      })
    }

    for (const plan of data.plans) {
      this.actions.push({
        d: plan.updatedAt,
        nr: actionNumber++,
        actionType: ACTION.FOCUS_PLAN,
        data: plan
      })
    }

    for (const focusTask of data.focusTasks as Array<FocusTaskInterface>) {
      const day = focusTask.updatedAt.substring(0, 10)
      if (day in focusTaskPerDay) {
        focusTaskPerDay[day].push(focusTask)
      } else {
        focusTaskPerDay[day] = [focusTask]
      }
    }

    for (const day in focusTaskPerDay) {
      const focusTasks: Array<FocusTaskInterface> = focusTaskPerDay[day]
      this.actions.push({
        d: focusTasks[0].updatedAt,
        nr: actionNumber++,
        actionType: ACTION.FOCUS_TASK,
        data: focusTasks
      })
    }

    this.actions.sort((a, b) => {
      if (a.d < b.d) {
        return 1;
      }
      if (a.d > b.d) {
        return -1;
      }
      return 0;
    })
  }

  toggleAction = (nr: number) => {
    const showAction = this.state.showAction
    showAction[nr] = !showAction[nr]
    this.setState({showAction: showAction})
  }

  getPreviousMeasure = (measure: MeasureInterface) => {
    const measures = this.state.measures.length
    let measureNr = 0
    for (const m of this.state.measures) {
      if (m.id === measure.id) {
        if (measureNr < (measures - 1)) {
          return this.state.measures[measureNr + 1]
        }
      }
      measureNr++
    }
  }

  showMeasure = (measure: MeasureInterface) => {
    let measurePrev: MeasureInterface | undefined = undefined
    if (measure.id > this.firstMeasureId) {
      measurePrev = this.getPreviousMeasure(measure)
    }
    return (
      <div className="border_gray my-4 p-4">
        <h3>
          {this.api.trTxt(TrVar.Measure)} {formatSoon(measure.createdAt, this.api.lang)}
        </h3>
        {
          measurePrev &&
            <p className="mt-4">
              {this.api.trTxt(TrVar.ComparedToPreviousMeasurement) + ' '}
              {formatSoon(measurePrev.createdAt, this.api.lang)}
            </p>

        }
        <MeasureTable measure={measure} measurePrev={measurePrev}/>
      </div>
    )
  }

  showVisionBoard = (d: any) => {
    const data: VisionBoardInterface = JSON.parse(d)

    let goalOutcome = '-'
    if (data.goalOutcome) {
      if (data.goalOutcome === 1) {
        goalOutcome = this.api.trTxt(TrVar.Yes)
      } else if (data.goalOutcome === 2) {
        goalOutcome = this.api.trTxt(TrVar.No)
      } else if (data.goalOutcome === 3) {
        goalOutcome = this.api.trTxt(TrVar.Partly)
      }
    }

    return (
      <div className="mt-4">
        <h3>{this.api.trTxt(TrVar.MeetingNotes)}</h3>
        <h4 className="mt-4">{this.api.trTxt(TrVar.GoalOfTodaysMeeting)}:</h4>
        <p className="whitespace-pre-line">{data.goal ? data.goal : ''}</p>

        <h4 className="mt-4">{this.api.trTxt(TrVar.DidWeReachGoal)}: {goalOutcome}</h4>

        <h4 className="mt-4">{this.api.trTxt(TrVar.WhichActionsDidYouTake)}:</h4>
        <p className="whitespace-pre-line">{data.nextStep ? data.nextStep : '-'}</p>

        <h4 className="mt-4">{this.api.trTxt(TrVar.WhichAreYourKeyTakeaways)}:</h4>
        <p className="whitespace-pre-line">{data.insights ? data.insights : '-'}</p>
        {
          data.pictures && data.pictures.map(pic => {
            return (
              <img alt="v_pic" key={"pic_" + pic.id} className="mr-4" style={{width: "140px"}}
                   src={"https://public.zebrain.se/visionboard/pictures/" + pic.url}/>
            )
          })
        }
      </div>
    )
  }

  showMeetingChats = (meetingId: number) => {
    const chats = this.state.meetingChats[meetingId]
    if (chats && chats.length > 0) {
      return (
        <div className="mt-4">
          <div className="page_bar" />
          <h3>{this.api.trTxt(TrVar.Chat)}</h3>
          {
            chats.map((chat: ChatInterface, i) => {
              const chatFrom = chat.fromCoach ? this.api.trTxt(TrVar.You) : this.state.client.firstName
              return (
                <div className="mt-2" key={"chat_" + meetingId + '_' + i}>
                  <p className="font_smaller">
                    {chatFrom} {formatHourMinute(chat.createdAt)}:
                  </p>
                  <div className="whitespace-pre-line">{chat.message}</div>
                </div>
              )
            })
          }
        </div>
      )
    }
    return (<></>)
  }

  showMeeting = (meeting: MeetingInterface) => {
    return (
      <div className="border_gray my-4 p-4">
        <h3>{this.api.trTxt(TrVar.Meeting)} {formatSoon(meeting.start, this.api.lang)}</h3>
        <p>
          {meeting.length} {this.api.trTxt(TrVar.Minutes).toLowerCase()},
          status: {this.api.trArr(TrArray.MeetingType)[meeting.meetingType]}
        </p>
        <div className="page_bar" />
        {
          meeting.visionBoardData ? this.showVisionBoard(meeting.visionBoardData) : (<p>{this.api.trTxt(TrVar.EmptyVisionBoard)}</p>)
        }
        {this.showMeetingChats(meeting.id)}
      </div>
    )
  }

  showFocusTasks = (focusTasks: Array<FocusTaskInterface>) => {
    let prevMQ = '-'
    let questions = 0
    return (
      <div className="border_gray my-4 p-4">
        {
          focusTasks.map(focusTask => {
            const showQuestion = prevMQ !== focusTask.measureQuestion
            prevMQ = focusTask.measureQuestion
            questions++
            return (
              <div key={'focus_task_' + focusTask.updatedAt}>
                {
                  (questions > 1 && showQuestion) &&
                    <div className="page_bar" style={{ margin:'10px 0 24px 0' }} />
                }
                {
                  showQuestion &&
                    <h3>{focusTask.measureQuestion}</h3>

                }
                <p className="font_smaller mt-4">{formatSoon(focusTask.updatedAt, this.api.lang)}:</p>
                <p className="mt-1">{focusTask.coachQuestion}</p>
                <p className="whitespace-pre-line mt-1"><i>{focusTask.answer}</i></p>
              </div>
            )
          })
        }
      </div>
    )
  }

  showFocusPlan = (focusPlan: FocusPlanInterface) => {
    let completed = '-'
    switch (focusPlan.done) {
      case PlanStatusType.NO: {
        completed = this.api.trTxt(TrVar.No)
        break
      }
      case PlanStatusType.SORT_OF: {
        completed = this.api.trTxt(TrVar.Partially)
        break
      }
      case PlanStatusType.YES: {
        completed = this.api.trTxt(TrVar.Yes)
        break
      }
      default: {
        completed = this.api.trTxt(TrVar.AnswerMissing)
        break
      }
    }

    return (
      <div className="border_gray my-4 p-4">
        <div key={'focus_plan_' + focusPlan.updatedAt}>
          <div className="r-flex">
            <p className="font_smaller">
              {this.api.trTxt(TrVar.Created)}: {formatSoon(focusPlan.createdAt, this.api.lang)}
            </p>
            <p className="font_smaller ml-4">
              {this.api.trTxt(TrVar.LastUpdated)}: {formatSoon(focusPlan.updatedAt, this.api.lang)}
            </p>
          </div>

          <h4 className="mt-4">Plan</h4>
          <p className="mt-1 whitespace-pre-line"><i>{focusPlan.plan}</i></p>

          <h4 className="mt-4">{this.api.trTxt(TrVar.WhenWillYouDoIt)}</h4>
          <p className="whitespace-pre-line mt-1"><i>{focusPlan.when}</i></p>



          <h4 className="mt-4">Deadline:</h4>
          <p className="mt-1"><i>{focusPlan.followUp}</i></p>

          <h4 className="mt-4">{this.api.trTxt(TrVar.Reminder)}:</h4>
          <p className="mt-1"><i>{focusPlan.reminderAt ? formatSoon(focusPlan.reminderAt, this.api.lang) : '-'}</i></p>

          <h4 className="mt-4">{this.api.trTxt(TrVar.PlanCompleted)}:</h4>
          <p className="mt-1"><i>{completed}</i></p>

          {
            focusPlan.done !== 0 &&
            <>
              <h4 className="mt-4">Feedback</h4>
              <p className="whitespace-pre-line mt-1"><i>{focusPlan.feedback}</i></p>
              <p className="mt-4 whitespace-pre-line"><i>{focusPlan.feedbackOther}</i></p>
            </>
          }

        </div>
      </div>
    )
  }

  showMoreAction = (action: ActionInterface) => {
    if (action.actionType === ACTION.MEETING) {
      return this.showMeeting(action.data)
    } else if (action.actionType === ACTION.MEASURE) {
      return this.showMeasure(action.data)
    } else if (action.actionType === ACTION.FOCUS_TASK) {
      return this.showFocusTasks(action.data)
    } else if (action.actionType === ACTION.FOCUS_PLAN) {
      return this.showFocusPlan(action.data)
    }

  }

  formatAction = (action: ActionInterface) => {
    let name = 'Unknown'
    let icon = ''
    if (action.actionType === ACTION.MEASURE) {
      name = this.api.trTxt(TrVar.Measure)
      icon = 'measurement.svg'
    } else if (action.actionType === ACTION.MEETING) {
      name = this.api.trTxt(TrVar.Meeting)
      name += ': ' + this.api.trArr(TrArray.MeetingType)[action.data.meetingType]
      icon = 'calendar.svg'
      if (action.data.visionBoardData) {
        name += ` (${this.api.trTxt(TrVar.MeetingNotes)})`
      }
      if (action.hasChat) {
        name += ' (' + this.api.trTxt(TrVar.Chat) + ')'
      }
    } else if (action.actionType === ACTION.FOCUS_TASK) {
      name = action.data.length + ' ' + (this.api.trTxt(TrVar.Exercises))
      icon = 'arrow_bullseye.svg'
    } else if (action.actionType === ACTION.FOCUS_PLAN) {
      name = 'Plan'
      icon = 'clock.svg'
    }

    return (
      <div className="mt-4 cursor-pointer" key={"action_" + action.nr}>
        <div className="flex" onClick={() => this.toggleAction(action.nr)}>
          <p style={{width: "120px"}}>{formatDateYear(action.d)}</p>
          <p style={{width: "100px"}}>{formatHourMinute(action.d)}</p>
          <div className="flex items-center">
            <img alt={icon} className="icon_small svg_white mr-4" src={"/assets/icon/" + icon}/>
            {name}
          </div>
        </div>
        {
          this.state.showAction[action.nr] && this.showMoreAction(action)
        }
      </div>
    )
  }

  toggleFilter = (filterAction: string|null|undefined) => {
    if (filterAction) {
      this.setState({filterAction: filterAction})
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading />
      )
    }

    let breadcrumbsName = this.api.trTxt(TrVar.WhatTheClientHasDone)
    const breadcrumbs = []
    if (this.props.coachId) {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.state.client.firstName, link: '', linkFunc: this.props.close})
    }
    let actionsFiltered = this.actions
    if (this.state.filterAction === 'meeting') {
      actionsFiltered = actionsFiltered.filter(action => action.actionType === ACTION.MEETING)
    } else if (this.state.filterAction === 'measure') {
      actionsFiltered = actionsFiltered.filter(action => action.actionType === ACTION.MEASURE)
    } else if (this.state.filterAction === 'exercise') {
      actionsFiltered = actionsFiltered.filter(action => action.actionType === ACTION.FOCUS_TASK)
    } else if (this.state.filterAction === 'plan') {
      actionsFiltered = actionsFiltered.filter(action => action.actionType === ACTION.FOCUS_PLAN)
    }

    return (
      <div className="page_content">
        <Breadcrumbs breadcrumbs={breadcrumbs}
          name={breadcrumbsName} />

        <div className="page_section no_width">
          <h1>{breadcrumbsName}</h1>
          <div className="top_margin" style={{maxWidth: "600px"}}>
            <IonSegment value={this.state.filterAction} mode="ios"
                        onIonChange={(e) => this.toggleFilter(String(e.target.value))}>
              <IonSegmentButton value="all">
                <IonLabel>{this.api.trTxt(TrVar.All)}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="meeting">
                <IonLabel>{this.api.trTxt(TrVar.Meeting)}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="measure">
                <IonLabel>{this.api.trTxt(TrVar.Measurement)}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="exercise">
                <IonLabel>{this.api.trTxt(TrVar.Exercises)}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="plan">
                <IonLabel>Plan</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div className="top_margin"/>
          {
            actionsFiltered.map(action => {
              return this.formatAction(action)
            })
          }
        </div>
      </div>
    )
  }
}
