import {IonContent, IonModal, IonPage,} from '@ionic/react'
import React from 'react'
import Api from "../services/Api"
import {RouteComponentProps} from "react-router"
import {LicenseInterface} from "../interfaces/License"
import {PackageInterface, PackageType} from "../interfaces/Package"
import EditLicense from "../components/edit/EditLicense"
import Loading from "../components/Loading";
import {CONFIG} from "../constants";
import {DisaInterface} from "../interfaces/Disa";
import './Onboarding.css'
import {CoachInterface} from "../interfaces/Coach";
import ExternalLogging from "../services/ExternalLogging";
import BehaviourOnboarding from '../components/ClientOnboarding/BehaviourOnboarding'
import PotentialOnboarding from '../components/ClientOnboarding/PotentialOnboarding'
import CoachSectionOnboarding from '../components/ClientOnboarding/CoachSectionOnboarding'
import {ClientLicenseInterface} from "../interfaces/ClientLicense";
import {InputInterface, InputsInterface} from '../components/form/interfaces'
import Input from "../components/form/Input";
import {checkInput} from '../components/form/CheckInput'
import CalendarOnboarding from '../components/ClientOnboarding/CalendarOnboarding'
import AboutOnboarding from '../components/ClientOnboarding/AboutOnboarding'
import { changeUrl } from '../services/Navigation'
import { TrVar } from '../services/translate'

interface ComponentProps extends RouteComponentProps<{}>{}

interface ComponentState {
  license?: LicenseInterface
  clientLicense?: ClientLicenseInterface
  pageNr: number
  page: number
  pages: Array<number>
  disa: Array<DisaInterface>
  measure: number
  coachFree: boolean
  coach?: CoachInterface
  loaded: boolean 
  listen_subpart: number
  send_subpart: number
  finishBehaviouralSection: boolean
  finishPotentialSection: boolean
  finishCoachSection: boolean
  finishCalendarSection: boolean
  packageFree: boolean
  onboardingSupport: boolean
  inputs: InputsInterface
  showFormError: boolean
  sentSupport: boolean  
  firstPotentialMeasure: boolean
  firstBehaviourMeasure: boolean
  missingAbout: boolean
}

enum Pages {
  ChooseLicense,
  AboutSection,
  BehaviouralSection,
  PotentialSection,
  CoachSection,
  CalendarSection,
  Done
}

export default class ClientOnboarding extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private scrollRef: any
  private externalLogging = ExternalLogging.getInstance()
  urlSearchParams: any;

  constructor(props: ComponentProps) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      page: Pages.ChooseLicense,
      pages: [],
      disa: [],
      pageNr: 0,
      measure: 0,
      coachFree: false,
      loaded: false,
      listen_subpart: 0, 
      send_subpart: 0,
      finishBehaviouralSection: false,
      finishPotentialSection: false,
      finishCoachSection: false,
      finishCalendarSection: false,
      license: {} as LicenseInterface,
      packageFree: false,
      onboardingSupport: false,
      inputs: this.inputs,
      showFormError: false,
      sentSupport: false,    
      coach: {} as CoachInterface,
      firstPotentialMeasure: true,
      firstBehaviourMeasure: true,
      missingAbout: true,
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', this.backListener)
    this.setURLSearch()
    this.externalLogging.enable()
    this.getData()
  }

  inputs: InputsInterface = {
    support: {
      type: 'textarea',
      name: 'support',
      placeholder: this.api.trTxt(TrVar.WriteQuestion),
      value: '',
      error: '',
      maxLength: 5000,
      minLength: 1
    },
  }  


  inputChange = (input: InputInterface) => {
    this.inputs[input.name] = input

    const formError = this.checkAllErrors() > 0

    this.setState({inputs: this.inputs, showFormError: formError})
  }  

  private checkAllErrors = () => {
    let errors = 0
    for (const name in this.inputs) {
        const input = this.inputs[name]
        if (checkInput(input, 'sv').length > 0) {
          errors += 1
        }
    }
    this.setState({
      showFormError: errors > 0
    })
    return errors
  }
  
  sendSupportMessage =  () => {
    if (this.checkAllErrors() > 0) {
      return
    } 

    const data = {
      type: 'support',
      url: document.location.pathname,
      message: this.inputs.support.value
    }
    
    this.api.post('feedback', data).then(_response => {
      this.setState({ sentSupport: true, onboardingSupport: false })
      this.inputs.support.value = ''
    })
  }  

  setURLSearch = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
    this.urlSearchParams = searchParams;
    return searchParams;
  }

  componentWillUnmount() {
    this.externalLogging.disable()
    window.removeEventListener('popstate', this.backListener)
  }

  backListener = (event: PopStateEvent)  => {
    if (event.currentTarget) {
      // @ts-ignore
      const urlParts = event.currentTarget.location.pathname.split('/')
      if (urlParts.length === 4 && urlParts[2] === 'client_onboarding') {
        const pageNrFromUrl = parseInt(urlParts[3])
        let page = 0
        if (pageNrFromUrl && pageNrFromUrl < this.state.pages.length) {
          page = this.state.pages[pageNrFromUrl]
          if (page > 0) {
            this.setState({page: page, pageNr: pageNrFromUrl})
          }
        }
      }
    }
  }

  gotLicense = (license: LicenseInterface, clientLicense: ClientLicenseInterface) => {
    const pages = this.getPages(license.package)
    const coachFree = !license.package.isVideoCoachIncluded
    this.setState({license: license, pages: pages, coachFree: coachFree, clientLicense: clientLicense})
  }

  private getPage = (data: any, coachFree: boolean) => {

    const isIncognito: boolean = (data.client?.isIncognito || this.urlSearchParams.incognito === "1")
    if (!data.license) {
      return Pages.ChooseLicense
    }
    if (data.missingFields.length > 0 && !isIncognito) {
      return Pages.AboutSection
    }
    if (!data.disa) {
      return Pages.BehaviouralSection
    }
    if (data.measure === 0) {
      return Pages.PotentialSection
    }
    if (!coachFree && !data.coach) {
      return Pages.CoachSection
    }
    if (!coachFree && data.client.areas.length === 0) {
      return Pages.CoachSection
    }
    return Pages.Done
  }

  getPageNr = (page: number, pages: Array<number>) => {
    let pageNr = 0
    for (const p of pages) {
      if (p === page) {
        return pageNr
      }
      pageNr++
    }
    return 0
  }

  getPages = (clientPackage?: PackageInterface) => {
    if (clientPackage?.isVideoCoachIncluded) {
      return [
        Pages.ChooseLicense,
        Pages.AboutSection,
        Pages.BehaviouralSection,
        Pages.PotentialSection,
        Pages.CoachSection,
        Pages.CalendarSection,
        Pages.Done
      ]
    } else {
      if (clientPackage?.type === PackageType.FREE) {
        this.setState({ packageFree: true })
        if (this.urlSearchParams.incognito === "1") {
          return [
            Pages.ChooseLicense,
            Pages.BehaviouralSection,
            Pages.PotentialSection,
            Pages.Done
          ]
        } else {
          return [
            Pages.ChooseLicense,
            Pages.AboutSection,
            Pages.BehaviouralSection,
            Pages.PotentialSection,
            Pages.Done
          ]
        }

      }
      return [
        Pages.ChooseLicense,
        Pages.AboutSection,
        Pages.BehaviouralSection,
        Pages.PotentialSection,
        Pages.Done
      ]
    }
  }

  setOnboarded = (redirect: boolean) => {
    this.api.post('client_onboarded', {}).then(response => {
      if (redirect) {
        window.location.href = "/app/client/overview"
      }
    })
  }


  private getData = () => {
    this.api.get('client_onboarding').then(response => {
      const coachFree = !response.json.license?.package?.isVideoCoachIncluded
      const pages = this.getPages(response.json.license?.package)

      let page
      if (CONFIG.SYSTEM !== 'prod' && this.urlSearchParams.page === 'restart') {
        page = Pages.AboutSection
      } else {
        page = this.getPage(response.json, coachFree)
      }

      const pageNr = this.getPageNr(page, pages)

      if (page === Pages.Done) {
        if (response.json.client.onboarded) {
          window.location.href = "/app/client/overview"
        } else {
          this.setOnboarded(true)
        }
        return
      }
      let url = "/app/client_onboarding/" + pageNr
      if (this.urlSearchParams.package) {
        url += "?package=" + this.urlSearchParams.package
      }
      this.props.history.replace(url)

      this.setState({
        license: response.json.license,
        clientLicense: response.json.clientLicense,
        pageNr: pageNr,
        page: page,
        pages: pages,
        coach: response.json.coach,
        disa: response.json.disa,
        measure: response.json.measure,
        loaded: true,
        coachFree: coachFree,
      })
    })
  }

  private next = () => {
    const numberSteps = this.state.pages.length;
    this.scrollToTop()

    if (numberSteps === 7) {
      if (this.state.page === Pages.ChooseLicense) {
        this.setState({ page: Pages.AboutSection, pageNr: 1, listen_subpart: 0, send_subpart: 0 })
        this.props.history.push("/app/client_onboarding/1")  
      } else if (this.state.page === Pages.AboutSection) {
        this.setState({ page: Pages.BehaviouralSection, pageNr: 2, listen_subpart: 0, send_subpart: 0 })
        this.props.history.push("/app/client_onboarding/" + 2)
      } else if (this.state.page === Pages.BehaviouralSection) {
        if (this.state.listen_subpart === 3) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishBehavioural();
        } else if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.PotentialSection, pageNr: 3, listen_subpart: 0, send_subpart: 0 })
          this.props.history.push("/app/client_onboarding/" + 3)
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } else if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 3) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishPotential();
        } else if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.CoachSection, send_subpart: 0, listen_subpart: 0, pageNr: 4 })
          this.props.history.push("/app/client_onboarding/" + 4)
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } else if (this.state.page === Pages.CoachSection) {
        if (this.state.listen_subpart === 4) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishCoach();
        } else if (this.state.listen_subpart === 5) {
          this.setState({ page: Pages.CalendarSection, send_subpart: 0, listen_subpart: 0, pageNr: 5 })
          this.props.history.push("/app/client_onboarding/" + 5)
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } else if (this.state.page === Pages.CalendarSection) {
        if (this.state.listen_subpart === 2) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishCalendar();
        } else if (this.state.listen_subpart === 3) {
          this.setState({ page: Pages.Done })
          this.props.history.push("/app/client/overview")
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      }      
    } else if (numberSteps === 5) {
      if (this.state.page === Pages.ChooseLicense) {
        this.setState({ page: Pages.AboutSection, pageNr: 1, listen_subpart: 0, send_subpart: 0 })
        this.props.history.push("/app/client_onboarding/1")  
      } else if (this.state.page === Pages.AboutSection) {
        this.setState({ page: Pages.BehaviouralSection, pageNr: 2, listen_subpart: 0, send_subpart: 0 })
        this.props.history.push("/app/client_onboarding/" + 2)
      } else if (this.state.page === Pages.BehaviouralSection) {
        if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.PotentialSection, pageNr: 3, listen_subpart: 0, send_subpart: 0 })
          this.props.history.push("/app/client_onboarding/" + 3)
        } else if (this.state.listen_subpart === 3) {
            this.addToProgress(this.state.send_subpart + 1)
            this.finishBehavioural();
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } else if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 3) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishPotential();
        } else if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.Done })
          this.props.history.push("/app/client/overview")
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      }        
    } else {
      if (this.state.page === Pages.ChooseLicense) {
        this.setState({ page: Pages.BehaviouralSection, pageNr: 2, listen_subpart: 0, send_subpart: 0 })
        this.props.history.push("/app/client_onboarding/2")   
      } else if (this.state.page === Pages.BehaviouralSection) {
        if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.PotentialSection, pageNr: 3, listen_subpart: 0, send_subpart: 0 })
          this.props.history.push("/app/client_onboarding/" + 3)
        } else if (this.state.listen_subpart === 3) {
            this.addToProgress(this.state.send_subpart + 1)
            this.finishBehavioural();
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } else if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 3) {
          this.addToProgress(this.state.send_subpart + 1)
          this.finishPotential();
        } else if (this.state.listen_subpart === 4) {
          this.setState({ page: Pages.Done })
          this.props.history.push("/app/client/overview")
        } else {
          this.addToProgress(this.state.send_subpart + 1)
        }
      } 
    }
  }

  togglePotentialMeasure = (bool: boolean) => {
    this.setState({ firstPotentialMeasure: bool })
  }
   
  toggleBehaviourMeasure = (bool: boolean) => {
    this.setState({ firstBehaviourMeasure: bool })
  }

  toggleMissingAbout = (bool: boolean) => {
    this.setState({ missingAbout: bool })
  }

  private scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.getScrollElement().then((el: HTMLElement) => {
        this.scrollRef.current.scrollToTop();
      });
    }
  };

  private goHome = () => {
    let homePage = CONFIG.HOME_PAGE
    if (this.api.lang === 'en') {
      homePage += 'en/'
    }
    window.location.href = homePage
  }

  private previous = () => {
    const numberSteps = this.state.pages.length;
    this.scrollToTop()

    if (numberSteps === 7) {
      if (this.state.page === Pages.AboutSection) {
        this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
      } else if (this.state.page === Pages.BehaviouralSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.AboutSection, pageNr: 1, listen_subpart: 0, send_subpart: 0 })
          this.props.history.push("/app/client_onboarding/" + 1)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      } else if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.BehaviouralSection, send_subpart: 3, listen_subpart: 3, pageNr: 2 })
          this.props.history.push("/app/client_onboarding/" + 2)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      } else if (this.state.page === Pages.CoachSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.PotentialSection, send_subpart: 3, listen_subpart: 3, pageNr: 3 })
          this.props.history.push("/app/client_onboarding/" + 3)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      } else if (this.state.page === Pages.CalendarSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.CoachSection, send_subpart: 4, listen_subpart: 4, pageNr: 4 })
          this.props.history.push("/app/client_onboarding/" + 4)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      }      
    } else if (numberSteps === 5) {
      if (this.state.page === Pages.AboutSection) {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
      } else if (this.state.page === Pages.BehaviouralSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.AboutSection, pageNr: 1 })
          this.props.history.push("/app/client_onboarding/" + 1)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      } else if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.BehaviouralSection, send_subpart: 3, listen_subpart: 3, pageNr: 2 })
          this.props.history.push("/app/client_onboarding/" + 2)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      }     
    } else {
      if (this.state.page === Pages.PotentialSection) {
        if (this.state.listen_subpart === 0) {
          this.setState({ page: Pages.BehaviouralSection, send_subpart: 3, listen_subpart: 3, pageNr: 2 })
          this.props.history.push("/app/client_onboarding/" + 2)
        } else {
          this.setState({ send_subpart: this.state.listen_subpart - 1, listen_subpart: this.state.listen_subpart - 1 })
        }
      }        
    }

  }  

  addToProgress = (part:number) => {
    this.setState({ listen_subpart: part, send_subpart: part })
  }

  finishBehavioural = () => {
    this.setState({ finishBehaviouralSection: true })
  }

  finishPotential = () => {
    this.setState({ finishPotentialSection: true })
  }
  
  finishCoach = () => {
    this.setState({ finishCoachSection: true })
    this.setOnboarded(false)
  }

  finishCalendar = () => {
    this.setState({ finishCalendarSection: true })
    // Set client as onboarded here, since some clients don't click last step
    this.setOnboarded(false)
  }

  showProgressBar = () => {
    let barWidth;
    const numberSteps = this.state.pages.length;

    if (this.state.page === Pages.BehaviouralSection) {
      if (this.state.listen_subpart === 0) {
        barWidth = (numberSteps === 7 ? '4.6%' : '10%')
      } else if (this.state.listen_subpart === 1) {
        barWidth = (numberSteps === 7 ? '9.2%' : '20%')
      } else if (this.state.listen_subpart === 2) {
        barWidth = (numberSteps === 7 ? '13.8%' : '30%')
      } else if (this.state.listen_subpart === 3) {
        barWidth = (numberSteps === 7 ? '18.4%' : '40%')
      } else if (this.state.listen_subpart === 4) {
        barWidth = (numberSteps === 7 ? '24%' : '50%')
      }
    }
    
    if (this.state.page === Pages.PotentialSection) {
      if (this.state.listen_subpart === 0) {
        barWidth = (numberSteps === 7 ? '29.6%' : '60%')
      } else if (this.state.listen_subpart === 1) {
        barWidth = (numberSteps === 7 ? '32.2%' : '70%')
      } else if (this.state.listen_subpart === 2) {
        barWidth = (numberSteps === 7 ? '36.8%' : '80%')
      } else if (this.state.listen_subpart === 3) {
        barWidth = (numberSteps === 7 ? '41.4%' : '90%')
      } else if (this.state.listen_subpart === 4) {
        barWidth = (numberSteps === 7 ? '49%' : '100%')
      }
    }

    if (this.state.page === Pages.CoachSection) {
      if (this.state.listen_subpart === 0) {
        barWidth = ('54.6%')
      } else if (this.state.listen_subpart === 1) {
        barWidth = ('59.2%')
      } else if (this.state.listen_subpart === 2) {
        barWidth = ('63.8%')
      } else if (this.state.listen_subpart === 3) {
        barWidth = ('68.4%')
      } else if (this.state.listen_subpart === 4) {
        barWidth = ('71%')
      } else if (this.state.listen_subpart === 5) {
        barWidth = ('74%')
      }
    }

    if (this.state.page === Pages.CalendarSection) {
      if (this.state.listen_subpart === 0) {
        barWidth = ('82%')
      } else if (this.state.listen_subpart === 1) {
        barWidth = ('88%')
      } else if (this.state.listen_subpart === 2) {
        barWidth = ('94%')
      } else if (this.state.listen_subpart === 3) {
        barWidth = ('100%')
      }
    }    
    
    if (this.state.page !== Pages.AboutSection && this.state.page !== Pages.ChooseLicense) {
      return (
        <div className='relative onboarding_progressbar'>
          <div className='onboarding_progressbar_progress' style={{ width: barWidth }} />
          { numberSteps === 7 ?
            <>
              <div style={{ left: '23%'}} className={'onboarding_progress_dot ' + (this.state.finishBehaviouralSection ? 'passed_progress' : '')} />
              <div style={{ left: '48%'}} className={'onboarding_progress_dot ' + (this.state.finishPotentialSection ? 'passed_progress' : '')} />
              <div style={{ left: '73%'}} className={'onboarding_progress_dot ' + (this.state.finishCoachSection ? 'passed_progress' : '')} />
              <div style={{ left: '98%'}} className={'onboarding_progress_dot ' + (this.state.finishCalendarSection ? 'passed_progress' : '')} />            
            </>
            :
            <>
              <div style={{ left: '48%'}} className={'onboarding_progress_dot ' + (this.state.finishBehaviouralSection ? 'passed_progress' : '')} />
              <div style={{ left: '98%'}} className={'onboarding_progress_dot ' + (this.state.finishPotentialSection ? 'passed_progress' : '')} />            
            </>            
          }
        </div>
      )      
    }

  }

  getForwardClass = () => {
    let showForward = true
    if (this.state.page === Pages.ChooseLicense) {
      showForward = false;
    } else if (this.state.page === Pages.AboutSection) {
      if (this.state.listen_subpart === 1 || !this.state.missingAbout) {
        showForward = true
      } else {
        showForward = false;
      }
    } else if (this.state.page === Pages.BehaviouralSection && (this.state.listen_subpart === 2 && this.state.firstBehaviourMeasure)) {
      showForward = false;
    } else if (this.state.page === Pages.PotentialSection && (this.state.listen_subpart === 2 && this.state.firstPotentialMeasure)) {
      showForward = false;
    } else if (this.state.page === Pages.CoachSection && (this.state.listen_subpart === 1 || this.state.listen_subpart === 2 || this.state.listen_subpart === 4)) {
      showForward = false;
    }

    if (CONFIG.SYSTEM === 'prod') {
      return 'display_none'
    } else {
      if (showForward) {
        return "green visibility_visible"
      } else {
        return "green button_disabled"
      }      
    }
  }

  getBackClass = () => {
    const numberSteps = this.state.pages.length;
    let showBack = true;

    if (this.state.pageNr <= 1) {
      if (this.state.page === Pages.AboutSection && this.state.listen_subpart === 1) {
        showBack = true;
      } else {
        showBack = false;
      }
    } else if (numberSteps !== 7) {
      if (this.state.page === Pages.BehaviouralSection && this.state.listen_subpart === 0) {
        showBack = false;
      }
    }

    if (showBack) {
      return 'visibility_visible'
    } else {
      return 'visibility_hidden'
    }
  }

  measureDone = (id: number) => {
    this.setState({measure: id})
  }

  selectCoach = (coach: CoachInterface) => {
    this.setState({ coach: coach })
  }

  finishOnboardingGoToDev = () => {
    this.setOnboarded(false)
    this.props.history.push("/app/client/overview")
    changeUrl("/app/client/tasks2")
  }

  finishOnboardingGoToChat = () => {
    this.setOnboarded(false)
    this.props.history.push("/app/client/overview")
    changeUrl("/app/client/chat")
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    const backClass = this.getBackClass()
    const forwardClass = this.getForwardClass()

    return (
      <IonPage>
        <IonContent scrollEvents={true} ref={this.scrollRef} className="app_bg">
          <IonModal className='modal_medium' isOpen={this.state.onboardingSupport} onDidDismiss={() => this.setState({ onboardingSupport: false })}>
            <div style={{ textAlign: 'right', padding: '10px' }}>
              <img className='cursor-pointer' onClick={() => this.setState({onboardingSupport: false})} src="/assets/icon/white/cross.svg" alt="cross" />
            </div>
            <div style={{margin: '0 auto', paddingTop: '75px'}}>
              <h2 style={{ color: '#fff' }}>{this.api.trTxt(TrVar.AskUs)}</h2>
              <h4 className='blue support_sub_title'>{this.api.lang === 'sv' ? 'Behöver du hjälp? Ställ din fråga här så återkommer vi så snart som möjligt.' : `Do you need help? Ask your question here and we'll get back to you as soon as possible.`}</h4>
              <Input data="support-input" input={this.inputs.support} update={this.inputChange} classes="page_text_area" language={this.api.lang} />
              {
                this.state.showFormError && <p className="top_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>
              }
              {
                this.state.sentSupport && <p style={{ color: '#fff' }}>{this.api.trTxt(TrVar.MsgSent)}</p>
              }
              <div className='flex justify-end support_button_div'>
                <div className="button" onClick={() => this.sendSupportMessage()}>{this.api.trTxt(TrVar.Send)}</div>
              </div>                
            </div>

          </IonModal>
          <div className="page_content onboarding h-full">
            <div className="onboarding_header">
              <div className="onboarding_logo cursor-pointer flex-shrink-0" onClick={() => this.goHome()}/>
              <div className="flex-grow mx-8 hide_small">
                {this.showProgressBar()}
              </div>

              <div className="flex items-center ml-4">
                <div className={"button white " + backClass} onClick={() => this.previous()}>
                  {this.api.trTxt(TrVar.Back)}
                </div>
                {this.state.page !== Pages.CalendarSection &&  <div className={"ml-4 button " + forwardClass} onClick={() => this.next()}>
                  {this.api.trTxt(TrVar.Next)}
                </div>}
              </div>
            </div>

            <div style={{ marginBottom: '20px' }} className="w-full show_small">
              {this.showProgressBar()}
            </div>

            {
              this.state.page === Pages.ChooseLicense &&
                <EditLicense license={this.state.license} gotLicense={this.gotLicense}
                             saveText={this.api.trTxt(TrVar.Continue)}
                             licenseCode={this.urlSearchParams.package}
                             save={this.next}/>
            }
            {
              this.state.page === Pages.AboutSection &&
              <AboutOnboarding save={this.next} who="client" scrollTop={this.scrollToTop}
                               missingInfo={this.toggleMissingAbout}
                               licenseCode={this.urlSearchParams.package} goTo={this.state.send_subpart} progress={this.addToProgress}/>
            }
            {
              this.state.page === Pages.BehaviouralSection &&
              <BehaviourOnboarding finishSection={this.finishBehavioural} goTo={this.state.send_subpart} progress={this.addToProgress} save={this.next} 
                                   packageFree={this.state.packageFree} coachFree={this.state.coachFree}
                                   firstMeasure={this.toggleBehaviourMeasure}
                                   scrollTop={this.scrollToTop} />
            }
            {
              this.state.page === Pages.PotentialSection &&
              <PotentialOnboarding finishSection={this.finishPotential} goTo={this.state.send_subpart}
                                   progress={this.addToProgress} coachFree={this.state.coachFree} packageFree={this.state.packageFree}
                                   scrollToTop={this.scrollToTop} measure={this.state.measure}
                                   measureDone={this.measureDone} license={this.state.license}
                                   firstMeasure={this.togglePotentialMeasure}
                                   goToDev={this.finishOnboardingGoToDev}
                                   save={this.next} />
            }
            { 
              this.state.page === Pages.CoachSection &&
              <CoachSectionOnboarding 
                scrollTop={this.scrollToTop} 
                selectCoach={this.selectCoach} 
                finishSection={this.finishCoach} 
                goTo={this.state.send_subpart} progress={this.addToProgress} 
                coachFree={this.state.coachFree} save={this.next}
                license={this.state.license} 
              />
            }
            {
              this.state.page === Pages.CalendarSection &&
              <CalendarOnboarding scrollToTop={this.scrollToTop} coach={this.state.coach}
                                  clientLicense={this.state.clientLicense} license={this.state.license} 
                                  finishSection={this.finishCalendar} goTo={this.state.send_subpart} 
                                  goToDev={this.finishOnboardingGoToDev}
                                  goToChat={this.finishOnboardingGoToChat}
                                  progress={this.addToProgress} close={this.next} onBoarding={true} />
            }
            <div className="onboarding_support top_margin flex justify-end">
              <div onClick={() => this.setState({ onboardingSupport: true, sentSupport: false })} className="onboarding_questionmark text_center">
                ?
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
