import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import { TrVar } from "../../services/translate"

interface ComponentProps {
  next: Function
}

interface ComponentState {
  coachType: number
  error: string
  loaded: boolean
  message: string
}

export default class CoachOnboardingStart extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    coachType: 0,
    loaded: false,
    error: '',
    message: '',
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    this.api.get('coach').then(response => {
      this.setState({
        coachType: response.json.coach.coachType,
        loaded: true
      })
    })
  }

  selectType = (coachType: number) => {
    this.setState({coachType:coachType})
  }

  checkForm = () => {
    if (this.state.coachType < 1) {
      this.setState({error: this.api.trTxt(TrVar.MissingInput), message: ''})
      return false
    }

    this.setState({error: ''})
    return true
  }

  save = () => {
    if (this.checkForm()) {
      const data = {
        coach_type: this.state.coachType,
      }
      this.api.post('coach_type_save', data).then(_response => {
        this.props.next()
      })
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    return (
      <div>
        <h1 className="top_margin">{this.api.trTxt(TrVar.WelcomeAboard)}!</h1>
        <p className="top_margin">
          {this.api.trTxt(TrVar.CoachOnboardingIntro1)}:
        </p>
        <p className="top_margin">
          {this.api.trTxt(TrVar.CoachOnboardingIntro2)}
        </p>

        {/*<div className="mt-8 border_gray p-4">*/}
        {/*  <div>*/}
        {/*    <div onClick={() => this.selectType(1)}>*/}
        {/*      <div className={"select_box" + (this.state.coachType === 1 ? " selected" : "")}/>*/}
        {/*      <div className="ml-4 inline_block"><b>Trainer coach</b></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="mt-4">*/}
        {/** THIS TRANSLATION DOES NOT EXIST !! */}
        {/*    {this.api.trVar('coach_onboarding_trainer')}*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="top_margin border_box">
          <div>
            <div onClick={() => this.selectType(2)}>
              <div className={"select_box" + (this.state.coachType === 2 ? " selected" : "")}/>
              <div className="left_margin inline_block"><b>Subscription coach</b></div>
            </div>
          </div>
          <div className="top_margin">
            {this.api.trTxt(TrVar.CoachOnboardingSubscription)}
          </div>
        </div>

        <div className="top_margin border_box">
          <div>
            <div onClick={() => this.selectType(3)}>
              <div className={"select_box" + (this.state.coachType === 3 ? " selected" : "")}/>
              <div className="left_margin inline_block"><b>Inside coach</b></div>
            </div>
          </div>
          <div className="top_margin">
            {this.api.trTxt(TrVar.CoachOnboardingInside)}
          </div>
        </div>

        {
          this.state.error.length > 0 &&
          <div className="error_box top_margin">{this.state.error}</div>
        }

        {
          this.state.message.length > 0 &&
          <div className="message_box top_margin">{this.state.message}</div>
        }

        <div className="text_center top_margin">
          <div className="button inline_block" onClick={() => this.save()}>{this.api.trTxt(TrVar.Next)}</div>
        </div>
        
      </div>
    )
  }
}
