import React from 'react'
import Api from "../../services/Api";
import {MeetingSchemaInterface} from "./interface";
import {IonModal} from "@ionic/react";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  meetingSchema?: MeetingSchemaInterface
  clientName: string
  includeHeader?: boolean
}

interface ComponentState {
  expand: boolean
}

export default class MeetingSchema extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  hadMeetings = 0
  futureBookedMeetings = 0
  totalStatus = 0

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      expand: false,
    }
    this.calculateKeyNumbers()
  }

  calculateKeyNumbers = () => {
    if (!this.props.meetingSchema) {
      return
    }
    for (let meeting of this.props.meetingSchema.meetings) {
      if (!meeting.isCancelled) {
        if (meeting.isPassed) {
          this.hadMeetings++
        } else {
          this.futureBookedMeetings++
        }
      }
    }
  }

  getStatusColor = (periodStartPos: number) => {
    if (this.props.meetingSchema && periodStartPos > this.props.meetingSchema.todayPos) {
      return '#ccc'
    } else  {
      return '#0a0'
    }
  }

  getTotalColor = () => {
    if (!this.props.meetingSchema) {
      return '#aaa'
    }
    switch(this.props.meetingSchema.currentPeriodOutcome) {
      case "DONE": {
        return '#0a0'
      }
      case "ON_TRACK": {
        return '#0a0'
      }
      case "BEHIND_ONE": {
        return '#ff0'
      }
      default: {
        return '#f00'
      }
    }
  }

  showHeaderSummary = () => {
    if (!this.props.meetingSchema) {
      return (<>{this.api.trTxt(TrVar.NA)}</>)
    }
    if (this.props.meetingSchema.unbookedMeetingCount === 0) {
      return (<span>{this.api.trTxt(TrVar.Done)}</span>)
    }
    switch(this.props.meetingSchema.currentPeriodOutcome) {
      case "DONE": {
        return (<span>{this.api.trTxt(TrVar.OnTrack)}</span>)
      }
      case "ON_TRACK": {
        return (<span>{this.api.trTxt(TrVar.OnTrack)}</span>)
      }
      case "BEHIND_ONE": {
        return (<span>{this.api.trTxt(TrVar.ABitBehind)}</span>)
      }
      case "BEHIND_MANY": {
        return (<span>{this.api.trTxt(TrVar.MuchBehind)}</span>)
      }
      case "AT_RISK": {
        return (<span>{this.api.trTxt(TrVar.Warning)}</span>)
      }
      case "AT_HIGH_RISK": {
        return (<span>{this.api.trTxt(TrVar.Warning)}</span>)
      }
      default: {
        return (<span>{this.api.trTxt(TrVar.MissingStatus)}</span>)
      }
    }
  }

  showSummary = () => {
    if (!this.props.meetingSchema) {
      return (<></>)
    }
    if (["DONE", "ON_TRACK"].indexOf(this.props.meetingSchema.currentPeriodOutcome) < 0) {
      return (
        <p className="top_margin">
          {
            this.api.trTxt(TrVar.ClientIsBehindSchedule)
          }
        </p>
      )
    }
    return (<></>)
  }

  drawSvg = () => {
    let fontSize = 10
    const width = 600

    if (!this.props.meetingSchema) {
      return (<></>)
    }

    return (
      <svg viewBox={"0 0 " + width + " 54"} xmlns="http://www.w3.org/2000/svg">
        {
          this.props.meetingSchema.periods.map((period, i) => {
            const x = period.startPos * width
            const periodWidth = (period.endPos - period.startPos) * width - 1
            const fill = this.getStatusColor(period.startPos)
            return (
              <rect key={"period_" + i}
                    x={x} y={16} width={periodWidth} height={16} fill={fill}/>
            )
          })
        }
        {
          this.props.meetingSchema.meetings.map((meeting, i) => {
            const x = meeting.pos * width
            let y = 24
            if (meeting.sameDay === 1) {
              y = 30
            } else if (meeting.sameDay === 2) {
              y = 18
            }
            return (
              <circle cx={x} cy={y} r={3} key={"meeting_" + i} fill="#333"/>
            )
          })
        }
        <text x={(this.props.meetingSchema.todayPos * width)} y={48} fontSize={fontSize + "px"}
              fill="#ffffff" textAnchor="middle">
          {this.api.trTxt(TrVar.Today)}
        </text>

        <line x1={(this.props.meetingSchema.todayPos * width)}
              x2={(this.props.meetingSchema.todayPos * width)} y1={16} y2={34}
              stroke="#ffffff" strokeWidth={2}/>
      </svg>
    )
  }

  render() {
    return (
      <div >
        <IonModal isOpen={this.state.expand} onDidDismiss={() => this.setState({expand: false})}
                  className="modal_medium">
          <div className="p-4 color_white">
            <div className="flex justify-between">
            <div/>
              <h2 className="text_center">
                {
                  this.api.trTxt(TrVar.MeetingBookings)
                }
              </h2>
              <img alt="close" src="/assets/icon/white/close-circle.svg" className="cursor-pointer icon_medium"
                   onClick={() => this.setState({expand: false})}/>
            </div>

            {
              this.props.meetingSchema ? (
                <>
                  <div className="top_margin flex justify-between">
                    <div>
                      <p>{this.api.trTxt(TrVar.Start)}:</p>
                      <p>{this.props.meetingSchema.startDate}</p>
                    </div>
                    <div>
                      <p>
                        {
                          this.api.trTxt(TrVar.LastDay)
                        }
                      </p>
                      <p>{this.props.meetingSchema.endDate}</p>
                    </div>
                  </div>

                  {this.drawSvg()}

                  <p className="top_margin">
                    {this.props.meetingSchema.periods.length}&nbsp;
                    {
                      this.api.trTxt(TrVar.MeetingIncludedInLicense).toLowerCase()
                    }
                  </p>
                  <p className="top_margin">
                    {this.hadMeetings}&nbsp;
                    {
                      this.api.trTxt(TrVar.MeetingsDone).toLowerCase()
                    }
                  </p>
                  <p className="top_margin">
                    {this.futureBookedMeetings}&nbsp;
                    {
                      this.api.trTxt(TrVar.FutureMeetingsBooked).toLowerCase()
                    }
                  </p>
                  <p className="top_margin">
                    {this.props.meetingSchema.unbookedMeetingCount}&nbsp;
                    {
                      this.api.trTxt(TrVar.MeetingsRemainingToBook).toLowerCase()
                    }
                  </p>
                  {
                    this.showSummary()
                  }
                  <div className="mt-12 font_small">
                    {
                      this.api.trTxt(TrVar.EachBoxInTheBarShowsOnMeetingPeriod)
                    }
                    &nbsp;{this.props.clientName}&nbsp;
                    {
                      this.api.trTxt(TrVar.HasBookedOneMeetingPerPeriod)
                    }

                  </div>
                </>
              ) : (
                <div className="top_margin">
                  <p>
                    {
                      this.api.trTxt(TrVar.MeetingStatusIsNotAvailable)
                    }
                  </p>
                  <p className="top_margin">
                    {
                      this.api.trTxt(TrVar.ThisIsBecauseTheLicenseLacksEndDateOrLimitedNumberOfMeetings)
                    }
                  </p>
                </div>
              )
            }


            <div className="top_margin font_small">
              <p className="red">BETA</p>
              <p>
              {
                this.api.trTxt(TrVar.IfYouDontFollowGetInTouch)
              }
              </p>
            </div>
          </div>
        </IonModal>
        <div className="flex items-center cursor-pointer"
             onClick={() => this.setState({expand: !this.state.expand})}>
          <div className="circle_medium"
               style={{backgroundColor: this.getTotalColor()}}/>

          <div className='left_margin align-center text_underline'>
            {
              this.props.includeHeader ?
                this.api.trTxt(TrVar.MeetingStatus) + ': '
                :
                ''
            }
            {this.showHeaderSummary()}
          </div>
        </div>
      </div>

    )
  }
}
