import React, { useState, useEffect } from 'react'
import Api from "../../services/Api"
import { ClientInterface } from '../../interfaces/Client';
import { IonModal } from '@ionic/react';
import './ClientTest.css'

interface ComponentProps {
  navigation: any
}

const ClientDashboardTest = (props: ComponentProps) => {
  const [client, setClient] = useState({} as ClientInterface)
  const [showSmallModal, setShowSmallModal] = useState(false)
  const [showMediumModal, setShowMediumModal] = useState(false)
  const api = Api.getInstance();
  
  useEffect(() => {
    const getData = () => {
      api.get('client?recommendations=1&meetingSummary=1&behaviour=1&measures=1&customerIntroduction=1')
        .then(response => {
          parseData(response.json)
      })
    }

    const parseData = (resultJson: any) => {
      setClient(resultJson.client)
    }

    getData();
  }, [])

  return (
    <div className="page_content">
      <div className="page_section">
        <h1>Hej {client.firstName}!</h1>
      </div>

      <div className="flex top_margin section_direction">
        <div className='half_page_section r_right_margin'>
          <div className='page_section  flex section_direction'>
            <div className='r-half-col flex flex-col justify-between'>
              <div className='r_right_margin'>
                <h4>Subtitle</h4>
                <h2>Rubrik</h2>              
                <div className="top_margin">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam, quam.
                </div>
              </div>
              <div>
                <div className="button btn_top_margin inline_block">Knapp</div>
              </div>
            </div>
            <div className="r-half-col">
              <img className='my-auto' src="/assets/illustration/development.svg" alt="" />
            </div>
          </div>          
          <div className="page_section top_margin">
            <h2>Rubrik</h2>
            <div className="flex section_direction">
              <div className='r-half-col r_right_margin '>
                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>

                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>

                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>
              </div>
              <div className='r-half-col'>
                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>
                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>
                <div className="flex top_margin">
                  <img width={50} src="/assets/icon/white/arrow_answer.svg" alt="" />
                  <div className='left_margin'>
                    <div className='blue_gray'>Text</div>
                    <h4>Rubrik</h4>
                    <div style={{marginTop: '5px'}} className="blue_gray">+0</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='half_page_section'>
          <div className='page_section r_top_margin flex section_direction'>
            <div className='r-half-col flex flex-col justify-between'>
              <div className='r_right_margin'>
                <h4>Subtitle</h4>
                <h2>Rubrik som är längre med flera ord</h2>              
                <div className="top_margin">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus odit earum dolor eligendi commodi omnis!
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus odit earum dolor eligendi commodi omnis!
                </div>
              </div>
              <div className=''>
                <div className="button inline_block btn_top_margin">Knapp</div>
              </div>
            </div>
            <div className="r-half-col r_top_margin">
              <img className='my-auto' src="/assets/illustration/development.svg" alt="" />
            </div>
          </div>    
          <div className='page_section top_margin'>
            <h2>Rubrik mex någon text</h2>
            <h4 className="top_margin">Prova coaching via chatt!</h4>
            <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque.</div>
            <div className="underline">Läs mer</div>
            <h4 className="top_margin">Prova coaching via chatt!</h4>
            <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque.</div>
            <div className="underline">Läs mer</div>
            <h4 className="top_margin">Prova coaching via chatt!</h4>
            <div>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque.</div>
            <div className="underline">Läs mer</div>
          </div>           
          
          <div className="page_section top_margin">
            <h1 className='cursor-pointer' onClick={() => setShowSmallModal(true)}>Test modal small  - click me</h1>
            <h1 className='cursor-pointer' onClick={() => setShowMediumModal(true)}>Test modal medium  - click me</h1>
            <IonModal isOpen={showSmallModal} onDidDismiss={() => setShowSmallModal(false)}
                className="show_modal small">
                <img onClick={() => setShowSmallModal(false)} className='show_modal_close' width={20} src='/assets/icon/white/cross.svg' alt='close' />    
              <h1 className="show_modal_title">title</h1> 
              <div className="show_modal_content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, quos?
              </div> 
              <div className='show_modal_button'>
                <div className="button inline_block green">
                  Button1
                </div>
                <div className="button  left_margin inline_block white">
                  Button2
                </div>
              </div>          
            </IonModal>
            
            <IonModal isOpen={showMediumModal} onDidDismiss={() => setShowMediumModal(false)}
              className="show_modal medium">
              <img onClick={() => setShowMediumModal(false)} className='show_modal_close' width={20} src='/assets/icon/white/cross.svg' alt='close' />    
              <h1 className="show_modal_title">title</h1> 
              <div className="show_modal_content">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam possimus dolorem at eligendi vitae illo eum architecto corporis natus dignissimos, quod perspiciatis placeat ad, odit explicabo beatae cum voluptatibus minima magnam totam. Saepe, nisi placeat excepturi temporibus quo atque sequi facere ratione nobis illo quis quaerat ipsam, ipsa perferendis. Debitis.
              </div> 
              <div className='show_modal_button'>
                <div className="button inline_block green">
                  Button1
                </div>
                <div className="button  left_margin inline_block white">
                  Button2
                </div>
              </div>
            </IonModal>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ClientDashboardTest;
