import {IonContent, IonItem, IonPage} from '@ionic/react';
import React from 'react';

interface ComponentProps {}

interface ComponentState {}

export default class TestMenu extends React.Component<ComponentProps, ComponentState> {

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="m-4">
            <h1 className="mb-4">Test menu</h1>
            <IonItem detail={true} href="/app/testing/turn">
              Test turn connectivity
            </IonItem>
            <IonItem detail={true} href="/app/help/camera_test">
              Test camera
            </IonItem>
          </div>
        </IonContent>
      </IonPage>
    );
  }

};
