import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
import Api from "../../../../services/Api";
import { TrVar } from "../../../../services/translate";

interface BankidProps {
    loginMethod: number
    canCreateAccount: boolean
    showBankIdDesktopView: boolean
    showCreate: boolean
    LOGIN: any
    CONFIG: any
    changeLoginMethod: () => void
    postLogin: (token: string, personId: string) => void
    iOS: () => boolean
    renderAgreement: () => JSX.Element
    setUnknownUser: (_unknownUser: boolean) => void
    unknownUser: boolean
    setErrorMsg: (_errorMsg: string) => void
    errorMsg: string
    signupAgreement: boolean,
    showAgreementError: boolean,
    setShowAgreementError: (_showAgreementError: boolean) => void
    upgradeIncognito: boolean
}

const Bankid = (props: BankidProps) => {
    const { 
        loginMethod, 
        canCreateAccount, 
        showCreate, 
        showBankIdDesktopView, 
        LOGIN, 
        CONFIG, 
        changeLoginMethod, 
        postLogin, 
        iOS, 
        renderAgreement, 
        setUnknownUser,
        unknownUser,
        setErrorMsg,
        errorMsg,
        signupAgreement,
        showAgreementError,
        setShowAgreementError,
        upgradeIncognito
    } = props;

    const [authProviderTalk, setAuthProviderTalk] = useState(false);
    const [bankIdMessage, setBankIdMessage] = useState('');
    const [orderRef, setOrderRef] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [qrStartToken, setQrStartToken] = useState('');
    const [qrStartSecret, setQrStartSecret] = useState('');
    const [waitingForPoll, setWaitingForPoll] = useState(false);
    const [showBankIdForm, setShowBankIdForm] = useState(false);
    const [showBankIdOnThisUnit, setShowBankIdOnThisUnit] = useState(false);
    const [autoStartToken, setAutoStartToken] = useState('');
    const [showQRCodes, setShowQRCodes] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [time, setTime] = useState(0);
    const timeRef = React.useRef(0);

    const api = Api.getInstance();
    let pollInterval: NodeJS.Timer | null = null;
    let qrInterval: NodeJS.Timer | null = null;
    let startTime: number = 0;

    useEffect(() => {
        if (loginMethod === LOGIN.BankID) {
            if (!showBankIdForm && showBankIdDesktopView) {
                loginBankId();
            } else if (showBankIdForm && !showBankIdDesktopView) {
                stopBankId();
            }
        }
    }, [showBankIdForm]);

    useEffect(() => {
        if (loginMethod === LOGIN.BankID) {
            if (showQRCodes && !showBankIdOnThisUnit) {
                console.log("qrcode: ", qrCode);
                initiateBankIdWithQR();
                startPolling();
            }
        }
    }, [showQRCodes]);

    useEffect(() => {
        return () => {
            if (pollInterval) clearInterval(pollInterval);
            if (qrInterval) clearInterval(qrInterval);
        };
    }, []);


    useEffect(() => {
        console.log('polling...', authProviderTalk, !waitingForPoll, loginMethod);
        if (authProviderTalk && !waitingForPoll) {
            if (loginMethod === LOGIN.BankID) {
                pollBankId(showBankIdOnThisUnit);
            }
        }
    }, [time]);

    const loginBankId = async () => {

        if (showCreate) {
            if (!signupAgreement) {
                setShowAgreementError(true);
                return;
            }
        }

        let data = { login_only: !canCreateAccount };
        setErrorMsg('');
        setUnknownUser(false);
        setShowAgreementError(false);

        if (authProviderTalk) {
            console.log('Session started, hold your horses');
            return;
        }
        setAuthProviderTalk(true);
        setErrorMsg('');

        try {
            const response = await api.get('qr_login_bankid_start');
            if (response.json.status === 'ok') {
                const { orderRef, qrStartToken, qrStartSecret, transactionId } = response.json;
                console.log('response.json: ', response.json);
                setOrderRef(orderRef);
                setTransactionId(transactionId);
                setQrStartToken(qrStartToken);
                setQrStartSecret(qrStartSecret);
                setShowQRCodes(true);
            } else if (response.json.status === 'already_in_progress') {
                setErrorMsg(api.trTxt(TrVar.AnotherSessionIsAlreadyStarted));
                console.log('--Another session is already started', false);
                setAuthProviderTalk(false);
            } else {
                setErrorMsg(response.json.message);
                console.log('--Error starting BankID session', false);
                setAuthProviderTalk(false);
            }
        } catch (error: any) {
            let errorMsg = '';
            if ("isExpectedError" in error && error.isExpectedError) {
                errorMsg = api.trTxt(TrVar.FoundNoAccountWithThatPersonNumber);
            }
            console.log('-->Error starting BankID session', false);
            setAuthProviderTalk(false);
            setErrorMsg(errorMsg);
        }
    };

    const startPolling = () => {
        pollInterval = setInterval(() => {
            timeRef.current = timeRef.current + 1;
            setTime(timeRef.current);
        }, 3000);
    };

    const pollBankId = (noId: boolean) => {
        console.log('--pollBankId', true);
        setAuthProviderTalk(true);
        setWaitingForPoll(true);
        setErrorMsg('');

        const postData = {
            orderRef: orderRef,
            transactionId: transactionId,
            login_only: !canCreateAccount
        };
        console.log("postData", postData)
        api.post("qr_login_bankid_poll", postData, ['person_number_in_use'])
            .then(response => {
                setWaitingForPoll(false);
                if (response.json.status === 'ok') {
                    if (pollInterval) clearInterval(pollInterval);
                    postLogin(response.json.token, response.json.personId);
                } else if (response.json.status === 'pending') {
                    handleProgressStatus(response.json.progressStatus);
                } else {
                    let message = response.json.message || api.trTxt(TrVar.LoginCancelled);
                    setErrorMsg(message);
                    console.log('--Error polling BankID', false);
                    setAuthProviderTalk(false);
                    if (pollInterval) clearInterval(pollInterval);
                }
            })
            .catch(_response => {
                setWaitingForPoll(false);
                if (pollInterval) clearInterval(pollInterval);
                let errorMsg = api.trTxt(TrVar.BankIdError);
                if (_response.isExpectedError && _response.errorName === 'person_number_in_use') {
                    errorMsg = api.trTxt(TrVar.WeCouldNotCreateAccount);
                }
                setErrorMsg(errorMsg);
                console.log('-->Error polling BankID', false);
                setAuthProviderTalk(false);
            });
    };

    const initiateBankIdWithQR = async () => {
        try {
            startTime = Date.now();
            startQrUpdate(qrStartToken, qrStartSecret);
        } catch (error) {
            console.error('Error initiating authentication:', error);
        }
    };

    const startQrUpdate = async (qrStartToken: string, qrStartSecret: string) => {
        const updateQrCode = () => {
            const currentTime = Math.floor((Date.now() - startTime) / 1000);
            const hmac = CryptoJS.HmacSHA256(currentTime.toString(), qrStartSecret);
            const qrAuthCode = hmac.toString(CryptoJS.enc.Hex);
            const animatedQrCode = `bankid.${qrStartToken}.${currentTime}.${qrAuthCode}`;
            setQrCode(animatedQrCode);
        };

        qrInterval = setInterval(updateQrCode, 1000);
    };

    const stopBankId = () => {
        if (qrInterval) clearInterval(qrInterval);
    };

    const startBankIdPhone = () => {
        if (showCreate) {
            if (!signupAgreement) {
                setShowAgreementError(true);
                return;
            }
        }
        setShowBankIdOnThisUnit(true);

        if (CONFIG.SYSTEM === 'develop') {
            alert('This will not work in develop system - we cannot talk to BankId');
            return;
        }

        setErrorMsg('');
        setUnknownUser(false);
        setShowAgreementError(false);

        if (authProviderTalk) {
            console.log('Session started, hold your horses');
            return;
        }
        
        setAuthProviderTalk(true);
        setErrorMsg('');

        api.get('qr_login_bankid_start').then(response => {
            console.log("response", response)
            if (response.json.status === 'ok') {
                const { autoStartToken, orderRef, transactionId } = response.json;
                setAutoStartToken(autoStartToken);
                setOrderRef(orderRef);
                setTransactionId(transactionId);

                let redirect = 'null';
                let bankidUrl = 'https://app.bankid.com/?autostarttoken=' + autoStartToken;
                if (CONFIG.SYSTEM !== 'prod' && iOS()) {
                    if (navigator.userAgent.includes('CriOS')) {
                        redirect = encodeURIComponent('googlechrome://');
                    } else {
                        redirect = encodeURIComponent(window.location.href + '#polling');
                    }
                    console.log('Redirect: ' + redirect);
                }
                startPolling();
                window.location.href = bankidUrl + '&redirect=' + redirect;
            }
        })
            .catch(_response => {
                setErrorMsg('');
                console.log('-->Error starting BankID session phone', false);
                setAuthProviderTalk(false);
            });
    };

    const handleProgressStatus = (progressStatus: string) => {
        if (progressStatus === 'OUTSTANDING_TRANSACTION') {
            setBankIdMessage(api.trTxt(TrVar.WaitingForYourBankIdCode));
        } else if (progressStatus === 'USER_SIGN') {
            setBankIdMessage(api.trTxt(TrVar.WaitingForYourBankIdSigning));
        } else if (progressStatus === 'NO_CLIENT') {
            setBankIdMessage(api.trTxt(TrVar.PleaseStartBankId));
        } else if (progressStatus === 'START_FAILED') {
            setBankIdMessage(api.trTxt(TrVar.BankIdStartTimeout));
            console.log('BankId start failed', false);
            setAuthProviderTalk(false);
            if (pollInterval) clearInterval(pollInterval);
        } else if (progressStatus === 'EXPIRED_TRANSACTION') {
            setBankIdMessage(api.trTxt(TrVar.BankIdCompleteTimeout));
            console.log('BankId expired transaction', false);
            setAuthProviderTalk(false);
            if (pollInterval) clearInterval(pollInterval);
        } else if (progressStatus === 'COMPLETE') {
            setBankIdMessage('Error: done, should not be handled here...');
            if (pollInterval) clearInterval(pollInterval);
        } else {
            setBankIdMessage('Unknown response: ' + progressStatus);
        }
    };

    return (
        <>
            {(showCreate && !upgradeIncognito) &&
                <div className="blue">
                    {api.trTxt(TrVar.Part1Of2)}
                </div>
            }
            <h1 className="bottom_margin">
                {showCreate ? api.trTxt(TrVar.BankId) : api.trTxt(TrVar.LoginWithBankId)}
            </h1>
            {changeLoginMethod()}
            {(!showBankIdForm && !showBankIdOnThisUnit) &&
                <>
                    {/* Show button to allow user to login with bankid on same device */}
                    {showBankIdDesktopView ?
                        <>
                            <div className="btn_top_margin">
                                <div onClick={() => setShowBankIdForm(!showCreate || signupAgreement)} data-cy="login-different-unit"
                                    className="button inline_block mobile_size_btn">
                                    {api.trTxt(TrVar.BankIdMobile)}
                                </div>
                            </div>
                            <div onClick={() => {
                                startBankIdPhone();
                            }} className="btn_top_margin underline cursor-pointer">{api.trTxt(TrVar.BankIdOnThisDevice)}</div>
                            <div className="login_inputs top_margin inline_block">{showCreate && renderAgreement()}</div>
                            
                        </>
                        :
                        <>
                            <div className="btn_top_margin">
                                <div onClick={() => {
                                    startBankIdPhone();
                                }} className="button mobile_size_btn inline_block">{api.trTxt(TrVar.BankIdMobile)}</div>
                            </div>
                            {showCreate && renderAgreement()}
                            <div className="btn_top_margin">
                                <div onClick={() => setShowBankIdForm(true)} data-cy="login-different-unit"
                                    className="underline cursor-pointer">
                                    {api.trTxt(TrVar.BankIdWithQR)}
                                </div>
                            </div>
                        </>
                    }
                </>
            }
            {/* Cancel login with bankid on same device */}
            {showBankIdOnThisUnit &&
                <div className="btn_top_margin">
                    <div onClick={() => setShowBankIdOnThisUnit(false)} className="button red inline_block">{api.trTxt(TrVar.Cancel)}</div>
                </div>
            }

            <div className="login_inputs top_margin inline_block">
                {api.lang !== 'sv' &&
                    <p className={"top_margin " + (showCreate ? 'color_white' : '')}>
                        ({api.trTxt(TrVar.OnlyWorksSweden)})
                    </p>
                }
                {errorMsg.length > 0 &&
                    <div data-cy="login-bankid-error" className="top_margin bottom_margin bg_white p-4 error_red">{errorMsg}</div>
                }
                {showBankIdForm &&
                    <div>
                        {qrCode && <div className="flex justify-center items-center"><QRCode value={qrCode} /></div>}
                        {authProviderTalk &&
                            <div className="top_margin p-4 bg_light_blue color_black">
                                {api.trTxt(TrVar.StartBankIdPhone)}
                            </div>
                        }
                        {
                            !authProviderTalk &&
                            <div className="flex top_margin justify-center section_direction reverse">
                            <div 
                                onClick={() => setShowBankIdForm(false)} 
                                className="button r_top_margin mobile_size_btn inline_block white right_margin"
                            >
                                {api.trTxt(TrVar.GoBack)}
                            </div>
                                
                                <div data-cy="login-bankid" onClick={() => loginBankId()}
                                className={"button mobile_size_btn inline_block " }>
                                {
                                    showCreate ?
                                    api.trTxt(TrVar.GetStarted)
                                    :
                                    api.trTxt(TrVar.Logging)
                                }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    );
};









export default Bankid;
