import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import Tip from '../Tip';
import { checkInputErrors, countInputErrors } from '../form/CheckInput';
import { InputsInterface, InputInterface } from '../form/interfaces';
import Input from '../form/Input';
import { TrVar } from '../../services/translate';

interface ComponentProps {
}

interface ComponentState {
  showFormError: boolean
  inputs: InputsInterface
  messageSent: boolean
}

export default class ClientTip extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

    inputs: InputsInterface = {
    employer: {
      type: 'text',
      name: 'employer',
      placeholder: this.api.trTxt(TrVar.Company) + '/' + this.api.trTxt(TrVar.Organisation),
      value: '',
      label: this.api.trTxt(TrVar.WorkPlace) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    mail: {
      type: 'text',
      name: 'mail',
      placeholder: this.api.trTxt(TrVar.EmailContactPerson),
      value: '',
      label: this.api.trTxt(TrVar.Email) + '*',
      error: '',
      maxLength: 64,
      minLength: 1
    }
  }  
  
  state: Readonly<ComponentState> = {
    showFormError: false,
    inputs: this.inputs,
    messageSent: false
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  sendFriendTip = () => {
    if (this.checkAllErrors() > 0) {
      return
    }
    const data = {
      work_place: this.inputs.employer.value,
      contact_email: this.inputs.mail.value
    }
    this.api.post('client_request_contact_friend', data).then(response => {
        if (response.json.delivered) {
          this.setState({ messageSent: true });
        }
    })
  }

  render() {    
    let name = this.api.trTxt(TrVar.TipSomeone)
    let breadcrumbs = [{ name: this.api.trTxt(TrVar.Overview), link: '/app/client/overview' }]
    
    return (  
      <>  
        <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>
        <div className="page_content">      
          <Tip />
          <div className="page_section top_margin">
            <h1>{this.api.trTxt(TrVar.TipSomeoneYouLike)}</h1>
            <div className='flex section_direction top_margin'>
              <div className="r-half-col">
                <h3> - {this.api.trTxt(TrVar.OrSomeoneYouThinkNeedCoaching).toLowerCase()}</h3>
                <div className="top_margin">{this.api.trTxt(TrVar.LeaveContactInformationAndWeWillTakeOver)}</div>
                <div className="top_margin">{this.api.trTxt(TrVar.Thanks)}</div>
              </div>
              <div className="r-half-col r_left_margin r_top_margin">
                <Input data="mail-input" input={this.inputs.mail} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
                <Input data="employer-input" input={this.inputs.employer} update={this.inputChange} classes="page_input bottom_margin" language={this.api.lang} />
                {
                  this.state.showFormError && <p className="top_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>        
                }
                <div>
                  {
                    this.state.messageSent ? (
                      <div className="top_margin">
                        {this.api.trTxt(TrVar.TipThanks)}
                      </div>
                    ) : (
                      <div onClick={() => this.sendFriendTip()} className="top_margin button inline_block green">
                        {this.api.trTxt(TrVar.Send)}
                      </div>
                    )
                  }
                </div>
              </div>                    
            </div>
          </div>
        </div>  
      </>    
    )  
  }
}
