import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import {
    IonModal,
  } from '@ionic/react'
import UploadPicture from "../edit/UploadPicture";
import Input from '../form/Input';
import {checkInputErrors, countInputErrors} from '../form/CheckInput';
import { InputsInterface, InputInterface } from '../form/interfaces';
import { ClientInterface } from '../../interfaces/Client';
import { i18nInterface } from '../../interfaces/i18n';
import { changeUrl } from "../../services/Navigation";
import Loading from '../Loading';
import { TrVar } from '../../services/translate';

interface ComponentProps{
    reload: Function
}

interface ComponentState {
  picture: string
  client: ClientInterface
  showUploadPicture: boolean
  showFormError: boolean
  inputs: InputsInterface
  i18n: i18nInterface
  showExpectedError: boolean
  showInvalidError: boolean
  loaded: boolean
}

export default class ClientProfile extends React.Component<ComponentProps, ComponentState >  {
  private api = Api.getInstance()

  inputs: InputsInterface = {
    firstName: {
      type: 'text',
      name: 'firstName',
      placeholder: this.api.trTxt(TrVar.FirstName),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    lastName: {
      type: 'text',
      name: 'lastName',
      placeholder: this.api.trTxt(TrVar.LastName),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    email: {
      type: 'email',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    city: {
      type: 'text',
      name: 'city',
      placeholder: this.api.trTxt(TrVar.City),
      value: '',
      error: '',
      maxLength: 64,
    },
    company: {
      type: 'text',
      name: 'company',
      placeholder: this.api.trTxt(TrVar.Company),
      value: '',
      error: '',
      maxLength: 64
    },
    phone: {
      type: 'text',
      placeholder: (this.api.trTxt(TrVar.Phone)) + ', format +467012345678',
      name: 'phone',
      value: '',
      error: '',
      maxLength: 18,
    }
  }

  state: Readonly<ComponentState> = {
    client: {} as ClientInterface,
    picture: '',
    showUploadPicture: false,
    showFormError: false,
    inputs: this.inputs,
    i18n: {} as i18nInterface,
    showExpectedError: false,
    showInvalidError: false,
    loaded: false, 
  }

  componentDidMount() {
    this.getClientData();
  }

  private valOrEmptyStr = (val: string) => {
    if (val) {
      return val
    }
    return ''
  }
    
  private getClientData = () => {
    this.api.get('client?i18n=1').then(response => {
      this.inputs.firstName.value = this.valOrEmptyStr(response.json.client.firstName)
      this.inputs.lastName.value = this.valOrEmptyStr(response.json.client.lastName)
      this.inputs.email.value = this.valOrEmptyStr(response.json.client.email)
      this.inputs.city.value = this.valOrEmptyStr(response.json.client.city)
      this.inputs.company.value = this.valOrEmptyStr(response.json.client.company)
      this.inputs.phone.value = this.valOrEmptyStr(response.json.client.phone)

      const client = response.json.client as ClientInterface

      this.setState({ picture: response.json.client.picture, client: client, loaded: true })
    })
  }

  closeUploadPicture = () => {
    this.setState({showUploadPicture: false})
  }
    
  setPicture = (pic: string) => {
    const data = {
      picture: pic
    }
    this.api.post('picture', data).then(response => {
      this.setState({picture: response.json.picture});
    })
  }

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }
    
  private saveClient = () => {
    if (this.checkAllErrors() > 0) {
      return
    }

    if (this.inputs.phone.value.length > 0) {
      if (!this.inputs.phone.value.match(/^\+\d{7,17}$/)) {
        this.inputs.phone.error = this.api.trTxt(TrVar.FormatPhoneNumber)
        return
      }
    }

    const data = {
      profile: {
        firstName: this.inputs.firstName.value,
        lastName: this.inputs.lastName.value,
        email: this.inputs.email.value,
        city: this.inputs.city.value,
        company: this.inputs.company.value,
        phone: this.inputs.phone.value
      }
    }

    this.api.post('client_save2', data, ['email_in_use', 'invalid_email'])
      .then(_response => {
        this.setState({ showExpectedError: false, showInvalidError: false, showFormError: false })
        changeUrl('/app/client/my_account')
      })
      .catch(response => {
        if (response.isExpectedError === 'email_in_use') {
          this.setState({ showExpectedError: true })
        } else if (response.isExpectedError === 'invalid_email') {
          this.setState({ showInvalidError: true })
        } else {
          this.setState({ showFormError: true })
        }
    })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    return (
      <>
        <Breadcrumbs breadcrumbs={[{name: this.api.trTxt(TrVar.MyAccount), link: '/app/client/my_account'}]}
          name={this.api.trTxt(TrVar.YourProfile)}/>
        <div className="page_content">
        <IonModal isOpen={this.state.showUploadPicture} className="modal_tall">
          <UploadPicture picture={this.state.picture} setPicture={this.setPicture}
            closeModal={this.closeUploadPicture}/>
        </IonModal>
          <div className="page_section">
            <div className="flex">
              <img className='svg_blue' height={50} src="/assets/icon/profile.svg" alt="blue profile icon" />
              <h1 className='left_margin my-auto'>{this.api.trTxt(TrVar.YourProfile)}</h1>
            </div>
            <div className="page_bar" />
              <div className="flex">
                <img className="coach_picture cursor-pointer" onClick={() => this.setState({showUploadPicture: true})}
                  alt="client" src={this.state.picture} />
                <div className="my-auto text_center cursor-pointer"
                  onClick={() => this.setState({showUploadPicture: true})}>
                  <p className="text_underline left_margin">{this.api.trTxt(TrVar.ChangeProfilePicture)}</p>
                </div>
            </div>
            <div className="page_bar" style={{'margin': '20px 0'}} />
              <div>
                <h2 className='bottom_margin'>{this.api.trTxt(TrVar.YourDetails)}</h2>
                  <div className="flex section_direction">
                <div className="page_half_content flex flex-col">
                  <Input data="first-name-input" input={this.inputs.firstName} update={this.inputChange} classes="page_input" language={this.api.lang} />
                  <Input data="email-input" input={this.inputs.email} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  <Input data="company-input" input={this.inputs.company} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />                    
                </div>
                <div className="page_half_content flex flex-col r_top_margin r_left_margin">
                  <Input data="last-name-input" input={this.inputs.lastName} update={this.inputChange} classes="page_input" language={this.api.lang} />
                  <Input data="phone-input" input={this.inputs.phone} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  <Input data="city-input" input={this.inputs.city} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                </div>
              </div>

              {
                this.state.showExpectedError && <p className="top_margin error_red">{this.api.trTxt(TrVar.EmailInUse)}</p>        
              }
              {
                this.state.showInvalidError && <p className="top_margin error_red">{this.api.trTxt(TrVar.InvalidEmail)}</p>        
              }   
              {
                this.state.showFormError && <p className="top_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>        
              }
              <button className='button top_margin' onClick={() => this.saveClient()}>{this.api.trTxt(TrVar.Save)}</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
