import React from 'react'
import Api from "../../services/Api"
import './License.css';
import {ClientLicenseInterface} from "../../interfaces/ClientLicense";
import {PackageInterface, PackageType} from "../../interfaces/Package";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  package?: PackageInterface
  clientLicense?: ClientLicenseInterface
  backgroundColor: boolean
}

interface ComponentState {
}

export default class License extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()

  packageName = () => {
    const packageType = this.props.package ? this.props.package.type : -1
    if (packageType === PackageType.TRAINER) {
      return this.api.trTxt(TrVar.Trainer)
    } else if (packageType === PackageType.DISCOVER) {
      return this.api.trTxt(TrVar.Discover)
    } else if (packageType === PackageType.KICKSTART) {
      return this.api.trTxt(TrVar.Kickstart)
    } else if (packageType === PackageType.OPTIMIZE) {
      return this.api.trTxt(TrVar.Optimise)
    } else if (packageType === PackageType.MAXIMIZE) {
      return this.api.trTxt(TrVar.Maximise)
    } else if (packageType === PackageType.INSIDE) {
      return this.api.trTxt(TrVar.Inside)
    } else if (packageType === PackageType.ACCESS) {
      return this.api.trTxt(TrVar.Access)
    } else if (packageType === PackageType.FREE) {
      return this.api.trTxt(TrVar.Free)
    } else if (packageType === PackageType.ACCESS_PRIVATE) {
      return this.api.trTxt(TrVar.AccessPrivate)
    } else if (packageType === PackageType.ACCESS_BASIC) {
      return this.api.trTxt(TrVar.Access)
    } else {
      return this.api.trTxt(TrVar.YouDontHaveALicense)
    }
  }

  showFrequency = () => {
    if (this.props.package?.isVideoCoachIncluded) {
      let frequency: string
      let meetingLength = this.props.package.type === PackageType.MAXIMIZE ? 45 : 30
      if (this.props.clientLicense && this.props.clientLicense.endDate && this.props.clientLicense.meetingCount) {
        let valid: string
      
          frequency = this.props.clientLicense.meetingCount + ' ' + this.api.trTxt(TrVar.VideoMeetingIn).toLowerCase() + ' ' + meetingLength + ' ' + this.api.trTxt(TrVar.MinutesEach).toLowerCase()
          valid = this.api.trTxt(TrVar.ValidBetween) + ' ' + this.props.clientLicense.startDate + ' ' + this.api.trTxt(TrVar.And).toLowerCase() + ' ' +  this.props.clientLicense.endDate

        return (
          <>
            <li>{frequency}</li>
            <li>{valid}</li>
          </>
        )
      } else {
        const meetingFrequency = this.props.package.type === PackageType.KICKSTART ? 1 : 2
          const nr = meetingFrequency === 1 ? this.api.trTxt(TrVar.TimeFrequency).toLowerCase() : this.api.trTxt(TrVar.TimesFrequency).toLowerCase() 
          frequency = meetingLength + ' ' + this.api.trTxt(TrVar.MinutesMeetingWithCoach).toLowerCase() + ' ' + meetingFrequency + ' ' + nr + this.api.trTxt(TrVar.InMonth)

        return (<li>{frequency}</li>)
      }

    } else {
      return (<></>)
    }

  }

  packageContent = () => {
    return (
      <>
        <ul>
          {this.props.package?.type !== PackageType.FREE ?
            <>
              {this.props.package?.isVideoCoachIncluded &&
                <>
                  <li>{this.api.trTxt(TrVar.MatchingWithCoach)}</li>
                  <li>{this.api.trTxt(TrVar.AllCoachingAreas)}</li>
                  <li>{this.api.trTxt(TrVar.FortyFiveIntroductionMeeting)}</li>
                </>
              }
              {this.showFrequency()}
              {/*{this.props.packageType === 3 &&                  */}
              {/*  <li>30 min videomöte med coach 1 gång/månad</li>*/}
              {/*}*/}
              {/*{this.props.packageType === 4 &&*/}
              {/*  <li>30 min videomöte med coach 2 gånger/månad</li>*/}
              {/*}*/}
              {/*{this.props.packageType === 5 &&*/}
              {/*  <li>45 min videomöte med coach 2 gånger/månad</li>*/}
              {/*}                  */}
              {this.props.package?.isChatCoachIncluded &&
                <li>{this.api.trTxt(TrVar.Chat)}</li>
              }
              <li>{this.api.trTxt(TrVar.AllDevelopmentAreas)}</li>
              <li>{this.api.trTxt(TrVar.AllExercises)}</li>
            </>
            :
            <>
              <li>{this.api.trTxt(TrVar.OneStartedDevelopmentArea)}</li>
              <li>{this.api.trTxt(TrVar.TwelveDigitalCoachQeustions)}</li>
            </>
          }
          <li>{this.api.trTxt(TrVar.BehaviourAnalysis)}</li>
          <li>{this.api.trTxt(TrVar.MeasureYourPotentialAndDevelopment)}</li>
          <li>{this.api.trTxt(TrVar.IndividualAccountOverview)}</li>
        </ul>
      </>
    ) 
  }

  render() {
    return (
      <div style={{ backgroundColor: (this.props.backgroundColor ? '#98b1ff' : '') }} className='license_box dark_blue'>
        <div>{this.api.trTxt(TrVar.MyLicense)}:</div>
        <h1>{this.packageName()}</h1>
        <div style={{ fontWeight: '500', fontSize: '18px' }} className="top_margin">
          {this.api.trTxt(TrVar.Included)}:
        </div>
        <div className="top_margin">
          {this.packageContent()}
        </div>
      </div>
    )
  }
}
