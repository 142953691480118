import React, { useEffect, useState } from 'react';
import { TrVar } from '../../services/translate';
import Api from "../../services/Api"
import Text from "../Text/Text";
import Breadcrumbs from "../menu/Breadcrumbs";
import './SupportAndFaq.css';
import Accordion from "../../components/Accordion";

interface ComponentProps {
  clientHasCoach: boolean
}

const ClientFaq = (props: ComponentProps) => {
  const api = Api.getInstance();
  const [section, setSection] = useState(props.clientHasCoach ? 0 : 2);

  const sectionZeroFaqList = [
    {
      title: api.trTxt(TrVar.WhatShouldIDoIfIHaveTechnicalIssuesWithThePlatform),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.SendAEmailToSupportAndWeWillHelpYouOut)}</p>,
    },
    {
      title: api.trTxt(TrVar.MyCoachHasNoAvailableTimesWhatShouldIDo),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ChatWithYourCoachAndFindASuitableTime)}</p>
    },
    {
      title: api.trTxt(TrVar.IHaveMissedAMeetingWhatDoIDo),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.OOpsChatWithYourCoachHopefullyYouCanFindANewTime)}</p>
    },
    {
      title: api.trTxt(TrVar.WhatHappensIfIDontHaveTimeForAllMyMeetings),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.UnfortunatelyYouWillLoseMeetingsYouHaventUsed)}</p>
    },
    {
      title: api.trTxt(TrVar.WhatHappensIfIDontCancelWithin24HoursAdvance),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.UnfortunatelyTheMeetingWillBeUsed)}</p>
    },
    {
      title: api.trTxt(TrVar.MyLicenseIsntValidLongEnoughForMeToHaveMeetingsEveryOtherWeek),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ChatWithYourCoachAndBookTheMeetingsEveryWeekInsteadOr)}</p>,
    },
    {
      title: api.trTxt(TrVar.WhyIsItImportantToBookAMeetingEveryOtherWeek),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.WeRecommendEveryOtherWeekSoThatYouWillHaveTimeToDoTasks)}</p>
    },
  ];

  const sectionOneFaqList = [
    {
      title: api.trTxt(TrVar.HowDoISolveIssuesWithTheVideoChat),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ThereIsAHelpSectionInTheVideoClientWhereYouCanFindSolutions)}</p>
    },
  ];

  const sectionSecondFaqList = [
    {
      title: api.trTxt(TrVar.HowDoIWorkbestWithMyAreasOfDevelopmentToReachMyPotential),
      content: <>
        <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ToGetIntoTheHabitOfWorkingOnYourPersonal)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.RememberToTryToBePatientAndNotExpectInstantResult)}</p>
      </>
    },
    {
      title: api.trTxt(TrVar.HowDoIKnowIfImMakingProgress),
      content: <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ByContinuouslyTakingNewPotentialMeasurements)}</p>
    }
  ];

  const sectionThirdHasCoachFaqList = [
    {
      title: api.trTxt(TrVar.WhatIsCoaching),
      content: (
        <>
          <p className=" text-base text-neutral-400">{api.trTxt(TrVar.CoachingIsAboutUnlockingYourFullPotentialByGuidingAndChallenging)}</p>
          <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.CoachingIsAlsoAboutLookingForwardAndGuidingYou)}</p>
        </>
      )
    },
    {
      title: api.trTxt(TrVar.WhatCanITalkToMyCoachAbout),
      content: (
        <>
          <p className=" text-base text-neutral-400">{api.trTxt(TrVar.YouCanTalkToYourCoachAbout1)}</p>
          <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.YouCanTalkToYourCoachAbout2)}</p>
          <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.YouCanTalkToYourCoachAbout3)}</p>
        </>
      )
    },
  ]

  const sectionThirdNoCoachFaqList = [
    {
      "title": api.trTxt(TrVar.HowDoesChatCoachingWork),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ChatCoachingWorksLikeThis)}</p>
    },
    {
      "title": api.trTxt(TrVar.HowLongDoIHaveAccessToTheCoach),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.YouHaveAcessToYourCoachWhileAccessLicense)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatCanITalkToMyCoachAbout),
      "content": <>
        <p className=" text-base text-neutral-400">{api.trTxt(TrVar.YouCanTalkToYourCoachAbout1)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.YouCanTalkToYourCoachAbout2)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.YouCanTalkToYourCoachAbout3)}</p>
      </>
    },
    {
      "title": api.trTxt(TrVar.WhatHappensToMyCoachIfMyLicenseIsUpgradedToVideoCoaching),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.WhenYouGetUpgradedToVideoCoaching)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatShouldIDoIfIHaveTechnicalIssuesWithThePlatform),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.SendAEmailToSupportAndWeWillHelpYouOut)}</p>
    },
    {
      "title": api.trTxt(TrVar.MyCoachHasNoAvailableTimesWhatShouldIDo),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ChatWithYourCoachAndFindASuitableTime)}</p>
    },
    {
      "title": api.trTxt(TrVar.IHaveMissedAMeetingWhatDoIDo),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.OOpsChatWithYourCoachHopefullyYouCanFindANewTime)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatHappensIfIDontHaveTimeForAllMyMeetings),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.UnfortunatelyYouWillLoseMeetingsYouHaventUsed)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatHappensIfIDontCancelWithin24HoursAdvance),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.UnfortunatelyTheMeetingWillBeUsed)}</p>
    },
    {
      "title": api.trTxt(TrVar.MyLicenseIsntValidLongEnoughForMeToHaveMeetingsEveryOtherWeek),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ChatWithYourCoachAndBookTheMeetingsEveryWeekInsteadOr)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhyIsItImportantToBookAMeetingEveryOtherWeek),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.WeRecommendEveryOtherWeekSoThatYouWillHaveTimeToDoTasks)}</p>
    },
    {
      "title": api.trTxt(TrVar.HowDoISolveIssuesWithTheVideoChat),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ThereIsAHelpSectionInTheVideoClientWhereYouCanFindSolutions)}</p>
    },
    {
      "title": api.trTxt(TrVar.HowDoIWorkbestWithMyAreasOfDevelopmentToReachMyPotential),
      "content": <>
        <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ToGetIntoTheHabitOfWorkingOnYourPersonal)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.RememberToTryToBePatientAndNotExpectInstantResult)}</p>
      </>
    },
    {
      "title": api.trTxt(TrVar.HowDoIKnowIfImMakingProgress),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ByContinuouslyTakingNewPotentialMeasurements)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatIsCoaching),
      "content": <>
        <p className=" text-base text-neutral-400">{api.trTxt(TrVar.CoachingIsAboutUnlockingYourFullPotentialByGuidingAndChallenging)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.CoachingIsAlsoAboutLookingForwardAndGuidingYou)}</p>
      </>
    },
    {
      "title": api.trTxt(TrVar.CanIReturnToMyToMyChatCoachAfterReceivingCoaching),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.YesYouCanJustChooseThemAgain)}</p>
    },
    {
      "title": api.trTxt(TrVar.AreTheConversationsBetweenMyCoachAndISaved),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.ConversationsAreSaved)}</p>
    }
  ]

  const sectionFourthFaqList = [
    {
      "title": api.trTxt(TrVar.WhoCanSeeMyAnswersInThePlatform),
      "content": <>
        <p className="text-base text-neutral-400">{api.trTxt(TrVar.YouCanOwnYourDataUnderGDPR)}</p>
        <p className="text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.YourEmployerCannotSeeYourAnswersEither)}</p>
        <p className=" text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.ToEvaluateAndImproveThePlatformItMayInSomeSituationsBeNecessaryToReview)}</p>
      </>
    },
    {
      "title": api.trTxt(TrVar.HowDoICancelMyAccount),
      "content": <p className=" text-base text-neutral-400">{api.trTxt(TrVar.IfYouWantToCancelYourAccountPleaseContactZebrain)}</p>
    },
    {
      "title": api.trTxt(TrVar.WhatIsCoaching),
      "content": <>
        <p className="text-base text-neutral-400">{api.trTxt(TrVar.CoachingIsAboutUnlockingYourFullPotentialByGuidingAndChallenging)}</p>
        <p className="text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.CoachingIsAlsoAboutLookingForwardAndGuidingYou)}</p>
      </>
    },
    {
      "title": api.trTxt(TrVar.HowChangeCoach),
      "content": <>
        <p className="text-base text-neutral-400">{api.trTxt(TrVar.HowChangeCoachAnswer)}</p>
        <p className="text-base text-neutral-400 top_margin" >{api.trTxt(TrVar.HowChangeCoachAnswer2)}</p>
      </>
    },
  ]

  const showQuestion = (id: string) => {
    let text = document.querySelector('#' + id + 'Answer');
    let img = document.querySelector('#' + id + 'Img');
    text?.classList.toggle('display_none');
    img?.classList.toggle('support_rotate_img');
  }

  return (
    <>
      <Breadcrumbs name={api.trTxt(TrVar.Faq)} />
      <div className="page_content">
        <div className="page_section">
          <h1>{api.trTxt(TrVar.Faq)}</h1>
          <div className="page_bar" />
          <div className="flex section_direction">
            <div className="page_half_content">
              {props.clientHasCoach &&
                <>

                  <h4 className="blue mt-7 text-2xl font-medium">
                    {api.trTxt(TrVar.MeetingWithCoach)}
                  </h4>
                  <div className="support_question_section mt-7">
                    <Accordion items={sectionZeroFaqList} />
                  </div>
                  <h4 className="mt-20 blue text-2xl font-medium">
                    {api.trTxt(TrVar.VideoMeeting)}
                  </h4>
                  <div className="support_question_section mt-7">
                    <Accordion items={sectionOneFaqList} />
                  </div>
                </>
              }
              <h4 className={`${props.clientHasCoach ? "mt-20" : "mt-7"} blue text-2xl font-medium`}>
                {api.trTxt(TrVar.HowYouUseThePlatform)}
              </h4>
              <div className="support_question_section mt-7">
                <Accordion items={sectionSecondFaqList} />
              </div>
              {props.clientHasCoach ?
                <>
                  <h4 className="mt-20 blue text-2xl font-medium">
                    {api.trTxt(TrVar.AboutCoaching)}
                  </h4>
                  <div className="support_question_section mt-7">
                    <Accordion items={sectionThirdHasCoachFaqList} />
                  </div>
                </>
                :
                <>
                  <h4 className="mt-20 blue text-2xl font-medium">
                    {api.trTxt(TrVar.AboutCoachingThroughChat)}
                  </h4>
                  <div className="support_question_section mt-7">
                    <Accordion items={sectionThirdNoCoachFaqList} />
                  </div>
                </>
              }
              <h4 className="mt-20 blue text-2xl font-medium">
                {api.trTxt(TrVar.OtherQuestions)}
              </h4>
              <div className="support_question_section mt-7">
                <Accordion items={sectionFourthFaqList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientFaq;
