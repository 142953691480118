import React from 'react';
import Api from '../../services/Api';
import Breadcrumbs from "../menu/Breadcrumbs";
import { CoachInterface, DescriptionInterface } from '../../interfaces/Coach';
import {InputsInterface, InputInterface} from '../form/interfaces'
import Input from "../form/Input";
import {checkInputErrors, countInputErrors, getInputValues} from '../form/CheckInput'
import ShowSelectCoach from '../edit/ShowSelectCoach';
import Loading from '../Loading';
import { changeUrl } from "../../services/Navigation";
import {IonModal} from "@ionic/react";
import UploadPicture from "../edit/UploadPicture";
import { TrVar } from '../../services/translate';



interface ComponentProps{
  onboarding: boolean
  done?: Function
  saveText?: string
}

interface ComponentState {
  coach: CoachInterface;
  showFormError: boolean;
  loaded: boolean;
  inputs: InputsInterface
  picture: string;
  description?: DescriptionInterface
  descriptions: Array<DescriptionInterface>
  showUploadPicture: boolean;
  showLanguageInfo: boolean
  addLanguage: string
  showDescriptionInfo: boolean
}

export default class CoachExperience extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance();

  inputs: InputsInterface = {
    description: {
      type: 'textarea',
      name: 'description',
      placeholder: this.api.trTxt(TrVar.Max340Characters),
      value: '',
      error: '',
      maxLength: 340
    },
    areas: {
      type: 'selectmultiple',
      name: 'areas',
      placeholder: '',
      value: '',
      error: '',
      options: [],
      minOptions: 1
    },
    languages: {
      type: 'selectmultiple',
      name: 'languages',
      placeholder: '',
      value: '',
      error: '',
      options: [],
      minOptions: 1
    },
    coachingExpertise: {
      type: 'textarea',
      name: 'coachingExpertise',
      placeholder: this.api.trTxt(TrVar.TextHere),
      value: '',
      error: '',
      maxLength: 5000
    },
    workExperience: {
      type: 'textarea',
      name: 'workExperience',
      placeholder: this.api.trTxt(TrVar.TextHere),
      value: '',
      error: '',
      maxLength: 5000
    },
    philosophy: {
      type: 'textarea',
      name: 'philosophy',
      placeholder: this.api.trTxt(TrVar.TextHere),
      value: '',
      error: '',
      maxLength: 5000
    },
  };

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    showFormError: false,
    loaded: false,
    inputs: this.inputs,
    descriptions: [],
    picture: '',
    showUploadPicture: false,
    showLanguageInfo: false,
    addLanguage: '',
    showDescriptionInfo: false,
  };

  componentDidMount() {
    this.getData();
  }

  updateDescriptionState = (name: string, value: string) => {
    if (this.state.description) {
      const description = this.state.description
      if (name === 'description') {
        description.description = value
      } else if (name === 'coachingExpertise') {
        description.coachingExpertise = value
      } else if (name === 'workExperience') {
        description.workExperience = value
      } else if (name === 'philosophy') {
        description.philosophy = value
      } else {
        return
      }
      this.setState({description: description})
    } else {
      console.error('No state.description')
    }

  }

  inputChange = (input: InputInterface) => {
    const coach = this.state.coach
    // Below are hacks to update coach state for preview module SelectCoach
    if (['description', 'coachingExpertise', 'workExperience', 'philosophy'].indexOf(input.name) > -1) {
      this.updateDescriptionState(input.name, input.value)

    } else if (input.name === 'areas') {
      const areaNames = []
      if (input.options) {
        for (let option of input.options) {
          if (option.selected) {
            areaNames.push(option.name)
          }
        }
        coach.areas = areaNames
      }
    } else if (input.name === 'languages') {
      const languages = []
      if (input.options) {
        for (let option of input.options) {
          if (option.selected) {
            languages.push(option.name)
          }
        }
        coach.languages = languages
      }
    }
    const formError = countInputErrors(this.inputs) > 0
    this.setState({inputs: this.inputs, coach: coach, showFormError: formError})
  }

  private getData = () => {
    let description: DescriptionInterface | undefined
    this.api.get('coach?areas=1&i18n=1&options=1&descriptions=1').then((response) => {
      const descriptions: Array<DescriptionInterface> = []
      for (const description of response.json.descriptions) {
        if (!description.description) description.description = ''
        if (!description.philosophy) description.philosophy = ''
        if (!description.workExperience) description.workExperience = ''
        if (!description.coachingExpertise) description.coachingExpertise = ''
        descriptions.push(description)
      }
      if (descriptions.length > 0) {
        description = descriptions[0]
        this.inputs.description.value = description.description
        this.inputs.coachingExpertise.value = description.coachingExpertise
        this.inputs.workExperience.value = description.workExperience
        this.inputs.philosophy.value = description.philosophy
      }
      for (const area of response.json.areas) {
        this.inputs.areas.options?.push({
          name: area.name,
          value: area.id,
          selected: area.selected,
          moreInfo: area.moreInfo,
        })
      }
      for (let lang of response.json.i18n.languages) {
        this.inputs.languages.options?.push({
          name: lang.name,
          value: lang.key,
          selected: lang.selected,
        })
      }
      const coach = response.json.coach as CoachInterface

      this.setState({picture: response.json.coach.picture, coach: coach, descriptions: descriptions,
        description: description, loaded: true});
    });
  };

  setPicture = (pic: string) => {
    const data = {
      picture: pic,
    };
    this.api.post('picture', data).then((response) => {
      const coach = this.state.coach
      coach.picture = response.json.picture
      this.setState({picture: response.json.picture, coach: coach});
    });
  };

  closeUploadPicture = () => {
    this.setState({showUploadPicture: false})
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  next = () => {
    if (this.props.onboarding) {
      if (this.props.done) {
        this.props.done()
      } else {
        alert("Fatal error - no next step")
      }
    } else {
      changeUrl('/app/coach/my_account')
    }
  }

  private saveCoach = () => {

    if (this.checkAllErrors() > 0) {
      return
    }

    const coachData = {
      about: {
        areas: getInputValues(this.inputs.areas, "int"),
      },
      i18n: {
        languages: getInputValues(this.inputs.languages, "str")
      },
      descriptions: this.state.descriptions
    }

    this.api.post('coach_save2', coachData).then(_response => {
      this.next()
    }).catch(response => {
      console.log('failed')
    })
  }

  getLanguageName = (language?: string) => {
    if (this.inputs.languages.options) {
      for (let lang of this.inputs.languages.options) {
        if (lang.value === language) {
          return lang.name
        }
      }
    }
    return "Unknown language " + language

  }

  getDescription = (language: string) => {
    for (const description of this.state.descriptions) {
      if (description.language === language) {
        return description
      }
    }
    return undefined
  }

  addLanguage = (e: any) => {
    const language = e.target.value
    if (language.length !== 2) {
      return
    }

    if (this.getDescription(language)) {
      alert("That language is already added")
      return
    }

    const description: DescriptionInterface = {
      language: language,
      description: '',
      philosophy: '',
      workExperience: '',
      coachingExpertise: '',
    }

    const descriptions = this.state.descriptions

    descriptions.push(description)

    this.inputs.description.value = ''
    this.inputs.coachingExpertise.value = ''
    this.inputs.philosophy.value = ''
    this.inputs.workExperience.value = ''

    this.setState({descriptions: descriptions, description: description, addLanguage: ''})
  }

  removeLanguage = () => {
    if (this.state.descriptions.length < 1) {
      alert("Cannot remove last language")
      return
    }
    if (this.state.description) {
      let  confirmMessage = (this.api.trTxt(TrVar.AreYouSureYouWantToRemove) + ' ')
      confirmMessage += this.getLanguageName(this.state.description.language) + '?\n\n'
      confirmMessage += (this.api.trTxt(TrVar.DoNotForgetToSave))

      const doRemove = window.confirm(confirmMessage)
      if (!doRemove) {
        return
      }
      const descriptions: Array<DescriptionInterface> = []
      for (const description of this.state.descriptions) {
        if (description.language !== this.state.description.language) {
          descriptions.push(description)
        }
      }
      const description = this.state.descriptions[0]
      this.updateNewDescription(description)
      this.setState({description: description, descriptions: descriptions})
    }

  }

  updateNewDescription = (description: DescriptionInterface) => {
    if (description) {
      this.inputs.description.value = description.description
      this.inputs.coachingExpertise.value = description.coachingExpertise
      this.inputs.philosophy.value = description.philosophy
      this.inputs.workExperience.value = description.workExperience
      this.setState({description: description})
    }
  }

  changeDescriptionLanguage = (language: string) => {

    // Read the new description values into coach struct and input values
    const description = this.getDescription(language)
    if (description) {
      this.updateNewDescription(description)
    }
  }

  renderLanguageInfo = () => {
    return (
      <div className="p-4 color_white">
        <div className="flex justify-end">
          <img alt="close" className="icon_medium cursor-pointer"
               onClick={() => this.setState({showLanguageInfo: false})}
               src="/assets/icon/white/cross.svg"/>
        </div>
        <div className="px-4">
          <h2>{this.api.trTxt(TrVar.ChooseLanguageForYourDescription)}</h2>
          <p className="top_margin">
            {
              this.api.trTxt(TrVar.AddDescriptionsOfYourCoachProfileInTheLanguagesYourClientsWant)
            }
          </p>
          <h4 className="top_margin">
            {
              this.api.trTxt(TrVar.KeepInMind)
            }            
          </h4>
          <p>
            {
              this.api.trTxt(TrVar.IfAClientWantsToBeCoachedInALanguageYouHaveNotAdded)
            }
          </p>
        </div>
      </div>
    )
  }

  renderDescriptionInfo = () => {
    return (
      <div className="p-4 color_white">
        <div className="flex justify-end">
          <img alt="close" className="icon_medium cursor-pointer"
               onClick={() => this.setState({showDescriptionInfo: false})}
               src="/assets/icon/white/cross.svg"/>
        </div>
        <div className="px-4">
          <h2>{this.api.trTxt(TrVar.ShortSummary)}</h2>
          <p className="top_margin">
            {this.api.trTxt(TrVar.DescriptionInformation)}
          </p>
        </div>
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    
    return (
      <>
        {
          !this.props.onboarding &&
          <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.MyAccount), link: '/app/coach/my_account' }]}
            name={this.api.trTxt(TrVar.MyCoachProfile)} />
        }
        <div className={!this.props.onboarding ? 'page_content' : ''}>
          <IonModal isOpen={this.state.showUploadPicture} className="modal_tall">
            <UploadPicture picture={this.state.picture} setPicture={this.setPicture}
              closeModal={this.closeUploadPicture} />
          </IonModal>
          <IonModal isOpen={this.state.showLanguageInfo} className="modal_md"
            onDidDismiss={() => this.setState({ showLanguageInfo: false })}>
            {this.renderLanguageInfo()}
          </IonModal>
          <div className="page_section">
            <div className="flex">
              <img className='svg_blue' src="/assets/icon/profile.svg" height={50} alt="" />
              <h2 className="left_margin">{this.api.trTxt(TrVar.MyCoachProfile)}</h2>
            </div>
            <div className="page_bar" />
            <div className="flex margin_bottom">
              <img className="coach_picture cursor-pointer" onClick={() => this.setState({ showUploadPicture: true })}
                alt="coach" src={this.state.picture} />
              <div className="my-auto text_center cursor-pointer"
                onClick={() => this.setState({ showUploadPicture: true })}>
                <p className="text_underline left_margin">{this.api.trTxt(TrVar.ChangeProfilePicture)}</p>
              </div>
            </div>
          </div>

          <div className="page_section top_margin">
            <h4 className='bottom_margin'>{this.api.trTxt(TrVar.CoachAreas)}</h4>
            <Input data="coach-areas-input" input={this.inputs.areas} update={this.inputChange} classes="" language={this.api.lang} />
          </div>

          <div className="page_section top_margin">
            <h3 className='bottom_margin'>{this.api.trTxt(TrVar.LangToCoach)}</h3>
            <Input data="languages-input" input={this.inputs.languages} update={this.inputChange} classes="" language={this.api.lang} />
          </div>

          <div className="page_section top_margin">
            <div className="flex items-center">
              <h3>{this.api.trTxt(TrVar.DescriptionOfYouAsCoach)}</h3>
              <div className="ml-4">
                <img alt="help" onClick={() => this.setState({ showLanguageInfo: true })}
                  className="icon_large cursor-pointer" src="/assets/icon/white/help-circle.svg" />
              </div>
            </div>
            <h4 className='double_top_margin'>
              {this.api.trTxt(TrVar.ChooseALanguageToWriteIn)}
            </h4>
            <div className="flex section_direction">
              <div style={{ width: "160px" }}>
                <select className="page_select w100" value={''}
                  onChange={(e) => this.addLanguage(e)}>
                  <option value=''>
                    {this.api.trTxt(TrVar.AddLanguage)}
                  </option>
                  {
                    this.inputs.languages.options?.map(option => {
                      return (
                        <option key={"option_" + option.value} value={option.value}>{option.name}</option>
                      )
                    })
                  }
                </select>

              </div>
              <div className='flex section_direction' style={{ flexWrap: 'wrap', maxWidth: '50%' }}>
                {
                  this.state.descriptions.map((description) => {
                    return (
                      <div className='button r_left_margin r_top_margin flex bottom_margin justify-center' key={"language_" + description.language}>
                        {this.getLanguageName(description.language)}
                        <img onClick={() => this.removeLanguage()} className='left_margin' src='/assets/icon/dark_blue/cross.svg' alt='close cross' />
                      </div>
                    )
                  })
                }
              </div>
            </div>


            {
              this.state.description &&
              <div className="double_top_margin">
                <h4>{this.api.trTxt(TrVar.ShowLanguages)}</h4>
                <select className="page_select w100" style={{ width: "160px" }}
                  value={this.state.description.language}
                  onChange={(e) => this.changeDescriptionLanguage(e.target.value)}>
                  {
                    this.state.descriptions.map((description) => {
                      return (
                        <option key={"language_" + description.language} value={description.language}>
                          {this.getLanguageName(description.language)}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            }
            {
              this.state.description &&
              <>
              <div className="double_top_margin">
                <h4>
                  {this.api.trTxt(TrVar.Preview)}.&nbsp;
                  {this.api.trTxt(TrVar.HowClientsSeeYou)} ({this.getLanguageName(this.state.description?.language)}):
                </h4>
                <div className="border_box">
                  <ShowSelectCoach coach={this.state.coach} description={this.state.description} />
                </div>
              </div>                
                <div className="double_top_margin">
                  <IonModal isOpen={this.state.showDescriptionInfo} className="modal_md"
                    onDidDismiss={() => this.setState({ showDescriptionInfo: false })}>
                    {this.renderDescriptionInfo()}
                  </IonModal>
                  <div className="flex section_direction">
                    <div className="page_half_content">
                      <div className="flex justify-between">
                        <h4 style={{ alignSelf: 'flex-end' }}>
                          {this.api.trTxt(TrVar.TextAboutMeAsCoach)} ({this.getLanguageName(this.state.description.language)})
                        </h4>
                        <div className="ml-4">
                          <img alt="help" onClick={() => this.setState({ showDescriptionInfo: true })}
                            className="icon_large cursor-pointer" src="/assets/icon/white/help-circle.svg" />
                        </div>
                      </div>

                      <Input data="description-input" input={this.inputs.description} update={this.inputChange}
                        classes="page_text_area" language={this.api.lang} />
                    </div>
                  </div>
                  <div className="page_half_content top_margin">
                    <h4>
                      {this.api.trTxt(TrVar.CoachExpertise)}  ({this.getLanguageName(this.state.description?.language)})
                    </h4>
                    <Input data="coach-expertise-input" input={this.inputs.coachingExpertise} update={this.inputChange}
                      classes="page_text_area" language={this.api.lang} />
                  </div>
                  <div className="page_half_content top_margin">
                    <h4>
                      {this.api.trTxt(TrVar.WorkExperience)}  ({this.getLanguageName(this.state.description?.language)})
                    </h4>
                    <Input data="work-experience-input" input={this.inputs.workExperience} update={this.inputChange}
                      classes="page_text_area" language={this.api.lang} />
                  </div>
                  <div className="page_half_content top_margin">
                    <h4>
                      {this.api.trTxt(TrVar.Philosophy)}  ({this.getLanguageName(this.state.description?.language)})
                    </h4>
                    <Input data="philosophy-input" input={this.inputs.philosophy} update={this.inputChange}
                      classes="page_text_area" language={this.api.lang} />
                    <button className="button top_margin" onClick={() => this.saveCoach()}>
                      {this.api.trTxt(TrVar.Save)}
                    </button>
                    {
                      this.state.showFormError &&
                      <p className="top_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>
                    }
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </>
    )
  }
}
