import React from 'react'
import { DisaInterface } from "../../interfaces/Disa";
import { TrVar } from '../../services/translate';
import Api from "../../services/Api";

interface TxtInterface {
  text: string
  value: string
  textAnchor: string
  x: number
  y: number
}

interface ComponentProps {
  disa: Array<DisaInterface>
  height?: number
  color?: string
}

interface ComponentState { }

export default class DisaRomb extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  coords = [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0]
  ]
  texts: Array<TxtInterface> = [
    {} as TxtInterface,
    {} as TxtInterface,
    {} as TxtInterface,
    {} as TxtInterface
  ]
  mainColor = "#bc80f0"
  stopColor = "#18022b"

  path = ''

  constructor(props: ComponentProps) {
    super(props)
    if (this.props.color === 'cerise') {
      this.mainColor = '#ff00b8'
      this.stopColor = '#4037a1'
    }
    this.checkAllValuesNotZero()

    const values = [this.props.disa[0].value, this.props.disa[1].value, this.props.disa[2].value, this.props.disa[3].value]
    const min = Math.min(values[0], values[1], values[2], values[3])
    const max = Math.max(values[0], values[1], values[2], values[3])
    let minRadius: number
    let multiplier: number
    if (min === max) {
      minRadius = 50
      multiplier = 0
    } else {
      minRadius = min < 10 ? 5 : 20
      multiplier = (50 - minRadius) / (max - min)
    }

    const minBottom = 50 - minRadius
    const minTop = 50 + minRadius

    this.coords[0][0] = minTop + multiplier * (values[0] - min)
    this.coords[0][1] = minBottom - multiplier * (values[0] - min)

    this.coords[1][0] = minTop + multiplier * (values[1] - min)
    this.coords[1][1] = minTop + multiplier * (values[1] - min)

    this.coords[2][0] = minBottom - multiplier * (values[2] - min)
    this.coords[2][1] = minTop + multiplier * (values[2] - min)

    this.coords[3][0] = minBottom - multiplier * (values[3] - min)
    this.coords[3][1] = minBottom - multiplier * (values[3] - min)

    this.path = "M " + this.coords[0][0] + "," + this.coords[0][1] + " "
    for (let i = 1; i < 4; i++) {
      this.path += "L " + this.coords[i][0] + "," + this.coords[i][1] + " "
    }
    this.path += " z"


    this.texts[0] = {
      text: this.getDisaName(this.props.disa[0].key),
      value: this.props.disa[0].value.toFixed(0) + "%",
      textAnchor: "start",
      x: this.coords[0][0] + 4,
      y: this.coords[0][1] - 14,
    }

    this.texts[1] = {
      text: this.getDisaName(this.props.disa[1].key),
      value: this.props.disa[1].value.toFixed(0) + "%",
      textAnchor: "start",
      x: this.coords[1][0] + 5,
      y: this.coords[1][1] + 8,
    }

    this.texts[2] = {
      text: this.getDisaName(this.props.disa[2].key),
      value: this.props.disa[2].value.toFixed(0) + "%",
      textAnchor: "end",
      x: this.coords[2][0] - 5,
      y: this.coords[2][1] + 5,
    }

    this.texts[3] = {
      text: this.getDisaName(this.props.disa[3].key),
      value: this.props.disa[3].value.toFixed(0) + "%",
      textAnchor: "end",
      x: this.coords[3][0] - 5,
      y: this.coords[3][1] - 14,
    }

  }

  getDisaName = (key: string) => {
    if (key === 'D') {
      return this.api.trTxt(TrVar.Driving)
    } else if (key === 'I') {
      return this.api.trTxt(TrVar.Boosting)
    } else if (key === 'S') {
      return this.api.trTxt(TrVar.Supporting)
    } else if (key === 'A') {
      return this.api.trTxt(TrVar.Detailed)
    } else {
      return "Unknown"
    }
  }

  checkAllValuesNotZero = () => {
    let sum = 0
    for (let i = 0; i < 4; i++) {
      sum += this.props.disa[i].value
    }

    if (sum === 0) {
      for (let i = 0; i < 4; i++) {
        this.props.disa[i].value = 25
      }
    }
  }

  render() {

    const randId = "disa_bg_" + Math.random()
    const randUrl = "url(#" + randId + ")"
    const conicBackground = "conic-gradient(from 45deg at 50% 50%, " + this.mainColor + " 45deg, "
      + this.stopColor + " 335deg)"

    return (
      <svg viewBox="0 0 200 150"
        xmlns="http://www.w3.org/2000/svg">
        <clipPath id={randId}>
          <path d={this.path} />
        </clipPath>

        <g transform="translate(50, 30)">
          <foreignObject width="100" height="100" clipPath={randUrl}>
            <div className="bg_disa_conic" />
            <div style={{ height: "100%", width: "100%", background: conicBackground }} />
          </foreignObject>
          {
            this.coords.map((coord, i) => {
              return (
                <circle key={"circle_" + i} cx={coord[0]} cy={coord[1]} r={2}
                  style={{ stroke: "#ffffff", strokeWidth: "0.8", fill: "none" }} />
              )
            })
          }
          {
            this.texts.map((t, i) => {
              return (
                <text key={"t_" + i} x={t.x} y={t.y} fontSize="0.4rem" fill="#ffffff" textAnchor={t.textAnchor}>
                  {t.text}
                </text>
              )
            })
          }
          {
            this.texts.map((t, i) => {
              return (
                <text key={"t_" + i} x={t.x} y={t.y + 9} fontSize="0.4rem" fill={this.mainColor} textAnchor={t.textAnchor}>
                  {t.value}
                </text>
              )
            })
          }
        </g>
      </svg>
    )
  }
}
