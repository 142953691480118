import React from 'react'
import Api from "../../services/Api"
import "./Tasks.css"
import {
  FocusAreaInterface,
  PotentialQuestionInterface,
  CoachQuestionInterface,
  FocusPlanInterface,
} from "./interfaces";
import {IonModal} from "@ionic/react";
import SelectReminderDate from "./SelectReminderDate";
import {formatDate, formatSoon} from "../../helpers/dateFunctions";
import SelectFollowUpDate from "./SelectFollowUpDate";
import {changeUrl} from "../../services/Navigation";
import SvgAnimation from '../SvgAnimation';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  navigation: any
  scrollRef: any
  baseUrl: string
  clientName: string
  focusArea: FocusAreaInterface
  potentialQuestion: PotentialQuestionInterface
  plans: Array<FocusPlanInterface>
  updatePlan: Function
  fromFollowup: boolean
}

interface ComponentState {
  position: number
  changes: number
  error: string
  acceptPlan: string
  plan: FocusPlanInterface
  coachQuestion: CoachQuestionInterface
  showCardDone: boolean
  areaComplete: boolean
  positionsDone: Array<boolean>
  showSelectDate: string
  showExample: boolean
  doneExample: boolean
  showPlanDoneModal: boolean
  showFollowupBtn: boolean
  showInstructions: boolean
}

export default class TaskPlan extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  positionsChanged: Array<boolean> = [false, false, false, false, false]
  nextSound = new Audio('/assets/sound/woop.mp3')

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      position: 0,
      changes: 0,
      acceptPlan: '-',
      plan: {} as FocusPlanInterface,
      error: '',
      showCardDone: false,
      coachQuestion: this.props.potentialQuestion.coachQuestions[0],
      areaComplete: false,
      positionsDone: [false, false, false, false, false],
      showSelectDate: '',
      showExample: false,
      doneExample: false,
      showPlanDoneModal: false,
      showFollowupBtn: false,
      showInstructions: true,
    }
  }

  componentDidMount() {
    this.bootPlan()
    setTimeout(() => {
      const tx = document.getElementsByTagName("textarea")
      for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px");
      }
      if (this.props.fromFollowup) {
        this.scrollDown(0)
      }
    }, 100)
  }

  bootPlan = () => {
    const plan = this.findPlan(this.props.potentialQuestion.coachQuestions[0].id)
    const acceptPlan = plan.plan.length === 0 ? '-' : 'y'
    const positionsDone = [
      plan.plan.length > 0,
      plan.when.length > 0,
      (!!plan.followUp && plan.followUp.length > 0),
      ((plan.reminderAt && plan.reminderAt.length > 0) || plan.planCompleted),
      plan.planCompleted
    ]
    this.setState({ plan: plan, acceptPlan: acceptPlan, positionsDone: positionsDone, showExample: plan.plan.length > 0, doneExample: plan.plan.length > 0 }, () => {
      if (plan.planCompleted) {
        this.setState({ showFollowupBtn: true, showInstructions: false })
      }
    })
  }

  forwardFollowup = () => {
    changeUrl(this.props.navigation.url + `/followup`)
  }

  findPlan = (coachQuestionId: number):FocusPlanInterface => {
    for (const plan of this.props.plans) {
      if (plan.coachQuestionId === coachQuestionId) {
        return plan
      }
    }
    const newPlan: FocusPlanInterface = {
      id: 0,
      coachQuestionId: coachQuestionId,
      plan: '',
      when: '',
      followUp: '',
      reminderAt: '',
      planCompleted: false,
      done: 0,
      notDoneReason: 0,
      feedback: '',
      feedbackOther: '',
      createdAt: '',
      updatedAt: '',
      followupCompleted: false,
    }
    return newPlan
  }

  private scrollDown = (position: number) => {
    const q = document.getElementById('scroll_p_' + position)
    if (this.props.scrollRef.current && q) {
      const scrollY = q.offsetTop - 50
      this.props.scrollRef.current.scrollToPoint(0, scrollY,625)
    }
  };

  savePlan = () => {
    const data = {
      id: this.state.plan.id,
      coach_question_id: this.state.plan.coachQuestionId,
      plan: this.state.plan.plan,
      when: this.state.plan.when,
      followup: this.state.plan.followUp,
      reminder_at: this.state.plan.reminderAt,
      plan_completed: this.state.plan.planCompleted,
      done: this.state.plan.done,
      not_done_reason: this.state.plan.notDoneReason,
      feedback: this.state.plan.feedback,
      feedback_other: this.state.plan.feedbackOther,
      followup_completed: this.state.plan.followupCompleted,
    }
    this.props.updatePlan(data);
    this.api.post('potential_plan/save', data).then(_result => {
      console.log('saved plan')
    })
  }

  handleDone = (position: number) => {
    const positionsDone = this.state.positionsDone
    positionsDone[position] = true
    this.positionsChanged[position] = false
    this.savePlan()
    this.props.updatePlan(this.state.plan)
    this.setState({ positionsDone: positionsDone })
    this.nextSound.play()
    setTimeout(() => this.scrollDown(position + 2), 200)
  }

  handleAnswer = (e: any, position: number) => {
    const plan = this.state.plan
    this.positionsChanged[position] = true
    if (position === 0) {
      plan.plan = e.target.value
    } else if (position === 1) {
      plan.when = e.target.value
    }
    this.setState({plan: plan})
    const h = e.target.scrollHeight > 270 ? 270 : e.target.scrollHeight
    e.target.style.height = h + "px"
  }

  saveReminder = (d: Date) => {
    const plan = this.state.plan
    const positionsDone = this.state.positionsDone
    positionsDone[3] = true
    d.setMinutes(0)
    d.setSeconds(0)
    plan.reminderAt = d.toISOString()
    plan.planCompleted = true

    this.setState({plan: plan, positionsDone: positionsDone, showSelectDate: ''}, () => this.savePlan())
    setTimeout(() => this.scrollDown(6), 200)
  }

  noReminder = () => {
    const plan = this.state.plan
    const positionsDone = this.state.positionsDone
    positionsDone[3] = true
    plan.reminderAt = ''
    plan.planCompleted = true

    this.setState({plan: plan, positionsDone: positionsDone, showSelectDate: ''}, () => this.savePlan())
    setTimeout(() => this.scrollDown(6), 200)
  }

  saveFollowUp = (d: Date) => {
    const plan = this.state.plan
    const positionsDone = this.state.positionsDone
    positionsDone[2] = true
    plan.followUp = d.toISOString().substring(0, 10)

    this.setState({plan: plan, positionsDone: positionsDone, showSelectDate: ''}, () => this.savePlan())
    setTimeout(() => this.scrollDown(5), 200)
  }

  finishPlan = () => {
    this.setState({ showPlanDoneModal: false })
    setTimeout(() => changeUrl('/app/client/tasks2/' + this.props.navigation.sub1 + '/'), 250)
  }

  showInput = (position: number) => {
    let value: string
    if (position === 0) {
      value = this.state.plan.plan
    } else if (position === 1) {
      value = this.state.plan.when
    } else {
      value = ''
    }
    const done = this.state.positionsDone[position]
    const changed = this.positionsChanged[position]
    return (
      <div className="flex justify-center top_margin">
        <div>
          <div className="flex flex-col relative" >
            <textarea 
              className="mx-auto task_text_area text_left"
              placeholder={this.api.trTxt(TrVar.WriteHere)}
              value={value}
              onInput={(e) => this.handleAnswer(e, position)} />

            <div className="bg-[rgb(0, 0, 38)] border-l-[1px] border-[#60608b] border-r-[1px]">
              <div className="task_bar" />
            </div>

            <div className={"flex justify-start cursor-pointer task_done_checkbox relative" +
              (value.length === 0 ? " disabled" : "")}
              onClick={() => this.handleDone(position)}>
              <div className={"task_radio_input " + (done ? "chosen" : "") + (value.length === 0 ? " disabled" : "")} />
              {
                (done && !changed) &&
                
                <div className='absolute h-5 object-contain p-0.5' >
                  <SvgAnimation color='#fff' position={(position + Math.random()).toString()} animationType='simple_checkmark' />
                </div>
              }
              <div className="left_margin">
                {
                  done ?
                    changed ? this.api.lang === 'sv' ? 'Spara ändringar' : 'Save changed'
                      : this.api.lang === 'sv' ? 'Sparat' : 'Saved'
                    : this.api.lang === 'sv' ? 'Redo för nästa fråga' : 'Ready for next question'
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

//   <div className="flex flex-col relative" >








  render() {
    return (
      <>
        <IonModal isOpen={this.state.showSelectDate.length > 0}
          onDidDismiss={() => this.setState({ showSelectDate: '' })}
          className="modal_r-full">
          <div className='r-top-calendar' />
          {
            this.state.showSelectDate === 'reminder' &&
            <SelectReminderDate save={(d: Date) => this.saveReminder(d)}
              close={() => this.setState({ showSelectDate: '' })} />
          }
          {
            this.state.showSelectDate === 'followUp' &&
            <SelectFollowUpDate save={(d: Date) => this.saveFollowUp(d)}
              close={() => this.setState({ showSelectDate: '' })} />
          }
        </IonModal>
        <div className="flex justify-center mx-auto" style={{ maxWidth: "800px" }}>
          <div className="w-full">
            <div className="task_question top_margin">
              <h4>
                {this.api.lang === 'sv' ? 'Nästa steg:' : `Next step`}
              </h4>
              <div>
                {this.api.lang === 'sv' ? 'Gör en plan utifrån dina svar.' : `Create a plan based on your answers`}
              </div>
              <div className="top_margin">
                {this.api.lang === 'sv' ? 'Vill du göra din plan nu?' : `Do you want to create the plan now?`}
              </div>
              <div className="top_margin flex">
                <div className="button blue inline_block" onClick={() => {
                  this.setState({ acceptPlan: 'y' })
                  setTimeout(() => this.scrollDown(0), 200)
                }}>
                  {this.api.lang === 'sv' ? 'Ja!' : `Yes!`}
                </div>
                <div className="button black inline_block ml-4"
                  onClick={() => {
                    this.setState({ acceptPlan: 'n' })
                    setTimeout(() => this.scrollDown(0), 200)
                  }}>
                  {this.api.lang === 'sv' ? 'Nej, inte just nu' : `Now is not a good time`}
                </div>
              </div>
            </div>
            <div className="task_question_arrow" />

            {
              this.state.acceptPlan === 'n' &&
              <>
                <div id='scroll_p_0' className="top_margin task_question">
                  <div>
                    {this.api.lang === 'sv' ?
                      'Okej, kom tillbaka och fortsätt när du kan.'
                      :
                      `Okey! Come back and continue when you can.`
                    }
                  </div>
                  <div className="top_margin button blue inline_block"
                    onClick={() => {
                      this.setState({ acceptPlan: '-' })
                      setTimeout(() => (changeUrl('/app/client/tasks2/' + this.props.navigation.sub1 + '/')), 250)
                    }}>
                    {this.api.lang === 'sv' ? 'Klar' : `Done`}
                  </div>
                </div>
                <div className="task_question_arrow" />
              </>
            }

            {
              this.state.acceptPlan === 'y' &&
              <>
                <div id='scroll_p_0' className="task_question top_margin relative">
                  <p>
                    {this.api.lang === 'sv' ? 'Härligt ' : `Great `} {this.props.clientName}!
                  </p>
                </div>
                <div className="task_question_arrow" />

                <div className="top_margin task_question">
                  <h4>
                    {this.api.lang === 'sv' ? 'Så kommer du igång:' : `How to get started:`}
                  </h4>
                  <div>
                    {this.api.lang === 'sv' ? 'Titta på dina svar ovan. Har du skrivit något som du kan ta vidare och formulera en plan utifrån?'
                      : `Look at your answers above. Have you written something that you can take action and formulate a plan from?`}
                  </div>
                  <div className='flex justify-between'>
                    <div onClick={() => {
                      this.setState({ showExample: true })
                      this.nextSound.play()
                      setTimeout(() => this.scrollDown(1), 200)
                    }} className="flex btn_top_margin relative cursor-pointer">
                      <div className={'task_radio_input task_plan   ' + (this.state.showExample ? 'checked' : '')} />
                      {this.state.showExample &&
                      <div className='absolute h-5 object-contain p-0.5'>
                        <SvgAnimation color='#000026' position={(this.state.plan.coachQuestionId + Math.random()).toString()} animationType='simple_checkmark' />
                      </div>
                      }
                      <div style={{ marginLeft: '7px' }}>{this.api.trTxt(TrVar.Next)}</div>
                    </div>
                    <div className='task_plan_instruction btn_top_margin'>
                      { !this.state.showInstructions &&
                        <div onClick={() => {this.setState({ showInstructions: true })}} className="cursor-pointer underline">
                          {this.api.lang === 'sv' ? 'Visa exempel' : `Show example`}
                        </div>                      
                      }                    
                    </div>               
                  </div>


                </div>
                <div className="task_question_arrow" />

                {(this.state.showExample && this.state.showInstructions) &&
                  <>
                    <div id='scroll_p_1' className="top_margin task_question">
                      <h4>{this.api.lang === 'sv' ? 'Så gör du din plan:' : `How to create your plan:`}</h4>
                    <div >
                        <ul>
                          <li>
                            {this.api.lang === 'sv' ? 'Planera in en enskild eller en återkommande aktivitet.' : `Plan a single or a recurring activity.`}
                          </li>
                          <li style={{ marginTop: '-5px' }}>
                            {this.api.lang === 'sv' ? 'Planen ska gå att genomföra inom de närmsta två veckorna.' : `You should be able to implement the plan within the next two weeks.`}
                          </li>
                        </ul>
                      </div>

                    <h4 className="top_margin">{this.api.lang === 'sv' ? 'Tänk på:' : `Bare in mind`}</h4>
                    <div >
                      <ul>
                        <li>
                          {this.api.lang === 'sv' ? 
                            'Var konkret. Avgränsa så mycket du kan!'
                            :
                            `Be specific and try to keep it simple.`
                          }
                        </li>
                        <li style={{ marginTop: '-5px' }}>
                          {this.api.lang === 'sv' ?
                            'Välj något som inte känns för svårt.  Börja hellre smått.'
                            :
                            `Choose something that doesn't feel too overwhelming. It's better to start small.`
                          }
                        </li>
                        <li style={{ marginTop: '-5px' }}>
                          {this.api.lang === 'sv' ?
                            'Planera vad du ska göra, inte hur du ska känna.'
                            :
                            `Plan what to do, not how to feel.`
                          }
                        </li>
                      </ul>
                    </div>
                    
                    <h4 className="top_margin">{this.api.lang === 'sv' ? 'Exempel' : `Example`}</h4>
                    <div>
                      <div>{this.api.lang === 'sv' ? 'Otydlig plan:' : `Unclear plan`}</div>
                      <ul style={{ marginTop: '0px'}}>
                        <li style={{ fontStyle: 'italic' }}>
                          {this.api.lang === 'sv' ? 'Jag borde höra av mig oftare till vänner.' : `I should reach out to friends more.`}
                        </li>
                      </ul>
                      <div className="top_margin">{this.api.lang === 'sv' ? 'Tydlig plan' : `Clear plan`}</div>
                      <ul style={{ marginTop: '0px'}}>
                        <li style={{ fontStyle: 'italic' }}>
                          {this.api.lang === 'sv' ? 'Jag ska ringa min vän Anna ikväll och föreslå att vi ska ses på onsdag nästa vecka.' : `I'm going to call my friend Anna tonight and suggest we meet on Wednesday next week.`}
                        </li>
                      </ul>                      
                    </div>
                      <div onClick={() => {
                          this.setState({ doneExample: true })
                          this.nextSound.play()                      
                          setTimeout(() => this.scrollDown(2), 200)
                        }} 
                        className="flex btn_top_margin relative cursor-pointer "
                      >
                        <div className={'task_radio_input task_plan ' + (this.state.doneExample ? 'checked' : '')} />
                        {this.state.doneExample &&
                          <div className='absolute h-5 object-contain p-0.5' >
                            <SvgAnimation  
                              color='#000026' 
                              position={(this.state.plan.coachQuestionId + Math.random()).toString()} 
                              animationType='simple_checkmark' 
                            />
                          </div>
                        }
                        <div style={{ marginLeft: '7px' }}>{this.api.trTxt(TrVar.Next)}</div>
                    </div>
                    </div>
                    <div className="task_question_arrow" />
                  </>
                }

                {this.state.doneExample &&
                  <>
                    <div id='scroll_p_2' className="top_margin task_question">
                      <h4>
                        {this.api.lang === 'sv' ? 'Så, vad ska du planera in att göra?' : `So what could you plan on doing?`}
                      </h4>
                      <div>
                        {this.api.lang === 'sv' ? 'Var så konkret du kan!' : `Be as precise as you can!`}
                      </div>
                    </div>
                    <div className="task_question_arrow" />
                  
                    {
                      this.showInput(0)
                    }
                  </>
                }

                {
                  this.state.positionsDone[0] &&
                  <>
                    <div id='scroll_p_3' className="task_question top_margin">
                      <h4>{this.api.lang === 'sv' ? 'När ska du göra det?' : `When will you do it?`}</h4>
                      <div>{this.api.lang === 'sv' ? 'Välj en tid inom de närmaste två veckorna.' : `Choose a deadline within the next two weeks.`}</div>
                    </div>
                    <div className="task_question_arrow" />
                    {
                      this.showInput(1)
                    }
                  </>
                }

                {
                  this.state.positionsDone[1] &&
                  <>
                    <div id='scroll_p_4' className="task_question top_margin">
                      <h4>{this.api.lang === 'sv' ? 'Uppföljning' : `Follow-up`}</h4>
                      <div>{this.api.lang === 'sv' ? 'När vill du följa upp hur det har gått?' : `When would you like to follow up on your plan?`}</div>
                      <div>{this.api.lang === 'sv' ? 'Bestäm en tid max två veckor fram i tiden.' : `Choose a deadline within the next two weeks.`}</div>
                        {this.state.plan.followUp &&
                          <>
                            <h4 className="top_margin">
                            {this.api.lang === 'sv' ? 'Gör uppföljning:' : `Your follow-up is set to: `} {
                              formatDate(this.state.plan.followUp, false, this.api.lang)
                            }                          
                            </h4>                                                
                          </>
                        }

                      <div className="button top_margin inline_block" onClick={() => this.setState({ showSelectDate: 'followUp' })}>
                        {
                          this.state.plan.followUp.length > 0 ? (
                            <span>{this.api.trTxt(TrVar.Edit)}</span>
                          ) : (
                            <span>{this.api.lang === 'sv' ? 'Välj dag' : `Choose date`}</span>
                          )
                        }
                      </div>
                    </div>
                    <div className="task_question_arrow" />
                  </>
                }
                
                {
                  this.state.positionsDone[2] &&
                  <>
                    <div id='scroll_p_5' className="task_question top_margin">
                      <div>{this.api.lang === 'sv' ? 'Vill du ha mejlpåminnelse för din plan?' : `Would you like to receive an email reminder for your plan?`}</div>
                      {this.state.plan.reminderAt &&
                      <>
                        <h4 className="top_margin">
                          {this.api.lang === 'sv' ? 'Påminnelse:' : `Reminder: `} {
                            formatSoon(this.state.plan.reminderAt, this.api.lang)
                          }                        
                        </h4>                           
                      </>
                      }
                      <div className="button top_margin inline_block" onClick={() => this.setState({ showSelectDate: 'reminder' })}>
                        {this.state.plan.reminderAt.length > 0 ? this.api.trTxt(TrVar.Edit) : this.api.trTxt(TrVar.Yes)}
                      </div>
                      <div className="button top_margin left_margin black inline_block" onClick={() => this.noReminder()}>
                        {(this.state.plan.reminderAt.length > 0 && this.api.lang === 'sv') ? 'Ta bort' : this.api.lang === 'sv' ? 'Nej' : (this.state.plan.reminderAt.length > 0 && this.api.lang === 'en') ? 'Delete' : 'No'}
                      </div>
                    </div>
                    <div className="task_question_arrow" />
                  </>
                }
                
                {
                  this.state.plan.planCompleted &&
                  <>
                    <IonModal isOpen={this.state.showPlanDoneModal}
                      onDidDismiss={() => this.setState({ showPlanDoneModal: false })}
                      className="modal_tall">
                      <div className="bg_blue h-full ">
                        <img onClick={() => this.setState({ showPlanDoneModal: false })} className='absolute modal_close'
                          src="/assets/focusArea/cross_black.svg" height={'20px'} alt="" />
                        <div style={{ height: '100%' }} className="flex flex-col justify-center">
                          <div className="text_center text-neutral-900">
                            <div className='modal_animation mx-auto'>
                              <SvgAnimation animationType='cup' />
                            </div>
                            <h1>
                              {this.api.lang === 'sv' ? 'Bra jobbat!' : 'Well done!'}
                            </h1>
                            <div className="top_margin">
                              {this.api.lang === 'sv' ? 'Du är nu ett steg närmare att nå ditt önskeläge.' : `You are one step closer to reaching your desired state.`}
                            </div>
                            <div>
                              {this.api.lang === 'sv' ? 'Fortsätt så!' : `Keep up the good work!`}
                            </div>
                            <div onClick={() => this.finishPlan()} className="button green inline_block btn_top_margin">{this.api.trTxt(TrVar.GoForward)}</div>
                          </div>
                        </div>
                      </div>
                    </IonModal>
                    <div style={{ marginTop: '40px' }} className="flex task_summary_divider">
                      <div className="task_divider" />
                      <div className='font_smaller blue_gray left_margin right_margin'>{this.api.lang === 'sv' ? 'Sammanfattning' : `Summary`}</div>
                      <div className="task_divider" />
                    </div>
                    <div id='scroll_p_6' style={{ marginTop: '100px' }} className="task_question ">
                      <h3>{this.api.lang === 'sv' ? 'Din plan' : `Your plan`}</h3>
                      <h4 className="top_margin">{this.api.lang === 'sv' ? 'Vad du ska göra' : `What you'll do`}</h4>
                      <div className="whitespace-pre-line">
                        {this.state.plan.plan}
                      </div>
                      <h4 className="top_margin">{this.api.lang === 'sv' ? 'När du ska göra det' : `When you'll do it`}</h4>
                      <div className="whitespace-pre-line">
                        {this.state.plan.when}
                      </div>
                      <h4 className="top_margin">{this.api.lang === 'sv' ? 'Uppföljning' : `Follow-up:`}</h4>
                      <div>
                        {formatDate(this.state.plan.followUp, false, this.api.lang)}
                      </div>
                      {
                        this.state.plan.reminderAt.length > 0 &&
                        <>
                          <h4 className='top_margin'>{this.api.lang === 'sv' ? 'Påminnelse' : `Reminder:`}</h4>
                          <div>
                            {formatSoon(this.state.plan.reminderAt, this.api.lang)}
                          </div>
                        </>
                      }
                    </div>
                    <div className="task_question_arrow" />
                    <div className='text_center'>
                      <div className="btn_top_margin button inline_block green" onClick={() => this.setState({ showPlanDoneModal: true })}>{this.api.lang === 'sv' ? 'Jag är klar' : `I'm done`}</div>
                      {this.state.showFollowupBtn &&
                        <div onClick={() => this.forwardFollowup()} className='btn_top_margin button inline_block white left_margin'>
                          {this.api.lang === 'sv' ? 'Gå till uppföljning' : `To follow-up`}
                        </div>
                      }
                    </div>
                  </>
                }
              </>
            }

          </div>
        </div>
      </>
    )
  }
}
