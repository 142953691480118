import React from 'react'
import Calendar from "../calendar/Calendar";
import {dateFormatDate} from "../../helpers/dateFunctions";
import Api from "../../services/Api";

interface ComponentProps {
  save: Function
  close: Function
}

interface ComponentState {}

export default class SelectReminderDate extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  firstDate: Date = new Date()
  lastDate: Date = new Date()

  constructor(props: ComponentProps) {
    super(props);
    this.lastDate.setDate(this.firstDate.getDate() + 14)
    this.lastDate.setHours(23)
    this.lastDate.setMinutes(59)
  }

  clickDate = (d: Date) => {
    if (d < this.firstDate) {
      const msg = this.api.lang === 'sv' ?
        'Du kan inte välja en påminnelsetid som passerat'
        :
        'You cannot choose a reminder time that has passed'
      alert(msg)
      return
    } else if (d > this.lastDate) {
      const msg = this.api.lang === 'sv' ?
        'Välj en påminnelsetid senast ' + dateFormatDate(this.lastDate, false, this.api.lang)
        :
        'Please select a reminder latest ' + dateFormatDate(this.lastDate, false, this.api.lang)
      alert(msg)
      return
    }
    this.props.save(d)
  }

  clickEvent = (e: any) => {
    console.log(e)
  }

  render() {

    let paddingBottom = 236
    let subtractHeight = window.innerWidth < 900 ? 148 : 90
    const pageHeight = "calc(100vh - " + subtractHeight + "px)"

    return (
      <div className="page_section" style={{ height: pageHeight, paddingBottom: paddingBottom + "px" }}>
        <h3 className="white text_center ml-4">
          Välj tid för påminnelse
          {/*<span className="hide_small">, senast {dateFormatDate(this.lastDate, false, this.api.lang)}</span>*/}
        </h3>
        <Calendar events={[]} clickDate={this.clickDate} clickEvent={this.clickEvent} firstDate={this.firstDate}
                  lastDate={this.lastDate} lockPeriod='week'/>
      </div>
    )
  }
}
