import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {MeasureArea} from "../../interfaces/MeasureArea";
import {MeasureQuestion} from "../../interfaces/MeasureQuestion";
import Loading from "../Loading";
import {DisaInterface} from "../../interfaces/Disa";
import {formatDate, formatDateMonDay, formatHourMinute, formatDateYear} from "../../helpers/dateFunctions";
import Breadcrumbs from "../menu/Breadcrumbs";
import LineChart from "../graphics/LineChart";
import SpeedoMeter from "../graphics/SpeedoMeter";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import { TrVar } from "../../services/translate";
import Text from "../Text/Text"

interface MeasureInterface {
  id: number
  version: number
  createdAt: string
  areas: Array<MeasureArea>
  totalCurrent: number
  totalDesired: number
}

interface ComponentProps{
  close: Function
  coachId?: number
  clientId?: number
  areaNr?: number
  summary: string
  onboarding?: boolean
  navigation?: any
  progress?: Function
  coachFree?: boolean
  packageFree?: boolean
  finishSection?: Function
}

interface ComponentState {
  measures: Array<MeasureInterface>
  areaNr: number
  firstMeasure: MeasureInterface
  lastMeasure: MeasureInterface
  firstName: string
  disa: Array<DisaInterface>
  loaded: boolean
  sumArea: number
  sumAreas: Array<string>
  summary: string
  startMeasureNr: number
  sortHighest: boolean
  compareAll: boolean
  summaryShowMeasure: number
  summaryCompareMeasure: number
}


export default class ClientMeasureDevelopment extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  graphColors = [
    "#BC80F0",
    "#60B9A5",
    "#5B6EF4",
    "#EAF47F",
    "#FF00C7",
  ]

  state: Readonly<ComponentState> = {
    measures: [],
    areaNr: this.props.areaNr ? this.props.areaNr : -1,
    firstMeasure: {} as MeasureInterface,
    lastMeasure: {} as MeasureInterface,
    startMeasureNr: 0,
    firstName: '',
    disa: [],
    loaded: false,
    sumArea: 0,
    sumAreas: [
      this.api.trTxt(TrVar.TotalPotential).toLowerCase(),
      this.api.trTxt(TrVar.SelfInsight),
      this.api.trTxt(TrVar.BasicEnergy),
      this.api.trTxt(TrVar.Motivation),
      this.api.trTxt(TrVar.SelfLeadership),
    ],
    compareAll: false,
    summary: this.props.summary,
    sortHighest: false,
    summaryShowMeasure: 0,
    summaryCompareMeasure: 0,
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
    if (this.props.areaNr && prevProps.areaNr !== this.props.areaNr) {
      this.setState({areaNr: this.props.areaNr})
    }
    if (prevProps.summary !== this.props.summary) {
      this.setState({summary: this.props.summary})
    }
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    let url = 'measures?full=1'
    if (this.props.clientId && this.props.coachId) {
      url = 'measures_coach/' + this.props.clientId + '?full=1'
    }
    this.api.get(url).then(response => {
      const measures = response.json.measures.length
      let startMeasureNr = 0
      if (measures > 4) {
        startMeasureNr = measures - 4
      }
      this.setState({
        measures: response.json.measures,
        firstMeasure: response.json.measures[measures - 1],
        lastMeasure: response.json.measures[0],
        firstName: response.json.firstName,
        startMeasureNr: startMeasureNr,
        summaryCompareMeasure: measures - 1,
        disa: response.json.disa,
        loaded: true
      })
    })
  }

  findFirstQuestion = (area: MeasureArea, id: number) => {
    for (const q of area.questions) {
      if (q.id === id) {
        return q
      }
    }
    return null
  }

  chooseArea = (area: number) => {
    this.setState({sumArea: area, compareAll: false})
  }

  closeArea = () => {
    this.changeArea(-1)
  }

  changeArea = (areaNr: number) => {
    if (this.props.coachId) {
      let url = '/app/coach/clients/' + this.props.navigation.client + '/development';
      if (areaNr >= 0) {
        url += '/' + areaNr
      }
      changeUrl(url)
    } else {
      let url = '/app/client/measure'
      if (areaNr >= 0) {
        url += '/' + areaNr
      }
      changeUrl(url)
    }
    this.setState({areaNr: areaNr})
  }

  changeText = (value: number) => {
    if (value > 0.5) {
      return this.api.trTxt(TrVar.Has).toLowerCase() + ' ' + this.api.trTxt(TrVar.Increased).toLowerCase() + ' ' + this.api.trTxt(TrVar.With).toLowerCase() + ' ' +
        value.toFixed(0) + '%'
    } else if (value > -0.5)  {
      return this.api.trTxt(TrVar.IsUnchanged).toLowerCase()
    } else {
      return this.api.trTxt(TrVar.Has).toLowerCase() + ' ' + this.api.trTxt(TrVar.Decreased).toLowerCase() + ' ' + this.api.trTxt(TrVar.With).toLowerCase() + ' ' +
        Math.abs(value).toFixed(0) + '%'
    }
  }

  showMeasures = () => {

    let dashes: any
    if (this.state.sumArea >= 0 && !this.state.compareAll) {
      const areaDesired = this.state.sumArea === 0 ?
        this.state.lastMeasure.totalDesired :
        this.state.lastMeasure.areas[this.state.sumArea - 1].desired

      const areaCurrent = this.state.sumArea === 0 ?
        this.state.firstMeasure.totalCurrent :
        this.state.firstMeasure.areas[this.state.sumArea - 1].current
      dashes = []
      dashes.push({
        name: this.api.trTxt(TrVar.DesiredState),
        value: areaDesired
      })
      dashes.push({
        name: this.api.trTxt(TrVar.StartState),
        value: areaCurrent
      })
    }

    const colors = this.state.compareAll ? this.graphColors : [this.graphColors[this.state.sumArea]]
    const valueArr = this.state.compareAll ? [[], [], [], [], []] : [[]]

    const names = []

    let measureNr = -1
    let foundMeasures = 0
    for (const measure of this.state.measures.slice().reverse()) {
      measureNr++
      if (measureNr < this.state.startMeasureNr) {
        continue
      }
      const name = formatDateMonDay(measure.createdAt, this.api.lang)
      names.push(name)
      if (this.state.compareAll) {
        for (let i = 0; i < 5; i++) {
          const value = i === 0 ? measure.totalCurrent : measure.areas[i - 1].current
          // @ts-ignore
          valueArr[i].push(value)
        }

      } else {
        const value = this.state.sumArea === 0 ? measure.totalCurrent : measure.areas[this.state.sumArea - 1].current
        // @ts-ignore
        valueArr[0].push(value)
      }
      foundMeasures++
      if (foundMeasures >= 4) {
        break
      }
    }

    let scale = 1
    let bottom = "1px"
    let left = "-10px"
    let right = "10px"
    if (window.innerWidth > 1000) {
      scale = 2.5
      bottom = "17px"
      left = "2px"
      right = "80px"
    }
    const width = scale * 314 + "px"
    const height = scale * 125 + "px"

    return (
      <div className="relative" style={{width: width, height: height}}>
        {
          this.state.startMeasureNr > 0 &&
            <div style={{position: "absolute", left: left, bottom: bottom}} className="cursor-pointer"
                 onClick={() => this.setState({startMeasureNr: this.state.startMeasureNr - 1})}>
                <img alt="left" className="icon_small" src="/assets/icon/white/chevron-back-outline.svg"/>
            </div>
        }
        <LineChart isArea={true} valueArr={valueArr} names={names} colors={colors} dashes={dashes} width={250} maxWidth={true}/>
        {
          ((this.state.startMeasureNr + 4) < this.state.measures.length) &&
          <div style={{position: "absolute", right: right, bottom: bottom}} className="cursor-pointer"
               onClick={() => this.setState({startMeasureNr: this.state.startMeasureNr + 1})}>
              <img alt="left" className="icon_small" src="/assets/icon/white/chevron-forward-outline.svg"/>
          </div>
        }
      </div>
    )

  }

  showMeasureSummary = () => {
    let namePrefix: string
    if (this.state.sumArea === 4) {
      namePrefix = this.api.trTxt(TrVar.Yours)
    } else {
      namePrefix = this.api.trTxt(TrVar.Your)
    }
    const name = namePrefix + ' ' + this.state.sumAreas[this.state.sumArea]
    let improveFromLast = 0
    let improveFromStart = 0
    const measures = this.state.measures.length

    if (measures > 1) {
      if (measures > 2) {
        if (this.state.sumArea === 0) {
          improveFromLast = this.state.lastMeasure.totalCurrent - this.state.measures[1].totalCurrent
        } else {
          improveFromLast = this.state.lastMeasure.areas[this.state.sumArea - 1].current -
            this.state.measures[1].areas[this.state.sumArea - 1].current
        }
      }

      if (this.state.sumArea === 0) {
        improveFromStart = this.state.lastMeasure.totalCurrent - this.state.firstMeasure.totalCurrent
      } else {
        improveFromStart = this.state.lastMeasure.areas[this.state.sumArea - 1].current -
          this.state.firstMeasure.areas[this.state.sumArea - 1].current
      }
    }

    const currentStart = this.state.sumArea === 0 ?
      this.state.firstMeasure.totalCurrent.toFixed(0) :
      this.state.firstMeasure.areas[this.state.sumArea - 1].current.toFixed(0)

    const currentLast = this.state.sumArea === 0 ?
      this.state.lastMeasure.totalCurrent.toFixed(0) :
      this.state.lastMeasure.areas[this.state.sumArea - 1].current.toFixed(0)
    const gapLast = this.state.sumArea === 0 ?
      (this.state.lastMeasure.totalDesired - this.state.lastMeasure.totalCurrent) :
      (this.state.lastMeasure.areas[this.state.sumArea - 1].desired -
        this.state.lastMeasure.areas[this.state.sumArea - 1].current)

    return (
      <div className="r-flex mt-12">
        <div>
          <h2>{name}</h2>
          <div className="r-flex top_margin justify-around">
            <div>
              <h4>{this.api.trTxt(TrVar.YourStartState)}:</h4>
              <h1 className={"top_margin color_area" + this.state.sumArea}>{currentStart}%</h1>
            </div>
            {
              this.state.measures.length > 1 &&
              <div className="r_left_margin r_top_margin">
                  <h4>{this.api.trTxt(TrVar.YourCurrentState)}:</h4>
                  <h1 className={"top_margin color_area" + this.state.sumArea}>{currentLast}%</h1>
              </div>
            }
            <div className="r_left_margin r_top_margin">
              <h4>{this.api.trTxt(TrVar.YourDesiredState)}:</h4>
              <h1 className={"top_margin color_area" + this.state.sumArea}>
                {
                  this.state.sumArea === 0 ?
                    this.state.lastMeasure.totalDesired.toFixed(0) :
                    this.state.lastMeasure.areas[this.state.sumArea - 1].desired.toFixed(0)
                }%
              </h1>
            </div>
          </div>

          {
            measures > 1 &&
            <div className="top_margin">
                <p>{name} {this.api.trTxt(TrVar.Has).toLowerCase()} {this.api.trTxt(TrVar.GoneFrom).toLowerCase()}</p>
                <p className="top_margin">
                    <span className={"color_area" + this.state.sumArea}>{currentStart}% {this.api.trTxt(TrVar.To).toLowerCase()} {currentLast}%</span>
                   &nbsp;{this.api.trTxt(TrVar.SinceYouStarted).toLowerCase()}.
                </p>
            </div>

          }

          <div className="top_margin">
            {this.api.trTxt(TrVar.YouHave)} <span className={"color_area" + this.state.sumArea}>{gapLast.toFixed(0)}%</span> {this.api.trTxt(TrVar.LeftToDesiredState).toLowerCase()}.
          </div>

          {
            (!this.props.onboarding && this.state.sumArea > 0) &&
            <div className="top_margin button inline_block"
                 onClick={() => this.changeArea(this.state.sumArea)}>
              {this.api.trTxt(TrVar.ReadMore)} {this.api.trTxt(TrVar.About).toLowerCase()} {name.toLowerCase()}
            </div>

          }
        </div>
        {
          this.state.measures.length > 1 &&
          <div className="r_left_margin r_top_margin">

            {
              measures > 2 &&
              <div className="r-flex items-center">
                  <div style={{width: "180px", height: "180px"}}>
                      <SpeedoMeter value={improveFromLast}/>
                  </div>
                  <div className="r_left_margin r_top_margin" style={{maxWidth: "250px"}}>
                      <h1 className={"color_area" + this.state.sumArea}>
                        {improveFromLast.toFixed(0)}%
                      </h1>
                      <p>{name} {this.changeText(improveFromLast)} {this.api.trTxt(TrVar.SinceLastMeasurement).toLowerCase()}</p>
                  </div>
              </div>
            }


              <div className="r-flex top_margin items-center">
                  <div style={{width: "180px", height: "180px"}}>
                      <SpeedoMeter value={improveFromStart}/>
                  </div>
                  <div className="r_left_margin r_top_margin" style={{maxWidth: "250px"}}>
                      <h1 className={"color_area" + this.state.sumArea}>
                        {improveFromStart.toFixed(0)}%
                      </h1>
                      <p>{name} {this.changeText(improveFromStart)} {this.api.trTxt(TrVar.SinceYouStarted).toLowerCase()}</p>
                  </div>
              </div>

          </div>

        }
      </div>
    )
  }

  closeSummary = () => {
    if (this.props.coachId) {
      this.setState({ summary: '' })
    } else {
      changeUrl('/app/client/measure')
    }

  }

  sortQuestions = (q1: MeasureQuestion, q2: MeasureQuestion) => {
    let diff: number
    if (this.state.summary === 'desired') {
      diff = q1.valueDesired - q2.valueDesired
    } else if (this.state.summary === 'gap') {
      diff = (q1.valueDesired - q1.valueCurrent) - (q2.valueDesired - q2.valueCurrent)
    } else if (this.state.summary === 'change') {
      // @ts-ignore
      diff = q1.changeCurrentSinceStart - q2.changeCurrentSinceStart
    } else {
      diff = q1.valueCurrent - q2.valueCurrent
    }

    if (this.state.sortHighest) {
      return -diff
    } else {
      return diff
    }
  }

  goToSummary = () => {
    if (this.props.coachId) {
      this.setState({summary: 'current'})
    } else {
      changeUrl('/app/client/measure/summary/current')
    }
  }

  changeSort = (s: string) => {
    if (s === this.state.summary) {
      this.setState({sortHighest: !this.state.sortHighest})
    } else {
      this.setState({summary: s})
    }
  }

  showQuestionBar = (question: MeasureQuestion) => {
    let width = 0
    if (this.state.summary === 'change') {
      if (question.changeCurrentSinceStart) {
        width = question.changeCurrentSinceStart
      }

    } else if (this.state.summary === 'desired') {
      width = question.valueDesired
    } else if (this.state.summary === 'gap') {
      width = question.valueDesired - question.valueCurrent
    } else {
      width = question.valueCurrent
    }
    let widthStr: string
    let bgColor: string
    if (width > 0) {
      widthStr = width + '%'
      bgColor = "task_bgcolor_" + question.areaNr
    } else {
      widthStr = Math.abs(width) + '%'
      bgColor = "bg_red"
    }
    return(
      <div className={bgColor} style={{width: widthStr, height: "10px"}}/>
    )
  }

  showTableExplanation = () => {
    let explanation = "Staplarna visar "
    const showDate = formatDateYear(this.state.measures[this.state.summaryShowMeasure].createdAt)
    const compareDate = formatDateYear(this.state.measures[this.state.summaryCompareMeasure].createdAt)
    if (this.state.summary === 'change') {
      explanation += "förändring i nu-läge (+/-)"
      if (this.state.summaryShowMeasure === this.state.summaryCompareMeasure) {
        explanation += " (den är alltid 0 då det inte är två olika mätningar)"
      } else {
        explanation += " mellan " + compareDate + " och " + showDate
      }
    } else if (this.state.summary === 'desired') {
      explanation += "önskat läge " + showDate
    } else if (this.state.summary === 'gap') {
      explanation += "gap " + showDate
    } else {
      explanation += "nu-läge " + showDate
    }

    return(
      <div className="mb-4">
        <i>{explanation}</i>
      </div>
    )
  }

  showSummary = () => {
    let breadcrumbsName: string
    const breadcrumbs = []
    if (this.props.coachId) {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.state.firstName, link: '', linkFunc: this.props.close})
      breadcrumbs.push({name: this.api.trTxt(TrVar.DevelopmentFor) + ' ' + this.state.firstName, link: '',
        linkFunc: this.closeSummary})
    } else {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MeasurePotential), link: '', linkFunc: this.closeSummary})
    }
    breadcrumbsName = this.api.trTxt(TrVar.Summary)

    const questions = []
    const sameMeasure = this.state.summaryShowMeasure === this.state.summaryCompareMeasure
    const lastMeasure = this.state.measures[this.state.summaryShowMeasure]
    const firstMeasure = this.state.measures.length > 1 ? this.state.measures[this.state.summaryCompareMeasure] : undefined
    for (const area of lastMeasure.areas) {
      let i = 0
      for (const q of area.questions) {
        q.areaNr = area.nr
        q.changeCurrentSinceStart = 0
        if (firstMeasure) {
          const firstQ = firstMeasure.areas[area.nr].questions[i]
          if (firstQ) {
            q.changeCurrentSinceStart = q.valueCurrent - firstQ.valueCurrent
          }
        }
        questions.push(q)
        i++
      }
    }

    return (
      <div className={this.props.onboarding ? "" : "page_content"}>
        {
          !this.props.onboarding &&
            <Breadcrumbs breadcrumbs={breadcrumbs}
                         name={breadcrumbsName} />
        }

        <div className="page_section">
          <h1>Summering</h1>
          <div className="page_bar"/>
          <div>
            Här kan du sortera på mätningens nuläge, önskat läge eller gap.
            {
              this.state.measures.length > 1 &&
                <p className="mt-4">Du kan också se ändring mellan mätningars nu-läge (+/-)</p>
            }
          </div>
          {
            this.state.measures.length > 1 &&
              <div className="mt-8 flex">
                  <div>
                      <h3>Visa mätning</h3>
                    {
                      this.state.measures.map((measure, idx) => {
                        return (
                            <div key={"show_measure_" + idx} className="mt-2 cursor-pointer flex items-center"
                                 onClick={() => this.setState({summaryShowMeasure: idx})}>
                              <div className={"check_box " + (idx === this.state.summaryShowMeasure ? "bg_blue": "")}/>
                              <div className="ml-2">{formatDateYear(measure.createdAt)}</div>
                            </div>
                          )
                      })
                    }
                  </div>
                  <div className="r-ml-8 r-mt-4">
                      <h3>Jämför med</h3>
                    {
                      this.state.measures.map((measure, idx) => {
                        return (
                          <div key={"show_measure_" + idx} className="mt-2 cursor-pointer flex items-center"
                               onClick={() => this.setState({summaryCompareMeasure: idx})}>
                            <div className={"check_box " + (idx === this.state.summaryCompareMeasure ? "bg_blue": "")}/>
                            <div className="ml-2">{formatDateYear(measure.createdAt)}</div>
                          </div>
                        )
                      })
                    }
                  </div>

              </div>
          }
        </div>

        <div className="page_section top_margin">
          {this.showTableExplanation()}
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd={sameMeasure ? "9" : "8"}>
                <h3>Påstående</h3>
              </IonCol>
              <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('current')} className="underline cursor-pointer">
                Nu
                {
                  this.state.summary === 'current' &&
                    <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow-"
                    + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
                }
              </IonCol>
              {
                !sameMeasure &&
                <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('change')} className="underline cursor-pointer">
                    +/-
                  {
                    this.state.summary === 'change' &&
                    <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow-"
                    + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
                  }
                </IonCol>
              }

              <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('desired')} className="underline cursor-pointer">
                Önskat
                {
                  this.state.summary === 'desired' &&
                  <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow-"
                  + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
                }
              </IonCol>
              <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('gap')} className="underline cursor-pointer">
                Gap
                {
                  this.state.summary === 'gap' &&
                  <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow-"
                  + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
                }
              </IonCol>
            </IonRow>
            {
              questions.sort(this.sortQuestions).map(q => {
                return (
                  <IonRow key={"question_" + q.id}>
                    <IonCol size="12" sizeMd={sameMeasure ? "9" : "8"}>
                      <div>{this.state.sumAreas[q.areaNr + 1]}: {q.question}</div>
                      {this.showQuestionBar(q)}
                    </IonCol>
                    <IonCol size="3" sizeMd="1">
                      {q.valueCurrent}
                    </IonCol>
                    {
                      !sameMeasure &&
                      <IonCol size="3" sizeMd="1">
                        {q.changeCurrentSinceStart}
                      </IonCol>
                    }
                    <IonCol size="3" sizeMd="1">
                      {q.valueDesired}
                    </IonCol>
                    <IonCol size="3" sizeMd="1">
                      {q.valueDesired - q.valueCurrent}
                    </IonCol>
                  </IonRow>
                )
              })
            }
          </IonGrid>
        </div>
      </div>
    )
  }

  questionLineChart = (question: MeasureQuestion, firstArea: MeasureArea) => {
    const values = []
    const names = []
    for (let i = this.state.measures.length - 1; i >= 0; i--) {
      const measure = this.state.measures[i]
      for (const area of measure.areas) {
        for (const q of area.questions) {
          if (q.id === question.id) {
            values.push(q.valueCurrent)
            names.push(formatDateMonDay(measure.createdAt, this.api.lang))
          }
        }
      }
    }
    values.push(question.valueDesired)
    names.push(this.api.trTxt(TrVar.DesiredState))
    const colors = [this.graphColors[this.state.sumArea]]
    return (
        <div className="mt-4" style={{maxWidth: "600px"}}>
          <LineChart isArea={false} valueArr={[values]} names={names} colors={colors} width={250} maxWidth={false}/>
        </div>
    )
  }

  onboardProgress = (sub: number) => {
    if (this.props.progress && this.props.finishSection) {
      this.props.progress(4)
      this.props.finishSection()
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading />
      )
    }

    if (this.state.measures.length === 0) {
      return (
        <div className="page_content">
          <div className="page_section">
            <h2>Not available</h2>
            <p className="top_margin">First measure is not done yet</p>
          </div>
        </div>
      )
    }

    if (this.state.summary.length > 0) {
      return this.showSummary()
    }

    if (this.state.areaNr > 0) {
      const areaNr = this.state.areaNr - 1
      const firstArea =  this.state.firstMeasure.areas[areaNr]
      const area =  this.state.lastMeasure.areas[areaNr]

      const breadcrumbs = []
        if (this.props.clientId && this.props.coachId) {
          breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
          breadcrumbs.push({name: this.state.firstName, link: '', linkFunc: this.props.close})
          const clientBaseName = this.api.trTxt(TrVar.DevelopmentFor) + ' ' + this.state.firstName
          breadcrumbs.push({name: clientBaseName, link: '', linkFunc: this.closeArea})
        } else {
          breadcrumbs.push({name: this.api.trTxt(TrVar.YourMeasurements), link: '', linkFunc: this.closeArea})
        }
        return (
          <div className={this.props.onboarding ? "" : "page_content"}>
            {
              !this.props.onboarding &&
                <Breadcrumbs breadcrumbs={breadcrumbs}
                            name={area.name} />
            }
            <div className="page_section">
              <h1>{this.api.trTxt(TrVar.ResultPerQuestion)}</h1>
              {
                area.questions.map((question: MeasureQuestion, questionIndex) => {
                  const firstQuestion = this.findFirstQuestion(firstArea, question.id)
                  return (
                    <div key={"question_" + questionIndex}>
                      <h3 className="top_margin">{question.question}</h3>
                      {this.questionLineChart(question, firstArea)}
                      {
                        (this.state.measures.length > 1 && firstQuestion) &&
                        <div className="top_margin">
                          {this.api.trTxt(TrVar.You)} {this.api.trTxt(TrVar.Have).toLowerCase()} {this.api.trTxt(TrVar.GoneFrom).toLowerCase()} <span className={"color_area" + this.state.sumArea}>{firstQuestion.valueCurrent}%
                          {this.api.trTxt(TrVar.To).toLowerCase()} {question.valueCurrent}%</span> {this.api.trTxt(TrVar.SinceYouStarted).toLowerCase()}.
                        </div>
                      }
                      {
                        (question.valueDesired - question.valueCurrent) > 0 ? (
                          <div className="top_margin">
                            {this.api.trTxt(TrVar.YouHave)} <span className={"color_area" + this.state.sumArea}>{(question.valueDesired - question.valueCurrent)}%</span> {this.api.trTxt(TrVar.LeftToDesiredState).toLowerCase()}.
                          </div>
                        ) : (
                          <div className="top_margin">
                            {this.api.trTxt(TrVar.YouAre)} <span className={"color_area" + this.state.sumArea}>{(question.valueCurrent - question.valueDesired)}%</span> {this.api.trTxt(TrVar.AboveDesiredState).toLowerCase()}.
                          </div>
                        )
                      }

                      <div className="page_bar" style={{'margin': '20px 0'}} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      } else {
        let improveText: string
        let increasedP = this.state.lastMeasure.totalCurrent - this.state.firstMeasure.totalCurrent
        let improveP = 0
        if (this.state.firstMeasure.totalCurrent > 0) {
          // HD 20211222: Don't use relative percentage, use percentage units to be consistent with rest of figures
          // improveP = 100 * increasedP / this.state.firstMeasure.totalCurrent
          improveP = increasedP
        }
        if (increasedP > 0.6) {
          improveText = this.api.trTxt(TrVar.YouHaveImprovedYourTotalPotential) + ' '
          improveText += Math.abs(improveP).toFixed(0) + '%'
        } else if (increasedP > -0.6) {
          improveText = ''
        } else {
          improveText = this.api.trTxt(TrVar.YouHaveDecreasedYourTotalPotential) + ' '
          improveText += Math.abs(improveP).toFixed(0) + '%'
        }

        let breadcrumbsName: string
        const breadcrumbs = []
        if (this.props.coachId) {
          breadcrumbsName = this.api.trTxt(TrVar.DevelopmentFor) + ' ' + this.state.firstName
          breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
          breadcrumbs.push({name: this.state.firstName, link: '', linkFunc: this.props.close})
        } else {
          breadcrumbsName = this.api.trTxt(TrVar.YourMeasurements)
        }

      return (
        <div className={this.props.onboarding ? "" : "page_content"}>
          {
            (!this.props.onboarding && !this.props.coachId) &&
            <div style={{ backgroundColor: '#BC80F0', color: '#fff' }} className="page_section">
              <div className="r-flex justify-between items-center">
                <h1>{this.api.trTxt(TrVar.HaveYouDeveloped)}</h1>
                <div className='r_top_margin r_right_margin r_left_margin' style={{ maxWidth: '406px' }}>
                  {this.api.trTxt(TrVar.HaveYouGainedInsights)}
                </div>
                <div className="r_top_margin button green inline_block" onClick={() => changeUrl('/app/client/measure/new')}>
                  {this.api.trTxt(TrVar.MeasureYourDevelopment)}
                </div>
              </div>
            </div>
          }
          {
            !this.props.onboarding &&
            <Breadcrumbs breadcrumbs={breadcrumbs}
              name={breadcrumbsName} />
          }

          <div className="page_section top_margin">
            <h1 onClick={() => this.goToSummary()}>{this.api.trTxt(TrVar.YourMeasurements)}</h1>
            {
              this.state.measures.length > 1 &&
              <h4 className="top_margin">
                {improveText}
              </h4>
            }

            {
              increasedP < 0 &&
                <Text color="gray" className="top_margin text_left">
                 {this.api.trTxt(TrVar.ItIsCompletelyNaturalWithSetbacks)}      
                </Text>
            }
            <div className="top_margin">
              {this.showMeasures()}
            </div>
            <div className="top_margin">
              {
                this.state.sumAreas.map((area, index) => {
                  let buttonClass = "white"
                  if (this.state.sumArea === index || this.state.compareAll) {
                    buttonClass = "area" + index
                  }
                  return (
                    <div key={"sum_area_" + index}
                      className={"button inline_block top_margin right_margin " + buttonClass}
                      onClick={() => this.chooseArea(index)}>
                      {area === this.api.trTxt(TrVar.TotalPotential).toLowerCase() && this.api.trTxt(TrVar.Your) + ' '}
                      {area}
                    </div>
                  )
                })
              }
              <div className="inline_block top_margin cursor-pointer" style={{ height: "50px", verticalAlign: "middle" }}
                onClick={() => this.setState({ compareAll: !this.state.compareAll })}>
                <div className="flex items-center ml-2">
                  <div data-cy="checkbox-all" className={"check_box " + (this.state.compareAll ? "bg_blue" : "")} />
                  <p className="ml-2">{this.api.trTxt(TrVar.Compare)}</p>
                </div>
              </div>
            </div>

            {
              this.state.sumArea > -1 &&
              this.showMeasureSummary()
            }
            {
              this.props.onboarding &&
              <div className="top_margin text_center">
                <div className="button green mobile_size_btn inline_block" onClick={() => this.onboardProgress(4)}>
                  {this.api.trTxt(TrVar.GoForward)}
                </div>
              </div>
            }
          </div>

          {
            (!this.props.onboarding && !this.props.coachId && this.state.measures.length > 0) &&
            <div className="page_section top_margin">
              <h2>
                {this.api.trTxt(TrVar.YourPreviousMeasurements)}:
              </h2>
              {
                this.state.measures.map((measure, index) => {
                  return (
                    <div className="top_margin r-flex justify-between items-center border_box"
                      key={"measure" + measure.id}>
                      <div style={{ width: '250px' }}>{this.api.trTxt(TrVar.Measurement)} {this.state.measures.length - index}{this.state.measures.length - index === 1 && ': ' + this.api.trTxt(TrVar.StartState)}</div>
                      <div className="flex r_top_margin items-center">
                        <img alt="calendar" className="icon_small svg_blue right_margin" src="/assets/icon/calendar.svg" />
                        {formatDate(measure.createdAt, false, this.api.lang)}
                        <img alt="time" className="icon_small left_margin right_margin" src="/assets/icon/blue/clock.svg" />
                        {formatHourMinute(measure.createdAt)}
                      </div>

                      <div className="flex r_left_margin items-center">

                        {(this.state.measures.length - index === 1) ?
                          <>
                            <div className="button r_top_margin inline_block" onClick={() => changeUrl('/app/client/measure/result/' + measure.id)}>
                              {this.api.trTxt(TrVar.Result)}
                            </div>
                            <p className="underline cursor-pointer left_margin" onClick={() => changeUrl('/app/client/measure/analysis/' + measure.id)}>
                              {this.api.trTxt(TrVar.Edit)}
                            </p>
                          </>
                          :
                          <div style={{ marginRight: '70px' }} className="button r_top_margin inline_block" onClick={() => changeUrl('/app/client/measure/result/' + measure.id)}>
                            {this.api.trTxt(TrVar.Result)}
                          </div>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
      )
    }      
  }
}
