import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from '../../services/Navigation';
import Breadcrumbs from "../menu/Breadcrumbs";
import {CONFIG} from "../../constants";
import {IonToggle,} from '@ionic/react'
import {PackageInterface, PackageType} from "../../interfaces/Package";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  who: string
  package?: PackageInterface
}

interface ComponentState {
  renderNr: number
}

export default class Account extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()
  private hideCoachLinks = (this.props.package?.type === PackageType.FREE ||
    this.props.package?.type === PackageType.ACCESS_PRIVATE)

  state = {
    renderNr: 0
  }


  goTo = (url: string) => {
    if (this.props.who === 'client') {
      changeUrl('/app/client/' + url)
    } else {
      changeUrl('/app/coach/' + url)
    }
  }

  changeLanguage = () => {
    const lang = this.api.lang === 'sv' ? 'en' : 'sv'
    const url = this.props.who === 'coach' ? 'coach_save2' : 'client_save2'

    this.api.post(url, {i18n: {systemLanguage: lang}}).then(_response => {
      this.api.setLanguage(lang)
      this.setState({renderNr: this.state.renderNr + 1})
      // window.location.reload()
    }).catch(response => {
      alert("Error: could not set language")
    })
  };

  resetCoachSelection = () => {
    if (window.confirm('Are your sure you want to remove your coach?')) {
      this.api.post('reset_coach_select', {}).then(_result => {
        alert('Removed your coach. You can now select coach again')
      })
    }
  }

  restartOnboarding = () => {
    const data = {
      haveSeenDevelopmentAreasIntro: false,
    }

    this.api.post('ui_settings', data)
      .then(_response => {
        window.location.href = '/app/client_onboarding?page=restart'
      })
  }

  render() {
    return (
      <>
        <Breadcrumbs name={this.api.trTxt(TrVar.MyAccount)} />
          <div className="page_content">
            <div className="page_section">
            <h2>{this.api.trTxt(TrVar.MyAccount)}</h2>
            <div className="page_bar" />
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-4">

                <div className="section_button cursor-pointer flex" onClick={() => this.goTo('my_profile')}>
                  <img className="my-auto svg_blue left_margin" height={29} src="/assets/icon/profile.svg" alt="profile img" />
                  <p className='my-auto left_margin'>
                    {
                      this.props.who === 'client' ? this.api.trTxt(TrVar.YourProfile) : this.api.trTxt(TrVar.MyCoachSettings)
                    }
                  </p>
                </div>

                {
                  (this.props.who === 'client' && !this.hideCoachLinks) &&
                    <div className="section_button cursor-pointer flex"
                         onClick={() => this.goTo('development_areas')}>
                      <img className="my-auto svg_blue left_margin" height={29} src="/assets/icon/diagram.svg"
                           alt="diagram img" />
                      <p className="my-auto left_margin">{this.api.trTxt(TrVar.CoachAreas)}</p>
                    </div>
                }

                {
                  this.props.who === 'coach' &&
                  <div className="section_button cursor-pointer flex" onClick={() => this.goTo('about_me')}>
                      <img className="my-auto svg_blue left_margin" height={29} src="/assets/icon/profile.svg" alt="profile img" />
                      <p className="my-auto left_margin">{this.api.trTxt(TrVar.MyCoachProfile)}</p>
                    </div>
                }

                {
                  this.props.who === 'client' ?
                    <>
                      <div className="section_button cursor-pointer flex" onClick={() => this.goTo('my_license')}>
                        <img className="my-auto left_margin" height={29} src="/assets/icon/blue/license.svg" alt="license img" />
                        <p className="my-auto left_margin">{this.api.trTxt(TrVar.YourLicense)}</p>
                      </div>
                      {
                        (this.props.who === 'client' && !this.hideCoachLinks) &&
                          <div className="section_button cursor-pointer flex" onClick={() => this.goTo('my_coach')}>
                              <img className="my-auto left_margin svg_blue" height={29} src="/assets/icon/coach.svg" alt="coach img" />
                              <p className="my-auto left_margin">{this.api.trTxt(TrVar.YourCoach)}</p>
                          </div>
                      }
                    </>
                    :
                    <div className="section_button cursor-pointer flex" onClick={() => this.goTo('my_account/agreement')}>
                      <img className="my-auto svg_blue left_margin" height={29} src="/assets/icon/coach.svg" alt="coach img" />
                      <p className="my-auto left_margin">{this.api.trTxt(TrVar.CoachAgreement)}</p>
                    </div>
                }

            </div>
            <div className="top_margin">
              <div>{this.api.trTxt(TrVar.ChooseLanguage)}</div>
              <div className="flex">
                <div className='right_margin align-center'>Svenska</div>
                <div style={{marginTop: '7px'}} className='align-center'>
                <label className="relative inline-flex items-center mb-2 cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" onChange={this.changeLanguage} checked={this.api.lang === 'sv' ? false : true} />
                  <div 
                  className="
                  w-9 h-3.5
                  peer-focus:outline-none 
                  bg-white
                  rounded-full 
                  peer  
                  peer-checked:after:translate-x-[calc(100%-4px)]
                  after:content-[''] 
                  after:absolute 
                  after:top-[-3px] 
                  after:bg-primary-500  
                  after:rounded-full 
                  after:h-5 
                  after:w-5 
                  after:transition-all  
                  peer-checked:bg-white"></div>
                </label>
                </div>
                <div className='left_margin align-center'>English</div>
              </div>
            </div>
          </div>
          {
            (CONFIG.SYSTEM !== 'prod' && this.props.who === 'client') &&
            <div className="flex top_margin">
              <div className="button white inline_block"
                  onClick={() => this.restartOnboarding()}>
                  DEMO: restart onboarding
              </div>

              <div className="button white inline_block ml-8"
                   onClick={() => this.resetCoachSelection()}>
                  DEMO: remove your coach selection
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}
