import React from 'react'
import {CoachInterface, DescriptionInterface} from "../../interfaces/Coach";
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';
import Text from '../Text/Text';

interface ComponentProps{
  coach: CoachInterface,
  description: DescriptionInterface,
  selectCoach?: Function
}

interface ComponentState {
  showFull: boolean
}

export default class ShowSelectCoach extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    showFull: false
  }

  selectCoach = (coach: CoachInterface) => {
    if (this.props.selectCoach) {
      this.props.selectCoach(coach)
    }
  }

  showReadMore = () => {
    const d = this.props.description
    if (d.coachingExpertise || d.workExperience || d.philosophy) {
      return true
    }
    return false
  }

  render() {
    const readMore = this.showReadMore()
    return (
      <>
        <div className="flex section_direction">
          <img alt="coach" src={this.props.coach.picture} className="coach_picture"/>
          <div className="r_left_margin">
            <div className="flex items-center bottom_margin r_top_margin">
              <h2>{this.props.coach.firstName} {this.props.coach.lastName}</h2>
              {/*<div className="ml-4 disa_score">{this.props.coach.score}p</div>*/}
            </div>

            {
              this.props.coach.certificateName &&
                <Text className="my-2"><b>{this.props.coach.certificateName}</b></Text>
            }

            {
              this.props.coach.areas.map((area, index) => {
                return (
                  <Text key={"area_" + index} className="inline_block">
                    {
                      index > 0 &&
                        <span className="mx-2">|</span>
                    }
                    {area}
                  </Text>
                )
              })
            }
            <div className="hide_small top_margin whitespace-pre-line">
              <Text color="gray">{this.props.description.description}</Text>
              {
                readMore &&
                <div className="blue_sky double_top_margin cursor-pointer underline" onClick={() => this.setState({showFull: !this.state.showFull})}>
                  {this.api.trTxt(TrVar.ReadMore)}...
                </div>
              }

              {
                this.props.selectCoach &&
                <div className="btn_top_margin button green mobile_size_btn inline_block" onClick={() => this.selectCoach(this.props.coach)}>
                  {this.api.trTxt(TrVar.SelectCoach)}
                </div>
              }

            </div>

          </div>
        </div>
        <div className="hide_large top_margin whitespace-pre-line">
          <p>{this.props.description.description}</p>
          {
            readMore &&
            <div className="double_top_margin cursor-pointer underline" onClick={() => this.setState({showFull: !this.state.showFull})}>
              {this.api.trTxt(TrVar.ReadMore)}...
            </div>
          }
          {
            this.props.selectCoach &&
            <div className="btn_top_margin button green mobile_size_btn inline_block" onClick={() => this.selectCoach(this.props.coach)}>
              {this.api.trTxt(TrVar.SelectCoach)}
            </div>
          }
        </div>
        {
        this.state.showFull &&
        <div className="top_margin flex">
          
          <div>
            <h4 className="top_margin">{this.api.trTxt(TrVar.Philosophy)}</h4>
            <Text color="gray" className="mt-2 whitespace-pre-line">
              {this.props.description.philosophy}
            </Text>

            <h4 className="top_margin">{this.api.trTxt(TrVar.CoachLanguages)}</h4>
            <Text color="gray" className="mt-2">
              {
                this.props.coach.languages.map((language, index) => {
                  return (
                    <span key={"language_" + index}>{language}&nbsp;</span>
                  )
                })
              }
            </Text>
            <h4 className="top_margin ">{this.api.trTxt(TrVar.WorkExperience)}</h4>
            <Text color="gray" className="mt-2 whitespace-pre-line">
              {this.props.description.workExperience}
            </Text>
            <h4 className="top_margin">{this.api.trTxt(TrVar.CoachExpertise)}</h4>
            <Text color="gray" className="mt-2 whitespace-pre-line">
              {this.props.description.coachingExpertise}
            </Text>
          </div>
        </div>
        }
      </>

    )
  }
}
