import {
  IonContent, IonPage, IonRange,
} from '@ionic/react';
import React from 'react';
import Api from "../../../services/Api";
import {BehaviouralQuestion} from "../../../interfaces/BehaviouralQuestion";
import BarChart from "../../../components/graphics/BarChart";
import {DisaInterface} from "../../../interfaces/Disa";
import DisaRomb from "../../../components/graphics/DisaRomb";
import {
  goodAt,
  hintPrimary, hintSecondary,
  toFeelGood,
  youAre,
  youCreate,
  youNeed,
  youSeek,
  youWant
} from "../../../components/texts/behaviour";
import {InputInterface, InputsInterface} from "../../../components/form/interfaces";
import Input from "../../../components/form/Input";
import {checkInputErrors} from "../../../components/form/CheckInput";
import { TrVar } from '../../../services/translate';

interface ComponentProps {}

interface ComponentState {
  page: string
  inputs: InputsInterface
  questions: Array<BehaviouralQuestion>
  runCalculating: boolean
  disa: Array<DisaInterface>
  disaTexts: Array<string>
  loaded: boolean
}

export default class OpenBehaviour extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()
  private scrollRef: any

  inputs: InputsInterface = {
    email: {
      type: 'text',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    company: {
      type: 'text',
      name: 'company',
      placeholder: this.api.trTxt(TrVar.Company),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1
    },
    title: {
      type: 'text',
      name: 'title',
      placeholder: 'Titel',
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1
    },
  }

  constructor(props: ComponentProps) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      page: '',
      inputs: this.inputs,
      runCalculating: false,
      questions: [],
      disa: [],
      disaTexts: [],
      loaded: false
    }
  }

  componentDidMount() {
    this.api.get('open/disa').then(response => {
      this.setState({ questions: response.json.questions, loaded: true })
    })
  }

  private scrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.getScrollElement().then((el: HTMLElement) => {
        this.scrollRef.current.scrollToTop();
      });
    }
  };

  private changeQuestion = (value: number, index: number) => {
    const questions = this.state.questions as Array<BehaviouralQuestion>;
    questions[index].value = value;
    this.setState({questions: questions});
  }

  private save = () => {
    setTimeout(() =>  this.setState({ runCalculating: true }), 250)
    const data = {
      questions: this.state.questions
    }

    this.api.post('open/disa', data).then(_response => {
      this.setState({disa: _response.json.disa, disaTexts: _response.json.disaTexts})
      setTimeout(this.postSave, 3000)
    })
  }

  private postSave = () => {
    this.setState({ runCalculating: false, page: 'analysis'}, () => this.scrollToTop())
  }

  inputChange = (input: InputInterface) => {
    this.inputs[input.name] = input
    this.setState({ inputs: this.inputs })
  }

  saveEmail = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    if (errors > 0) {
      alert('Det saknas information i formuläret :-)')
    } else {
      const data = {
        type: 'interest',
        from: 'Beteendestilstestet',
        email: this.inputs.email.value,
        company: this.inputs.company.value,
        title: this.inputs.title.value
      }

      this.api.post('feedback', data).then(_response => {
        this.setState({page: 'answer'})
      })
    }
  }

  showContent = () => {
    if (!this.state.loaded) {
      return (<>Loading...</>)
    }
    if (this.state.page === 'answer') {
      return this.showAnswer()
    } else if (this.state.page === 'analysis') {
      return this.showAnalysis()
    } else {
      return this.showWelcome()
    }
  }

  showWelcome = () => {
    return (
      <div className="recommendations_container bottom_margin pink_purple color_black">
        <h2>Välkommen!</h2>

        <p className="top_margin">
          Innan du gör beteendeanalysen vill vi gärna veta lite om dig:
        </p>

        <div className="top_margin">
          <Input data="email-input" input={this.inputs.email} update={this.inputChange} classes="page_input bg_blue"
                 language={this.api.lang} />
        </div>
        <div className="top_margin">
          <Input data="company-input" input={this.inputs.company} update={this.inputChange} classes="page_input bg_blue"
                 language={this.api.lang} />
        </div>
        <div className="top_margin">
          <Input data="title-input" input={this.inputs.title} update={this.inputChange} classes="page_input bg_blue"
                 language={this.api.lang} />
        </div>

        <div className="button green inline_block mt-4" onClick={() => this.saveEmail()}>
          Nästa
        </div>
      </div>
    )
  }

  showAnswer = () => {
    return (
      <div>
        <div className="page_section">
          <h2>Beteendeanalys</h2>

          <div className="top_margin">
            Nu är det dags att göra din beteendeanalys. Svara på alla påståenden genom att dra punkten mot
            det svar som stämmer bäst överens med dig.
          </div>

          <div className="top_margin">
            Beskriv dig själv som du brukar vara nu, inte som du vill vara i framtiden. Ditt spontana svar är
            vanligtvis det mest exakta.
          </div>
        </div>

        {
          this.state.questions.map((question: BehaviouralQuestion, index: number) => {
            const k = 'question_' + index;
            return (
              <div key={k} className='page_section top_margin'>
                <p style={{ fontWeight: '400' }} >{index + 1}. {question.name}</p>
                <div className="flex top_margin">
                  <div className="font_small my-auto right_margin hide_small">{question.left}</div>
                  <IonRange min={0} max={100} pin={true} style={{ padding: "0px"}}
                            className="behavior_analysis_slider" value={question.value}
                            onIonChange={e => this.changeQuestion(e.detail.value as number, index)}/>
                  <div className="font_small my-auto left_margin hide_small">{question.right}</div>
                </div>
                <div className="show_small">
                  <div className="flex justify-between">
                    <div className="my-auto font_small">{question.left}</div>
                    <div className="my-auto font_small">{question.right}</div>
                  </div>
                </div>
              </div>
            )
          })
        }

        {this.state.runCalculating &&
          <div className='onboarding_modal'>
            <div className="onboarding_modal_content">
              <div className='text_center' style={{ maxWidth: '510px', margin: '0 auto' }}>
                <BarChart value={100} size="transition_linear_width_2s" showValue={true}/>
                <div className='top_margin'>
                 Beräknar beteendeprofil...
                </div>
              </div>
            </div>
          </div>
        }

        <div className="top_margin text_center pb-8">
          <div className="button green inline_block mt-4" onClick={() => this.save()}>
            Spara
          </div>
        </div>
      </div>
    )
  }

  showAnalysis = () => {
    const primaryProp: DisaInterface = this.state.disa[0]
    const secondaryProp: DisaInterface = this.state.disa[1]
    const primaryKey: string = primaryProp.key
    const secondaryKey = secondaryProp.key

    return (
      <div>
        <div className="page_section page_section_gradient">
          <h1>{this.api.lang === 'sv' ? 'Ditt analysresultat' : 'Your analysis result'}</h1>
          <div className="page_bar" />
          <div className="col-1_2">
          {
            this.state.disa.map((d: DisaInterface, i) => {
              return (
                <div key={"disa_" + i} className="top_margin flex">
                  <div>
                    <img alt="style" className="icon_large mt-2 svg_white" src={"/assets/icon/behaviour_" + d.key + ".svg"}/>
                  </div>
                  <div className="left_margin">
                    <h4>{d.name}</h4>
                    <h2 className="color_cerise">{d.value.toFixed(0)}%</h2>
                  </div>
                </div>
              )
            })
          }
          </div>
          <div className="col-1_2">
            <div style={{maxWidth: "600px"}}>
              <DisaRomb disa={this.state.disa} color="cerise"/>
            </div>
          </div>
        </div>

        <div className="page_section top_margin">
          <div className="col-1_2">
              <h2 className="bold">{this.api.trTxt(TrVar.AboutYou)}</h2>
              <p className="top_margin">{this.api.trTxt(TrVar.YouAre)}:</p>
            {
              youAre[primaryKey][this.api.lang].map((prop: string, i: number) => {
                return (
                  <div key={"your_are_" + i}
                       className="pa_oval_button uppercase pa_gradient_cerise inline_block top_margin right_margin">
                    {prop}
                  </div>
                )
              })
            }

              <p className="top_margin">{this.api.trTxt(TrVar.YouAreAlso)}:</p>
            {
              youAre[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                return (
                  <div key={"your_are_" + i}
                       className="pa_oval_button uppercase pa_gradient_cerise inline_block top_margin right_margin">
                    {prop}
                  </div>
                )
              })
            }
          </div>

          <div className="col-1_2 r_top_margin_early_break">
              <div className="pa_circle_box r_left_margin">
                  <div className="pa_top pa_outer_circle pa_gradient_cerise_45">
                      <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                              <h4>{this.api.trTxt(TrVar.YouSeek)}:</h4>
                              <div dangerouslySetInnerHTML={{__html: youSeek[primaryKey][this.api.lang]}}/>
                          </div>
                      </div>
                  </div>

                  <div className="pa_left pa_outer_circle pa_gradient_cerise_45">
                      <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                              <h4>{this.api.trTxt(TrVar.YouWant)}:</h4>
                              <div dangerouslySetInnerHTML={{__html: youWant[primaryKey][this.api.lang]}}/>
                          </div>
                      </div>
                  </div>

                  <div className="pa_right pa_outer_circle pa_gradient_cerise_45">
                      <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                              <h4>{this.api.trTxt(TrVar.YouNeed)}:</h4>
                              <div dangerouslySetInnerHTML={{__html: youNeed[primaryKey][this.api.lang]}}/>
                          </div>
                      </div>
                  </div>

                  <div className="pa_bottom pa_outer_circle pa_gradient_cerise_45">
                      <div className="pa_inner_circle flex items-center text_center bg_dark_blue">
                          <div className="w-full">
                              <h4>{this.api.trTxt(TrVar.YouCreate)}:</h4>
                              <div dangerouslySetInnerHTML={{__html: youCreate[primaryKey][this.api.lang]}}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="col-1_2 top_margin">
              <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/brain.svg"/>
              <h2 className="top_margin bold">{this.api.trTxt(TrVar.Strengths)}:</h2>
              <h3 className="top_margin">{this.api.trTxt(TrVar.YouAreEspeciallyGoodAt)}:</h3>
              <ul>
                {
                  goodAt[primaryKey][this.api.lang].map((prop: string, i: number) => {
                    return (
                      <li key={"good_at_" + i}>
                        {prop}
                      </li>
                    )
                  })
                }
              </ul>

              <h3 className="top_margin">{this.api.trTxt(TrVar.YouAreAlsoGoodAt)}:</h3>
              <ul>
                {
                  goodAt[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                    return (
                      <li key={"also_good_at_" + i}>
                        {prop}
                      </li>
                    )
                  })
                }
              </ul>
          </div>

          <div className="col-1_2 top_margin">
              <img alt="style" className="icon_huge svg_cerise" src="/assets/icon/smiley.svg"/>
              <h2 className="top_margin bold">{this.api.trTxt(TrVar.Wellbeing)}:</h2>
              <h3 className="top_margin">{this.api.trTxt(TrVar.YouNeedToFeelGood)}:</h3>
              <ul>
                {
                  toFeelGood[primaryKey][this.api.lang].map((prop: string, i: number) => {
                    return (
                      <li key={"feel_good_" + i}>
                        {prop}
                      </li>
                    )
                  })
                }
              </ul>

              <h3 className="mt-8">{this.api.trTxt(TrVar.YouAlsoNeed)}:</h3>
              <ul>
                {
                  toFeelGood[secondaryKey][this.api.lang].map((prop: string, i: number) => {
                    return (
                      <li key={"also_feel_good_" + i}>
                        {prop}
                      </li>
                    )
                  })
                }
              </ul>
          </div>
        </div>

        <div className="page_section top_margin">
            <div className=" col-1_2">
                <h2 className="bold">{this.api.lang === 'sv' ? 'Mer om dig' : 'More about you'}</h2>
                <p className="top_margin">{this.state.disaTexts[0]}</p>
                <p className="top_margin">{this.state.disaTexts[1]}</p>
            </div>
            <div className="col-1_2 r_left_margin r_top_margin">
                <h3>&nbsp;</h3>
                <p className="top_margin">{this.state.disaTexts[2]}</p>
                <p className="top_margin">{this.state.disaTexts[3]}</p>
            </div>
        </div>

        <div className="page_section top_margin" style={{backgroundImage: "linear-gradient(266deg, #94abff 11.3%, #bc80f0 100%"}}>
            <div className="r-flex">
                <div>
                    <img alt="megafone" style={{width: "128px", height: "128px"}} src="/assets/icon/white/megafone.svg"/>
                </div>
                <div className="r_left_margin top_margin">
                    <h2 className="bold">{this.api.trTxt(TrVar.ThinkAbout)}:</h2>
                    <p className="top_margin">
                      {hintPrimary[primaryKey][this.api.lang]}
                    </p>

                    <p className="top_margin">
                      {hintSecondary[secondaryKey][this.api.lang]}
                    </p>
                </div>
            </div>
        </div>

        <div className="top_margin pb-8 text_center">
          <div className="button green inline_block top_margin"
               onClick={() => this.setState({page: 'answer'}, () => this.scrollToTop())}>
            Gör om frågorna
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <IonPage>
        <IonContent scrollEvents={true} ref={this.scrollRef}>
          <div style={{position: 'absolute', top: '28px', left: '16px', width: '120px'}}>
            <img alt="logo" src="/assets/icon/green/logo_text.svg"/>
          </div>
          <div className="page_content">
            {this.showContent()}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
