import React from 'react'

interface ComponentProps{
  value: number
  size: string
  oneColor?: boolean
  desiredValue?: number
  showValue?: boolean
}

interface ComponentState {
  width: number
  desiredWidth: number
  showValue: number
}

export default class BarChart extends React.Component<ComponentProps, ComponentState>  {

  barRef: any
  valueRef: any
  desiredRef: any;
  // colorMax = '#ca7cf8'
  // colorMin = '#fcc762'
  colorMin = '#feccc8'
  colorMax = '#bf77f6'

  state = {
    width: 0,
    desiredWidth: 0,
    showValue: 0
  }

  constructor(props: ComponentProps) {
    super(props)
    this.barRef = React.createRef()
    this.valueRef = React.createRef()
    this.desiredRef = React.createRef()
  }

  componentDidMount() {
    this.setWidth()
    this.props.desiredValue && this.setDesiredWidth()
  }

  setWidth = () => {
    this.valueRef.current.style.width = '0%'
    setTimeout(() => {
      if (!this.barRef.current) {
        console.log('No bar!')
        return
      }
      const width = this.barRef.current.offsetWidth
      const barWidth = this.props.value
      this.setState({width: width})
      this.valueRef.current.style.width = barWidth + '%'
      if (this.props.showValue) {
        let i = 1
        const interval = setInterval(() => {
          let showValue = (barWidth * i / 100)
          this.setState({showValue: showValue})
          i++
          if (i > 100) {
            clearInterval(interval)
          }
        }, 20)
      }
    }, 500)
  }

  setDesiredWidth = () => {
    this.desiredRef.current.style.width = '0%'
    setTimeout(() => {
      if (!this.barRef.current) {
        console.log('No bar!')
        return
      }
      const width = this.barRef.current.offsetWidth
      const barWidth = this.props.desiredValue
      this.setState({desiredWidth: width})
      this.desiredRef.current.style.width = barWidth + '%'
    }, 500)
  }


  componentDidUpdate(prevProps:ComponentProps, prevState:ComponentState, snapshot:any) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.value !== prevProps.value) {
      this.setWidth()
    }
    if (this.props.desiredValue !== prevProps.desiredValue) {
      this.setDesiredWidth()
    }
  }

  render() {
    let backgroundImage: string
    if (this.props.oneColor) {
      backgroundImage = this.colorMax
    } else {
      backgroundImage = 'linear-gradient(to right, ' + this.colorMin + ', ' + this.colorMax + ' ' + this.state.width + 'px)'
    }

    return (        
      <div ref={this.barRef} className={"relative bar_background " + this.props.size}>
        <div ref={this.valueRef} className={"text_right bar_animated " + this.props.size}
             style={{ background: backgroundImage }}>
          {
            this.props.showValue &&
              <div style={{padding: "4px 4px 0 0"}}>
                {
                  this.state.showValue > 5 &&
                  <>{this.state.showValue.toFixed(0)}%</>
                }
              </div>
          }

        </div>
        {
          this.props.desiredValue &&
            <div ref={this.desiredRef} className={'bar_desired_value ' + this.props.size} />
        }
      </div>
    )
  }
}
