import React from 'react';
import Api from '../../services/Api';

interface ComponentProps {
  save: Function
  goToDev: Function
}

const OnboardingOutro = (props: ComponentProps) => {
  const api = Api.getInstance();

  return (
    <div className='page_section outro_background flex flex-col justify-center text_center'>
      <div>
        <h1>{api.lang === 'sv' ? 'Härligt, du är klar!' : `Awesome, you're done!`}</h1>
        <h2 className='top_margin'>{api.lang === 'sv' ? 'Vad vill du göra?' : `What do you want to do?`}</h2>        
      </div>
      <div className="flex section_direction top_margin">
        <div className="r-half-col">
          <img src={"/assets/illustration/outro_dev_area.png"} alt="" />
          <h3>{api.lang === 'sv' ? 'Kom igång med din utveckling' : `Start your development`}</h3>
          <div style={{ marginTop: '10px' }}>
            {api.lang === 'sv' ?
              'Beräkna dina utvecklingsområden'
              :
              `Calculate your areas of development`
            }
          </div>
          <div onClick={() => props.goToDev()} className="button btn_top_margin green inline_block">{api.lang === 'sv' ? 'Utvecklingsområden' : `Areas of development`}</div>
        </div>
        <div className="r-half-col">
          <img src={"/assets/illustration/outro_dashboard.png"} alt="" />
          <h3>{api.lang === 'sv' ? 'Bekanta dig med plattformen' : `Familiarise yourself with the platform`}</h3>
          <div style={{ marginTop: '10px' }}>
            {api.lang === 'sv' ?
              'Gå vidare till din översikt'
              :
              `Go to your dashboard`
            }
          </div>
          <div onClick={() => props.save()} className="button btn_top_margin black inline_block">{api.lang === 'sv' ? 'Plattform' : `Platform`}</div>
        </div>        
      </div>
    </div>
  )
}

export default OnboardingOutro;
