import {Link} from "react-router-dom"
import React from 'react';
import {displayVolume} from "./sound";
import './sound.css'
import {DeviceInterface} from "../openvideo/interfaces";
import {changeUrl} from "../../services/Navigation";
import Api from "../../services/Api";

interface ComponentProps {
  inPlatform?: boolean
}

interface ComponentState {
  accessStatus: number
  recordingSound: boolean
  recordingVideo: boolean
  audioSrc: string
  audioDevices: Array<DeviceInterface>
  videoDevices: Array<DeviceInterface>
  videoDeviceId: string
  audioDeviceId: string
}

export default class CameraTest extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance()

  state = {
    accessStatus: 0,
    recordingSound: false,
    recordingVideo: false,
    audioSrc: '',
    audioDevices: [],
    videoDevices: [],
    videoDeviceId: 'unset',
    audioDeviceId: 'unset',
  }

  mediaRecorder: any
  videoEl: any
  volumeInterval: any
  videoInterval: any

  componentDidMount() {
    this.testAccess()
    this.setupMediaChange()
  }

  testAccess = () => {
    this.setState({accessStatus: 1})
    const constraints = {
      video: true,
      audio: true
    }
    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
      this.setState({accessStatus: 2}, () => {
        this.startVideo()
      })
    }).catch(err => {
      this.setState({accessStatus: 3})
      console.log(err)
    })
  }

  showAccessStatus = () => {
    if (this.state.accessStatus === 2) {
      return (<></>)
    }
    let txt = 'Status: waiting for test'
    let bgClass = 'bg_dark_blue'
    if (this.state.accessStatus === 1) {
      txt = 'Status: waiting for test to complete...'
    } else if (this.state.accessStatus === 2) {
      txt = 'It works! You have access to your camera and microphone.'
    } else if (this.state.accessStatus === 3) {
      txt = 'Camera access denied.'
      bgClass = 'bg_light_red'
    }
    return (
      <div className={"box_radius_large py-4 px-8 " + bgClass}>
        {txt}
      </div>
    )
  }

  setupMediaChange = () => {
    navigator.mediaDevices.ondevicechange = () => {
      console.log('Changed devices')
      const audioDeviceId = this.state.audioDeviceId
      const videoDeviceId = this.state.videoDeviceId
      this.getInputDevices()
      setTimeout(() => {
        if (audioDeviceId !== this.state.audioDeviceId || videoDeviceId !== this.state.videoDeviceId) {
          this.startVideo()
        }
      }, 200)
    }
  }

  getInputDevices() {
    navigator.mediaDevices.enumerateDevices().then((deviceInfos: Array<any>) => {
      const videoDevices: Array<DeviceInterface> = []
      const audioDevices: Array<DeviceInterface> = []
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const device = deviceInfos[i]
        if (device.kind === 'audioinput') {
          audioDevices.push({
            id: device.deviceId,
            label: device.label,
            groupId: device.groupId
          })
        } else if (device.kind === 'videoinput') {
          videoDevices.push({
            id: device.deviceId,
            label: device.label,
            groupId: device.groupId
          })
        }
      }
      this.setState({
        videoDevices: videoDevices,
        audioDevices: audioDevices,
        videoDeviceId: videoDevices[0].id,
        audioDeviceId: audioDevices[0].id
      })
    })
  }

  changeVideo = (device: DeviceInterface) => {
    if (device.id === this.state.videoDeviceId) {
      console.log('already selected ' + device.id)
      return
    }
    this.setState({videoDeviceId: device.id}, () => this.startVideo())
  }

  changeAudio = (device: DeviceInterface) => {
    if (device.id === this.state.audioDeviceId) {
      console.log('already selected ' + device.id)
      return
    }
    this.setState({audioDeviceId: device.id}, () => this.startVideo())
  }

  setVideoSize = () => {
    const ratio =  this.videoEl.videoHeight / this.videoEl.videoWidth
    const maxWidth = window.innerWidth
    if (maxWidth > (this.videoEl.videoWidth + 32)) {
      this.videoEl.width = this.videoEl.videoWidth
      this.videoEl.height = this.videoEl.videoHeight
    } else {
      this.videoEl.width = window.innerWidth - 32
      this.videoEl.height = this.videoEl.width * ratio
    }

  }

  startVideo = () => {
    const video = this.state.videoDeviceId === 'unset' ? true : {deviceId: {exact: this.state.videoDeviceId}}
    const audio = this.state.audioDeviceId === 'unset' ? true : {deviceId: {exact: this.state.audioDeviceId}}
    const constraints = {
      video: video,
      audio: audio
    }
    if (!this.videoEl) {
      this.videoEl = document.getElementById('videoEl') as HTMLVideoElement
      this.getInputDevices()
    }

    navigator.mediaDevices.getUserMedia(constraints).then((localStream) => {
      // @ts-ignore
      this.videoEl.stream = localStream
      const recordVideoEl = document.getElementById('recordingVideo')
      if (recordVideoEl) {
        displayVolume(localStream, recordVideoEl, this.videoInterval)
      }
      try {
        if (this.videoEl) {
          this.videoEl.muted = true
          this.videoEl.srcObject = localStream
          this.videoEl.onloadedmetadata = () => {
            this.setVideoSize()
            this.setState({recordingVideo: true})
            this.setupAudio()
          }
        }
      } catch (error) {
        alert(error)
      }
    }).catch(err => {
      alert(err)
    })
  }

  toggleRecordSound = () => {
    if (this.state.recordingSound) {
      clearInterval(this.volumeInterval);
      this.mediaRecorder.stop();
      this.setState({recordingSound: false})
    } else {
      this.setState({audioSrc: ''})
      this.mediaRecorder.start();
      this.setState({recordingSound: true})
    }
    console.log("Media recorder state: " + this.mediaRecorder.state)

  }

  setupAudio = () => {

    let chunks: Array<any> = []

    // @ts-ignore
    this.mediaRecorder = new MediaRecorder(this.videoEl.stream);

    this.mediaRecorder.onstop = (e: Event) => {

      const blob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
      chunks = [];
      const audioURL = URL.createObjectURL(blob);
      this.setState({audioSrc: audioURL})

    }

    this.mediaRecorder.ondataavailable = function(e: any) {
      chunks.push(e.data);
    }
  }

  render() {
    return (
      <>
      <div className="flex-1 p-8 color_white">
        {this.showAccessStatus()}

        <div className={this.state.accessStatus === 2 ? "display_block" : "display_none"}>
          <div className="r-flex">
            <div>
              <h4>Microphone</h4>
              {
                this.state.audioDevices.map((audioDevice: DeviceInterface) => {
                  return (
                    <div onClick={() => this.changeAudio(audioDevice)}
                         className="mt-2 cursor-pointer flex items-center"
                         key={"audio_" + audioDevice.id}>
                      <div className="circle_small border_black p-1 mr-2">
                        {
                          audioDevice.id === this.state.audioDeviceId &&
                          <img alt="check" src="/assets/icon/green/checkmark.svg"/>
                        }
                      </div>
                      {audioDevice.label}
                    </div>
                  )
                })
              }
            </div>
            <div className="r_left_margin r_top_margin">
              <h4>Camera</h4>
              {
                this.state.videoDevices.map((videoDevice: DeviceInterface) => {
                  return (
                    <div onClick={() => this.changeVideo(videoDevice)}
                         className="mt-2 cursor-pointer flex items-center"
                         key={"video_" + videoDevice.id}>
                      <div className="circle_small border_black p-1 mr-2">
                        {
                          videoDevice.id === this.state.videoDeviceId &&
                          <img alt="check" src="/assets/icon/green/checkmark.svg"/>
                        }
                      </div>
                      {videoDevice.label}
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className="mt-4">
            <video id="videoEl" autoPlay playsInline controls={false} width="300" height="200"
                   style={{transform: "scale(-1, 1)"}}/>
          </div>
          <div className="mt-4">
            <p className="mt-2">
              If the volume indicator below is all gray you should check your microphone level.
            </p>
          </div>

          <div className="mt-4" id="recordingVideo"/>
          <p className="mt-4">
            If you want to test your microphone and speaker volumes, click "Start recording sound" below.
          </p>

          <button className="mt-4 button" onClick={() => this.toggleRecordSound()}>
            {
              this.state.recordingSound ? "Stop recording sound" : "Start recording sound"
            }
          </button>

          {
            this.state.recordingSound &&
            <div className="mt-4" id="recordingSound">
              Start talking and click stop when you are done.
            </div>
          }
          {
            this.state.audioSrc.length > 0 &&
            <div className="mt-4">
              <audio src={this.state.audioSrc} controls={true}/>
            </div>
          }
        </div>

        {
          this.state.accessStatus === 3 &&
          <>
            {
              this.props.inPlatform ? (
                <p className="mt-4 underline cursor-pointer" onClick={() => changeUrl('/app/help/camera')}>
                  Instructions on how to solve camera access problems
                </p>
              ) : (
                <div className="mt-4">
                  <Link to='/app/help/camera' className="underline">
                    Instructions on how to solve camera access problems
                  </Link>
                </div>
              )
            }

          </>
        }
      </div>
      </>
    );
  }
}
