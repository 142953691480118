import React from 'react'
import { RecommendationInterface } from "../../interfaces/RecommendationInterface";
import {changeUrl} from "../../services/Navigation"
import './Recommendations.css';
import Api from "../../services/Api";
import { InputsInterface, InputInterface } from '../form/interfaces';
import Input from "../../components/form/Input";
import { TrVar } from '../../services/translate';
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";

const ZEBRAIN_RECOMMENDATIONS = "zebrain_recommendations"

interface StoredRecommendation {
  seen: number
  lastSeen: number
  lastClosed: number
}

interface StoredHashInterface {
  [key: string]: StoredRecommendation
}

interface ComponentProps {
  recommendations: RecommendationInterface
  isCoach: boolean
  submitInterest?: Function
}

interface ComponentState {
  recommendation: RecommendationInterface;
  inputs: InputsInterface;
  npsValue: number | null;
  errorMessage: string;
  npsType: string
}

export default class Recommendations extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  timestamp: number = 0
  storedRecommendations: StoredHashInterface = {}

  inputs: InputsInterface = {
    comment: {
      type: 'textarea',
      name: 'comment',
      placeholder: this.api.lang === 'sv' ? 'Skriv gärna en kommentar här...' : `We'd love to hear your comments...`,
      value: '',
      error: '',
      maxLength: 5000,
    },
  }

  state = {
    recommendation: {} as RecommendationInterface,
    inputs: this.inputs,
    npsValue: null,
    errorMessage: '',
    npsType: ''
  }

  componentDidMount() {
    this.timestamp = new Date().getTime()
    this.readRecommendations()
    this.getRecommendation()
  }

  inputChange = (input: InputInterface) => {
    this.inputs[input.name] = input
    this.setState({ inputs: this.inputs })
  }

  readRecommendations = () => {
    const recommendations = window.localStorage[ZEBRAIN_RECOMMENDATIONS]
    if (recommendations) {
      this.storedRecommendations = JSON.parse(recommendations)
    }
  }

  getRecommendation = () => {
    if (this.showRecommendation(this.props.recommendations)) {
      this.logRecommendation(this.props.recommendations.action, false, '')
      let npsType = ''
      if (this.props.recommendations.action === 'nps_general') {
        npsType = 'GENERAL'
      } else if (this.props.recommendations.action === 'nps_coach') {
        npsType = 'COACH'
      }
      this.setState({ recommendation: this.props.recommendations, npsType: npsType})
    }
  }

  logRecommendation = (rType: string, click: boolean, extra: string) => {
    const eventType = click ? LogEventType.COMPONENT_CLICK : LogEventType.COMPONENT_VIEW
    let name = rType
    if (extra.length > 0) {
      name += '_' + extra
    }
    const data: LogEventInterface = {
      eventType: eventType,
      eventData: {name: name}
    }
    const url = this.props.isCoach ? 'coach_log_event' : 'client_log_event'
    this.api.post(url, data).then()
  }

  storeStartRecommendation = (recommendation: string) => {
    this.storedRecommendations[recommendation] = {
      seen: 1,
      lastSeen: this.timestamp,
      lastClosed: 0
    }
  }

  storeRecommendation = () => {
    window.localStorage[ZEBRAIN_RECOMMENDATIONS] = JSON.stringify(this.storedRecommendations)
  }

  closeAndUpdateRecommendation = () => {
    const recommendation = this.state.recommendation
    const recommendObject = this.storedRecommendations[recommendation.action]
    if (recommendObject) {
      recommendObject.lastClosed = new Date().getTime()
      this.storeRecommendation()
    }
    
    this.setState({recommendation: {} as RecommendationInterface})
  }


  showRecommendation = (recommendation: RecommendationInterface)  => {
    let recommendObject = this.storedRecommendations[recommendation.action]

    if (recommendation.action === 'book_more_meetings' || recommendation.action === 'chat_coach') {
      // Show once per 24 hours
      if (!recommendObject) {
        this.storeStartRecommendation(recommendation.action)
        this.storeRecommendation()
        return true
      } else {
        const sinceLastClosed= this.timestamp - recommendObject.lastClosed
        if (sinceLastClosed > 24 * 3600 * 1000) {
          recommendObject.seen += 1
          recommendObject.lastSeen = this.timestamp
          this.storeRecommendation()
          return true
        }
        return false
      }
    } else {
      // Do not store viewing of this recommendation. It will be shown every time it is received from backend
      // This is probably not desired in the long run
      return true
    }
  }

  renderRecommendation = () => {
    if (this.state.recommendation.action === 'nps_general' || this.state.recommendation.action === 'nps_coach') {
      return this.renderNps()
    } else {
      return this.renderStandardRecommendation()
    }
  }

  renderCoachNps = () => {

  }

  chooseNpsValue = (value: number) => {
    this.setState({ npsValue: value, errorMessage: '' });
  }

  postNpsAnswer = () => {
    if (this.state.npsValue === null) {
      this.setState({ errorMessage: (this.api.lang === 'sv' ? 'Du behöver klicka på en siffra för att skicka in ditt svar.' : 'To submit your answer, you need to choose a number.') });
    } else {

      const npsData = {
        'score': this.state.npsValue,
        'comment': this.state.inputs.comment.value,
        'type': this.state.npsType
      }

      this.api.post('submit_nps', npsData)
        .then(response => {
          this.setState({ recommendation: {} as RecommendationInterface })
        }).catch(error => {
          this.setState({ errorMessage: (this.api.lang === 'sv' ? 'Hoppsan, något gick snett. Pröva igen om en stund.' : 'Oops, something went wrong. Try again in a moment.') })
        });
    }
  }

  declineNpsAnwer = () => {
    const npsData = {
      'score': null,
      'comment': '',
      'type': this.state.npsType
    }

    this.api.post('submit_nps', npsData)
      .then(response => {
        this.setState({ recommendation: {} as RecommendationInterface })
      }).catch(error => {
        this.setState({ errorMessage: (this.api.lang === 'sv' ? 'Hoppsan, något gick snett. Pröva igen om en stund.' : 'Oops, something went wrong. Try again in a moment.') })
      });
  }

  renderUpdate = () => {
    return (      
      <div style={{ height: 'calc(100vh - 150px)', maxHeight: '700px' }}>
        {this.api.lang === 'sv' ? 
        <div className='white recommendations_text'>
          <h1>Ladda om sidan</h1>
          <div className="top_margin">
            Vi har gjort en uppdatering. Så ladda om sidan, refresha browsern, uppdatera webbläsaren
            (eller vad du nu brukar kalla det) så blir allt som det ska.
          </div>
          <h4 style={{ marginTop: '50px' }}>Psst. Ett tips på snabbkommandon.</h4>
          <div>
            PC: Tryck Ctrl + F5
          </div>
          <div>Mac: Tryck Cmd + R</div>
        </div>
        :
        <div className='white recommendations_text'>
          <h1>Reload your page</h1>
          <div className="top_margin">
              We have made an update. So refresh your page, reload your browser, update your browser
              (or whatever you call it) and everything will turn out well.
          </div>
          <h4 style={{ marginTop: '50px' }}>Psst. A tip of shortcuts.</h4>
          <div>
            PC: Press Ctrl + F5
          </div>
          <div>Mac: Press Cmd + R</div>
        </div>
        }
      </div>
    )
  }

  renderNps = () => {
    return (
      <>
        <div className="w-full text_right">
          <img alt="close" className="icon_small cursor-pointer" src="/assets/icon/dark_blue/cross.svg"
               onClick={() => {
                 this.declineNpsAnwer()
                 this.logRecommendation('nps', true, 'cross')
               }} />
        </div>        
        <div style={{ color: '#000026', maxWidth: '738px' }} className="recommendations_text relative">
          <h1 className='hide_small'>{this.state.npsType === 'GENERAL' ? 
            this.api.trTxt(TrVar.RecommendationTypeNps)  
            :
            this.api.trTxt(TrVar.RecommendationTypeNpsCoach)  
          }
          </h1>
          <h2 className="show_small">{this.state.npsType === 'GENERAL' ? 
            this.api.trTxt(TrVar.RecommendationTypeNps)  
            :
            this.api.trTxt(TrVar.RecommendationTypeNpsCoach)  
          }
          </h2>
          <div style={{ fontSize: '14px' }} className="double_top_margin flex justify-between">
            <div>{this.api.lang === 'sv' ? 'Inte alls troligt' : 'Not likely at all'}</div>
            <div>{this.api.lang === 'sv' ? 'Högst troligt' : 'Extremely likely'}</div>
          </div>          
          <div className="flex justify-between">
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 0 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(0)}>0</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 1 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(1)}>1</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 2 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(2)}>2</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 3 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(3)}>3</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 4 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(4)}>4</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 5 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(5)}>5</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 6 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(6)}>6</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 7 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(7)}>7</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 8 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(8)}>8</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 9 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(9)}>9</div>
            <div className={"recommendations_nps_circle " + (this.state.npsValue === 10 ? 'chosen' : '')} onClick={() => this.chooseNpsValue(10)}>10</div>
          </div>
          <div className='top_margin'>
            <h4>{this.api.lang === 'sv' ? 'Kommentar' : 'Comment'}</h4>
            <Input data="comment-input" input={this.inputs.comment} update={this.inputChange} classes="page_text_area white medium" language={this.api.lang} />
          </div>
          <div onClick={() => this.postNpsAnswer()} style={{ width: '89px' }} className="top_margin button inline_block">{this.api.trTxt(TrVar.Send)}</div>
          { this.state.errorMessage.length > 0 &&
            <div className="top_margin recommendations_nps_error">{this.state.errorMessage}</div>
          }
        </div>
      </>
    )
  }

  checkAction = (action: string) => {
    if (action === 'book_more_meetings') {
      changeUrl('/app/client/bookings/meeting_times')
    } else if (action === 'reactivate_account') {
        
    } else if (action === 'submit_nps') {
        
    } else if (action === 'add_more_meetings') {
      changeUrl('/app/coach/bookings/meeting_times')
    } else if (action === 'book_more_meetings_alt') {
      changeUrl('/app/client/bookings/meeting_times')
    } else if (action === 'access_upgrade') {
      changeUrl('/app/client/editCoach')
    } else if (action === 'downgrade_to_access_light') {
      changeUrl('/app/client/overview')
    } else if (action === 'downgrade_to_access') {
      changeUrl('/app/client/chat_coach')
    } else if (action === 'lisence_expiring_header') {
      if (this.props.submitInterest) {
        this.props.submitInterest()
      }
    } else if (action === 'try_chat_coach') {
      changeUrl('/app/client/chat_coach')
    } else if (action === 'update_app') {
        
    } else if (action === 'continue_coach') {
      if (this.props.submitInterest) {
        this.props.submitInterest()
        this.setState({recommendation: {} as RecommendationInterface})
      } 
    } else if (action === 'select_coach') {
      changeUrl('/app/client/editCoach')
    }
  }

  renderStandardRecommendation = () => {
    // console.log(this.state.recommendation)
    return (
      <>
        <div className="w-full text_right">
          <img alt="close" className="icon_small cursor-pointer" src="/assets/icon/dark_blue/cross.svg"
               onClick={() => {
                 this.closeAndUpdateRecommendation()
                 this.logRecommendation(this.state.recommendation.action, true, 'cross')
            }} />
        </div>
        <div className='recommendations_text color_black'>
          <h1>{this.state.recommendation.title}</h1>
          <div className="top_margin">
            {this.state.recommendation.text}
          </div>
          {
            this.state.recommendation.cta &&
              <div style={{ marginTop: '50px' }}>
                  <div onClick={() => this.checkAction(this.state.recommendation.action)}
                       className="button green inline_block right_margin">{this.state.recommendation.cta.text}
                  </div>
                {
                  (this.state.recommendation.cta_alt && this.state.recommendation.cta_alt.text.length > 0) &&
                    <div onClick={() => {
                      this.closeAndUpdateRecommendation()
                      this.logRecommendation(this.state.recommendation.action, true, 'skip')
                    }}
                         className="button black inline_block">{this.state.recommendation.cta_alt?.text}</div>
                }
              </div>
          }
          {
            (this.state.recommendation.cta && this.state.recommendation.cta.subtext && this.state.recommendation.cta.subtext.length > 0) &&
            <div className='top_margin'>
              {this.state.recommendation.cta.subtext}
            </div>
          }
        </div>
      </>
    )
  }

  getBackgroundClass = () => {
    if (this.state.recommendation.type === 'update') {
      return "red"
    } else if (this.state.recommendation.type === 'tips') {
      return "blue_pink_cross"
    } else if (this.state.recommendation.type === 'book_more_meetings') {
      return "purple_yellow"
    } else if (this.state.recommendation.type === 'focusarea') {
      return "purple_blue"
    } else if (this.state.recommendation.type === 'measurement') {
      return "purple_green"
    } else if (this.state.recommendation.type === 'checkup') {
      return "blue_pink"
    } else if (this.state.recommendation.type === 'chat_coach') {
      return "pink_purple"
    } else if (this.state.recommendation.type === 'nps_coach') {
      return "pink_green"
    }

    const prio = this.state.recommendation.prio ? this.state.recommendation.prio : ''
    if (prio === 'suggest') {
      return "pink_purple no_height"
    } else if (prio === 'strong') {
      return "red"
    }
    return "pink_purple"
  }

  render() {

    if (!this.state.recommendation.type) {
      return (<></>)
      // return (<>
      //   {this.renderCheckup()}
      // </>)
    }

    const bgClass = this.getBackgroundClass()

    return (
      <div className={"recommendations_container bottom_margin " + bgClass}>
        {this.renderRecommendation()}
      </div>
    )
  }
}
