import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import './Support.css'
import {InputInterface, InputsInterface} from '../form/interfaces'
import Input from "../../components/form/Input";
import Accordion from "../../components/Accordion";
import {checkInput} from '../form/CheckInput'
import ExternalLogging from "../../services/ExternalLogging";
import {CONFIG} from "../../constants";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import { TrVar } from '../../services/translate';
import Text from "../Text/Text";

interface FAQItem {
  title: string;
  content: string;
}

interface ComponentProps{
  free?: boolean
  isCoach?: boolean
  otherFaqList: FAQItem[]; 
  yourClientsFaqList: FAQItem[]; 
  coachFaqList: FAQItem[]; 
}

interface ComponentState {
  inputs: InputsInterface
  showFormError: boolean
  sentSupport: boolean
}

export default class Support extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()
  private externalLogging = ExternalLogging.getInstance()

  inputs: InputsInterface = {
    support: {
      type: 'textarea',
      name: 'support',
      placeholder: this.api.trTxt(TrVar.WriteQuestion),
      value: '',
      error: '',
      maxLength: 5000,
      minLength: 1
    },
  }

  state = {
    inputs: this.inputs,
    showFormError: false,
    sentSupport: false,
  }

  componentDidMount() {
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'support'}
    }
    const url = this.props.isCoach ? 'coach_log_event' : 'client_log_event'
    this.api.post(url, data).then()
  }

  inputChange = (input: InputInterface) => {
    this.inputs[input.name] = input

    const formError = this.checkAllErrors() > 0

    this.setState({inputs: this.inputs, showFormError: formError})
  }

  private checkAllErrors = () => {
    let errors = 0
    for (const name in this.inputs) {
        const input = this.inputs[name]
        if (checkInput(input, 'sv').length > 0) {
          errors += 1
        }
    }
    this.setState({
      showFormError: errors > 0
    })
    return errors
  }
  
  sendSupportMessage =  () => {
    if (this.checkAllErrors() > 0) {
      return
    } 

    const data = {
      type: 'support',
      url: document.location.pathname,
      message: this.inputs.support.value
    }
    
    this.api.post('feedback', data).then(_response => {
      this.setState({ sentSupport: true })
    })
  }

  showQuestion = (id:string) => {
    let text = document.querySelector('#' + id + 'Answer');
    let img = document.querySelector('#' + id + 'Img');
    text?.classList.toggle('display_none');
    img?.classList.toggle('support_rotate_img');
  }
  
  render() {    
    return (
      <>
        <Breadcrumbs name={this.api.trTxt(TrVar.Support)} />
        <div className="page_content">
          <div className="page_section">
            <h1>{this.api.trTxt(TrVar.Faq)}</h1>
            <div className="page_bar" />
            <div className="flex section_direction">
              <div className="page_half_content">
                <h4 className="blue mt-8 text-2xl font-medium">
                  {this.api.trTxt(TrVar.BeingCoachAtZebrain)}
                </h4>
                <div className="mt-7 support_question_section">
                  <Accordion items={this.props.coachFaqList} />
                </div>
                <h4 className="blue mt-20 text-2xl font-medium">
                  {this.api.trTxt(TrVar.YourClients)}
                </h4>
                <div className="mt-7 support_question_section">
                  <Accordion items={this.props.yourClientsFaqList} />
                </div>
                <h4 className="blue mt-20 text-2xl font-medium">
                  {this.api.trTxt(TrVar.Other)}
                </h4>
                <div className="mt-7 support_question_section">
                  <Accordion items={this.props.otherFaqList} />
                </div>
              </div>
              
              <div className="page_half_content r_left_margin">
                {/* <img className='hide_medium' src="/assets/icon/placeholder.svg" alt="" /> */}
              </div>
            </div>
          </div>
      
          <div className="page_section top_margin">
            <h2>{this.api.trTxt(TrVar.ContactUs)}</h2>
            <h4 className="blue support_sub_title">{this.api.trTxt(TrVar.FindUs)}</h4>
            <div>
              <h4>{this.api.trTxt(TrVar.Address)}</h4>
              <Text color="gray">Birger Jarlsgatan 34</Text>
              <Text color="gray">114 29 Stockholm</Text>
            </div>
            <div>
              <h4 className='top_margin'>{this.api.trTxt(TrVar.Phone)}</h4>
              <Text color="gray"><a className='support_black_link' href="tel:+46768936265">0768-93 62 65</a></Text>
            </div>
            <div>
              <h4 className='top_margin'>{this.api.trTxt(TrVar.Email)}</h4>
              <Text color="gray"><a className='support_black_link' href="mailto:support@zebrain.se">support@zebrain.se</a></Text>
            </div>
          </div>
          {
            CONFIG.SYSTEM === "develop" &&
            <div className="page_section top_margin">
              <Text className="cursor-pointer">
                <span onClick={() => this.externalLogging.enable()}>Enable logging</span>
              </Text>
              <Text className="mt-4 cursor-pointer" >
                <span onClick={() => this.externalLogging.disable()}>Disable logging</span>
              </Text>
              <Text className="mt-4 cursor-pointer">
                <span onClick={() => this.externalLogging.simulatePageChange()}>Simulate page change</span>
              </Text>
              <Text className="mt-4 cursor-pointer">
                <span onClick={() => this.externalLogging.checkStatus()}>console.log status</span>
              </Text>
            </div>
          }

        </div>
      </>
    )
  }
}
