import React from 'react'
import Api from "../../services/Api"
import {MeasureArea} from "../../interfaces/MeasureArea";
import {MeasureQuestion} from "../../interfaces/MeasureQuestion";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import {formatSoon} from "../../helpers/dateFunctions";
import { TrVar } from "../../services/translate";

interface MeasureInterface {
  id: number
  version: number
  createdAt: string
  areas: Array<MeasureArea>
  totalCurrent: number
  totalDesired: number
}

interface ComponentProps{
  measure: MeasureInterface
  measurePrev?: MeasureInterface
}

interface ComponentState {
  summary: string
  sumAreas: Array<string>
  sortHighest: boolean
  showHelp: boolean
}


export default class MeasureTable extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()


  state: Readonly<ComponentState> = {
    summary: this.props.measurePrev ? 'change' : 'gap',
    sortHighest: true,
    sumAreas: [
      this.api.trTxt(TrVar.TotalPotential).toLowerCase(),
      this.api.trTxt(TrVar.SelfInsight),
      this.api.trTxt(TrVar.BasicEnergy),
      this.api.trTxt(TrVar.Motivation),
      this.api.trTxt(TrVar.SelfLeadership),
    ],
    showHelp: false
  }

  findFirstQuestion = (area: MeasureArea, id: number) => {
    for (const q of area.questions) {
      if (q.id === id) {
        return q
      }
    }
    return null
  }

  sortQuestions = (q1: MeasureQuestion, q2: MeasureQuestion) => {
    let diff: number
    if (this.state.summary === 'desired') {
      diff = q1.valueDesired - q2.valueDesired
    } else if (this.state.summary === 'gap') {
      diff = (q1.valueDesired - q1.valueCurrent) - (q2.valueDesired - q2.valueCurrent)
    } else if (this.state.summary === 'change') {
      // @ts-ignore
      diff = q1.changeCurrentSinceStart - q2.changeCurrentSinceStart
    } else {
      diff = q1.valueCurrent - q2.valueCurrent
    }

    if (this.state.sortHighest) {
      return -diff
    } else {
      return diff
    }
  }

  changeSort = (s: string) => {
    if (s === this.state.summary) {
      this.setState({sortHighest: !this.state.sortHighest})
    } else {
      this.setState({summary: s})
    }
  }

  showQuestionBar = (width: number, what: string) => {
    let widthStr: string
    let bgColor: string
    if (width > 0) {
      widthStr = width + '%'
      bgColor = what === 'desired' ? 'bg_blue_gray': 'bg_purple'
    } else {
      widthStr = Math.abs(width) + '%'
      bgColor = "bg_red"
    }
    return(
      <div className={bgColor} style={{width: widthStr, height: "10px"}}/>
    )
  }

  render() {
    const questions = []
    for (const area of this.props.measure.areas) {
      let i = 0
      for (const q of area.questions) {
        q.areaNr = area.nr
        q.changeCurrentSinceStart = 0
        if (this.props.measurePrev) {
          const firstQ = this.props.measurePrev.areas[area.nr].questions[i]
          if (firstQ) {
            q.changeCurrentSinceStart = q.valueCurrent - firstQ.valueCurrent
          }
        }
        questions.push(q)
        i++
      }
    }

    return (
      <div className="top_margin">
        <div className="link_text" onClick={() => this.setState({showHelp: !this.state.showHelp})}>
          {this.api.trTxt(TrVar.Help)}
        </div>
        {
          this.state.showHelp &&
          <div className="top_margin">
            <p>
              {this.api.trTxt(TrVar.HereYouCanSortOnMeasuresCurrentState)}
              , {this.props.measurePrev ? '+/-, ' : ''}
                {this.api.trTxt(TrVar.DesiredStateOrGap).toLowerCase()}
            </p>
            <p className="mt-2">
              {this.api.trTxt(TrVar.ClickOnTheColumnsHeaderToSort)}
            </p>
            {
              this.props.measurePrev &&
                <p className="top_margin">
                    +/-
                  {this.api.trTxt(TrVar.ShowsChangeInCurrentStateSincePreviousMeeting).toLowerCase()}
                  :&nbsp;
                  {formatSoon(this.props.measurePrev.createdAt, this.api.lang)}
                </p>
            }
            <div className="top_margin flex">
                <img alt="arrow" className="mr-2 icon_small svg_white align-bottom" src="/assets/icon/arrow_up.svg"/>
                =&nbsp;
              {this.api.trTxt(TrVar.HighestValueOnTop).toLowerCase()}
            </div>
            <div className="mt-2 flex">
                <img alt="arrow" className="mr-2 icon_small svg_white align-bottom" src="/assets/icon/arrow_down.svg"/>
                =&nbsp;
              {this.api.trTxt(TrVar.LowestValueOnTop).toLowerCase()}
            </div>
            <div className="top_margin">
              {this.api.trTxt(TrVar.IfYouDontChooseSortingTheTableIs)}
              &nbsp;
              {
                this.props.measurePrev ?
                  this.api.trTxt(TrVar.ChangeInCurrentState).toLowerCase()
                  :
                  this.api.trTxt(TrVar.HighestGap).toLowerCase()
              }.
            </div>
          </div>
        }

        <div className="top_margin">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="2">
                {this.api.trTxt(TrVar.CurrentState)}: {this.props.measure.totalCurrent.toFixed(1)}
              </IonCol>
              <IonCol size="12" sizeMd="10" className="mt-2">
                {this.showQuestionBar(this.props.measure.totalCurrent, 'current')}
              </IonCol>
            </IonRow>
            {
              this.props.measurePrev &&
                <IonRow>
                  <IonCol size="12" sizeMd="2">
                    {this.api.trTxt(TrVar.Previous)}:&nbsp;
                    {this.props.measurePrev.totalCurrent.toFixed(1)}
                  </IonCol>
                  <IonCol size="12" sizeMd="10" className="mt-2">
                    {this.showQuestionBar(this.props.measurePrev.totalCurrent, 'current')}
                  </IonCol>
                </IonRow>
            }
          </IonGrid>
        </div>

        <div className="mt-4">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="2">
                {this.api.trTxt(TrVar.WantedState)}: &nbsp;
                {this.props.measure.totalDesired.toFixed(1)}
              </IonCol>
              <IonCol size="12" sizeMd="10" className="mt-2">
                {this.showQuestionBar(this.props.measure.totalDesired, 'desired')}
              </IonCol>
            </IonRow>
            {
              this.props.measurePrev &&
                <IonRow>
                    <IonCol size="12" sizeMd="2">
                      {this.api.trTxt(TrVar.Previous)}:&nbsp;
                      {this.props.measurePrev.totalDesired.toFixed(1)}
                    </IonCol>
                    <IonCol size="12" sizeMd="10" className="mt-2">
                      {this.showQuestionBar(this.props.measurePrev.totalDesired, 'desired')}
                    </IonCol>
                </IonRow>
            }
          </IonGrid>
        </div>

        <IonGrid className="top_margin">
          <IonRow>
            <IonCol size="12" sizeMd={this.props.measurePrev ? "8" : "9"}>
              <h3>{this.api.trTxt(TrVar.Statement)}</h3>
            </IonCol>
            <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('current')} className="underline cursor-pointer">
              Nu
              {
                this.state.summary === 'current' &&
                  <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow_"
                    + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
              }
            </IonCol>
            {
              this.props.measurePrev &&
                <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('change')} className="underline cursor-pointer">
                    +/-
                  {
                    this.state.summary === 'change' &&
                      <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow_"
                        + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
                  }
                </IonCol>
            }

            <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('desired')} className="underline cursor-pointer">
              {this.api.trTxt(TrVar.Desired)}
              {
                this.state.summary === 'desired' &&
                  <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow_"
                    + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
              }
            </IonCol>
            <IonCol size="3" sizeMd="1" onClick={() => this.changeSort('gap')} className="underline cursor-pointer">
              Gap
              {
                this.state.summary === 'gap' &&
                  <img alt="arrow" className="ml-1 icon_small svg_white align-bottom" src={"/assets/icon/arrow_"
                    + (this.state.sortHighest ? "up" : "down") + ".svg"}/>
              }
            </IonCol>
          </IonRow>
          {
            questions.sort(this.sortQuestions).map(q => {
              return (
                <IonRow key={"question_" + q.id}>
                  <IonCol size="12" sizeMd={this.props.measurePrev ? "8" : "9"}>
                    <div>{this.state.sumAreas[q.areaNr + 1]}: {q.question}</div>
                    {this.showQuestionBar(q.valueCurrent, 'current')}
                    {this.showQuestionBar(q.valueDesired, 'desired')}
                  </IonCol>
                  <IonCol size="3" sizeMd="1">
                    {q.valueCurrent}
                  </IonCol>
                  {
                    this.props.measurePrev &&
                      <IonCol size="3" sizeMd="1">
                        {q.changeCurrentSinceStart}
                      </IonCol>
                  }
                  <IonCol size="3" sizeMd="1">
                    {q.valueDesired}
                  </IonCol>
                  <IonCol size="3" sizeMd="1">
                    {q.valueDesired - q.valueCurrent}
                  </IonCol>
                </IonRow>
              )
            })
          }
        </IonGrid>
      </div>
    )
  }
}
