import React from "react";
import {IonCol, IonGrid, IonModal, IonRow} from "@ionic/react";
import Api from "../../services/Api";
import {MeetingInterface, MeetingType} from "../../interfaces/Meeting";
import {formatSoon} from "../../helpers/dateFunctions";
import {changeUrl} from "../../services/Navigation";
import {ClientInterface} from "../../interfaces/Client";
import Breadcrumbs from "../menu/Breadcrumbs";
import VisionBoard from "../visionboard/VisionBoard";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import Loading from "../Loading";
import { TrVar } from "../../services/translate";
import { TrArray } from "../../services/translate_arrays";

interface ComponentProps {
  reload: Function
  navigation: any
}

interface ComponentState {
  previousMeetings: Array<MeetingInterface>
  filteredMeetings: Array<MeetingInterface>
  editMeeting: MeetingInterface
  showStatusHelp: boolean
  error: string
  loaded: boolean
  filterClients: Array<ClientInterface>
  filterClient: number
  filterStart: string
  filterEnd: string
}

export default class CoachMeetingHistory extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    previousMeetings: [],
    filteredMeetings: [],
    error: '',
    loaded: false,
    filterClients: [],
    showStatusHelp: false,
    filterClient: 0,
    filterStart: '',
    filterEnd: '',
    editMeeting: {} as MeetingInterface
  }

  componentDidMount() {
    this.getMeetings()
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'coach_meeting_history'}
    }
    this.api.post('coach_log_event', data).then()
  }

  getMeetings = () => {
    this.api.get('coach_meeting_history').then(response => {
      const previousMeetings:Array<MeetingInterface> =  response.json.previousMeetings

      let filterStart = ''
      let filterEnd = ''
      if (previousMeetings.length > 0) {
        filterEnd = previousMeetings[0].start.substring(0, 10)
        filterStart = previousMeetings[(previousMeetings.length - 1)].start.substring(0, 10)
      }

      this.setState({
        previousMeetings: previousMeetings,
        filteredMeetings: previousMeetings,
        filterStart: filterStart,
        filterEnd: filterEnd,
        filterClients: this.getFilterClients(previousMeetings),
        loaded: true
      })
    })
  }

  getFilterClients = (meetings: Array<MeetingInterface>) => {
    const clientIds: Array<number> = []
    const clients = []
    for (const meeting of meetings) {
      if (clientIds.indexOf(meeting.client.id) === -1) {
        clientIds.push(meeting.client.id)
        clients.push(meeting.client)
      }
    }
    return clients
  }

  showMeetingStatus = (meeting: MeetingInterface) => {
    let status = this.api.trArr(TrArray.MeetingType)[meeting.meetingType]
    let colorClass = 'error_red'
    let coachStatus: string
    if (meeting.coachConfirmed) {
      coachStatus = this.api.trTxt(TrVar.Confirmed)
    } else {
      coachStatus = this.api.trTxt(TrVar.NotConfirmed)
    }

    if (meeting.meetingType === MeetingType.BOOKED  || meeting.meetingType === MeetingType.CONFIRMED_OK) {
      colorClass = 'dark_green'
    }

    let confirmedClass = 'error_red'
    if (meeting.coachConfirmed) {
      confirmedClass = 'dark_green'
    }
    return (
      <div>
        <div className={colorClass}>{status}</div>
        <div className={confirmedClass}>{coachStatus}</div>
      </div>

    )
  }

  handleFilterStart = (e: any) => {
    this.setState({filterStart: e.target.value}, () => {
      if (this.state.filterStart.length === 10) {
        this.filter()
      }
    })
  }

  handleFilterEnd = (e: any) => {
    this.setState({filterEnd: e.target.value}, () => {
      if (this.state.filterEnd.length === 10) {
        this.filter()
      }
    })

  }

  setFilterClient = (e: any) => {
    this.setState({filterClient: parseInt(e.target.value)}, () => this.filter())
  }

  filter = () => {
    const meetings: Array<MeetingInterface> = []
    let start: any = null
    let end: any = null
    if (this.state.filterStart.length === 10) {
      start = Date.parse(this.state.filterStart)
    }
    if (this.state.filterEnd.length === 10) {
      end = Date.parse(this.state.filterEnd + ' 23:59:59')
    }

    for (const meet of this.state.previousMeetings) {
      const meetingStart = Date.parse(meet.start)
      if (start && start > meetingStart) {
        continue
      }
      if (end && end < meetingStart) {
        continue
      }
      if (this.state.filterClient > 0 && this.state.filterClient !== meet.client.id) {
        continue
      }
      meetings.push(meet)
    }
    this.setState({filteredMeetings: meetings})
  }

  sumMeetingTime = () => {
    let minutes = 0
    for (const meet of this.state.filteredMeetings) {
      // Count time unless meetingType is 2 (coach unavailable) or 3 (coach treats)
      if (meet.meetingType !== MeetingType.COACH_TREATS && meet.meetingType !== MeetingType.COACH_UNAVAILABLE) {
        minutes += meet.length
      }
    }
    return minutes
  }

  sumBillableMeetings = () => {
    let billableMeetings: { [l: number]: number } = {}
    for (const meet of this.state.filteredMeetings) {
      // Count time unless meetingType is 2 (coach unavailable) or 3 (coach treats)
      if (meet.meetingType !== MeetingType.COACH_TREATS && meet.meetingType !== MeetingType.COACH_UNAVAILABLE) {
        if (billableMeetings[meet.length]) {
          billableMeetings[meet.length]++
        } else {
          billableMeetings[meet.length] = 1
        }
      }
    }
    const meetingStrs = []
    for (const l in billableMeetings) {
      let meetingStr = billableMeetings[l] + ' ' + this.api.trTxt(TrVar.Meetings).toLowerCase() + ' '
      meetingStr += l + ' ' + this.api.trTxt(TrVar.Minutes).toLowerCase()
      meetingStrs.push(meetingStr)
    }
    return (
      <div className="mt-4">
      {
        meetingStrs.map((m: string, index) => {
          return (
            <p className="mt-2" key={"billable_" + index}>{m}</p>
          )
        })
      }
      </div>
    )
  }

  minutesToHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60

    return hours + " " + this.api.trTxt(TrVar.Hours).toLowerCase() + " " + mins + " " + this.api.trTxt(TrVar.Minutes).toLowerCase()
  }

  changeMeetingType = (e: any) => {
    const editMeeting = this.state.editMeeting
    editMeeting.meetingType = parseInt(e.target.value)
    this.setState({editMeeting: editMeeting})
  }

  changeMeetingNotes = (e: any) => {
    const editMeeting = this.state.editMeeting
    editMeeting.notes = e.target.value
    this.setState({editMeeting: editMeeting})
  }

  saveMeeting = () => {
    const data = {
      id: this.state.editMeeting.id,
      notes: this.state.editMeeting.notes,
      meetingType: this.state.editMeeting.meetingType
    }
    this.api.post('coach_edit_meeting', data).then(result => {
      const previousMeetings = this.state.previousMeetings
      for (const meeting of previousMeetings) {
        if (meeting.id === this.state.editMeeting.id) {
          meeting.notes = this.state.editMeeting.notes
          meeting.coachConfirmed = true
          meeting.meetingType = this.state.editMeeting.meetingType
        }
      }
      this.setState({editMeeting: {} as MeetingInterface, previousMeetings: previousMeetings})
    })
  }

  editMeeting = (meeting: MeetingInterface) => {
    const editMeeting: MeetingInterface = Object.assign({}, meeting)
    this.setState({editMeeting: editMeeting})
  }

  renderEditMeeting = () => {
    if (!this.state.editMeeting.id) {
      return (<></>)
    }
    return (
      <div className="p-4 color_white text_center scroll_y">
        <h2>
          {this.api.trTxt(TrVar.ConfirmMeeting)}&nbsp;
          {formatSoon(this.state.editMeeting.start, this.api.lang)}
        </h2>

        <select className="top_margin page_select" value={this.state.editMeeting.meetingType}
                onChange={(e) => this.changeMeetingType(e)}>
          {
            this.api.trArr(TrArray.MeetingType).map((meetingType, value:number) => {
              return (
                <option key={"meeting_type_" + value} value={value}>
                  {this.api.trArr(TrArray.MeetingType)[value]}
                </option>
              )
            })
          }
        </select>

        <div className="top_margin">
          <textarea className="page_text_area small"
                    placeholder={this.api.trTxt(TrVar.LeaveAComment)} style={{ height: "88px"}}
                    value={this.state.editMeeting.notes}
                    onChange={(e) => this.changeMeetingNotes(e)}/>
        </div>


        <div className="top_margin">
          <div className="button green inline_block"
               onClick={() => this.saveMeeting()}>
            {this.api.trTxt(TrVar.Confirm)}
          </div>
          <div className="button red ml-8 inline_block"
               onClick={() => this.setState({editMeeting: {} as MeetingInterface})}>
            {this.api.trTxt(TrVar.Cancel)}
          </div>
        </div>

        <div className="mt-8 mx-auto text_left"  style={{maxWidth: "495px"}}>
          <p className="mt-4">
            {
              this.api.trTxt(TrVar.HereYouCanConfirmThatTheMeeting)
            }
          </p>
          <p className="mt-4 underline cursor-pointer"
             onClick={() => this.setState({showStatusHelp: !this.state.showStatusHelp})}>
            {
              this.api.trTxt(TrVar.AlternativesForStatusOfTheMeeting)
            }:
          </p>
          {
            this.state.showStatusHelp &&
            <>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.BOOKED]}</h3>
                  {
                    this.api.trTxt(TrVar.MeetingWentAccordingToPlan)
                  }
                </div>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.CLIENT_CANCELLED_LATE]}</h3>
                  {
                    this.api.trTxt(TrVar.ClientCancelledLateOrDidNotShow)
                  }
                </div>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.COACH_UNAVAILABLE]}</h3>
                  {
                    this.api.trTxt(TrVar.YouMissedTheMeeting)
                  }
                </div>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.COACH_TREATS]}</h3>
                  {
                    this.api.trTxt(TrVar.YouOfferTheClientAnotherMeeting)
                  }
                </div>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.ZEBRAIN_TREATS]}</h3>
                  {
                    this.api.trTxt(TrVar.TechnicalProblemsTheClientGetsAnotherMeeting)
                  }
                </div>
                <div className="mt-4">
                    <h3>{this.api.trArr(TrArray.MeetingType)[MeetingType.CONFIRMED_OK]}</h3>
                  {
                    this.api.trTxt(TrVar.TheMeetingTookPlaceInSpiteOfProblems)
                  }
                </div>
            </>
          }
        </div>
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return <Loading />
    }
    if (this.props.navigation.sub1 === 'visionboard') {
      const visionBoardId = parseInt(this.props.navigation.sub2)
      return (
        <div className="page_content pt-4 w-full p-h-full bg_lighter_blue">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.PreviousMeetings), link: '/app/coach/meeting_history'}]
          } name={this.api.trTxt(TrVar.MeetingNotes)}/>
          <VisionBoard meetingId={visionBoardId}/>
        </div>
      )
    }
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.PreviousMeetings)}/>
        <IonModal isOpen={this.state.editMeeting.id !== undefined}
                  onDidDismiss={() => this.setState({editMeeting: {} as MeetingInterface})}
                  className="modal_tall">
          {
            this.renderEditMeeting()
          }
        </IonModal>
        <div className="page_section">
          {
            this.state.previousMeetings.length === 0 ? (
                <div className="px-4 px-4 bg_blue">
                  <p className="py-4">
                    {this.api.trTxt(TrVar.YouHaveNotHadAnyMeetingsYet)}
                  </p>
                </div>
            ) : (
              <div>
                <h1>{this.api.trTxt(TrVar.ShowingMeetingsWith)}</h1>
                <div className="r-flex items-center mt-4">
                  <div className="flex items-center">
                    <select style={{'width': '150px'}} className="page_select" onChange={this.setFilterClient} value={this.state.filterClient}>
                      <option value={0}>{this.api.trTxt(TrVar.AllClients)}</option>
                      {
                        this.state.filterClients.map(client => {
                          return (
                            <option key={"filter_client_" + client.id} value={client.id}>
                              {client.firstName} {client.lastName}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="flex items-center r_top_margin r_left_margin">
                    {this.api.trTxt(TrVar.Between).toLowerCase()}
                    <input type="text" value={this.state.filterStart}
                           className="mx-2 page_input" placeholder="YYYY-MM-DD" style={{width: "160px"}}
                           onChange={this.handleFilterStart}/>
                  </div>

                  <div className="r_top_margin flex items-center">
                    {this.api.trTxt(TrVar.And).toLowerCase()}
                    <input type="text" value={this.state.filterEnd}
                           className="mx-2 page_input" placeholder="YYYY-MM-DD" style={{width: "160px"}}
                           onChange={this.handleFilterEnd}/>
                  </div>
                </div>

                <div className="border_box top_margin">
                  <h3>{this.api.trTxt(TrVar.SummaryBillableMeetings)}</h3>
                  {this.sumBillableMeetings()}
                  <p className="mt-2">{this.api.trTxt(TrVar.TotalTime)}:&nbsp;
                    {this.minutesToHours(this.sumMeetingTime())}
                  </p>
                </div>

              </div>
            )
          }

          {
            this.state.filteredMeetings.map((meeting: MeetingInterface) => {
              return (
                <div key={"next_meeting_" + meeting.id} className="border_box top_margin flex items-center justify-between">
                  <img alt="coach" src={meeting.client.picture} className="picture_small round"
                       onClick={() => changeUrl('/app/coach/bookings/meeting/' + meeting.meetingKey)}/>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12" sizeMd="3" className="flex items-center link_text"
                        onClick={() => changeUrl("/app/coach/clients/" + meeting.client.id)}  >
                        <h4>
                          {meeting.client.firstName} {meeting.client.lastName}
                          {meeting.guestName ? ' + ' + meeting.guestName : ''}
                        </h4>
                      </IonCol>
                      <IonCol size="12" sizeMd="3">
                        <div className="flex items-center">
                          <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                          {formatSoon(meeting.start, this.api.lang)}, {meeting.length} min
                        </div>
                      </IonCol>

                      <IonCol size="12" sizeMd="4">
                        <div className="flex cursor-pointer"
                             onClick={() => this.editMeeting(meeting)}>
                          <img alt="edit" className="icon_small right_margin"
                               src="/assets/icon/white/create-outline.svg"/>
                          {this.showMeetingStatus(meeting)}
                        </div>
                      </IonCol>
                      <IonCol size="12" sizeMd="2" className="flex items-center justify-end">
                        {
                          meeting.visionBoardData &&
                          <div className="button white inline_block"
                               onClick={() => changeUrl('/app/coach/meeting_history/visionboard/' + meeting.id)}>
                            {this.api.trTxt(TrVar.MeetingNotes)}
                          </div>
                        }
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
