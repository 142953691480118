import React, { useEffect, useState, useRef } from 'react';
import Api from '../../services/Api';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  save: Function
  firstName: string
}

const EditAboutDone = (props: ComponentProps) => {
  const api = Api.getInstance();
  const [contentWidth, setContentWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Measure the width of the content container
    if (contentRef.current && contentRef.current.offsetWidth) {
      setContentWidth(contentRef.current.offsetWidth || 0 );
    }
  }, [props]); // Re-run when props change

  return (
    <div className="flex flex-col pb-16 grow min-h-[375px] flex-nowrap self-center inline">
      <div id="image-container" className="flex flex-col align-center" style={{ width: contentWidth }}> 
          <img className="object-contain max-w-full h-auto" src={'/assets/animations/check_gradient_fast.svg'} alt="checkmark" />
      </div>
      <div id="content-container" className="text_center flex flex-col align-center"  ref={contentRef}>
        <h1 className="text-ellipsis ">{api.trTxt(TrVar.Welcome)} {props.firstName}!</h1>
        <div style={{ marginBottom: '40px' }} className="top_margin flex-inline shrink">
          {api.lang === 'sv' ? 'Du är redo för nästa steg' : `You're ready for the next step`}.
        </div>
        <div className="button green mobile_size_btn inline_block flex flex-col align-center" onClick={() => props.save()}>
          {api.trTxt(TrVar.Next)}
        </div>
      </div>
    </div>
  )
}

export default EditAboutDone;
