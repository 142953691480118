import React from 'react'
import Api from "../../services/Api"
import {MeasureInterface} from "../../interfaces/MeasureInterface";
import {FocusAreaInterface, FocusMeasureInterface} from "../tasks/interfaces";
import { TrVar } from '../../services/translate';

interface ComponentState {}

interface ComponentProps {
  measures: Array<MeasureInterface>
  plansDone?: number
  focusMeasures?: Array<FocusMeasureInterface>
}

export default class FocusAreaSummary extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  focusAreaDoneMeasureQuestions = (focusArea: FocusAreaInterface) => {
    let done = 0
    for (const potentialQuestion of focusArea.potentialQuestions) {
      let allDone = true
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        if (!coachQuestion.done) {
          allDone = false
          break
        }
      }
      if (allDone && potentialQuestion.coachQuestions.length > 0) {
        done++
      }
    }
    return done
  }

  calculateFocusAreasDone = () => {
    let done = 0
    if (this.props.focusMeasures) {
      const focusMeasure = this.props.focusMeasures[0]
      for (const focusArea of focusMeasure.focusAreas) {
        const potentialQuestionsDone = this.focusAreaDoneMeasureQuestions(focusArea)
        if (potentialQuestionsDone > 0 && potentialQuestionsDone === focusArea.potentialQuestions.length) {
          done++
        }
      }
    }

    return done
  }

  focusAreaDoneCoachQuestions = (focusArea: FocusAreaInterface) => {
    let done = 0
    for (const potentialQuestion of focusArea.potentialQuestions) {
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        if (coachQuestion.done) {
          done++
        }
      }
    }
    return done
  }

  calculateDoneCoachQuestions = () => {
    let done = 0
    if (this.props.focusMeasures) {
      for (const focusArea of this.props.focusMeasures[0].focusAreas) {
        done += this.focusAreaDoneCoachQuestions(focusArea)
      }
    }

    return done
  }

  calculateDoneMeasureQuestions = () => {
    let done = 0
    if (this.props.focusMeasures)
    for (const focusArea of this.props.focusMeasures[0].focusAreas) {
      done += this.focusAreaDoneMeasureQuestions(focusArea)
    }
    return done
  }

  render() {
    if (this.props.measures.length === 0) {
      return (
        <div className="page_section">
          <h3>Not available</h3>
          <p className="top_margin">First measure is not completed yet</p>
        </div>
      )
    }

    const focusAreasDone = this.calculateFocusAreasDone()

    return (
      <div className="page_section min_width half r-half-col client_dashboard_second_box">
        <div className="flex flex-col h-full justify-around ">
          <div className="r-flex ">
            <div className="flex flex-col mt-4 align-middle justify-center items-start" style={{minWidth: "50%"}}>
              <h2>{this.api.trTxt(TrVar.Statistics)}</h2>
              <p className="font_small mt-2">
                {this.api.trTxt(TrVar.YouHaveWorkedThrough)}:
              </p>
              <p className="font_small">
                {focusAreasDone} {this.api.trTxt(TrVar.AreasOfDevelopment).toLowerCase()}
              </p>
              <p className="font_small">
                {this.props.plansDone} {this.api.trTxt(TrVar.Exercises).toLowerCase()}
              </p>
            </div>

            <div className="flex flex-col mt-4 align-middle justify-center">
              <div className="flex-shrink-0 flex flex-row">
                <div>
                  <img src={focusAreasDone === 0 ? '/assets/focusArea/crown_gray.svg' : '/assets/focusArea/crown_green.svg' } height={60} width={60} alt="" />
                </div>
                <div className='flex flex-col left_margin'>
                  <p className="font_smaller">{this.api.trTxt(TrVar.Completed)}</p>
                  <p style={{'marginTop': '-10px'}} className='font_smaller'>
                    {this.api.trTxt(TrVar.AreasOfDevelopment).toLowerCase()}
                  </p>
                  <h2 style={{'marginTop': '-10px'}} className="color_purple">{focusAreasDone}</h2>
                </div>
              </div>

              <div className="flex-shrink-0 flex flex-row top_margin">
                <img src={this.props.plansDone === 0 ? '/assets/focusArea/trophy_gray.svg' : '/assets/focusArea/trophy_green.svg' } height={60} width={60} alt="" />
                <div className="flex flex-col left_margin">
                  <p className="font_smaller">{this.api.trTxt(TrVar.Completed)}</p>
                  <p style={{'marginTop': '-10px'}} className='font_smaller'>
                    {this.api.trTxt(TrVar.Exercises).toLowerCase()}
                  </p>
                  <h2 style={{'marginTop': '-10px'}} className="color_purple">{this.props.plansDone}</h2>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
