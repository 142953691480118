import React from "react";
import { IonModal } from "@ionic/react";
import Api from "../../services/Api";
import {
  formatDate,
  twoDigits,
  formatSoon, weekdayLongFromDate, getMonthLong, dateFormatDate,
} from "../../helpers/dateFunctions";
import {MeetingInterface} from "../../interfaces/Meeting";
import Calendar from "../calendar/Calendar";
import { CalendarEventInterface } from "../../interfaces/CalendarEvent";
import { BlockInterface } from "../../interfaces/BlockInterface";
import { SuggestClientInterface } from "../../interfaces/Client";
import MeetingBlock from "../info/MeetingBlock";
import MeetingSuggestion from "../info/MeetingSuggestion";
import MeetingReschedule from "../info/MeetingReschedule";
import { TrVar } from "../../services/translate";

interface ComponentProps {
  close: Function
  lastBookableDate?: string,
  fromVideo?: boolean
  rescheduleMeetingId?: number
  suggestClient?: SuggestClientInterface
}

interface ComponentState {
  days: Array<any>
  meetings: Array<MeetingInterface>
  events: Array<CalendarEventInterface>
  showHelp: boolean
  error: string
  customLength: number
  customLengths: Array<number>
  loaded: boolean
  blocks: Array<BlockInterface>
  rescheduleEvent: CalendarEventInterface
  showCalendarOptions: boolean
  editBlockState: string
  rescheduleConfirm: boolean  
  date: Date
  suggestClients: Array<SuggestClientInterface>
  suggestClient: SuggestClientInterface
  suggestClientId: number
  hourFrom: number
  minFrom: number
  hourTo: number
  minTo: number
  length: number
  showEvent: CalendarEventInterface
  message: string
  showCancelMeeting: boolean
  showMoveMeeting: boolean   
}

export default class CalendarCoach extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()
  day = new Date()
  today = new Date()
  times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]

  state = {
    events: [] as Array<CalendarEventInterface>,
    days: [],
    meetings: [] as Array<MeetingInterface>,
    blocks: [] as Array<BlockInterface>,
    rescheduleEvent: {} as CalendarEventInterface,
    showHelp: false,
    error: '',
    customLength: 0,
    customLengths: [],
    loaded: false,
    showCalendarOptions: false,
    editBlockState: 'block',
    rescheduleConfirm: false,
    date: new Date(),
    suggestClients: [],
    suggestClient: this.props.suggestClient ? this.props.suggestClient : {} as SuggestClientInterface,
    suggestClientId: this.props.suggestClient ? this.props.suggestClient.id : 0,
    hourFrom: 0,
    minFrom: 0,
    hourTo: 0,
    minTo: 0,
    length: 30,
    showEvent: {} as CalendarEventInterface,
    message: '',
    showCancelMeeting: false,
    showMoveMeeting: false,    
  }

  componentDidMount() {
    this.getEvents()
    if (this.props.suggestClient !== undefined) {
      this.setState({length: this.props.suggestClient.meetingTime})
    }  
  }

  getEvents = () => {
    const url = 'coach_events'
    this.api.get(url).then(response => {
      this.parseData(response.json)
    })
  }

  parseData = (responseJson: any) => {
    const events: Array<CalendarEventInterface> = []

    if (responseJson.blocks) {
      const availableTime = this.api.trTxt(TrVar.Available)
      for (const b of responseJson.blocks as Array<BlockInterface>) {
        b.d_start = new Date(b.start)
        b.d_end = new Date(b.end)
        const length = (b.d_end.getTime() - b.d_start.getTime()) / 60000
        events.push({
          id: b.id,
          d: b.d_start,
          date: b.start,
          name: availableTime,
          class: 'bg_light_gray color_black opacity_05',
          lastName: '',
          length: length,
          url: '',
          type: 'block',
          parentEvent: b,
        })
      }
    }
    let rescheduleEvent = {} as CalendarEventInterface
    let meetingLength = this.state.length
    for (const e of responseJson.events as Array<CalendarEventInterface>) {
      if (this.props.rescheduleMeetingId === e.id) {
        rescheduleEvent = e
        meetingLength = rescheduleEvent.length
      }
      e.d = new Date(e.date)
      if (e.meetingType === 1) {
        e.class = 'bg_red color_black'
      } else if (e.type === 'suggestion') {
        e.class = 'bg_blue color_black'
      } else {
        e.class = 'bg_purple color_black'
      }

      events.push(e)
    }

    this.setState({
      events: events,
      length: meetingLength,
      blocks: responseJson.blocks,
      rescheduleEvent: rescheduleEvent,
      suggestClients: responseJson.suggestClients,
      loaded: true,
    })
  }

  handleMessage = (e: any) => {
    this.setState({message: e.target.value})
  }  

  removeEvent = (eventToDelete: CalendarEventInterface) => {
    const events = [] as Array<CalendarEventInterface>
    for (let event of this.state.events) {
      if (event.id !== eventToDelete.id) {
        events.push(event)
      }
    }
    this.setState({ events: events })
  }  

  cancelEvent = (event: CalendarEventInterface) => {
    const data = {
      cancel_text: this.state.message
    }
    this.api.post('coach_meeting/cancel/' + event.id, data).then(_response => {
      this.closeHandleClick()
      this.removeEvent(event)
    })
  }

  showMeeting = () => {
    let errorMessage = ''
    if (this.state.showEvent.d < new Date()) {
      errorMessage = this.api.trTxt(TrVar.MeetingsWhereTheStartTimeHasPassed)
    } else if (this.state.showEvent.meetingType === 1) {
      errorMessage = this.api.trTxt(TrVar.ClientCancelledLate)
    }
    return (
      <>
        <h4>{this.api.trTxt(TrVar.Meeting)}&nbsp;
          {this.api.trTxt(TrVar.With).toLowerCase()} {this.state.showEvent.name} {this.state.showEvent.lastName}</h4>
        <div className="mt-2">
          <p>{formatSoon(this.state.showEvent.date, this.api.lang)}</p>
          <p className="mt-2">{this.state.showEvent.length} {this.api.trTxt(TrVar.Minutes).toLowerCase()}</p>
        </div>

        {
          (errorMessage.length > 0) ? (
            <div className="mt-8">{errorMessage}</div>
          ) : (
            <div className="mt-8 flex justify-between">
              <h3 className="cursor-pointer underline"
                  onClick={() => this.setState({
                    showCancelMeeting: !this.state.showCancelMeeting,
                    showMoveMeeting: false
                  })}>
                {this.api.trTxt(TrVar.CancelMeeting)}
              </h3>
              <h3 className="cursor-pointer underline"
                  onClick={() => this.setState({
                    showMoveMeeting: !this.state.showMoveMeeting,
                    showCancelMeeting: false
                  })}>
                {this.api.trTxt(TrVar.MoveMeeting)}
              </h3>
            </div>
          )
        }


        {
          this.state.showCancelMeeting &&
          <>
              <textarea className="mt-4 page_text_area small w-full" rows={4}
                        placeholder={this.api.trTxt(TrVar.CancelMeetingExplanation)}
                        value={this.state.message} onChange={this.handleMessage}/>
              <p className="font_smaller mt-2">{this.api.trTxt(TrVar.CoachCancelMeetingWarning)}</p>
              <div className="mt-2 text_center">
                  <div className="button red inline_block"
                       onClick={() => this.cancelEvent(this.state.showEvent)}>{this.api.trTxt(TrVar.CancelMeeting)}</div>
              </div>
          </>
        }

        {
          this.state.showMoveMeeting &&
            <>
              <p className="mt-4">
                {this.api.trTxt(TrVar.ClickOkayBelowThenYouGoToCalendar)
                }
              </p>

                <div className="top_margin text_center">
                  <div className="button green inline_block"
                       onClick={() => this.setState({
                         rescheduleEvent: this.state.showEvent,
                         length: this.state.showEvent.length,
                         showEvent: {} as CalendarEventInterface,
                         showCalendarOptions: false
                       })}>Ok</div>
                </div>
            </>
        }
      </>
    )
  }
  
  selectHelp = () => {
    if (this.state.rescheduleEvent.id) {
      return (<MeetingReschedule close={() => this.setState({showHelp: false})}/>)
    } else if (this.state.suggestClient.id) {
      return (<MeetingSuggestion close={() => this.setState({showHelp: false})}/>)
    } else {
      return (
        <MeetingBlock close={() => this.setState({showHelp: false})}/>
      )
    }
  }

  changeEditBlockState = (e: any) => {
    this.setState({editBlockState: e.target.value})
  }

  getClient = (clientId: number) => {
    for (const client of this.state.suggestClients as Array<SuggestClientInterface>) {
      if (client.id === clientId) {
        return client
      }
    }
    return undefined
  }

  changeSuggestClient = (e: any) => {
    const clientId = parseInt(e.target.value)
    const client = this.getClient(clientId)
    if (client) {
      this.setState({
        suggestClient: client,
        suggestClientId: client.id,
        length: client.meetingTime
      })
    }
  }

  collidingTime = (dateFrom: Date, dateTo: Date) => {
    for (const block of this.state.blocks) {
      if (block.id === this.state.showEvent.id) {
        continue
      }
      if (dateFrom >= block.d_start && dateFrom <= block.d_end) {
        return true
      }
      if (dateTo >= block.d_start && dateTo <= block.d_end) {
        return true
      }
    }
    return false
  }

  tooShortPause = (dateFrom: Date, dateTo: Date) => {
    for (const block of this.state.blocks) {
      if (block.id === this.state.showEvent.id) {
        continue
      }
      const pauseBefore = (dateFrom.getTime() - block.d_end.getTime()) / 1000
      if (pauseBefore >= 0 && pauseBefore < 15 * 60) {
        return true
      }
      const pauseAfter = (block.d_start.getTime() - dateTo.getTime()) / 1000
      if (pauseAfter >= 0 && pauseAfter < 15 * 60) {
        return true
      }
    }
    return false
  }

  blockIsOk = (dateFrom: Date, dateTo: Date) => {
    let error = ''
    const seconds = (dateTo.getTime() - dateFrom.getTime()) / 1000

    if (dateFrom > dateTo) {
      error = this.api.trTxt(TrVar.MeetingsCannotEndBeforeTheyStart)
    } else if (seconds < 1800) {
      error = this.api.trTxt(TrVar.ShortestMeetingTimeIs30Min)
    } else if (this.collidingTime(dateFrom, dateTo)) {
      error = this.api.trTxt(TrVar.MeetingsNoOverlap)
    } else if (this.tooShortPause(dateFrom, dateTo)) {
      error = this.api.trTxt(TrVar.MeetingMinPause)
    } else if (dateTo < new Date()) {
      error = this.api.trTxt(TrVar.ThatTimeHasAlreadyPassed)
    }

    if (error.length > 0) {
      this.setState({error: error})
      return false
    }
    return true
  }

  saveBlock = (remove: boolean) => {
    const url = 'coach_blocks'
    const dateFrom = this.setDate(this.state.date, this.state.hourFrom, this.state.minFrom)
    const dateTo = this.setDate(this.state.date, this.state.hourTo, this.state.minTo)
    if (remove || this.blockIsOk(dateFrom, dateTo)) {
      const data = {
        block_id: this.state.showEvent.id ? this.state.showEvent.id : 0,
        date_from : dateFrom,
        date_to: dateTo,
        remove: remove ? 'y' : 'n'
      }
      this.api.post(url, data).then(response => {
        this.parseData(response.json)
        this.closeHandleClick()
      })
    }
  }

  setDate = (date: Date, hour: number, minute: number) => {
    let d = new Date(date.getTime())
    d.setHours(hour)
    d.setMinutes(minute)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
  }  

  changeLength = (e: any) => {
    this.setState({length: parseInt(e.target.value)})
  }

  suggestMeeting = () => {
    const url = 'coach_meeting_suggestion/add'
    const dateFrom = this.setDate(this.state.date, this.state.hourFrom, this.state.minFrom)
    const data = {
      date_start : dateFrom,
      length: this.state.length,
      clientId: this.state.suggestClient?.id
    }
    this.setState({showCalendarOptions: false, suggestClient: {} as SuggestClientInterface, suggestClientId: 0})
    this.api.post(url, data, ['meeting_time_not_available']).then(response => {
      if (this.props.suggestClient) {
        setTimeout(() => this.props.close(), 300)
      } else {
        this.getEvents()
      }

    })
    .catch(response => {
      if (response.isExpectedError) {
        alert(this.api.trTxt(TrVar.MeetingTimeNotAvailable))
      }
    });
  }

  renderSuggestClientMeeting = () => {
    return (
      <>
        <p className="mt-8 text_center bold">
          {weekdayLongFromDate(this.state.date, this.api.lang)}&nbsp;
          {this.state.date.getDate()}&nbsp;
          {getMonthLong(this.state.date.getMonth(), this.api.lang)}
        </p>
        <div className="flex flex-row flex-nowrap top_margin justify-center align-middle items-center">
          <p>{this.api.trTxt(TrVar.AtTime)}:</p>
          <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.hourFrom}
            onChange={(e) => this.changeHourFrom(e)}>
            {
              this.times.map(t => {
                return (
                  <option key={"h_from_" + t} value={t}>{twoDigits(t)}</option>
                )
              })
            }
          </select>
          :
          <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.minFrom} onChange={(e) => this.changeMinFrom(e)}>
            {
              this.minutes.map(m => {
                return (
                  <option key={"m_from_" + m} value={m}>{twoDigits(m)}</option>
                )
              })
            }
          </select>
        </div>
        <div className="top_margin">
          {this.api.trTxt(TrVar.Length)}: {this.state.length} {this.api.trTxt(TrVar.Minutes).toLowerCase()}
        </div>
        <div className="mt-8 text_center">
          <div className="button inline_block" onClick={() => this.suggestMeeting()}>
            {this.api.trTxt(TrVar.SuggestMeeting)}
          </div>
        </div>
      </>
    )
  }

  changeHourFrom = (e: any) => {
    this.setState({hourFrom: parseInt(e.target.value)})
  }

  changeMinFrom = (e: any) => {
    this.setState({minFrom: parseInt(e.target.value)})
  }

  changeHourTo = (e: any) => {
    this.setState({hourTo: parseInt(e.target.value)})
  }

  changeMinTo = (e: any) => {
    this.setState({minTo: parseInt(e.target.value)})
  }

  showDateHasPassed = () => {
    return (
      <>
        <h3>{this.api.trTxt(TrVar.DateHasPassed)}</h3>
        <p className="top_margin">
          {
            this.api.trTxt(TrVar.YouCannotMakeAnyChanges)}
        </p>
      </>
    )
  }
  
  editBlock = () => {
    if (this.state.showEvent && this.state.showEvent.d) {
      const endDate = new Date(this.state.showEvent.d.getTime() + this.state.showEvent.length * 60000)
      if (endDate < new Date()) {
        return this.showDateHasPassed()
      }
    }
    return (
      <>
        <div className="flex justify-between items-center">
          <select className="page_select" value={this.state.editBlockState} onChange={this.changeEditBlockState}>
            <option value="block">
              {
                this.state.showEvent.id ?
                  this.api.trTxt(TrVar.EditAvailableTime)
                  :
                  this.api.trTxt(TrVar.AddMeetings)
              }
            </option>
            {
              (this.state.suggestClients && this.state.suggestClients.length > 0) &&
              <option value="suggest">{this.api.trTxt(TrVar.SuggestMeeting)}</option>
            }
          </select>
        </div>
        {
          this.state.editBlockState === 'suggest' ? (
            <div className="top_margin">
              <select className="page_select" value={this.state.suggestClientId} onChange={this.changeSuggestClient}>
                <option value="0">{this.api.trTxt(TrVar.ChooseClient)}</option>
                {
                  this.state.suggestClients.map((client: SuggestClientInterface) => {
                    return (
                      <option key={"client_" + client.id} value={client.id}>{client.firstName} {client.lastName}</option>
                    )
                  })
                }
              </select>
              {
                this.state.suggestClient.id &&
                <>
                  {this.renderSuggestClientMeeting()}
                </>
              }
            </div>
          ) : (
            <>
              <p className="mt-8 text_center bold">
                {weekdayLongFromDate(this.state.date, this.api.lang)}&nbsp;
                {this.state.date.getDate()}&nbsp;
                {getMonthLong(this.state.date.getMonth(), this.api.lang)}
              </p>
              <div className="flex flex-row flex-nowrap top_margin justify-center align-middle items-center">
                <div className="grow" style={{ width: "50px" }}>{this.api.trTxt(TrVar.From)}:</div>
                <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.hourFrom}
                  onChange={(e) => this.changeHourFrom(e)}>
                  {
                    this.times.map(t => {
                      return (
                        <option key={"h_from_" + t} value={t}>{twoDigits(t)}</option>
                      )
                    })
                  }
                </select>
                :
                <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.minFrom} onChange={(e) => this.changeMinFrom(e)}>
                  {
                    this.minutes.map(m => {
                      return (
                        <option key={"m_from_" + m} value={m}>{twoDigits(m)}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="flex flex-row flex-nowrap mt-4  justify-center align-middle items-center">
                <div className="grow" style={{ width: "50px" }}>{this.api.trTxt(TrVar.To)}:</div>
                <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.hourTo} onChange={(e) => this.changeHourTo(e)}>
                  {
                    this.times.filter(t => t >= this.state.hourFrom).map(t => {
                      return (
                        <option key={"h_to_" + t} value={t}>{twoDigits(t)}</option>
                      )
                    })
                  }
                </select>
                :
                <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.minTo} onChange={(e) => this.changeMinTo(e)}>
                  {
                    this.minutes.map(m => {
                      return (
                        <option key={"m_to_" + m} value={m}>{twoDigits(m)}</option>
                      )
                    })
                  }
                </select>
              </div>
              {
                this.state.error.length > 0 &&
                <div className="mt-4 text_center">{this.state.error}</div>
              }
              <div className="mt-12 text_center">
                <div className="button" onClick={() => this.saveBlock(false)}>{this.api.trTxt(TrVar.Save)}</div>
              </div>

              {
                this.state.showEvent.id > 0 &&
                <div className="mt-8 text_center underline cursor-pointer" onClick={() => this.saveBlock(true)}>
                  {this.api.trTxt(TrVar.Remove)}
                </div>
              }
            </>
          )
        }
      </>
    )
  }

  rescheduleMeeting = () => {
    // this.setState({length: this.state.rescheduleEvent.length})
        
    return (
      <>
        <h4>{this.api.trTxt(TrVar.Reschedule)} {this.api.trTxt(TrVar.Meeting).toLowerCase()}&nbsp;
          {this.api.trTxt(TrVar.With).toLowerCase()} {this.state.rescheduleEvent.name}
        </h4>
        <div className="top_margin">
          {this.api.trTxt(TrVar.From)}: {formatDate(this.state.rescheduleEvent.date, false, this.api.lang)}
        </div>
        <div className="top_margin">
          {this.api.trTxt(TrVar.NewTime)}: {dateFormatDate(this.state.date, false, this.api.lang)}
        </div>
        <div className="flex flex-row flex-nowrap mt-4 justify-center align-middle items-center">
          <p>{this.api.trTxt(TrVar.AtTime)}:</p>
          <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.hourFrom}
            onChange={(e) => this.changeHourFrom(e)}>
            {
              this.times.map(t => {
                return (
                  <option key={"h_from_" + t} value={t}>{twoDigits(t)}</option>
                )
              })
            }
          </select>
          :
          <select className="mx-2 page_input bg_blue h-10 w-24" value={this.state.minFrom} onChange={(e) => this.changeMinFrom(e)}>
            {
              this.minutes.map(m => {
                return (
                  <option key={"m_from_" + m} value={m}>{twoDigits(m)}</option>
                )
              })
            }
          </select>
        </div>
        <div className="top_margin flex">
          {this.api.trTxt(TrVar.Length)}: {this.state.length} {this.api.trTxt(TrVar.Minutes).toLowerCase()}
        </div>
        <div className="top_margin flex">
          <div className={"radio_input flex-shrink-0" + (this.state.rescheduleConfirm ? " chosen" : "")}
            onClick={() => this.setState({ rescheduleConfirm: true })} />
          <p className="ml-2">{this.api.trTxt(TrVar.ConfirmedReschedule)}</p>
        </div>
        {
          this.state.rescheduleConfirm &&
          <div className="top_margin text_center">
            <div className="button" onClick={() => this.changeMeeting(false)}>{this.api.trTxt(TrVar.Reschedule)}</div>
          </div>
        }

      </>
    )
  }

  changeMeeting = (remove: boolean) => {
    const url = 'coach_meeting/reschedule/' + this.state.rescheduleEvent.id
    const dateFrom = this.setDate(this.state.date, this.state.hourFrom, this.state.minFrom)
    const data = {
      date : dateFrom,
      length: this.state.length
    }
    this.setState({ showCalendarOptions: false})
    this.api.post(url, data, ['meeting_time_not_available']).then(response => {
     this.getEvents()
    })
    .catch(response => {
      if (response.isExpectedError) {
        alert(this.api.trTxt(TrVar.MeetingTimeNotAvailable))
      }
    });
  }

  suggestClientMeeting = () => {
    return (
      <div className="m-4 color_white">
        <h4 className="text_center">{this.api.trTxt(TrVar.SuggestMeeting)}</h4>
        <p className="top_margin text_center">
          {this.state.suggestClient?.firstName} {this.state.suggestClient?.lastName}
        </p>
        {this.renderSuggestClientMeeting()}
      </div>
    )
  }

  doRemoveSuggestion = () => {
    const data = {
      suggestion_id: this.state.showEvent.id
    }

    this.api.post('coach_meeting_suggestion/remove', data).then(_result => {
      this.removeEvent(this.state.showEvent)
      this.closeHandleClick()
    })
  }

  suggestedClientMeeting = () => {
    return (
      <>
        <h4 className="text_center">
          {this.api.trTxt(TrVar.MeetingSuggestion)}
        </h4>
        <p className="top_margin">
          {this.state.showEvent?.name} {this.state.showEvent?.lastName}
        </p>
        <p className="top_margin">
          {formatSoon(this.state.showEvent.date, this.api.lang)}
        </p>
        <p className="top_margin">
          {this.api.trTxt(TrVar.WaitingForConfirmation)}
        </p>

        <p className="top_margin font_small">
          {
            this.api.trTxt(TrVar.PleaseConsiderThatTheClientHasReceivedAMail)
          }
        </p>
        <div className="text_center top_margin">
          <div className="button red inline_block" onClick={() => this.doRemoveSuggestion()}>
            {this.api.trTxt(TrVar.Remove)}
          </div>
        </div>
      </>
    )
  }

  handleClick = () => {
    if (this.state.showEvent.id) {
      if (this.state.showEvent.type === 'suggestion') {
        return this.suggestedClientMeeting()
      } else if (this.state.showEvent.type === 'meeting') {
        return this.showMeeting()
      } else {
        return this.editBlock()
      }
    } else if (this.state.rescheduleEvent.id) {
      return this.rescheduleMeeting()
    } else if (this.props.suggestClient) {
      return this.suggestClientMeeting()
    } else {
      if (this.state.date < new Date()) {
        return this.showDateHasPassed()
      } else {
        return this.editBlock()
      }
    }
  }

  closeHandleClick = () => {
    this.setState({
      showCalendarOptions: false,
      showEvent: {} as CalendarEventInterface,
      showCancelMeeting: false,
      showMoveMeeting: false,
      error: ''
    })
  }

  clickDate = (d: Date) => {
    this.setState({ date: d, showCalendarOptions: true, hourFrom: d.getHours(), hourTo: (d.getHours() + 2) });
  }

  clickEvent = (e: CalendarEventInterface) => {
    let hourFrom = 0
    let hourTo = 0
    let minFrom = 0
    let minTo = 0
    if (e.type === 'block') {
      const block: BlockInterface = e.parentEvent
      const start = new Date(block.start)
      const end = new Date(block.end)
      hourFrom = start.getHours()
      minFrom = start.getMinutes()
      hourTo = end.getHours()
      minTo = end.getMinutes()
    }
    this.setState({
      showCalendarOptions: true,
      showEvent: e,
      date: e.d,
      hourFrom: hourFrom,
      minFrom: minFrom,
      hourTo: hourTo,
      minTo: minTo,
    })
  }

  render() {

    let paddingBottom = 216
    let subtractHeight = window.innerWidth < 900 ? 170 : 112
    let onlyDate = false
    if (this.state.rescheduleEvent.id || this.props.suggestClient) {
      paddingBottom += 40
      onlyDate = true
    }
    if (this.props.fromVideo) {
      paddingBottom += 40
      subtractHeight -= 96
    }
    const pageHeight = "calc(100vh - " + subtractHeight + "px)"

    return (
      <>
        <IonModal isOpen={this.state.showHelp} className="modal_r-full"
                  onDidDismiss={() => this.setState({showHelp: false})}>
          {this.selectHelp()}
        </IonModal>        
        <IonModal isOpen={this.state.showCalendarOptions} className="modal_small"
                  onDidDismiss={() => this.closeHandleClick()}>
          <div className="m-4 color_white">
              <img alt="close" className="icon_small close_div" src="/assets/icon/white/cross.svg"
                   onClick={() => this.closeHandleClick()} />
            {this.state.showCalendarOptions && this.handleClick()}
          </div>

        </IonModal>
        
        <div className="page_section relative" style={{ height: pageHeight, paddingBottom: paddingBottom + "px" }}>
          {
            this.props.fromVideo ? (
              <div className="text_right mobile_mt-12">
                <img alt="close" onClick={() => this.props.close()} className="icon_small cursor-pointer"
                     src="/assets/icon/white/cross.svg"/>
              </div>
            ) : (
              <div className="close_div cursor-pointer" onClick={() => this.setState({ showHelp: true })}>
                <img alt="help" className="icon_medium" src="/assets/icon/white/questionmark.svg" />
              </div>
            )
          }

          {
            this.state.rescheduleEvent.id &&
            <div className="flex justify-center bg_purple p-2 mb-4">
              <p className="hide_small">
                {this.api.trTxt(TrVar.ClickToSelectNewTimeWith) + ' '}
                {this.state.rescheduleEvent.name}
              </p>
              <p className="hide_large">
                {this.api.trTxt(TrVar.SelectNewTime)}
              </p>
              <span className="link_text ml-4"
                    onClick={() => this.setState({rescheduleEvent: {} as CalendarEventInterface})}>
                {this.api.trTxt(TrVar.Cancel)}
              </span>
            </div>
          }
          {
            this.props.suggestClient &&
              <div className="flex justify-center bg_purple p-2 mb-4">
                  <p className="hide_small">
                    {this.api.trTxt(TrVar.ClickWhereYouWantToSuggestMeeting) + ' '}
                    {this.props.suggestClient.firstName}
                  </p>
              </div>
          }
        
          {
            this.state.loaded ?
              <Calendar events={this.state.events} clickDate={this.clickDate} clickEvent={this.clickEvent}
                        onlyDate={onlyDate}/>
              :
              <></>
          }
        </div>
        <div className="flex top_margin">
          <div className="calendar_dot right_margin" />
          <p>{this.api.trTxt(TrVar.AvailableTimes)}</p>
        </div>
      </>
    );
  }
}
