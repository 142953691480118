import React from 'react';
import Api from '../../services/Api';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  clientId: number
  close?: Function
}

interface ComponentState {
  notes: string
}

export default class CoachNotes extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance();

  state: Readonly<ComponentState> = {
    notes: '',
  };

  componentDidMount() {
    this.api.get('coach_notes/' + this.props.clientId).then(response => {
      this.setState({notes: response.json.notes})
    })
  }

  save = () => {
    const data = {
      notes: this.state.notes
    }
    this.api.post('coach_notes/' + this.props.clientId, data).then(response => {
      if (this.props.close) {
        this.props.close()
      }
    })
  }


  editNotes = (e: any) => {
    this.setState({ notes: e.target.value });
  };

  close = () => {
    if (this.props.close) {
      this.props.close()
    }
  }

  render() {

    return (
      <div className="top_margin bottom_margin left_margin right_margin flex flex-col justify-between h-full">
        <div className="flex justify-between">
          <h4 className="top_margin bottom_margin color_white">{this.api.trTxt(TrVar.CoachNotes)}</h4>
          {
              this.props.close &&
              <img alt="close" src="/assets/icon/white/close-circle.svg" className="cursor-pointer icon_medium"
                   onClick={() => this.close()}/>
          }
        </div>

        <textarea
          className="top_margin w-full page_text_area full noborder flex-grow"
          placeholder={this.api.trTxt(TrVar.TheseNotesAreOnlyVisible)}
          rows={4}
          value={this.state.notes}
          onChange={this.editNotes}
        />

        <div className="top_margin flex justify-end">
          <div className="button green" onClick={() => this.save()}>{this.api.trTxt(TrVar.Save)}</div>
        </div>

      </div>
    );
  }
}
