import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import {CoachInterface} from "../../interfaces/Coach";
import {ClientInterface} from "../../interfaces/Client";
import Breadcrumbs from "../menu/Breadcrumbs";
import {changeUrl} from "../../services/Navigation";
import CoachChat from "./CoachChat";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  navigation: any
}

interface ComponentState {
  coach: CoachInterface
  clients: Array<ClientInterface>
  previousClients: Array<ClientInterface>
  sortBy: string
  loaded: boolean
}

export default class CoachChats extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    clients: [] as Array<ClientInterface>,
    previousClients: [] as Array<ClientInterface>,
    sortBy: 'last',
    loaded: false,
  }

  componentDidMount() {
    this.getData()
  }

  getClient = (clientId: number) => {
    for (const client of this.state.clients) {
      if (client.id === clientId) {
        return client
      }
    }
    return null
  }

  getPreviousClient = (clientId: number) => {
    for (const client of this.state.previousClients) {
      if (client.id === clientId) {
        return client
      }
    }
    return null
  }

  showClientChat = () => {
    const clientId = parseInt(this.props.navigation.sub1)
    const client = this.getClient(clientId)
    if (client) {
      return (<CoachChat client={client} close={this.closeChat} isClientActive={true}/>)
    } else {
      const previousClient = this.getPreviousClient(clientId)
      if (previousClient) {
        return (<CoachChat client={previousClient} close={this.closeChat} isClientActive={false}/>)
      }
      return (
        <div className="page_content">Fatal error - could not find client {this.props.navigation.sub1}</div>
      )
    }
  }

  private getData = () => {
    this.api.get('coach_chats').then(response => {
      this.setState({
        coach: response.json.coach,
        clients: response.json.clients,
        previousClients: response.json.previousClients,
        loaded: true,
      })
    })
  }

  closeChat = () => {
    changeUrl('/app/coach/chat')
  }

  sortClients = (a: ClientInterface, b: ClientInterface) => {
    if (this.state.sortBy === 'name') {
      return a.firstName > b.firstName ? 1 : -1
    } else if (this.state.sortBy === 'unread') {
      const aKey = a.unreadChats ? a.unreadChats : 0
      const bKey = b.unreadChats ? b.unreadChats : 0
      return aKey < bKey ? 1 : -1
    } else {
      const aKey = (a.lastMessage && a.lastMessage !== '-') ? a.lastMessage : '1900-01-01 00:00:00'
      const bKey = (b.lastMessage && b.lastMessage !== '-') ? b.lastMessage : '1900-01-01 00:00:00'
      return aKey < bKey ? 1 : -1
    }
  }


  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    if (this.props.navigation.sub1.length > 0) {
      return this.showClientChat()
    }

    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.Chat)}/>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.MyClients)}</h1>
          <div className="page_bar"/>

          <IonGrid>
            <IonRow>
              <IonCol size="12" size-md="5" className="hide_small cursor-pointer"
                      onClick={() => this.setState({sortBy: 'name'})}>
                <p className="blue underline cursor-pointer">
                  {this.api.trTxt(TrVar.Client)}
                </p>
              </IonCol>
              <IonCol size="12" size-md="2" className="hide_small text_center cursor-pointer"
                      onClick={() => this.setState({sortBy: 'unread'})}>
                <p className="blue underline cursor-pointer">
                  {this.api.trTxt(TrVar.Unread)}
                </p>
              </IonCol>
              <IonCol size="12" size-md="3" className="hide_small cursor-pointer"
                      onClick={() => this.setState({sortBy: 'last'})}>
                <p className="blue underline cursor-pointer">
                  {this.api.trTxt(TrVar.Last)}
                </p>
              </IonCol>
              <IonCol size="12" size-md="2" className="hide_small">
                <p className="blue">{this.api.trTxt(TrVar.License)}</p>
              </IonCol>
            </IonRow>
            {
              this.state.clients.sort(this.sortClients).map((client, index) => {
                return (
                  <IonRow key={"client_" + client.id}
                       className="top_margin cursor-pointer flex items-center"
                       onClick={() => changeUrl('/app/coach/chat/' + client.id)}>
                    <IonCol size="12" size-md="5">
                      <div className="flex items-center">
                        <img alt="client" src={client.picture} className="picture_small round"/>
                        <div className="left_margin">
                          {client.firstName} {client.lastName}
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="12" size-md="2">
                      <span className="hide_small">{client.unreadChats}</span>
                      <span className="show_small">{client.unreadChats} {this.api.trTxt(TrVar.UnreadMessages)}</span>
                    </IonCol>

                    <IonCol size="12" size-md="3">
                      <div className="flex">
                        <span className="show_small">
                          {this.api.trTxt(TrVar.Last)}:
                        </span>&nbsp;
                        <span>{client.lastMessage}</span>
                      </div>
                    </IonCol>

                    <IonCol size="12" size-md="2">
                      <div className="flex">
                        <span className="show_small">
                          {this.api.trTxt(TrVar.License)}:
                        </span>&nbsp;
                        <span>{client.package.name}</span>
                      </div>
                    </IonCol>
                  </IonRow>
                );
              })
            }
          </IonGrid>
        </div>

        {
          this.state.previousClients.length > 0 &&
            <div className="page_section top_margin">
                <h2>
                  {
                    this.api.trTxt(TrVar.ClientsYouHaveCoachedBefore)
                  }
                </h2>
                <div className="page_bar"/>


                {
                  this.state.previousClients.map((client, index) => {
                  return (
                    <div key={"client_" + client.id}
                         className="flex items-center justify-between cursor-pointer bottom_margin"
                         onClick={() => changeUrl('/app/coach/chat/' + client.id)}>
                      <div className="flex items-center">
                        <img alt="client" src={client.picture} className="picture_small round"/>
                        <div className="left_margin">
                          {client.firstName} {client.lastName}
                        </div>
                      </div>
                      <div>
                        {client.unreadChats} {this.api.trTxt(TrVar.UnreadMessages)}
                      </div>
                    </div>
                  );
                })}
            </div>
        }
      </div>
    )
  }
}
