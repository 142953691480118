import React from "react";
import {IonLabel, IonRange} from "@ionic/react";

interface Question {
  name: string,
  value: number,
  left: string,
  right: string,
  disa: Array<number>,
}

interface ComponentProps {
  update: Function,
  name: string,
}

interface ComponentState {
  questions: Array<Question>
}

export default class BehaviouralQuestions extends React.Component<ComponentProps, ComponentState> {

  constructor(props: ComponentProps) {
    super(props);

    let questions: Array<Question> = [];

    questions.push({
      name: 'Fakta är viktigt när jag ska ta ett beslut',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0, 3]
    });

    questions.push({
      name: 'Jag trivs oftast i ett högre tempo',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0, 1]
    });

    questions.push({
      name: 'Känslor är viktigt när jag ska bestämma mig för något',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [1, 2]
    });

    questions.push({
      name: 'Jag tar gärna god tid på mig för att göra saker',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [2, 3]
    });

    questions.push({
      name: 'Optimistisk',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [1]
    });

    questions.push({
      name: 'Beslutsam',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0]
    });

    questions.push({
      name: 'Saklig',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0, 3]
    });

    questions.push({
      name: 'Tålmodig',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [2]
    });

    questions.push({
      name: 'Känslomässig',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [1, 2]
    });

    questions.push({
      name: 'Noggrann',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [3]
    });

    questions.push({
      name: 'Social',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [1]
    });

    questions.push({
      name: 'Tydlig',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0]
    });

    questions.push({
      name: 'Lyhörd',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [2]
    });

    questions.push({
      name: 'Systematisk',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [3]
    });

    questions.push({
      name: 'Lugn',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [2, 3]
    });

    questions.push({
      name: 'Detaljerad',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [3]
    });

    questions.push({
      name: 'Entusiastisk',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [1]
    });

    questions.push({
      name: 'Snabb',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0, 1]
    });

    questions.push({
      name: 'Rak',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [0]
    });

    questions.push({
      name: 'Lyssnande',
      value: 0,
      left: 'Stämmer inte',
      right: 'Stämmer bra',
      disa: [2]
    });

    this.state = {
      questions: questions
    }
  }

  private changeValue = (event: any, i: number) => {
    let pi = 0;
    const disa = [0, 0, 0, 0];
    const questions: Array<Question> = [];
    for (const question of this.state.questions) {
      if (i === pi) {
        question.value = event.target.value;
      }
      for (const p of question.disa) {
        disa[p] += question.value
      }
      questions.push(question);
      pi++;
    }

    const disaNormalized = this.normalizeDisa(disa);

    this.setState({questions: questions});
    this.props.update(disaNormalized);
  }

  private normalizeDisa(disa: Array<number>) {
    let total = 0;

    for (const value of disa) {
      total += value;
    }

    if (total === 0) {
      return disa;
    }

    const normalized = [0, 0, 0, 0];
    for (let i = 0; i < 4; i++) {
      normalized[i] = 100 * disa[i] / total;
    }
    return normalized;
  }

  render() {
    return (
      <div style={{padding: "0 16px", width: "44vw"}}>
        <h3 className="text_center my-4">{this.props.name}</h3>
        {
          this.state.questions.map((question, i: number) => {
            return (
              <div key={i} style={{marginTop: "-8px", textAlign: "center"}}>
                {
                  question.name !== '' &&
                    <p style={{marginBottom: "-12px"}}>{question.name}</p>
                }

                <IonRange min={0} max={100} color="secondary" value={question.value}
                          onIonChange={(e) => this.changeValue(e, i)}>
                  <IonLabel slot="start" style={{minWidth: "80px"}}>{question.left}</IonLabel>
                  <IonLabel slot="end" style={{minWidth: "80px"}}>{question.right}</IonLabel>
                </IonRange>
              </div>
            )
          })
        }
      </div>
    )
  }
}
