import React from 'react';
import Api from '../../services/Api';
import Breadcrumbs from "../menu/Breadcrumbs";
import {CoachInterface} from "../../interfaces/Coach";
import { changeUrl } from "../../services/Navigation";
import Input from '../form/Input';
import { countInputErrors, checkInputErrors } from '../form/CheckInput';
import { InputsInterface, InputInterface } from '../form/interfaces';
import { i18nInterface } from '../../interfaces/i18n';
import { CoachOptionsInterface } from '../../interfaces/CoachOptions';
import Loading from '../Loading';
import { TrVar } from '../../services/translate';

interface ComponentProps{
  onboarding: boolean
  done?: Function
  saveText?: string
}

interface ComponentState {
  coach: CoachInterface;
  fileName: string;
  rawFile: any;
  uploaded: boolean;
  showUploadError: string;
  error: boolean;
  showFormError: boolean;
  inputs: InputsInterface;
  i18n: i18nInterface;
  loaded: boolean;
  options: CoachOptionsInterface;
}

export default class CoachProfile extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance();

  inputs: InputsInterface = {
    firstName: {
      type: 'text',
      name: 'firstName',
      placeholder: this.api.trTxt(TrVar.FirstName),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    lastName: {
      type: 'text',
      name: 'lastName',
      placeholder: this.api.trTxt(TrVar.LastName),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    email: {
      type: 'email',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    city: {
      type: 'text',
      name: 'city',
      placeholder: this.api.trTxt(TrVar.City),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    company: {
      type: 'text',
      name: 'company',
      placeholder: this.api.trTxt(TrVar.Company),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 1,
    },
    phone: {
      type: 'text',
      placeholder: (this.api.trTxt(TrVar.Phone)) + ', format +467012345678',
      name: 'phone',
      value: '',
      error: '',
      minLength: 1,
      maxLength: 18,
    },    
    coachHours: {
      type: 'number',
      name: 'coachHours',
      placeholder: this.api.trTxt(TrVar.Hours),
      value: '',
      error: '',
      minValue: 0,
      maxValue: 80000,
      minLength: 1,
    },
    pausMeeting: {
      type: 'select',
      name: 'pausMeeting',
      placeholder: this.api.trTxt(TrVar.ChooseOneOption),
      value: '',
      error: '',
      minLength: 1,
      options: [],
    },
    minMeet: {
      type: 'select',
      name: 'minMeet',
      placeholder: this.api.trTxt(TrVar.ChooseOneOption),
      value: '',
      error: '',
      minLength: 1,
      options: [],
    },
  }



  // Inputs below are only for subscription coaches
  inputsSubscription: InputsInterface = {
    orgNr: {
      type: 'text',
      name: 'orgNr',
      placeholder: this.api.trTxt(TrVar.OrgNr),
      value: '',
      error: '',
      minLength: 1,
      maxLength: 16,
    },
    linkedin: {
      type: 'text',
      name: 'linkedin',
      placeholder: this.api.trTxt(TrVar.LinkedIn),
      value: '',
      error: '',
      maxLength: 128,
    },
    bankAccount: {
      type: 'text',
      name: 'bankAccount',
      placeholder: this.api.trTxt(TrVar.BankAccount),
      value: '',
      error: '',
      maxLength: 32,
    },
    membership: {
      type: 'select',
      name: 'membership',
      placeholder: this.api.trTxt(TrVar.ChooseOneOption),
      value: '',
      error: '',
      options: [],
      minLength: 1,
    },
    membershipOther: {
      type: 'text',
      name: 'membershipOther',
      placeholder: this.api.trTxt(TrVar.Other),
      value: '',
      error: '',
      maxLength: 64,
    },
    certificate: {
      type: 'select',
      name: 'certificate',
      placeholder: this.api.trTxt(TrVar.ChooseOneOption),
      value: '',
      error: '',
      options: [],
      minLength: 1,
    },
    certificateOther: {
      type: 'text',
      name: 'certificateOther',
      placeholder: this.api.trTxt(TrVar.Other),
      value: '',
      error: '',
      maxLength: 64,
    },
    managementExperience: {
      type: 'number',
      name: 'managementExperience',
      placeholder: this.api.trTxt(TrVar.NumberYears),
      value: '',
      error: '',
      minValue: 0,
      maxValue: 80,
      minLength: 1,
    },
    certifiedSince: {
      type: 'date',
      name: 'certifiedSince',
      placeholder: 'YYYY-MM-DD',
      value: '',
      error: '',
      minDate: '1900-01-01',
      maxDate: new Date().toISOString().slice(0, 10),
    }
  }

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,

    fileName: '',
    rawFile: null,
    uploaded: false,
    showUploadError: '',
    error: false,
    inputs: this.inputs,
    i18n: {} as i18nInterface,
    showFormError: false,
    loaded: false,
    options: {} as CoachOptionsInterface,
  }

  componentDidMount () {
    this.getData()
  }

  private valOrEmptyStr = (val: string) => {
    if (val) {
      return val
    }
    return ''
  }

  private getData = () => {
    const breakArray = [
      {name: '0 min', value: '0'},
      {name: '5 min', value: '5'},
      {name: '10 min', value: '10'},
      {name: '15 min', value: '15'},
      {name: '20 min', value: '20'},
      {name: '25 min', value: '25'},
      {name: '30 min', value: '30'},
      {name: '35 min', value: '35'},
      {name: '40 min', value: '40'},
      {name: '45 min', value: '45'},
      {name: '50 min', value: '50'},
      {name: '55 min', value: '55'},
      {name: '60 min', value: '60'},
    ]

    const minMeetingArray = [
      { name: '0', value: '0' },
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '5', value: '5' },
      { name: '6', value: '6' },
      { name: '7', value: '7' },
      { name: '8', value: '8' },
      { name: '9', value: '9' },
      { name: '10', value: '10' },
      { name: '11', value: '11' },
      { name: '12', value: '12' },
      { name: '13', value: '13' },
      { name: '14', value: '14' },
      { name: '15', value: '15' },
      { name: '16', value: '16' },
      { name: '17', value: '17' },
      { name: '18', value: '18' },
      { name: '19', value: '19' },
      { name: '20', value: '20' },
      { name: '21', value: '21' },
      { name: '22', value: '22' },
      { name: '23', value: '23' },
      { name: '24', value: '24' },
    ]

    this.api.get('coach?i18n=1&options=1').then(response => {
      let fileName = response.json.coach.cvFilename
      this.inputs.firstName.value = this.valOrEmptyStr(response.json.coach.firstName)
      this.inputs.lastName.value = this.valOrEmptyStr(response.json.coach.lastName)
      this.inputs.email.value = this.valOrEmptyStr(response.json.coach.email)
      this.inputs.city.value = this.valOrEmptyStr(response.json.coach.city)
      this.inputs.company.value = this.valOrEmptyStr(response.json.coach.companyName)
      this.inputs.coachHours.value = response.json.coach.coachingHours === 0 ? '0' : this.valOrEmptyStr(response.json.coach.coachingHours)
      this.inputs.pausMeeting.value = response.json.coach.meetingPause === 0 ? '0' : this.valOrEmptyStr(response.json.coach.meetingPause)
      this.inputs.minMeet.value = response.json.coach.meetingBookMinTime === 0 ? '0' : this.valOrEmptyStr(response.json.coach.meetingBookMinTime)
      this.inputs.phone.value = this.valOrEmptyStr(response.json.coach.phone)

      for (let time of breakArray) {
        this.inputs.pausMeeting.options?.push({
          name: time.name,
          value: time.value,
          selected: false,
        })
      }

      for (let meet of minMeetingArray) {
        this.inputs.minMeet.options?.push({
          name: meet.name,
          value: meet.value,
          selected: false,
        })
      }

      if (response.json.coach.coachType === 2) {
        for (const name in this.inputsSubscription) {
          this.inputs[name] = this.inputsSubscription[name]
        }
        this.inputs.orgNr.value = this.valOrEmptyStr(response.json.coach.orgNr)
        this.inputs.linkedin.value = this.valOrEmptyStr(response.json.coach.linkedin)
        this.inputs.bankAccount.value = this.valOrEmptyStr(response.json.coach.bankAccount)
        this.inputs.membership.value = this.valOrEmptyStr(response.json.coach.membership)
        this.inputs.membershipOther.value = this.valOrEmptyStr(response.json.coach.membershipOther)
        this.inputs.certificate.value = this.valOrEmptyStr(response.json.coach.certificate)
        this.inputs.certificateOther.value = this.valOrEmptyStr(response.json.coach.certificateOther)
        this.inputs.managementExperience.value = response.json.coach.managementExperience === 0 ? '0' : this.valOrEmptyStr(response.json.coach.managementExperience)
        this.inputs.certifiedSince.value = this.valOrEmptyStr(response.json.coach.certifiedSince)

        for (let membership of response.json.options.memberships) {
          if (membership.value === 0) continue
          this.inputs.membership.options?.push({
            name: membership.name,
            value: membership.value,
            selected: membership.selected,
          })
        }

        for (let certificate of response.json.options.certificates) {
          if (certificate.value === 0) continue
          this.inputs.certificate.options?.push({
            name: certificate.name,
            value: certificate.value,
            selected: certificate.selected,
          })
        }
      }
      
      const coach = response.json.coach as CoachInterface
      this.setState({ coach: coach, loaded: true, uploaded: fileName !== '', fileName: fileName })
    });
  };

  inputChange = (input: InputInterface) => {
    const formError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, showFormError: formError })
  }

  private checkAllErrors = () => {
    const errors = checkInputErrors(this.inputs, this.api.lang)
    this.setState({
      showFormError: errors > 0,
      inputs: this.inputs
    })

    return errors
  }

  uploadFile = (evt: any) => {
    if (evt === null) {
      return;
    }
    const rawFile = evt.target.files[0];
    console.log('File: ' + rawFile.name)
    const reader = new FileReader();

    reader.onload = ((theFile) => {
      return (e: any) => {
        if (e.target.result.length > 2000000) {
          console.log('Length: ' + e.target.result.length)
          this.setState({showUploadError: this.api.trTxt(TrVar.TooBigImgError)})
        } else {
          this.setState({rawFile: e.target.result, fileName: rawFile.name, uploaded: true, showUploadError: ''})
        }
      };
    })(rawFile);
    if(rawFile){
      reader.readAsDataURL(rawFile);
    } else {
      this.setState({showUploadError: this.api.trTxt(TrVar.NoFileError)})
    }
  }

  next = () => {
    if (this.props.onboarding) {
      if (this.props.done) {
        this.props.done()
      } else {
        alert("Fatal error - no next step")
      }
    } else {
      changeUrl('/app/coach/my_account')
    }
  }

  saveCoachProfile = () => {
    if (this.checkAllErrors() > 0) {
      return
    }

    if (this.state.coach.coachType === 2 && this.state.fileName.length < 1) {
      this.setState({showUploadError: this.api.trTxt(TrVar.CertificateError), showFormError: true})
      return
    }

    const profile: {[name: string] : any} = {
      firstName: this.inputs.firstName.value,
      lastName: this.inputs.lastName.value,
      email: this.inputs.email.value,
      city: this.inputs.city.value,
      company: this.inputs.company.value,
      coachingHours: parseInt(this.inputs.coachHours.value),
      meetingBookMinTime: parseInt(this.inputs.minMeet.value),
      meetingPause: parseInt(this.inputs.pausMeeting.value),
      phone: this.inputs.phone.value,
    }

    if (this.state.coach.coachType === 2) {
      profile['orgNr'] =  this.inputs.orgNr.value
      profile['bankAccount'] = this.inputs.bankAccount.value
      profile['linkedin'] = this.inputs.linkedin.value
      profile['membership'] = parseInt(this.inputs.membership.value)
      profile['certificate'] =  parseInt(this.inputs.certificate.value)
      profile['certificateOther'] =  this.inputs.certificateOther.value
      profile['fileName'] =  this.state.fileName
      profile['rawFile'] = this.state.rawFile
      profile['coachingHours'] =  parseInt(this.inputs.coachHours.value)
      profile['managementExperience'] =  parseInt(this.inputs.managementExperience.value)
      profile['certifiedSince'] = this.inputs.certifiedSince.value
      profile['meetingPause'] = parseInt(this.inputs.pausMeeting.value)
      profile['meetingBookMinTime'] = parseInt(this.inputs.minMeet.value)
      profile['membershipOther'] = this.inputs.membershipOther.value
    }

    let coachData = {
      profile: profile
    }

    this.api.post('coach_save2', coachData, ['email_in_use', 'invalid_email'])
      .then(_response => {
        this.next()
      })
      .catch(response => {
        if (response.isExpectedError) {
          this.setState({error: true})
        }
      })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    return (
      <>
        {
          !this.props.onboarding &&
          <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.MyAccount), link: '/app/coach/my_account' }]}
            name={this.api.trTxt(TrVar.MyCoachSettings)}/>
        }   
        <div className={!this.props.onboarding ? 'page_content' : ''}>
          <div className="page_section">
            <h2>{this.api.trTxt(TrVar.MyCoachSettings)}</h2>
            <div className="page_bar" />
            <div>
              <h3 className='bottom_margin top_margin'>{this.api.trTxt(TrVar.YourDetails)}</h3>
              <div className="flex section_direction">
                <div className="page_half_content flex flex-col">
                  <Input data="first-name-input" input={this.inputs.firstName} update={this.inputChange} classes="page_input" language={this.api.lang} /> 
                  <Input data="email-input" input={this.inputs.email} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  <Input data="phone-input" input={this.inputs.phone} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  <Input data="company-input" input={this.inputs.company} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  {
                    this.state.coach.coachType === 2 &&
                    <Input data="linkedin-input" input={this.inputs.linkedin} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  }
                </div>
                <div className="page_half_content flex flex-col r_top_margin r_left_margin">
                  <Input data="last-name-input" input={this.inputs.lastName} update={this.inputChange} classes="page_input" language={this.api.lang} />
                  <Input data="city-input" input={this.inputs.city} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} /> 
                  {
                    this.state.coach.coachType === 2 &&
                    <Input data="org-nr-input" input={this.inputs.orgNr} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  }
                  {
                    this.state.coach.coachType === 2 &&
                    <Input data="bank-account-input" input={this.inputs.bankAccount} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                  }
                </div>
              </div>
            </div>

            {
              this.state.coach.coachType === 2 &&
              <>
                <div className="page_bar" style={{ 'margin': '40px 0 20px 0' }} />
                <div className="flex section_direction">
                  <div className="page_half_content">
                    <div>
                      <h4 style={{ marginBottom: '10px' }}>{this.api.trTxt(TrVar.CoachMembership)}</h4>
                      <Input data="membership-input" input={this.inputs.membership} update={this.inputChange} classes="page_select w100" language={this.api.lang} />
                      {
                        parseInt(this.inputs.membership.value) === 100 &&
                        <Input data="othermembership-input" input={this.inputs.membershipOther} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                      }
                    </div>
                    <div className="top_margin">
                      <h4 style={{ marginBottom: '10px' }}>{this.api.trTxt(TrVar.CoachCertificate)}</h4>
                      <Input data="certificate-input" input={this.inputs.certificate} update={this.inputChange} classes="page_select w100" language={this.api.lang} />
                      {
                        parseInt(this.inputs.certificate.value) === 100 &&
                        <Input data="othercertificate-input" input={this.inputs.certificateOther} update={this.inputChange} classes="page_input top_margin" language={this.api.lang} />
                      }
                    </div>
                  </div>
                  <div className="page_half_content r_top_margin r_left_margin">
                    <h4 className="bottom_margin">{this.api.trTxt(TrVar.UploadCertificate)}</h4>
                    <div>
                      {
                        this.state.uploaded &&
                          <p><b>{this.api.trTxt(TrVar.UploadedDocument)}:</b> {this.state.fileName}</p>
                      }

                      <div className="flex cursor-pointer top_margin items-center" onChange={(e:any) => this.uploadFile(e)}>
                        <img className='svg_blue' src="/assets/icon/share.svg" alt="upload document" />
                        <input id="documentFile" type="file" className="display_none" onChange={(e:any) => this.uploadFile(e)} accept=".pdf"/>
                        <label htmlFor="documentFile" className="left_margin underline cursor-pointer">
                          {this.state.uploaded ? this.api.trTxt(TrVar.ChooseNewFile) :
                            this.api.trTxt(TrVar.ChooseFile)} (PDF, max 2MB)
                        </label>
                      </div>
                    </div>

                    {
                      this.state.showUploadError.length > 0 &&
                      <p className="error_red top_margin">{this.state.showUploadError}</p>
                    }
                  </div>
                </div>
              </>
            }
                
            <div className="page_bar" style={{ 'margin': '40px 0 20px 0' }} />
            <div className="page_half_content">
              <h4 style={{ marginBottom: '10px' }}>{this.api.trTxt(TrVar.NumberCoachHours)}</h4>
              <Input data="hours-input" input={this.inputs.coachHours} update={this.inputChange} classes="page_input" language={this.api.lang} />
            </div>

            {
              this.state.coach.coachType === 2 &&
              <>
                <div className="page_bar" style={{ 'margin': '40px 0 20px 0' }} />
                <div className="page_half_content">
                  <h4 style={{ marginBottom: '10px' }}>{this.api.trTxt(TrVar.ManagementCoachExperience)}</h4>
                  <Input data="management-input" input={this.inputs.managementExperience} update={this.inputChange} classes="page_input" language={this.api.lang} />
                </div>
                <div className="page_bar" style={{ 'margin': '40px 0 20px 0' }} />
                <div className="page_half_content">
                  <h4 style={{ marginBottom: '10px' }}>{this.api.trTxt(TrVar.CertifiedSince)}</h4>
                  <Input data="certifiedsince-input" input={this.inputs.certifiedSince} update={this.inputChange} classes="date_input" language={this.api.lang} />
                </div>
              </>
            }
                
            <div className="page_bar" style={{ 'margin': '40px 0 20px 0' }} />
                <div className="page_half_content">
                  <h4>{this.api.trTxt(TrVar.PausBetweenSessions)}</h4>
                  <div style={{ margin: '5px 0 10px 0' }} className="blue">{this.api.trTxt(TrVar.WantedTimeBetweenMeetings)}</div>
                  <Input data="pausmeeting-input" input={this.inputs.pausMeeting} update={this.inputChange} classes="page_select w100" language={this.api.lang} /> 
                  <h4 className="top_margin">{this.api.trTxt(TrVar.TimeAvailability)}</h4>
                  <div style={{ margin: '5px 0 10px 0' }} className="blue">{this.api.trTxt(TrVar.EarliestBooking)}</div>
                  <Input data="book-input" input={this.inputs.minMeet} update={this.inputChange} classes="page_select w100" language={this.api.lang} /> 
              <div className="btn_top_margin" />              
              {
                this.state.showFormError && <p className="top_margin bottom_margin error_red">{this.api.trTxt(TrVar.FormErrors)}</p>        
              }    
              <button onClick={this.saveCoachProfile} className="button">{this.api.trTxt(TrVar.Save)}</button>    
              {
                this.state.error &&
                <p className="error_red top_margin">{this.api.trTxt(TrVar.EmailInUse)}</p>      
              }    
            </div>
          </div>
        </div>
      </>
    )
  }
}
