import React from 'react';
import { ClientLicenseInterface } from '../../interfaces/ClientLicense';
import { LicenseInterface } from '../../interfaces/License';
import { PackageType } from '../../interfaces/Package';
import Api from '../../services/Api';
import Text from '../Text/Text'
import { changeUrl } from '../../services/Navigation'

interface ComponentProps {
  progress: Function
  license?: LicenseInterface
  clientLicense?: ClientLicenseInterface
}

const CalendarClientIntro = (props: ComponentProps) => {
  const api = Api.getInstance()

      return (
        <div className='page_section'>
          <h1>{api.lang === 'sv' ? 'Innan du bokar' : 'Before you book'}</h1>
          <div className='page_bar' />
          <div className="flex section_direction">
            <div className="r-half-col">
              <div style={{ maxWidth: '478px' }}>
                {api.lang === 'sv' ?
                  <>
                    <h3>Om din licens:</h3>
                    <ul className="text-neutral-400 list-disc translate-x-6" style={{ marginLeft: '-10px' }}>
                      {(props.clientLicense?.meetingCount && props.clientLicense.meetingCount !== null) &&
                        <li>I licensen ingår {props.clientLicense.meetingCount} coachmöten.</li>
                      }
                      {props.license?.package.type === PackageType.MAXIMIZE ?
                        <li>Alla coachmöten är 45 minuter.</li>  
                        :
                        <li>Ditt första coachmöte är 45 minuter, därefter är alla möten 30 minuter långa.</li>
                      }
                      <li>Du kan alltid chatta med din coach.</li>
                      {props.license?.endDate ?
                        <li>Licensen gäller fram till {props.clientLicense?.endDate}.</li>  
                        :
                        <li>Licensen har inget slutdatum.</li>
                      }
                    </ul>
                    <div className="top_margin">
                      <h3>Om- och avbokning</h3>
                    <ul className="text-neutral-400 list-disc translate-x-6" style={{ marginLeft: '-10px' }}>
                      <li>Avboka eller boka om möten senast 24 timmar innan.</li>
                      <li>Chatta direkt med din coach om du avbokar eller behöver göra en ändring.</li>
                    </ul>
                    </div>
                    <div className="top_margin">
                      <div onClick={() => props.progress(2)} className="top_margin mobile_size_btn button inline_block green  mr-2">
                        Till bokning
                      </div>
                      <div onClick={() => window.location.href = "/app/client/overview"} className="top_margin mobile_size_btn button white inline_block">
                        Hoppa över
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <h3>About your license:</h3>
                    <ul className="list-disc pl-12 text-neutral-400" style={{ marginLeft: '-10px' }}>
                      <li>Your license includes {props.clientLicense?.meetingCount ? props.clientLicense.meetingCount : ''} meetings</li>
                      {props.license?.package.type === PackageType.MAXIMIZE ?
                        <li>All sessions are 45 minutes.</li>  
                        :
                        <li>Your first session is 45 minutes, the following meetings are 30 minutes long.</li>
                      }
                      <li>Your always able to chat with your coach.</li>
                      {props.license?.endDate ?
                        <li>This license if valid to {props.clientLicense?.endDate}.</li>  
                        :
                        <li>This license has no end date.</li>
                      }
                    </ul>
                    <div className="top_margin">
                      <h3>Rescheduling and cancelation</h3>
                      <ul className="text-neutral-400 list-disc pl-12" style={{ marginLeft: '-10px' }}>
                        <li>Cancel or reschedule an appointment at least 24 hours in advance.</li>
                        <li>Chat with your coach if you need to cancel or make a change.</li>
                      </ul>
                    </div>
                    <div className="top_margin">
                      <div onClick={() => props.progress(2)} className="top_margin button mobile_size_btn inline_block green mr-2">
                        To the calendar
                      </div>
                      <div onClick={() => window.location.href = "/app/client/overview"} className="top_margin mobile_size_btn button white inline_block">
                        Skip
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="r-half-col r_top_margin">
            </div>
          </div>
        </div>
      )  
}

export default CalendarClientIntro;
//http://localhost:8100/app/client_onboarding/5