import React from 'react'
import { CoachInterface } from '../../interfaces/Coach';
import { ClientInterface } from "../../interfaces/Client";
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import {connect, ConnectedProps} from "react-redux";
import CoachAgreement from "../info/CoachAgreement";
import { TrVar } from '../../services/translate';
import Text from "../Text/Text";

const mergeProps = (parentProps: any) => ({
  ...parentProps,
});

const connector = connect( mergeProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ComponentState {
  coach: CoachInterface
  client: ClientInterface
  languages: Array<string>
  areas: Array<string>
  loaded: boolean
}

export default class ClientCoach extends React.Component<PropsFromRedux, ComponentState>  {
  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    client: {} as ClientInterface,
    languages: [],
    areas: [],
    loaded: false
  }

  componentDidMount() {
    this.getCoachData()
  }

  private getCoachData = () => {
    this.api.get('client?coachDetails=1').then(response => {
      const coach = response.json.coach ? response.json.coach : {} as CoachInterface
      const languages = response.json.coach ? response.json.coach.languages : []
      const areas = response.json.areas ? response.json.coach.areas : []
      this.setState({
        coach: coach,
        client: response.json.client,
        languages: languages,
        areas: areas,
        loaded: true
      })
    })
  }


  render() {
    return (
      <>
        <Breadcrumbs breadcrumbs={[{name: this.api.trTxt(TrVar.MyAccount), link: '/app/client/my_account'}]}
                     name={this.api.trTxt(TrVar.YourCoach)}/>
        <div className="page_content">
          <div className="page_section">
            <div className="flex">
              <img className='svg_blue' src="/assets/icon/coach.svg" height={50} alt="blue coach icon" />
              <h1 className='left_margin my-auto'>{this.api.trTxt(TrVar.YourCoach)}</h1>
            </div>
            <div className="page_bar" />
            <div className="flex section_direction">
              {
                this.state.coach.id ? (
                  <>
                    <div className="section_third text_center">
                      <img height={'auto'} width={'auto'} src={this.state.coach.picture} alt="coach"
                           className="round" />
                    </div>
                    <div className="section_third flex flex-col r_left_margin">
                      <div>
                        <h4 className="blue r_top_margin">{this.api.trTxt(TrVar.Name)}</h4>
                        <h4>{this.state.coach.firstName} {this.state.coach.lastName}</h4>
                      </div>
                      <div>
                        <h4 className="blue top_margin">{this.api.trTxt(TrVar.Language)}</h4>
                        <h4>{
                          this.state.languages.map((language:string, index) => {
                            const separator = index > 0 ? ', ' : ' '
                            return (
                              separator + language
                            )
                          })}</h4>
                      </div>
                      <div>
                        <h4 className="blue top_margin">{this.api.trTxt(TrVar.CoachHours)}</h4>
                        <h4>{this.state.coach.coachingHours}</h4>
                      </div>
                    </div>
                    <div className="section_third flex flex-col r_left_margin">
                      <div>
                        <h4 className="blue r_top_margin">{this.api.trTxt(TrVar.CoachCertificate)}</h4>
                        <h4>
                          {
                            this.state.coach.certificateName === 'Other' ? this.state.coach.certificateOther : this.state.coach.certificateName
                          }
                        </h4>
                      </div>
                      <div>
                        <h4 className="blue top_margin">{this.api.trTxt(TrVar.CoachAreas)}</h4>
                        <h4>
                          {
                            this.state.areas.map((area:string, index) => {
                              const separator = index > 0 ? ', ' : ' '
                              return (
                                separator + area
                              )
                            })
                          }
                        </h4>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>{this.api.trTxt(TrVar.NoAccessToCoachYet)}</div>
                )
              }
            </div>
          </div>

          {
            this.state.coach.id &&
              <>
                  <div className="page_section top_margin">
                      <h2>{this.api.trTxt(TrVar.Philosophy)}</h2>
                      <Text color="gray" className='top_margin whitespace-pre-line'>
                        {
                          this.state.coach.languageDescription.philosophy
                        }
                      </Text>
                  </div>

                  <div className="page_section top_margin">
                      <h2>{this.api.trTxt(TrVar.CoachExpertise)}</h2>
                      <Text color="gray" className='top_margin whitespace-pre-line'>
                        {
                          this.state.coach.languageDescription.coachingExpertise
                        }
                      </Text>
                  </div>

                  <div className="page_section top_margin">
                      <h2>{this.api.trTxt(TrVar.WorkExperience)}</h2>
                      <Text color="gray" className='top_margin whitespace-pre-line'>
                        {
                          this.state.coach.languageDescription.workExperience
                        }
                      </Text>
                  </div>

                  <div className="page_section top_margin">
                      <h2>{this.api.trTxt(TrVar.CoachDescription)}</h2>
                      <Text color="gray" className='top_margin whitespace-pre-line'>
                        {
                          this.state.coach.languageDescription.description
                        }
                      </Text>
                  </div>
              </>
          }

          {
            this.state.loaded &&
              <div className="page_section top_margin">
                  <CoachAgreement hasCoach={!!this.state.coach.id} chatCoach={this.state.client.package.isChatCoachIncluded}/>
              </div>
          }

        </div>
      </>
    )
  }
}
