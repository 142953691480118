import React from "react";
import Api from "../services/Api";
import {MeetingInterface} from "../interfaces/Meeting";
import {formatSoon} from "../helpers/dateFunctions";
import {ChatInterface} from "../interfaces/Chat";
import { TrVar } from "../services/translate";

interface ComponentProps {
  meetingKey: string
  close: Function
}

interface ComponentState {
  meeting: MeetingInterface
  chats: Array<ChatInterface>
  otherName: string
}

export default class MeetingHistory extends React.Component<ComponentProps, ComponentState> {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    meeting: {} as MeetingInterface,
    chats: [] as Array<ChatInterface>,
    otherName: '?'
  }

  componentDidMount() {
    this.getMeetingHistory()
  }

  getMeetingHistory = () => {
    const url = 'chat/get/' + this.props.meetingKey
    this.api.get(url).then(response => {
      this.setState({
        meeting: response.json.meeting,
        chats: response.json.chats,
        otherName: response.json.otherName
      })
    })
  }

  render() {
    return (
      <div className="page_content">
        <div className="page_section">
          <p className="underline cursor-pointer" onClick={() => this.props.close()}>{this.api.trTxt(TrVar.Back)}</p>
          <h2 className="top_margin">{this.api.trTxt(TrVar.Meeting)} {formatSoon(this.state.meeting.start, this.api.lang)}</h2>

          {
            this.state.chats.length === 0 &&
              <div className="top_margin">{this.api.trTxt(TrVar.ThereIsNoSavedConversation)}</div>
          }

          <div className="scroll_y top_margin" style={{maxWidth: "500px"}}>
            {
              this.state.chats.map((chat: ChatInterface, index) => {
                const messages = chat.message.split(/\n/)
                const leftRight = chat.sender === 'you' ? 'text_right' : 'text_left'
                const name = chat.sender === 'you' ? this.api.trTxt(TrVar.You) : this.state.otherName
                return (
                  <div className={"top_margin " + leftRight} key={"chat_bubble_" + index}>
                    <p className="font_smaller gray">{name}: {formatSoon(chat.createdAt, this.api.lang)}</p>
                    <div key={"chat_" + index} className={"chat_msg " + chat.sender}>

                      {
                        messages.map((message, idx) => {
                          return (
                            <div key={"mess_" + index + "_" + idx}>{message}</div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>          
        </div>
      </div>
    )
  }
}
