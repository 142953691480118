import React from "react";
import {IonModal} from "@ionic/react";
import Api from "../../services/Api";
import {
  dayOfWeek,
  formatDate,
  formatHourMinute, formatSoon,
  getMonthLong,
  getWeekdayLong,
} from "../../helpers/dateFunctions";
import {MeetingInterface, MeetingType, UnbookableType} from "../../interfaces/Meeting";
import Calendar from "../calendar/Calendar";
import {CalendarEventInterface} from "../../interfaces/CalendarEvent";
import { TrVar } from "../../services/translate";
import { changeUrl } from "../../services/Navigation";
import { CONFIG } from "../../constants"
import {InputInterface, InputsInterface} from "../form/interfaces";
import Input from "../form/Input";
import {countInputErrors} from "../form/CheckInput";
import Text from "../Text";
import { FaRegCalendarAlt } from 'react-icons/fa'; // Importing React Icons package for the calendar icon
import './Calendar.css';


interface ComponentProps {
  close: Function
  lastBookableDate?: string,
  onBoarding?: boolean
  fromVideo?: boolean
  rescheduleMeetingId?: number
  progress?: Function
  coachFree?: boolean
  finishSection?: Function
  goToChat?: Function
}

interface ComponentState {
  days: Array<any>
  meetings: Array<MeetingInterface>
  meeting: MeetingInterface
  events: Array<CalendarEventInterface>
  rescheduleDate: string
  changed: boolean
  showHelp: boolean
  showAddMeeting: boolean
  error: string
  customLength: number
  customLengths: Array<number>
  loaded: boolean
  showMeetingIsBooked: boolean
  hasBookedMeeting: boolean
  inputs: InputsInterface
  guestError: boolean
}

export default class CalendarClient extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()
  day = new Date()
  today = new Date()
  times = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

  inputs: InputsInterface = {
    name: {
      type: 'text',
      name: 'name',
      placeholder: this.api.trTxt(TrVar.FirstName),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 0
    },
    email: {
      type: 'email',
      name: 'email',
      placeholder: this.api.trTxt(TrVar.Email),
      value: '',
      error: '',
      maxLength: 64,
      minLength: 0,
    },
  }

  state = {
    days: [],
    meetings: [] as Array<MeetingInterface>,
    meeting: {} as MeetingInterface,
    events: [],
    rescheduleDate: '',
    changed: false,
    showHelp: false,
    showAddMeeting: false,
    error: '',
    customLength: 0,
    customLengths: [],
    showMeetingIsBooked: false,
    hasBookedMeeting: false,
    loaded: false,
    inputs: this.inputs,
    guestError: false,
  }

  componentDidMount() {
    this.getEvents()
  }

  getEvents = () => {
    const rescheduleMeetingId = this.props.rescheduleMeetingId ? this.props.rescheduleMeetingId : 0
    const url = 'client_meeting_block?custom_length=' + this.state.customLength + '&reschedule_meeting_id=' + rescheduleMeetingId
    this.api.get(url).then(response => {
      this.parseData(response.json)
    })
  }

  parseData = (responseJson: any) => {
    const events: Array<CalendarEventInterface> = []
    if (responseJson.meetings) {
      for (const m of responseJson.meetings as Array<MeetingInterface>) {
        m.date = new Date(m.start)
        if (m.id && this.props.rescheduleMeetingId === m.id) {
          this.setState({rescheduleDate: m.start})
        }
        let eventClass: string
        let eventName: string
        if (m.unbookable === UnbookableType.HARD) {
          eventClass = 'bg_red'
          if (m.booked) {
            eventName = this.api.trTxt(TrVar.Meeting)
          } else {
            eventName = this.api.trTxt(TrVar.UnBookable)
          }
        } else if (m.booked) {
          eventName = this.api.trTxt(TrVar.Booked)
          eventClass = 'bg_purple'
        } else {
          eventName = this.api.trTxt(TrVar.Book)
          eventClass = 'bg_green color_black'
        }
        events.push({
          id: m.id,
          d: m.date,
          date: m.start,
          name: eventName,
          class: eventClass,
          lastName: '',
          length: m.length,
          url: '',
          type: 'meeting',
          parentEvent: m,
        })
      }
    }

    this.setState({
      meetings: responseJson.meetings,
      customLengths: responseJson.customLengths,
      customLength: responseJson.meetingLength,
      events: events,
      loaded: true
    })
  }

  inputChange = (input: InputInterface) => {
    const guestError = countInputErrors(this.inputs) > 0
    this.setState({ inputs: this.inputs, guestError: guestError })
  }

  bookMeeting = () => {
    let email = ''
    let name = ''
    if (this.inputs.email.value.length > 0 || this.inputs.name.value.length > 0) {
      if  (this.inputs.email.value.length === 0 || this.inputs.name.value.length === 0) {
        alert('Please enter name and email. Or leave both fields empty to not invite guest.')
        return false
      } else {
        email = this.inputs.email.value
        name = this.inputs.name.value
      }
    }
    const data = {
      start : this.state.meeting.start,
      length: this.state.meeting.length,
      meetingId: this.props.rescheduleMeetingId ? this.props.rescheduleMeetingId : 0,
      email: email,
      name: name
    }

    this.api.post('client_meeting_block', data).then(_response => {
      if (this.props.onBoarding) {
        const meeting = this.state.meeting
        meeting.booked = true
        const events: Array<CalendarEventInterface> = this.state.events
        for (const ev of events) {
          if (ev.d === meeting.date) {
            ev.name = this.api.trTxt(TrVar.Booked)
            ev.class = 'bg_purple'
          }
        }
        this.setState({
          showMeetingIsBooked: true,
          hasBookedMeeting: true,
          meeting: meeting,
          events: events
        })
      } else {
        this.setState({showAddMeeting: false})
        setTimeout(() => {
          this.props.close(true)
        }, 300)
      }
    })
  }

  showBookMeetingText = (meeting: MeetingInterface) => {
    let txt: string
    if (meeting.meetingType === MeetingType.CLIENT_CANCELLED_LATE) {
      txt = this.api.trTxt(TrVar.YouCancelledTheMeetingLate)
    } else if (this.state.meeting.date < this.today) {
      txt = this.api.trTxt(TrVar.WeHopeTheMeetingWentWell)
    } else {
      txt = this.api.trTxt(TrVar.TheMeetingIsBooked)
    }
    return (
      <>{txt}</>
    )
  }

  closeAddMeetingFromOnboarding = () => {
    this.setState({showAddMeeting: false})
    setTimeout(() => {
      this.onboardingProgress(3)
    }, 300)
  }

  showAddMeeting = () => {

    if (this.state.showMeetingIsBooked) {
      return(
        <div className="m-4 color_white">
          <div className="flex justify-between items-center">
            <h4 className="text_center">&nbsp;</h4>
          </div>

          <p className="mt-4 text_center bold">&nbsp;</p>

          <p className="mt-8 text_center">
            {this.api.trTxt(TrVar.YourMeetingIsBooked)}
          </p>

          <div className="text_center">
            <div className="mt-8 button inline_block" onClick={() => this.closeAddMeetingFromOnboarding()}>
              {this.api.trTxt(TrVar.GoForward)}
            </div>
          </div>
          <div className="text_center">
            <div className="mt-4 button white inline_block" onClick={() => this.setState({
              showAddMeeting: false,
              showMeetingIsBooked: false,
            })}>
              {this.api.trTxt(TrVar.BookMoreMeetings)}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="m-4 color_white">
        <div className="flex justify-between items-center">
          <h4 className="text_center">&nbsp;</h4>
          <img alt="close" className="icon_small close_div" src="/assets/icon/white/cross.svg"
               onClick={() => this.setState({showAddMeeting: false})}/>
        </div>

        <p className="mt-4 text_center bold">
          {getWeekdayLong(dayOfWeek(this.state.meeting?.date), this.api.lang)}&nbsp;
          {this.state.meeting?.date?.getDate()}&nbsp;
          {getMonthLong(this.state.meeting?.date?.getMonth(), this.api.lang)}
        </p>

        <p className="mt-8 text_center">
          {formatHourMinute(this.state.meeting.start)}, {this.state.meeting.length} {this.api.trTxt(TrVar.Minutes).toLowerCase()}
        </p>

        {
          (this.state.meeting.unbookable !== UnbookableType.NONE && this.state.meeting.unbookableReason) &&
          <div className="mt-8 text_center whitespace-pre-line p-4 bg_red">
            {this.state.meeting.unbookableReason}
          </div>
        }
        {
          this.state.meeting.booked &&
          <p className="mt-8 text_center whitespace-pre-line border_box">
            {this.showBookMeetingText(this.state.meeting)}
          </p>
        }

        {
          (!this.state.meeting.booked && this.state.meeting.unbookable !== UnbookableType.HARD) &&
            <div className="text_center">
              {
                this.state.rescheduleDate.length > 0 &&
                  <div className="mt-8 text_center red">
                    {this.api.trTxt(TrVar.Reschedule)} {this.api.trTxt(TrVar.From).toLowerCase()}&nbsp;
                    {formatDate(this.state.rescheduleDate, true, this.api.lang)}
                  </div>
              }
              {
                ((CONFIG.SYSTEM === 'develop' || CONFIG.SYSTEM === 'stage') && !this.props.rescheduleMeetingId) &&
                <div className="mt-8">
                    <p className="bold text_center">Bjud in en gäst</p>
                    <div className="mt-4">
                        <Input data="name-input" input={this.inputs.name} update={this.inputChange} classes="page_input" language={this.api.lang} />
                    </div>
                    <div className="mt-4">
                        <Input data="name-input" input={this.inputs.email} update={this.inputChange} classes="page_input" language={this.api.lang} />
                    </div>
                </div>
              }
              <div className="mt-8 button inline_block" onClick={() => this.bookMeeting()}>
                {this.props.rescheduleMeetingId ? this.api.trTxt(TrVar.Reschedule): this.api.trTxt(TrVar.BookMeeting)}
              </div>
            </div>
        }
      </div>
    )
  }

  setLength = (meetingLength: number) => {
    this.setState({customLength: meetingLength}, () => {
      this.getEvents()
    })
  }

  clickDate = (d: Date) => {
    console.log('Clicked date ' + d)
  }

  clickEvent = (e: any) => {
    if (e.parentEvent) {
      this.setState({meeting: e.parentEvent, showAddMeeting: true})
    }
  }

  onboardingProgress = (sub: number) => {
    if (this.props.progress && this.props.finishSection) {
      this.props.progress(sub)     
      this.props.finishSection()
    }
  }



  render() {
      const showCustomTimes = (this.state.customLengths.length > 0 && !this.props.rescheduleMeetingId)
      const showReschedule = this.props.rescheduleMeetingId
      // console.log(window.innerWidth)
      let paddingBottom = 216
      let subtractHeight = window.innerWidth < 900 ? 170 : 112
      if (showCustomTimes || showReschedule) {
        paddingBottom += 30
      }
      if (this.props.onBoarding) {
        subtractHeight += 140
        paddingBottom += 60
      }
      if (this.props.fromVideo) {
        paddingBottom += 40
        subtractHeight -= 96
      }
      const pageHeight = "calc(100vh - " + subtractHeight + "px)"

    return (
      <>
        <IonModal isOpen={this.state.showAddMeeting} className="modal_small modal_backdrop"
          onDidDismiss={() => this.setState({ showAddMeeting: false })}>
          {
            this.showAddMeeting()
          }
        </IonModal>

        {this.state.events.length === 0 && 
          <div className="page_section flex items-center justify-center" style={{ height: pageHeight }}>
            <div className="flex flex-col items-center justify-center h-full w-full" style={{ maxWidth: "450px" }}>

              {/* Wrapper for center-aligned items */}
              <div id="centeredItemsWrapper">
                {/* Row 1: Calendar Icon */}
                <div className="flex items-center  justify-center mb-4">
                  <FaRegCalendarAlt size={50} />
                </div>

                {/* Row 2: Text */}
                <div className="flex items-center  justify-center">
                  <Text element="h2" className="text_center mb-4">
                    {this.api.trTxt(TrVar.NoAvailableTimesBooking)}
                  </Text>
                </div>

                <div className="flex items-center  justify-center">
                  <Text color="gray" className="text_center mb-4">
                    {this.api.trTxt(TrVar.CoachHasNoAvailableTimes)}
                  </Text>
                </div>
              </div>

              {/* Row 3: Button */}
              <div className="flex items-center" id="calendarEmptyNextBtn">
                <div className={"mt-4 button bg_primary_500 "}
                  onClick={() => {
                    if (this.props.goToChat && this.props.onBoarding) {
                      this.props.goToChat();  
                    } else {
                      changeUrl('/app/client/chat')
                    }
                  }}>
                  Till chatt
                </div>
              </div>

            </div>
          </div>
        }


        {this.state.events.length > 0 && <div>
          <div className="page_section" style={{ height: pageHeight, paddingBottom: paddingBottom + "px" }}>
            {
              this.props.onBoarding &&
              <h3 className="text_center mb-4">{this.api.trTxt(TrVar.BookAMeetingWithYourCoach)}</h3>
            }
            {
              this.props.fromVideo &&
              <div className="text_right mobile_mt-12">
                <img alt="close" onClick={() => this.props.close()} className="icon_medium cursor-pointer"
                  src="/assets/icon/white/close-circle.svg" />
              </div>
            }
            {
              showCustomTimes &&
              <div className="flex items-center justify-center mb-4">
                <div className="bold white">{this.api.trTxt(TrVar.MeetingLength)}</div>
                {
                  this.state.customLengths.map(meetingLength => {
                    const mClass = meetingLength === this.state.customLength ?
                      "circle_medium bg_green color_black flex justify-center items-center" :
                      "underline white"
                    return (
                      <div key={"length_" + meetingLength} className={"mx-4 cursor-pointer " + mClass}
                        onClick={() => this.setLength(meetingLength)}>
                        {meetingLength}
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              showReschedule &&
              <div className="flex items-center justify-center mb-4">
                {this.api.trTxt(TrVar.RescheduleMeeting)} {formatSoon(this.state.rescheduleDate, this.api.lang)}
              </div>
            }
            {
              (this.state.loaded) ?
                <Calendar events={this.state.events} clickDate={this.clickDate} clickEvent={this.clickEvent} />
                :
                <></>
            }
          </div>
          {
          this.props.onBoarding ?
            <div className="text_center my-4" style={{ maxWidth: '1296px' }}>
              <div className="flex justify-center">
              <div className={"mt-4 button bg_primary_500 "}
                  onClick={() => this.onboardingProgress(3)}>
                  {this.api.trTxt(TrVar.GoForward)}
                </div>             
              </div>
            </div>
            :
              <div className="flex justify-center top_margin" style={{ maxWidth: '1296px' }}>
                <p onClick={() => changeUrl('/app/client/chat')} className="underline cursor-pointer">
                  {this.api.trTxt(TrVar.ChatWithYourCoach)}&nbsp;
                </p>
                <p>
                  {this.api.trTxt(TrVar.IfThereAreNoAvailableTimes).toLowerCase()}.
                </p>                
              </div>       
          }
        </div>
        }
      </>
    )
  }
}
