import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import {ChatInterface} from "../../interfaces/Chat";
import ChatWindow from "../ChatWindow";
import {ClientInterface} from "../../interfaces/Client";
import WebSocketClient from "../../services/WebSocketClient";
import Breadcrumbs from "../menu/Breadcrumbs";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import {CoachInterface} from "../../interfaces/Coach";
import { TrVar } from '../../services/translate';


interface ComponentProps {
  client: ClientInterface
  isClientActive?: boolean
  clientHasCoach?: boolean
  close?: Function
}

interface ComponentState {
  coach: CoachInterface
  chats: Array<ChatInterface>
  loaded: boolean
  licenseAllowsChat: boolean
}

export default class CoachChat extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private webSocketClient = WebSocketClient.getInstance()
  chats: Array<ChatInterface> = []

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    chats: [],
    loaded: false,
    licenseAllowsChat: false,
  }

  componentDidMount() {
    this.webSocketClient.webSocket?.addEventListener("message", this.messageListener)
    this.getData()
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'chat'}
    }
    const url = 'coach_log_event'
    this.api.post(url, data).then()
  }

  componentWillUnmount() {
    this.webSocketClient.webSocket?.removeEventListener("message", this.messageListener)
  }

  private getData = () => {
    let url = 'coach_chat/coach/get/' + this.props.client.id

    this.api.get(url).then(response => {
      this.setState({
        coach: response.json.coach,
        chats: response.json.chats,
        licenseAllowsChat: response.json.licenseAllowsChat,
        loaded: true
      })
    })
  }

  messageListener = (event: any) => {
    const mess = JSON.parse(event.data)
    if (mess.type === 'coach') {
      if (mess.subType === 'chat') {
        if (this.props.client) {
          if (this.props.client.id === mess.clientId) {
            this.addChat(mess.chat)
          } else {
            console.log('For client ' + mess.clientId)
          }
        } else {
          this.addChat(mess.chat)
        }
      } else {
        console.log('Not for chat:')
      }
    }
  }

  toDate = (d: string) => {
    return new Date(d)
  }

  addChat = (chat: ChatInterface) => {
    const chats: Array<ChatInterface> = this.state.chats
    chats.push(chat)
    this.setState({chats: chats})
  }

  sendChat = (message: string) => {
    if (message.length > 0) {
      const data = {
        message: message,
        send_to_coach: false,
      }
      let url = 'coach_chat/coach/save/' + this.props.client.id
      this.api.post(url, data).then(response => {
        this.addChat(response.json.chat)
      })
    }
  }

  close = () => {
    if (this.props.close) {
      this.props.close()
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }
    let name = this.api.trTxt(TrVar.ChatWith) + ' ' + this.props.client.firstName
    let breadcrumbs = [{name: this.api.trTxt(TrVar.Chat), link: '', linkFunc: this.props.close}]

    const sendEnabled = this.props.isClientActive === true

    return (
      <div className="page_content">
        <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>

        <div className="page_section r-flex justify-between">
          {
            (!this.state.licenseAllowsChat || !this.props.isClientActive) &&
            <div className="pl-8">
                <h2>{this.api.trTxt(TrVar.YouCannotChatWithClientAnymore)}</h2>
                <p className="py-4">
                  {
                    this.api.trTxt(TrVar.YouCanStillReadMessages)
                  }
                </p>
            </div>
          }

          <ChatWindow chatMessages={this.state.chats} onChat={this.sendChat} standalone={true} chatWithZebrain={false}
                      isSendEnabled={sendEnabled}/>
        </div>

      </div>
    )
  }
}
