import React from 'react';
import {InputInterface} from './interfaces'
import {checkInput} from './CheckInput'
import { IonCol, IonRow } from "@ionic/react";
import './Input.css';

interface ComponentProps {
  input: InputInterface
  update: Function
  classes: string
  errorClasses?: string
  language: string
  data: string
}

interface ComponentState {}

export default class Input extends React.Component<ComponentProps, ComponentState> {

  handleChange = (e: any, doCheck: boolean) => {
    this.props.input.value = e.target.value
    if (doCheck || this.props.input.error.length > 0) {
      checkInput(this.props.input, this.props.language)
    }
    this.props.update(this.props.input)
  }

  handleBlur = (e: any) => {
    checkInput(this.props.input, this.props.language)
    this.props.update(this.props.input)
  }

  handleRadioChange = (value: any) => {
    if (this.props.input.options) {
      for (let option of this.props.input.options) {
        option.selected = false;
        if (option.value === value) {
          option.selected = !option.selected
        }
      }
    }
    this.props.input.error = checkInput(this.props.input, this.props.language)
    this.props.update(this.props.input)
  }

  handleMultipleChange = (value: string, readonly?: boolean) => {
    if (readonly) {
      alert('This value is preset and cannot be changed');
      return
    }
    if (this.props.input.options) {
      for (let option of this.props.input.options) {
        if (option.value === value) {
          option.selected = !option.selected
        }
      }
    }
    this.props.input.error = checkInput(this.props.input, this.props.language)
    this.props.update(this.props.input)
  }

  toggleClassOn = (value: string) => {
    if (this.props.input.options) {
      for (let option of this.props.input.options) {
        if (option.value === value) {
          let getInfo = document.getElementById(value);
          getInfo?.classList.toggle('display_none');
        }
      }
    }
  }

  toggleClassOff = (value: string) => {
    if (this.props.input.options) {
      for (let option of this.props.input.options) {
        if (option.value === value) {
          let getInfo = document.getElementById(value);
          getInfo?.classList.add('display_none');
        }
      }
    }
  }

  renderElement = () => {
    if (this.props.input.type === 'textarea') {
      return (
        <div className="flex flex-col">
        {this.props.input.label &&
          <label style={{ textAlign: 'left', marginBottom: '5px' }} htmlFor={this.props.input.name}>{this.props.input.label}</label>
        }
          <textarea data-cy={this.props.data} className={this.props.classes} name={this.props.input.name}
                    maxLength={this.props.input.maxLength}
                    value={this.props.input.value} placeholder={this.props.input.placeholder}
                    onBlur={(e) => this.handleBlur(e)}
                    onChange={(e) => this.handleChange(e, false)} />          
        </div>
      )
    } else if (this.props.input.type === 'selectmultiple') {
      return (
        <IonRow>
          {
            this.props.input.options?.map(option => {
              let selectBox = 'select_box'
              if (option.selected) {
                selectBox += " selected"
              }
              let readonlyClass = option.readonly ? 'opacity_05': ''
              return (
                <IonCol key={this.props.input.name + '_' + option.value}
                        onMouseLeave={() => this.toggleClassOff(option.value)} 
                        className={this.props.classes + " cursor-pointer flex"} size="12" sizeMd="6">
                  {
                    option.moreInfo &&
                    <div className='flex-shrink-0' onClick={() => this.toggleClassOn(option.value)}>
                        <img alt="arrow down" className="icon_small svg_blue right_margin" src={"/assets/icon/arrow_down.svg"} />
                        <div id={option.value} className={'display_none input-description '}>{option.moreInfo}</div>
                    </div>  
                  }
                  <div onClick={() => this.handleMultipleChange(option.value, option.readonly)}
                       className={selectBox + ' ' + readonlyClass}/>
                  <div onClick={() => this.handleMultipleChange(option.value, option.readonly)}
                       className={"left_margin inline_block " + readonlyClass}>{option.name}</div>
                </IonCol>
              )
            })
          }
        </IonRow>
      )
    } else if (this.props.input.type === 'radioselect') {
      return (
        <>
          {
            this.props.input.options?.map(option => {
              let selectBox = ' radioselect_box'
              if (option.selected) {
                selectBox += " selected"
              }
              return (
                <div key={this.props.input.name + '_' + option.value}
                        className={this.props.classes + " cursor-pointer inline_block mr-8"}
                        onClick={() => this.handleRadioChange(option.value)}>
                  <div className={selectBox}/>
                  <div className="left_margin inline_block">{option.name}</div>
                </div>
              )
            })
          }
        </>
      )
    } else if (this.props.input.type === 'select') {
      return (
        <select data-cy={this.props.data} className={this.props.classes}
                onChange={(e) => this.handleChange(e, true)}
                onBlur={(e) => this.handleBlur(e)}
                value={this.props.input.value}>
          <option value=''>{this.props.input.placeholder}</option>
        {
          this.props.input.options?.map(option => {
            return (
              <option key={this.props.input.name + '_' + option.value} value={option.value}>
                {option.name}
              </option>
            )
          })
        }
        </select>
      )
    } else if (this.props.input.type === 'date') {
      return (
        <input className={this.props.classes} type={this.props.input.type} name={this.props.input.name}
             data-cy={this.props.data}
             min={this.props.input.minDate}
             max={this.props.input.maxDate}
             value={this.props.input.value} placeholder={this.props.input.placeholder}
             onBlur={(e) => this.handleBlur(e)}
             onChange={(e) => this.handleChange(e, false)} />
     )
    }
    return (
      <div className='flex flex-col'>
        {this.props.input.label &&
          <label style={{ textAlign: 'left', marginBottom: '5px' }} htmlFor={this.props.input.name}>{this.props.input.label}</label>
        }
        <input className={this.props.classes} type={this.props.input.type} name={this.props.input.name}
              maxLength={this.props.input.maxLength}
              data-cy={this.props.data}
              min={this.props.input.minValue}
              max={this.props.input.maxValue}
              value={this.props.input.value} placeholder={this.props.input.placeholder}
              onBlur={(e) => this.handleBlur(e)}
              onChange={(e) => this.handleChange(e, false)} />      
      </div>

    )

  }

  render() {
    return (
      <>
        {this.renderElement()}
        {
          this.props.input.error.length > 0 &&
          <div className={"top_margin error_red " + (this.props.errorClasses)}>
            {this.props.input.error}
          </div>
        }
      </>
    )
  }
}
