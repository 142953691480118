import React from "react";
import './Disa.css';

interface ComponentProps {
  disa: Array<number>
}

interface ComponentState {

}

class Disa extends React.Component<ComponentProps, ComponentState> {

  render() {
    return (
      <div style={{padding: "0 16px", width: "44vw"}}>
        <h3 className="text_center mt-4">DISA</h3>
        <div className="disa_name mt-4">Drivande</div>
        <div className="disa_bar">
          <div style={{height: "12px", display: "inline-block", backgroundColor: "#c00", width: this.props.disa[0] + '%'}}></div>
          <div className="disa_value">{this.props.disa[0].toFixed(1)}</div>
        </div>


        <div className="disa_name">Inspirerande</div>
        <div className="disa_bar">
          <div style={{height: "12px", display: "inline-block", backgroundColor: "#ed0", width: this.props.disa[1] + '%'}}></div>
          <div className="disa_value">{this.props.disa[1].toFixed(1)}</div>
        </div>


        <div className="disa_name">Stödjande</div>
        <div className="disa_bar">
          <div style={{height: "12px", display: "inline-block", backgroundColor: "#0d0", width: this.props.disa[2] + '%'}}></div>
          <div className="disa_value">{this.props.disa[2].toFixed(1)}</div>
        </div>

        <div className="disa_name">Analytisk</div>
        <div className="disa_bar">
          <div style={{height: "12px", display: "inline-block", backgroundColor: "#00f", width: this.props.disa[3] + '%'}}></div>
          <div className="disa_value">{this.props.disa[3].toFixed(1)}</div>
        </div>
      </div>
    )
  }
}

export default Disa;
