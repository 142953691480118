import React from 'react'
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  lang: string
  calendarFeedUrl: string
}

interface ComponentState {
  showSyncWhat: string
}

export default class CalendarSynk extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    showSyncWhat: ''
  }

  render() {
    return (
      <div>
        <h3>
          {this.api.trTxt(TrVar.YouCanSynchroniseYourAvailableTimes)}
        </h3>
        <p className="top_margin">
          {this.api.trTxt(TrVar.ChooseYourCalendarBelow)}
        </p>
        <div className="top_margin flex">
          <div className="cursor-pointer underline"
            onClick={() => this.setState({ showSyncWhat: 'gmail' })}>{this.api.trTxt(TrVar.Gmail)}</div>
          <div className="cursor-pointer underline ml-4"
            onClick={() => this.setState({ showSyncWhat: 'outlook' })}>{this.api.trTxt(TrVar.Outlook)}</div>
          <div className="cursor-pointer underline ml-4"
            onClick={() => this.setState({ showSyncWhat: 'mac' })}>{this.api.trTxt(TrVar.MacCalendar)}</div>
        </div>
        {
          this.state.showSyncWhat === 'gmail' &&
            <div className="max_width top_margin">
                <h2>{this.api.trTxt(TrVar.Gmail)}</h2>
                <p className="top_margin">
                  1. {this.api.trTxt(TrVar.CopyTheUrlBelow)}
                </p>
                <div className="top_margin flex">
                    <div className="right_margin cursor-pointer flex-shrink-0"
                         onClick={() => navigator.clipboard.writeText(this.props.calendarFeedUrl)}>
                        <img alt="copy" className="icon_small"
                             src="/assets/icon/white/copy-outline.svg"/>
                    </div>
                    <p>{this.props.calendarFeedUrl}</p>
                </div>

                <div className="top_margin">
                    <ol>
                        <li>
                          {this.api.trTxt(TrVar.GoToSettingsMenu)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.Settings)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.AddCalendar)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.FromUrl)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.PasteTheCopiedUrl)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.ClickAddCalendar)}
                        </li>
                    </ol>
                </div>
            </div>
        }
        {
          this.state.showSyncWhat === 'outlook' &&
            <div className="max_width top_margin">
                <h2>{this.api.trTxt(TrVar.Outlook)}</h2>
                <p className="top_margin">
                    1. {this.api.trTxt(TrVar.CopyTheUrlBelow)}
                </p>
                <div className="top_margin flex">
                    <div className="right_margin cursor-pointer flex-shrink-0"
                         onClick={() => navigator.clipboard.writeText(this.props.calendarFeedUrl)}>
                        <img alt="copy" className="icon_small"
                             src="/assets/icon/white/copy-outline.svg"/>
                    </div>
                    <p>{this.props.calendarFeedUrl}</p>
                </div>

                <div className="top_margin">
                    <ol>
                        <li>
                          {this.api.trTxt(TrVar.AddCalendar)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.SubscribeFromWeb)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.PasteTheCopiedUrl)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.ClickOnImport)}
                        </li>
                    </ol>
                </div>
            </div>
        }
        {
          this.state.showSyncWhat === 'mac' &&
            <div className="max_width top_margin">
                <h2>{this.api.trTxt(TrVar.MacCalendar)}</h2>
                <p className="top_margin">
                    1. {this.api.trTxt(TrVar.CopyTheUrlBelow)}
                </p>
                <div className="top_margin flex">
                    <div className="right_margin cursor-pointer flex-shrink-0"
                         onClick={() => navigator.clipboard.writeText(this.props.calendarFeedUrl)}>
                        <img alt="copy" className="icon_small"
                             src="/assets/icon/white/copy-outline.svg"/>
                    </div>
                    <p>{this.props.calendarFeedUrl}</p>
                </div>

                <div className="top_margin">
                    <ol>
                        <li>
                          {this.api.trTxt(TrVar.Archive)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.NewCalendarSubscription)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.PasteTheCopiedUrl)}
                        </li>
                        <li>
                          {this.api.trTxt(TrVar.ClickSubscribe)}
                        </li>
                    </ol>
                </div>
            </div>
        }
      </div>
    )
  }
}
