import React from 'react';
import { CoachInterface } from '../../interfaces/Coach';
import Api from '../../services/Api';

interface ComponentProps {
  selectedCoach: CoachInterface
  progress: Function
}

const EditCoachOutro = (props: ComponentProps) => {
  const api = Api.getInstance();

  return (
    <div style={{height: '720px' }} className='page_section  flex flex-col justify-center'>
      <div className="coach_select_section">
        <img style={{ borderRadius: '50%', margin: '0 auto' }} height={'300px'} width={'300px'} src={props.selectedCoach.picture} alt="img of your coach" />
      </div>
      <div style={{ paddingTop: '40px', margin: '0 auto' }} className="text_center">
        {api.lang === 'sv' ?
          <>
            <h1>Bra val!</h1>
            <h1>
              {props.selectedCoach.firstName} passar dig utmärkt.
            </h1>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress(4)} className="button green mobile_size_btn inline_block">
                Nästa
              </div>
            </div>
          </>
          :
          <>
            <h1>Good choice!</h1>
            <h1>
              {props.selectedCoach.firstName} is great for you.
            </h1>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress(4)} className="button green mobile_size_btn inline_block">
                Next
              </div>
            </div>
          </>}
      </div>
    </div>
  )
}

export default EditCoachOutro;
