import {
  IonModal,
  IonRange,
} from '@ionic/react'
import React from 'react'
import Api from "../../services/Api"
import {changeUrl} from "../../services/Navigation"
import {MeasureArea} from "../../interfaces/MeasureArea";
import Breadcrumbs from "../menu/Breadcrumbs";
import Loading from "../Loading";
import './ClientMeasure.css';
import {MeasureQuestion} from "../../interfaces/MeasureQuestion";
import BarChart from '../graphics/BarChart';
import { TrVar } from "../../services/translate"

interface ComponentProps{
  measureId: number
  measureDone: Function
  first?: boolean
  onboarding?: boolean
  scrollToTop?: Function
  progress?: Function
  coachFree?: boolean
}

interface ComponentState {
  areas: Array<MeasureArea>
  selfInsight: MeasureArea
  basicEnergy: MeasureArea
  motivation: MeasureArea
  selfLeadership: MeasureArea
  loaded: boolean
  section: number
  confirmBadInput: string
  runCalculating: boolean
  calculatingText: string
}

enum SECTION {
  INSIGHT,
  ENERGY,
  MOTIVATION,
  LEADERSHIP,
}

export default class ClientMeasure extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    areas: [],
    selfInsight: {} as MeasureArea,
    basicEnergy: {} as MeasureArea,
    motivation: {} as MeasureArea,
    selfLeadership: {} as MeasureArea,
    loaded: false,
    section: SECTION.INSIGHT,
    confirmBadInput: '',
    runCalculating: false,
    calculatingText: ''
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    this.api.get('measure/get/' + this.props.measureId).then(response => {
      this.setState({
        areas: response.json.areas,
        selfInsight: response.json.areas[0],
        basicEnergy: response.json.areas[1],
        motivation: response.json.areas[2],
        selfLeadership: response.json.areas[3],
        loaded: true,
      })
    })
  }

  private changeQuestion = (event: any, id: number, current: boolean) => {
    const value = event.detail.value as number
    if (isNaN(value)) {
      return
    }
    const areas = this.state.areas
    for (const area of areas) {
      for (const question of area.questions) {
        if (question.id === id) {
          if (current) {
            question.valueCurrent = value
          } else {
            question.valueDesired = value
          }
        }
      }
    }
    this.setState({areas: areas});
  }

  private save = () => {
    setTimeout(() => {
      this.startCalculatingText()
      this.setState({
        runCalculating: true,
        calculatingText: this.api.trTxt(TrVar.AnalyzingResult)
      }
      )
    }, 25)
    
    const data = {
      areas: this.state.areas
    }

    this.api.post('measure/save/' + this.props.measureId, data).then(response => {
      if (this.props.onboarding) {
        setTimeout(() => this.props.measureDone(response.json.id), 3500)
        setTimeout(() => this.onboardingProgress(3), 4500)
      } else {
        setTimeout(() => {
          changeUrl('/app/client/measure/result/' + response.json.id)
        }, 4500)

      }
    })
  }

  startCalculatingText = () => {
    let i = 0
    const texts = [
      this.api.trTxt(TrVar.CalculatingDevelopmentAreas),
      this.api.trTxt(TrVar.CalculatingExercises),
    ]
    const calcInterval = setInterval(() => {
      this.setState({calculatingText: texts[i]})
      i++
      if (i > 1) {
        clearInterval(calcInterval)
      }
    }, 1500)
  }

  onboardingProgress = (sub: number) => {  
    if (this.props.progress) {
      this.props.progress(sub)     
    }
  }  
          
  questionIntro = () => {
    return (
      <>
        <div className="top_margin">
          {this.api.trTxt(TrVar.CurrentState)}: {this.api.trTxt(TrVar.HowDoYouExperienceYourSituation)}
        </div>
        <div>
          {this.api.trTxt(TrVar.DesiredState)}: {this.api.trTxt(TrVar.SituationAfterCoaching)}
        </div>    
      </>
    )
  }

  questionSection = (section: number, question: MeasureQuestion, qidx: number) => {
    let className: string
    if (section === 0) {
      className = 'insight_slider'
    } else if (section === 1) {
      className = 'energy_slider'
    } else if (section === 2) {
      className = 'motivation_slider'
    } else {
      className = 'leadership_slider'
    }
      return (
        <div key={"question_" + qidx} className="bottom_margin page_section r_top_margin">
          <h3>{question.question}</h3>
          <div className="text_center font_small top_margin"><b>{this.api.trTxt(TrVar.CurrentState)}</b></div>
          <div className="flex">
            <div className="font_small hide_small my-auto right_margin">{this.api.trTxt(TrVar.Incorrect)}</div>
            <IonRange pin={true} style={{ padding: "0" }} className={className} value={question.valueCurrent}
              onIonChange={(e: Event) => this.changeQuestion(e, question.id, true)} />
            <div className="font_small hide_small my-auto left_margin">{this.api.trTxt(TrVar.Correct)}</div> 
          </div>
          <div className="show_small">
            <div className="flex justify-between">
              <div className="font_small my-auto">{this.api.trTxt(TrVar.Incorrect)}</div>
              <div className="font_small  my-auto">{this.api.trTxt(TrVar.Correct)}</div>
            </div>
          </div>
          <div className="text_center font_small top_margin"><b>{this.api.trTxt(TrVar.DesiredState)}</b></div>
          <div className="flex">
            <div className="font_small hide_small my-auto right_margin">{this.api.trTxt(TrVar.Incorrect)}</div>
            <IonRange pin={true} style={{ padding: "0" }} className={className} value={question.valueDesired}
              onIonChange={(e: any) => this.changeQuestion(e, question.id, false)}/>
            <div className="font_small hide_small my-auto left_margin">{this.api.trTxt(TrVar.Correct)}</div>
          </div>
          <div className="show_small">
            <div className="flex justify-between">
              <div className="font_small my-auto">{this.api.trTxt(TrVar.Incorrect)}</div>
              <div className="font_small my-auto">{this.api.trTxt(TrVar.Correct)}</div>
            </div>
          </div>
        </div>
      )    
  }
  
  progressbar = (section: number) => {
    return (
      <div style={{ 'maxWidth' : '1296px'}}>
        <div className="measure_progress_bar text_center bottom_margin">
          <div className="flex justify-center min-w-[400px]">
            <div className="measure_progress insight">1</div>
            <div className="my-auto">
              <hr className="measure_progress_bar_divider" />
            </div>
            <div className={'measure_progress ' + (section > 0 ? 'energy' : '')}>2</div>
            <div className="my-auto">
              <hr className="measure_progress_bar_divider" />
            </div>
            <div className={'measure_progress ' + (section > 1 ? 'motivation' : '')}>3</div>
            <div className="my-auto">
              <hr className="measure_progress_bar_divider" />
            </div>
            <div className={'measure_progress ' + (section > 2 ? 'leadership' : '')}>4</div>
          </div>
          <div className="flex measure_progress_text justify-between bottom_margin ">
            <div className="font_smaller">{this.api.trTxt(TrVar.SelfInsight)}</div>
            <div className="font_smaller">{this.api.trTxt(TrVar.BasicEnergy)}</div>
            <div className="font_smaller">{this.api.trTxt(TrVar.Motivation)}</div>
            <div className="font_smaller">{this.api.trTxt(TrVar.SelfLeadership)}</div>
          </div>
        </div>        
      </div>

    )
  }

  nextSection = () => {
    if (this.state.section >= (this.state.areas.length - 1)) {
      this.setState({confirmBadInput: ''})
      this.save()
    } else {
      const closeBadInputFirst = this.state.confirmBadInput.length > 0
      if (closeBadInputFirst) {
        setTimeout(() => {
          if (this.props.scrollToTop) {
            this.props.scrollToTop()
          }
        }, 400)
      }
      this.setState({section: this.state.section + 1, confirmBadInput: ''})
      if (!closeBadInputFirst) {
        if (this.props.scrollToTop) {
          this.props.scrollToTop()
        }
      }
    }
  }

  checkNextPage = () => {
    const area = this.state.areas[this.state.section]
    let changedCurrent = 0
    let changedDesired = 0
    area.questions.forEach((q: any) => {
      if (q.valueCurrent !== 50) {
        changedCurrent++
      }
      if (q.valueDesired !== 50) {
        changedDesired++
      }
    })

    let badInput = ''

    if (changedCurrent === 0 && changedDesired === 0) {
      badInput = this.api.trTxt(TrVar.YouHaveNotChangedAnyInputs)
    } else if (changedCurrent === 0) {
      badInput = this.api.trTxt(TrVar.YouHaveNotChangedCurrentState)
    } else if (changedDesired === 0) {
      badInput = this.api.trTxt(TrVar.YouHaveNotChangedDesiredState)
    }

    if (badInput.length > 0) {
      badInput += ' ' + this.api.trTxt(TrVar.AreYouSureYouWantToContinue)
    }

    if (badInput.length === 0) {
      this.nextSection()
    } else {
      this.setState({confirmBadInput: badInput})
    }
  }
  
  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    return (
      <>
        {
          <div className={!this.props.onboarding ? 'page_content' : ''}>
            <IonModal className="modal_small" isOpen={this.state.confirmBadInput.length > 0}
              onDidDismiss={() => this.setState({ confirmBadInput: '' })}>
              <div className="flex flex-col justify-between h-full p-8 color_white">
                <p>{this.state.confirmBadInput}</p>
                <div className="flex top_margin justify-between">
                  <div className="button green" onClick={() => this.setState({ confirmBadInput: '' })}>
                    {this.api.trTxt(TrVar.Change)}
                  </div>
                  <div className="button white" onClick={() => this.nextSection()}>{this.api.trTxt(TrVar.Continue)}</div>
                </div>
              </div>
            </IonModal>
            {
              this.progressbar(this.state.section)
            }
            <div className='page_section' style={{ 'marginBottom': ' -30px' }}>
              {
                !this.props.onboarding && (
                  <Breadcrumbs breadcrumbs={[{ name: this.api.trTxt(TrVar.MeasurePotential), link: '/app/client/measure' }]}
                    name={this.api.trTxt(TrVar.AnswerQuestions)} />
                )
              }
              <h2>{this.state.areas[this.state.section].name}</h2>
              {this.questionIntro()}
            </div>
            {
              this.state.areas[this.state.section].questions.map((question: any, qidx: number) => {
                return this.questionSection(this.state.section, question, qidx);
              })
            }
            <div className="top_margin page_section no_bg text_center">
              <button onClick={() => this.checkNextPage()} className="button mobile_size_btn green">
                {this.state.section === (this.state.areas.length - 1) ? this.api.trTxt(TrVar.Save) : this.api.trTxt(TrVar.Continue)}
              </button>
            </div>
          </div>

        }
        {(this.state.runCalculating) &&
          <div className='onboarding_modal'>
            <div className="onboarding_modal_content">
              <div className='text_center' style={{ maxWidth: '550px', margin: '0 auto' }}>
                <div style={{ marginBottom: '40px' }}>{this.state.calculatingText}</div>
                <BarChart value={100} size="transition_linear_width_4s medium" oneColor={true} showValue={false} />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
