import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import {PackageType} from "../../interfaces/Package";
import './ClientLicense.css';
import {connect, ConnectedProps} from "react-redux";
import {LicenseInterface} from "../../interfaces/License";
import {ClientLicenseInterface} from '../../interfaces/ClientLicense';
import {SubscriptionInterface} from "../../interfaces/Subscription";
import {PaymentInterface} from "../../interfaces/Payment";
import {ClientLicenseOfferInterface} from '../../interfaces/ClientLicenseOffer';
import {formatSoon, todayStr} from '../../helpers/dateFunctions';
import {changeUrl} from "../../services/Navigation";
import License from './License';
import Tip from '../Tip';
import {TrVar} from "../../services/translate"

interface ComponentState {
  isActive: boolean
  license?: LicenseInterface
  clientLicense?: ClientLicenseInterface
  clientLicenseOffer?: ClientLicenseOfferInterface
  licenseType: number
  licenseCode: string
  error: string
  subscriptionError: string
  coachRequest: boolean
  subscriptions: Array<SubscriptionInterface>
  payments: Array<PaymentInterface>
}

const mergeProps = (parentProps: any) => ({
  ...parentProps,
});
  
const connector = connect( mergeProps);
  
type PropsFromRedux = ConnectedProps<typeof connector>;

export default class ClientLicense extends React.Component<PropsFromRedux, ComponentState>  {
  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    isActive: false,
    licenseType: 0,
    licenseCode: '',
    error: '',
    subscriptionError: '',
    coachRequest: false,
    subscriptions: [],
    payments: [],
  }

  componentDidMount() {
    this.getLicenseData()
  }

  private getLicenseData = () => {
    this.api.get('client_pay_plan').then(response => {
      this.setState({
        license: response.json.license,
        clientLicense: response.json.clientLicense,
        clientLicenseOffer: response.json.clientLicenseOffer,
        isActive: response.json.client.isActive,
        licenseType: response.json.license !== null ? response.json.license.package.type : 0,
        coachRequest: !!response.json.coachRequest,
        payments: response.json.payments,
        subscriptions: response.json.subscriptions,
      })
    })
  }

  changeCode = (e: any) => {
    this.setState({licenseCode: e.target.value})
  }

  getLicense = () => {
    if (this.state.licenseCode.length > 4) {
      const data = {
        code: this.state.licenseCode
      }
      this.api.post('acquire_license', data, ['license_code_not_found', 'no_seats_available', 'license_expired'])
        .then(_response => {
          window.location.reload()
        })
        .catch(response => {
          if (response.isExpectedError === 'license_code_not_found') {
            this.setState({error: this.api.trTxt(TrVar.LicenseCodeNotFound)})
          } else if (response.isExpectedError === 'no_seats_available') {
            this.setState({error: this.api.trTxt(TrVar.NoSeatsAvailable)})
          } else {
            this.setState({error: this.api.trTxt(TrVar.LicenseExpired)})
          }
        })
    }
  }

  acceptOffer = () => {
    this.api.post('accept_client_license_offer', {}, ['no_seats_available', 'license_expired'])
      .then(_response => {
        window.location.reload()
      })
      .catch(response => {
          if (response.isExpectedError === 'no_seats_available') {
            this.setState({error: this.api.trTxt(TrVar.NoSeatsAvailable)})
          } else {
            this.setState({error: this.api.trTxt(TrVar.LicenseExpired)})
          }
      })
  }

  licenseEndDate = () => {
    if (this.state.clientLicense && this.state.clientLicense.endDate !== null) {
      return this.state.clientLicense.endDate
    }
    if (this.state.license && this.state.license.endDate !== null) {
      return this.state.license.endDate
    }
    return null;
  }

  coachRequest = () => {
    const url = this.state.license?.package.type === PackageType.ACCESS_BASIC ?
      'request_coach/chat' :
      'request_coach/video'
    this.api.post(url, {}).then(_response => {
      this.setState({coachRequest: true})
    })
  }

  cancelSubscription = (subscription: SubscriptionInterface) => {
    this.api.post('cancel_subscription/' + subscription.id, {},['conflict']).then(response => {
      this.getLicenseData()
    }).catch(response => {
      if (response.isExpectedError) {
        this.setState({subscriptionError: response.errorMessage})
      }
    })
  }

  unCancelSubscription = (subscription: SubscriptionInterface) => {
    this.api.post('uncancel_subscription/' + subscription.id, {}, ['conflict']).then(response => {
      this.getLicenseData()
    }).catch(response => {
      if (response.isExpectedError) {
        this.setState({subscriptionError: response.errorMessage})
      }
    })
  }

  showSubscription = (subscription: SubscriptionInterface) => {
    let status = ""
    let statusString = ""
    if (subscription.endedAt) {
      status = "ended"
      statusString = this.api.trTxt(TrVar.Ended)
    } else if (subscription.cancelledAt) {
      status = "cancelled"
      statusString = this.api.trTxt(TrVar.Terminated)
    } else {
      status = "active"
      statusString = this.api.trTxt(TrVar.Active)
    }
    return (
      <div key={"subscription_" + subscription.id} className="page_section top_margin">
        <h2>{statusString} {this.api.trTxt(TrVar.Subscription).toLowerCase()}</h2>
        <p className="top_margin">
          {this.api.trTxt(TrVar.Created)}: {formatSoon(subscription.createdAt, this.api.lang)}
        </p>
        {
          status === "ended" &&
          <p className="top_margin">
            {this.api.trTxt(TrVar.Ended)}: {formatSoon(subscription.endedAt, this.api.lang)}
          </p>
        }
        {
          status === "cancelled" &&
            <div className="top_margin">
              <p>
                {this.api.trTxt(TrVar.Cancelled)}:
                &nbsp;{formatSoon(subscription.cancelledAt, this.api.lang)}
              </p>
              <p>
                {this.api.trTxt(TrVar.SubscriptionWillEnd)}: {subscription.validTo}
              </p>
              {
                this.state.subscriptionError.length > 0 &&
                  <div className="top_margin error_red">{this.state.subscriptionError}</div>
              }
              <div className="button white inline_block top_margin" onClick={() => this.unCancelSubscription(subscription)}>
                {this.api.trTxt(TrVar.UncancelTermination)}
              </div>
            </div>
        }
        {
          status === "active" &&
            <div className="top_margin">
              <p>
                {this.api.trTxt(TrVar.SubscriptionWillBeRenewed)}
                &nbsp;{subscription.validTo}.
              </p>
              {
                this.state.subscriptionError.length > 0 &&
                  <div className="top_margin error_red">{this.state.subscriptionError}</div>
              }
              <div className="button white inline_block top_margin" onClick={() => this.cancelSubscription(subscription)}>
                {this.api.trTxt(TrVar.Terminate)}
              </div>
            </div>
        }
      </div>
    )
  }

  render() {
    return (
      <>
        <Breadcrumbs breadcrumbs={[{name: this.api.trTxt(TrVar.MyAccount), link: '/app/client/my_account'}]}
                     name={this.api.trTxt(TrVar.YourLicense)}/>
        <div className="page_content">
          <div className="page_section">
            <div className="flex">
              <img height={50} src="/assets/icon/blue/license.svg" alt="blue license icon" />
              <h1 className='left_margin my-auto'>{this.api.trTxt(TrVar.YourLicense)}</h1>
            </div>
            <div className="page_bar" />
            <div className="section_direction flex">
              {this.state.isActive &&
                <License backgroundColor={true} package={this.state.license?.package}
                         clientLicense={this.state.clientLicense}/>
              }
              <div style={{ maxWidth: '760px', width: '100%', height: '100%' }} className="r-half-col r_top_margin r_left_margin">
                {(this.state.license?.package?.type === PackageType.FREE) &&
                  <div style={{ width: '100%' }} className='flex flex-wrap justify-between'>
                    <div className='flex flex-col justify-between'>
                      <div style={{ maxWidth: '279px', height: '100%' }}>
                        <h2>{this.api.trTxt(TrVar.DevelopYourFullPotential)}</h2>
                        <div className="blue top_margin">
                          <div>
                          {this.api.trTxt(TrVar.WithAccessYouUnlock)}
                          </div>
                        </div>
                      </div>
                      <div className='top_margin'>
                        <div onClick={() => changeUrl('/app/client/purchase')} className="button green inline_block">
                          {this.api.trTxt(TrVar.GetStarted)}
                        </div>                          
                      </div>
                    </div>

                    <img className='hide_medium' width={286} src="/assets/illustration/certified.svg" alt="" />
                  </div>
                }
                {(this.state.licenseType === PackageType.ACCESS && this.state.isActive && !this.state.clientLicenseOffer)
                  &&
                    <div className='section_direction flex license_section'>
                        <div className="page_half_content flex flex-col justify-between">
                            <div className='top_margin left_margin right_margin'>
                                <h3>{this.api.trTxt(TrVar.WantCoach)}</h3>
                              {
                                this.state.coachRequest ? (
                                  <h4 className="blue top_margin">
                                    {this.api.trTxt(TrVar.WeReportCoachRequest)}
                                  </h4>
                                ) : (
                                  <div className="top_margin">
                                    <h4 className="blue">{this.api.trTxt(TrVar.WeWillNotify)}</h4>
                                    <div className="top_margin">
                                      <div className="button inline_block mb-4" onClick={() => this.coachRequest()}>
                                        {this.api.trTxt(TrVar.SubmitCoachRequest)}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                        </div>

                        <div className="page_half_content my-auto">
                          {/* <img className='hide_medium' src="/assets/icon/placeholder.svg" alt="" /> */}
                        </div>
                    </div>
                }
                {
                  this.state.clientLicenseOffer ? (
                    <div className='license_section'>
                      <div className="flex license_full_height flex-col justify-between">
                        <div className='top_margin right_margin left_margin'>
                          <h3>{this.api.trTxt(TrVar.YouHaveBeenOffered)} {this.state.clientLicenseOffer.license.package.name}</h3>
                          {
                            this.state.clientLicenseOffer.lastValidDate >= todayStr() ? (
                              <>
                                <h4 className="top_margin">{this.api.trTxt(TrVar.OfferIsValid)} {this.state.clientLicenseOffer.lastValidDate}</h4>
                                <button className="button top_margin" onClick={() => this.acceptOffer()}>{this.api.trTxt(TrVar.Accept)}</button>
                              </>
                            ) : (
                              <p className="top_margin">{this.api.trTxt(TrVar.OfferExpired)} {this.state.clientLicenseOffer.lastValidDate}</p>
                            )
                          }
                        </div>
                      </div>

                    </div>
                  ) : !this.state.clientLicenseOffer && !this.state.isActive ? (
                      <div className='license_section'>
                        <div className="flex license_full_height flex-col justify-between">
                          <div className='top_margin right_margin left_margin'>
                            <h3>{this.api.trTxt(TrVar.YouDontHaveAPlan)}</h3>
                            <h4 className="top_margin">{this.api.trTxt(TrVar.IfYouHaveLicenseCode)}:</h4>
                            <input type="text" name="code"
                                   className='page_input top_margin'
                                   onChange={(e) => this.changeCode(e)}
                                   placeholder={this.api.trTxt(TrVar.LicenseCode)} value={this.state.licenseCode}/>
                          </div>
                          <div>
                            <button className="button r_top_margin left_margin bottom_margin" onClick={this.getLicense}>{this.api.trTxt(TrVar.GetLicensePackage)}</button>
                          </div>
                        </div>
                      </div>
                    )
                    : null}
                {
                  this.state.error.length > 0 &&
                    <div className="top_margin error_red">{this.state.error}</div>
                }
              </div>
            </div>
          </div>
          {
            this.state.license?.package?.type === PackageType.ACCESS_PRIVATE &&
            <div className="top_margin">
              <Tip />
            </div>
          }
          {
            this.state.subscriptions.map(subscription => {
              return this.showSubscription(subscription)
            })
          }
          {
            this.state.payments.map(payment => {
              return (
                  <div key={"payment_" + payment.subscriptionId} className="top_margin page_section">
                    <h2>{this.api.trTxt(TrVar.Payment)}</h2>
                    <p className="top_margin">
                      {this.api.trTxt(TrVar.Date)}: {formatSoon(payment.createdAt, this.api.lang)}
                    </p>
                    <p className="top_margin">
                      {this.api.trTxt(TrVar.Amount)}: {(payment.amount / 100).toFixed(2)}
                      &nbsp;{payment.currency}
                    </p>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }
}
