export enum MeetingType {
  BOOKED,
  CLIENT_CANCELLED_LATE,
  COACH_UNAVAILABLE,
  COACH_TREATS,
  ZEBRAIN_TREATS,
  CONFIRMED_OK
}

export enum UnbookableType {
  NONE,
  SOFT,
  HARD,
}

export interface MeetingInterface {
  id: number
  start: string
  date: Date
  length: number
  meetingKey: string
  meetingType: MeetingType
  client?: any
  coach: any
  unbookable?: UnbookableType
  unbookableReason?: string
  booked?: boolean
  meetingsSummary: any
  visionBoardData?: any
  coachConfirmed: boolean
  notes: string
  guestName?: string
  guestEmail?: string
}

export interface MeetingSuggestionInterface {
  id: number
  start: string
  initiator: string
  length: number
  status: number
  client: any
  coach: any
  createdAt: string
  updatedAt: string
}
