import React from 'react';
import {IonRange} from "@ionic/react";
import './UploadPicture.css';
import { getOrientation } from '../../helpers/get_exif_orientation';
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';

interface ComponentProps {
  picture: string;
  setPicture: Function,
  closeModal: Function,
}

interface ComponentState {
  picture?: string
  resultPicture: string
  rawPicture: string
  rangeValue: number
  uploaded: boolean
  mouseDown: boolean
}

export default class UploadPicture extends React.Component<ComponentProps, ComponentState> {

  api = Api.getInstance()

  rotatedDegrees: number;
  eImg: HTMLImageElement | undefined;
  rImg: HTMLImageElement | undefined;
  rImgRef: any;
  eImgRef: any;
  pImgRef: any;
  nWidth: number;
  nHeight: number;
  iWidth: number;
  iHeight: number;
  cx: number;
  cy: number;
  r: number;
  prevMoveX: number;
  prevMoveY: number;
  orientation: number;

  state = {
    picture: this.props.picture,
    rawPicture: '',
    resultPicture: '',
    rangeValue: 67,
    mouseDown: false,
    uploaded: false
  }

  constructor(props: ComponentProps) {
    super(props);
    this.rImgRef = React.createRef();
    this.eImgRef = React.createRef();
    this.pImgRef = React.createRef();
    this.rotatedDegrees = 0;
    this.nWidth = 0;
    this.nHeight = 0;
    this.iWidth = 0;
    this.iHeight = 0;
    this.cx = 0;
    this.cy = 0;
    this.r = 0;
    this.prevMoveX = 0;
    this.prevMoveY = 0;
    this.orientation = 0;
  }

  componentDidMount() {
    this.eImg = this.eImgRef.current;
    this.rImg = this.rImgRef.current;
    if (this.eImg) {

      this.eImg.addEventListener('touchmove', (event: any) => {
        this.touchMove(event);
      });

      this.eImg.addEventListener('touchend', (event: any) => {
        this.touchEnd(event);
      });

      this.eImg.addEventListener('mousedown', (event: any) => {
        this.setState({mouseDown: true})
      });

      this.eImg.addEventListener('mousemove', (event: any) => {
        this.mouseMove(event)
      });

      this.eImg.addEventListener('mouseup', (event: any) => {
        this.setState({mouseDown: false})
        this.touchEnd(event)
      });

    }
    setTimeout(() => {
      const pImg = document.getElementById('profileFile')
      if (pImg) {
        pImg.click()
      }
    }, 200)
  }

  touchMove = (event: any) =>  {

    event.preventDefault();

    if (event.changedTouches.length <= 0) {
      return;
    }
    let changeX: number;
    let changeY: number;
    let moveX: number;
    let moveY: number;

    moveX = event.changedTouches[0].clientX;
    moveY = event.changedTouches[0].clientY;

    if (this.prevMoveX === 0) {
      this.prevMoveX = moveX;
    }

    if (this.prevMoveY === 0) {
      this.prevMoveY = moveY;
    }

    changeX = moveX - this.prevMoveX;
    changeY = moveY - this.prevMoveY;

    this.prevMoveX = moveX;
    this.prevMoveY = moveY;

    if (changeX != null && changeX !== 0 && changeX) {
      this.cx += changeX;
    }

    if (changeY != null && changeY !== 0 && changeY) {
      this.cy += changeY;
    }

    this.limitXY();

    this.showMask();
  }

  mouseMove = (event: any) =>  {

    if (this.state.mouseDown) {
      event.preventDefault();

      let changeX: number;
      let changeY: number;
      let moveX: number;
      let moveY: number;

      moveX = event.offsetX;
      moveY = event.offsetY;

      if (this.prevMoveX === 0) {
        this.prevMoveX = moveX;
      }

      if (this.prevMoveY === 0) {
        this.prevMoveY = moveY;
      }

      changeX = moveX - this.prevMoveX;
      changeY = moveY - this.prevMoveY;

      this.prevMoveX = moveX;
      this.prevMoveY = moveY;

      if (changeX != null && changeX !== 0 && changeX) {
        this.cx += changeX;
      }

      if (changeY != null && changeY !== 0 && changeY) {
        this.cy += changeY;
      }

      this.limitXY();

      this.showMask();
    }
  }

  touchEnd = (event: any) => {
    this.prevMoveX = 0;
    this.prevMoveY = 0;
    this.renderResult();
  }

  rotate = (rotation: number) => {
    this.rotatedDegrees += rotation;
    this.rotateImage(this.rotatedDegrees);
    this.initMask();
  }

  initMask = () => {

      setTimeout(() => {
        if (this.eImg && this.rImg) {
          this.nWidth = this.eImg.naturalWidth;
          this.nHeight = this.eImg.naturalHeight;
          this.iWidth = this.eImg.width;
          this.iHeight = this.eImg.height;
          this.cx = this.iWidth / 2;
          this.cy = this.iHeight / 2;
          this.r = Math.min(this.iWidth, this.iHeight) / 3;

          console.log('e w ' + this.nWidth + ' e h ' + this.nHeight);
          console.log('i w ' + this.iWidth + ' i h ' + this.iHeight);

          this.showMask();
          this.renderResult();
        } else {
          console.log('No eImg or rImg');
        }
      }, 200);
  }

  showMask = () => {
    if (this.eImg) {
      let cssmask = 'radial-gradient(circle at ' + this.cx + 'px ' + this.cy + 'px,';
      cssmask += 'black ' + this.r + 'px, rgba(0, 0, 0, 0.6) ' + this.r + 'px)';
      this.eImg.setAttribute('style', '-webkit-mask-image: ' + cssmask);
      // this.eImg.setAttribute('style', 'mask-image: ' + cssmask);
    } else {
      console.log('No eImg in showMask');
    }
  }

  changeMask = (evt: any) => {
    const rangeValue = evt.target.value;
    this.setState({rangeValue: rangeValue});
    this.r = Math.min(this.iWidth, this.iHeight) * rangeValue / 200;
    this.limitXY();
    this.showMask();
    this.renderResult();
  }

  limitXY = () => {
    if (this.cx < this.r) {
      this.cx = this.r;
    } else if (this.cx > (this.iWidth - this.r)) {
      this.cx = this.iWidth - this.r;
    }

    if (this.cy < this.r) {
      this.cy = this.r;
    } else if (this.cy > (this.iHeight - this.r)) {
      this.cy = this.iHeight - this.r;
    }
  }

  renderResult = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    const ctx = canvas.getContext('2d');
    const sWidth = 2 * this.r * this.nWidth / this.iWidth;
    const sHeight = 2 * this.r * this.nHeight / this.iHeight;
    const sx = (this.cx - this.r) * this.nWidth / this.iWidth;
    const sy = (this.cy - this.r) * this.nHeight / this.iHeight;

    if (ctx !== null && this.eImg) {
      ctx.drawImage(this.eImg, sx, sy, sWidth, sHeight, 0, 0, 200, 200);
      this.setState({picture: canvas.toDataURL('image/jpeg')})
    }
  }

  rotateImage = (rotation: number) => {

    if (!this.rImg || !this.eImg) {
      return;
    }
    const canvas = document.createElement('canvas');
    const nWidth = this.rImg.naturalWidth;
    const nHeight = this.rImg.naturalHeight;
    let dx = 0;
    let dy = 0;

    console.log('N ' + nWidth + ', ' + nHeight + ' rotation ' + rotation);
    if (rotation === 90 || rotation === -90) {
      // canvas.width = canvas.height = Math.max(nHeight, nWidth);
      canvas.width = nHeight;
      canvas.height = nWidth;
      if (nHeight > nWidth) {
        dx = (nHeight - nWidth) / 2;
        dy = -dx;
      } else if (nWidth > nHeight) {
        dy = (nWidth - nHeight) / 2;
        dx = -dy;
      }
    } else {
      canvas.width = nWidth;
      canvas.height = nHeight;
    }

    const ctx = canvas.getContext('2d');

    // Rotation is around upper left corner. Translate to center of image
    if (ctx !== null) {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(rotation * Math.PI / 180);
      console.log('Rotating ' + rotation);
      ctx.translate(-canvas.width / 2, -canvas.height / 2);

      ctx.drawImage(this.rImg, dx, dy, nWidth, nHeight);
      this.setState({resultPicture: canvas.toDataURL('image/jpeg')});
    }
  }

  uploadImage = (evt: any) => {
    if (evt === null) {
      alert('Empty event');
      return;
    }
    this.setState({uploaded: true})
    const picFile = evt.target.files[0];

    if (!picFile.type.match('image.*')) {
      alert('This is not an image');
      return;
    }

    const reader = new FileReader();

    reader.onload = ((theFile) => {
      return (e: any) => {
        this.setState({rawPicture: e.target.result})
        // self.rawImage = e.target.result;
        this.rotateUploaded();
      };
    })(picFile);
    reader.readAsDataURL(picFile);
  }

  rotateUploaded = () => {
    if (this.state.rawPicture.length === 0) {
      return;
    }
    this.orientation = getOrientation(this.state.rawPicture);
    let rotation = 0;
    if (this.orientation === 7 || this.orientation === 8) {
      rotation = -90;
    } else if (this.orientation === 5 || this.orientation === 6) {
      rotation = 90;
    } else if (this.orientation === 3) {
      rotation = 180;
    }
    this.rotatedDegrees = rotation;
    console.log('orientation: ' + this.orientation + ' rotate ' + rotation);
    if (rotation === 0) {
      this.setState({resultPicture: this.state.rawPicture});
      this.initMask();
    } else {
      setTimeout(() => {
        this.rotateImage(rotation);
        this.initMask();
      }, 100);
    }
  }

  saveImage = () => {
    this.props.setPicture(this.state.picture);
    this.props.closeModal();
  }


  render() {
    return (
      <div className="color_white">
        <div className="top_margin left_margin">
          <div className="circle_medium flex justify-center"
               onClick={() => this.props.closeModal()}>
            <img ref={this.pImgRef} alt="back" src="/assets/icon/white/close-circle.svg"/>
          </div>
        </div>

        <div className="text_center">
          <div className="flex justify-evenly items-center">
            <div>
              <img alt="profile" className="result_img box_shadow" src={this.state.picture}/>
            </div>
            <div>
              {
                this.state.uploaded ? (
                  <div>
                    <div className="button inline_block" onClick={() => this.saveImage()}>{this.api.trTxt(TrVar.Save)}</div>
                  </div>

                ): (
                  <div className="cursor-pointer">
                    <input id="profileFile" type="file" className="display_none" onChange={(e:any) => this.uploadImage(e)}/>
                    <label htmlFor="profileFile" className="underline">{this.api.trTxt(TrVar.ChooseANewPicture)}</label>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <div className="top_margin left_margin right_margin">
          {/*<p>*/}
          {/*  2. Flytta cirkeln och ändra dess storlek tills du är nöjd.*/}
          {/*</p>*/}

          <div className={`${this.state.uploaded ? "visibility_visible" : "visibility_hidden"}`}>
            <div className="flex items-center justify-between bottom_margin">
              <div>
                {this.api.trTxt(TrVar.Smaller)}
              </div>
              <div style={{width: "60%"}}>
                <IonRange onIonChange={(ev: any) => this.changeMask(ev)}
                          min={5} max={100} value={this.state.rangeValue}/>
              </div>
              <div>
                {this.api.trTxt(TrVar.Larger)}
              </div>
            </div>

            <div className="flex justify-between result__padding bottom_margin">
              <div className="text_link cursor-pointer" onClick={() => this.rotate(-90)}>
                {this.api.trTxt(TrVar.Rotate)} {this.api.trTxt(TrVar.Left).toLowerCase()}
              </div>
              <div className="text_link cursor-pointer" onClick={() => this.rotate(90)}>
                {this.api.trTxt(TrVar.Rotate)} {this.api.trTxt(TrVar.Right).toLowerCase()}
              </div>
            </div>

          </div>
        </div>

        <div className={`${this.state.uploaded ? "visibility_visible" : "visibility_hidden"}`}>
          <div className="top_margin text_center">
            <img alt="edit" ref={this.eImgRef} className="edit_img" src={this.state.resultPicture} />
          </div>
        </div>
        <img ref={this.rImgRef} alt="hidden" style={{display: "none"}} src={ this.state.rawPicture}/>
      </div>
    );
  }
};
