import React, { useEffect } from 'react'
import Api from "../../services/Api"
import BehaviourEdit from "../behaviour/BehaviourEdit";
import BehaviourView from "../behaviour/BehaviourView";
import BehaviorEditOverview from '../OnboardingSteps/BehaviourEditOverview';
import BehaviourIntro from '../OnboardingSteps/BehaviourIntro';
import BehaviourOutro from '../OnboardingSteps/BehaviourOutro';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  save: Function
  packageFree: boolean
  progress: Function
  goTo: number
  finishSection: Function
  coachFree: boolean
  scrollTop: Function
  firstMeasure: Function
}

const BehaviourOnboarding = (props: ComponentProps) => {

  const api = Api.getInstance()

  useEffect(() => {
    const api = Api.getInstance()
    api.get('analysis/client', ['conflict']).then(response => {
      if (response.json.disa !== null) {
        props.firstMeasure(false)
      }
    })    
  // eslint-disable-next-line
  }, [])

  if (props.goTo === 0) {
    return (
      <BehaviorEditOverview progress={props.progress} coachFree={props.coachFree} packageFree={props.packageFree} />
    );
  } else if (props.goTo === 1) {
    return (
      <BehaviourIntro progress={props.progress} />
    )
  } else if (props.goTo === 2) {
    return (
      <BehaviourEdit progress={props.progress} isOnboarding={true} who="client" coachFree={props.coachFree} saveText={api.trTxt(TrVar.Save)} packageFree={props.packageFree}
        cancelButton={false} />
    )
  } else if (props.goTo === 3) {
    return (
      <BehaviourView finishSection={props.finishSection} progress={props.progress} who="client" onboarding={true} next={props.save} />
    )     
  } else {
    return (
      <BehaviourOutro progress={props.save} />
    )
  }
}

export default BehaviourOnboarding;
