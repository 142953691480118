import React from "react";
import {IonModal} from "@ionic/react";
import Api from "../../services/Api";
import {MeetingInterface, MeetingType, MeetingSuggestionInterface} from "../../interfaces/Meeting";
import {formatHourMinute, formatSoon, inMinutes} from "../../helpers/dateFunctions";
import MeetingHistory from "../MeetingHistory";
import CalendarCoach from "./CalendarCoach";
import {MeetingBlockInterface} from "../../interfaces/MeetingBlockInterface";
import {changeUrl} from "../../services/Navigation";
import Breadcrumbs from "../menu/Breadcrumbs";
import MeetingButton from "../MeetingButton";
import CalendarSynk from "../info/CalendarSynk";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import {showMeetingSuggestionStatus} from "../meeting_suggestions/meetingSuggestions";
import { TrVar } from "../../services/translate";
import {TrArray} from "../../services/translate_arrays";

interface ComponentProps {
  reload: Function
  navigation: any
}

enum MeetingStatus {
  FINISHED,
  STARTED,
  SOON,
  LONG_TIME
}

interface ComponentState {
  nextMeetings: Array<MeetingInterface>
  meetingBlocks: Array<MeetingBlockInterface>
  meetingPause: number
  showCancel: boolean
  showRemoveSuggestion: boolean
  removeSuggestionId: number
  showSyncWhat: string
  cancelText: string
  cancelMeetingId: number
  error: string
  meetingSuggestions: Array<MeetingSuggestionInterface>
}

export default class CoachBookings extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance()
  calendarFeedUrl = ''

  state: Readonly<ComponentState> = {
    nextMeetings: [],
    meetingBlocks: [],
    showSyncWhat: '',
    meetingPause: 0,
    showCancel: false,
    showRemoveSuggestion: false,
    removeSuggestionId: 0,
    cancelText: '',
    cancelMeetingId: 0,
    error: '',
    meetingSuggestions: [],
  }

  componentDidMount() {
    this.getMeetings()
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'bookings'}
    }
    this.api.post('coach_log_event', data).then()
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
    if (prevProps.navigation.sub1 === 'meeting_times' && this.props.navigation.sub1 === '') {
      this.getMeetings()
    }
  }

  getMeetings = () => {
    this.api.get('coach_meetings').then(response => {
      this.calendarFeedUrl = response.json.calendarFeedUrl
      const nextMeetings: Array<MeetingInterface> = response.json.nextMeetings

      this.setState({
        nextMeetings: nextMeetings,
        meetingBlocks: response.json.meetingBlocks,
        meetingPause: response.json.meetingPause,
        meetingSuggestions: response.json.meetingSuggestions,
      })
    })
  }

  rescheduleBooking = (id:number) => {
    this.setState({ showCancel: false },
      () => setTimeout(() => {changeUrl('/app/coach/bookings/reschedule/' + id)}, 300))
  }

  renderCancelBooking = () => {

    return (
      <div className="top_margin bottom_margin right_margin left_margin text_center white">
        <h3 className="mt-4">{this.api.trTxt(TrVar.ChangeMeeting)}</h3>
        <div className="button green inline_block top_margin" onClick={() => this.rescheduleBooking(this.state.cancelMeetingId)}>
          {this.api.trTxt(TrVar.ToCalendar)}
        </div>
        <h3 className="mt-12">
          {this.api.trTxt(TrVar.WantToCancelMeeting)}
        </h3>

        <textarea className="mt-4 page_text_area small w-full" rows={4}
                  placeholder={this.api.trTxt(TrVar.CancelMeetingExplanation)}
                  value={this.state.cancelText} onChange={this.handleCancelText}/>

        <div className="top_margin text_center">
          <p className="font_small">{this.api.trTxt(TrVar.CoachCancelMeetingWarning)}</p>
          <div className="mt-2 button red inline_block" onClick={() => this.cancelBooking()}>{this.api.trTxt(TrVar.CancelMeeting)}</div>
        </div>
      </div>
    )
  }

  renderRemoveSuggestion = () => {
    return (
      <div className="top_margin bottom_margin right_margin left_margin white">
        <h3 className="mt-4">{this.api.trTxt(TrVar.DoYouWantToRemoveMeetingSuggestion)}</h3>

        <p className="top_margin">
          {
            this.api.trTxt(TrVar.PleaseConsiderThatTheClientHasReceivedAMail)
          }
        </p>

        <div className="top_margin flex justify-between">
          <div className="button white inline_block" onClick={() => this.setState({showRemoveSuggestion: false, removeSuggestionId: 0})}>
            {this.api.trTxt(TrVar.Cancel)}
          </div>
          <div className="button red inline_block" onClick={() => this.doRemoveSuggestion()}>
            {this.api.trTxt(TrVar.Remove)}
          </div>
        </div>
      </div>
    )
  }

  handleCancelText = (e: any) => {
    this.setState({cancelText: e.target.value})
  }

  cancelBooking = () => {
    const data = {
      cancel_text: this.state.cancelText
    }
    this.api.post('coach_meeting/cancel/' + this.state.cancelMeetingId, data).then(_response => {
      this.setState({showCancel: false})
      this.getMeetings()
      this.props.reload()
    })
  }

  saveMeeting = (booked: boolean) => {
    if (booked) {
      this.props.reload()
    }
    this.getMeetings()
    this.goToMenu()
  }

  showMeetingStatus = (meetingType: number, formatDiv: boolean) => {
    let status = this.api.trArr(TrArray.MeetingType)[meetingType]
    if (meetingType === MeetingType.CLIENT_CANCELLED_LATE) {
      status = this.api.trTxt(TrVar.MeetingClientCancelledLate)
    } else if (meetingType === MeetingType.COACH_UNAVAILABLE) {
      status = this.api.trTxt(TrVar.MeetingCoachDidNotShow)
    } else if (meetingType === 3 || meetingType === 4) {
      status = this.api.trTxt(TrVar.MeetingCancelled)
    }
    if (status.length > 0) {
      if (formatDiv) {
        return (
          <div className="top_margin error_red">{status}</div>
        )
      } else {
        return (
          <div>{status}</div>
        )
      }

    }
    return (<></>)
  }

  minutesToHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60

    return hours + " " + this.api.trTxt(TrVar.Hours).toLowerCase() + " " + mins + " " + this.api.trTxt(TrVar.Minutes).toLowerCase()
  }

  meetingStatusOrChange = (meeting: MeetingInterface, meetingStatus: number) => {
    if (meeting.meetingType !== MeetingType.BOOKED && meeting.meetingType !== MeetingType.CONFIRMED_OK) {
      return this.showMeetingStatus(meeting.meetingType, false)
    } else if (meetingStatus === MeetingStatus.FINISHED) {
      return (
        <div className="red" style={{minWidth: "80px"}}>{this.api.trTxt(TrVar.Finished)}</div>
      )
    } else if (meetingStatus === MeetingStatus.STARTED) {
      return (
        <div className="dark_green" style={{minWidth: "80px"}}>{this.api.trTxt(TrVar.Ongoing)}</div>
      )
    } else {
      return (
        <div className="link_text"
             onClick={() => this.setState({cancelMeetingId: meeting.id, showCancel: true})}>
          {this.api.trTxt(TrVar.ChangeBooking)}
        </div>
      )
    }
  }

  nextMeetings = () => {

    let showMeetingButtons = 0

    return (
      <div className="page_section top_margin">
        <h3>{this.api.trTxt(TrVar.ComingMeetings)}</h3>
        {
          this.state.nextMeetings.map((meeting: MeetingInterface, i) => {
            const bgClass = [MeetingType.BOOKED, MeetingType.CONFIRMED_OK].includes(meeting.meetingType) ? '' : 'bg_red'
            const startsInMinutes = inMinutes(meeting.start)
            let meetingStatus: number
            let showMeetingButton = false
            if (startsInMinutes > 120) {
              meetingStatus = MeetingStatus.LONG_TIME
            } else if (startsInMinutes > 0) {
              meetingStatus = MeetingStatus.SOON
              showMeetingButton = showMeetingButtons < 1
            } else {
              if ((startsInMinutes + meeting.length) > -15) {
                showMeetingButton = true
              }
              if ((startsInMinutes + meeting.length) > 0) {
                meetingStatus = MeetingStatus.STARTED
                showMeetingButtons++
                showMeetingButton = true
              } else {
                meetingStatus = MeetingStatus.FINISHED
                // showMeetingButtons++
                // showMeetingButton = true
              }
            }
            return (
              <div key={"next_meeting_" + meeting.id}
                   className={"border_box max_width top_margin r-flex items-center no_padding justify-between " + bgClass}>
                <div className="r-flex justify-between top_left_margin cursor-pointer"
                     onClick={() => changeUrl("/app/coach/clients/" + meeting.client.id)}>
                  <img alt="client" className="picture_small round" src={meeting.client.picture}/>
                  <div className="r_left_margin min-w-200">
                    <p className="blue font_small">{this.api.trTxt(TrVar.Client)}</p>
                    <p className="link_text">
                      {meeting.client.firstName} {meeting.client.lastName}
                      {meeting.guestName ? ' + ' + meeting.guestName : ''}
                    </p>
                  </div>
                </div>

                <div className="top_left_margin">
                  <p className="bold">{formatSoon(meeting.start, this.api.lang)}</p>
                </div>

                <div className="flex" style={{'alignSelf': 'center'}}>
                  <div className="top_left_margin inline_block">
                    {this.meetingStatusOrChange(meeting, meetingStatus)}
                  </div>
                </div>

                {
                  showMeetingButton &&
                    <div className="top_left_margin inline_block">
                        <MeetingButton who="coach" meeting={meeting}
                                       name={meeting.coach.firstName}/>
                    </div>
                }
              </div>
            )
          })
        }

        {
          (this.state.nextMeetings.length === 0) &&
            <div className="top_margin border_box max_width">
              {
                this.api.trTxt(TrVar.NoBookedMeeting)
              }
            </div>
        }
      </div>
    )
  }

  goToMenu = () => {
    changeUrl('/app/coach/bookings')
  }

  // showMeetingSuggestionStatus = (suggestion: MeetingSuggestionInterface) => {
  //   if (suggestion.initiator === 'client') {
  //     if (suggestion.status === 0) {
  //       return (
  //         <div>
  //           <div className="button green inline_block"
  //                onClick={() => alert('Todo')}>
  //             Accept
  //           </div>
  //           <div className="ml-2 button red inline_block"
  //                onClick={() => alert('Todo')}>
  //             Reject
  //           </div>
  //         </div>
  //       )
  //     } else {
  //       return (
  //         <div>{suggestion.status === 1 ? 'Accepted by you' : ' Rejected by you'}</div>
  //       )
  //     }
  //   } else {
  //     if (suggestion.status === 0) {
  //       return (
  //         <div>{this.api.lang === 'sv' ? 'Väntar på svar från klient' : 'Waiting for client reply'}</div>
  //       )
  //     } else if (suggestion.status === 1) {
  //       return (
  //         <div>{this.api.lang === 'sv' ? 'Accepterat av klient' : 'Accepted by client'}</div>
  //       )
  //     } else if (suggestion.status === 2) {
  //       return (
  //         <div>{this.api.lang === 'sv' ? 'Avböjt av klient' : 'Rejected by client'}</div>
  //       )
  //     }
  //   }
  // }

  removeSuggestion = (suggestion: MeetingSuggestionInterface) => {
    this.setState({removeSuggestionId: suggestion.id, showRemoveSuggestion: true})
  }

  doRemoveSuggestion = () => {
    const data = {
      suggestion_id: this.state.removeSuggestionId
    }

    this.api.post('coach_meeting_suggestion/remove', data).then(_result => {
      this.setState({removeSuggestionId: 0, showRemoveSuggestion: false})
      this.getMeetings()
    })
  }

  render() {
    if (this.props.navigation.sub1 === 'meeting') {
      return (
        <MeetingHistory meetingKey={this.props.navigation.sub2} close={this.goToMenu}/>
      )
    } else if (this.props.navigation.sub1 === 'meeting_times') {
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs name={this.api.trTxt(TrVar.HandleMeetingTimes)}
                       breadcrumbs={[{
                         name: this.api.trTxt(TrVar.MyBookings),
                         link: '/app/coach/bookings',
                         linkFunc: this.saveMeeting
                       }]}/>
          <CalendarCoach close={this.saveMeeting}/>
        </div>
      )
    } else if (this.props.navigation.sub1 === 'reschedule') {
      const rescheduleMeetingId = parseInt(this.props.navigation.sub2)
      // let rescheduleMeeting = null
      // for (const meeting of this.state.nextMeetings) {
      //   if (meeting.id === rescheduleMeetingId) {
      //     rescheduleMeeting = meeting
      //     break
      //   }
      // }
      return (
        <div className="page_content cal_no_x_padding">
          <Breadcrumbs breadcrumbs={
            [{name: this.api.trTxt(TrVar.MyBookings), link: '/app/coach/bookings', linkFunc: this.saveMeeting}]
          } name={this.api.trTxt(TrVar.Reschedule)}/>
          <CalendarCoach close={this.saveMeeting} rescheduleMeetingId={rescheduleMeetingId}/>
        </div>
      )
    }
    return (
      <div className="page_content">
        <Breadcrumbs name={this.api.trTxt(TrVar.MyBookings)}/>
        <IonModal isOpen={this.state.showCancel} onDidDismiss={() => this.setState({showCancel: false})}
          className="modal_medium">
              <img alt="close" className="icon_small close_div" src="/assets/icon/white/cross.svg"
                   onClick={() => this.setState({showCancel: false})} />          
          {
            this.renderCancelBooking()
          }
        </IonModal>
        <IonModal isOpen={this.state.showRemoveSuggestion} onDidDismiss={() => this.setState({showRemoveSuggestion: false})}
                  className="modal_small">
          {
            this.renderRemoveSuggestion()
          }
        </IonModal>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.MyBookings)}</h1>
          <div className="page_bar"/>
          <div className="flex justify-between">
            <h3>{this.api.trTxt(TrVar.AddMeetings)}</h3>
            <div className="button" onClick={() => changeUrl('/app/coach/bookings/meeting_times')}>
              {this.api.trTxt(TrVar.ScheduleAMeeting)}
            </div>
          </div>
        </div>

        {this.nextMeetings()}

        {
          this.state.meetingSuggestions.length > 0 &&
            <div className="top_margin page_section">
                <h2>{this.api.trTxt(TrVar.MeetingSuggestion)}</h2>
              {
                this.state.meetingSuggestions.map((suggestion: MeetingSuggestionInterface) => {
                  return (
                    <div className="border_box top_margin r-flex justify-between items-center"
                         key={"suggestion_" + suggestion.id}>
                      <div className="flex items-center">
                        <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                        {formatSoon(suggestion.start, this.api.lang)}, {suggestion.length} min
                      </div>
                      <p>{suggestion.initiator === 'coach' ?
                        this.api.trTxt(TrVar.YourSuggestionTo)
                         :
                        this.api.trTxt(TrVar.ClientSuggestion)}
                        &nbsp;{suggestion.client.firstName}
                      </p>
                      {showMeetingSuggestionStatus(this.api.lang, suggestion)}
                      {
                        suggestion.status !== 2 &&
                          <div className="button red inline_block" onClick={() => this.removeSuggestion(suggestion)}>
                            {this.api.trTxt(TrVar.RemoveSuggestion)}
                          </div>
                      }
                    </div>
                  )
                })
              }
            </div>
        }

        <div className="page_section top_margin">
          <CalendarSynk lang={this.api.lang} calendarFeedUrl={this.calendarFeedUrl}/>
        </div>

        <div className="page_section top_margin">
          <h3>{this.api.trTxt(TrVar.AvailableTimes)}</h3>
          <div className="top_margin font_small">{this.api.trTxt(TrVar.YouHave)} {this.state.meetingPause}
            &nbsp; {this.api.trTxt(TrVar.MinutesBreakClientMeetings)}.
            &nbsp;{this.api.trTxt(TrVar.ChangeUnder)}
            &nbsp;
            <span onClick={() => changeUrl("/app/coach/my_profile")}
                         className="cursor-pointer underline">
              {this.api.trTxt(TrVar.EditProfile)}
            </span>
          </div>

          {
            this.state.meetingBlocks.map((meetingBlock: MeetingBlockInterface) => {
              return (
                <div key={"meeting_block_" + meetingBlock.id} className="border_box max_width top_margin flex items-center">
                  <img className="icon_small right_margin" alt="time" src="/assets/icon/white/time-outline.svg"/>
                  {formatSoon(meetingBlock.start, this.api.lang)} - {formatHourMinute(meetingBlock.end)}
                </div>
              )
            })
          }

          {
            this.state.meetingBlocks.length === 0 &&
            <div className="border_box max_width top_margin">
              {this.api.trTxt(TrVar.YouHaveNoAvailableMeetingTimes)}
            </div>
          }
        </div>
      </div>
    )
  }
}
