export enum LogEventType {
  PAGE_VIEW = 6,
  COMPONENT_VIEW = 17,
  COMPONENT_CLICK = 18,
}

export interface LogEventInterface {
  eventType: LogEventType
  eventData: any
}
