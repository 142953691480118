import {
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react'
import React from 'react'
import Api from "../../services/Api"
import {AreaInterface} from "../../interfaces/Area"
import {LanguageInterface} from "../../interfaces/Language"
import BarChart from '../graphics/BarChart';
import { TrVar } from "../../services/translate";
import Text from '../Text/Text';
import './edit.css'

interface ComponentProps{
  save: Function
  who: string
  saveText: string
  cancelButton: boolean
  hideAreas?: boolean
  hideCoachLanguage?: boolean
  hideLanguage?: boolean
  onboarding?: boolean
}

interface ComponentState {
  areas: Array<AreaInterface>
  languages: Array<LanguageInterface>
  showMore: Array<boolean>
  error: string
  runCalculating: boolean
  calculatingText: string
}

export default class EditAreas extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    areas: [] as Array<AreaInterface>,
    languages: [] as Array<LanguageInterface>,
    showMore: [],
    error: '',
    runCalculating: false,
    calculatingText: ''
  }

  componentDidMount() {
    const url = this.props.who + '?areas=1&i18n=1'
    this.api.get(url).then(response => {
      const showMore = []
      for (let i = 0; i < response.json.areas.length; i++) {
        showMore.push(false)
      }
      this.setState({
        areas: response.json.areas,
        languages: response.json.i18n.languages,
        showMore: showMore,
      })
      // Run check to see if anything is checked
      this.selectArea(0)
    })
  }

  private toggleShowMore = (index:number) => {
    const showMore = this.state.showMore as Array<boolean>
    showMore[index] = !showMore[index]
    for (let i = 0; i < showMore.length; i++) {
      if (i !== index) {
        showMore[i] = false
      }
    }
    this.setState({showMore: showMore})
  }

  private selectArea = (id: number) => {
    let areas = this.state.areas
    for (let area of areas) {
      if (area.id === id) {
        area.selected = !area.selected
      }
    }
    this.setState({areas: areas})
  }

  private selectLanguage = (key: string) => {
    let languages = this.state.languages
    for (let language of languages) {
      if (language.key === key) {
        language.selected = !language.selected
      }
    }
    this.setState({languages: languages})
  }

  startCalculatingText = () => {
    setTimeout(() => {
      this.setState({calculatingText: this.api.trTxt(TrVar.MatchingWithCoaches) + '...'})
    }, 1500)
  }

  private save = () => {

    const areas = []
    const languages = []
    const url = this.props.who + '_save2'
    for (const area of this.state.areas) {
      if (area.selected) {
        areas.push(area.id)
      }
    }

    for (const language of this.state.languages) {
      if (language.selected) {
        languages.push(language.key)
      }
    }

    if (!this.props.hideAreas && areas.length === 0) {
      if (this.props.who === 'client') {
        this.setState({error: this.api.trTxt(TrVar.YouMustSelectOneAreaToCoach)})
      } else {
        this.setState({error: this.api.trTxt(TrVar.YouMustSelectOneAreaToBeCoached)})
      }
      return
    }

    if (!this.props.hideCoachLanguage && languages.length === 0) {
      this.setState({error: this.api.trTxt(TrVar.YouMustSelectOneLanguage)})
      return
    }

    setTimeout(() => {
      this.startCalculatingText()
      this.setState({
        runCalculating: true,
        calculatingText: this.api.trTxt(TrVar.ScanningCoaches) + '...' 
      })
    }, 250)

    let data: any
    if (this.props.hideCoachLanguage) {
      data = {
        developmentAreas: {
          areas: areas,
        },
      }
    } else {
      data = {
        developmentAreas: {
          areas: areas,
        },
        i18n: {
          languages: languages
        }
      }
    }

    this.api.post(url, data).then(_response => {
      setTimeout(() => this.props.save(true, this.state.areas), 3000)    
    })
  }
  
  render() {
    const header = this.props.who === 'coach' ? this.api.trTxt(TrVar.WantToCoachIn) :
      this.props.hideCoachLanguage ?
        this.api.trTxt(TrVar.ChooseAreaOfDevelopment)
        :
        this.api.trTxt(TrVar.ChooseAreaOfDevelopmentAndLanguages)
    return (
      <>
        {
          !this.props.hideAreas &&
          <>
            <h1>{header}</h1>
            <div className="page_bar" />
            <Text color="gray"  className="r_top_margin content_width">
              {this.api.trTxt(TrVar.ChooseInterestingAreasOfDevelopment)}
            </Text>
            <Text color="gray" className="top_margin content_width">
              {this.api.trTxt(TrVar.YouWillBeMatchedWithACoachBasedOnTheAreas) + ' '}
            </Text>
            <h2 className="top_margin">
              {this.api.trTxt(TrVar.CoachingAreas)}
            </h2>
            <div className='r-half-col'>
              <IonGrid className="mt-4">
                <IonRow>
                  {
                    this.state.areas.map((area: AreaInterface, index) => {
                      let selectBox = 'select_box'
                      if (area.selected) {
                        selectBox += " selected"
                      }
                      return (
                        <IonCol className="mt-4" key={area.id} size="12" sizeMd="6">
                          <div className="flex">
                            {
                              this.props.who === 'client' &&
                              <div>
                                <img alt="expand" src="/assets/icon/arrow_down.svg"
                                  onClick={() => this.toggleShowMore(index)} className="icon_small cursor-pointer svg_white mr-2" />
                                {
                                  this.state.showMore[index] &&
                                  <div className="bubble_container">
                                    <div className="arrow_left_blue" />
                                    <div className="blue_bubble">
                                      {area.moreInfo}
                                    </div>
                                  </div>
                                }
                              </div>
                            }

                            <div onClick={() => this.selectArea(area.id)}>
                              <div className={selectBox} /> <div className="ml-2 inline_block">{area.name}</div>
                            </div>
                          </div>
                        </IonCol>
                      )
                    })
                  }
                </IonRow>
              </IonGrid>
            </div>

          </>
        }


        {
          !this.props.hideCoachLanguage &&
          <>
            <h2 className="mt-8">
              {this.props.who === 'client' ? this.api.trTxt(TrVar.SelectLanguageOfYourCoach) : this.api.trTxt(TrVar.SelectLanguagesYouCanCoachIn)}
            </h2>

            <div className="r-half-col">
              <IonGrid className="mt-4">
                <IonRow>
                  {
                    this.state.languages.map((language: LanguageInterface) => {
                      let selectBox = 'select_box'
                      if (language.selected) {
                        selectBox += " selected"
                      }
                      return (
                        <IonCol className="mt-4" key={language.key} size="12" sizeMd="6"
                          onClick={() => this.selectLanguage(language.key)}>
                          <div className={selectBox} />
                          <div className="ml-2 inline_block">{language.name}</div>
                        </IonCol>
                      )
                    })
                  }
                </IonRow>
              </IonGrid>
            </div>

          </>
        }

        {
          this.state.error.length > 0 &&
          <div className="mt-4 error_box">
            {this.state.error}
          </div>
        }

        <div className=" my-8">
          <div className="button green mobile_size_btn inline_block" onClick={() => this.save()}>
            {this.props.onboarding ?
              this.api.trTxt(TrVar.MatchWithCoach)
              :
              this.props.saveText
            }
          </div>
          {
            this.props.cancelButton &&
            <div className="button red inline_block ml-4" color="danger" onClick={() => this.props.save(false, [])}>
              {this.api.trTxt(TrVar.Cancel)}
            </div>
          }
        </div>
        {this.state.runCalculating &&
          <div className='onboarding_modal'>
            <div className="onboarding_modal_content">
              <div className='text_center' style={{ maxWidth: '550px', margin: '0 auto' }}>
                <div style={{ marginBottom: '40px' }}>{this.state.calculatingText}</div>
                <BarChart value={100} size="transition_linear_width_3s medium" oneColor={true} showValue={false} />
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}
