import React, {useState, useEffect, useRef} from 'react';
import Api from '../../services/Api';
import SvgAnimation from '../SvgAnimation';

interface ComponentProps {
  progress: Function
}

const BehaviourOutro = (props: ComponentProps) => {
  const api = Api.getInstance();
  const [contentWidth, setContentWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Measure the width of the content container
    if (contentRef.current && contentRef.current.offsetWidth) {
      setContentWidth(contentRef.current.offsetWidth || 0 );
    }
  }, [props]); // Re-run when props change

  
  return (
    <div className='page_section flex flex-col justify-center'>
      <div style={{ width: contentWidth }} className="modal_animation mx-auto">
        <SvgAnimation animationType='star' />
      </div>
      <div style={{ maxWidth: '331px', margin: '0 auto' }} className="text_center" ref={contentRef}>
        {api.lang === 'sv' ?
          <>
            <h1>Bra jobbat!</h1>
            <div className='top_margin'>
              Du är klar med beteendeanalysen och redo för din potentialmätning.
            </div>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress(4)} className="button mobile_size_btn green inline_block">
                Nästa
              </div>
            </div>
          </>
          :
          <>
            <h1>Well Done!</h1>
            <div className='top_margin'>
              You are finished with your behavioural analysis, time to measure your potential.
            </div>
            <div style={{ marginTop: '40px' }}>
              <div onClick={() => props.progress(4)} className="button mobile_size_btn green inline_block">
                Next
              </div>
            </div>
          </>}
      </div>
    </div>
  )
}

export default BehaviourOutro;
