import React, { useState, useEffect } from 'react'
import {
  FocusPlanInterface,
  PotentialQuestionInterface,
  FocusAreaInterface
} from "./interfaces";
import Api from "../../services/Api"
import { getDateLong, isSameDate } from "../../helpers/dateFunctions";
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from "../../services/translate"
import {IonLabel, IonSegment, IonSegmentButton} from "@ionic/react";

interface ComponentProps {
  plans: Array<FocusPlanInterface>
  potentialQuestion: PotentialQuestionInterface
  baseUrl: string
  navigation: any
  focusArea: FocusAreaInterface
}

const TaskSummary = (props: ComponentProps) => {
  const api = Api.getInstance();
  const [segment, setSegment] = useState(1);
  const [plan, setPlan] = useState({} as FocusPlanInterface);
  const [showMultipleDates, setShowMultipleDates] = useState(false);
  const [sameDate, setSameDate] = useState(Date);
  
  let dateArray = ['', '', '', ''];

  useEffect(() => {
    props.plans.forEach((plan) => {
      if (plan.coachQuestionId === props.potentialQuestion.coachQuestions[0].id) {
        setPlan(plan);
      }
    })

    const checkDates = () => {
      props.potentialQuestion.coachQuestions.forEach((question, i) => {
        if (question.createdAt) {
          dateArray[i] = new Date(question.createdAt).toISOString().split('T')[0]
          setSameDate(new Date (question.createdAt).toISOString().split('T')[0])
        }
      }) 
      if (isSameDate(new Date(dateArray[0]), new Date(dateArray[dateArray.length - 1]))) {
        setShowMultipleDates(true);
      }
    }

    checkDates()
  })

  const toggleSegment = (segmentStr: string|undefined|null) => {
    let segment = 1
    if (segmentStr) {
      segment = parseInt(segmentStr)
    }
    setSegment(segment)
  }

  const breadcrumbs = [];
  breadcrumbs.push({ name: api.trTxt(TrVar.DevelopmentAreas), link: props.baseUrl });
  breadcrumbs.push({ name: props.focusArea.name, link: props.baseUrl + props.focusArea.focusArea + "/" });
  breadcrumbs.push({
    name: api.trTxt(TrVar.Exercise),
    link: props.baseUrl + props.focusArea.focusArea + "/" + props.potentialQuestion.question.id
  })

  return (
    <>
      <Breadcrumbs name={api.trTxt(TrVar.Summary)} breadcrumbs={breadcrumbs} />
      <div className='page_content'>
        <div className="page_section">
          <h1>{api.lang === 'sv' ? 'Sammanfattning' : `Summary`}</h1>
          <div className="page_bar" />
          <div className="top_margin inline_block">
            <IonSegment value={segment + ''} mode="ios"
                        onIonChange={(e) => toggleSegment(String(e.target.value))}>
              <IonSegmentButton id='summarySegmentBtn' value="1">
                <IonLabel>{api.lang === 'sv' ? 'Dina svar' : 'Your answers'}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton id='summarySegmentBtn' value="2">
                <IonLabel>{api.lang === 'sv' ? 'Din plan' : 'Your plan'}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton id='summarySegmentBtn' value="3">
                <IonLabel>{api.lang === 'sv' ? 'Uppföljning' : 'Summary'}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div className="task_summary double_top_margin">
            {segment === 1 &&
              <>             
                {props.potentialQuestion.coachQuestions.map((question, i) => {
                  return (
                    <div key={question.id + Math.random()} style={{ marginTop: (i > 0 ? '60px' : '') }}>
                     { !showMultipleDates && question.createdAt ?
                        <div className="top_margin blue_sky font_small">{getDateLong(question.createdAt, api.lang)}</div>
                        : i === 0 ?
                        <div className="top_margin blue_sky font_small">{getDateLong(sameDate, api.lang)}</div>
                        : ''
                      }
                      <div className='top_margin'>{(api.lang === 'sv' && i === 0) ?
                        'Reflektion'
                        : (api.lang === 'sv' && i === 1) ?
                          'Önskeläge'
                          : (api.lang === 'sv' && i === 2) ?
                            'Beteende'
                            : (api.lang === 'sv' && i === 3) ?
                              'Insikt'
                              : (api.lang === 'en' && i === 0) ?
                                `Reflection`
                                : (api.lang === 'en' && i === 1) ?
                                  `Desired state`
                                  : (api.lang === 'en' && i === 2) ?
                                    `Behaviour`
                                    : `Insight`
                      }</div>
                    <h3>{question.question}</h3>
                      <div style={{ fontStyle: 'italic' }} className='top_margin'>{question.answer}</div>
                    </div>
                  )
                })}
              </>
            }

            {segment === 2 &&
              <>
              {plan.createdAt &&
                <div className="font_small blue">{new Date(plan.createdAt).toISOString().split('T')[0]}</div>

              }
                <h4>{api.lang === 'sv' ? 'Vad du ska göra' : `What you are going to do`}</h4>
                <div style={{ fontStyle: 'italic' }} className="top_margin">
                  {plan.plan}
                </div>
              </>
            }

            {segment === 3 &&
              <>
                <div className="font_small blue">{getDateLong(plan.updatedAt, api.lang)}</div>
                <h4>{api.lang === 'sv' ? 'Genomförde du din plan?' : `Did you complete your plan?`}</h4>
                <div style={{ fontStyle: 'italic' }} className="top_margin">
                  {(api.lang === 'sv' && plan.done === 1) ? 'Ja' : (api.lang === 'sv' && plan.done === 2) ? 'Delvis' : api.lang === 'sv' ? 'Nej' : ''}
                  {(api.lang === 'en' && plan.done === 1) ? `Yes` : (api.lang === 'en' && plan.done === 2) ? `Well, partially` : api.lang === 'en' ? `No` : ''}
                </div>
                <h4 className='double_top_margin'>
                  {(api.lang === 'sv' && plan.done === 1) ? 'Härligt! Vilka insikter och lärdomar kan du ta med dig framåt?' : (api.lang === 'sv' && plan.done === 2) ? 'Vad var det som gjorde att planen inte blev genomförd fullt ut?' : api.lang === 'sv' ? 'Vad var det som gjorde att planen inte blev genomförd?' : ''}
                  {(api.lang === 'en' && plan.done === 1) ? `Lovely! What insights and lessons can you take with you moving forward?` : (api.lang === 'en' && plan.done === 2) ? `What caused the plan not to be fully implemented?` : api.lang === 'en' ? `What got in the way of the execution of the plan?` : ''}
                </h4>
                <div style={{ fontStyle: 'italic' }} className="top_margin">
                {(plan.done === 1 || plan.done === 2) && 
                  <>
                    {plan.feedback}
                  </>  
                }

                {plan.done === 3 &&
                  <>
                  {(api.lang === 'sv' && plan.notDoneReason === 1) ? 'Glömde bort' : (api.lang === 'sv' && plan.notDoneReason === 2) ? 'För svårt/stort' : (api.lang === 'sv' && plan.notDoneReason === 3) ? 'Inte relevant längre' : (api.lang === 'sv' && plan.notDoneReason === 4) ? 'Hann inte' : api.lang === 'sv' ? 'Annat' : ''}
                  {(api.lang === 'en' && plan.notDoneReason === 1) ? `I forgot` : (api.lang === 'en' && plan.notDoneReason === 2) ? `Too difficult/overwhelming` : (api.lang === 'en' && plan.notDoneReason === 3) ? `No longer relevant` : (api.lang === 'en' && plan.notDoneReason === 4) ? `I didn't have time` : api.lang === 'en' ? `Other` : ''}
                  </>
                }
                </div>
                <h4 className='double_top_margin'>{api.lang === 'sv' ? 'Är det något annat kring din plan du vill lägga till?' : `Is there anything else you would like to add to your plan?`}</h4>
                <div style={{ fontStyle: 'italic' }} className="top_margin">
                  {plan.feedbackOther}
                </div>
              </>
            }
          </div>
        </div>
          
      </div>
    </>

  )
}

export default TaskSummary;
