import React from 'react'
import Api from "../../services/Api"
import "./Tasks.css"
import {
  FocusAreaInterface,
  PotentialQuestionInterface,
  FocusPlanInterface,
} from "./interfaces";
import {IonModal} from "@ionic/react";
import {formatDate } from "../../helpers/dateFunctions";
import Breadcrumbs from "../menu/Breadcrumbs";
import { changeUrl } from '../../services/Navigation';
import SvgAnimation from '../SvgAnimation';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  navigation: any
  scrollRef: any
  baseUrl: string
  clientName: string
  focusArea: FocusAreaInterface
  potentialQuestion: PotentialQuestionInterface
  plans: Array<FocusPlanInterface>
  updatePlan: Function
  isCardDone: Function
  doneStatements: number
  answeredQuestions: Function
  toggleStatement: Function
  toggleFollowup: Function
}

interface ComponentState {
  error: string
  page: string
  plan: FocusPlanInterface
  showDoneModal: boolean
  thankyouModal: boolean
  kindaCompleteModal: boolean
}

export default class TaskPlanFollowUp extends React.Component<ComponentProps, ComponentState>  {
  private api = Api.getInstance()
  doneStatements = this.props.doneStatements;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      page: '',
      plan: {} as FocusPlanInterface,
      error: '',
      showDoneModal: false,
      thankyouModal: false,
      kindaCompleteModal: false,
    }
  }

  componentDidMount() {
    this.props.toggleFollowup(false)
    this.bootPlan()
    this.props.toggleStatement(false)
  }

  bootPlan = () => {
    const plan = this.findPlan(this.props.potentialQuestion.coachQuestions[0].id)
    this.setState({ plan: plan })
  }

  findPlan = (coachQuestionId: number):FocusPlanInterface => {
    for (const plan of this.props.plans) {
      if (plan.coachQuestionId === coachQuestionId) {
        this.setState({page: 'start'})
        return plan
      }
    }

    return {} as FocusPlanInterface
  }

  savePlan = () => {
    const data = {
      id: this.state.plan.id,
      coach_question_id: this.state.plan.coachQuestionId,
      plan: this.state.plan.plan,
      when: this.state.plan.when,
      followup: this.state.plan.followUp,
      reminder_at: this.state.plan.reminderAt,
      plan_completed: this.state.plan.planCompleted,
      done: this.state.plan.done,
      not_done_reason: this.state.plan.notDoneReason,
      feedback: this.state.plan.feedback,
      feedback_other: this.state.plan.feedbackOther,
      followup_completed: this.state.plan.followupCompleted,
    }

    this.api.post('potential_plan/save', data).then(_result => {
      console.log('saved plan')
    })      
  }

  handleAnswer = (e: any, position: number) => {
    const plan = this.state.plan
    if (position === 0) {
      plan.plan = e.target.value
    } else if (position === 1) {
      plan.when = e.target.value
    }
    this.setState({plan: plan})
    const h = e.target.scrollHeight > 270 ? 270 : e.target.scrollHeight
    e.target.style.height = h + "px"
  }

  getBreadcrumbs = () => {
    let breadcrumbs = []
    if (this.props.navigation.client) {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.props.clientName, link: '/app/coach/clients/' + this.props.navigation.client})
    }
    return breadcrumbs
  }

  checkPlan = () => {
    this.savePlan()
    if (this.state.plan.done === 3) {
      this.setState({ page: 'did_not_complete' })
    } else {
      this.setState({ page: 'did_complete' })
    }
  }

  checkNewPlan = () => {
    this.savePlan()
    this.setState({ page: 'set_new_plan'})
  }

  checkFeedback = () => {
    this.savePlan()
    this.setState({ page: 'other_feedback' });
  }

  checkFeedback2 = () => {
    this.savePlan()
    if (this.state.plan.done === 1) {
     this.setState({ showDoneModal: true });     
    } else {
      this.setState({ page: 'want_continue' });    
    }
  }

  goBack = () => {
    changeUrl(`/app/client/tasks2/` + this.props.navigation.sub1 + `/` + this.props.navigation.sub2)        
  }
  
  goForward = () => {
    this.setState({
      showDoneModal: false,
      thankyouModal: false,
      kindaCompleteModal: false,
      plan: {
        ...this.state.plan,
        followupCompleted: true
      }
    }, () => {
      this.props.isCardDone(true)
      this.doneStatements += 1;
      this.savePlan()      
      this.props.answeredQuestions()
      this.props.toggleStatement(true)
      this.props.updatePlan(this.state.plan)
      setTimeout(() => changeUrl(`/app/client/tasks2/` + this.props.navigation.sub1 + `/`), 250)
    })
  }

  goBackandSave = () => {
    this.props.toggleFollowup(true)
    this.savePlan()
    this.goBack()
  }

  closeModal = () => {
    this.setState({
      showDoneModal: false,
      thankyouModal: false,
      kindaCompleteModal: false,
    })
    setTimeout(() => changeUrl(`/app/client/tasks2/` + this.props.navigation.sub1 + `/`), 250)
  }

  finishNotComplete = () => {
    this.setState({
      plan: {
        ...this.state.plan,
        followupCompleted: true
      },
      thankyouModal: true
    }, () => {
      this.savePlan();
      this.props.isCardDone(true)
      this.doneStatements += 1;    
      this.props.answeredQuestions()
      this.props.toggleStatement(true)
      this.props.updatePlan(this.state.plan)
    })
  }

  changeStatus = (status: number) => {
    let followupCompleted = status === 1 ? true : this.state.plan.followupCompleted
    this.setState({
      plan: {
        ...this.state.plan,
        done: status,
        followupCompleted: followupCompleted
      }
    })
  }

  changeErrorStatus = (status: number) => {
    this.setState({
      plan: {
        ...this.state.plan,
        notDoneReason: status,
      }
    })
  }

  updateFeedback = (e: any) => {
    this.setState({
      plan: {
        ...this.state.plan,
        feedback: e.target.value,
      }
    })    
  }

  updateFeedbackOther = (e: any) => {
    this.setState({
      plan: {
        ...this.state.plan,
        feedbackOther: e.target.value,
      }
    })    
  }

  sortaDone = () => {
    this.setState({
      plan: {
        ...this.state.plan,
        followupCompleted: true
      },
      kindaCompleteModal: true,
    }, () => {
      this.savePlan();
      this.props.isCardDone(true)
      this.doneStatements += 1;
      this.props.answeredQuestions()
      this.props.toggleStatement(true)    
      this.props.updatePlan(this.state.plan)
    })
  }
  
  showStart = () => {
    return (
      <>
        <h1>{this.api.lang === 'sv' ? 'Uppföljning' : `Follow-up`}</h1>
        <div className="page_bar" />

        <h2 className="double_top_margin">
          {this.api.lang === 'sv' ? 'Din plan' : `Your plan`}
        </h2>

        <h4 className="top_margin">
          {this.api.lang === 'sv' ? 'Vad du ska göra' : `What you are going to do`}
        </h4>
        <div className="whitespace-pre-line">
          {this.state.plan.plan}
        </div>

        <h4 className="top_margin">
          {this.api.lang === 'sv' ? 'När du ska göra det' : `When you are going to do it`}

        </h4>
        <div className="whitespace-pre-line">
          {this.state.plan.when}
        </div>

        <h4 className="top_margin">
          { this.api.lang === 'sv' ? 'Uppföljning' : `Follow-up`}
        </h4>
        <div className="whitespace-pre-line">
          {this.state.plan.followUp}
        </div>
        {
          this.state.plan.reminderAt &&
            <>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ? 'Påminnelse' : `Reminder`}
                </h4>
                <div className="whitespace-pre-line">
                  {formatDate(this.state.plan.reminderAt, true, this.api.lang)}
                </div>
            </>
        }
        <div className="btn_top_margin button green inline_block" onClick={() => this.setState({page: 'ask_done'})}>
          {this.api.lang === 'sv' ? 'Följ upp' : `Follow up`}
        </div>
        <div className="btn_top_margin left_margin button white inline_block" onClick={() => this.goBack()}>
          {this.api.lang === 'sv' ? 'Se svar' : `Review answers`}
        </div>
      </>
    )
  }

  askDone = () => {
    return (
      <>
        <h2>{this.api.lang === 'sv' ? 'Genomförde du din plan?' : `Did you complete your plan?`}</h2>
        <div className="flex flex-col">
          <div className="top_margin flex">
            <div onClick={() => this.changeStatus(1)} className={"followup_radio_btn " + (this.state.plan.done === 1 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Ja' : 'Yes'}!</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeStatus(2)} className={"followup_radio_btn " + (this.state.plan.done === 2 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Nja, delvis' : 'Well, partially'}</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeStatus(3)} className={"followup_radio_btn " + (this.state.plan.done === 3 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Nej' : 'No'}</div>
          </div>
        </div>      
        <div className="flex">
          <div onClick={() => this.checkPlan()} className={"btn_top_margin inline_block green button " + (this.state.plan.done === 0 ? 'button_disabled' : '')}>
            {this.api.trTxt(TrVar.Next)}
          </div>     
          <div onClick={() => this.setState({ page: 'start' })} className={"btn_top_margin inline_block white button left_margin" }>
            {this.api.trTxt(TrVar.Back)}
          </div>             
        </div>
      </>
    )
  }

  showComplete = () => {
    let title = () => {
      if (this.state.plan.done === 1) {
        if (this.api.lang === 'sv') {
          return 'Härligt! Vilka insikter och lärdomar kan du ta med dig framåt?'
        } else {
          return `Lovely! What insights and lessons can you take with you moving forward?`
        }
      } else {
        if (this.api.lang === 'sv') {
          return 'Vad var det som gjorde att planen inte blev genomförd fullt ut?'
        } else {
          return `What caused the plan not to be fully implemented?`
        }        
      }
    }

    return (
      <>
        <h2>{title()}</h2>
        <div className="flex flex-col">
          <textarea
            className='page_text_area double_top_margin xl grey'
            value={this.state.plan.feedback}
            onChange={this.updateFeedback}      
            placeholder={this.api.lang === 'sv' ? 'Skriv ditt svar här.' : `Enter your response here.`}
          />
          <div className='flex'>
            <div onClick={() => this.checkFeedback()} className={"button inline_block btn_top_margin green " + (this.state.plan.feedback.length <= 0 ? 'button_disabled' : '')}>
              {this.api.trTxt(TrVar.Next)}
            </div>       
            <div onClick={() => this.setState({ page: 'ask_done' })} className={"btn_top_margin inline_block white button left_margin" }>
              {this.api.trTxt(TrVar.Back)}
            </div>              
          </div>
        </div>
      </>
    )
  }

  showOtherFeedback = () => { 
    return (
      <>
        <IonModal isOpen={this.state.showDoneModal} className="modal_tall">
          <img onClick={() => this.goForward()} className='absolute modal_close'
               src="/assets/focusArea/cross_black.svg" height={'20px'} alt="" />
          <div className="followup_modal flex flex-col justify-center">
            <div className="modal_animation mx-auto">
              <SvgAnimation animationType='checkmark' />           
            </div>
            <h1>{this.api.lang === 'sv' ? 'Bra jobbat!' : `Well done!`}</h1>
            <div style={{ maxWidth: '450px' }} className="top_margin text_center">
              {this.api.lang === 'sv' ?
                'Varje plan och uppföljning är viktiga steg mot att nå din fulla potential.'
                :
                `Each plan and follow-up are important steps in the journey towards your full potential.`
              }
            </div>
            <div onClick={() => this.goForward()} className='button green btn_top_margin inline_block'>
              {this.api.trTxt(TrVar.GoForward)}
            </div>
          </div>
        </IonModal>        
        <h2>{this.api.lang === 'sv' ? 'Är det något annat kring din plan du vill lägga till?' : `Is there anything else you would like to add to your plan?`}</h2>
        <div className="flex flex-col">
          <textarea
            className='page_text_area double_top_margin xl grey'
            value={this.state.plan.feedbackOther}
            onChange={this.updateFeedbackOther}      
            placeholder={this.api.lang === 'sv' ? 'Skriv ditt svar här.' : `Enter your response here.`}
          />  
          <div>
            <div onClick={() => this.checkFeedback2()} className={"button inline_block btn_top_margin green " }>
              {this.api.trTxt(TrVar.Next)}
            </div>    
            <div onClick={() => this.setState({ page: 'did_complete' })} className={"btn_top_margin inline_block white button left_margin" }>
              {this.api.trTxt(TrVar.Back)}
            </div>              
          </div>
        </div>
      </>
    )    
  }  

  showNotComplete = () => {
    return (
      <>
        <h2>{this.api.lang === 'sv' ? 'Vad var det som gjorde att planen inte blev genomförd?' : `What got in the way of the execution of the plan?`}</h2>
        <div className="flex flex-col">
          <div className="top_margin flex">
            <div onClick={() => this.changeErrorStatus(1)} className={"followup_radio_btn " + (this.state.plan.notDoneReason === 1 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Glömde bort' : 'I forgot'}!</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeErrorStatus(2)} className={"followup_radio_btn " + (this.state.plan.notDoneReason === 2 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'För svårt/stort' : `Too difficult/overwhelming`}</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeErrorStatus(3)} className={"followup_radio_btn " + (this.state.plan.notDoneReason === 3 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Inte relevant längre' : 'No longer relevant'}</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeErrorStatus(4)} className={"followup_radio_btn " + (this.state.plan.notDoneReason === 4 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.lang === 'sv' ? 'Hann inte' : `I didn't have the time`}</div>
          </div>
          <div className="top_margin flex">
            <div onClick={() => this.changeErrorStatus(5)} className={"followup_radio_btn " + (this.state.plan.notDoneReason === 5 ? 'selected' : '')} />
            <div style={{ marginLeft: '10px' }}>{this.api.trTxt(TrVar.Other)}</div>
          </div>          
        </div>
        <div className='flex'>
          <div onClick={() => this.checkNewPlan()} className={"btn_top_margin inline_block green button " + (this.state.plan.notDoneReason === 0 ? 'button_disabled' : '')}>
            {this.api.trTxt(TrVar.Next)}
          </div>       
          <div onClick={() => this.setState({ page: 'ask_done' })} className={"btn_top_margin inline_block white button left_margin" }>
            {this.api.trTxt(TrVar.Back)}
          </div>            
        </div>

      </>
    )
  }

  showNewPlan = () => {
    let pageNr = this.state.plan.notDoneReason
    let title = () => {
      if (pageNr === 1) {
        if (this.api.lang === 'sv') {
          return 'Vill du ställa in en ny påminnelse eller ändra något annat?'
        } else {
          return `Would you like to set a new reminder or change something (else)?`
        }
      } else if (pageNr === 2) {
        if (this.api.lang === 'sv') {
          return (
            <div style={{ maxWidth: '892px' }}>
              <div>{this.api.lang === 'sv' ? 'Det låter som att du behöver planera något som är lite enklare att genomföra.' : `It sounds like you should plan something that is a little easier to implement?`}</div>
              <div className="top_margin">{this.api.lang === 'sv' ? 'Vill du gå tillbaka och ändra din plan?' : `Do you want to change anything in your plan?`}</div>
            </div>
          )
        } else {
          return ``
        }        
      } else if (pageNr === 3) {
        if (this.api.lang === 'sv') {
          return 'Vill du gå tillbaka och göra en annan plan?'
        } else {
          return `Would you like to create another plan?`
        }        
      } else if (pageNr === 4) {
        if (this.api.lang === 'sv') {
          return 'Känner du dig klar med övningen?'
        } else {
          return `Do you feel ready with the exercise?`
        }        
      } else {
        if (this.api.lang === 'sv') {
          return 'Vad var anledningen?'
        } else {
          return `What was the reason?`
        }        
      }
    }

    return (
      <>
        <IonModal isOpen={this.state.thankyouModal} className="modal_tall">
          <img onClick={() => this.setState({ thankyouModal: false })} className='absolute modal_close'
               src="/assets/focusArea/cross_black.svg" height={'20px'} alt="" />
          <div className="followup_modal flex flex-col justify-center">
            <div className="modal_animation mx-auto">
              <SvgAnimation animationType='checkmark' />
            </div>
            <h1>{this.api.lang === 'sv' ? 'Ta nästa chans!' : `Take the next opportunity!`}</h1>
            <div style={{ maxWidth: '350px' }} className="top_margin text_center">
              {this.api.lang === 'sv' ?
                'Är du redo att ta dig an nya utmaningar?'
                :
                `Are you ready to take on new challenges?`
              }
            </div>
            <div onClick={() => this.closeModal()} className='button green btn_top_margin inline_block'>
              {this.api.trTxt(TrVar.GoForward)}
            </div>
          </div>
        </IonModal>
        <h2>{title()}</h2>
        <div className="flex flex-col">
          {this.state.plan.notDoneReason === 5 &&
          <>
            <textarea
                className='page_text_area double_top_margin xl grey'
                value={this.state.plan.feedbackOther}
                onChange={this.updateFeedbackOther}      
                placeholder={this.api.lang === 'sv' ? 'Skriv ditt svar här.' : `Enter your response here.`}
            />             
            <div>
          <div onClick={() => this.setState({ page: 'did_not_complete' })} className={"btn_top_margin inline_block white button" }>
            {this.api.trTxt(TrVar.Back)}
          </div>                 
            </div>
            <h4 className='double_top_margin'>{this.api.lang === 'sv' ? 'Vill du försöka med planen igen?' : `Do you try your plan again?`}</h4>
          </>
          }          
          <div>
            <div onClick={() => this.finishNotComplete()} className="btn_top_margin inline_block button green">
              {this.api.lang === 'sv' ? 'Ja' : 'Yes'}
            </div>
            <div onClick={() => this.goBackandSave()} className="button white btn_top_margin left_margin inline_block">
              {this.api.lang === 'sv' ? 'Nej' : 'No'}
            </div>
          </div>
        </div>
      </>
    )
  }

  showWantToContinue = () => {
    return (
      <>
        <IonModal isOpen={this.state.kindaCompleteModal} className="modal_tall">
          <img onClick={() => this.setState({ kindaCompleteModal: false })} className='absolute modal_close'
               src="/assets/focusArea/cross_black.svg" height={'20px'} alt="" />
          <div className="followup_modal flex flex-col justify-center">
            <div className="modal_animation mx-auto">
              <SvgAnimation animationType='checkmark' />               
            </div>
            <h1>{this.api.lang === 'sv' ? 'Bra!' : `Great!`}</h1>
            <div style={{ maxWidth: '450px' }} className="top_margin text_center">
              {this.api.lang === 'sv' ?
                'Att följa upp sina planer är en viktig del i utvecklingsprocessen. '
                :
                `Following up on your plans is an important part of the development process.`
              }
            </div>
            <div onClick={() => this.closeModal()} className='button green btn_top_margin inline_block'>
              {this.api.trTxt(TrVar.GoForward)}
            </div>
          </div>
        </IonModal>        
        <h2>{this.api.lang === 'sv' ? 'Vill du göra om din plan?' : `Do you want to redo your plan?`}</h2>
        <div onClick={() => this.goBackandSave()} className="btn_top_margin inline_block button green">
          {this.api.lang === 'sv' ? 'Ja' : 'Yes'}
        </div>
        <div onClick={() => this.sortaDone()} className="button white btn_top_margin left_margin inline_block">
          {this.api.lang === 'sv' ? 'Nej' : 'No'}
        </div>
      </>
    )
  }

  showError = () => {
    return (
      <>
        <h2>Fatal error!</h2>
        <div className="page_bar" />

      </>
    )
  }

  selectContent = () => {
    if (this.state.page === 'ask_done') {
      return this.askDone()
    } else if (this.state.page === 'did_complete') {
      return this.showComplete()
    } else if (this.state.page === 'other_feedback') {
      return this.showOtherFeedback()
    } else if (this.state.page === 'did_not_complete') {
      return this.showNotComplete()
    } else if (this.state.page === 'set_new_plan') {
      return this.showNewPlan()
    } else if (this.state.page === 'want_continue') {
      return this.showWantToContinue()
    } else if (this.state.page === '') {
      return this.showError()
    } else {
      return this.showStart()
    }
  }

  render() {

    let breadcrumbs = this.getBreadcrumbs()
    breadcrumbs.push({name: this.api.trTxt(TrVar.DevelopmentAreas), link: this.props.baseUrl})
    breadcrumbs.push({name: this.props.focusArea.name, link: this.props.baseUrl + this.props.focusArea.focusArea + "/"})
    breadcrumbs.push({
      name: this.api.trTxt(TrVar.Exercise),
      link: this.props.baseUrl + this.props.focusArea.focusArea + "/" + this.props.potentialQuestion.question.id
    })

    return (
      <>
        <Breadcrumbs name={this.api.lang === 'sv' ? 'Uppföljning' : 'Follow-up'} breadcrumbs={breadcrumbs} />

        <div className="page_content">
          <div className="page_section">
            {this.selectContent()}
          </div>
        </div>
      </>
    );
  }
}
