import { CONFIG } from "../constants"

export default class ExternalLogging {

  static myInstance: ExternalLogging
  enabled = false
  loadAllScripts = false

  simulatePageChange = () => {
    if (this.enabled) {
      // @ts-ignore
      const _hsq = window._hsq = window._hsq || []
      _hsq.push(['setPath', window.location.pathname])
      _hsq.push(['trackPageView'])
      console.log('Sent page load to HubSpot: ' + window.location.pathname)
    }
  }

  enable = () => {
    if (this.enabled) {
      console.log('external logging already enabled')
      return
    }

    const parentScript = document.getElementsByTagName("script")[0]
    if (this.loadAllScripts) {
      this.loadMainScript(parentScript)
    } else {
      this.loadCustomScripts(parentScript)
    }
  }

  loadMainScript = (parentScript: HTMLScriptElement) => {

    if (parentScript) {
      console.log('Loading main hubspot script')
      const startScript = document.createElement('script')
      startScript.src = 'https://js-eu1.hs-scripts.com/25362987.js'
      startScript.type = 'text/javascript'
      parentScript.appendChild(startScript)
      this.enabled = true
    } else {
      console.warn('External logging could not load main script tab')
    }
  }

  loadCustomScript = (parentEl: HTMLScriptElement, id: string, src: string, attrs: any) => {
    if(!document.getElementById(id)){
      const scriptElement = document.createElement("script")
      scriptElement.src = src
      scriptElement.type="text/javascript"
      scriptElement.id=id
      if (attrs) {
        for(const a in attrs) {
          attrs.hasOwnProperty(a) && scriptElement.setAttribute(a, attrs[a])
        }
      }
      parentEl.appendChild(scriptElement)
    }
  }

  loadCustomScripts = (parentScript: HTMLScriptElement) => {
    console.log('Loading custom hubspot scripts')
    this.loadCustomScript(
      parentScript,
      "hs-analytics",
      "https://js-eu1.hs-analytics.net/analytics/1654084200000/25362987.js",
      undefined
    )

    this.loadCustomScript(
      parentScript,
      "hs-ads-pixel-25362987",
      "https://js-eu1.hsadspixel.net/fb.js",
      {
        "data-ads-portal-id":25362987,
        "data-ads-env":"prod",
        "data-loader":"hs-scriptloader",
        "data-hsjs-portal":25362987,
        "data-hsjs-env":"prod",
        "data-hsjs-hublet":"eu1"
      }
    )

    // @ts-ignore
    const _hsp = window._hsp = window._hsp || [];
    _hsp.push(['addEnabledFeatureGates', []]);

    this.loadCustomScript(
      parentScript,
      "cookieBanner-25362987",
      "https://js-eu1.hs-banner.com/25362987.js",
      {
        "data-cookieconsent":"ignore",
        "data-hs-ignore":true,
        "data-loader":"hs-scriptloader",
        "data-hsjs-portal":25362987,
        "data-hsjs-env":"prod",
        "data-hsjs-hublet":"eu1"
      }
    )
    this.enabled = true
  }

  checkStatus = () => {
    const scripts = document.getElementsByTagName('script')
    console.log('Existing scripts on site:')
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i]
      console.log(script.src)
    }
  }

  disable = () => {
    if (!this.enabled) {
      console.log('external logging already disabled')
      return
    }

    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i]
      if (script.parentElement && !script.src.startsWith(CONFIG.APP_URL_START) && script.src.startsWith('https://')) {
        const parent = script.parentElement
        // this.removeScriptChildren(script)
        if (parent) {
          parent.removeChild(script)
        }
      } else {
        console.log('Keeping ' + script.src)
      }
    }

    this.enabled = false
  }

  // removeScriptChildren = (el: HTMLElement) => {
  //   for (let i = 0; i < el.children.length; i++) {
  //     console.log('Removing child ' + el.children[i])
  //     el.removeChild(el.children[i])
  //   }
  // }


  /**
   * @returns {WebSocketClient}
   */
  static getInstance(): ExternalLogging {
    if (ExternalLogging.myInstance == null) {
      ExternalLogging.myInstance = new ExternalLogging()
    }
    return this.myInstance
  }
}
