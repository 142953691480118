import React from 'react'
import Api from "../../services/Api"
import { changeUrl } from "../../services/Navigation";
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import "./Tasks.css"
import Carousel from "../carousel/Carousel";
import {CardInterface} from "../carousel/interfaces";
import Text from "../Text/Text";
import {
  FocusAreaInterface,
  FocusAreaSettingsInterface,
  FocusMeasureInterface,
  FocusPlanInterface,
} from "./interfaces";
import {introTexts} from "./texts"
import OneTask from "./OneTask";
import {
  IonModal,
} from "@ionic/react";
import FocusAreaSettings from "./FocusAreaSettings";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import {CONFIG} from "../../constants";
import TaskPlanFollowUp from "./TaskPlanFollowUp";
import TaskSummary from './TaskSummary';
import { ClientUISettingsInterface } from '../../interfaces/ClientUISettings';
import BarChart from '../graphics/BarChart';
import { TrVar } from "../../services/translate";

interface ComponentProps {
  navigation: any
  scrollRef: any
  isFree: boolean
  clientUISettings: ClientUISettingsInterface
  updateSeenIntro: Function
  updateOneTaskIntro: Function
}

interface ComponentState {
  measures: Array<FocusMeasureInterface>
  measure: FocusMeasureInterface
  focusAreaSettings: Array<FocusAreaSettingsInterface>
  plans: Array<FocusPlanInterface>
  clientName: string
  measureId: number
  loaded: boolean
  position: number
  showFocusIntro: boolean
  showFocusAreaHelp: boolean
  error: string
  showReadMore: boolean
  coachQuestionKey: number
  statementKey: number
  showDeleteTasks: boolean
  allQuestions: boolean
  showFilter: number
  showReadMoreDev: boolean
  showIntroduction: boolean
  runIntroCalculation: boolean
  calculatingText: string
  comesFromFollowup: boolean
}

export default class Tasks2 extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private baseUrl = '/app/client/tasks2/'
  focusArea?: FocusAreaInterface
  chooseNext: boolean = false
  shouldAnimateQuestion = false;
  shouldAnimateStatement = false;
  countNewAnswers = 0;
  isQuestionCardDone = false;
  coachQuestionsCounter = 0;
  animateCoachQuestionsCounter = false;
  animateStatementCounter = false;
  statementsCounter = 0;  
  medalSrc = '';
  trophySrc = '';
    
  state: Readonly<ComponentState> = {
    measures: [],
    measure: {} as FocusMeasureInterface,
    focusAreaSettings: [],
    plans: [],
    loaded: false,
    clientName: '',
    position: 0,
    measureId: 0,
    showFocusIntro: false,
    showFocusAreaHelp: false,
    error: '',
    coachQuestionKey: 0,
    showReadMore: false,
    statementKey: 0,
    showDeleteTasks: false,
    allQuestions: false,
    showFilter: window.location.search.indexOf('filter=followup') > -1 ? 3 : 1,
    showReadMoreDev: false,
    showIntroduction: false,
    runIntroCalculation: false,
    calculatingText: this.api.lang === 'sv' ? 'Analyserar Potentialmätning...' : `Analyzing potential measurement...`,
    comesFromFollowup: false,
  }

  componentDidMount() {
    if (!this.props.clientUISettings.haveSeenDevelopmentAreasIntro) {
      this.setState({ showIntroduction: true })
    } 
    this.api.getProfile()
    this.getData()
    this.shouldAnimateQuestion = false
    this.shouldAnimateStatement = false
    this.countNewAnswers = 0;
    this.isQuestionCardDone = false;
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'development_areas'}
    }
    this.api.post('client_log_event', data).then()
  }

  componentDidUpdate(prevProps: ComponentProps) {   
    if (prevProps.navigation.sub2.length > 0 && this.props.navigation.sub1.length > 1 &&
        this.props.navigation.sub2.length === 0 && this.countNewAnswers > 0) {
      console.log('Animate, countNewAnswers is ' + this.countNewAnswers)
      const animateFrom = this.coachQuestionsCounter - this.countNewAnswers
      this.animateDoneQuestion(animateFrom, this.coachQuestionsCounter)
      this.animateMedal(this.coachQuestionsCounter, true, Math.random())
      if (this.isQuestionCardDone) {
        this.animateStatement(this.statementsCounter, true, Math.random())
        this.animateDoneStatement(this.statementsCounter - 1, this.statementsCounter)
      }
    }
  }

  toggleComesFromFollowup = (followup: boolean) => {
    this.setState({ comesFromFollowup: followup })
  }

  numberAnsweredQuestions = () => {
    this.countNewAnswers = this.countNewAnswers + 1;
  }  

  questionCardDone = () => {
    this.isQuestionCardDone = true;
  }

  getFocusArea = () => {
    if (this.props.navigation.sub1.length < 1) {
      return undefined
    }

    for (const fa of this.state.measure.focusAreas) {
      if (fa.focusArea === this.props.navigation.sub1) {
        return fa
      }
    }
    return undefined
  }

  updatePlan = (updatedPlan: FocusPlanInterface) => {
    const plans = this.state.plans
    let foundPlan = false
    for (let i = 0; i < plans.length; i++) {
      let plan = plans[i];
      if (plan.coachQuestionId === updatedPlan.coachQuestionId) {
        plans[i] = updatedPlan
        foundPlan = true
        break
      }
    }
    if (!foundPlan) {
      plans.push(updatedPlan)
    }
    this.setState({ plans: plans })
  }

  animateMedal = (count: number, animate: boolean, i: any) => {
    if (animate) {
      if (count < 1) {
        this.medalSrc = '/assets/animations/ribbon1.svg?i='+i
      } else {
        this.medalSrc = '/assets/animations/ribbon2.svg?i='+i
      }
    } else {
      if (count === 0) {
        this.medalSrc = '/assets/focusArea/medal_gray.svg'
      } else {
        this.medalSrc = '/assets/focusArea/medal_green.svg'
      }
    }
  }  

  animateStatement = (count: number, animate: boolean, i: any) => {    
    if (animate) {
      if (count < 1) {
        this.trophySrc = '/assets/animations/trophy1.svg?i='+i
      } else {
        this.trophySrc = '/assets/animations/trophy2.svg?i='+i
      }
    } else {
      if (count === 0) {
        this.trophySrc = '/assets/focusArea/trophy_gray.svg'
      } else {
        this.trophySrc = '/assets/focusArea/trophy_green.svg'
      }
    }
  }     

  animateDoneQuestion = (start: number, end: number) => {
    let i = start
    this.animateCoachQuestionsCounter = true
    this.coachQuestionsCounter = start
    this.setState({ coachQuestionKey: i })
    const steps = end - start
    let delay = 800
    if (steps > 1) {
      delay = 800 / steps
    }
    const interval = setInterval(() => {
      i++
      this.coachQuestionsCounter = i
      this.setState({ coachQuestionKey: i })
      if (i >= end) {
        clearInterval(interval)
        this.countNewAnswers = 0;
        this.animateCoachQuestionsCounter = false
      }
    }, delay)
  }

  animateDoneStatement = (start: number, end: number) => {
    let i = start
    this.animateStatementCounter = true
    this.statementsCounter = start
    this.setState({ statementKey : i })
    const interval = setInterval(() => {
      i++
      this.statementsCounter = i
      this.setState({ statementKey: i })
      if (i >= end) {
        clearInterval(interval)
        this.animateStatementCounter = false
      }
    }, 800)
  }

  getFocusAreaSettings = () => {
    if (!this.focusArea) {
      return undefined
    }
    for (const fas of this.state.focusAreaSettings) {
      if (fas.areaName === this.focusArea.focusArea) {
        return fas
      }
    }
    return undefined
  }

  getPotentialQuestion = (focusArea?: FocusAreaInterface) => {
    if (!focusArea || this.props.navigation.sub2.length < 1) {
      return undefined
    }

    const questionId = parseInt(this.props.navigation.sub2)

    for (const potentialQuestion of focusArea.potentialQuestions) {
      if (potentialQuestion.question.id === questionId) {
        return potentialQuestion
      }
    }
    return undefined
  }

  private getData = () => {
    let url = 'potential_tasks2'
    this.api.get(url, ['no_measure']).then(response => {
      this.setState({
        measures: response.json.measures,
        measure: response.json.measures[0],
        measureId: response.json.measureId,
        focusAreaSettings: response.json.focusAreaSettings,
        clientName: response.json.name,
        allQuestions: response.json.allQuestions,
        plans: response.json.plans,
        loaded: true
      })
    }).catch(response => {
      if (response.isExpectedError) {
        this.setState({error: 'no_measure', loaded: true})
      }
    })
  }

  openAllAreas = () => {
    this.api.post('all_potential_tasks2', {}).then(response => {
      this.getData()
    })
  }

  private findCardPosition = (focusArea: FocusAreaInterface) => {
    let position = 0
    for (const potentialQuestion of focusArea.potentialQuestions) {
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        // Return position of first potentialQuestion that is not done
        if (!coachQuestion.done) {
          return position
        }
      }
      position++
    }
    // If all potentialQuestions are done, return last
    if (position >= (focusArea.potentialQuestions.length - 1)) {
      position = focusArea.potentialQuestions.length - 1
    }
    return position
  }

  private goToFocusOrIntro = (focusArea: FocusAreaInterface) => {
    let foundFocusSettings = false
    for (const fas of this.state.focusAreaSettings) {
      if (fas.areaName === focusArea.focusArea) {
        foundFocusSettings = true
        break
      }
    }
    if (foundFocusSettings) {
      const position = this.findCardPosition(focusArea)
      this.setState({position: position}, () => this.goTo(focusArea))

    } else {
      this.focusArea = focusArea
      this.setState({showFocusIntro: true, position: 0})
    }
  }

  closeSettings = () => {
    this.setState({showFocusIntro: false})
    setTimeout(() => {
      this.goTo(this.focusArea)
    }, 300)
  }

  private hasStartedFocusArea = (focusArea: FocusAreaInterface) => {
    let foundFocusSettings = false
    for (const fas of this.state.focusAreaSettings) {
      if (fas.areaName === focusArea.focusArea) {
        foundFocusSettings = true
        break
      }
    }
    return foundFocusSettings
  }

  private goTo = (focusArea?: FocusAreaInterface) => {
    let url = this.baseUrl
    if (focusArea) {
      url += focusArea.focusArea + "/"
    }
    changeUrl(url)
  }

  selectQuestion = (questionId: number, position: number) => {
    this.setState({position: position})
    changeUrl(this.props.navigation.url + questionId)
  }

  focusAreaDoneCoachQuestions = (focusArea: FocusAreaInterface) => {
    let done = 0
    for (const potentialQuestion of focusArea.potentialQuestions) {
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        if (coachQuestion.done) {
          done++
        }
      }
    }
    return done
  }

  focusAreaDoneMeasureQuestions = (focusArea: FocusAreaInterface) => {
    let done = 0
    for (const potentialQuestion of focusArea.potentialQuestions) {
      let allDone = true
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        if (!coachQuestion.done) {
          allDone = false
          break
        }
      }
      if (potentialQuestion.loop) {
        allDone = false
        for (const plan of this.state.plans) {
          if (plan.coachQuestionId === potentialQuestion.coachQuestions[0].id) {
            if (plan.followupCompleted) {
              allDone = true;
            }
          }
        }
      }
      if (allDone && potentialQuestion.coachQuestions.length > 0) {
        done++
      }
    }
    return done
  }

  calculateFocusAreasDone = () => {
    let done = 0
    for (const focusArea of this.state.measure.focusAreas) {
      const potentialQuestionsDone = this.focusAreaDoneMeasureQuestions(focusArea)
      if (potentialQuestionsDone > 0 && potentialQuestionsDone === focusArea.potentialQuestions.length) {
        done++
      }
    }

    return done
  }

  calculateFocusAreas = () => {
    let focusAreas = 0
    for (const focusArea of this.state.measure.focusAreas) {
      if (focusArea.score > 1) {
        focusAreas++
      }
    }
    return focusAreas
  }

  calculateDoneCoachQuestions = () => {
    let done = 0
    for (const focusArea of this.state.measure.focusAreas) {
      done += this.focusAreaDoneCoachQuestions(focusArea)
    }
    return done
  }

  calculateDoneMeasureQuestions = () => {
    let done = 0
    for (const focusArea of this.state.measure.focusAreas) {
      done += this.focusAreaDoneMeasureQuestions(focusArea)
    }
    return done
  }

  saveFocusSettings = () => {
    //** TODO: Fix length in week for the API */
    if (this.focusArea) {
      let focusAreaSetting: FocusAreaSettingsInterface|undefined = this.getFocusAreaSettings()
      if (!focusAreaSetting) {
        focusAreaSetting = {
          lengthInWeeks: 4,
          areaName: this.focusArea.focusArea,
          createdAt: new Date().toDateString()
        }
        const focusAreaSettings = this.state.focusAreaSettings
        focusAreaSettings.push(focusAreaSetting)
        this.setState({focusAreaSettings: focusAreaSettings})
      }

      // Save via api
      const data = {
        'length_in_weeks': 4,
        'name': this.focusArea.focusArea
      }
      this.api.post('focus_area_settings/save', data).then((_result) => {
        console.log('saved focus area settings')
      })
    }
  }

  calculateWeek = (start: string) => {
    const d = new Date(start)
    const now = new Date()
    const utcNow = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
    const utcStart = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());

    return 1 + Math.floor((utcNow - utcStart) / (7 * 24 * 3600 * 1000));
  }

  focusAreaButtonClass = (i: number, focusArea: FocusAreaInterface) => {
    if (this.hasStartedFocusArea(focusArea)) {
      if (this.focusAreaDoneMeasureQuestions(focusArea) === focusArea.potentialQuestions.length) {
        return 'white'
      } else {
        return 'green'
      }
    }
    if (i === 0) {
      return 'green'
    }
    return 'white'
  }

  toggleQuestionAnimation = (toggle: boolean) => {
    toggle ? this.shouldAnimateQuestion = true : this.shouldAnimateQuestion = false;
  }  

  toggleStatementAnimation = (toggle: boolean) => {
    toggle ? this.shouldAnimateStatement = true : this.shouldAnimateStatement = false;
  }

  resetTasks = () => {
    this.api.post('reset_potential_tasks2', {}).then(_result => {
      this.setState({showDeleteTasks: false})
      this.getData()
    })

  }

  showDescription = (area: string) => {
    if (area === 'prestation') {
      return this.api.trTxt(TrVar.PerformanceDescription) 
    } else if (area === 'motivation') {
      return this.api.trTxt(TrVar.MotivationDescription)  
    } else if (area === 'stress') {
      return this.api.trTxt(TrVar.StressDescription)
    } else if (area === 'relation') {
      return this.api.trTxt(TrVar.RelationsDescription)
    } else if (area === 'future') {
      return this.api.trTxt(TrVar.FutureDescription) 
    } else {
      return this.api.trTxt(TrVar.SelfInsightDescription) 
    }
  }

  showMenu = () => {
    const focusAreasDone = this.calculateFocusAreasDone()
    let focusAreas = focusAreasDone
    if (focusAreas === 0) {
      if (this.state.allQuestions) {
        focusAreas = 6
      } else {
        focusAreas = this.calculateFocusAreas()
      }
    }

    let totalAreas = 0;

    this.state.measure.focusAreas.forEach(area => {
      if (area.potentialQuestions.length > 0) {
        totalAreas++
      }
    })

    return (
      <div className="page_content ">
        <IonModal isOpen={this.state.showFocusIntro} onDidDismiss={() => this.setState({showFocusIntro: false})}
          className="modal_full">
          <div className="relative" onClick={() => this.setState({showFocusIntro: false})}>
            <img className='absolute modal_close cursor-pointer' src="/assets/icon/white/cross.svg"  height={'20px'} alt="" />
          </div>
          {
            (this.focusArea && this.state.showFocusIntro) &&
            <FocusAreaSettings focusArea={this.focusArea} saveFocusSettings={this.saveFocusSettings}
                               close={() => this.closeSettings()}/>
          }
        </IonModal>
        <IonModal isOpen={this.state.showDeleteTasks} onDidDismiss={() => this.setState({showDeleteTasks: false})}
                  className="modal_small">
          <div className="p-4 color_white">
            <h2>Radera utvecklingsområden</h2>
            <p className="top_margin">Är du säker?</p>
            <p className="top_margin">Allt du har gjort inom utvecklingsområden kommer att raderas</p>
            <div className="top_margin flex justify-between">
              <div className="button red inline_block" onClick={() => this.resetTasks()}>
                Radera
              </div>
              <div className="button green inline_block" onClick={() => this.setState({showDeleteTasks: false})}>
                Ångra
              </div>
            </div>
          </div>
        </IonModal>

        <IonModal isOpen={this.state.showReadMoreDev} onDidDismiss={() => this.setState({ showReadMoreDev: false })}
          className="modal_full">
          <img onClick={() => this.setState({ showReadMoreDev: false })} className='absolute modal_close cursor-pointer' src="/assets/icon/white/cross.svg"  height={'20px'} alt="" />
          <div style={{
            backgroundColor: '#000026',
            color: '#fff',
            height: '100%',
            overflowY: 'scroll',
          }} className='flex justify-center'>
            <div style={{ maxWidth: '598px', padding: '15px' }} className='flex flex-col justify-center'>
              <h1 className='r-top-modal'>{this.api.lang === 'sv' ? 'Om utvecklingsområden' : `About areas of development`}</h1>
              <h4 className="top_margin">
                {this.api.lang === 'sv' ? 'Vad är ett utvecklingsområde?' : `What is an area of development?`}
              </h4>
              <div>
                {this.api.lang === 'sv' ?
                  'Ett utvecklingsområde innehåller övningar som du jobbar med på egen hand i plattformen. Områdena är personligt anpassade för dig efter dina svar i Potentialmätningen.'
                  :
                  `A developmental area contains exercises to help you reach your potential, you'll work on your own in the platform. Based on your answers in your potential measurement, the areas of development are individually adapated for you.`
                }
              </div>
              <h4 className="top_margin">
                {this.api.lang === 'sv' ? 'Jobba med övningar' : `Work with exercises`}
              </h4>
              <div>
                {this.api.lang === 'sv' ?
                  'Övningarna utgår från de påståenden i potentialmätningen där du markerat en skillnad mellan nuläge och önskat läge. Du får reflektera kring påståendena och svara på frågor som guidar dig mot ditt önskade läge. I vissa av övningarna kan du också göra handlingsplaner för din fortsatta utveckling.'
                  :
                  `The exercises are based on answers from the potential measurement, where there is a difference between your current and wanted state. By reflecting over the statements and answer questions, you'll be guided towards your wanted state. You'll create a plan of action in some exercises, to continue your development.`
                }
              </div>
              <h4 className="top_margin">
                {this.api.lang === 'sv' ? 'Mät din utveckling över tid' : `Measure your development over time`}
              </h4>
              <div>
                {this.api.lang === 'sv' ?
                  'Det är viktigt att du kontinuerligt gör nya potentialmätningar för att se om du närmat dig ditt önskeläge.'
                  :
                  `It is very important that you regularly do new potential measurements to see your improvements.`
                }
              </div>
              <h4 className="top_margin">
                {this.api.lang === 'sv' ? 'Vår rekommendation' : `We recommend`}
              </h4>
              <div className='r-bottom-modal'>
                {this.api.lang === 'sv' ?
                  'Din potentialmätning ligger till grund för din rekommenderade ordning. Börja därför gärna med utvecklingsområde 1. Är du mer intresserad av ett annat område kan du självklart börja med det istället.'
                  :
                  `The recommended order of the areas is based on your potential measurement. We recommend that you start with developmental area 1. Of course, you're welcome to start with another area if it interests you more.`
                }
              </div>                  
            </div>
        
          </div>
        </IonModal>        
        
        <Breadcrumbs name={this.api.lang === 'sv' ? 'Utvecklingsområden' : 'Areas of development'}/>
        
        <div className="page_section">
          <div className="focus_area_intro flex flex-row">
            <div className='w-1/2'>
              <h1>{this.api.lang === 'sv' ? 'Dina utvecklingsområden' : 'Your areas of development'}</h1>
              <div onClick={() => this.setState({ showReadMoreDev: true })} className="font_small blue cursor-pointer underline">{this.api.lang === 'sv' ? 'Läs mer' : `Read more`}</div>
              <Text color="gray" className="top_margin bottom_margin right_margin">
                {
                  this.api.lang === 'sv' ?
                    'Dina utvecklingsområden är personligt anpassade för dig baserat på din potentialmätning. '
                    :
                    'Your personalized development areas are based on your potential measurement. '
                }
                {
                  this.state.allQuestions &&
                    this.api.lang === 'sv' ?
                      'Vi rekommenderar att du börjar med område 1.'
                      : this.api.lang === 'en' ?
                      'We recommend that you start with area 1.'
                      : ''
                }
              </Text>
            </div>
            <div className="flex task_done_banner align-end w-1/2">
              <div style={{'maxWidth': '540px'}}>
                <div className="r-flex focus_area_results flex justify-between">
                  <div className="flex-shrink-0 flex r_top_margin right_margin">
                      <img src={focusAreasDone === 0 ? '/assets/focusArea/crown_gray.svg' : '/assets/focusArea/crown_green.svg' } width={60} height={60} alt="" />
                    <div className='flex flex-col left_margin'>
                      <p style={{'marginTop': '-5px'}}  className='font_smaller'>
                        {this.api.lang === 'sv' ? 'Färdiga' : `Finished`}
                      </p>                              
                      <p style={{'marginTop': '-10px'}} className='font_smaller'>
                        {this.api.lang === 'sv' ? 'utvecklingsområden' : 'areas of development'}
                      </p>
                      <h2 style={{ 'marginTop': '-5px' }} className="color_purple">{focusAreasDone} { this.api.lang === 'sv' ? 'st' : 'pc'}</h2>
                    </div>
                  </div>
                  <div className="flex-shrink-0 flex r_top_margin right_margin">
                      <img src={this.calculateDoneMeasureQuestions() === 0 ? '/assets/focusArea/trophy_gray.svg' : '/assets/focusArea/trophy_green.svg' } width={60} height={60} alt="" />
                    <div className="flex flex-col left_margin">
                      <p style={{'marginTop': '-5px'}}  className='font_smaller'>
                        {this.api.lang === 'sv' ? 'Färdiga' : `Finished`}
                      </p>                      
                      <p style={{'marginTop': '-10px'}} className='font_smaller'>
                        {this.api.trTxt(TrVar.Exercises).toLowerCase()}
                      </p>
                      <h2 style={{'marginTop': '-5px'}} className="color_purple">{this.calculateDoneMeasureQuestions()} { this.api.lang === 'sv' ? 'st' : 'pc'}</h2>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
        
        {(totalAreas === focusAreasDone) &&
          <div className="page_section top_margin">
                <div className="text_max_width">
                  <h2>{this.api.lang === 'sv' ? 'Bra jobbat, du är klar med alla dina övningar!' : `Well done, you've finished all of your exercises!`}</h2>
                  <p className="top_margin">
                    {
                      this.api.lang === 'sv' ?
                        'Men låt inte det stoppa dig! Lås upp alla övningar och fortsätt jobba med din utveckling med andra övningar.'
                        :
                        `But don't let that stop you! Unlock more exercises and continue working on your development using other exercises.`
                    }
              </p>
                  <div className="btn_top_margin button green inline_block" onClick={() => this.openAllAreas()}>
                    {
                      this.api.lang === 'sv' ? 'Öppna alla övningar' : 'Open all questions'
                    }
                  </div>    


                  <h4 className="double_top_margin">
                    {
                  this.api.lang === 'sv' ? 
                      'Mät din utveckling'
                      :
                      `Measure your development`
                    }
                  </h4>
                  <p className="top_margin">
                    {
                  this.api.lang === 'sv' ? 
                      'Om du inte redan gjort det, se till att mäta dina framsteg genom att göra en ny potentialmätning.'
                      :
                      `If you haven't already done so, make sure to measure your progress by measuring your potential again.`
                    }
                  </p>

                  <div className="btn_top_margin button green inline_block" onClick={() => changeUrl('/app/client/measure/new')}>
                    {this.api.lang === 'sv' ? 'Gå till Potentialmätning' : 'Measure your progress!'}
                  </div>
                </div>            
          </div>
        }

        {
          focusAreas > 0 ?
            (
              <div style={{'maxWidth': '1296px'}} className="top_margin flex focus_area_card_section">
                  {
                    this.state.measure.focusAreas.map((focusArea: FocusAreaInterface, i) => {
                      const focusAreaDoneMeasureQuestions = this.focusAreaDoneMeasureQuestions(focusArea)
                      if (focusArea.score > 1 || focusAreaDoneMeasureQuestions > 0 || this.state.allQuestions)
                        return (
                          <div key={"focus_" + i} className={"focus_area_card relative " + focusArea.focusArea}>
                            {(focusAreaDoneMeasureQuestions === focusArea.potentialQuestions.length) &&
                              <img className='absolute focus_area_checkmark' src="/assets/focusArea/circle_check_green.svg"
                                alt="" />
                            }
                            {(this.props.isFree && i > 0) &&
                              <img onClick={() => changeUrl('/app/client/purchase')} className='absolute focus_area_lock'
                                src="/assets/focusArea/lock.svg" alt="" />
                            }
                            {(i === 0 && this.api.lang === 'sv') &&
                              <img className='tasks_rec_banner' src='/assets/illustration/rec_banner.svg' alt='recommended banner' />
                            }
                            {(i === 0 && this.api.lang === 'en') &&
                              <img className='tasks_rec_banner' src='/assets/illustration/rec_banner_en.svg' alt='recommended banner' />
                            }
                            <div className={'focus_area_card_text ' + focusArea.focusArea}>
                              <div style={{ 'color': '#94ABFF' }} className="font_small">
                                {this.api.lang === 'sv' ? 'Utvecklingsområde' : 'Developmental area'} {i + 1}
                              </div>
                              <h2>{focusArea.name}</h2>
                              <Text color="gray" className="mt-4">
                                {this.showDescription(focusArea.focusArea)}
                              </Text>
                              <Text color="gray" className="mt-4">
                                {this.api.lang === 'sv' ? 'Innehåller' : 'Contains'}
                                &nbsp;{focusArea.potentialQuestions.length}
                                &nbsp;{(this.api.trTxt(TrVar.Exercises)).toLowerCase()}.
                              </Text>
                              {(this.props.isFree && i > 0) ?
                                <div className={"button font_small inline_block top_margin white"}
                                  onClick={() => changeUrl('/app/client/purchase')}>
                                  {this.api.lang === 'sv' ? 'Lås upp utvecklingsområde' : 'Unlock developmental area'}
                                </div>
                                :
                                <div className={"button font_small inline_block top_margin " +
                                  this.focusAreaButtonClass(i, focusArea)}
                                  onClick={() => this.goToFocusOrIntro(focusArea)}>
                                  {(this.focusAreaDoneMeasureQuestions(focusArea) === focusArea.potentialQuestions.length) ?
                                    this.api.lang === 'sv' ? 'Se svar/Ändra' : 'See answers/Edit'
                                    :
                                    (this.hasStartedFocusArea(focusArea) ?
                                      this.api.trTxt(TrVar.Continue)
                                      :
                                      this.api.lang === 'sv' ? 'Kom igång!' : 'Get started!'
                                    )
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        )
                      return null
                    })
                  }
              </div>
            ) : (
              <div className="page_section top_margin">
                <div className="text_max_width">
                  <h2>{this.api.lang === 'sv' ? 'Här var det inga utvecklingsområden!' : 'You don\'t have any development areas'}</h2>
                  <p className="top_margin">
                    {
                      this.api.lang === 'sv' ?
                        'Det beror på att dina nulägen i potentialmätningen är samma eller är så nära dina ' +
                        'önskade lägen att det inte finns något gap att jobba med.'
                        :
                        'This is because your current states in the potential measurement are the same or are so ' +
                        'close to your desired states that there is not gap to work with.'
                    }
                  </p>
                  <p className="top_margin">
                    {
                      this.api.lang === 'sv' ?
                        'Om detta inte känns helt rätt, och du tror att det kan finnas skillnader mellan dina ' +
                        'nulägen och din önskade lägen. Då rekommenderar vi dig att titta igenom din potentialmätning ' +
                        'igen. Kanske finns där några frågor som du vill ändra i?'
                        :
                        'If this doesn\'t feel quite right or if you perceive that there are still differences ' +
                        'between your current states and your desired states, we recommend you to look through your ' +
                        'potential measurement again. Maybe there are some questions that you want to change?'
                    }
                  </p>
                  <div className="top_margin button green inline_block" onClick={() => changeUrl('/app/client/measure/new')}>
                    {this.api.lang === 'sv' ? 'Gå till potentialmätning' : 'Take me to the potential measurement!'}
                  </div>

                  <p className="mt-12">
                    {
                      this.api.lang === 'sv' ? 'Om det stämmer att ditt nuläge och önskade läge är samma på alla ' +
                        'frågor. Ja då säger vi grattis, du använder redan din fulla potential.'
                        :
                        'If it is true that your current state and your desired state match in all questions, ' +
                        'well then we say congratulations! You are using your full potential!'
                    }
                  </p>
                  <p className="top_margin">
                    {
                      this.api.lang === 'sv' ? 'Får vi föreslå att du jobbar med övningarna ändå, kanske finns det ' +
                        'något du kan vidareutveckla ännu mer?'
                        :
                        'May we suggest that you work with the exercises anyway, as there might be areas in which ' +
                        'you can dig deeper?'
                    }
                  </p>
                  <p className="top_margin">
                    {
                      this.api.lang === 'sv' ? 'Klicka på knappen så öppnar du upp samtliga övningar i plattformen.' +
                        ' Därifrån är det bara att välja och vraka.'
                        :
                        'Click here to open up all exercises in the platform. Pick an choose as you like!'
                    }
                  </p>
                  <div className="top_margin button green inline_block" onClick={() => this.openAllAreas()}>
                    {
                      this.api.lang === 'sv' ? 'Öppna alla övningar' : 'Open all'
                    }
                  </div>
                </div>
              </div>
            )
        }

        {
          (CONFIG.SYSTEM !== 'prod') &&
            <div className="button red inline_block top_margin"
                 onClick={() => this.setState({showDeleteTasks: true})}>
                DEMO: starta om utvecklingsområden
            </div>
        }
      </div>
    )
  }

  storePosition = (position: number) => {
    this.setState({position: position})
  }

  setChooseNext = (chooseNext: boolean) => {
    this.chooseNext = chooseNext
  }

  showFocusArea = () => {
    let breadcrumbs = [{name: this.api.lang === 'sv' ? 'Utvecklingsområden' : 'Areas of development', link: this.baseUrl}]

    this.focusArea = this.getFocusArea()

    if (!this.focusArea) {
      return (<div>Missing focusArea</div>)
    }

    let coachQuestionsDone = 0
    let potentialQuestionsDone = 0


    const cards: Array<CardInterface> = []
    for (const potentialQuestion of this.focusArea.potentialQuestions) {
      let planDone;
      let followupDone;
      let followupDate: string;
      for (const plan of this.state.plans) {
        if (plan.coachQuestionId === potentialQuestion.coachQuestions[0].id) {
          planDone = plan.planCompleted;
          followupDone = plan.followupCompleted;
          followupDate = plan.followUp;
        }
      }
      const tasks = []
      let potentialQuestionDone = true
      for (const coachQuestion of potentialQuestion.coachQuestions) {
        if (coachQuestion.done && !potentialQuestion.loop) {
          coachQuestionsDone++
        } else {
          potentialQuestionDone = false
        }
        tasks.push(coachQuestion.done)
      }
      if (potentialQuestion.loop) {
        for (const plan of this.state.plans) {
          if ((plan.coachQuestionId === potentialQuestion.coachQuestions[0].id) && plan.followupCompleted) {
            potentialQuestionsDone++
          }
        }
      } else {
        if (potentialQuestionDone) {
          potentialQuestionsDone++
        }        
      }

      if (this.state.showFilter === 1) {
        cards.push({
          id: potentialQuestion.question.id,
          name: potentialQuestion.question.question,
          current: potentialQuestion.question.valueCurrent,
          desired: potentialQuestion.question.valueDesired,
          done: potentialQuestionDone,
          tasks: tasks,
          plan: potentialQuestion.loop,
          planDone: !!planDone,
          followup: potentialQuestion.loop,
          followupDone: !!followupDone,
          followupDate: followupDate!,
        })        
      } else if (this.state.showFilter === 2) {
        if ((tasks[0] === true && (!followupDone) && !potentialQuestionDone) || (planDone && !followupDone)) {
          cards.push({
            id: potentialQuestion.question.id,
            name: potentialQuestion.question.question,
            current: potentialQuestion.question.valueCurrent,
            desired: potentialQuestion.question.valueDesired,
            done: potentialQuestionDone,
            tasks: tasks,
            plan: potentialQuestion.loop,
            planDone: !!planDone,
            followup: potentialQuestion.loop,
            followupDone: !!followupDone,
            followupDate: followupDate!,
          })  
        } 
      } else if (this.state.showFilter === 3) {
        if (planDone && !followupDone) {
          cards.push({
            id: potentialQuestion.question.id,
            name: potentialQuestion.question.question,
            current: potentialQuestion.question.valueCurrent,
            desired: potentialQuestion.question.valueDesired,
            done: potentialQuestionDone,
            tasks: tasks,
            plan: potentialQuestion.loop,
            planDone: !!planDone,
            followup: potentialQuestion.loop,
            followupDone: !!followupDone,
            followupDate: followupDate!,
          })  
        }
        
      } else if (this.state.showFilter === 4) {
        if (followupDone || (!potentialQuestion.loop && potentialQuestionDone)) {
          cards.push({
            id: potentialQuestion.question.id,
            name: potentialQuestion.question.question,
            current: potentialQuestion.question.valueCurrent,
            desired: potentialQuestion.question.valueDesired,
            done: potentialQuestionDone,
            tasks: tasks,
            plan: potentialQuestion.loop,
            planDone: !!planDone,
            followup: potentialQuestion.loop,
            followupDone: !!followupDone,
            followupDate: followupDate!,
          })     
        }
      }


    }
    if (!this.animateCoachQuestionsCounter) {
      this.coachQuestionsCounter = coachQuestionsDone
      this.animateMedal(coachQuestionsDone, false, Math.random())
    }

    if (!this.animateStatementCounter) {
      this.statementsCounter = potentialQuestionsDone
      this.animateStatement(potentialQuestionsDone, false, Math.random())
    }

    return (
      <div className="page_content">
        <IonModal isOpen={this.state.showFocusIntro} onDidDismiss={() => this.setState({ showFocusIntro: false })}
          className="modal_full">
          {
            (this.focusArea && this.state.showFocusIntro) &&
            <>
              <div className="relative" onClick={() => this.setState({ showFocusIntro: false })}>
                <img className='absolute modal_close cursor-pointer' src="/assets/icon/white/cross.svg" height={'20px'} alt="" />
              </div>
              <FocusAreaSettings focusArea={this.focusArea} saveFocusSettings={this.saveFocusSettings}
                close={() => this.closeSettings()} />
            </>
          }
        </IonModal>
        <IonModal isOpen={this.state.showFocusAreaHelp} onDidDismiss={() => this.setState({ showFocusAreaHelp: false })}
          className="modal_full">
          <div className="text_center bg_dark_blue color_white help_modal ">
            <img onClick={() => this.setState({ showFocusAreaHelp: false })} className='absolute modal_close'
              src="/assets/icon/white/cross.svg" height={'20px'} alt="" />
            <div className="mx-auto flex focus_area_setting_page flex-col justify-center" style={{ maxWidth: '600px', height: '100%' }}>
              <div>
                <h1>
                  {this.api.lang === 'sv' ?
                    'Så jobbar du med ditt utvecklingsområde'
                    :
                    'How to work with your developmental area'
                  }
                </h1>
                <div className='mx-auto' style={{ maxWidth: '490px', textAlign: 'center' }}>
                  <h4 className="top_margin">
                    {this.api.lang === 'sv' ? 'Svara på insiktsdrivande frågor' : `Answer insight-driven questions`}
                  </h4>
                  <Text color="gray" className="top_margin">
                    {this.api.lang === 'sv' ?
                      'Varje övning innehåller frågor där du får reflektera över vad du kan göra för att komma närmare din fulla potential.'
                      :
                      `Each exercise contains questions designed to make you reflect on different ways you can achieve your full potential.`
                    }
                  </Text>
                  <h4 className="top_margin">
                    {this.api.lang === 'sv' ? 'Gör planer som tar dig framåt' : `Make plans that helps you move forward`}
                  </h4>
                  <Text color="gray" className="top_margin">
                    {this.api.lang === 'sv' ?
                      'I en del övningar kan du även göra planer och sätta mål.'
                      :
                      `In some exercises you can also create plans and set goals.`
                    }
                  </Text>
                  <h4 className="top_margin">{this.api.lang === 'sv' ? 'Följ upp och utvecklas' : `Follow up and develop`}</h4>
                  <Text color="gray" className="top_margin">
                    {this.api.lang === 'sv' ?
                      'Ett tag efter att du gjort en plan så gör du en uppföljning om hur det gått.'
                      :
                      `A certain time after you’ve created the plan, you can track how it went.`
                    }
                  </Text>
                </div>
              </div>
              <div>
                <div className="btn_top_margin button green inline_block"
                  onClick={() => this.setState({ showFocusAreaHelp: false })}>
                  {this.api.trTxt(TrVar.Continue)}
                </div>
              </div>
            </div>
          </div>
        </IonModal>

        <IonModal isOpen={this.state.showReadMore} onDidDismiss={() => this.setState({ showReadMore: false })}
          className="modal_full">
          <div className="text_center bg_dark_blue color_white help_modal ">
            <img onClick={() => this.setState({ showReadMore: false })} className='absolute modal_close'
              src="/assets/icon/white/cross.svg" height={'20px'} alt="" />
            <div className="mx-auto focus_area_setting_page flex flex-col justify-center" style={{ height: '100%', maxWidth: '598px' }} >
              <h1>
                {this.api.lang === 'sv' ?
                  'Vad innebär ' + this.focusArea.name.toLowerCase() + '?'
                  :
                  `What does ` + this.focusArea.name.toLowerCase() + ` mean?`
                }
              </h1>
              <div>
                {
                  introTexts(this.focusArea.focusArea, this.api.lang).map((text, index) => {
                    return (
                      <Text color="gray" key={"intro_text_" + index} className="top_margin r-fontsize">
                        {text}
                      </Text>
                    )
                  })
                }
              </div>
              <div>
                <div onClick={() => this.setState({ showReadMore: false })} className="btn_top_margin green inline_block button">
                  {this.api.trTxt(TrVar.Continue)}
                </div>
              </div>
            </div>
          </div>
        </IonModal>

        <Breadcrumbs name={this.focusArea.name} breadcrumbs={breadcrumbs} />

        <div className="page_section flex flex-row justify-between  relative w-full">

          <div className='flex flex-row'>
            <div className='my-auto focus_icon w-20 pr-4'>
              <img src={"/assets/focusArea/area_icon_" + this.focusArea.focusArea + '.svg'} height={"60px"} alt="" />
            </div>
            <div className='sm:block hidden '>
              <h1 className=''>{this.focusArea.name}</h1>
              <div className="flex">
                <p className="font_small cursor-pointer blue underline"
                  onClick={() => this.setState({ showReadMore: true })}>
                  {this.api.lang === 'sv' ? 'Om ' : 'About '} {this.focusArea.name.toLowerCase()}
                </p>
                <p className="left_margin cursor-pointer blue underline font_small"
                  onClick={() => this.setState({ showFocusAreaHelp: true })}>
                  {this.api.lang === 'sv' ? 'Läs mer' : `Read more`}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col align-center">
            <div className="flex flex-row">
              <div className="w-16">
                <img className="object-contain" src={this.trophySrc} height={65} alt="" />
              </div>
              <div className='flex flex-col'>
                <div className="flex flex-col left_margin">
                  <p style={{ 'marginTop': '-5px' }} className='font_smaller'>
                    {this.api.lang === 'sv' ? 'Färdiga' : `Finished`}
                  </p>
                  <p style={{ 'marginTop': '-10px' }} className='font_smaller'>
                    {this.api.trTxt(TrVar.Exercises).toLowerCase()}
                  </p>
                  <h2 key={this.state.statementKey} style={{ 'marginTop': '-5px' }} className='color_purple'>{this.statementsCounter} {this.api.lang === 'sv' ? 'st' : 'pc'}</h2>
                </div>
              </div>
            </div>
            <div>
              <div className='block sm:hidden '>
                <h1 className='hide_medium'>{this.focusArea.name}</h1>
                <div className="flex">
                  <p className="font_small cursor-pointer blue underline"
                    onClick={() => this.setState({ showReadMore: true })}>
                    {this.api.lang === 'sv' ? 'Om ' : 'About '} {this.focusArea.name.toLowerCase()}
                  </p>
                  <p className="left_margin cursor-pointer blue underline font_small"
                    onClick={() => this.setState({ showFocusAreaHelp: true })}>
                    {this.api.lang === 'sv' ? 'Läs mer' : `Read more`}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
        
        <div className="flex top_margin">
          <div onClick={() => this.setState({ showFilter: 1 })} style={{ textDecoration: (this.state.showFilter === 1 ? 'underline' : '') }} className='task_filter_option cursor-pointer'>{this.api.lang === 'sv' ? 'Visa alla' : `Show all`}</div>
          <div onClick={() => this.setState({ showFilter: 2 })} style={{ textDecoration: (this.state.showFilter === 2 ? 'underline' : '') }} className='task_filter_option cursor-pointer left_margin'>{this.api.lang === 'sv' ? 'Pågående' : `Ongoing`}</div>
          <div onClick={() => this.setState({ showFilter: 3 })} style={{ textDecoration: (this.state.showFilter === 3 ? 'underline' : '') }} className='task_filter_option cursor-pointer left_margin'>{this.api.lang === 'sv' ? 'Uppföljning' : `Follow-up`}</div>
          <div onClick={() => this.setState({ showFilter: 4 })} style={{ textDecoration: (this.state.showFilter === 4 ? 'underline' : '') }} className='task_filter_option cursor-pointer left_margin'>{this.api.lang === 'sv' ? 'Avslutade' : `Finished`}</div>
        </div>
        
        <div className="page_section no_bg">
          <Carousel filter={this.state.showFilter} cards={cards} position={this.state.position} storePosition={this.storePosition} focusArea={this.focusArea.name}
            click={this.selectQuestion} chooseNext={this.chooseNext} setChooseNext={this.setChooseNext}
            toggleFollowup={this.toggleComesFromFollowup}
            isFree={this.props.isFree} plans={this.state.plans} navigation={this.props.navigation} />
        </div>
      </div>
    )
  }

  startCalculatingText = () => {
    let i = 0
    const texts = [
      this.api.lang === 'sv' ? 'Beräknar utvecklingsområden...' : 'Calculating areas of development...',
    ]
    const calcInterval = setInterval(() => {
      this.setState({ calculatingText: texts[i] })
      i++
      if (i > 1) {
        clearInterval(calcInterval)
        this.setState({ showIntroduction: false, runIntroCalculation: false })
      }
    }, 3000)
  }

  nextIntroDevAreas = () => {
    const data = {
      haveSeenDevelopmentAreasIntro: true,
    }

    this.api.post('ui_settings', data)
      .then(response => {
        this.startCalculatingText()
        this.setState({ runIntroCalculation: true })
        this.props.updateSeenIntro();
      })
  }
  // <div className='flex flex-col flex-nowrap overflow-y-scroll justify-center text_center color_white bg_dark_blue' style={{  width: '100%', padding: '15px' }}>
  showAreaIntroduction = () => {
    return (
      <div className="page_content">
        <IonModal isOpen={this.state.showIntroduction} className="modal_full">
          <div className='flex overflow-y-scroll flex-row justify-center text_center color_white bg_dark_blue w-full h-full' style={{ width: '100%', padding: '15px' }}>
            
            {this.state.runIntroCalculation ?
              <>
                <div className='text_center flex flex-col justify-center' style={{ maxWidth: '550px', margin: '0 auto', width: '100%' }}>
                  <div className='color_white' style={{ marginBottom: '40px', height: '50px' }}>{this.state.calculatingText}</div>
                  <BarChart value={100} size="transition_linear_width_4s medium" oneColor={true} showValue={false} />
                </div>
              </>
              :
              <>
                <h1 className='task_intro_header pt-16'>
                  {this.api.lang === 'sv' ? 'Så fungerar utvecklingsområden' : `How areas of development work`}
                </h1>
                <div className="flex flex-col flex-nowrap">
                  <div id="devAreaContent" className='flex max-lg:flex-nowrap lg:flex-wrap  task_intro justify-center top_margin '>
                    <div style={{ maxWidth: '360px', margin: '0 auto' }} className="page_section third">
                      <img style={{ width: '100%' }} src={"/assets/illustration/" + (this.api.lang === 'sv' ? "intro_pot_measure.png" : "intro_pot_measure_en.png" )} alt="" />
                      <h2 className='top_margin'>{this.api.lang === 'sv' ?
                        'Potentialmätning'
                        :
                        `Potential measurement`
                      }</h2>
                      <div style={{ paddingTop: '10px' }}>
                        {this.api.lang === 'sv' ?
                          'I potentialmätningen svarade du på en rad olika påståenden genom att markera nuläge och önskat läge.'
                          :
                          `In your potential measurement you answered where your current and desired state are on statements.`
                        }
                      </div>
                    </div>
                    <div className='flex flex-col justify-center task_arrow_container'>
                      <img className='tasks2_arrow' src="/assets/icon/white/curved_arrow.svg" alt="" />
                    </div>
                    <div style={{ maxWidth: '375px', margin: '0 auto' }} className="page_section third r_top_margin">
                      <img style={{ width: '100%' }} src={"/assets/illustration/" + (this.api.lang === 'sv' ? "intro_dev_areas.png" : "intro_dev_areas_en.png" )} alt="" />
                      <h2>
                        {this.api.lang === 'sv' ?
                          'Personligt anpassade utvecklingsområden'
                          :
                          `Individually adapted areas of development`
                        }
                      </h2>
                      <div style={{ paddingTop: '10px' }}>
                        {this.api.lang === 'sv' ?
                          'De påståenden där du markerade ett gap mellan nuläge och önskat läge omvandlas till övningar som fördelas in i olika utvecklingsområden.'
                          :
                          `The statements where you answered you've got a gap, is translated into exercises within the different areas of development.`
                        }
                      </div>
                    </div>
                    <div className='flex flex-col justify-center task_arrow_container'>
                      <img height={'100%'} className='tasks2_arrow' src="/assets/icon/white/curved_arrow.svg" alt="" />
                    </div>
                    <div style={{ maxWidth: '360px', margin: '0 auto' }} className="page_section third r_top_margin">
                      <img style={{ width: '100%' }} src={"/assets/illustration/" + (this.api.lang === 'sv' ? "intro_exercises.png" : "intro_exercises_en.png")} alt="" />
                      <h2>
                        {this.api.lang === 'sv' ?
                          'Individuella övningar'
                          :
                          `Individual exercises`
                        }
                      </h2>
                      <div style={{ paddingTop: '10px' }}>
                        {this.api.lang === 'sv' ?
                          'I övningen får du reflektera och svara på frågor som guidar dig mot ditt önskade läge.'
                          :
                          `In an exercise, you'll reflect over questions which will guide you towards your full potential.`
                        }
                      </div>
                    </div>
                  </div>
                  <div className="" style={{ paddingBottom: '115px' }}>
                    <div onClick={() => this.nextIntroDevAreas()} className="inline_block btn_top_margin button green">{this.api.lang === 'sv' ? 'Beräkna Utvecklingsområden' : `Calculate areas of development`}</div>
                  </div>
                </div>
              </>
            }
          </div>
        </IonModal>
      </div>
    )
  }


  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }


    if (this.state.measures.length === 0) {
      return (
        <div className="page_content">
        <Breadcrumbs name={this.api.lang === 'sv' ? 'Utvecklingsområden' : 'Areas of development'}/>
          <div className="page_section">
            <h1>{this.api.lang === 'sv' ? 'Utvecklingsområden' : 'Areas of development'}</h1>
            <div className="top_margin">
              {this.api.lang === 'sv' ? 'Du har inte gjort någon mätning av din potential.' : `You've not measured your potential yet.`}
            </div>
            <div onClick={() => changeUrl('/app/client/measure/new')} className="top_margin button white inline_block">{this.api.lang === 'sv' ? 'Mät potential' : 'Measure potential'}</div>
          </div>
        </div>
      )
    }

    if (this.props.navigation.sub2.length > 0) {
      const fa = this.getFocusArea()
      const pq = this.getPotentialQuestion(fa)
      if (fa && pq) {
        if (this.props.navigation.sub3 === 'followup') {
          console.log('Followup!: ' + this.props.navigation.sub3)
          return (<TaskPlanFollowUp focusArea={fa} potentialQuestion={pq} navigation={this.props.navigation}
                           plans={this.state.plans}
                           clientName={this.state.clientName}
                           isCardDone={this.questionCardDone}
                           doneStatements={this.statementsCounter}
                           updatePlan={this.updatePlan}
                           toggleStatement={this.toggleStatementAnimation}
                           answeredQuestions={this.numberAnsweredQuestions}
                           toggleFollowup={this.toggleComesFromFollowup}
                           scrollRef={this.props.scrollRef} baseUrl={this.baseUrl} />)
        } else if (this.props.navigation.sub3 === 'summary') {
          return (
            <TaskSummary baseUrl={this.baseUrl} focusArea={fa}
                         plans={this.state.plans} potentialQuestion={pq}
                         navigation={this.props.navigation} />
          )
        }
        return (<OneTask focusArea={fa} potentialQuestion={pq} navigation={this.props.navigation}
                         isCardDone={this.questionCardDone}
                         toggleQuestion={this.toggleQuestionAnimation}
                         toggleStatement={this.toggleStatementAnimation}
                         answeredQuestions={this.numberAnsweredQuestions}
                         statementNr={this.state.position + 1}
                         statementTotal={fa.potentialQuestions.length}
                         doneStatements={this.statementsCounter}
                         plans={this.state.plans}
                         fromFollowup={this.state.comesFromFollowup}
                         updatePlan={this.updatePlan}
                         clientName={this.state.clientName}
                         clientUISettings={this.props.clientUISettings}
                         updateOneTaskIntro={this.props.updateOneTaskIntro}
                         scrollRef={this.props.scrollRef} baseUrl={this.baseUrl} setChooseNext={this.setChooseNext}/>)

      } else {
        return (<div className="page_content">Missing focusArea or question</div>)
      }

    } else if (this.props.navigation.sub1.length > 0) {
      return this.showFocusArea()
    } else {
      if (this.state.showIntroduction) {
        return (
          this.showAreaIntroduction()
        )
      }      
      return this.showMenu()
    }
  }
}
