import store from "../redux/store";
import {CONFIG} from "../constants"
import {language_en, var_language_en, err_language_en} from "./language_en";
import {language_sv, var_language_sv, err_language_sv} from "./language_sv";
import {TrVar, TrTxt} from "./translate"
import {TOKEN_REMOVE, TOKEN_STORE} from "../redux/actionTypes";
import { TrArr, TrArray } from "./translate_arrays";

interface ProfileInterface {
  picture: string,
  firstName: string,
  lastName: string,
  email: string,
}

const defaultProfile = {
  picture: "assets/images/profile.png",
  firstName: "",
  lastName: "",
  email: "",
}

export interface ApiCallResponse {
  response: Response,
  json?: any,
  isExpectedError: boolean,
  errorName?: string,
  errorMessage?: string,
}

export enum LogLevel {
  CRITICAL = "CRITICAL",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  DEBUG = "DEBUG",
}

export default class Api {

  static myInstance: Api;
  private headers: any;
  private baseUrl: string;
  public loggedIn: boolean;
  public profile: ProfileInterface;
  public token: string;
  public lang: string;
  private texts: any
  private varTexts: any
  private errTexts: any

  constructor() {
    this.loggedIn = false;
    this.lang = 'sv'
    this.baseUrl = CONFIG.API_ENDPOINT;
    this.headers = {
      'X-Version': CONFIG.VERSION,
      'X-platform': 'unknown',
      'Content-Type': 'application/json',
      'X-Lang': this.lang
    };
    this.getLanguage()
    this.setLanguage(this.lang)

    this.profile = defaultProfile;

    this.token = window.localStorage[CONFIG.TOKEN_NAME];

    if (this.token) {
      this.loggedIn = true;
      this.headers['Authorization'] = 'Bearer ' + this.token;
    }
  }


  /**
   * @returns {ApiService}
   */
  static getInstance(): Api {
    if (Api.myInstance == null) {
      Api.myInstance = new Api();
    }

    return this.myInstance;
  }

  private getLanguage = () => {
    let lang = window.localStorage['zebrain_lang']
    if (lang) {
      this.lang = lang
    } else {
      window.localStorage['zebrain_lang'] = this.lang
    }
  }

  public setLanguage = (lang: string) => {
    if (lang === 'sv') {
      this.texts = language_sv
      this.varTexts = var_language_sv
      this.errTexts = err_language_sv
    } else {
      lang = 'en'
      this.texts = language_en
      this.varTexts = var_language_en
      this.errTexts = err_language_en
    }
    this.headers['X-Lang'] = this.lang = window.localStorage['zebrain_lang'] = lang
  }

  public tr = (key: string) => {
    // key is always in English
    if (this.lang === 'en') {
      return key
    }
    let value = this.texts[key]
    if (value) {
      return value
    }
    return key
  }

  public trVar = (key: string, lc?: boolean) => {
    let value = this.varTexts[key]
    if (value) {
      if (lc) {
        return value.toLowerCase()
      }
      return value
    }
    return key
  }

  public trTxt = (key: TrVar) => {
    const value = TrTxt[key][this.lang]
    if (value) {
      return value
    }
    return TrTxt[key]['en']
  }

  public trArr = (key: TrArray) => {
    const value = TrArr[key][this.lang]
    if (value) {
      return value
    }
    return TrArr[key]['en']
  }  

  public trFormat = (key: TrVar, vars: Array<string>) => {
    const value = TrTxt[key][this.lang]
    if (value) {
      return value
    }
    return TrTxt[key]['en']
  }



  public trErr = (key: string) => {
    let value = this.errTexts[key]
    if (value) {
      return value
    }
    return key
  }

  public getProfile() {
    this.get('settings').then(response => {
     this.profile = response.json;
    });
  }

  public logOut(nextUrl: string) {
    this.post('logout', {}).then(_response => {
      this.loggedIn = false;
      this.profile = defaultProfile;
      delete window.localStorage[CONFIG.TOKEN_NAME];
      if (nextUrl) {
        window.location.href = nextUrl
      }
    })
  }

  public logInNoDispatch(token: string, personId: string) {
    window.localStorage[CONFIG.TOKEN_NAME] = token;
    window.localStorage[CONFIG.PERSON_ID] = personId;
    this.headers['Authorization'] = 'Bearer ' + token;
    this.loggedIn = true;
    this.token = token;
  }

  public logIn(token: string, personId: string) {
    this.logInNoDispatch(token, personId)
    store.dispatch({type: TOKEN_STORE, payload: token})
    this.getProfile();
  }

  public get(url: string, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.getRaw(url);
    return this.makeCall(call, expectedErrors)
  }

  public post(url: string, data: any, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.postRaw(url, data)
    return this.makeCall(call, expectedErrors)
  }

  public getBlob(url: string) {
    const opener = window.open("about:blank","_blank")
    this.getRaw(url)
      .then(res => res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob)
        if (opener) {
          opener.location = file
        }
        // window.open(file, '_blank')
      })
  }

  private getRaw(url: string) {

    return fetch(this.baseUrl + url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
    });
  }

  private postRaw(url: string, data: any) {

    return fetch(this.baseUrl + url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  }

  private makeCall(apiCall: Promise<Response>, expectedErrors: Array<string>): Promise<ApiCallResponse> {
    return new Promise((resolve, reject) => {
      apiCall
      // We got an http response
      .then((response) => {

        const result: ApiCallResponse = {
          response: response,
          isExpectedError: false,
        };

        if (response.ok) {
          // Call was successful (2XX status code)
          response.json().then(json => {
            // Uncomment to debug. Should be removed in production.
            // console.log("----> ", json)

            result.json = json
            resolve(result);
          })
          .catch(() => {
            // This is for status 204 which will have an empty body (ie no json)
            resolve(result);
          });
        } else {
          // We got an error (most likely 4XX or 5XX status code)
          response.json().then(json => {
            result.json = json
            result.errorName = result.json.name;
            result.errorMessage = result.json.message;
            result.isExpectedError = expectedErrors.includes(result.errorName!)
            if (!result.isExpectedError) {
              if (response.status === 401) {
                // User session has most likely expired server side
                store.dispatch({type: TOKEN_REMOVE})
              } else {
                this.handleUnexpectedError(result)
              }
            }
            reject(result)
          })
          .catch(() => {
            // Response body is not json
            this.handleUnexpectedError(result)
            reject(result);
          });
        }
      })

      // We got no http response (eg due to timeout or network error)
      .catch(reason => {
        console.error(reason)
        this.handleUnexpectedError()
      });
    });
  }

  private handleUnexpectedError(response?: ApiCallResponse) {
    let alertMsg: string = this.trTxt(TrVar.UnexpectedErrorOccurred)
    if (response) {
      // Log error to backend
      const logMsg = response.errorName ?
        response.errorName :
        'Status: ' + response.response.status
      this.log(LogLevel.ERROR, logMsg)

      const additionalMsg = response.errorName ?
        response.errorMessage + ' (' + response.errorName + ')' :
        response.response.statusText + ' (' + response.response.status + ')'
      alertMsg += '\n' + this.trTxt(TrVar.AdditionalInformation) + ': ' + additionalMsg
    }
    // Display error to user
    alert(alertMsg)
  }

  /**
   * Sends a log message to backend
   */
  public log(level: LogLevel, message: string) {
    const data = {
      level: level,
      message: message,
    }
    try {
      this.postRaw('log', data)
    } catch(err) {
      console.log(err)
    }
  }
}
