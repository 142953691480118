import React, { useState } from 'react';
import Api from '../../services/Api';
import { TrVar } from '../../services/translate';
import Breadcrumbs from "../menu/Breadcrumbs";
import { changeUrl } from '../../services/Navigation';

interface ComponentProps {
  free: boolean
  clientHasCoach: boolean
}

const ClientSupport = (props: ComponentProps) => {
  const api = Api.getInstance()
  
  const [sentSupport, setSentSupport] = useState(false);
  const [input, setInput] = useState('')

  const handleChange = (e: any, doCheck: boolean) => {
    setInput(e.target.value)
  }
  
  const sendSupportMessage = () => {
    if (input.length < 1) {
      return;
    }

    const data = {
      type: 'support',
      url: document.location.pathname,
      message: input
    }
    
    api.post('feedback', data).then(_response => {
      setSentSupport(true)      
    })
  }



  return (
    <>
      <Breadcrumbs name={api.trTxt(TrVar.Support)} />
      <div className="page_content">
        <div className="page_section">
          <h1>{api.trTxt(TrVar.Support)}</h1>
          <div className="page_bar" />
          <h3 className='double_top_margin'>{api.trTxt(TrVar.DoYouHaveAQuestionOrWantToLeaveFeedback)}</h3>
          {props.clientHasCoach &&
            <p className='top_margin'>
              <em>
                {api.trTxt(TrVar.DoesYourCoachNotHaveAnyTimes)} &nbsp;
                {api.trTxt(TrVar.WriteToYourCoachIn)} &nbsp;
              </em>
              <em className='underline cursor-pointer' onClick={() => changeUrl('/app/client/chat')}>{api.trTxt(TrVar.TheChat).toLowerCase()}</em>
            </p>            
          }

          <div className="top_margin">
          <textarea className={'page_text_area'} name={'supportInput'}
                    maxLength={5000}
                    value={input} placeholder={api.trTxt(TrVar.WriteHere)}
                    onChange={(e) => handleChange(e, false)} />    
            {
              sentSupport && <p>{api.trTxt(TrVar.MsgSent)}</p>
            }

          </div>
          <div className="button btn_top_margin inline_block bottom_margin" onClick={() => sendSupportMessage()}>{api.trTxt(TrVar.Send)}</div>
          <h2 className='double_top_margin'>{api.trTxt(TrVar.Contact)}</h2>
          <div className='top_margin'>
            <h4>{api.trTxt(TrVar.Address)}</h4>
            <p>{api.trTxt(TrVar.BirgerJarlsgatan34)}</p>
            <p>{api.trTxt(TrVar.OneOneFourStockholm)}</p>
          </div>
          <div>
            <h4 className='top_margin'>{api.trTxt(TrVar.Phone)}</h4>
            <p><a className='support_black_link' href="tel:+46768936265">{api.trTxt(TrVar.TelephoneNumber)}</a></p>
          </div>
          <div>
            <h4 className='top_margin'>{api.trTxt(TrVar.Email)}</h4>
            <p><a className='support_black_link' href="mailto:support@zebrain.se">{api.trTxt(TrVar.SupportAtZebrainSe).toLowerCase()}</a></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientSupport
