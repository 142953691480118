export function displayVolume(stream: MediaStream, parentEl: HTMLElement, volumeInterval: any) {
  if (volumeInterval) {
    clearInterval(volumeInterval)
  }
  while (parentEl.lastElementChild) {
    parentEl.removeChild(parentEl.lastElementChild);
  }
  const audioContext = new AudioContext()
  const mediaStreamSource = audioContext.createMediaStreamSource(stream)

  const destination = audioContext.createMediaStreamDestination()

  // mediaStreamSource.connect(audioContext.destination)
  // console.log(mediaStreamSource)
  const analyser = audioContext.createAnalyser()
  analyser.connect(destination)

  const audio = document.createElement('audio')
  audio.srcObject = destination.stream
  audio.autoplay = false
  mediaStreamSource.connect(destination)
  // analyser.minDecibels = -90;
  // analyser.maxDecibels = -10;
  mediaStreamSource.connect(analyser)

  analyser.fftSize = 1024

  const bufferLength = analyser.frequencyBinCount

  const volumeEl = document.createElement('div')
  volumeEl.classList.add('video_volume_div')
  parentEl.appendChild(volumeEl)

  const volumeBarEl = document.createElement('div')
  volumeBarEl.classList.add('video_volume_bar_div')
  volumeEl.append(volumeBarEl)

  const dataArray = new Uint8Array(bufferLength)

  requestAnimationFrame(function() {
    drawSound(analyser, bufferLength, dataArray, volumeBarEl)
  })
  // volumeInterval = setInterval(() => {
  //   analyser.getByteFrequencyData(dataArray)
  //   let total = 0
  //   for(let i = 0; i < bufferLength; i++) {
  //     total += dataArray[i]
  //   }
  //   // Multiply with 20 to estimate 100 = high volume
  //   let volume = Math.sqrt(total / bufferLength) * 20
  //
  //   volumeBarEl.style.width = Math.min(100, volume) + "%"
  //   // console.log(volume)
  // }, 100)
}

function drawSound(analyser: any, bufferLength: number, dataArray: Uint8Array, volumeBarEl: any) {
  analyser.getByteFrequencyData(dataArray)
  let total = 0
  for(let i = 0; i < bufferLength; i++) {
    total += dataArray[i]
  }
  // Multiply with 20 to estimate 100 = high volume
  let volume = Math.sqrt(total / bufferLength) * 20

  volumeBarEl.style.width = Math.min(100, volume) + "%"
  requestAnimationFrame(function() {
    drawSound(analyser, bufferLength, dataArray, volumeBarEl)
  })
}
