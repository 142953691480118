import React from 'react'
import Api from "../../services/Api"
import Breadcrumbs from "../menu/Breadcrumbs";
import "./Tasks.css"
import {
  FocusAreaInterface,
  PotentialQuestionInterface,
  CoachQuestionInterface, FocusPlanInterface,
} from "./interfaces";
import {
  IonModal,
} from "@ionic/react";
import CardHelpModal from './CardHelpModal';
import {changeUrl} from "../../services/Navigation";
import TaskPlan from "./TaskPlan";
import SvgAnimation from '../SvgAnimation';
import { TrVar } from "../../services/translate";
import { ClientUISettingsInterface } from '../../interfaces/ClientUISettings';

interface ComponentProps {
  navigation: any
  scrollRef: any
  baseUrl: string
  focusArea: FocusAreaInterface
  potentialQuestion: PotentialQuestionInterface
  setChooseNext: Function
  toggleQuestion: Function
  toggleStatement: Function
  answeredQuestions: Function
  isCardDone: Function
  updatePlan: Function
  plans: Array<FocusPlanInterface>
  statementNr: number
  statementTotal: number
  doneStatements: number
  clientName: string
  fromFollowup: boolean
  clientUISettings: ClientUISettingsInterface
  updateOneTaskIntro: Function
}

interface ComponentState {
  position: number
  changes: number
  error: string
  seenIntro: boolean
  hideIntro: boolean
  coachQuestion: CoachQuestionInterface
  showCardHelp: boolean
  showCardDone: boolean
  areaComplete: boolean
  isPlanDone: boolean
  isFollowupDone: boolean
  coachQuestionRewritten: boolean
  modifiedBeforeUpdate: boolean
  hasPlan: boolean
}

export default class OneTask extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private taskChanged = false
  nextSound = new Audio('/assets/sound/woop.mp3')
  private checkIfCheckmark = [0, 0, 0, 0, 0, 0]
  private checkAnswer = [0, 0, 0, 0, 0, 0]
  medalSrc = ''
  doneStatements = this.props.doneStatements;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      position: 0,
      changes: 0,
      error: '',
      seenIntro: false,
      hideIntro: false,
      showCardHelp: false,
      showCardDone: false,
      coachQuestion: this.props.potentialQuestion.coachQuestions[0],
      areaComplete: false,
      isPlanDone: false,
      isFollowupDone: false,
      coachQuestionRewritten: this.isCoachQuestionRewritten(this.props.potentialQuestion.coachQuestions[0]),
      modifiedBeforeUpdate: this.isModifiedBeforeUpdate(this.props.potentialQuestion.coachQuestions),
      hasPlan: this.hasPlan(this.props.potentialQuestion.coachQuestions[0].id)
    }
  }

  hasPlan = (questionId: number) => {
    for (const plan of this.props.plans) {
      console.log(plan.coachQuestionId + ' === ' + questionId)
      if (plan.coachQuestionId === questionId) {
        return true
      }
    }
    return false
  }

  isCoachQuestionRewritten = (coachQuestion: CoachQuestionInterface) => {
    const rewrittenCoachQuestionIds = [
      5, 6, 8, 9, 10, 15, 16, 17, 31, 36, 44, 45, 46, 48, 52, 64, 67, 69, 72, 75, 77, 78, 79, 81, 83, 84, 85, 87,
      88, 89, 90, 93, 94, 96, 97, 98, 99, 100, 101, 102, 103, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117,
      120, 121, 122, 123, 125, 126, 130, 131, 134, 138, 139, 140, 141, 142, 144
    ]
    return rewrittenCoachQuestionIds.indexOf(coachQuestion.id) > -1;
  }

  isModifiedBeforeUpdate = (coachQuestions: Array<CoachQuestionInterface>) => {
    if (coachQuestions.length === 0) {
      return false
    }
    const rewrittenAt = new Date('2022-12-06T17:00:00')
    for (const coachQuestion of coachQuestions) {
      if (coachQuestion.updatedAt) {
        const updatedAt = new Date(coachQuestion.updatedAt)
        if (updatedAt > rewrittenAt) {
          return false
        }
      }
    }

    // None of the coach question answers were modified after update
    return true
  }

  componentDidMount() {
    const oneTaskIntroName = this.props.focusArea.focusArea + '_hide_intro'
    let seenIntro = false
    if (oneTaskIntroName in this.props.clientUISettings) {
      seenIntro = true
    }

    this.props.toggleQuestion(false)
    this.props.toggleStatement(false)

    this.setState({ seenIntro: seenIntro })
    this.checkDone()
    this.checkPlanDone();
    this.checkAnswerLength()
    setTimeout(() => {
      const tx = document.getElementsByTagName("textarea")
      for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px");
      }
    }, 100)
    this.animateMedal(this.countAnswers(), false, 0);
  }

  checkDone = () => {
    let index = 0;
    this.props.potentialQuestion.coachQuestions.map((question: any) => {
      if (question.done) {
        this.checkIfCheckmark[index] = 1
      } else {
        this.checkIfCheckmark[index] = 0
      }
      index++
      return this.checkIfCheckmark
    })
  }

  private saveAnswer = () => {
    if (this.taskChanged) {
      this.taskChanged = false
      const data = {
        coach_question_id: this.state.coachQuestion.id,
        name: this.state.coachQuestion.name,
        answer: this.state.coachQuestion.answer,
        done: this.state.coachQuestion.done
      }
      this.api.post('focus_task/save', data).then(_response => {
        this.setState({changes: 0})
      })
    }
  }

  hideIntro = () => {
    let data = {}

    if (this.props.focusArea.focusArea === 'prestation') {
      data = {
        'prestation_hide_intro': true,
      }
    } else if (this.props.focusArea.focusArea === 'self_insight') {
      data = {
        'self_insight_hide_intro': true,
      }
    } else if (this.props.focusArea.focusArea === 'stress') {
      data = {
        'stress_hide_intro': true,
      }
    } else if (this.props.focusArea.focusArea === 'motivation') {
      data = {
        'motivation_hide_intro': true,
      }
    } else if (this.props.focusArea.focusArea === 'relation') {
      data = {
        'relation_hide_intro': true,
      }
    } else {
      data = {
        'future_hide_intro': true,
      }
    }

    this.api.post('ui_settings', data)
      .then(response => {
        this.props.updateOneTaskIntro(this.props.focusArea.focusArea)
        this.setState({ seenIntro: true })
      }
    )
  }

  introContinue = () => {
    if (this.state.hideIntro) {
      this.hideIntro()
    } else {
      this.setState({seenIntro: true})
    }
  }


  getBreadcrumbs = () => {
    let breadcrumbs = []
    if (this.props.navigation.client) {
      breadcrumbs.push({name: this.api.trTxt(TrVar.MyClients), link: '/app/coach/clients'})
      breadcrumbs.push({name: this.props.clientName, link: '/app/coach/clients/' + this.props.navigation.client})
    }
    return breadcrumbs
  }

  nextQuestion = (i: number) => {
    this.setState({ position: i, coachQuestion: this.props.potentialQuestion.coachQuestions[i] });
  }

  handleAnswer = (e: any, i: number) => {
    const coachQuestion = this.props.potentialQuestion.coachQuestions[i];
    coachQuestion.answer = e.target.value;
    this.taskChanged = true;
    this.setState({ coachQuestion: coachQuestion });
    this.checkIfCheckmark[i] = 0;
    this.checkAnswerLength()
    const h = e.target.scrollHeight > 270 ? 270 : e.target.scrollHeight
    e.target.style.height = h + "px"
    return this.checkIfCheckmark
  }

  checkAnswerLength = () => {
    let index = 0;
    this.props.potentialQuestion.coachQuestions.map((question: any) => {
      if (question.answer.length === 0) {
        this.checkAnswer[index] = 1
      } else {
        this.checkAnswer[index] = 0
      }
      index++
      return this.checkAnswer
    })
  }

  isCardDone = (coachQuestions: number) => {
    if (this.props.potentialQuestion.loop) {
      if (coachQuestions === (this.props.potentialQuestion.coachQuestions.length - 1)) {
        this.setState({ showCardDone: true })
      }
      return false
    }

    if (coachQuestions === (this.props.potentialQuestion.coachQuestions.length - 1)) {
      this.setState({ showCardDone: true })
      return true
    }
    return false
  }

  handleDone = (i: number) => {
    const coachQuestion = this.props.potentialQuestion.coachQuestions[i];
    if (this.checkAnswer[i] === 0) {
      const questionWasDone = coachQuestion.done
      coachQuestion.done = true
      this.taskChanged = true
      this.setState({ coachQuestion: coachQuestion }, () => this.saveAnswer())
      this.nextQuestion(i);
      this.checkIfCheckmark[i] = 1;
      if (!questionWasDone) {
        this.nextSound.play()
        this.props.toggleQuestion(true)
        this.props.answeredQuestions()
        this.animateMedal(i + 1, true, coachQuestion.question);
        if (this.isCardDone(i)) {
          this.doneStatements++
          this.props.toggleStatement(true)
          this.props.isCardDone()
        } else {
          // this.scrollToBottom();
          setTimeout(() => {
            this.scrollDown(i + 1)
          }, 100)
        }
      }
      return this.checkIfCheckmark
    }
  }

  countAnswers = () => {
    let questions = this.props.potentialQuestion.coachQuestions;
    let totalCount = 0;
    questions.forEach((q: any) => {
      if (q.done) return totalCount++
    })
    return totalCount;
  }

  animateMedal = (count: number, animate: boolean, i:any) => {
    if (animate) {
      if (count === 1) {
        this.medalSrc = '/assets/animations/medal1.svg'
      } else {
        this.medalSrc = '/assets/animations/medal2.svg?i='+i
      }
    } else {
      if (count === 0) {
        this.medalSrc = '/assets/animations/medal_gray.svg'
      } else {
        this.medalSrc = '/assets/animations/medal_green.svg'
      }
    }
  }

  private scrollDown = (position: number) => {
    const q = document.getElementById('scroll_q_' + position)
    if (this.props.scrollRef.current && q) {
      const scrollY = q.offsetTop - 50
      this.props.scrollRef.current.scrollToPoint(0, scrollY,625)
    }
  };

  private scrollToBottom = () => {
    if (this.props.scrollRef.current) {
      this.props.scrollRef.current.scrollToBottom(625)
    }
  };

  checkPlanDone = () => {
    let questionId = this.props.potentialQuestion.coachQuestions[0].id;
    this.props.plans.forEach(plan => {
      if (plan.coachQuestionId === questionId) {
        this.setState({ isPlanDone: true })
        if (plan.followupCompleted) {
          this.setState({ isFollowupDone: true })
        }        
      }
    })
  }

  showProgress = () => {

    return (
      <>
        <IonModal isOpen={this.state.showCardHelp} onDidDismiss={() => this.setState({ showCardHelp: false })}
          className="modal_full">
          {
            this.state.showCardHelp &&
            <CardHelpModal close={() => this.setState({ showCardHelp: false })} />
          }
        </IonModal>
        <div className="task_progress_direction flex justify-between items-center">
          <div className='flex'>
            <img className='align-center  w-16' src={"/assets/focusArea/area_icon_" + this.props.focusArea.focusArea + '.svg'} height={"50px"}
              alt="area" />
            <div className="flex flex-col left_margin">
              <h2>{this.props.focusArea.name}</h2>
              <div onClick={() => this.setState({ showCardHelp: true })} className="font_small underline blue cursor-pointer">{this.api.lang === 'sv' ? 'Läs mer' : `Read more`}</div>
            </div>
          </div>
          <div className="flex grow justify-center">
            {
              this.props.potentialQuestion.coachQuestions.map((cq: CoachQuestionInterface, i) => {
                const bg = cq.done ? "bg_green" : "bg_blue_gray"
                return (
                  <div className='hide_small px-1' key={"circle_" + i}>
                    <div  className={"circle_small flex items-center justify-center color_black " + bg}>
                      {i + 1}
                    </div>
                  </div>
                )
              })
            }
            {this.props.potentialQuestion.loop &&
              <>
                  <div className='hide_small'>
                    <div  className={"circle_small flex items-center justify-center color_black " + (this.state.isPlanDone ? 'bg_green' : 'bg_blue_gray')}>
                      {this.props.potentialQuestion.coachQuestions.length + 1}
                    </div>
              </div>              
                  <div className='hide_small'>
                    <div className={"circle_small flex items-center justify-center color_black " + (this.state.isFollowupDone ? 'bg_green' : 'bg_blue_gray')}>
                      {this.props.potentialQuestion.coachQuestions.length + 2}
                    </div>
                  </div>       
            </>

            }
          </div>
          <div className='flex justify-end'>
            <div className="flex w-12 ali">
              <img src={this.medalSrc} height={'50px'} alt="medal" />
            </div>
            <div className="flex">
              <div className="ml-1 mt-4" >{this.countAnswers() + (this.state.isPlanDone ? 1 : 0 ) + (this.state.isFollowupDone ? 1 : 0 )}</div>
              <div  className="show_small mt-4">/{this.props.potentialQuestion.coachQuestions.length + (this.props.potentialQuestion.loop ? 2 : 0)}</div>
            </div>

          </div>
        </div>        
      </>

    )
  }

  questionTitle = (index: number) => {
    if (index === 0) {
       return this.api.trTxt(TrVar.Reflection) 
    } else if (index === 1) {
       return this.api.trTxt(TrVar.DesiredState)
    } else if (index === 2) {
       return this.api.trTxt(TrVar.Behaviour)
    } else {
       return this.api.trTxt(TrVar.Insight)
    }
  }

  renderQuestions = () => {
    const conversations = []
    let index = 0
    for (const question of this.props.potentialQuestion.coachQuestions) {
      if (index === 0 || this.props.potentialQuestion.coachQuestions[index - 1].done) {
        const position = index
        conversations.push(
          <div key={'id_' + question.id + '_' + question.name} id={'scroll_q_' + position} className="top_margin ">
            <div className='flex flex-col'>
              <div className="task_question">
                <div className="font_smallest blue_gray">
                  {this.questionTitle(position)}
                </div>
                <div className="max-w-8">{question.question}</div>
              </div>
              <div className="task_question_arrow" />
            </div>

            <div className="flex justify-center top_margin">
              <div className="flex flex-col relative" >
                {
                  question.done &&
                  <img className="question_card_done_marker" src={"/assets/animations/thumbsup.svg?id_" + question.id + '_' + question.name}
                    width={'30px'} alt="thumb's up" />
                }

                <textarea disabled={this.props.navigation.client > 0}
                  className="mx-auto task_text_area text_left"
                  placeholder={this.api.trTxt(TrVar.WriteHere)}
                  value={question.answer}
                  onInput={(e) => this.handleAnswer(e, position)} 
                  style={{height: '175px'}}
                />
                
                <div className="bg-[rgb(0, 0, 38)] border-l-[1px] border-[#60608b] border-r-[1px]">
                  <div className="task_bar" />
                </div>

                <div className={"flex justify-start cursor-pointer task_done_checkbox relative " + (this.checkAnswer[position] === 1 ? 'disabled' : '')}
                  onClick={() => this.handleDone(position)}>
                  <div className={"task_radio_input " + (this.checkIfCheckmark[position] === 1 ? "chosen" : this.checkAnswer[position] === 1 ? "disabled" : "")} />
                  {this.checkIfCheckmark[position] === 1 &&
                    <div className='absolute h-5 object-contain p-0.5' >
                      <SvgAnimation  color='#fff' position={(position + Math.random()).toString()} animationType='simple_checkmark' />
                    </div>
                  }
                  <div className="left_margin">
                    {this.checkIfCheckmark[position] === 1 ?
                      this.api.lang === 'sv' ? 'Sparat' : 'Saved'
                      :
                      (this.checkIfCheckmark[position] === 0 && question.done) ?
                        this.api.trTxt(TrVar.Save)
                        :
                        ((position + 1) === this.props.potentialQuestion.coachQuestions.length) ?
                          this.api.lang === 'sv' ? 'Klar med alla frågor' : 'Done with all questions'
                          :
                          this.api.lang === 'sv' ? 'Redo för nästa fråga' : 'Ready for next question'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        break
      }
      index++
    }
    if (this.countAnswers() === this.props.potentialQuestion.coachQuestions.length) {
      conversations.push(
        <div key={'coachquestion_done'}>
          <div className="text_center">
            <div className="button green btn_top_margin inline_block" onClick={() => this.goBack()}>
              {this.api.trTxt(TrVar.Back)}
            </div>
          </div>

          {
            this.props.potentialQuestion.loop &&
            <>
              <div className="flex flex-row task_plan_divider mt-8">
                <div className="task_divider grow" />
                <div  className=' font_smaller blue_gray left_margin right_margin'>{this.api.lang === 'sv' ? 'Gör plan' : `Create plan`}</div>
                <div className="task_divider grow" />
              </div>
              <div >
                <TaskPlan focusArea={this.props.focusArea} potentialQuestion={this.props.potentialQuestion}
                  navigation={this.props.navigation}
                  clientName={this.props.clientName.split(' ')[0]}
                  updatePlan={this.props.updatePlan}
                  fromFollowup={this.props.fromFollowup}
                  scrollRef={this.props.scrollRef} baseUrl={this.props.baseUrl} plans={this.props.plans}
                />
              </div>
            </>
          }

        </div>
      )
    }
    return conversations
  }

  showIntro = () => {
    return (
      <div className="flex justify-center mx-auto" >
        <div>
          <div id='scroll_p_0' className="task_question">
            <div className="blue_gray font_smallest">{this.api.lang === 'sv' ? 'Introduktion' : 'Introduction'}</div>
            <h2  >{this.api.lang === 'sv' ? 'Hej' : 'Hi'} {this.api.profile.firstName}!</h2>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                'Så gör du övningen:'
                :
                `This is how you do the exercise:`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Varje övning innehåller 4-5 frågor. Ta dig tid att reflektera kring varje fråga och skriv sedan ner din tankar. Du kan fortsätta vid ett senare tillfälle om du inte hinner färdigt.'
                :
                `Each exercise contains 4-5 questions. Take time to reflect on each question and then write down your thoughts. If you don't have time to finish, feel free to come back later.`
              }
            </div>
            <h4 className="top_margin">
              {this.api.lang === 'sv' ?
                'Så är frågorna utformade:'
                :
                `How the questions are designed:`
              }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Frågorna i varje övning följer coachmetodikens struktur:'
                :
                `The questions in each exercise follow the structure of the coaching methodology:`
              }
            </div>
            <h4 className="top_margin">
                1. {this.api.trTxt(TrVar.Reflection)}
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Börja reflektera över din nuvarande situation.'
                :
                `Start reflecting on your current situation.`
              }
            </div>
            <h4 className="top_margin">
                2. {this.api.trTxt(TrVar.DesiredState) }
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Hur skulle du önska att saker och ting vore om du fick tänka fritt?'
                :
                `How would you like things to be if you could think freely?`
              }
            </div>
            <h4 className="top_margin">
              3. {this.api.trTxt(TrVar.Behaviour)}
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Vad kan du göra för att skapa förändring?'
                :
                `What can you do to initiate change?`
              }
            </div>
            <h4 className="top_margin">
                4. {this.api.trTxt(TrVar.Insight)}
            </h4>
            <div>
              {this.api.lang === 'sv' ?
                'Vad tar du med dig och vad kan du lära dig om detta?'
                :
                `What do you take with you and what can you learn from this?`
              }
            </div>
            <div className="btn_top_margin flex items_center cursor-pointer"
              onClick={() => this.setState({ hideIntro: !this.state.hideIntro })}>
              <div className="check_box small relative">
                {
                  this.state.hideIntro &&
                  <img src="/assets/focusArea/check_dark.svg" alt="check" />
                }
              </div>
              <div className="left_margin blue_gray font_small">
                {this.api.lang === 'sv' ? 'Visa inte igen' : 'Do not show again'}
              </div>
            </div>
          </div>
          <div className="task_question_arrow" />
          <div className="button btn_top_margin green inline_block" onClick={() => this.introContinue()}>
            {this.api.trTxt(TrVar.Continue)}
          </div>
        </div>
      </div>
    )
  }

  cardDone = () => {
    this.setState({showCardDone: false})
    this.props.setChooseNext(true)
    setTimeout(() => {
      this.goBack()
    }, 300)
  }

  areaDone = () => {
    this.setState({showCardDone: false})
    setTimeout(() => {
      changeUrl('/app/client/tasks2')
    }, 300)
  }

  goBack = () => {
    changeUrl(this.props.baseUrl + this.props.focusArea.focusArea + "/")
  }

  closeDoneModal = () => {
    this.setState({ showCardDone: false });
    this.scrollToBottom();
  }

  render() {

    let breadcrumbs = this.getBreadcrumbs()
    breadcrumbs.push({name: this.api.trTxt(TrVar.DevelopmentAreas), link: this.props.baseUrl})
    breadcrumbs.push({name: this.props.focusArea.name, link: this.props.baseUrl + this.props.focusArea.focusArea + "/"})

    if (!this.props.potentialQuestion.question || !this.state.coachQuestion) {
      return (
        <div className="page_content">
          <Breadcrumbs name={this.api.trTxt(TrVar.Exercise)} breadcrumbs={breadcrumbs} />
          
          <div className="page_section">
            Missing question!
          </div>
        </div>
      )
    }

    return (
      <div className="page_content relative h-screen ">
        <IonModal isOpen={this.state.showCardDone} onDidDismiss={() => this.setState({ showCardDone: false })}
          className="modal_tall">
          {!this.props.potentialQuestion.loop
            ?
            <div className="bg_blue h-full">
              <div className="text_right">
                <img  className="cursor-pointer mt-3 mr-4"
                  height={'20px'} src="/assets/icon/white/cross.svg" alt="close"
                  onClick={() => this.closeDoneModal()} />
              </div>
              <div className="flex flex-col justify-center">
                <div className="text_center ml-4">
                  <div className="modal_animation mx-auto" style={{maxWidth: "300px"}}>
                    <SvgAnimation animationType='star' />
                  </div>
                  <h2>
                    {this.api.lang === 'sv' ? 'Bra jobbat!' : 'Great job!'}
                  </h2>
                  <div className="top_margin">
                    {this.api.lang === 'sv' ? 'Du är ett steg närmare ditt önskeläge ' : `You're a step closer to your desired state.`}
                  </div>
                </div>

                <div className='text_center'>
                  <div className="top_margin button green inline_block"
                    onClick={() => this.cardDone()}>
                    {this.api.lang === 'sv' ? 'Gå vidare' : 'Go forward'}
                  </div>
                </div>
                <div className='text_center'>
                  <div className="top_margin button black inline_block"
                    onClick={() => this.setState({ showCardDone: false })}>
                    {this.api.lang === 'sv' ? 'Se mina svar' : 'See answers'}
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="bg_blue h-full ">
              <div className="text_right">
                <img  className="cursor-pointer ml-4 mt-3 mr-4"
                  height={'20px'} src="/assets/icon/dark_blue/cross.svg" alt="close"
                  onClick={() => this.closeDoneModal()} />
              </div>
              <div  className="flex flex-col justify-center text_center">
                <div>
                  <div  className="modal_animation mx-auto text-neutral-900">
                    <SvgAnimation animationType='checkmark' />
                    <h2>
                      {this.api.lang === 'sv' ? 'Härligt!' : 'Great!'}
                    </h2>
                    <div className="top_margin">
                      {this.api.lang === 'sv' ?
                        'Du har svarat på alla frågor. Nu är det dags att göra en plan.'
                        : `You have answered all the questions. Now it's time to create your plan.`}
                    </div>
                  </div>
                    
                </div>
                <div className='mx-auto btn_top_margin'>
                  <div className="button green inline_block"
                    onClick={() => this.closeDoneModal()}>
                    {this.api.lang === 'sv' ? 'Gör plan' : 'Create plan'}
                  </div>
                </div>
              </div>
            </div>
          }
        </IonModal>
        <Breadcrumbs name={this.api.trTxt(TrVar.Exercise)} breadcrumbs={breadcrumbs} />
        <div className="task_header">
          {this.showProgress()}

        </div>
        <div className="mt-24" />
        <div className="page_section task_section relative w-full " style={{width: "100%"}}>

          <div className="flex justify-center mx-auto w-full" >
            <div className="w-full">
              <h2  className='text_center'>{this.props.potentialQuestion.question.question}</h2>
              {this.state.seenIntro &&
                <div className="flex flex-row task_question_divider mt-8">
                  <div className="task_divider grow" />
                  <div className='font_smaller blue_gray left_margin right_margin'>{this.api.lang === 'sv' ? 'Frågor' : `Questions`}</div>
                  <div className="task_divider grow" />
                </div>
              }
              {
                this.state.seenIntro || this.props.fromFollowup ? (
                  <>
                    {
                      (this.state.coachQuestionRewritten && (this.state.hasPlan || this.state.modifiedBeforeUpdate)) &&
                        <div className="gradient_pink_purple color_black p-4 mb-8 box_radius flex max-w-8">
                          <img className="icon_large" alt="changed" src="/assets/icon/megaphone.svg"/>
                          <div className="ml-4">
                            {
                              this.state.hasPlan &&
                                <div>
                                  <h3>Nytt!</h3>
                                  <p>
                                    {
                                      this.api.lang === 'sv' ?
                                        'I den här övningen kan du nu göra en plan.'
                                        :
                                        'In this exercise you can now create a plan.'
                                    }
                                  </p>
                                </div>

                            }
                              <p>
                                {
                                  this.api.lang === 'sv' ?
                                    'Några frågor har formulerats om sedan du svarade så du kanske vill justera dina svar.'
                                    :
                                    'Some of these questions were modified since you answered, so you might want to adjust your answers.'
                                }
                              </p>
                          </div>

                        </div>
                    }
                    {this.renderQuestions()}
                  </>
                ) : (
                  this.showIntro()
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
