import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import { TrVar } from '../../services/translate';
import {ClientInterface} from "../../interfaces/Client";
import ChatWindow from "../ChatWindow";
import {ChatInterface} from "../../interfaces/Chat";
import Modal from '../../components/Modal';
import {IonModal} from "@ionic/react";
import './ClientChatAi.css';
interface AiMessageInterface {
  role: string
  content: string
}

interface SessionInterface {
  id: number
  name: string
  createdAt: string
  messages: Array<AiMessageInterface>
}
interface ComponentProps {
  scrollRef: any
}

interface ComponentState {
  sessions: Array<SessionInterface>
  currentSession: SessionInterface
  chatMessages: Array<ChatInterface>
  client: ClientInterface
  behaviour: string
  loaded: boolean
  waiting: boolean
  expanded: boolean
  aiTermsChecked: boolean
  aiTermsError: boolean
  approvedAiTerms: boolean|null
  showConfirmDelete: boolean
  toBeDeleted: number
}

export default class ClientChatAi extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    sessions: [],
    currentSession: {} as SessionInterface,
    chatMessages: [],
    behaviour: 'Neutral',
    client: {} as ClientInterface,
    loaded: false,
    waiting: false,
    expanded: false,
    aiTermsChecked: false,
    aiTermsError: false,
    approvedAiTerms: false,
    showConfirmDelete: false,
    toBeDeleted: 0
  }
  behaviours = ['Neutral', 'Driving', 'Boosting', 'Supporting', 'Analytical']
  firstMessage = {
    "role": "assistant",
    "content": this.api.trTxt(TrVar.AiIntro)
  }

  componentDidMount() {
    this.getData()
  }

  scrollDown = () => {
    if (this.props.scrollRef.current) {
      this.props.scrollRef.current.scrollToBottom();
    }
  }

  private getData = () => {

    let url = 'ai_chat/sessions'

    this.api.get(url).then(response => {
      let newSession: SessionInterface = {
        id: 0,
        createdAt: new Date().toISOString(),
        name: 'New session',
        messages: [this.firstMessage],
      }
      let currentSession: SessionInterface
      let sessions: Array<SessionInterface>
      if (response.json.sessions.length > 0) {
        currentSession = response.json.sessions[0]
        sessions = [newSession].concat(response.json.sessions)
      } else {
        currentSession = newSession
        sessions = [currentSession]
      }
      const chatMessages: Array<ChatInterface> = this.sessionToChatMessages(currentSession)
      this.setState({
        sessions: sessions,
        currentSession: currentSession,
        chatMessages: chatMessages,
        client: response.json.client,
        approvedAiTerms: response.json.approvedAiTerms,
        loaded: true
      }, () => this.scrollDown())
    })
  }

  sessionToChatMessages = (session: SessionInterface) => {
    const chatMessages: Array<ChatInterface> = []
    for (const message of session.messages) {
      chatMessages.push({
        id: 0,
        sender: message.role === 'user' ? 'you' : 'other',
        senderName: message.role === 'user' ? this.api.trTxt(TrVar.You) : 'Emma',
        message: message.content,
        createdAt: session.createdAt
      })
    }
    return chatMessages
  }

  checkAiTerms = () => {
    this.setState({aiTermsChecked: !this.state.aiTermsChecked, aiTermsError: false})
  }

  submitAiTerms = () => {
    if (!this.state.aiTermsChecked) {
      this.setState({aiTermsError: true})
      return
    }

    const data = {
      approved_ai_terms: true
    }

    this.api.post('ui_settings', data).then(result => {
      this.setState({approvedAiTerms: true})
    })
  }

  getName = () => {
    this.api.get('ai_chat/get_name/' + this.state.currentSession.id, ['service_unavailable'])
      .then(response => {
        const currentSession = this.state.currentSession
        const sessions = this.state.sessions
        currentSession.name = response.json.name
        for (const session of sessions) {
          if (session.id === currentSession.id) {
            session.name = currentSession.name
          }
        }
        this.setState({currentSession: currentSession, sessions: sessions})
      }).catch(response => {
        if (response.isExpectedError) {
          console.log('Failed naming session')
        }
      })
  }

  sendMessage = (message: string) => {
    if (message.length > 0) {
      const data = {
        firstMessage: this.api.trTxt(TrVar.AiIntro),
        message: message,
        id: this.state.currentSession.id,
        behaviour: this.state.behaviour
      }
      const currentSession = this.state.currentSession
      currentSession.messages.push({
        role: 'user',
        content: message
      })
      const chatMessages = this.sessionToChatMessages(currentSession)
      this.setState({waiting: true, currentSession: currentSession, chatMessages: chatMessages})
      let url = 'ai_chat/send_message'
      this.api.post(url, data, ['service_unavailable']).then(response => {
        let sessions = this.state.sessions
        if (this.state.currentSession.id === 0) {
          // Add new session
          sessions.splice(1, 0, response.json.session)
          // Reset session 0
          sessions[0] = {
            id: 0,
            createdAt: new Date().toISOString(),
            name: 'New session',
            messages: [this.firstMessage],
          }
        }
        const chatMessages = this.sessionToChatMessages(response.json.session)
        this.setState({currentSession: response.json.session, sessions: sessions, waiting: false,
            chatMessages: chatMessages}, () => {
          if (this.state.currentSession.name === 'Current session' && message.length > 8) {
            this.getName()
          }
        })

      }).catch(response => {
        if (response.isExpectedError) {
          alert('Emma failed to respond. You might want to try sending again?')
          this.setState({waiting: false})
        }
      })
    }
  }

  removeCurrentSession = (sessionId: number) => {
    this.setState({showConfirmDelete: false})
    this.api.get('ai_chat/remove_session/' + sessionId).then(_response => {
        const sessions = this.state.sessions.filter(session => session.id !== sessionId);
        let currentSession: SessionInterface;
        if (sessions.length > 1) {
          currentSession = sessions[1];
        } else {
          currentSession = sessions[0];
        }
        const chatMessages = this.sessionToChatMessages(currentSession)
        this.setState({currentSession: currentSession, sessions: sessions, chatMessages: chatMessages})
      })
  }

  changeSession = (sessionId: number) => {
    for (const session of this.state.sessions) {
      if (session.id === sessionId) {
        const chatMessages = this.sessionToChatMessages(session)
        this.setState({currentSession: session, chatMessages: chatMessages, expanded: false})
        break
      }
    }
  }

  changeBehaviour = (e: any) => {
    this.setState({behaviour: e.target.value})
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    let breadcrumbs = [{
      name: this.api.trTxt(TrVar.SelectChat),
      link: '/app/client/chat'
    }]
    let name = "AI Emma"


    return (
      <div className="page_content">
        <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>

        {
          this.state.approvedAiTerms ? (
            <div className="page_section">
              <Modal 
                isOpen={this.state.showConfirmDelete} 
                onLeftClick={() => this.setState({showConfirmDelete: false})}
                onRightClick={() => this.removeCurrentSession(this.state.toBeDeleted)}
                leftButtonText={this.api.trTxt(TrVar.NoSave)}
                rightButtonText={this.api.trTxt(TrVar.YesRemove)}
                onDismiss={() => this.setState({showConfirmDelete: false})}
              ></Modal>
              
              <div className="r-flex">
                <div style={{width: "min(330px, 100vw - 64px)"}}>
                  <div className="flex">
                    <img alt="coach" className="circle_large" src="/assets/images/ai_emma.svg"/>
                    <div className="left_margin ">
                      <div className="color_neutral_400">
                        {this.api.trTxt(TrVar.YourCoach)}
                      </div>
                      <h2>AI Emma</h2>
                    </div>
                  </div>

                  <div className="mt-4 page_bar hide_large"/>

                  <div className="r_top_margin relative">
                    <div className={"expandable_left_content bg_dark_blue" + (this.state.expanded ? " expanded  " : "")} style={{paddingRight: '20px'}}>
                      <div className="mt-4 button white inline_block full_width" style={{width: '100%'}} onClick={() => this.changeSession(0)}>
                        {this.api.trTxt(TrVar.NewChat)}
                      </div>
                      {/*<p className="mt-8">Beteendestil (test)</p>*/}
                      {/*<select value={this.state.behaviour} className="page_select"*/}
                      {/*        onChange={(e) => this.changeBehaviour(e)}>*/}
                      {/*  {*/}
                      {/*    this.behaviours.map((behaviour, i) => {*/}
                      {/*      return (*/}
                      {/*        <option key={"behaviour_" + i} value={behaviour}>{behaviour}</option>*/}
                      {/*      )*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*</select>*/}
                      <h3 className="mt-8">{this.api.trTxt(TrVar.PreviousChats)}</h3>
                      {
                        this.state.sessions.filter(session => session.id > 0).map(session => {
                          const selected = session.id === this.state.currentSession.id
                          const sessionClasses = selected ? '  ai_earlier_chat_active ' : ' ai_earlier_chat'
                          return (
                            <div 
                              className={"p-2 mt-3 border_radius_small flex items-center justify-between cursor-pointer hoverable-session" + sessionClasses} 
                              key={"session_" + session.id}
                            >
                              <div onClick={() => this.changeSession(session.id)}>{session.name}</div>
                              <svg 
                                className="ml-4 icon_small cursor-pointer" 
                                onClick={() => this.setState({showConfirmDelete: true, toBeDeleted: session.id})} 
                                width="18" 
                                height="20" 
                                viewBox="0 0 18 20" fill="none" 
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path 
                                className="trash-icon-path" 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M5.58579 0.585786C5.96086 0.210714 6.46957 0 7 0H11C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2V4H17C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H16.9311L16.1305 17.213C16.1305 17.2131 16.1305 17.2129 16.1305 17.213C16.0765 17.9698 15.7379 18.6783 15.1826 19.1954C14.6274 19.7125 13.8968 20 13.138 20H4.86202C4.10323 20 3.37262 19.7125 2.81735 19.1954C2.26213 18.6783 1.92347 17.97 1.86954 17.2132C1.86953 17.2132 1.86955 17.2133 1.86954 17.2132L1.06886 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H5V2C5 1.46957 5.21071 0.960859 5.58579 0.585786ZM7 4H11V2H7V4ZM3.07395 6L3.86446 17.0708C3.88242 17.3231 3.99533 17.5594 4.18042 17.7318C4.36551 17.9042 4.60905 18 4.862 18H13.138C13.391 18 13.6345 17.9042 13.8196 17.7318C14.0047 17.5594 14.1176 17.3233 14.1355 17.071L14.926 6H3.07395ZM7 8C7.55228 8 8 8.44772 8 9V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15V9C6 8.44772 6.44772 8 7 8ZM11 8C11.5523 8 12 8.44772 12 9V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V9C10 8.44772 10.4477 8 11 8Z" 
                                fill="#000026"/>
                              </svg>
                            </div>
                          )
                        })
                      }
                      <div className={"expandable_toggle" + (this.state.expanded ? " expanded": "")}
                           onClick={() => this.setState({expanded: !this.state.expanded})}>
                        <img src={"/assets/icon/black/chevron-" + (this.state.expanded ? "back" : "forward") + "-outline.svg"}
                             alt="to" className="icon_small"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="r-ml-12 r_top_margin w-full flex justify-around">
                  <ChatWindow chatMessages={this.state.chatMessages} onChat={this.sendMessage} standalone={true}
                              chatWithZebrain={false} ai={true} waiting={this.state.waiting}
                              isSendEnabled={true}/>

                </div>
              </div>
            </div>
          ) : (
            <div className="page_section">
              <h1>{this.api.trTxt(TrVar.GetCoachedByAI)}</h1>
              <div className="page_bar"/>
              

              <div className="flex-container mt-10">
                <div className="flex-grow">
                  <div>
                  {this.api.trTxt(TrVar.YouGet)}:
                    <ul className="text-neutral-400 pl-12 list-disc pt-2">
                      <li>{this.api.trTxt(TrVar.CustomnizedCoaching)}</li>
                      <li>{this.api.trTxt(TrVar.FastGuidance)}</li>
                      <li>{this.api.trTxt(TrVar.UnlimitedUsage)}</li>
                    </ul>
                    {this.api.trTxt(TrVar.AiBetaVersion)}.
                  </div>
                  <div className="mt-10 flex flex-row flex-nowrap">
                    <div
                      className={"check_box" + (this.state.aiTermsChecked ? " bg_blue" : "")}
                      onClick={() => this.checkAiTerms()}
                    >
                    </div>
                    <div className="pl-4">
                      <p className="font_small inline">{this.api.trTxt(TrVar.AcceptGDPR)}.</p>
                      <p>
                        <a className="font_small color_white underline" href="https://www.zebrain.se/sv/ai-emma-terms-and-conditions/">
                        {this.api.trTxt(TrVar.ReadMore)}
                        </a>
                      </p>
                    </div>
                  </div>

                  {this.state.aiTermsError && (
                    <div className="mt-10 p-4 bg_apricot corner_radius color_black flex items-center">
                      <img alt="check" className="icon_small" src="/assets/icon/red_circle_checkbox.svg" />
                      <p className="ml-4">{this.api.trTxt(TrVar.AcceptStorePersonalInfo)}.</p>
                    </div>
                  )}
                </div>

                <div className="ai-container">
                  <img className="ai-container-img" alt="decoration" src="/assets/images/ai_emma.svg" />
                </div>
              </div>

              <div className="next-button-container">
                <div className="mt-10 button inline_block" onClick={() => this.submitAiTerms()}>
                {this.api.trTxt(TrVar.Next)}
                </div>
              </div>



            </div>
          )
        }

      </div>
    )
  }
}
