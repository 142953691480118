import React from 'react';
import "./Menu.css";
import Api from "../../services/Api"
import {urlChange} from "../../redux/actions";
import {connect, ConnectedProps} from "react-redux";
import {changeUrl} from "../../services/Navigation";
import { Link } from 'react-router-dom';
import {ClientInterface} from "../../interfaces/Client";
import {CoachInterface} from "../../interfaces/Coach";
import { PackageInterface } from "../../interfaces/Package";
import { TrVar } from "../../services/translate"
// import {CONFIG} from "../../constants";

interface ComponentState {
  isHamburgerOpen: boolean
  showDetailedMenu: boolean
  innerWidth: number
  showDropdown: boolean
  unreadChats: boolean
}

const mapState = (state: any) => ({
  navigation: state.navigation,
})

const mapDispatch = {
  urlChange,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ComponentProps extends PropsFromRedux {
  client?: ClientInterface
  coach?: CoachInterface
  isClient?: boolean
  isCoach?: boolean
  unreadChats: boolean
  package?: PackageInterface
  oldPowerTasks?: boolean
  showChat?: boolean
  customerPrograms?: number
  frameworks?: number
}

class Menu extends React.Component<ComponentProps, ComponentState> {
  private api = Api.getInstance()
  private lastOpenMenu: Date
  private who: string
  private firstName: string
  private profileImage: string

  constructor(props: ComponentProps) {
    super(props);
    this.lastOpenMenu = new Date()
    this.state = {
      isHamburgerOpen: false,
      showDetailedMenu: false,
      innerWidth: window.innerWidth,
      showDropdown: false,
      unreadChats: false,
    }
    if (this.props.client) {
      this.who = 'client'
      this.firstName = this.props.client.firstName
      this.profileImage = this.props.client.picture
    } else if (this.props.coach) {
      this.who = 'coach'
      this.firstName = this.props.coach.firstName
      this.profileImage = this.props.coach.picture
    } else {
      this.who = 'unknown'
      this.firstName = 'Unknown'
      this.profileImage = '/assets/icon/white/profile_image.svg'
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.setDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDimensions)
  }

  setDimensions = () => {
    if (window.innerWidth > 799 && this.state.isHamburgerOpen) {
      this.setState({isHamburgerOpen: false})
    }
    this.setState({innerWidth: window.innerWidth})
  }

  toggleHamburger = () => {
    this.setState({isHamburgerOpen: !this.state.isHamburgerOpen})
  }


  goTo = (url: string) => {
    if (this.state.isHamburgerOpen) {
      this.toggleHamburger()
    }
    if (url === 'logout') {
      this.logOut()
    } else if (url.startsWith("/")) {
      window.location.href = url
    } else {
      this.setState({showDetailedMenu: false})
      this.lastOpenMenu = new Date();
      changeUrl('/app/' + this.who + '/' + url);
    }
  };

  logOut = () => {
    if (window.location.pathname.startsWith('/app/coach')){
      this.api.logOut('/app/signed_out?coach')
    } else {
      this.api.logOut('/app/signed_out?client')
    }
  };

  getPage = () => {
    if (this.props.navigation.client > 0) {
      return 'clients'
    }
    if (this.props.navigation.page === '') {
      return 'overview'
    }
    return this.props.navigation.page
  }

  menuItemLarge = (page: string, selectedPage: string, icon: string, name: string, badges?: any, itemNew?: boolean) => {
    const expanded = this.state.showDetailedMenu || this.state.isHamburgerOpen
    const wide = expanded ? " wide" : ""
    const selected = page === selectedPage
    const color = selected? 'black' : 'white'
    const sel = selected ? "selected_link" : "unselected_link"
    const badgeNr = (badges && badges.unread > 0) ? badges.unread : 0
    return (
      <div className={"relative cursor-pointer hover:bg-primary-500 flex flex-nowrap items-center " + sel}
          onClick={() => this.goTo(page)}>
        <img alt={page} className={"menu_icon svg_" + color} src={"/assets/icon/" + icon}/>
        <div data-cy="left-menu-expanded" className={"whitespace-nowrap " + (wide ? "ml-3 visible transition-all duration-[0.1s]" : "ml-0 invisible transition-all duration-0")}>
          {name}
          {
            (expanded && badgeNr > 0) &&
            <span 
              className="ml-2 inline-flex items-center justify-center w-6 h-6 me-2 bg-red-500 text-white rounded-full"
            >
              {badgeNr}
            </span>
          }
          {
            (expanded && itemNew) &&
            <div className={"text-green-500 " + (this.api.lang === 'en' ? "text-en" : "")}>{this.api.lang === 'sv' ? 'Ny' : 'New' }</div>            
          }
        </div>
        {
          (!expanded && itemNew) &&
            <div className={"expanded_new_marker closed"} />            
        }
        {
          (!expanded && badgeNr > 0) &&
          <div className="badge_marker"/>
        }
      </div>
    )
  }

  openMenu = () => {
    if (this.state.showDetailedMenu) {
      return
    }
    const now = new Date();
    const sinceLastOpen = now.getTime() - this.lastOpenMenu.getTime()
    if (sinceLastOpen > 300) {
      this.lastOpenMenu = now;
      this.setState({ showDetailedMenu: true })
    }
  }

  showChat = () => {
    if (this.who === 'client') {
      if (!this.props.showChat) {
        // If they don't have a coach, hide chat
        return false
      // } else if (this.props.package && this.props.package.type > 6) {
      //   // package type 7 is access - they can not chat with Zebrain
      //   return false
      }
    }
    return true
  }
  // Show dropdown menu
  handleMouseOver = () => {
    if (!this.state.showDropdown) this.setState({ showDropdown: true });
  };

  // Hide dropdown menu
  handleMouseLeave = () => {
    if (this.state.showDropdown) {
      setTimeout(() => {
        this.setState({ showDropdown: false });
      }, 10); // 100ms delay
    }
  };
  render() {
    const page = this.getPage()
    const wide = this.state.showDetailedMenu ? " wide" : ""
    let changeRoleUrl = ""
    let changeRoleText = ""
    if (this.props.isClient) {
      changeRoleUrl = "/app/client/overview"
      changeRoleText = this.api.trTxt(TrVar.SwitchToClient)
    } else if (this.props.isCoach) {
      changeRoleUrl = "/app/coach/overview"
      changeRoleText = this.api.trTxt(TrVar.SwitchToCoach)
    }

    return (
      <>
        <div 
          data-cy="dropdown-menu" 
          className="top_menu" 
          onMouseOver={() => { if (!this.state.showDropdown) this.setState(prevState => ({ showDropdown: true })) } }
          // onMouseLeave={() => {if (this.state.showDropdown) this.setState(prevState => ({ showDropdown: false }))} }
        >
          <div className="client_name right_margin color_white">
            {this.firstName}
          </div>
          <img className="dropdown_image" src={this.profileImage} alt="profile" />
        </div>
        
        <div 
          className={"dropdown_menu" + (this.state.showDropdown ? " show" : "")}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className='my-auto'>
            <div data-cy="dropdown-link" className="flex items-center cursor-pointer" onClick={() => this.goTo('my_account')}>
              <img alt="log out" className="icon_small svg_white" src={"/assets/icon/profile.svg"}/>
              <div className="ml-2">{this.api.trTxt(TrVar.MyAccount)}</div>
            </div>
            {
              changeRoleUrl !== "" &&
              <Link to={changeRoleUrl} className="dropdown_link flex items-center" onClick={() => changeUrl(changeRoleUrl)}>
                  <img alt="log out" className="icon_small" src={"/assets/icon/white/change.svg"}/>
                  <div className="ml-3 color_white">{changeRoleText}</div>
              </Link>
            }
            <div className="dropdown_link flex items-center cursor-pointer" onClick={() => this.goTo('logout')}>
              <img alt="log out" className="icon_small svg_white" src={"/assets/icon/sign_out.svg"}/>
              <div className="ml-3">{this.api.trTxt(TrVar.SignOut)}</div>
            </div>
          </div>
          <img className="dropdown_image" src={this.profileImage} alt="profile" />
        </div>

        <div data-cy="left-menu" className={"left_menu " + wide}
             onMouseOver={() => this.openMenu()}
             onMouseLeave={() => this.setState({ showDetailedMenu: false })}>

          <div className={"menu_logo " + wide}/>
          {
            this.who === 'client' ? (
              <>
                {this.menuItemLarge( 'overview', page, 'overview.svg', this.api.trTxt(TrVar.YourOverview))}
                {this.menuItemLarge( 'bookings', page, 'calendar.svg', this.api.trTxt(TrVar.YourBookings))}
                {
                  this.props.frameworks && this.props.frameworks > 0 ?
                  this.menuItemLarge( 'program', page, 'program.svg', this.api.trTxt(TrVar.YourProgram)) : ''
                }
                {this.menuItemLarge( 'meeting_history', page, 'calendar_checked.svg', this.api.trTxt(TrVar.MeetingHistory))}
                {
                  this.props.oldPowerTasks &&
                  this.menuItemLarge( 'tasks', page, 'diagram.svg', this.api.trTxt(TrVar.GrowPotential))
                }
                {this.menuItemLarge('tasks2', page, 'arrow_bullseye.svg', this.api.trTxt(TrVar.DevelopmentAreas))}
                {this.menuItemLarge( 'measure', page, 'measurement.svg', this.api.trTxt(TrVar.YourMeasurements))}
                {this.menuItemLarge( 'behaviour', page, 'search.svg', this.api.trTxt(TrVar.YourBehaviourProfile))}
              </>
            ) : (
              <>
                {this.menuItemLarge( 'overview', page, 'overview.svg', this.api.trTxt(TrVar.Overview))}
                {this.menuItemLarge( 'bookings', page, 'calendar.svg', this.api.trTxt(TrVar.MyBookings))}
                {this.menuItemLarge( 'meeting_history', page, 'calendar_checked.svg', this.api.trTxt(TrVar.MeetingHistory))}
                {this.menuItemLarge( 'clients', page, 'clients.svg', this.api.trTxt(TrVar.MyClients))}
                {this.menuItemLarge( 'behaviour', page, 'search.svg', this.api.trTxt(TrVar.YourBehaviourProfile))}
              </>
            )
          }
          {
            this.showChat() &&
            this.menuItemLarge( 'chat', page, 'chat.svg', this.api.trTxt(TrVar.Chat),
              this.props.unreadChats)
          }
          <div id="iconBar" className={"menu_bar " + wide}/>

          {
            this.who === 'client' ?
              <>
                {this.menuItemLarge('about_potential', page, 'method.svg', this.api.trTxt(TrVar.AboutPotential))}
                {this.menuItemLarge( 'faq', page, 'circled_questionmark.svg', this.api.trTxt(TrVar.Faq))}
                {this.menuItemLarge( 'support', page, 'support.svg', this.api.trTxt(TrVar.Support))}
                
              </>
              :
              <>
                {this.menuItemLarge('measure', page, 'method.svg', this.api.trTxt(TrVar.OurMethod))}
                {this.menuItemLarge( 'support', page, 'support.svg', this.api.trTxt(TrVar.Support))}
              </>
          }
          {/*{this.menuItemLarge( 'chat', page, 'chat.svg', this.api.trVar('chat'),*/}
          {/*  this.props.unreadChats)}*/}

        </div>

        <div className="hamburger_top_menu items-center">
          <div className={"menu_hamburger " + (this.state.isHamburgerOpen ? " change_bars" : "")}
               onClick={() => this.toggleHamburger()} >
            <div className="menu_bar1"/>
            <div className="menu_bar2"/>
            <div className="menu_bar3"/>
          </div>
        </div>

        {
          this.state.innerWidth < 800 &&
          <div className={"hamburger_menu" + (this.state.isHamburgerOpen ? " open" : "")}>
              <div className='flex justify-between relative'>
                <div className="logo_hamburger">
                    <img src="/assets/icon/green/logo_text.svg" alt="Zebrain" />
                </div>            
                <img onClick={()=> this.toggleHamburger()} className="cursor-pointer absolute" style={{ right: '22px', top: '29px' }} src="/assets/icon/white/cross.svg" alt="" />
              </div>
            {
              this.who === 'client' ? (
                <>
                  {this.menuItemLarge( 'overview', page, 'overview.svg', this.api.trTxt(TrVar.YourOverview))}
                  {this.menuItemLarge( 'bookings', page, 'calendar.svg', this.api.trTxt(TrVar.YourBookings))}
                  {
                    this.props.frameworks && this.props.frameworks > 0 ?
                      this.menuItemLarge( 'program', page, 'program.svg', this.api.trTxt(TrVar.YourProgram))
                        : ''
                  }
                  {this.menuItemLarge( 'meeting_history', page, 'calendar_checked.svg', this.api.trTxt(TrVar.PreviousMeetings))}
                  {
                    this.props.oldPowerTasks &&
                    this.menuItemLarge( 'tasks', page, 'diagram.svg', this.api.trTxt(TrVar.GrowPotential))
                  }
                  {this.menuItemLarge( 'tasks2', page, 'arrow_bullseye.svg', this.api.trTxt(TrVar.DevelopmentAreas))}
                  {this.menuItemLarge( 'measure', page, 'measurement.svg', this.api.trTxt(TrVar.YourMeasurements))}
                  {this.menuItemLarge( 'behaviour', page, 'search.svg', this.api.trTxt(TrVar.YourBehaviourProfile))}
                </>
              ) : (
                <>
                  {this.menuItemLarge( 'overview', page, 'overview.svg', this.api.trTxt(TrVar.Overview))}
                  {this.menuItemLarge( 'bookings', page, 'calendar.svg', this.api.trTxt(TrVar.MyBookings))}
                  {this.menuItemLarge( 'meeting_history', page, 'calendar_checked.svg', this.api.trTxt(TrVar.PreviousMeetings))}

                  {this.menuItemLarge( 'clients', page, 'clients.svg', this.api.trTxt(TrVar.MyClients))}
                  {this.menuItemLarge('measure', page, 'method.svg', this.api.trTxt(TrVar.OurMethod))}
                  {this.menuItemLarge( 'support', page, 'support.svg', this.api.trTxt(TrVar.Support))}
                      
                </>
              )
            }
            {this.menuItemLarge( 'chat', page, 'chat.svg', this.api.trTxt(TrVar.Chat),
              this.props.unreadChats)}
              <div id="iconBar" className={"menu_bar " + wide}/>


            {
                this.who === 'client' && 
                <>
                  {this.menuItemLarge('about_potential', page, 'method.svg', this.api.trTxt(TrVar.AboutPotential))}
                  {this.menuItemLarge( 'faq', page, 'circled_questionmark.svg', this.api.trTxt(TrVar.Faq))}
                  {this.menuItemLarge( 'support', page, 'support.svg', this.api.trTxt(TrVar.Support))}  
                </>
                
            }
            {this.menuItemLarge( 'my_account', page, 'profile.svg', this.api.trTxt(TrVar.MyAccount))}
            {
              changeRoleUrl !== "" &&
              <Link to={changeRoleUrl} className="menu_hamburger_link unselected_link flex items-center" onClick={() => changeUrl(changeRoleUrl)}>
                  <img alt="log out" className="icon_small" src={"/assets/icon/white/change.svg"}/>
                  <div className="ml-3 my-4 color_white">{changeRoleText}</div>
              </Link>
            }
            {this.menuItemLarge( 'logout', page, 'sign_out.svg', this.api.trTxt(TrVar.SignOut))}
          </div>
        }
      </>
    )
  }
}

export default connector(Menu);
