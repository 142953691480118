import React from 'react'

interface ComponentProps{
  close: Function
}

interface ComponentState {}

export default class Emoji extends React.Component<ComponentProps, ComponentState>  {

  // See https://www.unicode.org/emoji/charts/full-emoji-list.html
  emojis = [
    0x1F600,
    0x1F605,
    0x1F602,
    0x1F970,
    0x1F929,
    0x1F60E,
    0x1F92F,
    0x1F634,
    0x1F911,
    0x1F64F,
    0x1F483,
    0x1F57A,
    0x1F3C3,  // Extra chars
    0x1F46B,
    0x1F491,  // Extra chars
    0x1F451,
    0x1F459,
    0x1FA71,
    0x1F98B,
    0x1F981,
    0x1F406,
    0x1F338,
    0x1F341,
    0x1F333,
    0x1F334,
    0x1F30E,
    // 0x2B50, star
    0x1F308,
    0x1F525,
    0x1F4A5,
    // 0x2600, sun
    0x1F957,
    0x1F382,  // Cake
    0x1F942,
    // 2615 hot beverage
    0x1F37E,
    0x1F3CB,
    0x1F3C7, // Horse
    0x1F993 // Zebra
  ];

  getSurrogateString(hexNr: number) {
    let highSurrogate = Math.floor((hexNr - 0x10000) / 0x400) + 0xD800;
    let lowSurrogate = (hexNr - 0x10000) % 0x400 + 0xDC00;
    return String.fromCharCode(highSurrogate, lowSurrogate);
  }

  addEmoji = (emoji: number) => {
    this.props.close(this.getSurrogateString(emoji))
  }

  render() {

    return (
      <div>
        <div className="text_right m-4" onClick={() => this.props.close(null)}>
          <img alt="close" className="icon_medium cursor-pointer" src="/assets/icon/white/close-circle.svg" />
        </div>
        {
          this.emojis.map((emoji, index) => {
            return (
              <div key={'emoji_' + index} className="p-2 inline_block cursor-pointer"
                   onClick={() => this.addEmoji(emoji)}
                   style={{fontSize: '32px'}}>
                {this.getSurrogateString(emoji)}
              </div>
            )
          })
        }
      </div>
    )
  }
}
