import React, { useState } from 'react';
import "./Tasks.css";
import {
  FocusAreaInterface,
} from "./interfaces";
import Api from "../../services/Api";
import { introTexts } from "./texts";
import SvgAnimation from '../SvgAnimation';
import { TrVar } from '../../services/translate';

interface ComponentProps {
  focusArea: FocusAreaInterface
  saveFocusSettings: Function
  close: Function
}

const FocusAreaSettings = (props: ComponentProps) => {
  const [page, setPage] = useState(0);
  const api = Api.getInstance()


  const saveFocusSettings = () => {
    props.saveFocusSettings()
    props.close()
  }

  return (
    <div className="text_center bg_dark_blue color_white help_modal">
      {
        page === 0 &&
        <div className="mx-auto focus_area_setting_page flex flex-col justify-center" style={{ maxWidth: '500px', height: '100%' }}>
          <div>
            <h1>
              {api.lang === 'sv' ?
                'Då kör vi igång med området '
                :
                'Then we get started with the area '
              }
              {props.focusArea.name}!
            </h1>
          </div>
          <div>
            <div className="btn_top_margin button green inline_block"
              onClick={() => setPage(1)}>
              {api.trTxt(TrVar.Continue)}
            </div>
          </div>
        </div>
      }

      {
        page === 1 &&
        <div className="mx-auto flex flex-row justify-center" style={{ maxWidth: '650px', height: '100%' }}>
          <div className="self-end">
            <h1>
              {api.lang === 'sv' ?
                'Vad innebär ' + props.focusArea.name.toLowerCase() + '?'
                :
                'What does ' + props.focusArea.name.toLowerCase() + ' mean?'
              }
            </h1>
            {
              introTexts(props.focusArea.focusArea, api.lang).map((text, index) => {
                return (
                  <p key={"intro_text_" + index} style={{ maxWidth: '600px' }} className="top_margin mx-auto r-fontsize">
                    {text}
                  </p>
                )
              })
            }

          </div>
          <div className="self-start">
            <div className="btn_top_margin button green inline_block bottom_margin"
              onClick={() => setPage(2)}>
              {api.trTxt(TrVar.Continue)}
            </div>
          </div>
        </div>
      }

      {
        page === 2 &&
        <div className="mx-auto flex flex-row justify-center" style={{ maxWidth: '600px', height: '100%' }}>
          <div className="self-end">
            <h1>
              {api.lang === 'sv' ?
                'Så jobbar du med ditt utvecklingsområde'
                :
                'How to work with your developmental area'
              }
            </h1>
            <div className='mx-auto' style={{ maxWidth: '490px', textAlign: 'center' }}>
              <h4 className="top_margin">
                {api.lang === 'sv' ? 'Svara på insiktsdrivande frågor' : `Answer insight yielding questions`}
              </h4>
              <div className="top_margin">
                {api.lang === 'sv' ?
                  'Varje övning innehåller frågor där du får reflektera över vad du kan göra för att komma närmare din fulla potential.'
                  :
                  `Each exercise contains questions where you'll reflect over what you can do to reach your full potential.`
                }
              </div>
              <h4 className="top_margin">
                {api.lang === 'sv' ? 'Gör planer för din utveckling' : `Plan your development`}
              </h4>
              <div className="top_margin">
                {api.lang === 'sv' ?
                  'I vissa övningar kan du göra planer för din framtida utveckling.'
                  :
                  `In some exercises you've got the opportunity to make plans for your future development.`
                }
              </div>
              <h4 className="top_margin">{api.lang === 'sv' ? 'Följ upp dina planer' : `Follow up your plans`}</h4>
              <div className="top_margin">
                {api.lang === 'sv' ?
                  'En tid efter att du gjort en plan, får du svara på uppföljningsfrågor om hur det gått.'
                  :
                  `Some time after you've made a plan, you'll answer follow up questions how it went.`
                }
              </div>
            </div>
          </div>
          <div>
            <div className="btn_top_margin button green inline_block"
              onClick={() => setPage(3)}>
              {api.trTxt(TrVar.Continue)}
            </div>
          </div>
        </div>
      }
        
      {
        page === 3 &&
        <div className="mx-auto focus_area_setting_page flex flex-row justify-center" style={{ maxWidth: '600px', height: '100%' }}>
          <div className='modal_animation mx-auto'>
            <SvgAnimation animationType='checkmark'  />
          </div>
          <div className="">
            <div>
              <h1>{api.lang === 'sv' ? 'Härligt!' : 'Awesome!'}</h1>
              <p className="top_margin">
                {api.lang === 'sv' ? 'Du är all-set och vi kan sätta igång.'
                  :
                  'You’re all set, and we can get started.'}

              </p>
            </div>
            <div>
              <div className="btn_top_margin button green inline_block" onClick={() => saveFocusSettings()}>
                {api.trTxt(TrVar.Continue)}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default FocusAreaSettings;
