import {daysShort, daysShortEn, daysLong, daysLongEn, monthsLongEn, monthsLong,
  monthsShort, monthsShortEn} from "./dateStructs"

export function getWeekdayShort(dayNum: number, lang: string) {
  if (lang === 'en') {
    return daysShortEn[dayNum]
  }
  return daysShort[dayNum]
}

export function weekdayLongFromDate(date: Date, lang: string) {
  let day = date.getDay() - 1
  if (day < 0) {
    day = 6
  }
  return getWeekdayLong(day, lang)
}

export function getWeekdayLong(dayNum: number, lang: string) {
  if (lang === 'en') {
    return daysLongEn[dayNum]
  }
  return daysLong[dayNum]
}

export function getDateLong(dateStr: string, lang: string) {
  const d = new Date(dateStr)
  return d.getDate() + ' ' + getMonthLong(d.getMonth(), lang) + ', ' + d.getFullYear()
}

export function getDayTime(dateStr: string, lang: string) {
  const d = new Date(dateStr)
  return  weekdayLongFromDate(d, lang) + ' - ' + dateFormatHourMinute(d)
}

export function getMonthLong(monthNum: number, lang: string) {
  if (lang === 'en') {
    return monthsLongEn[monthNum]
  }
  return monthsLong[monthNum]
}

export function getMonthShort(monthNum: number, lang: string) {
  if (lang === 'en') {
    return monthsShortEn[monthNum]
  }
  return monthsShort[monthNum]
}

function dateFormatHourMinute(d: Date) {
   return d.getHours() + ':' + twoDigits(d.getMinutes())
}

export function formatHourMinute(dateStr: string) {
  const d = new Date(dateStr)
  return dateFormatHourMinute(d)
}

export function formatCountDown(dateStr: string, lang: string) {
  const d = new Date(dateStr)
  const now = new Date()
  const secondsDiff = (d.getTime() - now.getTime()) / 1000
  const inTime = lang === 'sv' ? 'Om ' : 'In '

  if (secondsDiff > 0 && secondsDiff < 24 * 3600) {
    const hours = Math.floor(secondsDiff / 3600)
    const minutes = Math.floor((secondsDiff - 3600 * hours) / 60)
    const seconds = Math.floor(secondsDiff - 3600 * hours - 60 * minutes)
    if (hours > 0) {
      return inTime + hours + ' h ' + minutes + ' min'
    } else {
      return inTime + minutes + ' min ' + twoDigits(seconds) + ' s'
    }
  } else {
    return "-"
  }

}

export function dateFormatSoon(d: Date, lang: string) {
  const now = new Date()
  const midnight = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const utcDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());

  const dayDiff = Math.floor((utcDate - midnight) / (24 * 3600 * 1000));

  let ret: string

  if (now.getFullYear() !== d.getFullYear()) {
    ret = '' + d.getFullYear() + ' ' + d.getDate() + '/' + (d.getMonth() + 1)
  } else if (dayDiff === 0) {
    if (lang === 'en') ret = 'Today'
    else ret = 'I dag'
  } else if (dayDiff === 1) {
    if (lang === 'en') ret = 'Tomorrow'
    else ret = 'I morgon'
  } else if (dayDiff === 2) {
    if (lang === 'en') ret = 'Day after tomorrow'
    else ret = 'I övermorgon'
  } else if (dayDiff === -1) {
    if (lang === 'en') ret = 'Yesterday'
    else ret = 'I går'
  } else {
    let day = lang === 'en' ? daysLongEn : daysLong
    ret = day[dayOfWeek(d)] + ' ' + d.getDate() + '/' + (d.getMonth() + 1)
  }

  ret += ' ' + d.getHours() + ':' + twoDigits(d.getMinutes())
  return ret
}

export function formatSoon(dateStr: string, lang: string) {
  const d = new Date(dateStr)
  return dateFormatSoon(d, lang)

}

export function isSoon(dateStr: string, minutes: number) {
  const d = new Date(dateStr)
  const now = new Date()

  const minuteDiff = (d.getTime() - now.getTime()) / (60 * 1000);

  if (minuteDiff < minutes) {
    return true
  } else {
    return false
  }
}

export function inMinutes(dateStr: string) {
  const d = new Date(dateStr)
  const now = new Date()

  return (d.getTime() - now.getTime()) / (60 * 1000);
}

export function isSameDate(d1: Date, d2: Date) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export function twoDigits(digit: number) {
  if (digit < 10) {
    return "0" + digit
  }
  return "" + digit
}

export function getDayOfWeek(d: Date) {
  let dayOfWeek = d.getDay() - 1
  if (dayOfWeek < 0) {
    dayOfWeek = 6
  }
  return dayOfWeek
}

export function getWeekFromDate(d: Date) {
  const date = new Date(d.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
    - 3 + (week1.getDay() + 6) % 7) / 7)
}

export function dateFormatDate(d: Date, showTime: boolean, lang: string) {
  let day = lang === 'en' ? daysLongEn : daysLong
  let ret = day[dayOfWeek(d)] + ' ' + d.getDate() + '/' + (d.getMonth() + 1)
  if (showTime) {
    ret += ' ' + twoDigits(d.getHours()) + ':' + twoDigits(d.getMinutes())
  }
  return ret
}

export function formatDate(dateStr: string, showTime: boolean, lang: string) {
  const d = new Date(dateStr)
  return dateFormatDate(d, showTime, lang)
}

export function formatDateMonDay(dateStr: string, lang: string) {
  const d = new Date(dateStr)
  return d.getDate() + '/' + (d.getMonth() + 1)
}

export function dayOfWeek(date: Date) {
  if (date) {
    const dow = date.getDay()
    if (dow === 0) {
      return 6
    } else {
      return dow - 1
    }
  }
  return 0  // Actually an error, but should not happen
}

export function todayStr() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
}

export function formatDateYear(dateStr: string) {
  var today = new Date(dateStr);
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
}
