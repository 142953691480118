import React, { useEffect } from 'react';
import ClientMeasure from "../client/ClientMeasure";
import ClientMeasureDevelopment from "../client/ClientMeasureDevelopment";
import { LicenseInterface } from '../../interfaces/License';
import ClientMeasureOverview from '../OnboardingSteps/ClientMeasureOverview';
import ClientMeasureIntro from '../OnboardingSteps/ClientMeasureIntro';
import ClientMeasureOutro from '../OnboardingSteps/ClientMeasureOutro';
import Api from '../../services/Api';
import OnboardingOutro from '../OnboardingSteps/Outro';

interface ComponentProps {
  save: Function
  scrollToTop: Function
  measure: number
  progress: Function
  coachFree: boolean
  finishSection: Function
  measureDone: Function
  goTo: number
  packageFree: boolean
  license?: LicenseInterface
  firstMeasure: Function
  goToDev: Function
}

const PotentialOnboarding = (props: ComponentProps) => {
  const measureDone = (id: number) => {
    props.measureDone(id)
  }

  useEffect(() => {
    const api = Api.getInstance()
    let url = 'measures?full=1'
    api.get(url).then(response => {
      if (response.json.measures.length > 0) {
        props.firstMeasure(false)
      }
    })
  // eslint-disable-next-line
  }, [])

  if (props.goTo === 0) {
    return (
      <ClientMeasureOverview progress={props.progress} coachFree={props.coachFree} />
    )
  } else if (props.goTo === 1) {
    return (
      <ClientMeasureIntro progress={props.progress} />
    )
  } else if (props.goTo === 2) {
    return (
      <ClientMeasure progress={props.progress} measureId={props.measure} onboarding={true} measureDone={measureDone} coachFree={props.coachFree}
        scrollToTop={props.scrollToTop} />
    )
  } else if (props.goTo === 3) {
    return (
      <ClientMeasureDevelopment finishSection={props.finishSection} coachFree={props.coachFree} progress={props.progress} areaNr={-1} onboarding={true}
        summary={''} close={() => props.save()} />
    )     
  } else {
    if (props.packageFree && props.coachFree) {
      return (
        <OnboardingOutro save={props.save} goToDev={props.goToDev} />
      )
    } else {
      return (
        <ClientMeasureOutro save={props.save} goToDev={props.goToDev} packageFree={props.packageFree} coachFree={props.coachFree} close={() => props.save()} license={props.license} />
      )      
    }
  }
}

export default PotentialOnboarding
