import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import Breadcrumbs from "../menu/Breadcrumbs";
import {PackageType} from "../../interfaces/Package";
import {ClientInterface} from "../../interfaces/Client";
import {changeUrl} from "../../services/Navigation";
import { TrVar } from '../../services/translate';
import { CONFIG } from "../../constants";

interface ChatPartnerInterface {
  coachId: number
  active: boolean
  firstName: string
  lastName: string
  picture: string
}
interface ComponentProps {
  hasAiCoach?: boolean
  isClientActive?: boolean
  clientHasCoach?: boolean
  close?: Function
  client?: ClientInterface
}

interface ComponentState {
  chatPartners: Array<ChatPartnerInterface>
  loaded: boolean
  packageType: PackageType
}

export default class ClientChat extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()

  state: Readonly<ComponentState> = {
    chatPartners: {} as Array<ChatPartnerInterface>,
    loaded: false,
    packageType: 0
  }

  componentDidMount() {
    this.getData()
  }

  private getData = () => {
    let url = 'coach_chat/client/get'

    this.api.get(url).then(response => {
      this.setState({
        chatPartners: response.json.chatPartners,
        packageType: response.json.packageType,
        loaded: true
      })
    })
  }

  close = () => {
    if (this.props.close) {
      this.props.close()
    }
  }

  renderCoachBox = (url: string, logo: string, name: string, isArchived: boolean, coachId: number, title = "") => {
    return (
      <div 
        key={"chat_partner_" + coachId} 
        className={`top_margin p-4 box_radius_small bg_neutral_800 color_white flex cursor-pointer ${isArchived ? 'archived_coach_chat' : 'chat_coach'}`}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'bg_neutral_600'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'bg_neutral_800'}
        onClick={() => changeUrl(url)}
        style={{maxWidth: "495px", flexDirection: 'column'}}
      >
        <div className="flex" style={{ maxWidth: "495px", flexDirection: 'row', alignItems: 'center' }}>
          <img alt="coach" className="circle_large" src={logo} />
          <div className="left_margin" style={{display: 'inline'}}>
            <p className="color_neutral_400">{title}</p>
            <h2>{name}</h2>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    let breadcrumbs: any = null
    let name = this.api.trTxt(TrVar.SelectChat)
    let inactiveCoaches = 0

    let currentCoach = this.state.chatPartners.find((chatPartner) => {
      return chatPartner.active
    })

    let hasChatAccess = this.props.client?.package.isVideoCoachIncluded && this.props.client?.package.isChatCoachIncluded


    return (
      <div className="page_content">
        <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>
        <div className="page_section">
          <h1>{this.api.trTxt(TrVar.Chat)}</h1>
          <div className="page_bar"/>
          {
            this.props.hasAiCoach && this.renderCoachBox('/app/client/chat/ai', '/assets/images/ai_emma.svg', 'AI Emma', false, -1, "Din AI coach")

          }

          {
            currentCoach && hasChatAccess && this.renderCoachBox('/app/client/chat/' + currentCoach.coachId, currentCoach.picture, currentCoach.firstName, false, currentCoach.coachId, "Din videocoach")
          }

          {((currentCoach && this.state.chatPartners.length > 1) || (!currentCoach && this.state.chatPartners.length > 0)) && <h3 className="mt-16">
            {this.api.trTxt(TrVar.PreviousCoaches)}
          </h3>
          }

          {
            !!this.state.chatPartners && this.state.chatPartners.map((chatPartner, i) => {
              if(!chatPartner.active){
                return this.renderCoachBox('/app/client/chat/' + chatPartner.coachId, chatPartner.picture, chatPartner.firstName, true, chatPartner.coachId)
              }
            })
          }
        </div>
      </div>
    )
  }
}
