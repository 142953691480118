import React from 'react';
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';
import Text from "../Text/Text";

interface ComponentProps{
  save?: Function
  hasCoach: boolean
  chatCoach?: boolean
  finishSection?: Function
  progress?: Function
  inOnboarding?: boolean
  next?: Function
}

const CoachAgreement = (props: ComponentProps) => {
  const api = Api.getInstance();

  const save = () => {
    if (props.save) {
      props.save();
      if (props.finishSection) {
        props.finishSection()
      }
    }
  }

  return (
    <>

      <>
        <div className='page_section'>
          <h1>
            {props.hasCoach ? api.trTxt(TrVar.Coaching) + '-' + api.trTxt(TrVar.Agreement) : api.trTxt(TrVar.PersonalData)}
          </h1>
          {props.hasCoach &&
            <Text color="gray" className="top_margin">
              {api.trTxt(TrVar.TheRoleOfTheCoachIsToSupportAndChallengeYouInTheProcessOfReachingYourGoals)}
            </Text>
          }
        </div>
        {
          props.hasCoach &&
          <>
            <div className="page_section top_margin">
              <h2 className="top_margin">{api.trTxt(TrVar.TheRoleOfTheCoachIsTo)}:</h2>
              <ul className="top_margin">
                <Text color="gray" element="li">{api.trTxt(TrVar.BeANeutralPartyWhoWillGuideYou)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.SupportAndChallengeYouInTheProcess)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.ConductCoachingWIthTheHelpOfPowerfulQuestions)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.DriveYourDevelopmentForwardButNotGiveYouReadyMadeSolutions)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.TreatTheContentOfTheConversationsPersonalTrustPrivateRelations)}</Text>
              </ul>
            </div>
            <div className="page_section top_margin">
              <h2 className="top_margin">{api.trTxt(TrVar.TheRoleOfTheClientIsTo)}:</h2>
              <ul className="top_margin">
                <Text color="gray" element="li">{api.trTxt(TrVar.BeMotivatedAndPreparedToDoTheWorkRequiredToAchieveYourGoals)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.ContributeToTheProcessByBeingActiveAndOpenToTheProcess)}</Text>
                <Text color="gray" element="li">{api.trTxt(TrVar.BeResponsibleForActingInTheDirectionTowardsYourDesiredSituation)}</Text>
              </ul>
            </div>
          </>
        }

        <div className="page_section top_margin">
          <h2 className="top_margin">{api.trTxt(TrVar.AnonymisedReport)}</h2>
          <Text color="gray" className="top_margin">{api.trTxt(TrVar.IfYourLicenseIsPaidByyourOrganisationAnAnonymisedReportWillBeAvailableForTheContactPersonInYourOrganisation)}</Text>
        </div>

        <div className="page_section top_margin">
          <h2 className="top_margin">{api.trTxt(TrVar.Other)}</h2>
          <Text color="gray" className="top_margin">
            {api.trTxt(TrVar.ToReadMoreAboutHowWeHandleYourPersonalInformation)}&nbsp;
            <a target="_new" className="underline color_white"
              href={"https://www." + api.trTxt(TrVar.ZebrainLegal)}>{api.trTxt(TrVar.ZebrainLegal)}</a>.
          </Text>
        </div>
      </>

      {
        (!props.hasCoach && props.inOnboarding) &&
        <div style={{ backgroundColor: '#1a1a3a' }} className="text_center page_section top_margin">
          <div className="button green mobile_size_btn inline_block" onClick={() => (props.next ? props.next() : '')}>{api.trTxt(TrVar.IAccept)}</div>
        </div>
      }

      {
        props.save &&
        <div style={{ backgroundColor: '#1a1a3a' }} className="text_center page_section top_margin">
          <div className="button green mobile_size_btn inline_block" onClick={() => save()}>{api.trTxt(TrVar.IAccept)}</div>
        </div>
      }

    </>
  )
}

export default CoachAgreement;
