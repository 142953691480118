import React from 'react'
import {IonContent} from "@ionic/react";
import Api from "../../services/Api";
import { TrVar } from '../../services/translate';

interface ComponentProps{
  close: Function
  onlyShow?: string
}

interface ComponentState {}

export default class MeasureInfo extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  render() {
    return (
      <IonContent>
        <div style={{ minHeight: "100%" }}>
          <div className="page_section">
            <div className="text_right cursor-pointer" onClick={() => this.props.close()}>
              <img alt="close" src="/assets/icon/white/close-circle.svg" className="icon_medium" />
            </div>
            {
              (!this.props.onlyShow || this.props.onlyShow === '0') &&
              <>
                <h4 className="blue bold">{this.api.trTxt(TrVar.SelfInsight)}</h4>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.SelfInsightIsTheFoundationToAllDevelopment)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.SelfInsightIsANecessaryFoundation)}
                </p>
              </>
            }
            {
              (!this.props.onlyShow || this.props.onlyShow === '1') &&
              <>
                <h4 className="top_margin blue bold">{this.api.trTxt(TrVar.BasicEnergy)}</h4>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.ByBasicEnergyWeMeanTheAbsenceOfHarmfulStressInOurLives)}
                </p>
              </>
            }
            {
              (!this.props.onlyShow || this.props.onlyShow === '2') &&
              <>
                <h4 className="top_margin blue bold">{this.api.trTxt(TrVar.Motivation)}</h4>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.MotivationContainsEverythingWeNeedToFeelMotivated)}
                </p>
                <ul className="top_margin">
                  <li>
                    {this.api.trTxt(TrVar.CoachTasksStep3Sub1)}
                  </li>
                  <li>
                    {this.api.trTxt(TrVar.CoachTasksStep3Sub2)}
                  </li>
                </ul>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.ZebrainsOwnMethod)}
                </p>
              </>
            }
            {
              (!this.props.onlyShow || this.props.onlyShow === '3') &&
              <>
                <h4 className="top_margin blue bold">{this.api.trTxt(TrVar.SelfLeadership)}</h4>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.ZebrainsMethodAreas)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.SelfLeadershipIsTheAbilityToWorkOnOurOwn)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.ZebrainsTestMethods)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.SelfLeadershipWasIntroducedInLiterature)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.SharonParkerDescription)}
                </p>
                <p className="top_margin">
                  {this.api.trTxt(TrVar.CrucialFactors)}:
                </p>
                <ul className='top_margin'>
                  <li>{this.api.trTxt(TrVar.CreateClearGoals)}</li>
                  <li>{this.api.trTxt(TrVar.CreateClarityOnYourGoals)}</li>
                  <li>{this.api.trTxt(TrVar.ClearFeedbackAndReflection)}</li>
                  <li>{this.api.trTxt(TrVar.ContinuousFeedback)}</li>
                </ul>
                <div className="top_margin">
                  {
                    <ol style={{ 'listStyle': 'none' }}>
                      <li>{this.api.trTxt(TrVar.ZebrainsReference1)}</li>
                      <li>{this.api.trTxt(TrVar.ZebrainsReference2)}</li>
                      <li>{this.api.trTxt(TrVar.ZebrainsReference3)}</li>
                      <li>{this.api.trTxt(TrVar.ZebrainsReference4)}</li>
                    </ol>
                  }
                </div>
              </>
            }
          </div>
        </div>
      </IonContent>
    )
  }
}
